import {
  BuildingAcccessEmployeeModel,
  BuildingAcccessExternalVisitorModel,
  BuildingAcccessInternalVisitorModel,
  BuildingAcccessReplacementCardModel,
  BuildingAcccessShortTermEmployeeModel,
  BuildingAcccessSpecialAccessModel,
  BuildingAcccessVMSExternalVisitorModel,
  GallagherVMSIntergationModel,
  GallagherVMSIntergationUpdateModel,
  BuildingAccessRequest_Approval_Items,
  Vms_Meeting_Items,
  CardType,
} from "interfaces/Visitor";
import { makeAutoObservable, flow, toJS, runInAction } from "mobx";
import client from "services/api";
import RootStore from "./RootStore";

export interface Form_prequisite {
  id: number;
  building_name: string;
  name: string;
  card_type: number;
  prerequisites_data: string;
  location: number;
}
export interface Special_Access_Area {
  id: number;
  building_name: string;
  name: string;
  location: number;
  vms_access_group: boolean;
  vms_special_access_required: boolean;
}
export default class BAMStore {
  error: string | unknown = "";
  isLoading = false;
  isLoadingAll = false;

  rootStore!: RootStore;
  location_cardTypes: { [location: string]: Form_prequisite[] } = {};
  location_saas: { [location: string]: Special_Access_Area[] } = {};
  vms_location_saas: { [location: string]: Special_Access_Area[] } = {};
  prerequisites: Form_prequisite[] = [];
  special_access_areas: Special_Access_Area[] = [];

  hasRequests: boolean = false;
  building_requests_grouped_status: {
    [status_id: number]: BuildingAccessRequest_Approval_Items[];
  } = {};
  building_requests_grouped_request: {
    [request_id: number]: BuildingAccessRequest_Approval_Items[];
  } = {};
  building_requests_approver: BuildingAccessRequest_Approval_Items[] = [];
  hasMeetings: boolean = false;
  building_request_vms_host: Vms_Meeting_Items[] = [];
  vms_meetings_grouped_status: {
    [status_id: number]: Vms_Meeting_Items[];
  } = {};
  vms_meetings_grouped_request: {
    [vms_meeting_id: number]: Vms_Meeting_Items[];
  } = {};
  vms_meeting_by_meeting_id: Vms_Meeting_Items[] = [];
  meeting_visitor_count: number = 0;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  loadAllRequests = flow(function* (this: BAMStore, approver_id: number) {
    this.isLoadingAll = true;
    [0, 1, 2].forEach(async (idx) => {
      await this.loadMyRequests(approver_id, idx).then((res) => {
        this.isLoadingAll = false;
        let numberOfRequests: number = 0;
        Object.keys(this.building_requests_grouped_status).forEach((typ) => {
          numberOfRequests = numberOfRequests + this.building_requests_grouped_status[typ].length;
        });
        this.hasRequests = numberOfRequests > 0;
        return this.building_requests_grouped_status;
      });
    });
  });

  loadAllVmsMeetings = flow(function* (this: BAMStore, host_id: number) {
    this.isLoadingAll = true;
    let numberOfVmsMeetings: number = 0;
    [0, 1, 2, 3].forEach(async (idx) => {
      await this.loadVmsMeetings(host_id, idx).then((res) => {
        this.isLoadingAll = false;
        Object.keys(this.vms_meetings_grouped_status).forEach((typ) => {
          numberOfVmsMeetings = numberOfVmsMeetings + this.vms_meetings_grouped_status[typ].length;
        });
      });
      this.hasMeetings = numberOfVmsMeetings > 0;
      return this.vms_meetings_grouped_status;
    });
  });

  loadSingleMeeting = flow(function* (this: BAMStore, vms_meeting_id) {
    this.isLoading = true;
    this.meeting_visitor_count = 0;
    this.vms_meeting_by_meeting_id = [];
    try {
      const url = `/api/building_access_request/vms_meeting_id/${vms_meeting_id}/`;
      const res = yield client.get(url);
      this.vms_meeting_by_meeting_id = res.data.results;
      this.meeting_visitor_count = res.data.count;
      if (this.vms_meeting_by_meeting_id) {
        return this.vms_meeting_by_meeting_id;
      }
    } catch (err) {
      console.log(err);
      this.error = err;
      this.isLoading = false;
    }
    return this.vms_meeting_by_meeting_id;
  });

  changeStatus = flow(function* (
    this: BAMStore,
    requested_area_list_id: number,
    status_id: number,
    request_id: number,
    area_id: number,
    approver_id: number,
    approver_comment: string
  ) {
    try {
      const form_data = {
        approval_status: status_id,
        building_request: request_id,
        access_area: area_id,
        approved_by: approver_id,
        comment: approver_comment,
      };
      const url = `/api/requested_area_list/${requested_area_list_id}/`;
      const res = yield client.patch(url, form_data);
      console.log("changeStatus res: ", res);
      return res;
    } catch (err) {
      console.log(err);
      this.error = err;
    }
  });

  vmsChangeStatus = flow(function* (
    this: BAMStore,
    status_id: number,
    building_request_vms_id: number
  ) {
    try {
      const form_data = {
        request_status: status_id,
        building_request: building_request_vms_id,
      };
      const url = `/api/vms_meetings/${building_request_vms_id}/`;
      const res = yield client.patch(url, form_data);
      console.log("changeStatus res: ", res);
      return res;
    } catch (err) {
      console.log(err);
      this.error = err;
    }
  });

  // hasRequests(approver_id: number) {
  //   console.log("hr: ", Object.keys(this.building_requests_grouped_status));
  //   if (Object.keys(this.building_requests_grouped_status).length > 0) return true;
  //   this.loadAllRequests(approver_id).then((res) => {
  //     return Object.keys(this.building_requests_grouped_status).length > 0 ? true : false;
  //   });
  //   return false;
  // }
  //rebase the commit(https://bitbucket.org/bhptechsi/work-day/commits/269434056efde68e3d5c191e6cac0009a776b22a) which shows the requests tab
  // hasRequests(approver_id: number) {
  //   if (this.building_requests_grouped_status) return true;
  //   this.loadAllRequests(approver_id).then((res) => {
  //     return this.building_requests_grouped_status ? true : false;
  //   });
  //   return false;
  // }

  getRequests(status_types: number[]) {
    let requests: BuildingAccessRequest_Approval_Items[] = [];
    status_types.forEach((typ) => {
      requests = requests.concat(this.building_requests_grouped_status[typ] || []);
    });
    return requests;
  }

  getVmsMeetings(status_types: number[]) {
    let vms_meetings: Vms_Meeting_Items[] = [];
    status_types.forEach((typ) => {
      vms_meetings = vms_meetings.concat(this.vms_meetings_grouped_status[typ] || []);
    });
    return vms_meetings;
  }

  loadMyRequests = flow(function* (this: BAMStore, approver_id: number, status_id: number) {
    this.isLoading = true;
    try {
      const url = `/api/building_access_request/approver_id/${approver_id}/status_id/${status_id}/`;
      const res = yield client.get(url);
      this.building_requests_approver = res.data.results;
      if (this.building_requests_approver) {
        this.building_requests_grouped_status[status_id] = [];
        this.building_requests_approver.map((element, idx) => {
          const theElement = { ...element, status_id, id: idx };
          const request_id = element.building_request_id;
          if (this.building_requests_grouped_request[request_id])
            this.building_requests_grouped_request[request_id].push(theElement);
          else this.building_requests_grouped_request[request_id] = [theElement];
          if (this.building_requests_grouped_status[status_id])
            this.building_requests_grouped_status[status_id].push(theElement);
          else this.building_requests_grouped_status[status_id] = [theElement];
        });
      }
      this.isLoading = false;
      return this.building_requests_grouped_status[status_id];
    } catch (err) {
      console.log(err);
      this.error = err;
      this.isLoading = false;
    }
    return this.building_requests_approver;
  });

  loadVmsMeetings = flow(function* (this: BAMStore, host_id: number, status_id: number) {
    this.isLoading = true;
    try {
      const url = `/api/building_access_request/host_id/${host_id}/status_id/${status_id}/`;
      const res = yield client.get(url);
      this.building_request_vms_host = res.data.results;
      if (this.building_request_vms_host) {
        this.vms_meetings_grouped_status[status_id] = [];
        this.building_request_vms_host.map((vmselement, idx) => {
          const theVmsElement = { ...vmselement, status_id, id: idx };
          const request_id = theVmsElement.id;
          if (this.vms_meetings_grouped_request[request_id])
            this.vms_meetings_grouped_request[request_id].push(theVmsElement);
          else this.vms_meetings_grouped_request[request_id] = [theVmsElement];
          if (this.vms_meetings_grouped_status[status_id])
            this.vms_meetings_grouped_status[status_id].push(theVmsElement);
          else this.vms_meetings_grouped_status[status_id] = [theVmsElement];
        });
      }
      this.isLoading = false;
      return this.vms_meetings_grouped_status[status_id];
    } catch (err) {
      console.log(err);
      this.error = err;
      this.isLoading = false;
    }
    return this.building_request_vms_host;
  });

  loadBAMPrerequisites = flow(function* (this: BAMStore) {
    const url = `/api/form_prerequisite/`;
    const res = yield client.get(url);
    this.location_cardTypes = {};
    this.prerequisites = res.data.results;
    if (this.prerequisites) {
      // Sort card type (prerequisite) in ascending order
      this.prerequisites
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((element) => {
          const locationId = `${element.location}`;
          if (this.location_cardTypes[locationId])
            this.location_cardTypes[locationId].push({
              ...element,
              name: CardType[element.card_type],
            });
          else this.location_cardTypes[locationId] = [element];
        });
    }
    return this.prerequisites;
  });

  loadBAMSpecialAccessAreas = flow(function* (this: BAMStore) {
    const url = `/api/special_access_area/`;
    const res = yield client.get(url);
    this.location_saas = {};
    this.special_access_areas = res.data.results;
    if (this.special_access_areas) {
      this.special_access_areas.map((element) => {
        const locationId = `${element.location}`;
        if (this.location_saas[locationId]) this.location_saas[locationId].push(element);
        else this.location_saas[locationId] = [element];
      });
    }
    return this.special_access_areas;
  });

  loadVMSAccessGroupAreas = flow(function* (this: BAMStore) {
    const url = `/api/vms_access_area/`;
    const res = yield client.get(url);
    this.vms_location_saas = {};
    this.special_access_areas = res.data.results;
    if (this.special_access_areas) {
      this.special_access_areas.map((element) => {
        const locationId = `${element.location}`;
        if (this.vms_location_saas[locationId]) this.vms_location_saas[locationId].push(element);
        else this.vms_location_saas[locationId] = [element];
      });
    }
    return this.special_access_areas;
  });

  getSpecialAccessAreas = flow(function* (this: BAMStore, locationId: number) {
    return this.location_saas[locationId];
  });

  createAccessRequest_ShortTermEmployee = flow(function* (
    this: BAMStore,
    newAccessRequest: BuildingAcccessShortTermEmployeeModel
  ) {
    let form_data = new FormData();
    form_data.append("location_id", newAccessRequest.location_id.toString());
    form_data.append("card_type_id", newAccessRequest.card_type_id.toString());
    form_data.append("start_date_time", newAccessRequest.start_date_time);
    form_data.append("end_date_time", newAccessRequest.end_date_time);
    form_data.append("access_hours", newAccessRequest.access_hours.toString());
    form_data.append("requester_id", newAccessRequest.requester_id.toString());
    form_data.append("card_holder_id", newAccessRequest.card_holder_id.toString());
    form_data.append("special_access", newAccessRequest.special_access ? "True" : "False");
    form_data.append("access_reason", newAccessRequest.access_reason);
    form_data.append("host_name_id", newAccessRequest.host_name_id.toString());
    form_data.append("additional_info", newAccessRequest.additional_info.toString());
    form_data.append(
      "company",
      newAccessRequest.company ? newAccessRequest.company.toString() : ""
    );
    if (newAccessRequest.photo)
      form_data.append("photo", newAccessRequest.photo, newAccessRequest.photo.name);
    else form_data.append("photo", "");
    if (newAccessRequest.access_area_ids) {
      newAccessRequest.access_area_ids.forEach((aid) => {
        form_data.append("access_area_ids", aid);
      });
    } //else {
    //   form_data.append("access_area_ids", "");
    // }
    const options = { headers: { "content-type": "multipart/form-data" } };
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(
        `/api/building_access_request/card_type/short_term_employee/`,
        form_data,
        options
      );
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  createAccessRequest_Employee = flow(function* (
    this: BAMStore,
    newAccessRequest: BuildingAcccessEmployeeModel
  ) {
    let form_data = new FormData();
    console.log(form_data);
    form_data.append("location_id", newAccessRequest.location_id.toString());
    form_data.append("card_type_id", newAccessRequest.card_type_id.toString());
    form_data.append("start_date_time", newAccessRequest.start_date_time);
    form_data.append("end_date_time", newAccessRequest.end_date_time);
    form_data.append("access_hours", newAccessRequest.access_hours.toString());
    form_data.append("requester_id", newAccessRequest.requester_id.toString());
    form_data.append("card_holder_id", newAccessRequest.card_holder_id.toString());
    form_data.append("special_access", newAccessRequest.special_access ? "True" : "False");
    form_data.append("access_reason", newAccessRequest.access_reason);
    form_data.append("host_name_id", newAccessRequest.host_name_id.toString());
    form_data.append("additional_info", newAccessRequest.additional_info.toString());

    form_data.append(
      "company",
      newAccessRequest.company ? newAccessRequest.company.toString() : ""
    );

    if (newAccessRequest.photo)
      form_data.append("photo", newAccessRequest.photo, newAccessRequest.photo.name);
    else form_data.append("photo", "");
    if (newAccessRequest.access_area_ids) {
      newAccessRequest.access_area_ids.forEach((aid) => {
        form_data.append("access_area_ids", aid);
      });
    } //else {
    //   form_data.append("access_area_ids", "");
    // }
    const options = { headers: { "content-type": "multipart/form-data" } };

    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(
        `/api/building_access_request/card_type/employee/`,
        form_data,
        options
      );
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  createAccessRequest_ExternalVisitor = flow(function* (
    this: BAMStore,
    newAccessRequest: BuildingAcccessExternalVisitorModel
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(
        `/api/building_access_request/card_type/external_visitor/`,
        newAccessRequest
      );
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  createAccessRequest_ReplacementCard = flow(function* (
    this: BAMStore,
    newAccessRequest: BuildingAcccessReplacementCardModel
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(
        `/api/building_access_request/card_type/replacement_card/`,
        newAccessRequest
      );
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  createAccessRequest_InternalVisitor = flow(function* (
    this: BAMStore,
    newAccessRequest: BuildingAcccessInternalVisitorModel
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(
        `/api/building_access_request/card_type/internal_visitor/`,
        newAccessRequest
      );
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  createAccessRequest_SpecialAccess = flow(function* (
    this: BAMStore,
    newAccessRequest: BuildingAcccessSpecialAccessModel
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(
        `/api/building_access_request/card_type/special_access/`,
        newAccessRequest
      );
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  createAccessRequest_Visitor = flow(function* (
    this: BAMStore,
    newAccessRequest: BuildingAcccessVMSExternalVisitorModel
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(
        `/api/building_access_request/card_type/vms_external_visitor/`,
        newAccessRequest
      );
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  createAccessRequest_VMS_External_Visitor = flow(function* (
    this: BAMStore,
    newAccessRequest: GallagherVMSIntergationModel
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(
        `/api/building_access_request/card_type/gallagher_vms_external_visitor/`,
        newAccessRequest
      );
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  get_vmsCardHolder = flow(function* (this: BAMStore, host_name_id: number) {
    this.isLoading = true;
    try {
      const res = yield client.get(
        `/api/building_access_request/vms_host_name_id/${host_name_id}/`
      );
      this.isLoading = false;
      console.log("Host Validation:", res);
      return res;
    } catch (err) {
      this.isLoading = false;
      console.log("Host Validation Failed", err);
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  updateAccessRequest_Gallagher_VMS_External_Visitor = flow(function* (
    this: BAMStore,
    newAccessRequest: GallagherVMSIntergationUpdateModel,
    building_request_vms_id: number
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.put(
        `/api/building_access_request/card_type/gallagher_vms_external_visitor_update/${building_request_vms_id}/`,
        newAccessRequest
      );
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  updateAccessRequest_VMS_External_Visitor = flow(function* (
    this: BAMStore,
    newAccessRequest: BuildingAcccessVMSExternalVisitorModel
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(
        `/api/building_access_request/card_type/vms_external_visitor_update/`,
        newAccessRequest
      );
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });
}
