import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useTheme,
  Link,
  useMediaQuery,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import {
  useBuildingStore,
  useTeamStore,
  useBookingStore,
  useUserStore,
} from "providers/RootStoreProvider";
import { useEffect, useState } from "react";
import { bhpColor } from "styles/globals";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnMenuContainer,
  GridFilterMenuItem,
  GridOverlay,
  GridSortModel,
} from "@material-ui/data-grid";
import { ScheduleType } from "interfaces/Schedule";
import moment from "moment";
import { superscriptDateFormat } from "utils/date";
import { capitalizeString, sortAlphaNumeric } from "utils/string";
import { useHistory } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { getScheduleTypeString } from "utils/schedule";
import config from "config";
import StarBorderTwoToneIcon from "@material-ui/icons/StarBorderTwoTone";
import StarTwoToneIcon from "@material-ui/icons/StarTwoTone";
import { getDateTimeAtStartOfToday } from "utils/hourlyBookings";
import { getEarliestBooking } from "utils/bookings";
import BookingsPopover from "components/utils/BookingsPopover";
import DOMPurify from "dompurify";

//import useMediaQuery from "utils/useMediaQuery";

interface TeamDetailsPeopleSearchGridProps {
  teamId: number;
  focussedDate: string;
  searchTerm: string;
  workType: ScheduleType | null;
  t: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0,
    },
    chartOuter: {
      border: `1px solid ${bhpColor.blueGrey4}`,
      [theme.breakpoints.down("sm")]: {
        border: 0,
        paddingLeft: 0,
      },
    },
    teamSelect: {
      "& fieldset": {
        backgroundColor: "white",
      },
    },
    dataGrid: {
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiDataGrid-row.Mui-even": {
        backgroundColor: "#F6F6F6",
      },
      "& .MuiDataGrid-cell": {
        color: "#466371",
      },
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-sortIcon": {
          opacity: `1 !important`,
        },
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        color: "#50524D",
        fontWeight: "bold !important",
      },
      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden",
      },
      padding: "0 0 1% 0%",
    },
    recordsFoundLabel: {
      color: "#E65400",
      fontFamily: "Arial",
      fontStyle: "italic",
      paddingTop: 20,
      fontSize: 14,
      fontWeight: "bold",
    },
    spaceTable: {
      height: "635px",
      border: 0,
      "& .MuiTablePagination-root": {
        color: bhpColor.blueGrey1,
      },
      "& .MuiDataGrid-viewport": {
        maxHeight: "none",
      },
      "& .MuiDataGrid-root": {
        border: 0,
      },
      "& .MuiDataGrid-cell": {
        border: 0,
        "&:focus": {
          outline: 0,
        },
      },
      "& .MuiDataGrid-columnHeader": {
        "&:focus": {
          outline: 0,
        },
        "&:focus-within": {
          outline: 0,
        },
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        paddingLeft: 0,
      },
      "& .MuiDataGrid-columnsContainer": {
        borderBottom: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
        color: bhpColor.blueGrey1,
      },
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-row": {
        "&:nth-child(odd)": {
          backgroundColor: bhpColor.backgroundGrey,
          "&:hover": {
            backgroundColor: bhpColor.backgroundGrey,
          },
        },
        "&.Mui-selected": {
          "&:hover": {
            backgroundColor: bhpColor.blueGrey2,
            color: bhpColor.blueGrey1,
          },
          backgroundColor: bhpColor.blueGrey1,
          color: bhpColor.white,
        },
        "&:hover": {
          backgroundColor: bhpColor.backgroundGrey,
        },
      },
    },
  })
);
const TeamDetailsPeopleSearchGrid = observer((props: TeamDetailsPeopleSearchGridProps) => {
  const { teamId, focussedDate, searchTerm, workType, t } = props;
  const classes = innerStyles();
  const teamStore = useTeamStore();
  const buildingStore = useBuildingStore();
  const userStore = useUserStore();
  const bookingStore = useBookingStore();
  const history = useHistory();

  const openFloorPlan = (id: number | undefined, date: string | undefined) => {
    if (id) {
      buildingStore.setupFloorPlanFromSerraviewId(id, false, date);
    }
  };

  const [peopleSchedules, setPeopleSchedules] = useState([]);
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const gridRowHeight = smAndUp ? 52 : 150;
  const gridPageSize = 20;

  const onNameClick = (peopleId: any): void => {
    const newUrl = `/people/${peopleId}`;
    history.push(newUrl);
  };

  const onLocationClick = (locationId: any): void => {
    const locationUrl = `/offices/${locationId}`;
    history.push(locationUrl);
  };

  const [sortModel, setSortModel] = useState<GridSortModel>(
    smAndUp
      ? [
          {
            field: "work_type",
            sort: "desc",
          },
        ]
      : []
  );

  const [userIdEdited, setUserIdEdited] = useState<number | null>(null);

  const handleSortChange = (model: any) => {
    if (JSON.stringify(sortModel) !== JSON.stringify(model)) {
      setSortModel(model);
    }
  };

  useEffect(() => {
    (async () => {
      /**Call to teams api to get people schedules */
      const response = await teamStore.getPeopleSchedule(teamId, focussedDate, focussedDate);
      /**Search Filtering Starts here */
      let filteredResponse = response;
      if (searchTerm !== "") {
        filteredResponse = filteredResponse.filter((res: any) =>
          res.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      if (workType !== null) {
        filteredResponse = filteredResponse.filter(
          (res: any) => res.work_schedules && res.work_schedules[focussedDate]?.status === workType
        );
      }
      /**Search Filtering Ends here */
      if (filteredResponse && filteredResponse.length > 0) {
        const startOfToday = moment(focussedDate).add(-24, "hours").toDate();
        const endOfToday = moment(focussedDate).add(24, "hours").toDate();

        bookingStore
          .loadTeamBookings(
            {
              start: startOfToday,
              end: endOfToday,
            },
            teamId.toString()
          )
          .then((data) => {
            const usersUpdatedObject = filteredResponse.map((user) => {
              const usersDataFromTeamSchedules = data.filter(
                (b) =>
                  b.user_id === user.user_id &&
                  (b.booking_details?.start_date_local === focussedDate ||
                    b.booking_details?.end_date_local === focussedDate)
              );
              if (usersDataFromTeamSchedules && usersDataFromTeamSchedules.length > 1) {
                return {
                  ...user,
                  work_schedules:
                    user.work_schedules && user.work_schedules[focussedDate]
                      ? {
                          [focussedDate]: {
                            ...user.work_schedules[focussedDate],
                            multiple_bookings: usersDataFromTeamSchedules,
                            upcoming_booking: getEarliestBooking(
                              usersDataFromTeamSchedules,
                              focussedDate
                            ),
                          },
                        }
                      : null,
                };
              } else {
                return {
                  ...user,
                  work_schedules:
                    user.work_schedules && user.work_schedules[focussedDate]
                      ? {
                          [focussedDate]: {
                            ...user.work_schedules[focussedDate],
                            multiple_bookings: usersDataFromTeamSchedules,
                            upcoming_booking: usersDataFromTeamSchedules[0] || null,
                          },
                        }
                      : null,
                };
              }
            });
            setPeopleSchedules(usersUpdatedObject);
          });
      }
    })();
  }, [focussedDate, searchTerm, workType, userStore.me]);

  function CustomNoRowsOverlay() {
    return <GridOverlay className={classes.root}>{t("No records found.")}</GridOverlay>;
  }

  const isUserFavourite = (userId: number) => {
    return userStore.me?.favourite_users && userStore.me?.favourite_users.includes(userId);
  };

  const addUserToFavorites = async (userId: number) => {
    if (userId) {
      setUserIdEdited(userId);
      await userStore.addFavouriteUser(userId);
    }
    setUserIdEdited(null);
  };

  const removeUserFromFavorites = async (userId: number) => {
    if (userId) {
      setUserIdEdited(userId);
      await userStore.removeFavouriteUser(userId);
    }
    setUserIdEdited(null);
  };

  const gridColumns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      sortable: true,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Box
              style={{
                display: "inline-flex",
                visibility: params.row.user_id === userStore.me?.id ? "hidden" : "visible",
              }}
              paddingRight={2}
            >
              {userStore.isLoadingFavouriteUsers && params.row.user_id === userIdEdited ? (
                <CircularProgress size={14} color="secondary" />
              ) : isUserFavourite(params.row.user_id) ? (
                <Tooltip title={`Click to remove ${params.row.name} from favourites`}>
                  <StarTwoToneIcon
                    color="primary"
                    style={{ cursor: "pointer", display: "flex", alignSelf: "center" }}
                    onClick={() => removeUserFromFavorites(params.row.user_id)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={`Click to make ${params.row.name} as favourite`}>
                  <StarBorderTwoToneIcon
                    style={{ cursor: "pointer", alignSelf: "center" }}
                    onClick={() => addUserToFavorites(params.row.user_id)}
                  />
                </Tooltip>
              )}
            </Box>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                return onNameClick(params.row.user_id);
              }}
              underline="always"
              color="inherit"
            >
              {params.row.name}
            </Link>
          </>
        );
      },
    },
    {
      field: "work_type",
      headerName: t("Work Type"),
      sortable: true,
      flex: 1,
      valueGetter: (params) =>
        params.row.work_schedules
          ? `${
              params.row.work_schedules[focussedDate]
                ? getScheduleTypeString(params.row.work_schedules[focussedDate]?.status)
                : "Not Available"
            }`
          : "",
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params) => {
        return params.row.work_schedules ? (
          <div>
            {params.row.work_schedules[focussedDate]
              ? getScheduleTypeString(params.row.work_schedules[focussedDate]?.status)
              : t("Not Available")}
          </div>
        ) : (
          ""
        );
      },
    },
    {
      field: "team",
      headerName: t("Default Team"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => (params.row.team ? params.row.team.team_name : ""),
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params) =>
        params.row.team ? <div>{params.row.team.team_name}</div> : <div>--</div>,
    },
    {
      field: "default_office",
      headerName: t("Location"),
      sortable: true,
      flex: 1,
      valueGetter: (params) =>
        params.row.work_schedules
          ? params.row.work_schedules[focussedDate]?.status === ScheduleType.OFFICE
            ? `${params.row.work_schedules[focussedDate]?.city} ${params.row.work_schedules[focussedDate]?.building_name}`
            : params.row.default_office
            ? `${params.row.default_office.city}, ${params.row.default_office.building_name}`
            : ""
          : "",
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params: GridCellParams) => {
        return params.row.work_schedules ? (
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              return onLocationClick(
                params.row.work_schedules[focussedDate]?.status === ScheduleType.OFFICE
                  ? params.row.work_schedules[focussedDate]?.building_id
                  : params.row.default_office
                  ? params.row.default_office.id
                  : ""
              );
            }}
            underline="always"
            color="inherit"
          >
            {params.row.work_schedules[focussedDate]?.status === ScheduleType.OFFICE
              ? `${params.row.work_schedules[focussedDate]?.city}, ${params.row.work_schedules[focussedDate]?.building_name}`
              : params.row.default_office
              ? `${params.row.default_office.city}, ${params.row.default_office.building_name}`
              : ""}
          </Link>
        ) : (
          t("Not Available")
        );
      },
    },
    {
      field: "space",
      headerName: t("Desk"),
      sortable: true,
      flex: 1,
      valueGetter: (params) =>
        params.row.work_schedules && params.row.work_schedules[focussedDate]
          ? params.row.work_schedules[focussedDate]?.upcoming_booking?.space
            ? params.row.work_schedules[focussedDate]?.upcoming_booking?.space.name
            : ""
          : "",
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params: GridCellParams) => {
        return (
          params.row.work_schedules && (
            <BookingsPopover
              focussedDate={focussedDate}
              spaceReference={
                params.row.work_schedules &&
                params.row.work_schedules[focussedDate]?.upcoming_booking
                  ? params.row.work_schedules[focussedDate].upcoming_booking.space.name
                  : null
              }
              serraViewId={
                params.row.work_schedules[focussedDate]?.upcoming_booking?.space.serraview_id ||
                null
              }
              bookings={params.row.work_schedules[focussedDate]?.multiple_bookings}
            />
          )
        );
      },
    },
    {
      field: "working_hours",
      headerName: t("Working Hours"),
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return params.row.work_schedules &&
          params.row.work_schedules[focussedDate]?.status !== ScheduleType.OFF
          ? `${moment(
              params.row.work_schedules[focussedDate]?.start_time &&
                params.row.work_schedules[focussedDate]?.start_time !== ""
                ? params.row.work_schedules[focussedDate]?.start_time
                : config.workday.defaultStartWorkingHour,
              ["HH:mm", "H:mm"]
            ).format("hh:mm A")} - ${moment(
              params.row.work_schedules[focussedDate]?.finish_time &&
                params.row.work_schedules[focussedDate]?.finish_time !== ""
                ? params.row.work_schedules[focussedDate]?.finish_time
                : config.workday.defaultFinishWorkingHour,
              ["HH:mm", "H:mm"]
            ).format("hh:mm A")}`
          : "";
      },
    },
    {
      field: "remote_timezone",
      headerName: t("Local Time"),
      sortable: true,
      flex: 1,
      renderCell: (params) => {
        return `${t(moment(new Date()).tz(params.row.remote_timezone).format("ddd"))} ${moment(
          new Date()
        )
          .tz(params.row.remote_timezone)
          .format("h:mma")} (${moment().tz(params.row.remote_timezone).zoneAbbr()})`;
      },
    },
  ];

  const CustomColumnMenu = (props: any) => {
    const { hideMenu, currentColumn } = props;
    return (
      <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} open={false}>
        <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
      </GridColumnMenuContainer>
    );
  };

  const gridColumnsMobile: GridColDef[] = [
    {
      field: "name",
      headerName: t("Name"),
      sortable: false,
      flex: 1.5,
      disableColumnMenu: true,

      renderCell: (params: GridCellParams) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", lineHeight: "50px" }}>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                return onNameClick(params.row.user_id);
              }}
              underline="always"
              color="inherit"
            >
              {params.row.name}
            </Link>
          </div>
        );
      },
    },
    {
      field: "work_type",
      headerName: t("Work Type"),
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params) =>
        params.row.work_schedules
          ? `${
              params.row.work_schedules[focussedDate]
                ? getScheduleTypeString(params.row.work_schedules[focussedDate]?.status)
                : "Not Available"
            }`
          : "",
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params) => {
        return params.row.work_schedules ? (
          <div>
            {params.row.work_schedules[focussedDate]
              ? getScheduleTypeString(params.row.work_schedules[focussedDate]?.status)
              : t("Not Available")}
          </div>
        ) : (
          ""
        );
      },
    },
    {
      field: "Desk",
      headerName: t("Desk"),
      sortable: false,
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (params) =>
        params.row.work_schedules && params.row.work_schedules[focussedDate]
          ? params.row.work_schedules[focussedDate]?.upcoming_booking?.space
            ? params.row.work_schedules[focussedDate]?.upcoming_booking?.space.name
            : ""
          : "",
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params: GridCellParams) => {
        return (
          params.row.work_schedules && (
            <BookingsPopover
              focussedDate={focussedDate}
              spaceReference={
                params.row.work_schedules &&
                params.row.work_schedules[focussedDate]?.upcoming_booking
                  ? params.row.work_schedules[focussedDate].upcoming_booking.space.name
                  : null
              }
              serraViewId={
                params.row.work_schedules[focussedDate]?.upcoming_booking?.space.serraview_id ||
                null
              }
              bookings={params.row.work_schedules[focussedDate]?.multiple_bookings}
            />
          )
        );
      },
    },
    {
      field: "working_hours",
      headerName: t("Working Hours"),
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.work_schedules &&
          params.row.work_schedules[focussedDate]?.status !== ScheduleType.OFF
          ? `${moment(
              params.row.work_schedules[focussedDate]?.start_time &&
                params.row.work_schedules[focussedDate]?.start_time !== ""
                ? params.row.work_schedules[focussedDate]?.start_time
                : config.workday.defaultStartWorkingHour,
              ["HH:mm", "H:mm"]
            ).format("hh:mm A")} - ${moment(
              params.row.work_schedules[focussedDate]?.finish_time &&
                params.row.work_schedules[focussedDate]?.finish_time !== ""
                ? params.row.work_schedules[focussedDate]?.finish_time
                : config.workday.defaultFinishWorkingHour,
              ["HH:mm", "H:mm"]
            ).format("hh:mm A")}`
          : "";
      },
    },
    {
      field: "remote_timezone",
      headerName: t("Local Time"),
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return `${t(moment(new Date()).tz(params.row.remote_timezone).format("ddd"))} ${moment(
          new Date()
        )
          .tz(params.row.remote_timezone)
          .format("h:mma")} (${moment().tz(params.row.remote_timezone).zoneAbbr()})`;
      },
    },
  ];
  return (
    <>
      <Box py={1} px={{ xs: 2, md: 4 }} className={classes.root}>
        <Paper elevation={0}>
          {peopleSchedules?.length > 0 && (
            <>
              <Typography component={"span"} className={classes.recordsFoundLabel}>
                {peopleSchedules?.length}{" "}
                {peopleSchedules?.length > 1 ? t("Results for") : t("Result for")}:{" "}
                <Box
                  style={{ fontWeight: "normal", display: "inline" }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      superscriptDateFormat(
                        `${t(moment(focussedDate).format("Do"))} ${t(
                          moment(focussedDate).format("MMMM")
                        )}`
                      )
                    ),
                  }}
                />
              </Typography>
            </>
          )}
          {
            <Grid className={classes.dataGrid} container>
              <Grid item xs>
                <div
                  className={classes.spaceTable}
                  style={{
                    height:
                      peopleSchedules.length > 0
                        ? Math.min(peopleSchedules.length + 1, 20) * gridRowHeight + 80
                        : 200,
                  }}
                >
                  <DataGrid
                    rows={peopleSchedules}
                    getRowId={(r) => r.name}
                    columns={smAndUp ? gridColumns : gridColumnsMobile}
                    rowHeight={gridRowHeight}
                    pageSize={gridPageSize}
                    loading={teamStore.isLoading}
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                      ColumnSortedAscendingIcon: ExpandMore,
                      ColumnSortedDescendingIcon: ExpandLess,
                      ColumnUnsortedIcon: UnfoldMoreIcon,
                      ColumnMenu: CustomColumnMenu,
                    }}
                    disableSelectionOnClick
                    hideFooterRowCount
                    disableColumnMenu={false}
                    sortModel={sortModel}
                    onSortModelChange={(model) => {
                      handleSortChange(model);
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          }
        </Paper>
      </Box>
    </>
  );
});
export default TeamDetailsPeopleSearchGrid;
