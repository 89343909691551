import {
  Box,
  Tooltip,
  MenuItem,
  Menu,
  Grid,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { useRootStore } from "providers/RootStoreProvider";
import BuildTwoToneIcon from "@material-ui/icons/BuildTwoTone";
import React from "react";
import { bhpColor } from "styles/globals";
import CloseIcon from "@material-ui/icons/Close";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import HeaderImage from "assets/headerImage.png";
import { getOfficeServiceIframe } from "utils/locations";

interface ServiceContextMenuProps {
  t: any;
}

const useStyles = makeStyles({
  menu: {
    fontSize: 16,
    "& ul": {
      width: "max-content",
    },
    "& .MuiList-padding": {
      paddingTop: 0,
    },
    "& .MuiPaper-root": {
      border: `1px solid ${bhpColor.grey1}`,
      "& a": {
        color: bhpColor.orange1,
        textDecoration: "none",
        fontWeight: 700,
      },
    },
  },
  tooltip: {
    whiteSpace: "pre-line",
  },
  buildingSelector: {
    padding: 0,
    marginRight: "8px",
    "& .MuiSelect-root": {
      padding: "4px",
      paddingRight: "25px",
      width: "210px",
      fontSize: "14px",
    },
    "& .MuiSelect-iconOutlined": {
      right: 0,
    },
  },
  dialogTitle: {
    backgroundImage: `url(${HeaderImage})`,
    color: bhpColor.white,
    fontWeight: 700,
    paddingTop: "4px",
    paddingBottom: "4px",
    "& h2": {
      fontWeight: "bold",
      "& svg": {
        verticalAlign: "text-top",
      },
    },
  },
});

/**
 * Create a context menu for service request
 * @param props - t for internationalisation
 * @returns a service icon or context menu
 */
export const ServiceContextMenu = observer((props: ServiceContextMenuProps) => {
  const { t } = props;
  const store = useRootStore();
  const history = useHistory();

  const classes = useStyles();
  const [serviceMenuAnchorEl, setServiceMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const openServiceMenu = Boolean(serviceMenuAnchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setServiceMenuAnchorEl(event.currentTarget);
  };
  const handleServiceMenuClose = () => {
    setServiceMenuAnchorEl(null);
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          color: bhpColor.blueGrey2,
        }}
        onClick={handleMenuClick}
        key={`serviceMenu`}
      >
        {!openServiceMenu ? (
          <Tooltip title={t("Open Service Request Menu")}>
            <BuildTwoToneIcon
              color="secondary"
              style={{
                cursor: "help",
                display: "flex",
                fontSize: "20px",
                alignSelf: "center",
                marginLeft: "12px",
              }}
            />
          </Tooltip>
        ) : (
          <div style={{ width: 30 }}></div>
        )}
      </Box>
      <Menu
        id={`serviceRequest-menu`}
        open={openServiceMenu}
        onClose={handleServiceMenuClose}
        anchorEl={serviceMenuAnchorEl}
        className={`${classes.menu} waldo`}
        style={{ fontSize: "14px" }}
      >
        <Grid
          container
          className={classes.dialogTitle}
          key={`context-menu`}
          style={{
            display: "flex",
            paddingLeft: "20px",
          }}
        >
          <Grid item xs={11}>
            {t("Lodge a support request")}
          </Grid>
          <Grid item xs={1}>
            <Box
              onClick={handleServiceMenuClose}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <CloseIcon fontSize="small" />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ color: bhpColor.blueGrey1, paddingRight: "12px" }}>
          <Box>
            <ul>
              <li>
                <strong>{t("Technology request")}</strong>, {t("lodge a service request in")}{" "}
                <a
                  href="https://bhp.service-now.com/sp/?id=sc_cat_item&sys_id=4b63e79adb6a43004b46fc1dbf9619ec"
                  title={t("Open new window to lodge a technology service request")}
                  target="_blank"
                >
                  {t("ServiceNow")}
                </a>
              </li>
              {}
              <li>
                <strong>{t("Workplace request")}</strong>, {t("open a support request in")}{" "}
                <a
                  href={getOfficeServiceIframe()}
                  target="_blank"
                  title={t("Lodge a workplace service request")}
                >
                  {t("Property Assist")}
                </a>
              </li>
            </ul>
          </Box>
        </Grid>
      </Menu>
    </>
  );
});
