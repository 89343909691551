import { createTheme } from "@material-ui/core/styles";
import { bhpColor } from "./globals";

//Primary palette: the most widely used colors across all screens and components.
var custom_bhp_primary = {
  50: "#FEF3E1",
  100: "#FCE1B3",
  200: "#FBCE82",
  300: "#F9BA50",
  400: "#F9AB2B",
  500: "#F89D0C",
  600: "#F49108",
  700: "#EE8105",
  800: "#E87202",
  900: "#DF5900",
  // Simon: I added this one
  1000: "#E65400",
};

var custom_bhp_secondary = {
  50: "#FBFBFB",
  100: "#F6F6F6",
  200: "#F1F1F1",
  300: "#E4E4E4",
  400: "#C2C2C2",
  500: "#A3A3A3",
  600: "#7A7A7A",
  700: "#666666",
  800: "#464646",
  900: "#252525",
  // Simon: I added this
  1000: "#466371",
};

let themelight = createTheme({
  palette: {
    type: "light",
    primary: {
      main: custom_bhp_primary[1000],
    },
    secondary: {
      main: custom_bhp_secondary[1000],
    },
  },
  overrides: {
    MuiTab: {
      wrapper: {
        fontSize: "16px",
        textTransform: "capitalize",
        flexDirection: "row",
      },
    },
    MuiButton: {
      disableElevation: true,
      containedPrimary: {
        fontWeight: "bold",
        textTransform: "none",
      },
      containedSecondary: {
        fontWeight: "bold",
        textTransform: "none",
      },
      outlinedPrimary: {
        fontWeight: "bold",
        textTransform: "none",
      },
      outlinedSecondary: {
        fontWeight: "bold",
        textTransform: "none",
      },
      text: {
        fontWeight: "bold",
        textTransform: "none",
      },
    },
    MuiGrid: {
      root: {
        flexGrow: 1,
      },
    },
    MuiDialogTitle: {
      root: {
        color: bhpColor.secondary,
        fontSize: "22px",
        fontWeight: "bold",
      },
    },
    MuiTypography: {
      h1: {
        fontSize: "32px",
        fontWeight: "bold",
        color: bhpColor.blueGrey1,
      },
      h2: {
        fontSize: "20px",
        color: bhpColor.blueGrey2,
      },
      //h3 bigger than h2 - might want to fix this
      h3: {
        fontWeight: "bold",
        fontSize: "23px",
        color: bhpColor.blueGrey1,
      },
      h4: {
        fontSize: "20px",
        fontWeight: "bold",
        color: bhpColor.blueGrey1,
      },
      h5: {
        fontSize: "16px",
        fontWeight: "bold",
        color: bhpColor.blueGrey1,
      },
    },
  },
});

themelight.typography.h4 = {
  [themelight.breakpoints.up("xs")]: {
    fontSize: "16px",
  },
};
themelight.typography.h5 = {
  [themelight.breakpoints.up("xs")]: {
    fontSize: "12px",
  },
};

export default themelight;
