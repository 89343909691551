import { observer } from "mobx-react-lite";
import { bhpColor } from "styles/globals";
import { Fragment, useState } from "react";

import moment from "moment";
import { generateHolidayInformationModalContent } from "utils/date";
import {
  createStyles,
  Grid,
  Link,
  makeStyles,
  Popover,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useBuildingStore } from "providers/RootStoreProvider";
import { MultipleBooking } from "interfaces/Schedule";
import { cityAbbr } from "utils/locations";

interface BookingsPopoverProps {
  focussedDate: string;
  spaceReference: string;
  serraViewId: number;
  bookings: MultipleBooking[] | null;
}

interface DialogData {
  title: string | null;
  content: string | null;
  primaryButtonText: string | null;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
  })
);

const BookingsPopover = observer((props: BookingsPopoverProps) => {
  const { focussedDate, spaceReference, serraViewId, bookings } = props;
  const classes = innerStyles();
  const buildingStore = useBuildingStore();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openFloorPlan = (id: number | undefined, date: string | undefined) => {
    if (id) {
      buildingStore.openFloorPlan(true);
      buildingStore.setupFloorPlanFromSerraviewId(id, false, date);
    }
  };

  return (
    <>
      {smAndUp && (
        <div
          aria-haspopup="true"
          onMouseEnter={(event) => handlePopoverOpen(event)}
          onMouseLeave={handlePopoverClose}
          style={{ color: bhpColor.orange1 }}
        >
          <Link href="#" onClick={() => openFloorPlan(serraViewId, focussedDate)}>
            {spaceReference}
          </Link>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            className={classes.popover}
          >
            <div
              style={{
                position: "relative",
                border: `2px solid ${bhpColor.orange1}`,
                borderRadius: `4px`,
                backgroundColor: "white",
                minHeight: 100,
                width: 350,
                whiteSpace: "pre-line",
                fontSize: "15px",
                padding: 8,
              }}
            >
              <Grid
                container
                spacing={1}
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <Grid item xs={12}>
                  <div
                    style={{
                      paddingBottom: 8,
                      fontWeight: 700,
                      textAlign: "center",
                      color: bhpColor.blueGrey1,
                    }}
                  >
                    {bookings && bookings.length > 1 ? "Bookings" : "Booking"}
                  </div>
                </Grid>
                {bookings
                  ?.slice()
                  .sort((a, b) => {
                    return a.booking_details!.start_time_local < b.booking_details!.start_time_local
                      ? -1
                      : 1;
                  })
                  .map((booking) => {
                    return (
                      <Fragment key={booking.id}>
                        {booking?.building && (
                          <Grid item xs={2}>
                            <div
                              style={{
                                fontWeight: "bold",
                                color: bhpColor.blueGrey1,
                              }}
                            >
                              {booking?.building ? cityAbbr(booking?.building) : ""}
                            </div>
                          </Grid>
                        )}
                        <Grid item xs={3}>
                          <div
                            style={{
                              fontWeight: "bold",
                              color: bhpColor.orange1,
                            }}
                          >
                            {booking.space?.name}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div>
                            {moment(
                              booking.booking_details
                                ? booking.booking_details!.start_time_local
                                : booking.booking_period.start,
                              ["HH:mm", "H:mm"]
                            ).format("hh:mm A")}{" "}
                            -{" "}
                            {moment(
                              booking.booking_details
                                ? booking.booking_details!.end_time_local
                                : booking.booking_period.end,
                              ["HH:mm", "H:mm"]
                            ).format("hh:mm A")}
                          </div>
                        </Grid>
                      </Fragment>
                    );
                  })}
              </Grid>
            </div>
          </Popover>
        </div>
      )}
    </>
  );
});

export default BookingsPopover;
