import { makeStyles } from "@material-ui/core";
import { bhpColor } from "./globals";

const drawerWidth = 216;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  drawer: {
    top: "inherit !important",
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      visibility: "visible !important",
      transform: "unset !important",
      "& .MuiDrawer-paper": {
        visibility: "visible !important",
        transform: "unset !important",
      },
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  logo: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(2),
  },
  logoHorizontal: {
    height: 60,
    marginLeft: `-${theme.spacing(2)}px`,
    alignSelf: "center",
  },
  sectionMobileMenu: {
    display: "flex",
    backgroundColor: bhpColor.blueGrey4,
    padding: "0 16px",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  basicLink: {
    color: bhpColor.orange1,
    fontWeight: 700,
  },
  snackbarError: {
    "& .MuiSnackbarContent-root": {
      color: bhpColor.purple1,
      fontWeight: "bold",
      backgroundColor: bhpColor.purple4,
      border: `2px solid ${bhpColor.purple1}`,
      borderRadius: 4,
    },
  },
  hidden: {
    display: "none",
  },
  navLink: {
    "&:hover": {
      color: `${bhpColor.orange1} !important`,
    },
  },
}));

export {};
