import { Space } from "interfaces/Building";
import WcIcon from "@material-ui/icons/Wc";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import GamepadIcon from "@material-ui/icons/Gamepad";
import { bhpColor } from "styles/globals";
import {
  AddToQueue,
  Brightness1,
  EmojiFoodBeverage,
  ImportantDevices,
  LocalHospital,
  MeetingRoom,
  PregnantWoman,
  SelectAll,
  SupervisedUserCircle,
} from "@material-ui/icons";

export interface EnhancedSpace {
  id: number;
  parentId: number | null;
  name: string;
  typeId: number[];
  icon?: any;
  color?: string;
  size?: number;
  meta?: string;
}

export const OtherTypeDefaultConfiguration = {
  color: bhpColor.orange2,
  size: 26,
};

export const esOffset = {
  x: 0,
  y: -3,
};

export const deskTypeIds: number[] = [
  30, 11415, 11433, 11434, 11334, 11335, 11338, 11404, 11408, 11409, 11413, 11415, 11428, 11431,
  11435, 11438, 11439, 11446, 11445, 11414,
];

export const getSpaceExtension = (id: number | null | undefined) => {
  if (!id) return null;
  const thisItem = spaceExtension.find((ste) => ste.id === id);
  return thisItem || null;
};

export const spaceExtension: EnhancedSpace[] = [
  { id: 1, parentId: null, name: "Desks", typeId: [] },
  { id: 2, parentId: null, name: "Amenities", typeId: [] },
  { id: 3, parentId: null, name: "Toilets", typeId: [] },
  { id: 4, parentId: null, name: "Facilites", typeId: [] },
  { id: 5, parentId: null, name: "Meeting Rooms", typeId: [] },
  {
    id: 14,
    parentId: 1,
    name: "Desk",
    typeId: [30],
    icon: <Brightness1 style={{ color: bhpColor.available }} />,
  },
  {
    id: 15,
    parentId: 1,
    name: "Workstation - Linear",
    typeId: [11415],
    icon: <Brightness1 style={{ color: bhpColor.available }} />,
  },
  {
    id: 16,
    parentId: 1,
    name: "Workstation - Quiet Corral",
    typeId: [11433],
    icon: <Brightness1 style={{ color: bhpColor.available }} />,
  },
  {
    id: 17,
    parentId: 1,
    name: "Workstation - Concierge",
    typeId: [11434],
    icon: <Brightness1 style={{ color: bhpColor.available }} />,
  },
  {
    id: 18,
    parentId: 2,
    name: "Breakout",
    typeId: [21, 11379, 11442],
    icon: <EmojiFoodBeverage />,
  },
  {
    id: 19,
    parentId: 3,
    name: "Toilets",
    typeId: [3645, 3646, 11443, 11425],
    icon: <WcIcon style={{ color: bhpColor.availabilityAmber }} />,
    color: bhpColor.availabilityAmber,
    size: 40,
  },
  {
    id: 21,
    parentId: 3,
    name: "Toilets - Male",
    typeId: [3644, 11424],
    icon: <WcIcon style={{ color: bhpColor.availabilityAmber }} />,
  },
  {
    id: 22,
    parentId: 3,
    name: "Toilet - Ambulant",
    typeId: [11426],
    icon: <WcIcon style={{ color: bhpColor.availabilityAmber }} />,
  },
  {
    id: 23,
    parentId: 4,
    name: "Lift Lobby",
    typeId: [423],
    color: bhpColor.orange1,
    icon: <ExitToAppIcon style={{ transform: "rotate(-90deg)" }} />,
  },
  {
    id: 24,
    parentId: 4,
    name: "Change Rooms - Male",
    typeId: [11422],
    icon: <SupervisedUserCircle />,
  },
  {
    id: 25,
    parentId: 4,
    name: "Change Rooms - Female",
    typeId: [11423],
    icon: <SupervisedUserCircle />,
  },
  {
    id: 26,
    parentId: 4,
    name: "Change Rooms - All Gender",
    typeId: [11355],
    icon: <SupervisedUserCircle />,
  },
  {
    id: 27,
    parentId: 4,
    name: "Carers Room",
    typeId: [11322],
    icon: <PregnantWoman style={{ color: bhpColor.availabilityAmber }} />,
  },
  {
    id: 28,
    parentId: 4,
    name: "First Aid Room",
    typeId: [11344],
    icon: <LocalHospital style={{ color: bhpColor.availabilityRed }} />,
  },
  {
    id: 29,
    parentId: 4,
    name: "Wellness Room",
    typeId: [428],
    icon: <LocalHospital style={{ color: bhpColor.availabilityAmber }} />,
  },
  { id: 30, parentId: 4, name: "Testing Room", typeId: [435], icon: <AddToQueue /> },
  { id: 31, parentId: 4, name: "Tech Hub", typeId: [11363], icon: <ImportantDevices /> },
  { id: 32, parentId: 4, name: "Utility Bay", typeId: [29, 3651], icon: <SelectAll /> },
  {
    id: 33,
    parentId: 4,
    name: "Fire Stairs (Evacuation Stairs)",
    typeId: [11027],
    icon: <ExitToAppIcon />,
  },
  {
    id: 34,
    parentId: 5,
    name: "Meeting Rooms (Name, sub-function)",
    typeId: [11351, 11373, 11382, 11383, 11398],
    icon: <MeetingRoom />,
  },
  {
    id: 35,
    parentId: 4,
    name: "Calibration point",
    typeId: [-99],
    icon: <GamepadIcon style={{ height: 30, width: 30 }} />,
  },
];

export const getSpaceTypeDetail = (typeId: number | undefined) => {
  let typeDetail: EnhancedSpace = { id: 0, parentId: null, name: "Unknown", typeId: [0] };
  if (!typeId) return typeDetail;
  const thisSpaceTypeDetail = spaceExtension.filter((std) => std.typeId.includes(typeId));
  if (thisSpaceTypeDetail && thisSpaceTypeDetail.length > 0) typeDetail = thisSpaceTypeDetail[0];
  return typeDetail;
};

export const sampleSpaces: Space[] = [
  {
    id: 9999991,
    name: "Toilet",
    space_type: { id: 3646, name: "Unisex toilet" },
    coordinates: { x: 665, y: 420 },
    corrected_coordinates_x: 865,
    corrected_coordinates_y: 660,
  },
  {
    id: 9999992,
    name: "Lift Lobby",
    space_type: { id: 423, name: "Lift Lobby" },
    coordinates: { x: 475, y: 440 },
    corrected_coordinates_x: 475,
    corrected_coordinates_y: 450,
  },
  {
    id: 9999993,
    name: "Calibration Point",
    space_type: { id: -99, name: "Calibration point" },
    coordinates: { x: 475, y: 440 },
    corrected_coordinates_x: 10,
    corrected_coordinates_y: 10,
  },
];
