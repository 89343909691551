import {
  Box,
  Button,
  FormControl,
  Grid,
  Hidden,
  Paper,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import Typography from "@material-ui/core/Typography";
import { dashboardStyles } from "../../styles/dashboardStyles";

import {
  useBuildingStore,
  useScheduleStore,
  useTeamStore,
  useUserStore,
} from "providers/RootStoreProvider";
import YourScheduleWidget from "components/dashboard/yourSchedule/YourScheduleWidget";
import Topbar from "./Topbar";

import { useHistory } from "react-router-dom";
import WorkdaySummary from "components/dashboard/officeCapacity/WorkdaySummary";
import { bhpColor, settings } from "styles/globals";
import CalendarTabHeadRow from "components/dashboard/teams/CalendarTabHeadRow";
import TeamDistributionSummary from "components/dashboard/teams/TeamDistributionSummary";
import { BuildingTeamSchedule } from "interfaces/Schedule";
import { TeamWorkdaySchedule, WorkdaySchedule } from "interfaces/Teams";
import TeamOfficeSummary from "components/dashboard/teams/TeamOfficeSummary";
import { useTranslation } from "react-i18next";
import NoDeskBookedToday from "components/dashboard/dialogs/NoDeskBookedToday";
import TomorrowNearCapacity from "components/dashboard/dialogs/TomorrowNearCapacity";
import { nextDay } from "utils/date";
import { ConfirmDialog } from "components/utils/ConfirmDialog";

const Dashboard = observer((props: any) => {
  const { t, i18n } = useTranslation();
  const classes = dashboardStyles();
  const history = useHistory();
  const scheduleStore = useScheduleStore();
  const buildingStore = useBuildingStore();
  const userStore = useUserStore();
  const teamStore = useTeamStore();
  const xsAndUp = useMediaQuery(useTheme().breakpoints.up("xs"));
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));
  const smAndDown = useMediaQuery(useTheme().breakpoints.down("sm"));
  const [showFeedbackPopup, setShowFeedbackPopup] = useState<boolean>(false);

  const me = userStore.me;

  const jumpToSchedule = () => {
    history.push("/my-planner");
  };

  const jumpToTeamPage = () => {
    // todo: fix this when the drop down gets chosen.
    history.push(`/teams/planner/${focussedTeamId!}`);
    // this has issues with delays.
    // if (teamStore.myTeam) {
    //   history.push(`/teams/${teamStore.myTeam.id}`)
    // }
  };

  const dateFormat = "YYYY-MM-DD";
  const todayDate = new Date();
  const displayDate = (dte: string | Date) => ({
    date: moment(dte).format("Do of MMMM"),
    day: moment(dte).format("dddd"),
  });

  const officeDaysToLoad = 7;
  const theDate = moment(todayDate).format(dateFormat);
  const [chosenDate, setChosenDate] = useState(moment(new Date()).format(dateFormat));
  const theDates: string[] = Array.from(Array(officeDaysToLoad).keys()).map((offset) => {
    return moment(todayDate).add(offset, "days").format(dateFormat);
  });

  const changeDate = (dte: string) => {
    setChosenDate(dte);
  };

  const [focussedTeamId, setFocussedTeamId] = useState<number | null>(null);
  const changeTeam = (teamId: string) => {
    localStorage.setItem("selectedDashboardTeamId", teamId.toString());
    loadTeam(parseInt(teamId));
  };

  const handleNoFeedbackPopup = () => {
    setShowFeedbackPopup(false);
    localStorage.setItem("showFeedbackPopup", "false");
  };

  const handleYesFeedbackPopup = () => {
    setShowFeedbackPopup(false);
    localStorage.setItem("showFeedbackPopup", "false");
    history.push("/feedback/1");
  };

  const loadTeam = async (teamId: number) => {
    teamStore.setMyTeam(teamId);
    setFocussedTeamId(teamId);
    await buildingStore.loadTeamBuildings(teamId);
    const buildingIds = buildingStore.teamLocations.map((tb) => tb.id);
    buildingStore.loadTeamBuildingSchedules(
      buildingIds,
      userStore.me?.profile.team || 0,
      chosenDate,
      chosenDate
    );
    teamStore.loadTeamSchedulePeopleList(
      teamId,
      theDate,
      moment(theDate).add(officeDaysToLoad, "days").format(settings.momentDateFormat)
    );
    teamStore.loadTeamWorkdaySchedule(
      teamId,
      theDate,
      moment(theDate).add(officeDaysToLoad, "days").format(settings.momentDateFormat)
    );
  };

  const getScheduleForDate = (theDate: string) => {
    const todaySchedule = scheduleStore.scheduleForDay(theDate);
    if (todaySchedule.space && !todaySchedule.office)
      todaySchedule.office = todaySchedule.space.building
        ? buildingStore.getBuilding(todaySchedule.space.building?.id)
        : null;
    return todaySchedule;
  };

  const getOrdinal = (dte: Moment) => {
    return dte.format("Do").slice(-2);
  };
  const supDate = (date: Moment) => {
    return (
      <>
        {date.format("D")}
        <sup style={{ fontSize: "small" }}>{t(getOrdinal(date))}</sup> {t("of")}{" "}
        {t(date.format("MMMM"))}
      </>
    );
  };

  const hasTeam = userStore.hasTeam();

  const [teamWorkdaySchedule, setTeamWorkdaySchedule] = useState<TeamWorkdaySchedule | null>(null);
  const [workdaySchedule, setWorkdaySchedule] = useState<WorkdaySchedule | null>(null);
  const [teamBuildingSchedules, setTeamBuildingSchedules] = useState<
    { buildingId: number; teamBuildingSchedule: BuildingTeamSchedule[] }[]
  >([]);

  const viewingTeamTitleAndButton = () => {
    return (
      <Box width={"100%"} style={{ marginTop: 10 }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography style={smAndUp ? {} : { fontSize: 18 }}>{t("Viewing")}</Typography>
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
            <Typography style={smAndUp ? {} : { fontSize: 18 }}>
              {teamStore.myTeams.find((team) => team.id === focussedTeamId)?.name}
              {smAndUp ? ", " : ""}
              <span className={classes.unbold} style={smAndUp ? {} : { display: "block" }}>
                {supDate(moment(chosenDate))}
              </span>
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: "right" }}>
            <Button className={classes.orangeButton} variant="outlined" onClick={jumpToTeamPage}>
              {t("View Team Page")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  useEffect(() => {
    //Check if start of month then set the flag to show the popup as true
    if (moment(new Date()).isSame(moment().startOf("month"))) {
      localStorage.setItem("showFeedbackPopup", "true");
    }

    /*Check if there is not a local storage flag for feedback popup
    then save one with flag as true
    */
    if (!localStorage.getItem("showFeedbackPopup")) {
      localStorage.setItem("showFeedbackPopup", "true");
      setShowFeedbackPopup(false);
    } else {
      /*Check if it is last day of month and the feedback flag is not
      already set to false (i.e. is still true), then show the popup. */
      if (
        moment(new Date()).isSame(moment().endOf("month")) &&
        localStorage.getItem("showFeedbackPopup") === "true"
      ) {
        setShowFeedbackPopup(true);
      } else {
        setShowFeedbackPopup(false);
      }
    }
  }, []);

  const [showHourly, setShowHourly] = useState<boolean>(false);
  const toggleShowHourly = () => {
    setShowHourly(showHourly ? false : true);
  };

  useEffect(() => {
    async function fetchData() {
      buildingStore.loadBuildingsWithCapacity(theDate);
      scheduleStore.loadSchedules();
      await teamStore.loadMyTeams();

      /**Check local storage for saved team id */
      const savedTeamId = Number(localStorage.getItem("selectedDashboardTeamId"));

      /**Check if Saved Team still exists */
      const savedTeamExist: boolean = teamStore.myTeams.some((team) => team.id === savedTeamId);

      /**If a team was saved and currently exist then set current team */
      const currentTeamId =
        savedTeamId && savedTeamExist ? savedTeamId : userStore.me?.profile.team;

      /**Check no teamId saved then save the current team id */
      if (!savedTeamId && currentTeamId) {
        localStorage.setItem("selectedDashboardTeamId", currentTeamId.toString());
      }

      if (currentTeamId) {
        teamStore.loadMyTeam(currentTeamId);
        const team = currentTeamId;
        setFocussedTeamId(team);
        teamStore.setMyTeam(team);
        await buildingStore.loadTeamBuildings(team);
        const buildingIds = buildingStore.teamLocations.map((tb) => tb.id);
        buildingStore.loadTeamBuildingSchedules(buildingIds, team, chosenDate, chosenDate);
        teamStore.loadTeamSchedulePeopleList(
          currentTeamId,
          theDate,
          moment(theDate).add(officeDaysToLoad, "days").format(settings.momentDateFormat)
        );

        teamStore.loadTeamWorkdaySchedule(
          currentTeamId,
          theDate,
          moment(theDate).add(7, "days").format(settings.momentDateFormat)
        );
      }
    }
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const welcomeTitle = me ? `${t("Welcome")}, ${userStore.getName()}` : t("Welcome");
  const todaySchedule = getScheduleForDate(theDate);
  const tomorrowSchedule = getScheduleForDate(moment(nextDay(theDate)).format("YYYY-MM-DD"));

  return (
    <>
      <ConfirmDialog
        isOpen={showFeedbackPopup}
        title={t("We would love your feedback!")}
        primaryButtonText={t("Yes")}
        secondaryButtonText={t("No")}
        handleSecondaryClick={handleNoFeedbackPopup}
        handlePrimaryClick={handleYesFeedbackPopup}
        maxWidth="sm"
      >
        <strong>Would you like to provide feedback on the My Workday platform?</strong>
      </ConfirmDialog>
      <Topbar
        title={welcomeTitle}
        subtitle={t("dashboard.subheading")}
        breadcrumb={`${t("Dashboard")} > ${userStore.getName()}`}
      />
      <NoDeskBookedToday schedule={todaySchedule}></NoDeskBookedToday>
      <TomorrowNearCapacity schedule={tomorrowSchedule}></TomorrowNearCapacity>
      <Box className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Typography style={smAndUp ? {} : { fontSize: 18 }}>
                  {t("My planned location today")}
                </Typography>
                <Typography variant="h3">
                  {t(displayDate(todayDate).day)},{" "}
                  <span className={classes.unbold}>{supDate(moment(todayDate))}</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                style={{ display: "flex", justifyContent: "end", alignItems: "end" }}
              >
                <Button
                  className={classes.orangeButton}
                  style={{ height: "36px", marginBottom: "-9px", marginTop: "10px" }}
                  variant="outlined"
                  onClick={() => toggleShowHourly()}
                >
                  {t("Desk Availability")}
                </Button>
              </Grid>
            </Grid>
            <Paper elevation={0} className={classes.paper}>
              <WorkdaySummary
                t={t}
                buildingDetail={
                  userStore.me?.profile.default_office || todaySchedule.office
                    ? buildingStore.getBuilding(
                        todaySchedule.office
                          ? todaySchedule.office.id
                          : userStore.me?.profile.default_office
                          ? userStore.me.profile.default_office
                          : 0
                      )
                    : null
                }
                userSchedule={todaySchedule}
                showOfficeCapacityWidget={true}
                showOfficeCapacityHourly={showHourly}
                userId={me!.id}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6} className={classes.yourScheduleGrid}>
            <Grid container>
              <Grid item xs={12}>
                {t("Upcoming week")}
              </Grid>
              <Grid
                item
                style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}
              >
                <Typography variant="h3">{t("My Planner")}</Typography>
              </Grid>
              <Grid
                item
                style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
              >
                <Button
                  className={classes.orangeButton}
                  variant="outlined"
                  onClick={jumpToSchedule}
                >
                  {t("My Full Planner")}
                </Button>
              </Grid>
            </Grid>
            <YourScheduleWidget
              t={t}
              startDate={theDate}
              selectedDate={smAndDown ? chosenDate : undefined}
              changeDate={smAndDown ? changeDate : undefined}
              altTopMargin={15}
              altRowHeight={118}
            />
            <Hidden mdUp>{viewingTeamTitleAndButton()}</Hidden>
          </Grid>
        </Grid>
      </Box>
      <Box
        padding={{ xs: 1, md: 4 }}
        paddingTop={0}
        style={mdAndUp ? {} : { backgroundColor: "white" }}
      >
        {/* Team Widget area */}
        {hasTeam && (
          <Grid container>
            <Hidden smDown>{viewingTeamTitleAndButton()}</Hidden>
            <Grid item xs={12}>
              <Box
                style={
                  mdAndUp
                    ? {
                        borderRadius: 4,
                        backgroundColor: bhpColor.backgroundGrey,
                        border: `1px solid ${bhpColor.blueGrey4}`,
                      }
                    : {}
                }
                marginTop={2}
              >
                <Grid container alignContent={"center"}>
                  <Hidden smDown>
                    <Grid item xs={12} lg={6}>
                      <CalendarTabHeadRow
                        t={t}
                        dateRange={theDates}
                        changeDate={changeDate}
                        selectedDate={chosenDate}
                      />
                    </Grid>
                  </Hidden>
                  <Grid item xs={12} lg={6} style={{ display: "flex" }}>
                    <Grid container alignContent={"center"}>
                      <Grid item xs={12} sm={6} className={classes.teamSelectionGrid}>
                        <Box
                          style={{ fontSize: 14, color: bhpColor.blueGrey1 }}
                          px={{ xs: 2, lg: 2 }}
                        >
                          {t("Select Team")}:
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box pr={2} pl={{ xs: 2, sm: 2, md: 0 }}>
                          <FormControl variant="filled" size="small" fullWidth>
                            <Select
                              labelId="teamsSelectLabel"
                              id="teamsSelectId"
                              value={focussedTeamId}
                              onChange={(event) => changeTeam(event.target.value as string)}
                              style={{ backgroundColor: "white" }}
                              variant="outlined"
                              fullWidth
                            >
                              {teamStore.myTeams
                                ? teamStore.myTeams.map((team) => {
                                    return (
                                      <MenuItem value={team.id} key={team.id}>
                                        {team.name}
                                      </MenuItem>
                                    );
                                  })
                                : ""}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box
                p={2}
                style={{
                  backgroundColor: "white",
                  borderBottomRightRadius: 4,
                  borderBottomLeftRadius: 4,
                }}
              >
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <Box mt={{ sm: 3, md: 0 }}>
                      <Typography>{t("Team Distribution")}</Typography>
                      <TeamDistributionSummary
                        t={t}
                        workdaySchedule={teamStore.getTeamWorkdaySchedule(chosenDate)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box mt={{ xs: 3, md: 0 }}>
                      <Typography>{t("Offices")}</Typography>
                      <TeamOfficeSummary
                        t={t}
                        selectedDate={chosenDate}
                        teamSchedulePeopleList={teamStore.teamSchedulePeopleList}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      {/* End Team Widget Area */}
    </>
  );
});

export default Dashboard;
