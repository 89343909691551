import { makeStyles, MenuItem, Select, Theme, createStyles } from "@material-ui/core";
import { useEffect, useState } from "react";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectHeight: {
      "& .MuiOutlinedInput-input": {
        padding: `10px 25px 10px 8px`,
      },
    },
  })
);
interface TimeWidgetProps {
  handleCompareTimeSelection: any;
}

export const TimeWidget = (props: TimeWidgetProps) => {
  const { handleCompareTimeSelection } = props;
  const classes = innerStyles();
  const [hour, setHour] = useState<any>("");
  const [minute, setMinute] = useState<any>("");
  const [ampm, setAmPm] = useState<string>("AM");

  const handleHourChange = (hour: any) => {
    setHour(hour);
  };

  const handleMinuteChange = (minute: any) => {
    setMinute(minute);
  };

  const handleAMPMChange = (ampm: any) => {
    setAmPm(ampm);
  };

  useEffect(() => {
    handleCompareTimeSelection(hour, minute, ampm);
  }, [hour, minute, ampm]);

  return (
    <>
      <p style={{ lineHeight: 0, fontWeight: "bold", paddingLeft: 4 }}>Enter time to compare</p>
      <div style={{ display: "flex" }} className={classes.selectHeight}>
        <div style={{ paddingLeft: 4, paddingRight: 4 }}>
          <Select
            labelId="hourSelect"
            id="hour-select"
            value={hour}
            label="Hour"
            variant={"outlined"}
            autoWidth={true}
            displayEmpty={true}
            onChange={(event) => handleHourChange(event.target.value)}
          >
            <MenuItem value={""}>--</MenuItem>
            {Array.from(Array(12).keys()).map((key) => {
              return (
                <MenuItem key={key} value={key + 1}>
                  {key < 9 ? `0${key + 1}` : key + 1}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div
          style={{
            paddingLeft: 4,
            paddingRight: 4,
            alignSelf: "center",
            fontWeight: "bold",
            fontSize: 16,
          }}
        >
          :
        </div>
        <div style={{ paddingLeft: 4, paddingRight: 4 }}>
          <Select
            labelId="minute-select"
            id="minute-select"
            value={minute}
            label="Minute"
            variant={"outlined"}
            autoWidth={true}
            displayEmpty={true}
            onChange={(event) => handleMinuteChange(event.target.value)}
          >
            <MenuItem value={""}>--</MenuItem>
            {Array.from(Array(12).keys()).map((key) => {
              let counter = 5;
              return (
                <MenuItem value={key * counter} key={`minute${key}`}>
                  {key < 2 ? `0${key * counter}` : key * counter}
                </MenuItem>
              );
              counter += 5;
            })}
          </Select>
        </div>
        <div style={{ paddingLeft: 12 }}>
          <Select
            labelId="ampm-select"
            id="ampm-select"
            value={ampm}
            variant={"outlined"}
            autoWidth={true}
            displayEmpty={true}
            onChange={(event) => handleAMPMChange(event.target.value)}
          >
            <MenuItem value={"AM"}>AM</MenuItem>
            <MenuItem value={"PM"}>PM</MenuItem>
          </Select>
        </div>
      </div>
    </>
  );
};
