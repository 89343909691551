import { Box, Grid, Select, Slider, Tooltip } from "@material-ui/core";
import { FloorplanSpecs, Space } from "interfaces/Building";
import { bhpColor } from "styles/globals";
import {
  BasicBooking,
  bookingPeriodStatus,
  BOOKING_DURATION_MIN,
  BOOKING_GAP,
  getBookingsAtThisTime,
  getSortedBookings,
  isFireWardenBooked,
  isFirstAidOfficerBooked,
  Period,
} from "utils/hourlyBookings";
import {
  clickedImageColour,
  DeskColour,
  newImageColour,
  spot_available,
  spot_focussed,
  spot_officer,
  spot_partly,
  spot_unavailable,
} from "./Constants";
import { bookingStyles } from "styles/bookingStyles";
import moment from "moment";
import { toJS } from "mobx";
import {
  deskTypeIds,
  EnhancedSpace,
  getSpaceTypeDetail,
  OtherTypeDefaultConfiguration,
} from "../enhancedFloorplan/OtherTypes";

export interface Scale {
  scale: { x: number; y: number };
  offset: { x: number; y: number };
}

export const colorShift = (imageData: any, tolerance: number, down: boolean = true) => {
  const data = imageData.data;
  for (var i = 0; i < data.length; i += 4) {
    var red = data[i];
    var green = data[i + 1];
    var blue = data[i + 2];
    if (down) {
      if (red < tolerance && green < tolerance && blue < tolerance) {
        data[i] = newImageColour.r;
        data[i + 1] = newImageColour.g;
        data[i + 2] = newImageColour.b;
      }
    } else {
      if (red > tolerance && green > tolerance && blue > tolerance) {
        data[i] = clickedImageColour.r;
        data[i + 1] = clickedImageColour.g;
        data[i + 2] = clickedImageColour.b;
      }
    }
  }
  return imageData;
};

export const drawCross = (ctx: any, x: number, y: number, colour: string, size: number = 36) => {
  const displayText = "+";
  const fontSize = size;
  const lineHeight = 10;
  const padding = 6;

  const xPos = x;
  const yPos = y;

  ctx.font = `bold ${fontSize}px Arial`;
  ctx.textBaseline = "top";
  ctx.textAlign = "left";
  ctx.fillStyle = colour;
  ctx.fillText(displayText, xPos + padding / 2, yPos + lineHeight / 2);
};

export const drawLine = (
  ctx: any,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  lineWidth: number | null,
  lineColour: string | null,
  lineStyle: string | "solid" | "dashed" = "solid"
) => {
  ctx.beginPath();
  ctx.lineWidth = lineWidth || 1;
  if (lineStyle == "dashed") ctx.setLineDash([5, 3]);
  ctx.strokeStyle = lineColour || "#000000";
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.stroke();
  ctx.setLineDash([]);
};

export const drawStar = (
  ctx: any,
  x: number,
  y: number,
  outerRadius: number,
  numberOfSpikes: number,
  insetFactor: number,
  color: string = "rgb(255,255,255)"
) => {
  ctx.save();
  ctx.beginPath();
  ctx.translate(x, y);
  ctx.fillStyle = color;
  ctx.strokeStyle = bhpColor.blueGrey1;
  ctx.lineWidth = 1;
  ctx.moveTo(0, 0 - outerRadius);
  for (var i = 0; i < numberOfSpikes; i++) {
    ctx.rotate(Math.PI / numberOfSpikes);
    ctx.lineTo(0, 0 - outerRadius * insetFactor);
    ctx.rotate(Math.PI / numberOfSpikes);
    ctx.lineTo(0, 0 - outerRadius);
  }
  ctx.closePath();
  ctx.fill();
  ctx.restore();
};

export const drawHoverDot = (
  ctx: any,
  xPos: number,
  yPos: number,
  color: string,
  correctedRadius: number
) => {
  const shadowColor = "rgb(0, 0, 0)";
  etchCircle(ctx, xPos, yPos, correctedRadius, 2, 0.8, shadowColor, color);
};

/** Draw a circle - solid or outlined
 * @param ctx
 * @param xPos
 * @param yPos
 * @param radius
 * @param lineWidth - width of outline
 * @param globalAlpha - transparency 0 see through -> 1 solid
 * @param strokeColor - if null no outline
 * @param fillColor - if null no fill
 */
export const etchCircle = (
  ctx: any,
  xPos: number,
  yPos: number,
  radius: number,
  lineWidth: number | null,
  globalAlpha: number | null,
  strokeColor: string | null,
  fillColor: string | null
) => {
  if (ctx) {
    if (globalAlpha) ctx.globalAlpha = globalAlpha;
    if (lineWidth) ctx.lineWidth = lineWidth;
    ctx.strokeStyle = strokeColor;
    if (strokeColor) {
      ctx.beginPath();
      ctx.moveTo(xPos + radius, yPos);
      ctx.strokeStyle = strokeColor;
      ctx.arc(xPos, yPos, radius, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.closePath();
    }
    if (fillColor) {
      ctx.beginPath();
      ctx.moveTo(xPos + radius, yPos);
      ctx.fillStyle = fillColor;
      ctx.arc(xPos, yPos, radius, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();
    }
    if (globalAlpha) ctx.globalAlpha = 1;
  }
};

/** Modal position can't overlap top or right of image
 * also will move if team information exists
 * and if desk properties length too long
 * @param clickPos - the center of te space clicked
 * @param spc  - the found space
 * @returns x,y to locate the box
 */
export const getBoxPosition = (
  clickPos: { x: number; y: number; id?: number },
  spc: Space,
  infoBoxRef: any,
  box: { width: number; height: number },
  canvasWidth: number
) => {
  const yPos = clickPos.y;
  const boxPosition = {
    x: clickPos.x + box.width > canvasWidth ? clickPos.x - box.width : clickPos.x,
    y: yPos < box.height ? box.height + yPos : yPos,
  };
  return boxPosition;
};

export const drawSpot = (
  ctx: any,
  spc: { x: number; y: number; id?: number },
  color: string = "rgb(200, 0, 0)",
  clickedSpace: Space | null,
  outerColor: string | null,
  correctedRadius: number,
  focussed: boolean,
  favourite: boolean,
  spaceTypeId?: number
) => {
  const xPos = spc.x;
  const yPos = spc.y;
  const strokeColor = outerColor
    ? outerColor
    : clickedSpace && `${spc.id}` === `${clickedSpace.id}`
    ? bhpColor.orange1
    : null;

  etchCircle(ctx, xPos, yPos, correctedRadius, 5, null, strokeColor, color);

  if (focussed) drawFocus(ctx, xPos, yPos, "rgb(255,0,0)", correctedRadius, 5);
  if (favourite) drawStar(ctx, xPos, yPos, correctedRadius - 3, 5, 0.5);
};

export const outputIcon = (icon: any) => {
  //console.log("icon: ", icon);
  return icon;
};

export const drawIcon = (
  xpos: number | undefined,
  ypos: number | undefined,
  details: EnhancedSpace,
  doClick: any,
  spc?: Space
) => {
  if (xpos && ypos) {
    const size = details.size;
    const color = details.color;
    const icon = details.icon;

    const corrected_X_Pos = xpos; //size ? xpos - size / 2 : xpos - OtherTypeDefaultConfiguration.size / 2;
    const corrected_Y_Pos = ypos; //size ? ypos + size / 2 : ypos + OtherTypeDefaultConfiguration.size / 2;
    return (
      <Box
        style={{
          position: "absolute",
          cursor: "pointer",
          left: `${corrected_X_Pos}px`,
          top: `${corrected_Y_Pos}px`,
          padding: "6px",
          backgroundColor: "#FFFFFF55",
        }}
      >
        <Tooltip title={details.name}>
          <Box
            onClick={(evt) => doClick(evt, spc)}
            style={{
              height: OtherTypeDefaultConfiguration.size,
              color: color || OtherTypeDefaultConfiguration.color,
              backgroundColor: "#FFFFFFCA",
            }}
          >
            {icon}
          </Box>
        </Tooltip>
      </Box>
    );
  }
};

export const drawFocus = (
  ctx: any,
  xPos: number,
  yPos: number,
  color: string,
  initialRadius: number,
  ringSpace: number
) => {
  let strokeColor = color || bhpColor.orange1;
  const drawRings = () => {
    const rings = [1, 100, 200, 300];
    rings.map((thisRing, idx) => {
      const theRadius = initialRadius + ringSpace * idx;
      const ring = { x: xPos, y: yPos };
      etchCircle(ctx, ring.x, ring.y, theRadius, 1, null, strokeColor, null);
    });
  };
  drawRings();
};

/** adds the given text to the canvas
 * @param ctx - canvas context
 * @param text - the text to display
 * @param x - canvas X
 * @param y - canvas Y
 * @param rotated - if true 45 degree labels
 * @param centered - if true center based on text width
 * @param textColor - default black
 * @param textBackgroundColor - default white
 * @param textBackgroundBorderColor - default black
 */
export const drawLabel = (
  ctx: any,
  text: string,
  x: number,
  y: number,
  rotated: boolean | null,
  centered: boolean | null,
  textColor: string | null,
  textBackgroundColor: string | null,
  textBackgroundBorderColor: string | null
) => {
  const isRotated = rotated || false;
  const isCentered = centered || false;
  const txtColor = textColor ? textColor : "rgb{0,0,0)";
  const txtBackgroundColor = textBackgroundColor ? textBackgroundColor : "rgb(255,255,255)";
  const txtBackgroundBorderColor = textBackgroundBorderColor
    ? textBackgroundBorderColor
    : "rgb(0,0,0)";
  const displayText = text.replace(/\./g, "");

  const fontSize = 12;
  const lineHeight = 10;
  const padding = 6;

  ctx.font = `${fontSize}px Arial`;
  const lines = displayText.indexOf("\n") ? displayText.split("\n") : [displayText];
  let longestText = "";
  lines.map((text) => {
    if (text.length > longestText.length) longestText = text;
  });
  var width = ctx.measureText(longestText).width + padding; /// width in pixels
  const xPos = isRotated ? 0 : isCentered ? x - width / 2 : x;
  const yPos = isRotated
    ? 3
    : y + (lines.length > 1 ? lines.length - 1 * 2 * lineHeight : lineHeight) + padding / 2;

  ctx.textBaseline = "top";
  ctx.textAlign = "left";
  if (isRotated) {
    ctx.save();
    // rotate the canvas 45 degrees
    ctx.translate(x, y);
    ctx.rotate(-Math.PI / 4);
  }
  // draw background rectangle for text
  ctx.fillStyle = txtBackgroundColor;
  ctx.fillRect(xPos, yPos, width, fontSize * lines.length + padding);
  ctx.lineWidth = 1;
  ctx.strokeStyle = txtBackgroundBorderColor;
  ctx.strokeRect(xPos, yPos, width, fontSize * lines.length + padding);
  // draw text
  ctx.fillStyle = txtColor;
  if (lines.length === 1) {
    ctx.fillText(lines[0], xPos + padding / 2, yPos + lineHeight / 2);
  } else {
    let ypos = yPos + padding / 2;
    for (var i = 0; i < lines.length; i++) {
      ctx.fillText(lines[i], xPos + padding / 2, ypos);
      ypos = ypos + lineHeight + padding / 2;
    }
  }
  if (isRotated) {
    // restore canvas back to horizontal
    ctx.restore();
  }
};

export const getScales = (floorId: string, floorplanSpecs: FloorplanSpecs) => {
  let xscale =
    floorplanSpecs.floor_specific &&
    floorplanSpecs.floor_specific[floorId] &&
    floorplanSpecs.floor_specific[floorId].scale
      ? floorplanSpecs.floor_specific[floorId]!.scale!.x
      : floorplanSpecs.scale.x;
  const xoffset =
    floorplanSpecs.floor_specific &&
    floorplanSpecs.floor_specific[floorId] &&
    floorplanSpecs.floor_specific[floorId].offset
      ? floorplanSpecs.floor_specific[floorId]!.offset!.x
      : floorplanSpecs.offset.x;
  const yscale =
    floorplanSpecs.floor_specific &&
    floorplanSpecs.floor_specific[floorId] &&
    floorplanSpecs.floor_specific[floorId].scale
      ? floorplanSpecs.floor_specific[floorId]!.scale!.y
      : floorplanSpecs.scale.y;
  const yoffset =
    floorplanSpecs.floor_specific &&
    floorplanSpecs.floor_specific[floorId] &&
    floorplanSpecs.floor_specific[floorId].offset
      ? floorplanSpecs.floor_specific[floorId]!.offset!.y
      : floorplanSpecs.offset.y;
  const spaceRadius =
    floorplanSpecs.floor_specific &&
    floorplanSpecs.floor_specific[floorId] &&
    floorplanSpecs.floor_specific[floorId].spaceRadius
      ? floorplanSpecs.floor_specific[floorId]!.spaceRadius
      : floorplanSpecs.spaceRadius;
  return {
    scale: { x: xscale, y: yscale },
    offset: { x: xoffset, y: yoffset },
    spaceRadius: spaceRadius,
  };
};

export const scaledX = (x: number, theScales: Scale) => x * theScales.scale.x + theScales.offset.x;
export const scaledY = (y: number, theScales: Scale) => y * theScales.scale.y + theScales.offset.y;
export const correctCoordinates = (spc: { x: number; y: number; id?: number }, scales: Scale) => {
  return { x: scaledX(spc.x, scales), y: scaledY(spc.y, scales), id: spc.id };
};

export const getColor = (
  spc: Space,
  ignoreClick: boolean = false,
  periodStatus: string | null,
  isFocussed: boolean,
  floorbookings: BasicBooking[],
  deskHours: Period | undefined,
  gap: number = BOOKING_GAP,
  min_duration: number = BOOKING_DURATION_MIN,
  myId: number | undefined,
  isWayFinder: boolean | undefined,
  dayPeriod: Period | undefined
) => {
  const comparisonHours = isWayFinder ? dayPeriod : deskHours;
  const timezoneToUse =
    floorbookings.length > 0 ? floorbookings[0].building?.building_timezone : null;
  if (isWayFinder && comparisonHours) {
    //return available or not
    const bookingsForThisSpace = floorbookings.filter((booking) => booking.space?.id === spc.id);
    if (!bookingsForThisSpace || bookingsForThisSpace.length == 0) return spot_available;

    const bookingsAtThisTime = bookingsForThisSpace.filter((booking) => {
      const bkgStart = booking.booking_period.start;
      const bkgEnd = booking.booking_period.end;
      const isBooked =
        (bkgStart < comparisonHours.start && bkgEnd >= comparisonHours.end) ||
        (bkgStart >= comparisonHours.start && bkgStart < comparisonHours.end) ||
        (bkgEnd > comparisonHours.start && bkgEnd < comparisonHours.end);
      return isBooked;
    });
    if (bookingsAtThisTime && bookingsAtThisTime.length > 0) {
      const myBooking = bookingsAtThisTime.filter((bkg) => bkg.user_id === myId);
      if (myBooking && myBooking.length > 0) return spot_focussed;
      return spot_unavailable;
    }
    return spot_available;
  }

  const relevantBookings =
    isWayFinder && dayPeriod
      ? getBookingsAtThisTime(floorbookings, dayPeriod, isWayFinder ? true : false)
      : floorbookings;

  const bookingStatus = periodStatus
    ? periodStatus
    : bookingPeriodStatus(
        relevantBookings,
        comparisonHours,
        spc.id,
        true,
        true,
        true,
        gap,
        min_duration
      );

  const spaceBookings = getSortedBookings(
    isWayFinder
      ? relevantBookings.filter((bkg) => bkg.space && bkg.space.id === spc.id)
      : relevantBookings.filter(
          (bkg) => bkg.space && bkg.space.id === spc.id && bkg.booking_period.end > new Date()
        )
  ).slice();
  const is_partBooked = bookingStatus !== "000";
  const mySeat = myId ? spaceBookings.find((bkg) => bkg.user_id === myId) : null;
  const isMySeat = mySeat ? true : false;
  const unavailable = isWayFinder && is_partBooked ? true : bookingStatus === "111";

  const firstAidOfficerBooked = isFirstAidOfficerBooked(relevantBookings, spc.id);
  const fireWardenBooked = isFireWardenBooked(relevantBookings, spc.id);
  const isOfficer = firstAidOfficerBooked || fireWardenBooked;

  if (is_partBooked && !unavailable) return spot_partly;
  if (isWayFinder && isMySeat) return spot_focussed;
  if (isMySeat && !ignoreClick) return spot_focussed; // && !unavailable

  if (isOfficer) {
    return unavailable ? spot_unavailable : spot_officer;
  } else {
    return unavailable
      ? spot_unavailable
      : ignoreClick
      ? is_partBooked
        ? spot_partly
        : spot_available
      : is_partBooked
      ? spot_partly
      : spot_available;
  }
};

export const getColorRGB = (color: DeskColour) => {
  return `rgb(${color.r},${color.g},${color.b})`;
};

export const getNumberSetter = (
  type: string | "slider" | "dropdown",
  rangeValue: number,
  handleRangeChange: any
) => {
  const classes = bookingStyles();
  const rangeChange = (event, newValue) => {
    handleRangeChange(newValue);
  };

  const ddChange = (event, newValue) => {
    handleRangeChange(event.target.value);
  };
  const marks = [
    {
      value: 0,
      label: "Midnight",
    },
    {
      value: 9,
      label: "9am",
    },
    {
      value: 17,
      label: "5pm",
    },
    {
      value: 24,
      label: "Midnight",
    },
  ];

  switch (type) {
    case "slider":
      return (
        <Slider
          value={rangeValue}
          step={0.25}
          max={24}
          onChange={rangeChange}
          valueLabelDisplay="off"
          aria-labelledby="discrete-slider"
          marks={marks}
        />
      );
    case "dropdown":
      return (
        <Select
          native
          onChange={ddChange}
          style={{ marginLeft: "4px", marginRight: "4px", width: "78px" }}
          variant="outlined"
          className={classes.timeSelector}
          value={rangeValue}
        >
          {[
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
          ].map((hr) => {
            const an = hr < 12 ? "am" : "pm";
            const displayHour = hr > 12 ? hr - 12 : hr;
            return (
              <>
                <option
                  value={hr}
                  label={`${displayHour}:00${an}`}
                >{`${displayHour}:00${an}`}</option>
                <option
                  value={hr + 0.25}
                  label={`${displayHour}:15${an}`}
                >{`${displayHour}:15${an}`}</option>
                <option
                  value={hr + 0.5}
                  label={`${displayHour}:30${an}`}
                >{`${displayHour}:30${an}`}</option>
                <option
                  value={hr + 0.75}
                  label={`${displayHour}:45${an}`}
                >{`${displayHour}:45${an}`}</option>
              </>
            );
          })}
        </Select>
      );
  }
};

export const getHourString = (hr: number) => {
  if (hr == 0) return "12am";
  if (hr == 12) return "12pm";
  return hr > 12 ? `${hr - 12}pm` : `${hr}am`;
};

/** adds the given text to the canvas
 * @param ctx - canvas context
 * @param text - the text to display
 * @param x - canvas X
 * @param y - canvas Y
 * @param textColor - default black
 * @param textBackgroundColor - default white
 * @param textBackgroundBorderColor - default black
 */
export const drawText = (
  ctx: any,
  text: string,
  x: number,
  y: number,
  textAlign: string = "left",
  textColor: string | null,
  textBackgroundColor: string | null,
  textBackgroundBorderColor: string | null,
  fontSize: number = 12,
  isBold: boolean = false
) => {
  const txtColor = textColor ? textColor : "rgb{0,0,0)";
  const txtBackgroundColor = textBackgroundColor ? textBackgroundColor : "rgb(255,255,255)";
  const txtBackgroundBorderColor = textBackgroundBorderColor
    ? textBackgroundBorderColor
    : "rgb(0,0,0)";
  const displayText = text.replace(/\./g, "");

  const lineHeight = Math.round(fontSize * 0.83);
  const padding = fontSize / 2;
  const hPadding = fontSize + Math.round(fontSize * 0.16);

  ctx.font = `${isBold ? "bold" : ""} ${fontSize}px Arial`;
  const lines = displayText.indexOf("\n") ? displayText.split("\n") : [displayText];
  let longestText = "";
  lines.map((text) => {
    if (text.length > longestText.length) longestText = text;
  });
  var width = ctx.measureText(longestText).width + hPadding; /// width in pixels
  const xPos = x - 10;
  const yPos = y;
  ctx.textBaseline = "top";
  ctx.textAlign = textAlign;

  if (textBackgroundColor) {
    // draw background rectangle for text
    ctx.fillStyle = txtBackgroundColor;
    ctx.fillRect(xPos, yPos, width, fontSize * lines.length + padding);
  }
  if (textBackgroundBorderColor) {
    ctx.lineWidth = 1;
    ctx.strokeStyle = txtBackgroundBorderColor;
    ctx.strokeRect(xPos, yPos, width, fontSize * lines.length + padding);
  }
  // draw text
  ctx.fillStyle = txtColor;
  if (lines.length === 1) {
    ctx.fillText(lines[0], xPos + hPadding, yPos + lineHeight / 2);
  } else {
    let ypos = yPos + padding / 2;
    for (var i = 0; i < lines.length; i++) {
      ctx.fillText(lines[i], xPos + padding / 2, ypos);
      ypos = ypos + lineHeight + padding / 2;
    }
  }
};

export const drawKey = (
  isWayFinder: boolean | undefined,
  wayFinderPeriod: Period,
  rangeValue: number,
  handleRangeChange: any
) => {
  return (
    <Grid
      container
      style={{
        display: "inline-flex",
        justifyContent: "start",
        width: isWayFinder ? "1024px" : "60%",
        alignItems: "flex-start",
        flexDirection: "column",
        marginBottom: "8px",
      }}
    >
      <Grid item xs={12} style={{ display: "flex", width: "100%", fontSize: "14px" }}>
        <Grid item xs={3} style={{ whiteSpace: "nowrap" }}>
          Availability Legend:
        </Grid>
        <Grid item xs={isWayFinder ? 7 : 9}>
          <Grid container>
            <Grid item xs={isWayFinder ? 4 : 3}>
              <Grid
                item
                style={{ color: bhpColor.available, display: "flex", alignItems: "center" }}
              >
                <div className="dot" style={{ backgroundColor: bhpColor.available }}></div>
                Available
              </Grid>
              <Grid item style={{ whiteSpace: "nowrap" }}>
                <div style={{ color: bhpColor.focussed, display: "flex", alignItems: "center" }}>
                  <div className="dot" style={{ backgroundColor: bhpColor.focussed }}></div>My
                  Booking
                </div>
              </Grid>
            </Grid>
            <Grid item xs={isWayFinder ? 4 : 3}>
              {isWayFinder ? (
                <Grid
                  item
                  style={{ color: bhpColor.unavailable, display: "flex", alignItems: "center" }}
                >
                  <div className="dot" style={{ backgroundColor: bhpColor.unavailable }}></div>
                  Unavailable
                </Grid>
              ) : (
                <Grid
                  item
                  style={{
                    color: spot_partly.hex,
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="dot" style={{ backgroundColor: spot_partly.hex }}></div>Partly
                  Available
                </Grid>
              )}
              <Grid item style={{ whiteSpace: "nowrap" }}>
                <div style={{ color: bhpColor.team, display: "flex", alignItems: "center" }}>
                  <div className="dot" style={{ border: `2px solid ${bhpColor.team}` }}></div>Team
                  Member
                </div>
              </Grid>
            </Grid>
            <Grid item xs={isWayFinder ? 4 : 3}>
              {isWayFinder ? (
                <Grid
                  item
                  style={{
                    color: bhpColor.firstAid,
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    height: "20px",
                  }}
                >
                  <span
                    style={{
                      paddingLeft: "8px",
                      paddingRight: "6px",
                      color: bhpColor.firstAid,
                      fontWeight: "700",
                      fontSize: "20px",
                      paddingTop: "2px",
                    }}
                  >
                    +
                  </span>{" "}
                  First Aid Officer
                </Grid>
              ) : (
                <Grid
                  item
                  style={{ color: bhpColor.unavailable, display: "flex", alignItems: "center" }}
                >
                  <div className="dot" style={{ backgroundColor: bhpColor.unavailable }}></div>
                  Unavailable
                </Grid>
              )}
              <Grid
                item
                style={{
                  whiteSpace: "nowrap",
                  color: bhpColor.fireWarden,
                  display: "flex",
                  alignItems: "center",
                  height: "20px",
                }}
              >
                <span
                  style={{
                    paddingLeft: "8px",
                    paddingRight: "6px",
                    color: bhpColor.fireWarden,
                    fontWeight: "700",
                    fontSize: "20px",
                    paddingTop: "3px",
                  }}
                >
                  +
                </span>{" "}
                Fire Warden
              </Grid>
            </Grid>
            {!isWayFinder && (
              <Grid item xs={isWayFinder ? 4 : 3}>
                <Grid container>
                  <Grid item xs={12} style={{ height: "21px" }}>
                    {" "}
                  </Grid>
                  <Grid
                    item
                    style={{
                      color: bhpColor.firstAid,
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      height: "20px",
                    }}
                  >
                    <span
                      style={{
                        paddingLeft: "8px",
                        paddingRight: "6px",
                        color: bhpColor.firstAid,
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      {" + "}
                    </span>
                    First Aid Officer
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {isWayFinder && (
          <Grid item xs={3}>
            <Grid container style={{ maxWidth: "400px" }}>
              <Grid item xs={12}>
                <Grid
                  container
                  style={{ alignItems: "center", justifyContent: "end", display: "flex" }}
                >
                  <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
                    Availability at
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
                    {getNumberSetter("dropdown", rangeValue, handleRangeChange)}
                  </Grid>
                  <Grid item xs={1}>
                    {" "}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const drawExtras = (allSpaces: Space[], doClick?: any) => {
  return allSpaces
    .filter((spc) => !deskTypeIds.includes(spc.space_type?.id || 0))
    .map((spc, idx) => {
      const thisSpaceDetail = getSpaceTypeDetail(spc.space_type?.id);
      if (thisSpaceDetail.icon)
        return drawIcon(
          spc.corrected_coordinates_x,
          spc.corrected_coordinates_y,
          thisSpaceDetail,
          doClick,
          spc
        );
    });
};
