import { makeAutoObservable, flow, toJS, runInAction } from "mobx";
import client from "services/api";
import { getDateString, getTimeString } from "utils/date";
import {
  BasicBooking,
  BasicBookingLite,
  BookingJSON,
  DateFocussedBuilding,
  Period,
  StoreBuildingBookingList,
  StoreBuildingBookingListLite,
  StoreFloorBookingList,
  StoreUserBookingList,
  StoreSpaceBookingList,
} from "utils/hourlyBookings";
import RootStore from "./RootStore";

const BOOKINGPAGE_SIZE = 500;
export default class BookingStore {
  rootStore!: RootStore;
  floor_bookings: StoreFloorBookingList = {};
  dirtyFloor: boolean = false;
  user_bookings: StoreUserBookingList = {};
  space_bookings: StoreSpaceBookingList = {};
  building_bookings: StoreBuildingBookingList = {};
  building_bookings_lite: StoreBuildingBookingListLite = {};
  focussedBuilding: DateFocussedBuilding = {};

  error: string | unknown = "";

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setDirtyFloor() {
    runInAction(() => {
      this.dirtyFloor = true;
    });
  }

  setDateFocussedBuilding = flow(function* (this: BookingStore, date: string, building_id: number) {
    this.focussedBuilding[date] = building_id;
  });

  getDateFocussedBuilding = flow(function* (this: BookingStore, date: string) {
    return this.focussedBuilding[date];
  });

  getFloorBookings(floor_id: string) {
    return this.floor_bookings[floor_id] ? this.floor_bookings[floor_id] : [];
  }

  getSpaceBookings(space_id: string) {
    let space_bookings: BasicBooking[] = [];
    for (let key in this.floor_bookings) {
      const thisFloor = this.floor_bookings[key];
      space_bookings = space_bookings
        .slice()
        .concat(thisFloor.filter((bkg) => bkg.space && `${bkg.space.id}` === `${space_id}`));
    }
    return space_bookings;
  }

  getSpaceBookingsInPeriod(space_id: string, period: Period) {
    let space_bookings: BasicBooking[] = [];
    for (let key in this.floor_bookings) {
      const thisFloor = this.floor_bookings[key];
      space_bookings = space_bookings
        .slice()
        .concat(thisFloor.filter((bkg) => bkg.space && `${bkg.space.id}` === `${space_id}`));
    }
    const bookingsInPeriod = space_bookings.filter(
      (bkg) =>
        (bkg.booking_period.end > period.start && bkg.booking_period.end < period.end) || // overlaps start
        (bkg.booking_period.start > period.start && bkg.booking_period.start < period.end) || // overlaps end
        (bkg.booking_period.start > period.start && bkg.booking_period.end < period.end) || // exists inside
        (bkg.booking_period.start < period.start && bkg.booking_period.end > period.end) // completely overlaps
    );
    return bookingsInPeriod;
  }

  createUserBooking = flow(function* (
    this: BookingStore,
    user_id: number,
    space_id: number,
    start_date_local: string,
    start_time_local: string,
    end_date_local: string,
    end_time_local: string,
    created_by: string
  ) {
    const url = `/api/bookings/`;
    const data: any = {
      space: space_id,
      user: user_id,
      start_date_local,
      end_date_local,
      start_time_local,
      end_time_local,
      created_by,
    };
    yield client.post(url, data);
  });

  loadUserBookings = flow(function* (this: BookingStore, period: Period, user_id: string) {
    const url = `/api/bookings/?user=${user_id}&start_datetime_utc__gte=${period.start.toISOString()}&limit=${BOOKINGPAGE_SIZE}`;
    const res = yield client.get(url);
    this.user_bookings = {};
    let bookings: BasicBooking[] = [];
    if (res && res.data && res.data.results) {
      res.data.results.map((booking: BookingJSON) => {
        const thisBooking: BasicBooking = {
          id: booking.id,
          space: booking.space,
          building: booking.building,
          user_name: booking.user?.preferred_name ? booking.user!.preferred_name : `Not available`,
          user_id: booking.user?.id || null,
          booking_period: {
            start: new Date(booking.start_datetime_utc),
            end: new Date(booking.end_datetime_utc),
          },
          booking_details: {
            start_date_local: booking.start_date_local,
            start_time_local: booking.start_time_local,
            end_date_local: booking.end_date_local,
            end_time_local: booking.end_time_local,
          },
        };
        bookings.push(thisBooking);
      });
    }
    bookings
      .sort((bkg1, bkg2) =>
        new Date(bkg1.booking_details!.start_date_local) >
        new Date(bkg2.booking_details!.start_date_local)
          ? 1
          : -1
      )
      .map((bkg) => {
        if (this.user_bookings[bkg.booking_details!.start_date_local])
          this.user_bookings[bkg.booking_details!.start_date_local].push(bkg);
        else this.user_bookings[bkg.booking_details!.start_date_local] = [bkg];
      });
    return bookings;
  });

  loadSpaceBookings = flow(function* (this: BookingStore, period: Period) {
    const url = `/api/favourite_space_bookings/?start_datetime_utc__gte=${period.start.toISOString()}&end_datetime_utc__lte=${period.end.toISOString()}&limit=${BOOKINGPAGE_SIZE}`;
    const res = yield client.get(url);
    this.space_bookings = {};
    let bookings: BasicBooking[] = [];

    if (res && res.data && res.data.results) {
      res.data.results.map((booking: BookingJSON) => {
        const thisBooking: BasicBooking = {
          id: booking.id,
          space: booking.space,
          building: booking.building,
          user_name: booking.user?.preferred_name ? booking.user!.preferred_name : `Not available`,
          user_id: booking.user?.id || null,
          booking_period: {
            start: new Date(booking.start_datetime_utc),
            end: new Date(booking.end_datetime_utc),
          },
          booking_details: {
            start_date_local: booking.start_date_local,
            start_time_local: booking.start_time_local,
            end_date_local: booking.end_date_local,
            end_time_local: booking.end_time_local,
          },
        };
        bookings.push(thisBooking);
      });
    }
    bookings
      .sort((bkg1, bkg2) =>
        new Date(bkg1.booking_details!.start_date_local) >
        new Date(bkg2.booking_details!.start_date_local)
          ? 1
          : -1
      )
      .map((bkg) => {
        if (this.space_bookings[bkg.booking_details!.start_date_local])
          this.space_bookings[bkg.booking_details!.start_date_local].push(bkg);
        else this.space_bookings[bkg.booking_details!.start_date_local] = [bkg];
      });
    return bookings;
  });

  loadFloorBookings = flow(function* (this: BookingStore, period: Period, floor_id: string) {
    if (!floor_id || floor_id === "null") return [];
    let bookings: BasicBooking[] = [];
    if (floor_id) {
      const url = `/api/bookings/?floor_id=${floor_id}&within_start_datetime_utc=${period.start.toISOString()}&within_end_datetime_utc=${period.end.toISOString()}&limit=${BOOKINGPAGE_SIZE}`;
      const res = yield client.get(url);
      if (res && res.data && res.data.results) {
        res.data.results.forEach((booking: BookingJSON) => {
          const thisBooking: BasicBooking = {
            ...booking,
            user_name: booking.user?.preferred_name
              ? booking.user!.preferred_name
              : `Not available`,
            user_id: booking.user?.id || null,
            user_is_fire_warden: booking.user?.is_fire_warden || false,
            user_is_first_aid_officer: booking.user?.is_first_aid_office || false,
            booking_details: {
              start_date_local: booking.start_date_local,
              start_time_local: booking.start_time_local,
              end_date_local: booking.end_date_local,
              end_time_local: booking.end_time_local,
            },
            booking_period: {
              start: new Date(booking.start_datetime_utc),
              end: new Date(booking.end_datetime_utc),
            },
          };
          bookings.push(thisBooking);
        });
      }
      this.floor_bookings[floor_id.toString()] = bookings.slice();
    }
    this.dirtyFloor = false;
    return bookings;
  });

  deleteBooking = flow(function* (this: BookingStore, booking_id: number) {
    const url = `/api/bookings/${booking_id}/`;
    try {
      const res = yield client.delete(url);
      if (res.status === 204) this.removeBookingsFromLocalObjects(booking_id);
    } catch (err) {
      console.log(err);
      this.removeBookingsFromLocalObjects(booking_id);
      this.error = err;
    }
  });

  removeBookingsFromLocalObjects = flow(function* (this: BookingStore, booking_id: number) {
    // floor_bookings: { [floor_id: string]: BasicBooking[] } = {};
    // user_bookings: { [date: string]: BasicBooking[] } = {};
    // building_bookings: { [building_id: string]: BasicBooking[] } = {};
    for (let key in this.floor_bookings) {
      this.floor_bookings[key] = this.floor_bookings[key]
        .filter((bkg) => bkg.id !== booking_id)
        .slice();
    }
    for (let key in this.user_bookings) {
      this.user_bookings[key] = this.user_bookings[key]
        .filter((bkg) => bkg.id !== booking_id)
        .slice();
    }
    for (let key in this.building_bookings) {
      this.building_bookings[key] = this.building_bookings[key]
        .filter((bkg) => bkg.id !== booking_id)
        .slice();
    }
    this.dirtyFloor = true;
  });

  loadBuildingBookings = flow(function* (this: BookingStore, period: Period, building_id: string) {
    const url = `/api/bookings/?building=${building_id}&within_start_datetime_utc=${period.start.toISOString()}&within_end_datetime_utc=${period.end.toISOString()}&limit=${BOOKINGPAGE_SIZE}`;
    const res = yield client.get(url);
    if (res && res.data && res.data.results) {
      const bookings = res.data.results.map((booking: BookingJSON) => {
        const thisBooking: BasicBooking = {
          id: booking.id,
          space: booking.space,
          user_name: booking.user?.preferred_name ? booking.user!.preferred_name : `Not available`,
          user_id: booking.user?.id || null,
          booking_period: {
            start: new Date(booking.start_datetime_utc),
            end: new Date(booking.end_datetime_utc),
          },
          booking_details: {
            start_date_local: booking.start_date_local,
            start_time_local: booking.start_time_local,
            end_date_local: booking.end_date_local,
            end_time_local: booking.end_time_local,
          },
          building: booking.building,
        };
        return thisBooking;
      });
      this.building_bookings[building_id.toString()] = bookings.slice();
      return bookings;
    }
  });

  loadBuildingBookingsLite = flow(function* (
    this: BookingStore,
    period: Period,
    building_id: string
  ) {
    const url = `/api/bookings_lite/?building=${building_id}&within_start_datetime_utc=${period.start.toISOString()}&within_end_datetime_utc=${period.end.toISOString()}&limit=2000`;
    const res = yield client.get(url);
    if (res && res.data && res.data.results) {
      const bookingslite = res.data.results.map((booking: BookingJSON) => {
        const thisBookingLite: BasicBookingLite = {
          id: booking.id,
          booking_period: {
            start: new Date(booking.start_datetime_utc),
            end: new Date(booking.end_datetime_utc),
          },
          booking_details: {
            start_date_local: booking.start_date_local,
            start_time_local: booking.start_time_local,
            end_date_local: booking.end_date_local,
            end_time_local: booking.end_time_local,
          },
          building_id: building_id,
        };
        return thisBookingLite;
      });
      this.building_bookings_lite[building_id.toString()] = bookingslite.slice();
      return bookingslite;
    }
  });

  loadTeamBookings = flow(function* (this: BookingStore, period: Period, team_id: string) {
    const url = `/api/bookings/?user__userteam__team_id=${team_id}&within_start_datetime_utc=${period.start.toISOString()}&within_end_datetime_utc=${period.end.toISOString()}&limit=${BOOKINGPAGE_SIZE}`;
    const res = yield client.get(url);
    let bookings: BasicBooking[] = [];
    if (res && res.data && res.data.results) {
      res.data.results.map((booking: BookingJSON) => {
        const thisBooking: BasicBooking = {
          id: booking.id,
          space: booking.space,
          user_name: booking.user?.preferred_name ? booking.user!.preferred_name : `Not Available`,
          user_id: booking.user?.id || null,
          booking_period: {
            start: new Date(booking.start_datetime_utc),
            end: new Date(booking.end_datetime_utc),
          },
          booking_details: {
            start_date_local: booking.start_date_local,
            start_time_local: booking.start_time_local,
            end_date_local: booking.end_date_local,
            end_time_local: booking.end_time_local,
          },
          building: booking.building,
        };
        bookings.push(thisBooking);
      });
    }
    this.building_bookings[team_id.toString()] = bookings.slice();
    return bookings;
  });

  createUserBookingNoDesk = flow(function* (
    this: BookingStore,
    user_id: number,
    building: number,
    start_date_local: string,
    start_time_local: string,
    end_date_local: string,
    end_time_local: string,
    created_by: string,
    desk_required: boolean
  ) {
    const url = `/api/bookings/`;
    const data: any = {
      user: user_id,
      building,
      start_date_local,
      end_date_local,
      start_time_local,
      end_time_local,
      created_by,
      desk_required,
    };
    return yield client.post(url, data);
  });
}
