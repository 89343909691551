import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";

const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    backgroundColor: "#2D2C2CBF 75%",
    zIndex: 1,
  },
})(Backdrop);

interface DisabledOverlayProps {
  open: boolean;
  children: any;
}
const DisabledOverlay = (props: DisabledOverlayProps) => {
  return (
    <LimitedBackdrop open={props.open}>
      <div
        style={{
          position: "relative",
          backgroundColor: "white",
          padding: 30,
          borderRadius: 5,
          margin: "20px",
        }}
      >
        {props.children}
      </div>
    </LimitedBackdrop>
  );
};

export default DisabledOverlay;
