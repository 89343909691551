import { useMemo } from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-big-calendar";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import { Grid } from "@material-ui/core";
import { bhpColor } from "styles/globals";

export default function CustomWeekView({
  date,
  localizer,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(), "day"),
  ...props
}) {
  const currRange = useMemo(() => CustomWeekView.range(date, { localizer }), [date, localizer]);

  return (
    <TimeGrid
      date={date}
      eventOffset={15}
      localizer={localizer}
      max={max}
      min={min}
      range={currRange}
      scrollToTime={scrollToTime}
      {...props}
    />
  );
}

CustomWeekView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

CustomWeekView.range = (date, { localizer }) => {
  const start = getMonday(date);
  const end = localizer.add(start, 13, "day");

  let current = start;
  const range: any[] = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

CustomWeekView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -3, "day");

    case Navigate.NEXT:
      return localizer.add(date, 3, "day");

    default:
      return date;
  }
};

CustomWeekView.title = (date, { localizer }) => {
  const [start, ...rest] = CustomWeekView.range(date, { localizer });

  return (
    <Grid
      item
      xs={12}
      style={{ display: "flex", fontSize: "16px", fontWeight: 700, color: bhpColor.blueGrey1 }}
    >
      <Grid item xs={6}>
        Week 1
      </Grid>
      <Grid item xs={6}>
        Week2
      </Grid>
    </Grid>
  );
};

function getMonday(d: any) {
  d = new Date(d);
  d.setHours(0, 0, 0, 0);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}
