export enum EventDates {
  ThisWeek = "This Week",
  NextWeek = "Next Week",
  ThisMonth = "This Month",
  NextMonth = "Next Month",
  ThisYear = "This Year",
  AllEvents = "All Events",
}

export interface Event {
  id: number;
  is_active: boolean;
  event_title: string;
  event_category: string;
  event_description: string;
  building: number;
  floor: number;
  floor_name: string;
  event_start_date: string;
  event_start_time: string;
  event_end_date: string;
  event_end_time: string;
}
