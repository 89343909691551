import { Box, Button, ButtonGroup, createStyles, makeStyles, Theme } from "@material-ui/core";
import { BuildingWithCapacity, Floor } from "interfaces/Building";
import { useEffect } from "react";
import { useState } from "react";
import { bhpColor, settings } from "styles/globals";
import {
  mdiChevronRight,
  mdiChevronLeft,
  mdiChevronDoubleRight,
  mdiChevronDoubleLeft,
} from "@mdi/js";
import Icon from "@mdi/react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx"; // eslint-disable-line @typescript-eslint/no-unused-vars
import { useBuildingStore } from "providers/RootStoreProvider";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    "& .MuiButton-text": {
      paddingRight: 2,
      paddingLeft: 2,
    },
    floorButton: {
      fontSize: 20,
      color: bhpColor.blueGrey1,
      borderRadius: settings.borderRadius,
      borderWidth: 2,
      borderColor: bhpColor.blueGrey1,
      borderStyle: "solid",
      backgroundColor: bhpColor.blueGrey4,
      width: 60,
      height: 60,
      //marginRight: theme.spacing(1),
      "&.current": {
        backgroundColor: bhpColor.blueGrey1,
        color: bhpColor.white,
      },
      "&.unavailable": {
        backgroundColor: bhpColor.backgroundGrey,
        borderColor: bhpColor.blueGrey3,
        color: bhpColor.blueGrey3,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        height: 50,
        width: 50,
        marginRight: 6,
        minWidth: 50,
      },
    },
    rightScroll: {
      display: "flex",
      verticalAlign: "middle",
      alignItems: "center",
      cursor: "pointer",
      height: 45,
      width: 45,
      marginLeft: 3,
      "&.disabled": {
        color: bhpColor.blueGrey4,
        cursor: "default",
      },
      [theme.breakpoints.down("sm")]: {
        height: 20,
        width: 20,
        marginLeft: 0,
        paddingTop: 0,
      },
      "& svg": {
        height: 34,
        width: 34,
        [theme.breakpoints.down("sm")]: {
          height: 20,
          width: 20,
        },
      },
    },
  })
);

interface FloorChooserProps {
  building?: BuildingWithCapacity | null;
  floorId: number | null;
  onFloorChange: any;
  theDate: string;
}

const FloorChooser = observer((props: FloorChooserProps) => {
  const { building, floorId, onFloorChange, theDate } = props;
  const buildingStore = useBuildingStore();

  const classes = innerStyles();
  const unavailableFloors: number[] = [];
  const displaySize = 5;
  const floorSkipSize = 5;
  const maxIndex = building && building.floors ? building.floors.length - displaySize : 0;

  const [startIndex, setStartIndex] = useState(0);

  const [hasFloorsLeft, setHasFloorsLeft] = useState(startIndex > 0);
  const [hasAmpleFloorsLeft, setHasAmpleFloorsLeft] = useState(startIndex > floorSkipSize);

  const [hasFloorsRight, setHasFloorsRight] = useState(
    building && building.floors ? building.floors.length > displaySize : false
  );
  const [hasAmpleFloorsRight, setHasAmpleFloorsRight] = useState(
    building && building.floors
      ? building.floors.length > startIndex + displaySize + floorSkipSize
      : false
  );

  const [hasFloorSkipRight, setHasFloorSkipRight] = useState(
    building && building.floors
      ? building.floors.length > startIndex + displaySize + floorSkipSize
      : false
  );

  const [canScroll, setCanScroll] = useState(
    (building && building.floors && building.floors.length > displaySize) || false
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [primed, setPrimed] = useState(false);

  const doScroll = (direction: string, amount: number = 1) => {
    let newIndex = 0;
    switch (direction) {
      case "right":
        if (startIndex <= maxIndex - amount) newIndex = startIndex + amount;
        else newIndex = maxIndex;
        setStartIndex(newIndex);
        break;
      case "left":
        if (startIndex > amount) newIndex = startIndex - amount;
        else newIndex = 0;
        setStartIndex(newIndex);
        break;
    }
  };

  const sortAlphaNumeric = (a: any, b: any) => {
    // convert to strings and force lowercase
    a = typeof a === "string" ? a.toLowerCase() : a.toString();
    b = typeof b === "string" ? b.toLowerCase() : b.toString();
    return a.localeCompare(b, "en", { numeric: true });
  };

  const setFloor = (floorId: number, floorName: string = "") => {
    if (unavailableFloors.indexOf(floorId) < 0) onFloorChange(floorId, floorName);
  };

  useEffect(() => {
    setHasFloorsLeft(startIndex !== 0);
    setHasAmpleFloorsLeft(startIndex > floorSkipSize);
    setHasFloorsRight(startIndex < maxIndex);
    setHasAmpleFloorsRight(
      (building &&
        building.floors &&
        building.floors.length >= startIndex + displaySize + floorSkipSize) ||
        false
    );
  }, [startIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (building && building !== null && building.id !== 0) {
      if (building.floors) {
        setHasFloorsRight(building.floors.length > displaySize);
        setHasAmpleFloorsRight(
          building && building.floors
            ? building.floors.length > startIndex + displaySize + floorSkipSize
            : false
        );
        setHasFloorSkipRight(
          building && building.floors
            ? building.floors.length > startIndex + displaySize + floorSkipSize
            : false
        );
        setStartIndex(0);
        setHasFloorsLeft(false);
        setPrimed(true);
        building.floors.map((flr) => buildingStore.primeSpaces(building.id, flr.id, theDate));
        setCanScroll(
          (building && building.floors && building.floors.length > displaySize) || false
        );
      }
    }
  }, [building]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      {canScroll && (
        <>
          <Box
            style={{
              display: `${
                building && building.floors
                  ? building.floors.length < displaySize + floorSkipSize
                    ? "none"
                    : "flex"
                  : "none"
              }`,
            }}
            className={`${classes.rightScroll} ${hasAmpleFloorsLeft ? "" : "disabled"}`}
            onClick={() => doScroll(hasFloorsLeft ? "left" : "", 5)}
          >
            <Icon path={mdiChevronDoubleLeft} style={{ transform: "scaleX(0.6)" }} />
          </Box>

          <Box
            style={{
              display: `${
                building && building.floors
                  ? building.floors.length < displaySize
                    ? "none"
                    : "flex"
                  : "none"
              }`,
            }}
            className={`${classes.rightScroll} ${hasFloorsLeft ? "" : "disabled"}`}
            onClick={() => doScroll(hasFloorsLeft ? "left" : "")}
          >
            <Icon path={mdiChevronLeft} style={{ transform: "scaleX(0.6)" }} />
          </Box>
        </>
      )}
      <ButtonGroup variant="outlined">
        {building?.floors
          ?.slice()
          .sort((a, b) => sortAlphaNumeric(a.name, b.name))
          .slice(startIndex, startIndex + displaySize)
          .map((itm, idx) => {
            return (
              <Button
                onClick={() => setFloor(itm.id, itm.name)}
                key={`fl${idx}`}
                className={`${classes.floorButton} ${
                  itm.id === floorId
                    ? "current"
                    : buildingStore.floorSpaces[itm.id] &&
                      buildingStore.floorSpaces[itm.id].length > 0
                    ? ""
                    : "unavailable"
                }`}
              >
                {itm.name}
              </Button>
            );
          })}
      </ButtonGroup>
      {canScroll ? (
        <>
          <Box
            style={{ display: "flex" }}
            className={`${classes.rightScroll} ${hasFloorsRight ? "" : "disabled"}`}
            onClick={() => doScroll(hasFloorsRight ? "right" : "", 1)}
          >
            <Icon path={mdiChevronRight} style={{ transform: "scaleX(0.6)" }} />
          </Box>
          {hasFloorSkipRight && (
            <Box
              style={{
                display: "flex",
              }}
              className={`${classes.rightScroll} ${hasAmpleFloorsRight ? "" : "disabled"}`}
              onClick={() => doScroll(hasFloorsRight ? "right" : "", 5)}
            >
              <Icon path={mdiChevronDoubleRight} style={{ transform: "scaleX(0.6)" }} />
            </Box>
          )}
        </>
      ) : (
        ""
      )}
    </Box>
  );
});

export default FloorChooser;
