import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  CircularProgress,
  Slide,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { useState, useRef, useEffect } from "react";

import { bhpColor } from "styles/globals";
import HeaderImage from "assets/headerImage.png";
import { confirmDialogSize } from "interfaces/Utils";

const PopupStyles = makeStyles((theme) => ({
  root: {
    color: bhpColor.blueGrey1,
    "& .MuiPaper-root": {
      color: bhpColor.blueGrey1,
    },
    // Simon: not sure why we want that
    // "& .MuiDialog-paper": {
    //   minHeight: settings.modalDefaultHeight,
    // },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(2),
    },
  },
  dialogTitle: {
    backgroundImage: `url(${HeaderImage})`,
    color: bhpColor.white,
    "& h2": {
      fontWeight: "bold",
      "& svg": {
        verticalAlign: "text-top",
      },
    },
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 16,
  },
  mainItem: {
    flex: "auto",
  },
  bottomItem: {
    paddingBottom: 0,
  },
  circularIcon: {
    color: "white",
  },
  cancelButton: {
    "&:hover": {
      backgroundColor: `${bhpColor.orange4}50`,
    },
  },
}));

interface ConfirmDialogProps {
  title?: string | null;
  primaryButtonText?: string | null;
  secondaryButtonText?: string | null;
  isOpen: boolean;
  handlePrimaryClick: any;
  handleSecondaryClick: any;
  children?: React.ReactNode;
  dialogWidth?: string;
  disablePrimary?: boolean;
  maxWidth?: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ConfirmDialog(props: ConfirmDialogProps) {
  const classes = PopupStyles();
  const [saving, setSaving] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const primaryTextRef = useRef<HTMLSpanElement>(null);

  const {
    title,
    primaryButtonText,
    secondaryButtonText,
    isOpen,
    handlePrimaryClick,
    handleSecondaryClick,
    dialogWidth = "auto",
    disablePrimary = false,
    maxWidth = "xs",
  } = props;

  const customStyles = makeStyles((theme) => ({
    paper: {
      width: dialogWidth,
    },
  }));
  return (
    <Dialog
      TransitionComponent={Transition}
      maxWidth={maxWidth}
      open={isOpen}
      className={`${classes.root}`}
      classes={{ paper: customStyles().paper }}
    >
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={classes.content}>{props.children}</DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button
          onClick={async () => {
            setSaving(true);
            await handlePrimaryClick();
            setSaving(false);
          }}
          variant="contained"
          color="primary"
          disableElevation
          disabled={disablePrimary}
        >
          {!saving && (
            <span
              ref={(el) => {
                if (el) {
                  setHeight(el.offsetHeight);
                  setWidth(el.offsetWidth);
                }
              }}
            >
              {primaryButtonText || "Confirm"}
            </span>
          )}
          {saving && (
            <span style={{ width: width, height: height }}>
              <CircularProgress className={classes.circularIcon} size={20} />
            </span>
          )}
        </Button>
        <Button
          onClick={handleSecondaryClick}
          className={classes.cancelButton}
          style={{ display: `${secondaryButtonText ? "inline-flex" : "none"}` }}
        >
          {secondaryButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
