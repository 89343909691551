import { useEffect, useRef, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useMediaQuery, useTheme } from "@material-ui/core";

interface ChartCanvasWrapperProps {
  setcontext: any;
  draw?: any;
  height?: number;
  width?: number;
  mousemove?: any;
  addListeners?: boolean;
  redraw?: any;
}

const ChartCanvasWrapper = observer((props: ChartCanvasWrapperProps) => {
  const { width, height, mousemove, draw, setcontext, addListeners, redraw } = props;
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));

  const canvasRef = useRef(null);
  const canvasWidth = width || 1024;
  const canvasHeight = height || 1024;
  const [hasListener, setHasListener] = useState(false);

  let currentX = 0;
  let currentY = 0;
  let movements = 0; // use this to limit the sending of points upstream
  const mouseMove = (evt: any, ctx: any) => {
    const mouseX = evt.offsetX;
    const mouseY = evt.offsetY;
    if ((currentX !== 0 && currentX !== mouseX) || (currentY !== 0 && currentY !== mouseY)) {
      if (mousemove && movements % 3 === 2) mousemove(mouseX, mouseY, ctx); // every 3rd point should do
    }
    currentX = mouseX;
    currentY = mouseY;
    movements++;
  };

  useEffect(() => {
    const canvas = canvasRef.current || undefined;
    if (canvas) {
      const ctx = (canvas as HTMLCanvasElement).getContext("2d");
      if (!hasListener && addListeners !== false) {
        (canvas as HTMLCanvasElement).addEventListener("mousemove", function (event) {
          mouseMove(event, ctx);
        });
        setHasListener(true);
      }
      setcontext(ctx);
      if (ctx) {
        draw(ctx);
      }
    }
  }, [draw]);

  const relevantProps = { ...props };
  delete relevantProps.setcontext;
  delete relevantProps.draw;
  delete relevantProps.addListeners;
  delete relevantProps.mousemove;

  return <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} {...relevantProps} />;
});

export default ChartCanvasWrapper;
