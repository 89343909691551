import Icon from "@mdi/react";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { mdiClockTimeFour, mdiChevronDown } from "@mdi/js";
import {
  Box,
  createStyles,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Popover,
  Radio,
  Select,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { useBuildingStore, useUserStore } from "providers/RootStoreProvider";
import { Building } from "interfaces/Building";
import moment from "moment";
import { bhpColor } from "styles/globals";
import { TimeWidget } from "./TimeWidget";
import { getCityAbbr } from "utils/locations";
import { propagateMaybeChanged } from "mobx/dist/internal";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    onHoverPointerChange: {
      cursor: "pointer",
    },
    selectedTimezoneBackground: {
      //backgroundColor: bhpColor.blueGrey4,
    },
  })
);

const getStatusColor = (hour: number): string => {
  switch (true) {
    case hour < 6 && hour > 21:
      return bhpColor.grey3;
    case hour > 6 && hour < 9:
      return bhpColor.orange1;
    case hour > 17 && hour < 21:
      return bhpColor.orange1;
    case hour >= 9 && hour <= 17:
      return bhpColor.green1;
    default:
      return bhpColor.grey3;
  }
};

export const TimezoneWidget = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = innerStyles();
  const buildingStore = useBuildingStore();
  const userStore = useUserStore();
  const [currentLocationTimezone, setCurrentLocationTimezone] = useState<any | null>(
    userStore.defaultOffice || null
  );
  const [locations, setLocations] = useState<any[]>([]);
  const [time, setTime] = useState(Date.now());
  const [selectedTimezone, setSelectedTimezone] = useState<string>();
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const [compareHour, setCompareHour] = useState<number | null>(null);
  const [compareMinute, setCompareMinute] = useState<number | null>(null);
  useEffect(() => {
    (async () => {
      await buildingStore.loadBuildings();
      const favouriteCity = localStorage.getItem("favouriteCity");

      setLocations(
        buildingStore.buildings
          .reduce((prev, current) => {
            if (prev.find((building) => building.city === current.city)) {
              return current.city === favouriteCity
                ? [current, ...prev.filter((l) => l.city !== current.city)]
                : prev;
            } else {
              return current.city === favouriteCity ? [current, ...prev] : [...prev, current];
            }
          }, [] as Building[])
          .map((building) => {
            return {
              city: building.city,
              timeZone: building.building_timezone,
              cityAbbr: getCityAbbr(building),
            };
          })
      );

      if (favouriteCity) {
        setSelectedTimezone(favouriteCity);
        setCurrentLocationTimezone(locations.find((location) => location.city === favouriteCity));
      } else {
        localStorage.setItem("favouriteCity", userStore.me?.profile.default_city || "");
        setSelectedTimezone(userStore.me?.profile.default_city);
      }
    })();

    //setLocations([selectedTimezone, ...locations.filter((l) => l?.city !== selectedTimezone)]);
  }, [buildingStore.buildings, userStore.me]);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleTimezoneChange = (selectedLocation: any) => {
    setLocations([selectedLocation, ...locations.filter((l) => l.city !== selectedLocation.city)]);
    setSelectedTimezone(selectedLocation.city);
    setCurrentLocationTimezone(
      locations.find((location) => location.city === selectedLocation.city) || null
    );
    localStorage.setItem("favouriteCity", selectedLocation.city);
  };

  const handleCompareTimeChange = (hour: number, minute: number, ampm: string) => {
    setCompareHour(ampm === "AM" ? hour : 12 + hour);
    setCompareMinute(minute);
  };

  return (
    <PopupState variant="popover" popupId="timeZoneMenu">
      {(popupState) => (
        <>
          <div
            style={{ display: "inline-flex", paddingLeft: 8, paddingRight: 2, paddingTop: 4 }}
            {...bindTrigger(popupState)}
            className={classes.onHoverPointerChange}
          >
            <div style={{ alignSelf: "center", paddingRight: 2 }}>
              <Icon path={mdiClockTimeFour} size={1} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {currentLocationTimezone && (
                <>
                  <div style={{ fontSize: 14, fontWeight: "bold" }}>
                    {currentLocationTimezone.city}
                    {currentLocationTimezone.cityAbbr
                      ? `, ${currentLocationTimezone.cityAbbr}`
                      : ""}
                  </div>
                  <div style={{ fontSize: 12 }}>
                    {moment(new Date()).tz(currentLocationTimezone.timeZone).format("h:mm A")}
                  </div>
                </>
              )}
            </div>
            <div style={{ alignSelf: "center", paddingLeft: 2 }}>
              <Icon path={mdiChevronDown} size={1} />
            </div>
          </div>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box width={800} pl={2} py={2}>
              <Grid container spacing={2} style={{ textAlign: "left" }}>
                {locations.map((location) => {
                  return (
                    <Fragment key={location.city}>
                      <Grid
                        item
                        xs={3}
                        style={{
                          borderBottom: `1px solid ${bhpColor.blueGrey1}`,
                          fontSize: 16,
                          fontWeight: "bold",
                          textAlign: "left",
                          color: bhpColor.blueGrey1,
                          display: "flex",
                        }}
                        className={
                          selectedTimezone === location.city
                            ? classes.selectedTimezoneBackground
                            : ""
                        }
                      >
                        <div style={{ alignSelf: "center" }}>
                          <Radio
                            checked={selectedTimezone === location.city}
                            onChange={() => handleTimezoneChange(location)}
                            value="a"
                            name="radio-button"
                          />
                        </div>
                        <div style={{ alignSelf: "center" }}>{location.city}</div>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          borderBottom: `1px solid ${bhpColor.blueGrey1}`,
                          display: "flex",
                        }}
                        className={
                          selectedTimezone === location.city
                            ? classes.selectedTimezoneBackground
                            : ""
                        }
                      >
                        <div>
                          <div
                            style={{
                              fontSize: 18,
                              fontWeight: "bold",
                              display: "inline-flex",
                              color: bhpColor.blueGrey2,
                            }}
                          >
                            <div>{moment(new Date()).tz(location.timeZone).format("hh:mm")}</div>{" "}
                            <div style={{ fontSize: 12, lineHeight: 2.4, marginLeft: 4 }}>
                              {moment(new Date()).tz(location.timeZone).format("A")}
                            </div>
                          </div>
                          <div
                            style={{
                              fontSize: 10,
                              fontStyle: "italic",
                              color: bhpColor.blueGrey3,
                            }}
                          >
                            {moment(new Date()).tz(location.timeZone).format("dddd")}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 10,
                            width: 10,
                            backgroundColor: getStatusColor(
                              Number(moment(new Date()).tz(location.timeZone).format("HH"))
                            ),
                            borderRadius: "50%",
                            display: "inline-flex",
                            alignSelf: "center",
                            marginLeft: 20,
                          }}
                        ></div>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          borderBottom: `1px solid ${bhpColor.blueGrey1}`,
                          display: "flex",
                        }}
                        className={
                          selectedTimezone === location.city
                            ? classes.selectedTimezoneBackground
                            : ""
                        }
                      >
                        <div style={{ display: "inline-flex", alignSelf: "center" }}>
                          <div style={{ color: bhpColor.grey2 }}>
                            {moment().tz(location.timeZone).zoneAbbr()}
                          </div>
                          <div style={{ fontWeight: "bold", marginLeft: 4 }}>{`(${moment()
                            .tz(location.timeZone)
                            .format("Z")})`}</div>
                        </div>
                      </Grid>

                      {location.city === selectedTimezone
                        ? mdAndUp && (
                            <Grid
                              item
                              xs={3}
                              style={{
                                padding: 0,
                                marginLeft: location.city === selectedTimezone ? 0 : 12,
                                marginRight: location.city === selectedTimezone ? 0 : 12,
                                borderBottom: `1px solid ${bhpColor.blueGrey1}`,
                              }}
                              className={
                                location.city === selectedTimezone
                                  ? classes.selectedTimezoneBackground
                                  : ""
                              }
                            >
                              <TimeWidget
                                key={location.city}
                                handleCompareTimeSelection={handleCompareTimeChange}
                              />
                            </Grid>
                          )
                        : mdAndUp && (
                            <Grid
                              item
                              xs={3}
                              style={{
                                padding: 0,
                                marginLeft: location.city === selectedTimezone ? 0 : 12,
                                marginRight: location.city === selectedTimezone ? 0 : 12,
                                borderBottom: `1px solid ${bhpColor.blueGrey1}`,
                                display: "flex",
                              }}
                              className={
                                location.city === selectedTimezone
                                  ? classes.selectedTimezoneBackground
                                  : ""
                              }
                            >
                              {compareHour && (compareMinute || compareMinute === 0) && (
                                <>
                                  <div style={{ alignSelf: "center" }}>
                                    <div
                                      style={{
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        display: "inline-flex",
                                        color: bhpColor.blueGrey2,
                                      }}
                                    >
                                      <div>
                                        {moment(new Date())
                                          .set("hour", compareHour || 0)
                                          .set("minute", compareMinute || 0)
                                          .tz(location.timeZone)
                                          .format("hh:mm")}
                                      </div>{" "}
                                      <div style={{ fontSize: 12, lineHeight: 2.4, marginLeft: 4 }}>
                                        {moment(new Date())
                                          .set("hour", compareHour || 0)
                                          .set("minute", compareMinute || 0)
                                          .tz(location.timeZone)
                                          .format("A")}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 10,
                                        fontStyle: "italic",
                                        color: bhpColor.blueGrey3,
                                      }}
                                    >
                                      {moment(new Date())
                                        .set("hour", compareHour || 0)
                                        .set("minute", compareMinute || 0)
                                        .tz(location.timeZone)
                                        .format("dddd")}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      height: 10,
                                      width: 10,
                                      backgroundColor: getStatusColor(
                                        Number(
                                          moment(new Date())
                                            .set("hour", compareHour || 0)
                                            .set("minute", compareMinute || 0)
                                            .tz(location.timeZone)
                                            .format("HH")
                                        )
                                      ),
                                      borderRadius: "50%",
                                      display: "inline-flex",
                                      alignSelf: "center",
                                      marginLeft: 20,
                                    }}
                                  ></div>
                                </>
                              )}
                            </Grid>
                          )}
                    </Fragment>
                  );
                })}
              </Grid>
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  );
};
