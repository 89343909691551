import { observer } from "mobx-react-lite";
import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { Box, TextField } from "@material-ui/core";
import { useState } from "react";

interface ReportNoticeDialogProps {
  t: any;
  handleResponseClick: any;
  handleCloseClick: any;
  isOpen: boolean;
  noticeId?: number;
}

const ReportNoticeDialog = observer((props: ReportNoticeDialogProps) => {
  const { t, handleResponseClick, handleCloseClick, isOpen, noticeId } = props;

  const handleSave = () => {
    handleResponseClick(reportNoticeReason, noticeId);
    setReportNoticeReason("");
  };
  const [reportNoticeReason, setReportNoticeReason] = useState<string>("");
  const handleReportNoticeReasonChange = (newValue: any) => {
    setReportNoticeReason(newValue.target.value);
  };
  return (
    <>
      {
        <ConfirmDialog
          isOpen={isOpen}
          title={t("Report Notice")}
          primaryButtonText={t("Report Notice")}
          secondaryButtonText={t("Cancel")}
          handleSecondaryClick={handleCloseClick}
          handlePrimaryClick={handleSave}
          disablePrimary={!reportNoticeReason}
          maxWidth="sm"
        >
          <Box>
            <Box py={2}>
              Reporting this notice will send a notification to the team's Owner/s for review.
            </Box>
            <Box py={1} style={{ fontWeight: "bold" }}>
              Please enter a reason for reporting this notice :
            </Box>
            <Box component="form">
              <div>
                <TextField
                  id="reportNoticeTextArea"
                  variant="outlined"
                  fullWidth
                  rows={4}
                  inputProps={{ maxLength: 250 }}
                  multiline
                  style={{ width: "100%" }}
                  placeholder={t(`Enter reason... (250 Characters limit)`)}
                  value={reportNoticeReason || ""}
                  onChange={handleReportNoticeReasonChange}
                />
              </div>
            </Box>
          </Box>
        </ConfirmDialog>
      }
    </>
  );
});

export default ReportNoticeDialog;
