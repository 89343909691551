import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Slide,
} from "@material-ui/core";
import { mdiInformationOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { bhpColor, settings } from "styles/globals";
import HeaderImage from "assets/headerImage.png";
import React from "react";
import { TransitionProps } from "@material-ui/core/transitions";

const PopupStyles = makeStyles((theme) => ({
  root: {
    color: bhpColor.blueGrey1,
    "& .MuiPaper-root": {
      color: bhpColor.blueGrey1,
    },
    "& .MuiDialog-paper": {
      minHeight: settings.modalDefaultHeight,
    },
  },
  dialogTitle: {
    backgroundImage: `url(${HeaderImage})`,
    color: bhpColor.white,
    "& h2": {
      fontWeight: "bold",
      "& svg": {
        verticalAlign: "text-top",
      },
    },
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  mainItem: {
    flex: "auto",
  },
  bottomItem: {
    paddingBottom: 0,
  },
  cancelButton: {
    "&:hover": {
      backgroundColor: `${bhpColor.orange4}50`,
    },
  },
}));

interface PopupInformationWidgetProps {
  title?: string | null;
  content?: any | string | null;
  buttonText?: string | null;
  secondaryButtonText?: string | null;
  isOpen: any;
  handleClose: any;
  handleSecondaryClick?: any;
  hidePrimaryButton?: boolean;
  t: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function PopupInformationWidget(props: PopupInformationWidgetProps) {
  const classes = PopupStyles();
  const {
    title,
    content,
    buttonText,
    isOpen,
    handleClose,
    secondaryButtonText,
    handleSecondaryClick,
    hidePrimaryButton = false,
    t,
  } = props;
  const cancel = (e: any) => {
    if (typeof handleSecondaryClick === "function") handleSecondaryClick(e);
    else handleClose();
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={isOpen}
      scroll={"paper"}
      onClose={handleClose}
      className={classes.root}
    >
      <DialogTitle
        className={classes.dialogTitle}
        style={{ display: `${title ? "inline-flex" : "none"}` }}
      >
        <Icon path={mdiInformationOutline} size="1em" /> {t(title)}
      </DialogTitle>
      <DialogContent>
        <Box
          minHeight="140px"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: content ? t(content) : "" }}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between", padding: settings.outerPadding }}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          disableElevation
          style={{ visibility: hidePrimaryButton ? "hidden" : "visible" }}
        >
          {t(buttonText)}
        </Button>

        <Button
          onClick={(e) => cancel(e)}
          variant={secondaryButtonText ? "contained" : "text"}
          className={classes.cancelButton}
          disableElevation
          color="secondary"
        >
          {secondaryButtonText ? t(secondaryButtonText) : t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
