import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import EventsList from "./EventsList";
import EventDetails from "./EventDetails";
import { useEffect, useState } from "react";
import { EventDates } from "interfaces/Event";
import { BuildingWithCapacity } from "interfaces/Building";
import { useHistory } from "react-router-dom";

interface EventsProps {
  selectedDateFilter: EventDates | null;
  building: BuildingWithCapacity;
  eventId: number | null;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventList: {
      minHeight: 600,
      maxHeight: 600,
      overflowY: "scroll",
      overflowX: "hidden",
    },
    eventDetail: {
      minHeight: 600,
      maxHeight: 600,
      overflow: "auto",
      border: "1px solid #B7B9BB",
      borderRadius: 4,
    },
  })
);

const Events = observer((props: EventsProps) => {
  const { selectedDateFilter, building, eventId } = props;
  const classes = innerStyles();
  const [selectedEvent, setSelectedEvent] = useState<any | null>(null);
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));
  const history = useHistory();

  const handleEventSelection = (event: any) => {
    history.push(`${event.id}`);
    setSelectedEvent(event);
  };

  useEffect(() => {
    setSelectedEvent(null);
  }, [selectedDateFilter]);

  return (
    <Box px={mdAndUp ? 1 : 0} py={4}>
      <Grid container>
        <Grid item xs={mdAndUp ? 5 : 12} className={mdAndUp ? classes.eventList : ""}>
          <EventsList
            selectedDateFilter={selectedDateFilter}
            handleEventSelection={handleEventSelection}
            building={building}
            eventId={eventId}
          />
        </Grid>
        {mdAndUp ? (
          <Grid item xs={7} className={classes.eventDetail}>
            {selectedEvent && <EventDetails event={selectedEvent} building={building} />}
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </Box>
  );
});
export default Events;
