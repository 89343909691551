export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeString(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
export const sortAlphaNumeric = (a: any, b: any) => {
  // convert to strings and force lowercase
  a = typeof a === "string" ? a.toLowerCase() : a.toString();
  b = typeof b === "string" ? b.toLowerCase() : b.toString();
  return a.localeCompare(b, "en", { numeric: true });
};
