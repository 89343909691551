import { observer } from "mobx-react-lite";
import { ConfirmDialog } from "components/utils/ConfirmDialog";
import {
  Box,
  createStyles,
  debounce,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { usePeopleStore, useTeamStore } from "providers/RootStoreProvider";
import { Team, TeamCategoryType, TeamParticipant, TeamRoleType } from "interfaces/Teams";
import { forwardRef, Fragment, useEffect, useState } from "react";
import { toJS } from "mobx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { bhpColor } from "styles/globals";

const useStyles = makeStyles((theme) =>
  createStyles({
    textFieldLabel: {
      paddingLeft: 3,
      paddingBottom: 5,
      paddingTop: 10,
      fontWeight: "bold",
      color: "#466371",
    },
    searchTextField: {
      backgroundColor: "white",
    },
    selectBox: {
      "& .MuiOutlinedInput-input": {
        paddingTop: 12,
        paddingBottom: 12,
        color: bhpColor.blueGrey1,
      },
    },
  })
);

interface AddPeopleDialogProps {
  t: any;
  handleResponseClick?: any;
  handleCloseClick?: any;
  isOpen: boolean;
}

interface PeopleOrTeamDropdownOption extends TeamParticipant {
  type: string;
}

const AddPeopleDialog = observer((props: AddPeopleDialogProps) => {
  const { t, handleResponseClick, handleCloseClick, isOpen } = props;
  const peopleStore = usePeopleStore();
  const teamStore = useTeamStore();
  const classes = useStyles();

  const handleSave = () => {
    switch (chosenUser?.type) {
      case "User":
        console.log(`saving User as ${TeamRoleType[selectedRole]}`);
        break;
      case "Team":
        console.log(`saving Team as ${TeamRoleType[selectedRole]}`);
        break;
    }
    handleResponseClick(chosenUser, selectedRole);
  };

  const handleCancel = () => {
    peopleStore.setSearchFilter("");
    setPeopleDropdownOptions([]);
    handleCloseClick(false);
  };

  const [peopleDropdownOptions, setPeopleDropdownOptions] = useState<PeopleOrTeamDropdownOption[]>(
    []
  );
  const [selectedRole, setSelectedRole] = useState(TeamRoleType.PARTICIPANT);
  const gridPageSize = 20;

  const [chosenUser, setChosenUser] = useState<PeopleOrTeamDropdownOption | null>(null);

  const filterPeopleAndTeam = async () => {
    const resU = await peopleStore.getFilteredUsers(gridPageSize);
    const resT = await teamStore.getFilteredTeams(peopleStore.searchFilter, -1);
    let idx = 0;
    if (resT.results) {
      const userList = [
        ...peopleStore.users.map((usr) => {
          idx++;
          return {
            id: idx,
            user_id: usr.id,
            first_name: usr.first_name,
            last_name: usr.last_name,
            preferred_name: usr.preferred_name,
            email: usr.email,
            default_team: usr.profile.team_name || "",
            type: "User",
          };
        }),
      ];
      const teamList = [
        ...resT.results
          .filter((team: Team) => team.category !== TeamCategoryType.ORGANISATIONAL_UNIT)
          .map((team: Team) => {
            idx++;
            return {
              id: idx,
              user_id: team.id,
              preferred_name: team.name,
              default_team: "",
              type: "Team",
            };
          }),
      ];
      const fullList = userList.concat(teamList);
      setPeopleDropdownOptions(fullList);
    }
  };

  useEffect(() => {
    if (peopleStore.searchFilter != "") filterPeopleAndTeam();
  }, [peopleStore, peopleStore.searchFilter]);

  return (
    <>
      {
        <ConfirmDialog
          isOpen={isOpen}
          title={t("Add people to your team")}
          primaryButtonText={t("Add")}
          secondaryButtonText={t("Cancel")}
          handleSecondaryClick={handleCancel}
          handlePrimaryClick={handleSave}
        >
          <Grid container style={{ minWidth: "100%" }}>
            <Grid item xs={12}>
              <Autocomplete
                id="peopleAutoComplete"
                options={[...peopleDropdownOptions]}
                fullWidth
                groupBy={(opt) => opt.type}
                autoComplete
                defaultValue={undefined}
                clearOnBlur={false}
                getOptionLabel={(participant: PeopleOrTeamDropdownOption) =>
                  `${participant?.preferred_name}, ${participant?.default_team}` || ""
                }
                onChange={(event, value) => {
                  if (!value) {
                    setPeopleDropdownOptions([]);
                  }
                  setChosenUser(value);
                }}
                className={classes.searchTextField}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="people"
                    label={t("People or team")}
                    placeholder={`${t("Type a name or team")}...`}
                    size="small"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {teamStore.isLoading || peopleStore.isLoading ? (
                            <CircularProgress color="secondary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    onChange={debounce((event) => {
                      peopleStore.setSearchFilter(event.target.value);
                    }, 500)}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <div className={classes.textFieldLabel}>{t("Role")}</div>
            </Grid>
            <Grid container direction="row" style={{ justifyContent: "space-between" }}>
              <Grid item>
                <Select
                  labelId="role"
                  id="role"
                  value={selectedRole}
                  label="Role"
                  variant="outlined"
                  className={classes.selectBox}
                  fullWidth
                  onClick={(evt: any) => {
                    const value = evt.target.value;
                    setSelectedRole(value);
                  }}
                >
                  <MenuItem value={TeamRoleType.OWNER}>Owner</MenuItem>;
                  <MenuItem value={TeamRoleType.PARTICIPANT}>Participant</MenuItem>;
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </ConfirmDialog>
      }
    </>
  );
});

export default AddPeopleDialog;
