import React, { ReactElement, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PeopleIcon from "@material-ui/icons/People";
import EditIcon from "@material-ui/icons/Edit";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import PagesOutlinedIcon from "@material-ui/icons/PagesOutlined";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useBuildingStore, useUserStore } from "providers/RootStoreProvider";
import { Paper } from "@material-ui/core";
import TeamDetailsPlannerTab from "./TeamDetailsPlannerTab";
import TeamDetailsInformationTab from "./TeamDetailsInformationTab";
import TeamDetailsPeopleTab from "./TeamDetailsPeopleTab";
import TeamNoticeboardTab from "./TeamNoticeboardTab";
import TeamIloTab from "./TeamIloTab";
import background from "assets/background.svg";
import { bhpColor } from "styles/globals";
import TeamLeaveTab from "./TeamLeaveTab";
import { mdiAirplane } from "@mdi/js";
import Icon from "@mdi/react";

interface TeamsTabPanelProps {
  children?: React.ReactNode;
  t: any;
}

const TeamsTabPanel = observer((props: TeamsTabPanelProps) => {
  const { children, t, ...other } = props;
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();

  const isNoticeboard = history.location.pathname.indexOf("noticeboard") > -1;

  return (
    <div role="tabpanel" {...other}>
      <Box
        bgcolor={smAndUp && !isNoticeboard ? "white" : "transparent"}
        position="relative"
        p={smAndUp ? 3 : 0}
      >
        {children}
      </Box>
    </div>
  );
});

interface TeamsTabsProps {
  teamId?: number;
  hidePeopleTab?: boolean;
  hideLeaveTab?: boolean;
  hidePlannerTab?: boolean;
  hideInformationTab?: boolean;
  hideNoticeboardTab?: boolean;
  hideIloTab?: boolean;
  preferredPanel?: string;
  t: any;
}

interface TabType {
  label: string;
  link: string;
  icon: ReactElement;
  component: ReactElement;
  hideTab?: boolean;
}

const tabStyles = makeStyles((theme: Theme) =>
  createStyles({
    contours: {
      border: `1px solid ${bhpColor.blueGrey4}`,
      backgroundImage: `url(${background})`,
      height: "794px",
      overflowY: "auto",
    },
  })
);

const TeamsTabs = observer((props: TeamsTabsProps) => {
  const {
    teamId,
    t,
    hidePeopleTab,
    hidePlannerTab,
    hideNoticeboardTab,
    hideInformationTab,
    hideLeaveTab,
    hideIloTab,
    preferredPanel,
  } = props;
  const theme = useTheme();
  const history = useHistory();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [activeTab, setActiveTab] = useState(history.location.pathname);

  const isAddMode = preferredPanel === "create";
  const classes = tabStyles();

  const tabs: TabType[] =
    isAddMode || !teamId
      ? [
          {
            label: t("Create"),
            link: `/teams/create`,
            icon: (
              <Box display="flex" alignItems="center" my="auto" mr={1}>
                <EditIcon fontSize="small" />
              </Box>
            ),
            component: (
              <TeamsTabPanel t={t}>
                <TeamDetailsInformationTab t={t} teamId={0} />
              </TeamsTabPanel>
            ),
            hideTab: !isAddMode,
          },
        ]
      : [
          {
            label: <Hidden mdDown>{t("Planner")}</Hidden>,
            link: `/teams/planner/${teamId}`,
            icon: (
              <Box display="flex" alignItems="center" my="auto" mr={1}>
                <CalendarTodayIcon fontSize="small" />
              </Box>
            ),
            component: (
              <TeamsTabPanel t={t}>
                <TeamDetailsPlannerTab t={t} teamId={teamId} />
              </TeamsTabPanel>
            ),
            hideTab: hidePlannerTab || isAddMode,
          },
          {
            label: <Hidden mdDown>{t("Information")}</Hidden>,
            link: `/teams/information/${teamId}`,
            icon: (
              <Box display="flex" alignItems="center" my="auto" mr={1}>
                <EditIcon fontSize="small" />
              </Box>
            ),
            component: (
              <TeamsTabPanel t={t}>
                <TeamDetailsInformationTab t={t} teamId={teamId} />
              </TeamsTabPanel>
            ),
            hideTab: hideInformationTab || isAddMode,
          },
          {
            label: <Hidden mdDown>{t("People")}</Hidden>,
            link: `/teams/people/${teamId}`,
            icon: (
              <Box display="flex" alignItems="center" my="auto" mr={1}>
                <PeopleIcon fontSize="small" />
              </Box>
            ),
            component: (
              <TeamsTabPanel t={t}>
                <TeamDetailsPeopleTab t={t} teamId={teamId} />
              </TeamsTabPanel>
            ),
            hideTab: hidePeopleTab || isAddMode,
          },
          {
            label: <Hidden mdDown>{t("noticeboard")}</Hidden>,
            link: `/teams/noticeboard/${teamId}`,
            icon: (
              <Box display="flex" alignItems="center" my="auto" mr={1}>
                <PagesOutlinedIcon fontSize="small" />
              </Box>
            ),
            component: (
              <TeamsTabPanel t={t}>
                <TeamNoticeboardTab t={t} teamId={teamId} />
              </TeamsTabPanel>
            ),
            hideTab: hideNoticeboardTab || isAddMode,
          },

          {
            label: <Hidden mdDown>{t("leave")}</Hidden>,
            link: `/teams/leave/${teamId}`,
            icon: (
              <Box display="flex" alignItems="center" my="auto" mr={1}>
                <Icon path={mdiAirplane} size={"20px"} />
              </Box>
            ),
            component: (
              <TeamsTabPanel t={t}>
                <TeamLeaveTab t={t} teamId={teamId} />
              </TeamsTabPanel>
            ),
            hideTab: hideLeaveTab || isAddMode,
          },

          {
            label: <Hidden mdDown>{t("ILO")}</Hidden>,
            link: `/teams/ilo/${teamId}`,
            icon: (
              <Box display="flex" alignItems="center" my="auto" mr={1}>
                <EventAvailableOutlinedIcon fontSize="small" />
              </Box>
            ),
            component: (
              <TeamsTabPanel t={t}>
                <TeamIloTab t={t} teamId={teamId} />
              </TeamsTabPanel>
            ),
            hideTab: hideIloTab || isAddMode,
          },
        ];

  const handleChange = (tabLink: string) => {
    history.push(tabLink);
    setActiveTab(tabLink);
  };

  const getTabType = (): "fullWidth" | "standard" => {
    if (smAndUp) {
      return "standard";
    } else {
      return "fullWidth";
    }
  };

  useEffect(() => {
    if (preferredPanel === "") return;
    switch (preferredPanel) {
      case "create":
        setActiveTab("/teams/create");
        break;
      case "information":
        setActiveTab(`/teams/information/${teamId}`);
        break;
      case "noticeboard":
        setActiveTab(`/teams/noticeboard/${teamId}`);
        break;
      case "people":
        setActiveTab(`/teams/people/${teamId}`);
        break;
      case "planner":
        setActiveTab(`/teams/planner/${teamId}`);
        break;
      case "leave":
        setActiveTab(`/teams/leave/${teamId}`);
        break;
      case "ILO":
      case "ilo":
        setActiveTab(`/teams/ilo/${teamId}`);
        break;
    }
  }, [preferredPanel]);

  const selectedTab = tabs.find((tab) => activeTab === tab.link);
  const isNoticeboard = activeTab.indexOf("noticeboard") > -1;

  return (
    <div>
      <Tabs
        value={activeTab}
        variant={getTabType()}
        onChange={(event, value) => handleChange(value)}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs
          .filter((tab) => !tab.hideTab)
          .map((tab) => (
            <Tab key={tab.link} icon={tab.icon} value={tab.link} label={tab.label} />
          ))}
      </Tabs>
      <Box className={isNoticeboard ? classes.contours : undefined}>
        <Paper style={isNoticeboard ? { backgroundColor: "transparent" } : {}} elevation={0}>
          {selectedTab && selectedTab.component}
        </Paper>
      </Box>
    </div>
  );
});

export default TeamsTabs;
