import {
  Box,
  Button,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useBookingStore, useBuildingStore, useRootStore } from "providers/RootStoreProvider";
import { bhpColor } from "styles/globals";
import CapacityTabHeadRow from "components/office/CapacityTabHeadRow";
import CapacityTabChart from "components/office/CapacityTabChart";
import TeamScheduleRow from "components/team/TeamScheduleRow";
import { Team } from "interfaces/Teams";
import { useEffect, useState } from "react";
import { BuildingWithCapacity } from "interfaces/Building";
import { toJS } from "mobx";
import { SettingsSelectBox } from "components/settings/SettingsSelectBox";

interface CapacityTabProps {
  isSite?: boolean;
  correctedTotalCapacity?: number;
  t: any;
  displayStart: string;
  displayEnd: string;
  scrollTo: any;
  currentTeam: Team | null;
  building?: BuildingWithCapacity;
  weekView?: boolean;
  viewChange?: any;
  changeTeam?: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartOuter: {
      //border: `1px solid ${bhpColor.blueGrey4}`,
      [theme.breakpoints.down("sm")]: {
        border: 0,
        paddingLeft: 0,
      },
      "& .MuiTableHead-root": {
        color: bhpColor.blueGrey1,
        border: `1px solid ${bhpColor.blueGrey4}`,
        borderRadius: "4px",
      },
      "& .MuiTableCell-root": {
        border: 0,
      },
    },
    selectBox: {
      width: "calc(100% - 150px)",
      height: 40,
      "& svg": {
        zIndex: 2,
      },
      "& select": {
        zIndex: 2,
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& invalid": { color: "gray" },
      "& fieldset": {
        zIndex: 1,
        height: 40,
        top: -3,
        backgroundColor: "white",
        "& .Mui-focussed": {
          backgroundColor: "white",
        },
      },
    },
    selectInput: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  })
);

const CapacityTab = observer((props: CapacityTabProps) => {
  const {
    isSite,
    correctedTotalCapacity,
    displayStart,
    displayEnd,
    scrollTo,
    currentTeam,
    t,
    building = null,
    weekView = false,
    viewChange,
    changeTeam,
  } = props;
  const classes = innerStyles();
  const store = useRootStore();
  const buildingStore = useBuildingStore();
  const bookingStore = useBookingStore();
  const isOffice = !isSite;

  const [focussedDate, setFocussedDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const selectDate = (newDate: string) => {
    setFocussedDate(newDate);
  };

  const [bcr, setBcr] = useState(
    buildingStore.buildingCapacityRange.filter(
      (bld) =>
        moment(bld.date).isSameOrAfter(moment(displayStart)) &&
        moment(bld.date).isSameOrBefore(moment(displayEnd))
    )
  );

  const handleViewChange = () => {
    if (viewChange) viewChange(!weekView);
  };

  const handleTeamChange = (val: any) => {
    console.log("ct: ", val);
    if (changeTeam) changeTeam(val);
  };

  useEffect(() => {
    setBcr(
      buildingStore.buildingCapacityRange.filter(
        (bld) =>
          moment(bld.date).isSameOrAfter(moment(displayStart)) &&
          moment(bld.date).isSameOrBefore(moment(displayEnd))
      )
    );
  }, [buildingStore.buildingCapacityRange]);

  const [is12hr, setIs12hr] = useState(true);
  const changeRange = (stat: boolean) => {
    setIs12hr(stat);
  };

  return (
    <Box style={{ color: bhpColor.blueGrey1 }}>
      <Box className={classes.chartOuter}>
        <Table style={{ border: 0, color: bhpColor.blueGrey1 }}>
          <TableHead>
            <CapacityTabHeadRow
              isSite={isSite}
              t={t}
              buildingCapacityRange={buildingStore.buildingCapacityRange.filter(
                (bld) =>
                  moment(bld.date).isSameOrAfter(moment(displayStart)) &&
                  moment(bld.date).isSameOrBefore(moment(displayEnd))
              )}
              scrollTo={scrollTo}
              focussedDate={focussedDate}
              focusDate={selectDate}
            />
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} colSpan={bcr.length}>
                <Grid container style={{ display: "flex", alignItems: "center" }}>
                  {/* <Grid
                    item
                    xs={2}
                    style={{ fontWeight: "bold", color: bhpColor.blueGrey1, paddingBottom: "2px" }}
                  >
                    View team
                  </Grid> */}
                  {/* <Grid item xs={10}>
                    <SettingsSelectBox
                      cssClass={classes.selectBox}
                      width={"50%"}
                      theId={"teamSelector"}
                      theKey={"tc"}
                      theItems={store.teamStore.myTeams}
                      valueField={"id"}
                      labelField={"name"}
                      handleChange={handleTeamChange}
                      defaultValue={currentTeam ? currentTeam.id : 0}
                      phText={t("Choose Team...")}
                      t={t}
                    />
                  </Grid> */}
                </Grid>
              </TableCell>
              <TableCell colSpan={3} align="right">
                <Button
                  size="small"
                  style={{ width: "55px", height: "24px", marginRight: "10px" }}
                  color="primary"
                  variant={is12hr ? "contained" : "outlined"}
                  onClick={() => changeRange(true)}
                >
                  12 hrs
                </Button>
                <Button
                  size="small"
                  style={{ width: "55px", height: "24px" }}
                  color="primary"
                  variant={!is12hr ? "contained" : "outlined"}
                  onClick={() => changeRange(false)}
                >
                  24 hrs
                </Button>
              </TableCell>
            </TableRow>

            <CapacityTabChart
              correctedTotalCapacity={correctedTotalCapacity}
              isSite={isSite}
              t={t}
              buildingCapacityRange={bcr}
              buildingTeamSchedule={buildingStore.buildingTeamSchedule}
              team={currentTeam}
              scrollTo={scrollTo}
              focussedDate={focussedDate}
              weekView={weekView}
              building={building}
              is12hr={is12hr}
            />

            <Hidden smUp>
              {/* <Typography
                style={{ fontSize: 12, fontWeight: 700, paddingTop: 24, paddingBottom: 12 }}
              >
                {t("View Team")}
              </Typography> */}
              <Paper
                elevation={0}
                style={{
                  backgroundColor: bhpColor.backgroundGrey,
                  paddingTop: 4,
                  paddingBottom: 4,
                }}
              >
                <Box p={2}>
                  {t("Viewing Team")} <b>{currentTeam?.name}</b>.
                </Box>
              </Paper>
            </Hidden>
            <Hidden smDown>
              {weekView &&
              buildingStore.buildingTeamSchedule &&
              buildingStore.buildingTeamSchedule.length > 0 ? (
                <TeamScheduleRow
                  isSite={isSite}
                  t={t}
                  buildingCapacityRange={buildingStore.buildingCapacityRange.filter(
                    (bld) =>
                      moment(bld.date).isSameOrAfter(moment(displayStart)) &&
                      moment(bld.date).isSameOrBefore(moment(displayEnd))
                  )}
                  buildingTeamSchedule={buildingStore.buildingTeamSchedule}
                  team={currentTeam}
                />
              ) : null}
            </Hidden>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
});

export default CapacityTab;
