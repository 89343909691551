import { Box, createStyles, makeStyles, Paper, Theme } from "@material-ui/core";
import Icon from "@mdi/react";
import { ScheduleType } from "interfaces/Schedule";
import { bhpColor, settings } from "styles/globals";
import { getWorkTypeColor } from "utils/backGroundColor";
import { getIconPath } from "utils/icons";
import { getTeamsScheduleStatusTypeText } from "utils/teams";

interface WorkingStatusSummaryProps {
  count: number;
  workingStatus: ScheduleType;
  text?: string;
  color?: string;
  backgroundColor?: string;
  width?: string;
  t: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      color: bhpColor.blueGrey1,
      fontSize: "18px",
      height: 52,
    },
    iconBox: {
      width: 48,
      height: 52,
      borderRadius: settings.borderRadius,
      backgroundColor: bhpColor.blueGrey1,
      color: bhpColor.white,
      verticalAlign: "top",
      textAlign: "center",
      display: "inline-block",
    },
    icon: {
      alignSelf: "center",
      height: 20,
      color: bhpColor.white,
      marginTop: 14,
    },
    countBlock: {
      height: 45,
      width: 105,
      display: "inline-block",
    },
  })
);

const WorkingStatusSummary = (props: WorkingStatusSummaryProps) => {
  const { count, workingStatus, text, color, backgroundColor, width, t } = props;
  const classes = innerStyles();

  const bgStyle = backgroundColor ? { backgroundColor: backgroundColor } : {};
  const widthStyle = width ? { width: width } : {};

  return (
    <Paper elevation={0} className={classes.paper} style={{ ...bgStyle, ...widthStyle }}>
      <Box
        className={classes.iconBox}
        style={{
          backgroundColor: color
            ? color
            : workingStatus == ScheduleType.OFFICE
            ? bhpColor.blueGrey1
            : bhpColor.blueGrey2,
        }}
      >
        <Icon path={getIconPath(workingStatus)} size={1} className={classes.icon} />
      </Box>
      <Box
        paddingLeft={1}
        className={classes.countBlock}
        style={{
          color: color ? color : getWorkTypeColor(workingStatus),
        }}
      >
        <Box style={{ fontWeight: 700, fontSize: 20, marginBottom: -8, marginTop: 4 }}>{count}</Box>
        <Box paddingTop={1} style={{ fontSize: 10 }}>
          {text ? text : getTeamsScheduleStatusTypeText(t, workingStatus)}
        </Box>
      </Box>
    </Paper>
  );
};

export default WorkingStatusSummary;
