import { createStyles, makeStyles, Theme, Grid, Box, Typography, styled } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { bhpColor } from "styles/globals";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getEventImage } from "utils/events";

interface EventDetailsTopProps {
  date: string;
  subject: string;
  imageType?: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventDetailTop: {
      borderBottom: "1px solid #B7B9BB",
    },
    eventDate: {
      justifyContent: "center",
      alignSelf: "center",
      color: bhpColor.grey1,
    },
    eventSubject: {
      color: bhpColor.blueGrey1,
      fontSize: 20,
      fontWeight: 700,
      textAlign: "center",
      overflowWrap: "break-word",
      whiteSpace: "normal",
    },
  })
);

const Img = styled("img")({
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  padding: 14,
});

const EventDetailsTop = observer((props: EventDetailsTopProps) => {
  const { date, subject, imageType } = props;
  const classes = innerStyles();
  const { t, i18n } = useTranslation();
  return (
    <Grid container className={classes.eventDetailTop}>
      <Grid item xs={2} className={classes.eventDate}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography
              variant="subtitle1"
              component="div"
              style={{ fontWeight: 700, fontSize: 26, lineHeight: "normal", textAlign: "center" }}
            >
              <Box>{moment(date, "YYYY-MM-DD").format("DD")}</Box>
              <Box>{moment(date, "YYYY-MM-DD").format("MMM").toUpperCase()}</Box>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8} container style={{ alignItems: "center", alignSelf: "center" }}>
        <Grid item>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="div"
            className={classes.eventSubject}
          >
            {subject}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={2} style={{ textAlign: "right" }}>
        <Grid item>
          <Img alt="Not Available" src={getEventImage(imageType)} />
        </Grid>
      </Grid>
    </Grid>
  );
});
export default EventDetailsTop;
