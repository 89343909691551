import ReactDOM from "react-dom";
import Main from "./Main";
import "./styles/index.scss";
import { RootStoreProvider } from "providers/RootStoreProvider";
import { ThemeProvider } from "@material-ui/core/styles";
import themelight from "./styles/dds-material-react-light-theme";
import { Typography, Box, Container, CssBaseline, CircularProgress } from "@material-ui/core";

import logo from "./assets/workday-vertical.png";
import { PublicClientApplication } from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";

import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { bhpColor } from "styles/globals";
import "./i18n";
import { Suspense } from "react";
import { t } from "i18next";

const ErrorComponent = (error: any) => {
  // TODO: style this
  return <p>An Error Occurred: {JSON.stringify(error)}</p>;
};

const LoadingComponent = () => {
  return (
    <CssBaseline>
      <ThemeProvider theme={themelight}>
        <Container maxWidth="md">
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <Box bgcolor="white" borderRadius={8} p={10}>
              <Box display="flex" justifyContent="center">
                <img style={{ maxWidth: 120 }} alt="logo" src={logo}></img>
              </Box>
              <Box display="flex" mt={1} style={{ textAlign: "center" }}>
                {t("Please wait. Authenticating...")}
              </Box>
              <Box
                fontWeight="bold"
                mt={1}
                color="primary.main"
                display="flex"
                justifyContent="center"
              >
                <CircularProgress color="primary" />
              </Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </CssBaseline>
  );
};

const config = {
  auth: {
    clientId: window.env.AZURE_CONFIG.clientId,
    authority: `https://login.microsoftonline.com/${window.env.AZURE_CONFIG.tenantId}`,
    redirectUri: `${window.location.origin}/signin`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// create PublicClientApplication instance
const publicClientApplication = new PublicClientApplication(config);

const authRequest = {
  scopes: ["openid", "profile"],
};

const ComingSoon = () => {
  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Box
          display="flex"
          flexDirection="column"
          bgcolor="white"
          justifyContent="center"
          alignItems="center"
          borderRadius={8}
          p={10}
        >
          <Box display="flex" justifyContent="center">
            <img style={{ maxWidth: 120 }} alt="logo" src={logo}></img>
          </Box>
          <Box display="flex" mt={1}>
            Coming Soon
          </Box>
          <Box alignSelf="flex-end">
            <Typography variant="caption" style={{ color: bhpColor.orange1 }}>
              {window.env.app_ver}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

ReactDOM.render(
  <RootStoreProvider>
    <MsalProvider instance={publicClientApplication}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <Suspense fallback="loading">
          <Main />
        </Suspense>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </RootStoreProvider>,
  document.getElementById("root")
);
