import { observer } from "mobx-react-lite";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Popover, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { useContext, useState } from "react";
import { HolidayContext } from "Main";
import { bhpColor } from "styles/globals";
import Icon from "@mdi/react";
import { mdiInformation } from "@mdi/js";
import { generateHolidayInformationModalContent } from "utils/date";

interface HolidayPopoverProps {
  date: string;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
  })
);

const HolidayPopover = observer((props: HolidayPopoverProps) => {
  const { date } = props;
  const classes = innerStyles();

  const allHolidays = useContext(HolidayContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const getHolidaysForDate = (date: string) => {
    const holidaysFound =
      allHolidays.findIndex((holiday) => holiday.date === date) >= 0 ? true : false;
    const scheduleDayHolidays = holidaysFound
      ? allHolidays.find((holiday) => holiday.date === date)?.holidays
      : [];
    return scheduleDayHolidays && scheduleDayHolidays.length > 0 ? scheduleDayHolidays : null;
  };
  const isHoliday = getHolidaysForDate(date) ? true : false;
  return (
    <>
      {isHoliday && smAndUp && (
        <div
          aria-haspopup="true"
          onMouseEnter={(event) => handlePopoverOpen(event)}
          onMouseLeave={handlePopoverClose}
          style={{ position: "absolute", top: -8, right: -10, color: bhpColor.orange1 }}
        >
          <Icon path={mdiInformation} size="20px" />
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            className={classes.popover}
          >
            <div
              style={{ paddingRight: 24 }}
              dangerouslySetInnerHTML={{
                __html: generateHolidayInformationModalContent(getHolidaysForDate(date) || []),
              }}
            />
          </Popover>
        </div>
      )}
    </>
  );
});

export default HolidayPopover;
