import { makeAutoObservable, flow, toJS, runInAction } from "mobx";
import axios from "axios";
import { User } from "interfaces/User";
import { SettingsWdpType } from "interfaces/SettingsWdp";
import { Building, FavouriteSpace, SpaceBooking } from "interfaces/Building";
import { Buffer } from "buffer";

import { capitalizeFirstLetter } from "utils/string";

import RootStore from "./RootStore";
import client from "services/api";
import config from "config";
import moment from "moment";
import { getBoolean, DEFAULT_END_TIME, DEFAULT_START_TIME, Period } from "utils/hourlyBookings";

declare global {
  interface Window {
    token: string;
    env: any;
    DD_RUM?: any;
  }
}

// helper function: generate a new file from base64 String
const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1; // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime });
};

export default class UserStore {
  rootStore!: RootStore;
  me: User | null = null;
  defaultOffice: Building | null = null;
  profilePicture = "";
  error = "";
  isLoading = false;
  userByTeamCount?: number;
  accessToken = "";
  isLoadingFavourites = false;
  favouriteSpaces: FavouriteSpace[] = [];
  usersByTeamId: User[] = [];
  isLoadingFavouriteUsers: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  hasTeam = () => {
    return this.me?.profile.team ? true : false;
  };

  resetUsersByTeamId = () => {
    this.usersByTeamId = [];
  };

  getMe = flow(function* (this: UserStore) {
    this.isLoading = true;
    try {
      const res = yield client.get("/api/me/whoami/");
      this.me = res.data;
      if (this.me?.profile.default_office) {
        if (this.defaultOffice?.id !== this.me?.profile.default_office)
          this.defaultOffice = this.rootStore.buildingStore.getBuilding(
            this.me?.profile.default_office
          );
      }
      if (this.me?.profile.favourite_spaces) {
        this.favouriteSpaces = this.me.profile.favourite_spaces.slice();
        this.primeFavouritesBookings(new Date());
      }
      if (!["LOCAL"].includes(window.env.app_env)) {
        if (!this.profilePicture.length) {
          const resPicture = yield axios.get("https://graph.microsoft.com/v1.0/me/photo/$value", {
            headers: { Authorization: `Bearer ${window.token}` },
            responseType: "arraybuffer",
          });
          this.profilePicture = `data:image/jpeg;base64,${Buffer.from(
            resPicture.data,
            "binary"
          ).toString("base64")}`;

          const cachedProfilePic = localStorage.getItem("profilePic");
          if (cachedProfilePic !== this.profilePicture) {
            const picData = new FormData();
            picData.append("img", dataURLtoFile(this.profilePicture, `${this.me?.email}.png`));

            localStorage.setItem("profilePic", this.profilePicture);
            yield client.post("/api/me/picture/", picData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
      this.error = JSON.stringify(err);
    }
    this.isLoading = false;
  });

  hasFavourites = () => {
    return this.me?.profile.favourite_spaces ? true : false;
  };

  hasFavouritesInBuilding = (buildingId: number) => {
    return getBoolean(this.me && this.me.profile.favourite_spaces);
  };

  isFavouriteSpace(spaceId: number) {
    return spaceId && this.me && this.me.profile.favourite_spaces
      ? this.me.profile.favourite_spaces.findIndex((spc) => spc.id === spaceId) > -1
      : false;
  }

  primeFavouritesBookings = flow(function* (this: UserStore, dateFrom: Date) {
    try {
      const date = moment(dateFrom).format("YYYY-MM-DD");
      const startTime: string = DEFAULT_START_TIME;
      const endTime: string = DEFAULT_END_TIME;
      this.favouriteSpaces.map((fav) => {
        if (fav.building_id) {
          const timezone = this.rootStore.buildingStore.getBuilding(
            fav.building_id
          )?.building_timezone;
          if (timezone) {
            this.rootStore.buildingStore
              .getSpaceBookings(fav.id, date, timezone)
              .then((bookings) => {
                if (bookings && bookings.length > 0) {
                  let currentBookings = fav.bookings?.slice();
                  runInAction(() => {
                    fav.bookings = currentBookings
                      ? currentBookings.concat(bookings)
                      : bookings.slice();
                  });
                }
                return;
              });
          }
        }
        if (fav.id) {
          const bld = fav.building_id
            ? this.rootStore.buildingStore.getBuilding(fav.building_id)
            : null;
          const btz = bld && bld.building_timezone ? bld.building_timezone : "UTC";
          const bookingStartUTC = moment.tz(`${date} ${startTime}`, btz).toDate();
          const bookingEndUTC = moment.tz(`${date} ${endTime}`, btz).toDate();
          const deskHours: Period = { start: bookingStartUTC, end: bookingEndUTC };
          const res = this.rootStore.bookingStore.loadSpaceBookings(deskHours);
        }
      });
    } catch (err) {
      this.error = `${JSON.stringify(err)}`;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  getFavouriteUsers = flow(function* (this: UserStore, date: string) {
    try {
      this.error = "";
      this.isLoadingFavouriteUsers = true;
      const res = yield client.get(`/api/users/user/favourite-users/?date=${date}`);
      this.isLoadingFavouriteUsers = false;
      return res.data;
    } catch (err) {
      this.error = `${JSON.stringify(err)}`;
      this.isLoadingFavouriteUsers = false;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  addFavouriteUser = flow(function* (this: UserStore, userId: number) {
    try {
      this.error = "";
      this.isLoadingFavouriteUsers = true;
      const res = yield client.post(`/api/users/set-favourite-user/`, {
        favouriteUserId: userId,
      });
      this.getMe();
      this.isLoadingFavouriteUsers = false;
    } catch (err) {
      this.error = `${JSON.stringify(err)}`;
      this.isLoadingFavouriteUsers = false;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  removeFavouriteUser = flow(function* (this: UserStore, userId: number) {
    try {
      this.error = "";
      this.isLoadingFavouriteUsers = true;
      const res = yield client.post(`/api/users/remove-favourite-user/`, {
        favouriteUserId: userId,
      });
      this.getMe();
      this.isLoadingFavouriteUsers = false;
    } catch (err) {
      this.error = `${JSON.stringify(err)}`;
      this.isLoadingFavouriteUsers = false;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  addFavouriteSpaces = flow(function* (this: UserStore, spaceId: number) {
    try {
      this.error = "";
      this.isLoading = true;
      this.isLoadingFavourites = true;
      const res = yield client.post(`/api/user-profiles/${this.me?.id}/set-favourite-desk/`, {
        space_id: spaceId,
      });
      this.getMe();
      this.isLoading = false;
      this.isLoadingFavourites = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = `${JSON.stringify(err)}`;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  removeFavouriteSpaces = flow(function* (this: UserStore, spaceId: number) {
    try {
      this.error = "";
      this.isLoading = true;
      this.isLoadingFavourites = true;
      const res = yield client.post(`/api/user-profiles/${this.me?.id}/unset-favourite-desk/`, {
        space_id: spaceId,
      });
      this.getMe();
      this.isLoading = false;
      this.isLoadingFavourites = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = `${JSON.stringify(err)}`;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  setToken = flow(function* (this: UserStore, accessToken: string) {
    window.token = yield accessToken;
  });

  getInitials = () => {
    return this.me?.email
      .split("@")[0]
      .split(".")
      .map((x) => x[0].toUpperCase())
      .join("");
  };

  getName = () => {
    return this.me
      ? capitalizeFirstLetter(
          this.me.profile.preferred_name ? this.me.profile.preferred_name : this.me?.first_name
        )
      : "";
  };

  getPreferredName = () => {
    return this.me
      ? capitalizeFirstLetter(
          this.me.profile.preferred_name
            ? this.me.profile.preferred_name
            : this.me?.first_name + " " + this.me?.last_name
        )
      : "";
  };

  changeSettings = flow(function* (this: UserStore, givenSettings: SettingsWdpType) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.put(`/api/user-profiles/${givenSettings.user}/`, givenSettings);
      if (this.me) {
        this.me.profile = res.data;
        this.defaultOffice = yield this.rootStore.buildingStore.loadBuilding(
          this.me.profile.default_office!
        );
      }
      //marking the data as dirty in case someone changed their default office
      this.rootStore.scheduleStore.forceDataReload();

      this.isLoading = false;
      return res;
    } catch (err) {
      console.log("response ERROR");

      this.isLoading = false;
      this.error = JSON.stringify(err);
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  getUsersSummaryByTeamId = flow(function* (this: UserStore, teamId: number) {
    try {
      const res = yield client.get(`/api/users-simple/?profile__team=${teamId}`);
      this.userByTeamCount = res?.data?.count || 0;
      return res?.data;
    } catch (err) {
      this.error = JSON.stringify(err);
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  getUsersByTeamId = flow(function* (this: UserStore, teamId: number) {
    try {
      const res = yield client.get(`/api/users/?team=${teamId}`);
      this.userByTeamCount = res?.data?.count || 0;

      return res?.data?.results;
    } catch (err) {
      this.error = JSON.stringify(err);
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  loadUsersByTeamId = flow(function* (this: UserStore, teamId: number) {
    try {
      const res = yield client.get(`/api/users/?team=${teamId}`);
      this.usersByTeamId = res?.data.results || [];
    } catch (err) {
      this.error = JSON.stringify(err);
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  get routine(): {
    status: number | null;
    start_time: string | null;
    finish_time: string | null;
    desk_start_time: string | null;
    desk_finish_time: string | null;
    office: number | null;
  }[] {
    return [
      {
        status:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.mon_default?.status !== null
            ? this.me?.profile.default_week?.mon_default?.status
            : null,
        start_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.mon_default?.start_time
            ? this.me?.profile.default_week?.mon_default?.start_time
            : config.workday.defaultStartWorkingHour,
        finish_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.mon_default?.finish_time
            ? this.me?.profile.default_week?.mon_default?.finish_time
            : config.workday.defaultFinishWorkingHour,
        desk_start_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.mon_default?.desk_start_time
            ? this.me?.profile.default_week?.mon_default?.desk_start_time
            : config.workday.defaultStartWorkingHour,
        desk_finish_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.mon_default?.desk_finish_time
            ? this.me?.profile.default_week?.mon_default?.desk_finish_time
            : config.workday.defaultFinishWorkingHour,
        office:
          this.me?.profile.default_week?.mon_default?.office || this.defaultOffice?.id || null,
      },
      {
        status:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.tue_default?.status !== null
            ? this.me?.profile.default_week?.tue_default?.status
            : null,
        start_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.tue_default?.start_time
            ? this.me?.profile.default_week?.tue_default?.start_time
            : config.workday.defaultStartWorkingHour,
        finish_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.tue_default?.finish_time
            ? this.me?.profile.default_week?.tue_default?.finish_time
            : config.workday.defaultFinishWorkingHour,
        desk_start_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.tue_default?.desk_start_time
            ? this.me?.profile.default_week?.tue_default?.desk_start_time
            : config.workday.defaultStartWorkingHour,
        desk_finish_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.tue_default?.desk_finish_time
            ? this.me?.profile.default_week?.tue_default?.desk_finish_time
            : config.workday.defaultFinishWorkingHour,
        office:
          this.me?.profile.default_week?.tue_default?.office || this.defaultOffice?.id || null,

        // ? this.me?.profile.default_week?.tue_default?.office
        // : this.defaultOffice?.id,
      },
      {
        status:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.wed_default?.status !== null
            ? this.me?.profile.default_week?.wed_default?.status
            : null,
        start_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.wed_default?.start_time
            ? this.me?.profile.default_week?.wed_default?.start_time
            : config.workday.defaultStartWorkingHour,
        finish_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.wed_default?.finish_time
            ? this.me?.profile.default_week?.wed_default?.finish_time
            : config.workday.defaultFinishWorkingHour,
        desk_start_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.wed_default?.desk_start_time
            ? this.me?.profile.default_week?.wed_default?.desk_start_time
            : config.workday.defaultStartWorkingHour,
        desk_finish_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.wed_default?.desk_finish_time
            ? this.me?.profile.default_week?.wed_default?.desk_finish_time
            : config.workday.defaultFinishWorkingHour,
        office:
          this.me?.profile.default_week?.wed_default?.office || this.defaultOffice?.id || null,

        // office: this.me?.profile.default_week
        //   ? this.me?.profile.default_week?.wed_default?.office
        //   : this.defaultOffice.id,
      },
      {
        status:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.thu_default?.status !== null
            ? this.me?.profile.default_week?.thu_default?.status
            : null,
        start_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.thu_default?.start_time
            ? this.me?.profile.default_week?.thu_default?.start_time
            : config.workday.defaultStartWorkingHour,
        finish_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.thu_default?.finish_time
            ? this.me?.profile.default_week?.thu_default?.finish_time
            : config.workday.defaultFinishWorkingHour,
        desk_start_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.thu_default?.desk_start_time
            ? this.me?.profile.default_week?.thu_default?.desk_start_time
            : config.workday.defaultStartWorkingHour,
        desk_finish_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.thu_default?.desk_finish_time
            ? this.me?.profile.default_week?.thu_default?.desk_finish_time
            : config.workday.defaultFinishWorkingHour,
        office:
          this.me?.profile.default_week?.thu_default?.office || this.defaultOffice?.id || null,
      },
      {
        status:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.fri_default?.status !== null
            ? this.me?.profile.default_week?.fri_default?.status
            : null,
        start_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.fri_default?.start_time
            ? this.me?.profile.default_week?.fri_default?.start_time
            : config.workday.defaultStartWorkingHour,
        finish_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.fri_default?.finish_time
            ? this.me?.profile.default_week?.fri_default?.finish_time
            : config.workday.defaultFinishWorkingHour,
        desk_start_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.fri_default?.desk_start_time
            ? this.me?.profile.default_week?.fri_default?.desk_start_time
            : config.workday.defaultStartWorkingHour,
        desk_finish_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.fri_default?.desk_finish_time
            ? this.me?.profile.default_week?.fri_default?.desk_finish_time
            : config.workday.defaultFinishWorkingHour,
        office:
          this.me?.profile.default_week?.fri_default?.office || this.defaultOffice?.id || null,
      },
      {
        status:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.sat_default?.status !== null
            ? this.me?.profile.default_week?.sat_default?.status
            : null,
        start_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.sat_default?.start_time
            ? this.me?.profile.default_week?.sat_default?.start_time
            : config.workday.defaultStartWorkingHour,
        finish_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.sat_default?.finish_time
            ? this.me?.profile.default_week?.sat_default?.finish_time
            : config.workday.defaultFinishWorkingHour,
        desk_start_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.sat_default?.desk_start_time
            ? this.me?.profile.default_week?.sat_default?.desk_start_time
            : config.workday.defaultStartWorkingHour,
        desk_finish_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.sat_default?.desk_finish_time
            ? this.me?.profile.default_week?.sat_default?.desk_finish_time
            : config.workday.defaultFinishWorkingHour,
        office:
          this.me?.profile.default_week?.sat_default?.office || this.defaultOffice?.id || null,
      },
      {
        status:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.sun_default?.status !== null
            ? this.me?.profile.default_week?.sun_default?.status
            : null,
        start_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.sun_default?.start_time
            ? this.me?.profile.default_week?.sun_default?.start_time
            : config.workday.defaultStartWorkingHour,
        finish_time:
          this.me?.profile.default_week && this.me?.profile.default_week?.sun_default?.finish_time
            ? this.me?.profile.default_week?.sun_default?.finish_time
            : config.workday.defaultFinishWorkingHour,
        desk_start_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.sun_default?.desk_start_time
            ? this.me?.profile.default_week?.sun_default?.desk_start_time
            : config.workday.defaultStartWorkingHour,
        desk_finish_time:
          this.me?.profile.default_week &&
          this.me?.profile.default_week?.sun_default?.desk_finish_time
            ? this.me?.profile.default_week?.sun_default?.desk_finish_time
            : config.workday.defaultFinishWorkingHour,
        office:
          this.me?.profile.default_week?.sun_default?.office || this.defaultOffice?.id || null,
      },
    ];
  }

  get isFullySetup(): boolean {
    if (this.me) {
      if (
        this.me.profile.default_office !== null &&
        this.me.profile.private_schedule !== null &&
        this.me.profile.default_week !== null &&
        this.me.profile.remote_timezone !== null &&
        this.me.profile.default_week?.mon !== null &&
        this.me.profile.default_week?.tue !== null &&
        this.me.profile.default_week?.wed !== null &&
        this.me.profile.default_week?.thu !== null &&
        this.me.profile.default_week?.fri !== null &&
        this.me.profile.default_week?.sat !== null &&
        this.me.profile.default_week?.sun !== null
      ) {
        return true;
      }
    }
    return false;
  }
}
