import {
  Box,
  Grid,
  Button,
  CircularProgress,
  Paper,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { bhpColor } from "styles/globals";
import SearchIcon from "@material-ui/icons/Search";
import { debounce } from "lodash";
import { ScheduleType } from "interfaces/Schedule";
import { Autocomplete } from "@material-ui/lab";
import { capitalizeString } from "utils/string";
import { Team } from "interfaces/Teams";
import { useHistory } from "react-router";
import { useTeamStore, useUserStore } from "providers/RootStoreProvider";
//import useMediaQuery from "utils/useMediaQuery";

interface TeamDetailsPlannerNewAppointmentProps {
  teamId: number;
  t: any;
}

const TeamDetailsPlannerNewAppointment = observer(
  (props: TeamDetailsPlannerNewAppointmentProps) => {
    const { teamId, t } = props;
    const teamStore = useTeamStore();
    const userStore = useUserStore();
    const theme = useTheme();
    const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
    const history = useHistory();

    const jumpToTeamsNewAppointment = async () => {
      const res = await userStore.getUsersByTeamId(teamId);
      let email_list = res.map(({ email }) => email);
      window.open(
        "https://pod51219.outlook.com/?path=/calendar/action/compose&to=" +
          email_list.toString() +
          "",
        "_blank"
      );
    };

    const drawNewAppointmentButton = () => {
      return (
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: smAndUp ? 22 : 0,
            marginRight: smAndUp ? theme.spacing(8) : 15,
            marginBottom: smAndUp ? -130 : 4,
          }}
        >
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={jumpToTeamsNewAppointment}
          >
            + {t(`New Appointment`)}
          </Button>
        </Box>
      );
    };

    return (
      <>
        <Box>{drawNewAppointmentButton()}</Box>
        <Box px={smAndUp ? 4 : 2} py={smAndUp ? 4 : 1}>
          <Grid container spacing={smAndUp ? 4 : 2}></Grid>
        </Box>
      </>
    );
  }
);
export default TeamDetailsPlannerNewAppointment;
