import { createStyles, makeStyles, Theme, Grid, Box, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { bhpColor } from "styles/globals";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiMapMarkerOutline } from "@mdi/js";
import { mdiClockTimeFourOutline } from "@mdi/js";
import moment from "moment";

interface EventDetailsBodyProps {
  date: string;
  time: string;
  location?: string;
  information: string;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventDetailsBox: {
      padding: theme.spacing(3),
      whiteSpace: "normal",
    },
    heading: {
      color: bhpColor.grey1,
      fontSize: 20,
      fontWeight: "bold",
    },
    detailsBody: {
      color: bhpColor.blueGrey2,
      fontSize: 20,
      padding: theme.spacing(3),
    },
    informationBody: {
      color: bhpColor.grey1,
      fontSize: 18,
      fontWeight: "normal",
      padding: theme.spacing(3),
    },
    icon: {
      alignSelf: "center",
      height: 25,
      width: 25,
      color: bhpColor.blueGrey2,
      marginTop: 4,
    },
    time: {
      color: bhpColor.blueGrey2,
      fontWeight: "normal",
    },
    location: {
      color: bhpColor.blueGrey2,
      fontWeight: "normal",
    },
    daysLeft: { color: bhpColor.grey1, fontWeight: "normal" },
  })
);

const EventDetailsBody = observer((props: EventDetailsBodyProps) => {
  const { date, time, location, information } = props;
  const classes = innerStyles();
  const { t, i18n } = useTranslation();
  const calculateDaysLeftLabel = (date: string): string => {
    const noOfDaysLeft = moment(date).diff(moment(), "days");
    if (noOfDaysLeft === 0) {
      return "Today";
    } else if (noOfDaysLeft === 1) {
      return `In ${noOfDaysLeft} day`;
    } else if (noOfDaysLeft > 1 && noOfDaysLeft < 31) {
      return `In ${noOfDaysLeft} days`;
    } else {
      return "";
    }
  };
  return (
    <>
      <Box className={classes.eventDetailsBox}>
        <Typography variant="h6" component="div" className={classes.heading}>
          Details:
        </Typography>
        <Box className={classes.detailsBody}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container item xs={12}>
                <Grid item xs={1} className={classes.icon}>
                  <Icon path={mdiClockTimeFourOutline} size={1} />
                </Grid>
                <Grid item xs={11}>
                  <Grid item container direction="column">
                    <Grid item className={classes.time}>
                      {time}
                    </Grid>
                    <Grid item className={classes.daysLeft}>
                      {calculateDaysLeftLabel(date)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {location && location != "" && (
                <Grid container item xs={12}>
                  <Grid item xs={1} className={classes.icon}>
                    <Icon path={mdiMapMarkerOutline} size={1} />
                  </Grid>
                  <Grid item xs={11}>
                    <Grid item container direction="column">
                      <Grid item className={classes.location}>
                        {location}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={classes.eventDetailsBox}>
        <Typography variant="h6" component="div" className={classes.heading}>
          Information:
        </Typography>
        <Box className={classes.informationBody}>
          <Grid container>
            <Grid item xs={12}>
              {information}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
});
export default EventDetailsBody;
