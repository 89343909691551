import {
  Avatar,
  Box,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { wdpStrings } from "config/strings";
import { Building } from "interfaces/Building";
import { observer } from "mobx-react-lite";
import { useRootStore } from "providers/RootStoreProvider";
import { useEffect, useState } from "react";
import { bhpColor, settings } from "styles/globals";
import Topbar from "./Topbar";
import { useTranslation } from "react-i18next";
import InformationIcon from "components/utils/InformationIcon";
import { PopupInformationWidget } from "components/settings/PopupInformationWidget";
import { api } from "utils/bridge";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainTable: {
      "& td": {
        // borderBottom: 0,
      },
    },
    textArea: {
      height: 32,
      width: "100%",
      paddingLeft: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      backgroundColor: `${bhpColor.blueGrey4}50`,
    },
    avatar: {
      backgroundColor: "rgb(250, 182, 54)",
      color: "white",
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginLeft: "16px",
      fontSize: "16px",
    },
  })
);

interface DialogData {
  title: string | null;
  content: string | null;
  primaryButtonText: string | null;
  secondaryButtonText?: string | null;
}

const Profile = observer(() => {
  const store = useRootStore();
  const classes = innerStyles();
  const { t, i18n } = useTranslation();
  const [informationOpen, setInformationOpen] = useState(false);
  const [informationModal, setInformationModal] = useState<DialogData | null>();

  const handleInformationOpen = (infoId: number) => {
    openInformationModal(api.getModalProps(t, infoId));
  };

  const openInformationModal = (informationData: DialogData) => {
    setInformationModal(Object.assign({}, informationData));
    setInformationOpen(true);
  };

  const handleInformationClose = () => {
    setInformationOpen(false);
  };

  const subHeadAndInfo = (label: string, infoId: number) => {
    return (
      <Box style={{ display: "flex" }}>
        <Typography>{t(label)}</Typography>
        {infoId ? (
          <InformationIcon onclick={() => handleInformationOpen(infoId)} color={bhpColor.orange1} />
        ) : (
          ""
        )}
      </Box>
    );
  };

  const DrawRow = (
    label: string,
    value: string = "",
    urlText: string = "",
    urlLink: string = "",
    infoId: number = 0
  ) => {
    return (
      <>
        <Grid item xs={12} sm={3}>
          {subHeadAndInfo(label, infoId)}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper variant="outlined" className={classes.textArea}>
            <Box display="flex">{value}</Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Link href={urlLink} underline="always" target="_blank" variant="subtitle2">
            {t(urlText)}
          </Link>
        </Grid>
      </>
    );
  };

  const getBuildingDisplayName = (theBuilding: Building | null) => {
    return theBuilding ? `${theBuilding.city}, ${theBuilding.building_name}` : "";
  };

  useEffect(() => {
    store.userStore.getMe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Topbar title={t("Profile")} subtitle={t("View your profile")} breadcrumb={t("Profile")} />
      <Box py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }}>
        <Typography variant="h3">{t("Your profile")}</Typography>
        <Box mt={1}>{t("Profile.attributes")}</Box>
        <Box my={4}>
          <Grid container className={classes.mainTable}>
            <Grid item xs={12} lg={7}>
              <Grid container alignItems="center" spacing={2}>
                {DrawRow(
                  "Access",
                  `${
                    store.userStore.me?.is_superuser
                      ? "Super User"
                      : store.userStore.me?.is_staff
                      ? "Admin User"
                      : "Everyday User"
                  }`,
                  "Request additional access",
                  "https://bhp.service-now.com/sp?id=sc_category&sys_id=c1cd74d3db35c3002ffff06dbf9619f5&catalog_id=-1",
                  9
                )}
                {DrawRow(
                  "Name",
                  `${store.userStore.me?.first_name} ${store.userStore.me?.last_name}`
                )}
                {store.userStore.me?.profile.preferred_name &&
                  DrawRow("Display Name", `${store.userStore.me?.profile.preferred_name}`)}
                {DrawRow(
                  "Role Title",
                  store.userStore.me?.profile.employee_position || t("Not available")
                )}
                {DrawRow("Manager", store.userStore.me?.profile.manager_name || t("Not available"))}
                {DrawRow("Email", store.userStore.me?.email || t("Not available"))}
                {DrawRow(
                  "Phone",
                  store.userStore.me?.profile.employee_mobile_number || t("Not available")
                )}
                {DrawRow(
                  "Location",
                  store.userStore.defaultOffice
                    ? getBuildingDisplayName(store.userStore.defaultOffice)
                    : t("Not available")
                )}
                {(store.userStore.me?.profile.is_fire_warden ||
                  store.userStore.me?.profile.is_first_aid_officer) &&
                  DrawRow(
                    [
                      store.userStore.me?.profile.is_fire_warden ? t("Fire Warden") : null,
                      store.userStore.me?.profile.is_first_aid_officer
                        ? t("First Aid Officer")
                        : null,
                    ]
                      .filter((x) => x !== null)
                      .join(", ")
                  )}
                <Grid item xs={12} sm={3}>
                  {t("Profile Image")}
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Avatar className={classes.avatar} src={store.userStore.profilePicture}>
                    {store.userStore.getInitials()}
                  </Avatar>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box py={2}>
            <Link
              href={wdpStrings.page.profile.changeProfileLink}
              color="primary"
              target="new"
              style={{ textDecoration: "underline" }}
            >
              {t("Edit profile attributes")}
            </Link>
          </Box>
        </Box>
      </Box>
      <PopupInformationWidget
        t={t}
        isOpen={informationOpen}
        handleClose={handleInformationClose}
        handleSecondaryClick={handleInformationClose}
        title={informationModal?.title || null}
        content={informationModal?.content || null}
        buttonText={informationModal?.primaryButtonText || null}
        secondaryButtonText={informationModal?.secondaryButtonText || null}
      />
    </>
  );
});

export default Profile;
