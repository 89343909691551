import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import EventSeat from "@material-ui/icons/EventSeat";
import Box from "@material-ui/core/Box";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useBookingStore, useScheduleStore, useUserStore } from "providers/RootStoreProvider";

import Topbar from "./Topbar";
import ScheduleTab from "components/schedule/ScheduleTab";
import LoadingZone from "components/schedule/LoadingZone";
import BookingTab from "components/schedule/BookingTab";
import BookSpaceRequiredConfirm from "components/schedule/dialogs/BookSpaceRequiredConfirm";
import BookingBeingRemovedConfirm from "components/schedule/dialogs/BookingBeingRemovedConfirm";
import { useTranslation } from "react-i18next";

import { actualStartOfWeek, endOfPeriod } from "utils/date";
import { toJS } from "mobx";
import { getBuildingBookingSpecs } from "utils/hourlyBookings";
import LeaveTab from "components/schedule/LeaveTab";
import { mdiAirplane } from "@mdi/js";
import Icon from "@mdi/react";

interface TabPanelProps {
  children?: React.ReactNode;
}

const TabPanel = observer((props: TabPanelProps) => {
  const { children, ...other } = props;
  const scheduleStore = useScheduleStore();

  return (
    <div role="tabpanel" {...other}>
      <Box bgcolor="white" position="relative" px={2} py={3}>
        {scheduleStore.isLoading && <LoadingZone isLoading={scheduleStore.isLoading} />}
        {children}
      </Box>
    </div>
  );
});

const Schedule = observer(() => {
  const theme = useTheme();
  const history = useHistory();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const scheduleStore = useScheduleStore();
  const bookingStore = useBookingStore();
  const userStore = useUserStore();
  const { t, i18n } = useTranslation();

  const plannerTab = {
    label: "Planner",
    link: "/my-planner",
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <FormatListBulleted fontSize="small" />
      </Box>
    ),
    component: (
      <TabPanel>
        <ScheduleTab t={t} />
      </TabPanel>
    ),
  };

  const bookingsTab = {
    label: "Bookings",
    link: "/my-planner/bookings",
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <EventSeat fontSize="small" />
      </Box>
    ),
    component: (
      <TabPanel>
        <BookingTab t={t} />
      </TabPanel>
    ),
  };

  const leaveTab = {
    label: "Leave",
    link: "/my-planner/leave",
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <Icon path={mdiAirplane} size={"20px"} />
        {/* <EventSeat fontSize="small" /> */}
      </Box>
    ),
    component: (
      <TabPanel>
        <LeaveTab t={t} />
      </TabPanel>
    ),
  };

  const tabs = [plannerTab, bookingsTab, leaveTab];

  const handleChange = (tabLink: string) => {
    history.push(tabLink);
  };

  useEffect(() => {
    // we reset these on laoding this page so we dont show a modal
    // if/when navigating away
    scheduleStore.scheduleChangingToOffice = null;
    scheduleStore.scheduleChangingAwayFromOfficeWithBooking = null;
    if (userStore.me) {
      scheduleStore.loadSchedules();
      const schedulePeriod = {
        start: actualStartOfWeek(new Date()),
        end: endOfPeriod(actualStartOfWeek(new Date()), 15),
      };
      bookingStore.loadUserBookings(schedulePeriod, userStore.me.id.toString());
    }
  }, [scheduleStore, bookingStore, userStore.me]); // eslint-disable-line react-hooks/exhaustive-deps

  const getTabType = (): "fullWidth" | "standard" => {
    if (smAndUp) {
      return "standard";
    } else {
      return "fullWidth";
    }
  };
  const currentTab = history.location.pathname;
  const selectedTab = tabs.find((tab) => currentTab === tab.link);

  return (
    <>
      <Topbar
        title={t("My Planner")}
        subtitle={t("Edit or view your location planner")}
        breadcrumb={t("Planner")}
      />
      <BookSpaceRequiredConfirm t={t} />
      <BookingBeingRemovedConfirm t={t} />
      <Box p={{ xs: 0, sm: 2, md: 4 }}>
        <Tabs
          value={currentTab}
          variant={getTabType()}
          onChange={(event, value) => handleChange(value)}
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.link} icon={tab.icon} value={tab.link} label={t(tab.label)} />
          ))}
        </Tabs>
        {selectedTab && selectedTab.component}
      </Box>
    </>
  );
});

export default Schedule;
