import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { bhpColor } from "../../styles/globals";

const globals = {
  toggleButtonWidth: 88,
};

export const SettingsWorkdayWidgetStyles = makeStyles((theme: Theme) =>
  createStyles({
    routineFormControl: {
      width: "85%",
      margin: 0,
      borderRadius: 4,
      height: 178,
      paddingTop: 10,
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: 24,
      marginBottom: 24,
      backgroundColor: `${bhpColor.blueGrey4}30`,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        backgroundColor: "transparent",
        height: 40,
        // width: 346,
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        alignItems: "center",
      },
      "&.lighten": {
        [theme.breakpoints.up("md")]: {
          backgroundColor: `${bhpColor.white} !important`,
        },
      },
    },
    routineToggleButtonGroup: {
      fontWeight: "bold",
      color: bhpColor.grey1,
      "& .MuiToggleButtonGroup-groupedVertical:not(:first-child)": {
        marginTop: 6,
      },
      [theme.breakpoints.up("md")]: {
        minWidth: globals.toggleButtonWidth + 24,
        "& .MuiToggleButtonGroup-groupedVertical:not(:first-child)": {
          marginTop: -1,
        },
      },
    },
    routineWeekend: {
      backgroundColor: `${bhpColor.blueGrey4}30 !important`,
      [theme.breakpoints.down("sm")]: {
        backgroundColor: `${bhpColor.blueGrey3}40 !important`,
      },
    },
    routineButtonPanel: {
      width: 346,
      [theme.breakpoints.up("md")]: {
        width: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    routineToggleButton: {
      justifyContent: "left",
      fontSize: 12,
      fontWeight: "bold",
      paddingLeft: 8,
      borderRadius: "4px !important",
      border: `2px solid ${bhpColor.blueGrey2} !important;`,
      margin: 6,
      marginLeft: "auto",
      marginRight: "auto",
      width: "90%",
      height: 30,
      padding: 0,
      textTransform: `unset`,
      lineHeight: "normal",
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        paddingLeft: 2,
      },

      "&.office": {
        textAlign: "left",
        color: bhpColor.blueGrey1,
        border: `2px solid ${bhpColor.blueGrey1} !important;`,
      },
      "&.remote": {
        color: bhpColor.blueGrey2,
        border: `2px solid ${bhpColor.blueGrey2} !important;`,
      },
      "&.off": {
        color: bhpColor.grey2,
        border: `2px solid ${bhpColor.grey2} !important;`,
      },
      "& svg": {
        width: 10,
        height: 10,
        marginRight: 4,
      },
      "&.Mui-selected": {
        color: bhpColor.white,
        "&.office": {
          backgroundColor: bhpColor.blueGrey1,
        },
        "&.remote": {
          backgroundColor: bhpColor.blueGrey2,
        },
        "&.off": {
          backgroundColor: bhpColor.grey2,
        },
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "3px",
        marginRight: "3px",
      },
    },

    editButton: {
      justifyContent: "center",
      fontSize: 11,
      fontWeight: "normal",
      borderRadius: "4px !important",
      textTransform: `unset`,
      lineHeight: "normal",
      color: `${bhpColor.black} !important`,
      backgroundColor: `${bhpColor.blueGrey4} !important`,
      height: 30,
    },
    editButtonDisabled: {
      //backgroundColor: "#D3D3D3 !important",
      color: `${bhpColor.grey2} !important`,
    },
    displayHours: {
      justifyContent: "center",
      textAlign: "center",
      fontSize: 11,
      height: "28px",
      fontWeight: "normal",
      textTransform: "none",
      backgroundColor: "transparent",
      marginTop: 10,
      color: bhpColor.black,
    },
  })
);
