import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  CircularProgress,
  Slide,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { useState, useRef, useEffect } from "react";

import { bhpColor } from "styles/globals";
import HeaderImage from "assets/headerImage.png";
import { confirmDialogSize } from "interfaces/Utils";

const PopupStyles = makeStyles((theme) => ({
  root: {
    color: bhpColor.blueGrey1,
    "& .MuiPaper-root": {
      color: bhpColor.blueGrey1,
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(2),
    },
  },
  dialogTitle: {
    backgroundImage: `url(${HeaderImage})`,
    color: bhpColor.white,
    "& h2": {
      fontWeight: "bold",
      "& svg": {
        verticalAlign: "text-top",
      },
    },
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    "&.snug": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    paddingLeft: 16,
  },
  mainItem: {
    flex: "auto",
  },
  bottomItem: {
    paddingBottom: 0,
  },
  circularIcon: {
    color: "white",
  },
  cancelButton: {
    "&:hover": {
      backgroundColor: `${bhpColor.orange4}50`,
    },
  },
}));

interface TeamIloAddActivityConfirmDialogProps {
  title?: string | null;
  primaryButtonText?: string | null;
  secondaryButtonText?: string | null;
  thirdButtonText?: string | null;
  isOpen: boolean;
  handlePrimaryClick: any;
  handleSecondaryClick: any;
  handleThirdButtonClick?: any;
  children?: React.ReactNode;
  dialogWidth?: string;
  disablePrimary?: boolean;
  maxWidth?: any;
  snug?: any; //remove any padding from the top and bottom
  centered?: any; // effort to center dialog
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function TeamIloAddActivityConfirmDialog(props: TeamIloAddActivityConfirmDialogProps) {
  const classes = PopupStyles();
  const [saving, setSaving] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const primaryTextRef = useRef<HTMLSpanElement>(null);

  const {
    title,
    primaryButtonText,
    secondaryButtonText,
    thirdButtonText,
    isOpen,
    handlePrimaryClick,
    handleSecondaryClick,
    handleThirdButtonClick,
    dialogWidth,
    disablePrimary = false,
    maxWidth,
    snug,
    centered,
  } = props;

  return (
    <Dialog
      TransitionComponent={Transition}
      open={isOpen}
      className={`${classes.root}`}
      style={{
        width: dialogWidth || "",
        maxWidth: maxWidth || "",
        inset: centered ? "10%" : "",
        position: centered ? "fixed" : "relative",
        top: centered ? "0%" : "",
      }}
    >
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={`${classes.content} ${snug ? "snug" : ""}`}>
        {props.children}
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button
          onClick={async () => {
            setSaving(true);
            await handlePrimaryClick();
            setSaving(false);
          }}
          variant="contained"
          color="primary"
          disableElevation
          disabled={disablePrimary}
        >
          {!saving && (
            <span
              ref={(el) => {
                if (el) {
                  setHeight(el.offsetHeight);
                  setWidth(el.offsetWidth);
                }
              }}
            >
              {primaryButtonText || "Confirm"}
            </span>
          )}
          {saving && (
            <span style={{ width: width, height: height }}>
              <CircularProgress className={classes.circularIcon} size={20} />
            </span>
          )}
        </Button>
        {thirdButtonText && handleThirdButtonClick && (
          <Button
            onClick={handleThirdButtonClick}
            style={{
              display: `${thirdButtonText ? "inline-flex" : "none"}`,
              backgroundColor: bhpColor.red,
              color: bhpColor.white,
            }}
            variant="contained"
            disableElevation
          >
            {thirdButtonText}
          </Button>
        )}
        <Button
          onClick={handleSecondaryClick}
          className={classes.cancelButton}
          style={{ display: `${secondaryButtonText ? "inline-flex" : "none"}` }}
        >
          {secondaryButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
