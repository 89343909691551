// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { toJS } from "mobx";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import OfficeCapacityWidget_V2 from "components/dashboard/officeCapacity/OfficeCapacityWidget_V2";
import { BuildingType, BuildingWithCapacity } from "interfaces/Building";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useBuildingStore, useTeamStore, useUserStore } from "providers/RootStoreProvider";
import { useEffect, useState } from "react";
import { Fragment } from "react";
import { bhpColor, settings } from "styles/globals";
import OfficeCurrentCapacity from "./OfficeCurrentCapacity";
import OfficeTabPanel from "./OfficeTabPanel";
import WorkingStatusSummary from "components/utils/WorkingStatusSummary";
import { ScheduleType } from "interfaces/Schedule";

interface OfficeDetailProps {
  building: BuildingWithCapacity | null;
  t: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiGrid-container": {
        display: "flex",
        [theme.breakpoints.down("md")]: {
          display: "grid",
        },
      },
    },
    capacityBlock: {
      minWidth: "150px",
      marginBottom: "17px",
      [theme.breakpoints.down("md")]: {
        backgroundColor: bhpColor.backgroundGrey,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  })
);

const OfficeDetailPage = observer((props: OfficeDetailProps) => {
  const { building, t } = props;
  const classes = innerStyles();
  const todayDate = new Date();
  const userStore = useUserStore();
  const buildingStore = useBuildingStore();
  const teamStore = useTeamStore();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [focussedDate, setFocussedDate] = useState(moment(todayDate).format("YYYY-MM-DD"));
  const [isLoaded, setIsLoaded] = useState(false);

  const numberOfDaysOnChart = 6;
  const cacheNumberOfDays = 14;
  const [displayStart, setDisplayStart] = useState(moment(todayDate).format("YYYY-MM-DD"));
  const [displayEnd, setDisplayEnd] = useState(
    moment(new Date()).add(numberOfDaysOnChart, "d").format("YYYY-MM-DD")
  );

  const cacheStartDate = moment(todayDate).format("YYYY-MM-DD");
  const [cacheEndDate, setCacheEndDate] = useState(
    moment(todayDate).add(14, "days").format("YYYY-MM-DD")
  );

  const changeFocussedDate = (dte: string) => {
    setFocussedDate(dte);
  };

  const addDaysToDate = (date: string, amount: number) => {
    return moment(date).add(amount, "d").format("YYYY-MM-DD");
  };

  const scrollTo = (direction: number) => {
    //setStartDate(date);
    if (direction < 0) {
      const newStart = addDaysToDate(displayStart, -1);
      setDisplayStart(
        moment(newStart).isBefore(todayDate) ? moment(todayDate).format("YYYY-MM-DD") : newStart
      );
      setDisplayEnd(
        addDaysToDate(
          moment(newStart).isBefore(todayDate) ? moment(todayDate).format("YYYY-MM-DD") : newStart,
          numberOfDaysOnChart
        )
      );
    }
    if (direction > 0) {
      if (
        moment(addDaysToDate(displayEnd, 1)).isSameOrBefore(
          addDaysToDate(cacheStartDate, cacheNumberOfDays)
        )
      ) {
        setDisplayStart(addDaysToDate(displayStart, 1));
        setDisplayEnd(addDaysToDate(displayEnd, 1));
        if (moment(displayEnd).isSameOrAfter(moment(cacheEndDate))) {
          setCacheEndDate(
            moment(cacheEndDate)
              .add(numberOfDaysOnChart + 1, "d")
              .format("YYYY-MM-DD")
          );
        }
      }
    }
  };

  const [focussedTeam, setFocussedTeam] = useState<number | null>(
    userStore.me?.profile.team || null
  );

  const changeTeam = (teamId: string) => {
    setIsLoaded(false);
    setInitialFocussedTeam(teamId === "" ? null : parseInt(teamId));
    setFocussedTeam(teamId === "" ? null : parseInt(teamId));
  };

  const isOffice = building && building.building_type === BuildingType.OFFICE;

  const [calculatedTotal, setCalculatedTotal] = useState<number>(
    building ? building.total_capacity || 0 : 0
  );

  const findCalculatedTotal = async () => {
    if (building && calculatedTotal === 0) {
      const theNewTotal = await buildingStore.getCapacityFromDefaultUsers(building.id);
      setCalculatedTotal(theNewTotal);
    }
  };

  useEffect(() => {
    if (!buildingStore.isLoading && !isOffice && building && building.total_capacity === 0)
      findCalculatedTotal();
  }, []);

  const [initialFocussedTeam, setInitialFocussedTeam] = useState<number | null>(null);

  useEffect(() => {
    if (!isLoaded && !buildingStore.isLoadingCapacity) {
      if (building) {
        buildingStore.loadBuildingWithCapacity(building.id, cacheStartDate, cacheEndDate);
        buildingStore.loadBuildingSchedule(
          building.id,
          initialFocussedTeam,
          cacheStartDate,
          cacheEndDate
        );
        setIsLoaded(true);
        setInitialFocussedTeam(focussedTeam);
      }
    }
  }, [building, isLoaded, cacheStartDate, cacheEndDate, buildingStore, userStore, focussedTeam]);

  useEffect(() => {
    teamStore.loadMyTeams();
    if (userStore.me?.profile.team && !focussedTeam) setFocussedTeam(userStore.me?.profile.team);
  }, [userStore.me, focussedTeam]);

  return building ? (
    <Fragment>
      <Box py={2}>
        <Typography>{t("Today")}</Typography>
        <Typography variant="h3">
          {t(moment(todayDate).format("dddd"))},
          <span style={{ fontWeight: "normal" }}>{`${t(moment(todayDate).format("Do"))} ${t(
            "of"
          )} ${t(moment(todayDate).format("MMMM"))}`}</span>
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} md={6} style={{ display: "flex", alignItems: "center" }}>
          <Box width={smAndUp ? "unset" : "100%"} pb={{ xs: 1, sm: 0 }}>
            <WorkingStatusSummary
              t={t}
              count={building.booked_attendance || 0}
              workingStatus={ScheduleType.OFFICE}
              text={t("Attendance - Office")}
              backgroundColor={smAndUp ? bhpColor.white : bhpColor.backgroundGrey}
              width={smAndUp ? undefined : "100%"}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{ justifyContent: "end", display: "grid" }}>
          <OfficeCapacityWidget_V2
            t={t}
            date={todayDate}
            hideName={true}
            fullWidth={true}
            snug={true}
            transparent={true}
            buildingDetail={
              building
                ? {
                    ...building,
                    total_capacity:
                      building.total_capacity && building.total_capacity > 0
                        ? building.total_capacity
                        : calculatedTotal,
                  }
                : null
            }
          />
        </Grid>
      </Grid>
      <Box>
        <OfficeTabPanel
          t={t}
          building={{
            ...building,
            total_capacity:
              building.total_capacity && building.total_capacity > 0
                ? building.total_capacity
                : calculatedTotal,
          }}
          team={focussedTeam ? teamStore.myTeams.filter((tm) => tm.id == focussedTeam)[0] : null}
          focussedDate={focussedDate}
          focussedTeam={focussedTeam}
          displayStart={displayStart}
          displayEnd={displayEnd}
          changeDate={changeFocussedDate}
          changeTeam={changeTeam}
          scrollTo={scrollTo}
        />
      </Box>
    </Fragment>
  ) : (
    <Box>{t("No building to view...")}</Box>
  );
});

export default OfficeDetailPage;
