import { Box, Button, FormControl } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Icon from "@mdi/react";
import { mdiCalendarRemoveOutline, mdiHeadset, mdiMapMarkerRadiusOutline } from "@mdi/js";
import { SettingsWorkdayWidgetStyles } from "./SettingsWorkdayWidgetStyles";
import { Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { wdpStrings } from "config/strings";
import { SettingsScheduleType } from "interfaces/Schedule";
import moment from "moment";
import { useState, useEffect } from "react";
import { useBuildingStore } from "providers/RootStoreProvider";
import { cityAbbr } from "utils/locations";

interface SettingsWorkdayWidgetProps {
  orientation: "vertical" | "horizontal";
  dayIdx: number;
  day: string;
  workType: number | null;
  onChangeValue: any;
  t: any;
  onEditHoursLocationClick?: any;
  settingsDefaultWeek?: SettingsScheduleType;
}

const DisplayHours = (props: { startTime: any; finishTime: any; workType: any }) => {
  const classes = SettingsWorkdayWidgetStyles();

  const { startTime, finishTime, workType } = props;
  return (
    <Box
      className={classes.displayHours}
      style={{ visibility: `${workType === 2 || workType === null ? "hidden" : "visible"}` }}
    >
      {startTime && finishTime ? (
        <Box>
          {moment(startTime, ["HH:mm", "H:mm"]).format("hh:mm A")} -{" "}
          {moment(finishTime, ["HH:mm", "H:mm"]).format("hh:mm A")}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export function SettingsWorkdayWidget(props: SettingsWorkdayWidgetProps) {
  const classes = SettingsWorkdayWidgetStyles();
  const buildingStore = useBuildingStore();
  const {
    orientation,
    day,
    dayIdx,
    onChangeValue,
    workType,
    t,
    onEditHoursLocationClick,
    settingsDefaultWeek,
  } = props;
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [enableHoursAndLocation, setEnableHoursAndLocation] = useState(false);

  const getOfficeLocationById = (officeId: any) => {
    return buildingStore.getBasicBuilding(officeId);
  };

  useEffect(() => {
    if (workType === 2 || workType === null) {
      setEnableHoursAndLocation(false);
    } else {
      setEnableHoursAndLocation(true);
    }
  }, [workType]);

  return (
    <FormControl variant="outlined" className={`${classes.routineFormControl}`}>
      <ToggleButtonGroup
        orientation={orientation}
        exclusive
        className={classes.routineToggleButtonGroup}
        aria-label={`worktype day ${day}`}
        value={workType}
        onChange={(e) => onChangeValue(e, dayIdx)}
        key={`dytbg${dayIdx}`}
      >
        <ToggleButton
          value={0}
          className={`${classes.routineToggleButton} office`}
          aria-label={t("Office")}
        >
          <Icon style={{ width: 20, height: 20 }} path={mdiMapMarkerRadiusOutline} />
          {cityAbbr(getOfficeLocationById(settingsDefaultWeek?.office)) || t("BHP Location")}
        </ToggleButton>
        <ToggleButton
          value={1}
          className={`${classes.routineToggleButton} remote`}
          aria-label={t("Remote")}
        >
          <Icon style={{ width: 16, height: 16 }} path={mdiHeadset} />
          {"Remote"}
        </ToggleButton>
        <ToggleButton
          value={2}
          className={`${classes.routineToggleButton} off`}
          aria-label={t("Off")}
        >
          <Icon style={{ width: 16, height: 16 }} path={mdiCalendarRemoveOutline} /> {"Off"}
        </ToggleButton>
        {smAndUp && (
          <DisplayHours
            startTime={settingsDefaultWeek?.start_time}
            finishTime={settingsDefaultWeek?.finish_time}
            workType={workType}
          />
        )}
        {smAndUp && (
          <Button
            className={`${classes.editButton} ${
              !enableHoursAndLocation ? classes.editButtonDisabled : ""
            }`}
            aria-label={t("Edit Hours/Location")}
            onClick={() => {
              onEditHoursLocationClick(day, workType, settingsDefaultWeek);
            }}
            disabled={!enableHoursAndLocation}
          >
            {t("Edit Hours/Location")}
          </Button>
        )}
      </ToggleButtonGroup>
    </FormControl>
  );
}
