import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Hidden,
  Snackbar,
} from "@material-ui/core";
import Topbar from "./Topbar";
import { teamStyles } from "styles/teamStyles";
import { observer } from "mobx-react-lite";
import { useTeamStore, useUserStore } from "providers/RootStoreProvider";
import { useEffect, useState } from "react";
import { TeamCategoryType, TeamVisibilityType, TeamWorkSchedule } from "interfaces/Teams";
import moment from "moment";
import { ScheduleType } from "interfaces/Schedule";
import { getIconPath } from "utils/icons";
import TeamWorkingStatusSummary from "components/team/TeamWorkingStatusSummary";
import TeamsTabPanel from "components/team/TeamsTabPanel";
import { getTeamsScheduleStatusTypeText } from "utils/teams";
import { displaySettings } from "styles/globals";
import { getWorkTypeColor } from "utils/backGroundColor";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";
import TeamDetailsPlannerNewAppointment from "components/team/TeamDetailsPlannerNewAppointment";

const TeamsDetails = observer((props: any) => {
  const teamStore = useTeamStore();
  const userStore = useUserStore();
  const classes = teamStyles();
  const { t, i18n } = useTranslation();
  const [selectedTeam, setSelectedTeam] = useState<TeamWorkSchedule | null>(null);
  const [isValidTeam, setIsValidTeam] = useState(true);
  const teamId = props.match.params.teamId;
  const todayDate = new Date();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("md"));

  const history = useHistory();

  const [isInTeam, setIsInTeam] = useState(false);
  const [isTeamOwner, setIsTeamOwner] = useState(false);

  const isCreate = !teamId || teamId === "create";

  const [saveState, setSaveState] = useState(true);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState<string>(t("Team Update Successful"));
  const snackbarOpen = () => setSnackOpen(true);
  const snackbarClose = () => setSnackOpen(false);
  const setSnackBox = (success: boolean, msg?: string) => {
    setSnackMessage(success ? t("Team Update Successful") : `${t(msg || "No luck...")}`);
    setSaveState(success);
  };

  useEffect(() => {
    if (teamStore.myTeams.length < 1) teamStore.loadMyTeams();
    if (teamId && !isCreate) {
      teamStore.setCurrentTeam(teamId);
      (async () => {
        if (teamId && !isCreate) {
          const response = await teamStore.getTeamScheduleForDates(
            teamId,
            moment(todayDate).format("yyyy-MM-DD"),
            moment(todayDate).add(1, "d").format("yyyy-MM-DD")
          );
          if (response) setSelectedTeam(response[0]);
          const isValid = await teamStore.teamExists(teamId.toString());
          setIsValidTeam(isValid);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (!isCreate) {
      setIsInTeam(teamStore.amIInTeam(Number(teamId)));
      setIsTeamOwner(teamStore.amITeamOwner(Number(teamId), userStore.me!.id));
    }
  }, [teamStore.myTeams, isInTeam, isTeamOwner]);

  const topBarBreadcrumb = `${t("Teams")} ${selectedTeam ? ` > ${selectedTeam.name}` : ""}`;
  const topbarTitle = isCreate ? t("Create Team") : `${selectedTeam ? `${selectedTeam.name}` : ""}`;
  const teamScheduleSummary: JSX.Element[] = [];

  if (!isCreate) {
    for (const type of [ScheduleType.OFFICE, ScheduleType.REMOTE, ScheduleType.OFF]) {
      teamScheduleSummary.push(
        <Grid item lg={2} xs={7} key={type} className={classes.teamWorkSummarySpacing}>
          <Box paddingTop={3} className={classes.capacityBlock}>
            <TeamWorkingStatusSummary
              count={
                selectedTeam?.schedules[0]?.schedule_status_counts?.find(
                  (item) => item.status === type
                )?.count || 0
              }
              iconPath={getIconPath(type)}
              text={getTeamsScheduleStatusTypeText(t, type)}
              color={getWorkTypeColor(type)}
            />
          </Box>
        </Grid>
      );
    }
  }

  const hasPath =
    history.location.pathname.indexOf("planner") > -1 ||
    history.location.pathname.indexOf("people") > -1 ||
    history.location.pathname.indexOf("noticeboard") > -1 ||
    history.location.pathname.indexOf("leave") > -1 ||
    history.location.pathname.indexOf("ilo") > -1;
  const [targetPanel, setTargetPanel] = useState(hasPath ? "" : "information");

  const joinLeaveGroup = async () => {
    const res = isInTeam
      ? await teamStore.leaveTeam(Number(teamId))
      : await teamStore.joinTeam(Number(teamId));
    if (res.status === 200) {
      setIsInTeam(!isInTeam);
      setSnackBox(true);
      snackbarOpen();
    } else {
      setSnackBox(false, res.data.response.data.errors);
      snackbarOpen();
    }
    const res2 = await teamStore.loadMyTeams();
    setTargetPanel("information");
  };

  const drawJoinLeaveButton = () => {
    return (
      !teamStore.isLoading &&
      !isCreate &&
      teamStore.currentTeam &&
      !isTeamOwner &&
      (teamStore.currentTeam.visibility === TeamVisibilityType.VISIBLE ||
        (teamStore.currentTeam.visibility === TeamVisibilityType.CLOSED && isInTeam)) &&
      teamStore.currentTeam.category !== TeamCategoryType.ORGANISATIONAL_UNIT && (
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "-70px",
            marginRight: theme.spacing(4),
            marginBottom: "32px",
          }}
        >
          <Button variant="contained" color="primary" onClick={joinLeaveGroup}>
            {isInTeam ? t("LEAVE") : t("JOIN")}
          </Button>
        </Box>
      )
    );
  };

  return (
    <>
      {isValidTeam || isCreate ? (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            className={saveState ? classes.saveSuccess : classes.saveFailed}
            autoHideDuration={displaySettings.snackDelay}
            open={snackOpen}
            onClose={snackbarClose}
            message={snackMessage}
          />
          <Topbar
            title={topbarTitle}
            subtitle={
              isCreate
                ? t("Complete team details for your new team")
                : t("View your team's way of working")
            }
            breadcrumb={topBarBreadcrumb}
          />
          {drawJoinLeaveButton()}
          {!isCreate ? (
            <Box py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }}>
              <Typography variant={`${smAndUp ? "h2" : "h5"}`}>{t("Today")}</Typography>
              <Typography variant={`${smAndUp ? "h3" : "h4"}`}>
                {t(moment(todayDate).format("dddd"))},{" "}
                <span style={{ fontWeight: "normal" }}>{`${t(moment(todayDate).format("Do"))} ${t(
                  "of"
                )} ${t(moment(todayDate).format("MMMM"))}`}</span>
              </Typography>{" "}
              <Grid container>{teamScheduleSummary}</Grid>
            </Box>
          ) : (
            ""
          )}
          <Hidden smDown>
            <TeamDetailsPlannerNewAppointment t={t} teamId={teamId} />
          </Hidden>
          <Box px={{ xs: 1, md: 8 }} py={{ xs: 4, md: 8 }}>
            <TeamsTabPanel
              t={t}
              teamId={teamId}
              hidePeopleTab={isCreate || !isTeamOwner}
              hidePlannerTab={isCreate || !isInTeam}
              hideNoticeboardTab={!isInTeam}
              hideIloTab={!isInTeam}
              preferredPanel={isCreate ? "create" : targetPanel}
            />
          </Box>
        </>
      ) : (
        <Box py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }}>
          <Alert style={{ padding: 10, marginTop: 30, maxWidth: "600px" }} severity="error">
            <AlertTitle>
              <strong>{t("Oops, something wrong happened")}</strong>
            </AlertTitle>
            {t("It looks like this team does not exist or you do not have permission to view it.")}
          </Alert>
        </Box>
      )}
    </>
  );
});

export default TeamsDetails;
