import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { bhpColor, settings } from "./globals";

export const bookingStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    pa4: {
      padding: 16,
    },
    pb4: {
      paddingBottom: 16,
    },
    paper: {
      padding: settings.outerPadding,
      color: bhpColor.blueGrey1,
      "&.version2": {
        paddingRight: 32,
        paddingLeft: 32,
        [theme.breakpoints.down("xs")]: {
          paddingRight: settings.outerPadding,
          paddingLeft: settings.outerPadding,
        },
      },
      "&.booking": {
        minHeight: 798,
      },
    },
    halfColumn: {
      [theme.breakpoints.down("xs")]: {
        width: settings.dashboardSingleColumn,
      },
    },
    unbold: {
      fontWeight: "normal",
    },
    inlineLink: {
      display: "inline-block",
      fontWeight: 400,
      textDecoration: "underline",
      cursor: "pointer",
      color: bhpColor.orange1,
      position: "relative",
      zIndex: 1000,
    },
    label: {
      fontSize: "16px",
      fontWeight: 700,
    },
    alignStartCentered: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
    newBookingSelect: {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 80px)",
      },

      height: 40,
      "& svg": {
        zIndex: 2,
      },
      "& select": {
        zIndex: 2,
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& fieldset": {
        zIndex: 1,
        height: 40,
        top: -3,
        backgroundColor: "white",
        "& .Mui-focussed": {
          backgroundColor: "white",
        },
      },
    },
    selectInput: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    timeSelector: {
      padding: 0,
      "& .MuiSelect-root": {
        padding: "4px",
        width: "80px",
        fontSize: "14px",
      },
      "& .MuiSelect-iconOutlined": {
        right: 0,
      },
    },
  })
);
