import moment from "moment";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Select,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  Chip,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CancelIcon from "@material-ui/icons/Cancel";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _without from "lodash/without";

import { SettingsSelectBox } from "components/settings/SettingsSelectBox";
import { BuildingWithCapacity, Space } from "interfaces/Building";
import { useRootStore } from "providers/RootStoreProvider";
import { bookingStyles } from "styles/bookingStyles";
import Topbar from "./Topbar";
import { CapacityChart } from "components/booking/CapacityChart";
import FloorChooser from "components/booking/FloorChooser";
import SpaceChooser from "components/booking/SpaceChooser";
import ShowTeamOverview from "components/booking/ShowTeamOverview";
import ShowBuildingSummary from "components/booking/ShowBuildingSummary";
import SelectionSummary from "components/booking/SelectionSummary";
import { isDayTooFarToBook, isDateInPast, addHours } from "utils/date";
import { bhpColor, settings } from "styles/globals";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import OfficeCapacityWidget_V2 from "components/dashboard/officeCapacity/OfficeCapacityWidget_V2";
import InteractiveFloorplan from "components/booking/InteractiveFloorplan";
import { getExceededSpaceCapacityMessage } from "components/booking/utils";
import { TeamParticipant } from "interfaces/Teams";
import { drawFavouriteSetter } from "components/booking/FavouriteContextMenu";
import {
  addMinutes,
  BOOKING_DURATION_MAX,
  BOOKING_DURATION_MIN,
  DEFAULT_END_TIME,
  DEFAULT_INCREMENT,
  DEFAULT_START_TIME,
  getBookingDateTimeData,
  getBookingsForDate,
  getDayPeriod,
  getDaysApart,
  getDeskHours,
  getEndString,
  Period,
} from "utils/hourlyBookings";
import IF_BookingList from "components/booking/Hourly/IF_BookingList";
import { $mobx, toJS } from "mobx";
import { Schedule } from "interfaces/Schedule";

interface BookingProps {
  match?: any;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const Booking = observer((props: BookingProps) => {
  const store = useRootStore();
  const classes = bookingStyles();
  const sclasses = useStyles();
  const history = useHistory();
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const date = props.match.params.date || undefined;
  const requestedBuildingId = props.match.params.buildingId || null;

  const initialBuildingId = requestedBuildingId
    ? parseInt(requestedBuildingId)
    : store.userStore.me?.profile.default_office || null;

  const bookingDate = date ? moment(new Date(date)).utc() : moment(new Date());
  const theDate = bookingDate.format("YYYY-MM-DD");
  const displayDate = {
    date: `${t(bookingDate.format("Do"))} ${t("of")} ${t(bookingDate.format("MMMM"))}`,
    day: t(bookingDate.format("dddd")),
  };

  const currentUser = store.userStore.me;
  const userSettingsDefaultDeskHours = getDeskHours(currentUser, theDate);

  const storedFloorId = Number(localStorage.getItem("selectedFloorId"));
  const [currentBuildingId, setCurrentBuildingId] = useState<number | null>(initialBuildingId);
  const [currentBuilding, setCurrentBuilding] = useState<BuildingWithCapacity | null>(
    initialBuildingId ? store.buildingStore.getBuilding(initialBuildingId) : null
  );
  const [currentFloorId, setCurrentFloorId] = useState<number | null>(
    currentBuilding &&
      currentBuilding.floors &&
      storedFloorId &&
      currentBuilding.floors.filter((flr) => `${flr.id}` === `${storedFloorId}`)
      ? Number(storedFloorId)
      : null
  );

  const [todaySchedule, setTodaySchedule] = useState<Schedule | null>(null);
  const [theBuildingTimeZone, setTheBuildingTimeZone] = useState<string | null>(null);
  const [deskFilterNames, setdeskFilterNames] = useState<String[]>([]);

  // set up default start and end booking times
  const [bookingStart, setBookingStart] = useState(
    `${userSettingsDefaultDeskHours.desk_start_time}`
  );

  const handleTomorrowDates = (dte: string, tme: string) => {
    let tmString = tme;
    let dtString = dte;
    if (tme.includes("+")) {
      // get proper hr
      // add to date
      tmString = tme.split(" ")[1];
      dtString = moment(dte)
        .add(Number(tme.split(" ")[0].replace("+", "")), "days")
        .format("YYYY-MM-DD");
    }
    return `${dtString} ${tmString}`;
  };

  const [bookingEnd, setBookingEnd] = useState(`${userSettingsDefaultDeskHours.desk_finish_time}`);
  const [deskHours, setDeskHours] = useState({
    start: currentBuilding?.building_timezone
      ? moment.tz(`${date} ${bookingStart}`, currentBuilding.building_timezone).toDate()
      : moment(`${date} ${bookingStart}`).toDate(),
    end: currentBuilding?.building_timezone
      ? moment
          .tz(handleTomorrowDates(`${date}`, `${bookingEnd}`), currentBuilding.building_timezone)
          .toDate()
      : moment(handleTomorrowDates(`${date}`, `${bookingEnd}`)).toDate(),
  });

  const [endSelectorEnd, setEndSelectorEnd] = useState("23:45");

  const setStartTime = (newTime: string) => {
    setBookingStart(newTime);
  };

  const setFinishTime = (newTime: string) => {
    setBookingEnd(newTime);
  };

  /** on-mount set up the building and timezone */
  useEffect(() => {
    ///on-mount
    store.scheduleStore.loadSchedules(true).then(() => {
      const theSchedule = store.scheduleStore.getScheduleForDay(theDate);
      if (`${theSchedule.desk_start_time}` === "") {
        console.log(
          "No start time in schedule, setting: ",
          userSettingsDefaultDeskHours.desk_start_time
        );
        theSchedule.desk_start_time = userSettingsDefaultDeskHours.desk_start_time;
      }
      if (`${theSchedule.desk_finish_time}` === "") {
        console.log(
          "No finish time in schedule, setting: ",
          userSettingsDefaultDeskHours.desk_finish_time
        );
        theSchedule.desk_finish_time = userSettingsDefaultDeskHours.desk_finish_time;
      }

      setTodaySchedule(theSchedule);
      setHoursFromSchedule(theSchedule, currentBuilding?.building_timezone);
      store.buildingStore.loadBuildingsWithCapacity(theDate, true).then(() => {
        // now that everything is loaded set the drop downs
        if (currentBuildingId) {
          const buildingTimeZone = currentBuilding?.building_timezone!;
          setCurrentBuilding(store.buildingStore.getBuilding(currentBuildingId, theDate));
          setTheBuildingTimeZone(buildingTimeZone);
          const minDuration =
            store.buildingStore.currentBuilding?.minimum_booking_time || BOOKING_DURATION_MIN;
          const maxDuration =
            store.buildingStore.currentBuilding?.maximum_booking_time || BOOKING_DURATION_MAX;
          const bookingStartTimeOnSite = moment.tz(`${date} ${bookingStart}`, buildingTimeZone);
          const endTimeEnd = bookingStartTimeOnSite.clone().add(maxDuration, "minutes");
          //TODO calc days diff and add +1 to time
          setEndSelectorEnd(endTimeEnd.format("YYYY-MM-DD HH:mm"));
        }
      });
    });

    if (store.userStore.me?.profile.team) {
      loadTeamInformation(
        store.userStore.me.profile.team,
        bookingDate.format(settings.momentDateFormat)
      );
    }
    if (currentFloorId) store.buildingStore.setCurrentFloor(currentFloorId);
    setupUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [availableSpaceCount, setAvailableSpaceCount] = useState(0);
  let total_space_available = 0;
  const getAvailaleSpaceCount = (count) => {
    total_space_available = total_space_available + count;
    setAvailableSpaceCount(total_space_available);
  };
  useEffect(() => {
    const buildingTimeZone = currentBuilding?.building_timezone; //store.buildingStore.currentBuilding?.building_timezone;
    if (buildingTimeZone) {
      const minDuration = currentBuilding?.minimum_booking_time || BOOKING_DURATION_MIN;
      const maxDuration = currentBuilding?.maximum_booking_time || BOOKING_DURATION_MAX;
      const bookingStartTimeOnSite = moment.tz(`${date} ${bookingStart}`, buildingTimeZone);
      const endTimeEnd = bookingStartTimeOnSite.clone().add(maxDuration, "minutes");
      setEndSelectorEnd(endTimeEnd.format("YYYY-MM-DD HH:mm"));
      updateDeskHours(bookingStart, bookingEnd, buildingTimeZone);
    }
  }, [bookingStart, bookingEnd]);

  // use this to ensure we aren't booking at a date in the past
  const isDateInvalid = isDayTooFarToBook(new Date(theDate)) || isDateInPast(theDate);
  const isDateTooFar = isDayTooFarToBook(new Date(theDate));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [canBook, setCanBook] = useState(!isDateInvalid);

  const loadTeamInformation = (teamId: number, date: string) => {
    store.teamStore.loadTeamSchedulePeopleList(teamId, date, date);
    store.teamStore.setCurrentTeam(teamId);
    getTeamMembers(teamId);
  };

  const handleTeamChanged = (teamId: string) => {
    if (!Number.isNaN(teamId)) loadTeamInformation(Number(teamId), theDate);
  };

  const [teamMembers, setTeamMembers] = useState<TeamParticipant[]>([]);
  const getTeamMembers = async (teamId: number) => {
    const res = await store.teamStore.loadTeamMembers(teamId);
    setTeamMembers(res);
  };

  const [focussedOnFloorplan, setFocussedOnFloorplan] = useState(false);
  // #endregion

  const [floorPlanOpen, setFloorPlanOpen] = useState(true);
  const showFloorPlan = (id: number | null) => {
    if (id) {
      store.buildingStore.setupFloorPlanFromBookingDetails(
        store.buildingStore.currentBuilding,
        store.buildingStore.currentFloor,
        store.buildingStore.currentSpace,
        false,
        bookingDate.format("YYYY-MM-DD"),
        false
      );
      store.buildingStore.openFloorPlan(true);
    }
  };

  const handleHoursChange = (date: string, deskHours: Period | null) => {
    if (deskHours === null) return;
    const newStart = moment
      .tz(deskHours.start, currentBuilding?.building_timezone!)
      .format("HH:mm");
    const newEnd = moment.tz(deskHours.end, currentBuilding?.building_timezone!).format("HH:mm");
    if (bookingStart !== newStart || bookingEnd !== newEnd) {
      setStartTime(newStart);
      setFinishTime(newEnd);
      store.scheduleStore.setDeskHours(date, deskHours, currentBuilding?.building_timezone!);
    }
  };

  const changeDeskHoursStart = (newTime: string) => {
    setStartTime(newTime);
  };

  const changeDeskHoursEnd = (newTime: string) => {
    setFinishTime(newTime);
  };

  const handleBuildingChange = (newLocation: string) => {
    if (currentBuildingId != parseInt(newLocation)) {
      setCurrentFloorId(null);
      store.buildingStore.setCurrentFloor(null);
      setCurrentBuildingId(parseInt(newLocation));
      setCurrentBuilding(store.buildingStore.getBuilding(Number(newLocation), theDate));
      store.buildingStore.setCurrentBuilding(parseInt(newLocation), theDate);

      //const theSchedule = store.scheduleStore.getScheduleForDay(theDate);
      //setHoursFromSchedule(theSchedule);
      store.teamStore.loadMyTeams();
      setSelectedDeskFilters([]);
      setdeskFilterNames([]);
    }
  };

  const handleFloorChange = (newFloorId: number, newFloorName: string) => {
    setCurrentFloorId(newFloorId);
    setSelectedDeskFilters([]);
    setdeskFilterNames([]);
  };

  const [currentSpace, setCurrentSpace] = useState<Space | null>(store.buildingStore.currentSpace);
  const handleSpaceChange = (newSpaceId: number | null) => {
    if (newSpaceId === null) {
      setCurrentSpace(null);
      store.buildingStore.setCurrentSpace(currentFloorId, null);
    }
    if (currentFloorId && newSpaceId) {
      const theSpace = store.buildingStore.floorSpaces[`${currentFloorId}`].find(
        (spc) => spc.id === newSpaceId
      );
      if (theSpace) {
        setCurrentSpace(theSpace);
        store.buildingStore.setCurrentSpace(currentFloorId, newSpaceId, theSpace);
      }
    }
  };

  const setHoursFromSchedule = (theSchedule: Schedule, timezone: string = "") => {
    const timezoneToUse =
      timezone !== "" ? timezone : theSchedule.office?.building_timezone || null;
    const newStart = `${
      `${theSchedule.desk_start_time}` !== ""
        ? theSchedule.desk_start_time
        : userSettingsDefaultDeskHours.desk_start_time
    }`;
    const newEnd = `${
      `${theSchedule.desk_finish_time}` !== ""
        ? theSchedule.desk_finish_time
        : userSettingsDefaultDeskHours.desk_finish_time
    }`;
    const startTime = timezoneToUse
      ? moment.tz(`${theDate} ${newStart}`, timezoneToUse).toDate()
      : moment(`${theDate} ${newStart}`).toDate();
    const endTime = timezoneToUse
      ? moment.tz(`${theDate} ${newEnd}`, timezoneToUse).toDate()
      : moment(`${theDate}  ${newEnd}`).toDate();
    if (bookingStart !== newStart || bookingEnd !== newEnd) {
      setStartTime(newStart);
      setFinishTime(newEnd);

      let corrected_desk_hours = {
        start: moment(`${date} ${newStart}`).toDate(),
        end: moment(`${date} ${newEnd}`).toDate(),
      };
      setDeskHours(corrected_desk_hours);
      store.scheduleStore.setDeskHours(theDate, { start: startTime, end: endTime }, timezoneToUse);
    }
  };

  const updateDeskHours = (bookingStart: string, bookingEnd: string, timezone: string) => {
    if (bookingStart && bookingStart !== "" && bookingEnd && bookingEnd !== "" && timezone) {
      const endTime = getEndString(bookingEnd, date);
      // const startUTC = moment.tz(`${date} ${bookingStart}`, timezone).toDate();
      // const endUTC = moment.tz(endTime, timezone).toDate();
      // const dhours = { start: startUTC, end: endUTC };
      // setDeskHours(dhours); //commenting since it is calculating different timezone
      let corrected_desk_hours = {
        start: moment(`${date} ${bookingStart}`).toDate(),
        end: moment(`${date} ${bookingEnd}`).toDate(),
      };
      setDeskHours(corrected_desk_hours);
    }
  };

  const setupUser = () => {
    store.teamStore.setMyTeam(store.userStore.me?.profile.team || 0);
    store.teamStore.loadMyTeams();
    if (store.userStore.me) {
      const thisDayPeriod = getDayPeriod(new Date(theDate), undefined, theBuildingTimeZone);
      store.bookingStore.loadUserBookings(
        { start: addHours(thisDayPeriod.start, -36), end: addHours(thisDayPeriod.end, 36) },
        store.userStore.me.id.toString()
      );
    }
  };

  const createBooking = async (
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string
  ) => {
    // don't make the booking if the building is at or over capacity
    try {
      setCanBook(false);
      if (store.buildingStore.currentFloor)
        localStorage.setItem("selectedFloorId", `${store.buildingStore.currentFloor.id}`);
      if (store.buildingStore.currentSpace && deskHours) {
        const created_by = "frontend";
        const bookDateTimeData = {
          start_date_local: startDate,
          start_time_local: startTime,
          end_date_local: endDate,
          end_time_local: endTime,
        };
        await store.bookingStore.createUserBooking(
          store.userStore.me!.id,
          store.buildingStore.currentSpace.id,
          bookDateTimeData.start_date_local,
          bookDateTimeData.start_time_local,
          bookDateTimeData.end_date_local,
          bookDateTimeData.end_time_local,
          created_by
        );
        store.scheduleStore.forceDataReload();
        store.scheduleStore.loadSchedules(true);
        setCanBook(!isDateInvalid);
        history.push(`/my-planner/bookings`);
      }
    } catch (err) {
      store.setError(err as AxiosError);
    }
  };

  useEffect(() => {
    // store.buildingStore.setCurrentBuilding(currentBuildingId, theDate);
    if (`${currentBuildingId}` !== `${store.buildingStore.currentBuilding?.id}`) {
      store.buildingStore.setCurrentBuilding(currentBuildingId, theDate);
      if (todaySchedule?.office) {
        const startTime = `${
          todaySchedule.desk_start_time || userSettingsDefaultDeskHours.desk_start_time
        }`;
        const endTime = `${
          todaySchedule.desk_finish_time || userSettingsDefaultDeskHours.desk_finish_time
        }`;
        setStartTime(startTime);
        setFinishTime(endTime);
      }
    }
    if (currentFloorId && `${currentFloorId}` !== `${store.buildingStore.currentFloorId}`) {
      setCurrentSpace(null);
      store.buildingStore.setCurrentSpace(currentFloorId, null);
      store.buildingStore.setCurrentFloorId(currentFloorId);
      store.buildingStore.setCurrentFloor(currentFloorId);
      if (currentBuildingId) {
        store.buildingStore.loadSpaces(currentBuildingId, currentFloorId, theDate, true);
        //TODO load floor bookings
      }
    }
    if (store.buildingStore.currentSpace) setCanBook(!isDateInvalid);
  }, [currentFloorId, currentBuildingId, currentSpace]);

  const getDropDownOptions = (
    bookingDate: string,
    isEndField: boolean = false,
    dropDownEndTime: string | null
  ) => {
    let options: { value: string; label: string }[] = [];
    const startTime = new Date(bookingDate);
    //if (!isEndField) startTime.setHours(0, 0, 0, 0);
    startTime.setHours(0, 0, 0, 0);

    const endTime = !isEndField
      ? addMinutes(startTime, 24 * 60)
      : dropDownEndTime
      ? new Date(dropDownEndTime)
      : addMinutes(startTime, BOOKING_DURATION_MAX);
    for (
      var loopTime = new Date(startTime);
      loopTime < endTime;
      loopTime = addMinutes(loopTime, DEFAULT_INCREMENT)
    ) {
      const momentValue = moment(loopTime);
      let value = momentValue.format("HH:mm");
      let label = momentValue.format("h:mma");
      const daysDifference = getDaysApart(
        moment(new Date(loopTime)).format("YYYY-MM-DD"),
        moment(new Date(startTime)).format("YYYY-MM-DD")
      );
      if (daysDifference > 0 && isEndField) {
        value = `+${daysDifference} ${value}`;
        label = `+${daysDifference} ${label}`;
      }
      options.push({ value, label });
    }
    return options;
  };

  /**
   * converts given time to double digit time
   * @param time the time value 8:45 maybe, needs to be 5 digits
   */
  const getComparisonValue = (time: string) => {
    if (time.includes("+")) return time;
    return moment(`${moment(new Date()).format("YYYY-MM-DD")} ${time}`).format("HH:mm");
  };

  const drawTimeSelector = (
    onChanged: any,
    name: string,
    value: string,
    startString: string,
    endString: string | null,
    isEndTime: boolean = false
  ) => {
    const theOptions = getDropDownOptions(startString, isEndTime, endString).slice();
    return (
      <select
        name={name}
        value={getComparisonValue(value)}
        style={{
          marginLeft: "4px",
          marginRight: "4px",
          borderRadius: "4px",
          color: bhpColor.blueGrey1,
          borderColor: bhpColor.grey2,
        }}
        className={classes.timeSelector}
        onChange={(e) => onChanged(e.target.value)}
      >
        {theOptions.map((opt, idx) => {
          return (
            <option
              key={`ts:${idx}-${new Date().getMilliseconds()}}`}
              value={opt.value}
              label={opt.label}
            >
              {opt.label}
            </option>
          );
        })}
      </select>
    );
  };

  const startSelector = drawTimeSelector(
    changeDeskHoursStart,
    "deskHoursStart",
    bookingStart,
    theDate,
    null
  );

  const endSelector = drawTimeSelector(
    changeDeskHoursEnd,
    "deskHoursEnd",
    bookingEnd,
    theDate, //endSelectorStart
    endSelectorEnd,
    true
  );

  const ITEM_HEIGHT = 78;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  };

  useEffect(() => {
    if (store.buildingStore.currentFloorId) {
      store.buildingStore.loadSpaceTags(currentFloorId).then((values) => {
        setdeskFilterNames(values);
      });
    }
  }, [currentFloorId, currentBuildingId]);

  const [selectedDeskFilters, setSelectedDeskFilters] = useState<String[]>([]);

  const handleDeskFilterChange = (event) => {
    setAvailableSpaceCount(0);
    setSelectedDeskFilters(event.target.value);
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setSelectedDeskFilters((current) => _without(current, value));
  };

  return (
    <>
      <Topbar
        title={t("Book a Desk")}
        subtitle={t("Make and manage your desk bookings")}
        breadcrumb={t("Book a Desk")}
      />
      {store.buildingStore.isLoadingCapacity && (
        <Box py={{ xs: 2, md: 4 }} px={{ xs: 0, md: 4 }} className={classes.root}>
          Loading building details <CircularProgress variant="indeterminate" size={14} />
        </Box>
      )}
      {store.buildingStore.isOffice(currentBuildingId) && (
        <Box py={{ xs: 2, md: 4 }} px={{ xs: 0, md: 4 }} className={classes.root}>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <Box px={{ xs: 2, md: 0 }}>
                {t("Your booking for")}
                <Typography variant="h3">
                  {displayDate.day}, <span className={classes.unbold}>{displayDate.date}</span>
                </Typography>
              </Box>
              {isDateInPast(theDate) && (
                <Box py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }}>
                  <Alert style={{ padding: 10, marginTop: 30, maxWidth: "600px" }} severity="error">
                    <AlertTitle>
                      <strong>{t("Limited view")}</strong>
                    </AlertTitle>
                    {t("Past date displayed for review purposes")}
                  </Alert>
                </Box>
              )}
              {isDateTooFar && (
                <Box py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }}>
                  <Alert style={{ padding: 10, marginTop: 30, maxWidth: "600px" }} severity="error">
                    <AlertTitle>
                      <strong>{t("Limited view")}</strong>
                    </AlertTitle>
                    {t("Bookings can't be made that far in advance")}
                  </Alert>
                </Box>
              )}
            </Grid>
            <Grid item md={6} style={{ justifyContent: "end", display: "grid" }}>
              <OfficeCapacityWidget_V2
                date={new Date(bookingDate.toISOString())}
                t={t}
                hideName={true}
                fullWidth={true}
                snug={true}
                buildingDetail={currentBuilding}
              />
            </Grid>
            <Grid item md={12} lg={6}>
              <Paper elevation={0} className={`${classes.paper} version2`}>
                <Grid container>
                  <Grid item xs={2} className={classes.alignStartCentered}>
                    <Box className={classes.label}>{t("Location")}</Box>
                  </Grid>
                  <Grid item xs={10}>
                    <SettingsSelectBox
                      t={t}
                      cssClass={classes.newBookingSelect}
                      phText={t("Choose your office...")}
                      fontSize={smAndUp ? undefined : "14px"}
                      phValue={-1}
                      theItems={store.buildingStore.getCapacityList(t)}
                      handleChange={handleBuildingChange}
                      defaultValue={currentBuildingId}
                      theId={"location"}
                      theKey={"locationKey"}
                      showPlaceholder={false}
                    />
                    {!store.buildingStore.isLoadingCapacity &&
                      getExceededSpaceCapacityMessage(currentBuilding, date)}
                  </Grid>
                  <Grid item xs={2} className={classes.alignStartCentered}>
                    {/* floors */}
                    <Box paddingTop={2} className={classes.label}>
                      {t("Floor")}
                    </Box>
                  </Grid>
                  <Grid item xs={10} style={{ display: "flex", justifyContent: "flex-start" }}>
                    <Tooltip
                      title={currentFloorId ? "" : "Please select a floor"}
                      open={!currentFloorId}
                      arrow
                    >
                      <Box paddingTop={2}>
                        {store.buildingStore.currentBuilding ? (
                          <FloorChooser
                            onFloorChange={handleFloorChange}
                            floorId={currentFloorId}
                            building={store.buildingStore.currentBuilding}
                            theDate={theDate}
                          />
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={12} lg={6}>
              <Grid container style={{ height: "100%" }}>
                <Grid item xs={12}>
                  <Paper
                    elevation={0}
                    className={`${classes.paper} version2`}
                    style={{ height: "100%", whiteSpace: "pre" }}
                  >
                    {store.teamStore.isLoadingCurrentTeam ? (
                      <>
                        {t("Loading default team information")}{" "}
                        <CircularProgress size={14} style={{ color: bhpColor.orange1 }} />
                      </>
                    ) : store.teamStore.currentTeam || true ? (
                      <ShowTeamOverview
                        version2
                        selectedTeamChanged={handleTeamChanged}
                        t={t}
                        team={store.teamStore.currentTeam}
                        teamSchedulePeopleList={store.teamStore.teamSchedulePeopleList}
                      />
                    ) : (
                      <>{t("No default team")} </>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Paper
                elevation={0}
                className={`${classes.paper} booking version2`}
                style={{ alignItems: "center" }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    {currentBuilding ? (
                      <>
                        <ShowBuildingSummary
                          t={t}
                          building={currentBuilding}
                          spaceCount={availableSpaceCount}
                        />
                        {store.buildingStore.isLoadingSpacesForThisFloor(currentFloorId) && (
                          <Box>
                            {`Loading spaces `}
                            <CircularProgress size={14} color="secondary" />
                          </Box>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    <Box>
                      Desk Filters :
                      <Select
                        labelId="desk-property-filters"
                        id="desk-property-filters"
                        multiple
                        value={selectedDeskFilters}
                        onChange={handleDeskFilterChange}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected: any) => (
                          <div className={sclasses.chips}>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                clickable
                                deleteIcon={
                                  <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                                }
                                className={sclasses.chip}
                                onDelete={(e) => handleDelete(e, value)}
                                onClick={() => console.log("clicked chip")}
                              />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {deskFilterNames.map((name) => (
                          <MenuItem
                            key={name as string}
                            value={name as string}
                            style={getStyles(name, selectedDeskFilters, theme)}
                          >
                            {/* <Checkbox checked={selectedDeskFilters.includes(name)} />
                              <ListItemText primary={name} /> */}
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    <Box style={{ display: "none" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!floorPlanOpen}
                            onChange={() => setFloorPlanOpen(!floorPlanOpen)}
                            name="floorplanOpen"
                          />
                        }
                        label={
                          <Typography style={{ fontSize: 18, fontWeight: 700 }}>
                            Desk Properties
                          </Typography>
                        }
                        labelPlacement="end"
                      />
                    </Box>
                    <Box style={{ display: "flex", marginRight: "12px" }}>
                      {deskHours && (
                        <>
                          {t(`Desk Hours`)}
                          {startSelector}
                          {` - `}
                          {endSelector}
                        </>
                      )}
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        marginRight: "16px",
                        marginTop: "16px",
                        zIndex: "10",
                      }}
                    >
                      <IF_BookingList
                        userBookings={store.bookingStore.user_bookings}
                        date={theDate}
                        currentBuilding={store.buildingStore.currentBuilding}
                        t={t}
                      />
                    </Box>
                  </Grid>
                </Grid>
                {
                  floorPlanOpen &&
                    (store.buildingStore.currentFloor ? (
                      <InteractiveFloorplan
                        floorId={store.buildingStore.currentFloorId}
                        date={theDate}
                        buildingId={store.buildingStore.currentBuilding?.id}
                        onSpaceChange={handleSpaceChange}
                        onDeskHoursChange={handleHoursChange}
                        teamMembers={teamMembers}
                        showSpaceId={store.buildingStore.focussedSpaceId || undefined}
                        deskHours={deskHours}
                        bookingStart={bookingStart}
                        bookingEnd={bookingEnd}
                        spaceId={
                          store.buildingStore.currentSpace
                            ? store.buildingStore.currentSpace.id
                            : currentSpace?.id
                        }
                        doBooking={(startDate, startTime, endDate, endTime) =>
                          createBooking(startDate, startTime, endDate, endTime)
                        }
                        disableEditing={!canBook}
                        startSelector={startSelector}
                        endSelector={endSelector}
                        spaceCount={getAvailaleSpaceCount}
                        deskFilters={selectedDeskFilters}
                      />
                    ) : (
                      <Box>No floor</Box>
                    ))
                  //  : (
                  //   <>
                  //     <SpaceChooser
                  //       t={t}
                  //       version2
                  //       isLoading={store.buildingStore.isLoading}
                  //       floor={store.buildingStore.currentFloor || null}
                  //       spaces={currentFloorId ? store.buildingStore.floorSpaces[currentFloorId] : []}
                  //       team={store.teamStore.myTeam}
                  //       onSpaceChange={handleSpaceChange}
                  //     />
                  //     <Box
                  //       paddingTop={2}
                  //       style={{
                  //         marginTop:
                  //           store.buildingStore.currentFloor &&
                  //           store.buildingStore.currentFloor?.hasFloorPlan
                  //             ? "0px"
                  //             : "42px",
                  //       }}
                  //     >
                  //       <Grid container>
                  //         <Grid item xs={3} md={2}>
                  //           {store.buildingStore.currentFloor &&
                  //           store.buildingStore.currentFloor?.hasFloorPlan ? (
                  //             <Box
                  //               style={{ fontWeight: 700 }}
                  //               marginBottom={2}
                  //               onClick={() =>
                  //                 showFloorPlan(
                  //                   store.buildingStore.currentFloor
                  //                     ? store.buildingStore.currentFloor.id
                  //                     : null
                  //                 )
                  //               }
                  //               className={classes.inlineLink}
                  //             >
                  //               {t("View Floor Plan")}
                  //             </Box>
                  //           ) : (
                  //             ""
                  //           )}
                  //           <SelectionSummary
                  //             t={t}
                  //             building={
                  //               store.buildingStore.currentBuilding
                  //                 ? store.buildingStore.currentBuilding
                  //                 : currentBuilding
                  //             }
                  //             floor={store.buildingStore.currentFloor}
                  //             space={
                  //               store.buildingStore.currentSpace
                  //                 ? store.buildingStore.currentSpace
                  //                 : currentSpace
                  //             }
                  //           />
                  //         </Grid>
                  //         <Grid item xs={9} md={3} style={{ alignSelf: "flex-end" }}>
                  //           <Box textAlign="start" style={{ display: "flex", alignItems: "center" }}>
                  //             {drawFavouriteSetter(store, currentSpace, null, null)}
                  //             <Button
                  //               onClick={createBooking}
                  //               variant="contained"
                  //               color="primary"
                  //               disableElevation
                  //               disabled={!canBook || store.buildingStore.currentSpace === null}
                  //             >
                  //               {t("Book Desk")}
                  //             </Button>
                  //           </Box>
                  //         </Grid>
                  //       </Grid>
                  //     </Box>
                  //   </>
                  // )
                }
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
      {isDateTooFar && (
        <Box py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }}>
          <Alert style={{ padding: 10, marginTop: 30, maxWidth: "600px" }} severity="error">
            <AlertTitle>
              <strong>{t("Sorry error")}</strong>
            </AlertTitle>
            {t("Invalid date content")}
          </Alert>
        </Box>
      )}

      {currentBuilding && !store.buildingStore.isOffice(currentBuildingId) && (
        <Box py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }}>
          <Alert style={{ padding: 10, marginTop: 30, maxWidth: "600px" }} severity="error">
            <AlertTitle>
              <strong>{t("Sorry error")}</strong>
            </AlertTitle>
            {t("This location does not allow bookings")}
          </Alert>
        </Box>
      )}
    </>
  );
});

export default Booking;
