import {
  Box,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableHead,
  Theme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useBookingStore, useBuildingStore, useTeamStore } from "providers/RootStoreProvider";
import { bhpColor } from "styles/globals";
import PeopleTabHeadRow from "components/office/PeopleTabHeadRow";
import PeopleTabData from "components/office/PeopleTabData";
import { useEffect, useState } from "react";
import { getDateTimeAtStartOfToday } from "utils/hourlyBookings";
import { BuildingTeamSchedule, MultipleBooking } from "interfaces/Schedule";
import { getEarliestBooking } from "utils/bookings";

interface PeopleTabProps {
  focussedDate: string;
  focussedTeam: number | null;
  changeTeam: any;
  displayStart: string;
  displayEnd: string;
  scrollTo: any;
  changeDate: any;
  buildingId: number;
  t: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartOuter: {
      [theme.breakpoints.down("sm")]: {
        border: 0,
        paddingLeft: 0,
      },
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          borderBottom: 0,
          color: "inherit",
        },
      },
    },
    teamSelect: {
      "& fieldset": {
        backgroundColor: "white",
      },
    },
  })
);

const PeopleTab = observer((props: PeopleTabProps) => {
  const {
    displayStart,
    displayEnd,
    focussedDate,
    changeDate,
    focussedTeam,
    changeTeam,
    scrollTo,
    buildingId,
    t,
  } = props;
  const classes = innerStyles();
  const buildingStore = useBuildingStore();
  const bookingStore = useBookingStore();
  const teamStore = useTeamStore();
  const [buildingTeamSchedule, setBuildingTeamSchedule] = useState<BuildingTeamSchedule | null>(
    null
  );

  useEffect(() => {
    const startOfToday = moment().add(-24, "hours").toDate();
    const endOfToday = moment().add(24, "hours").toDate();
    const bts =
      buildingStore.buildingTeamSchedule?.find((bts) =>
        moment(bts.date).isSame(moment(focussedDate))
      ) || null;
    if (bts) {
      bookingStore
        .loadBuildingBookings({ start: startOfToday, end: endOfToday }, buildingId.toString())
        .then((data) => {
          const usersUpdatedObject = bts.users.map((user) => {
            const usersDataFromBuildingBookings = data.filter(
              (b) =>
                b.user_id === user.id &&
                (b.booking_details?.start_date_local === focussedDate ||
                  b.booking_details?.end_date_local === focussedDate)
            );
            if (usersDataFromBuildingBookings && usersDataFromBuildingBookings.length > 1) {
              return {
                ...user,
                multiple_bookings: usersDataFromBuildingBookings,
                upcoming_booking: getEarliestBooking(usersDataFromBuildingBookings, focussedDate),
              };
            } else {
              return {
                ...user,
                multiple_bookings: usersDataFromBuildingBookings,
                upcoming_booking: usersDataFromBuildingBookings[0] || null,
              };
            }
          });
          setBuildingTeamSchedule({ ...bts, users: usersUpdatedObject });
        });
    } else {
      setBuildingTeamSchedule(null);
    }
  }, [focussedDate, buildingStore.buildingTeamSchedule]);

  return (
    <Box style={{ color: bhpColor.blueGrey1 }}>
      <Box className={classes.chartOuter}>
        <Table style={{ border: 0 }}>
          <TableHead>
            <PeopleTabHeadRow
              t={t}
              buildingCapacityRange={buildingStore.buildingCapacityRange.filter(
                (bld) =>
                  moment(bld.date).isSameOrAfter(moment(displayStart)) &&
                  moment(bld.date).isSameOrBefore(moment(displayEnd))
              )}
              selectedDate={focussedDate}
              scrollTo={scrollTo}
              changeDate={changeDate}
              numberPlanned={
                buildingStore.buildingTeamSchedule?.find((bts) =>
                  moment(bts.date).isSame(moment(focussedDate))
                )?.total_users_planned || 0
              }
              numberBooked={
                buildingStore.buildingTeamSchedule?.find((bts) =>
                  moment(bts.date).isSame(moment(focussedDate))
                )?.total_users_booked || 0
              }
            />
          </TableHead>
          <TableBody>
            <PeopleTabData
              t={t}
              buildingTeamSchedule={buildingTeamSchedule}
              columnSpan={10}
              changeTeam={changeTeam}
              focussedTeam={focussedTeam}
              focussedDate={focussedDate}
            />
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
});
export default PeopleTab;
