import moment from "moment";

export interface Timezone {
  id?: number;
  abbreviation?: string;
  name: string;
}
export interface AllHolidays {
  date: string;
  holidays: Holiday[];
}
export interface Holiday {
  country: string;
  holidayName: string;
  holidayType: string;
  state?: string;
}
export enum EditHoursLocationType {
  Settings = 0,
  Planner = 1,
}

export interface Building {
  id: number;
  display_name: string;
  city: string;
  building_name?: string;
  total_capcity?: number;
}

export const getTime = (timezone?: string) => {
  return timezone
    ? ` ${"Local Time"} ${moment(new Date()).tz(timezone).format("ddd")} ${moment(new Date())
        .tz(timezone)
        .format("h:mm a")} (${moment().tz(timezone).format("z")})`
    : moment(new Date()).format("DDD dd HH:mm");
};

export type confirmDialogSize = "xs" | "sm" | "md" | "lg" | "xl" | boolean | string;

/**
 * Get a date range for a given date AT the timezone
 * uses the timezone to establish fully qualified javascript start/end Dates
 * @param timezone - the timzone e.g Australia/Brisbane
 * @param date - the date AT the building YYYY-MM-DD
 * @returns an object containing fully qualified javascript dates {start, end}
 */
export const dateToUTCRange = (
  timezone: string,
  date: string,
  from: string = "00:00:00",
  to: string = "23:59:59"
) => {
  const momentFrom = moment.tz(`${date} ${from}`, timezone);
  const momentTo = moment.tz(`${date} ${to}`, timezone);
  const periodFrom = new Date(momentFrom.toDate());
  const periodTo = new Date(momentTo.toDate());
  const thisPeriod = { start: periodFrom, end: periodTo };
  return thisPeriod;
};
