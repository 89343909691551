import { ScheduleType } from "interfaces/Schedule";
import { Team, TeamCategoryType, TeamVisibilityType } from "interfaces/Teams";
import { capitalizeString } from "./string";
import { EventDates } from "interfaces/Event";

export const getTeamsScheduleStatusTypeText = (t: any, workType: ScheduleType): string => {
  switch (workType) {
    case ScheduleType.OFFICE:
      return t("At a BHP Location");
    case ScheduleType.REMOTE:
      return t("Remote");
    case ScheduleType.OFF:
      return t("Off");
    default:
      return t("Unavailable");
  }
};

// export const getEventsDateTypeText = (t: any, dateType: EventDates): string => {
//   switch (dateType) {
//     case EventDates.ThisWeek:
//       return t("This Week");
//     case EventDates.NextWeek:
//       return t("Next Week");
//     case EventDates.ThisMonth:
//       return t("This Month");
//     case EventDates.NextMonth:
//       return t("Next Month");
//     case EventDates.ThisYear:
//       return t("This Year");
//     case EventDates.AllEvents:
//       return t("All Events");
//     default:
//       return t("This Month");
//   }
// };

export const getTeamsCategoryKeyValues = () => {
  let teamsCategoryNames: { key: string; value: number }[] = [];
  for (let item in TeamCategoryType) {
    if (isNaN(Number(item))) {
      teamsCategoryNames.push({ key: item, value: TeamCategoryType[item] as unknown as number });
    }
  }
  return teamsCategoryNames;
};

export const getTeamCategoryString = (categoryType: TeamCategoryType) => {
  switch (categoryType) {
    case 0:
      return "Organisational unit";
    case TeamCategoryType.CROSS_FUNCTIONAL:
      return capitalizeString("Cross-functional");
    default:
      return capitalizeString(TeamCategoryType[categoryType].split("_").join(" "));
  }
};

export const getTeamVisibilityString = (classificationType: TeamVisibilityType) => {
  return capitalizeString(TeamVisibilityType[classificationType]);
};
