import { Box, Grid, Link, Paper, useTheme } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useFeedbackStore } from "providers/RootStoreProvider";
import Topbar from "./Topbar";
import IFrameResizer from "iframe-resizer-react";
import { useTranslation } from "react-i18next";
import { feedbackStyles } from "styles/feedbackStyles";
import { bhpColor } from "styles/globals";
import { FeedbackFormSummary } from "components/form/FeedbackFormSummary";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FeedbackForm } from "interfaces/FeedbackForm";
import { useHistory } from "react-router";

interface feedbackURLParams {
  feedbackId: string;
}

const Profile = observer(() => {
  const classes = feedbackStyles();
  const theme = useTheme();
  const urlParams: feedbackURLParams = useParams();
  const [showFeedbackForm, setShowFeedbackForm] = useState<boolean>(false);
  const [selectedFeedbackForm, setSelectedFeedbackForm] = useState<FeedbackForm | null>(null);
  //const [feedbackFormURL, setFeedbackFormURL] = useState<string | null>(null);
  const { t, i18n } = useTranslation();
  const feedbackStore = useFeedbackStore();
  const history = useHistory();

  useEffect(() => {
    if (urlParams.feedbackId) {
      setShowFeedbackForm(true);
      feedbackStore.feedbackForms.length > 0
        ? setSelectedFeedbackForm(
            feedbackStore.feedbackForms.find((x) => x.id.toString() === urlParams.feedbackId) ||
              null
          )
        : setSelectedFeedbackForm(null);
    }
  }, [urlParams.feedbackId, feedbackStore.feedbackForms]);

  return (
    <>
      <Topbar
        title={t("Feedback")}
        subtitle={t("Feedback Content")}
        breadcrumb={
          showFeedbackForm && selectedFeedbackForm
            ? `${t("Feedback")} > ${selectedFeedbackForm.form_title}`
            : t("Feedback")
        }
      />
      <Box px={{ xs: 2, md: 4 }}>
        <Grid container spacing={3}>
          {showFeedbackForm ? (
            <Grid item xs={12}>
              <Box pt={1}>
                {"< "}
                <Link
                  variant="body2"
                  onClick={() => history.push("/feedback")}
                  style={{ cursor: "pointer", fontWeight: "bold", color: bhpColor.blueGrey1 }}
                  underline="always"
                >
                  Back to Forms
                </Link>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <h2 className={classes.header}>{t("Forms")}</h2>
              {
                <div style={{ color: bhpColor.grey1 }}>
                  {t(
                    "We’re seeking your feedback. Please select from the below tiles to provide feedback."
                  )}
                </div>
              }
            </Grid>
          )}
          {showFeedbackForm ? (
            <IFrameResizer
              src={selectedFeedbackForm?.form_url || ""}
              style={{ border: 0, minHeight: "1800px", width: "1px", minWidth: "100%" }}
            />
          ) : (
            feedbackStore.feedbackForms.map((form, idx) => {
              return (
                <Grid item xs={12} sm={6} lg={6} key={`bf${idx}`}>
                  <Box key={`b${idx}`}>
                    <FeedbackFormSummary
                      feedbackId={form.id}
                      formName={form.form_name}
                      formTitle={form.form_title}
                      formDescription={form.form_description}
                      t={t}
                      url={form.form_url}
                    />
                  </Box>
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
    </>
  );
});

export default Profile;
