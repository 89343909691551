import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
} from "@material-ui/core";
import { Building, BuildingType } from "interfaces/Building";
import { bhpColor } from "styles/globals";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnMenuContainer,
  GridDensityTypes,
  GridFilterMenuItem,
  GridOverlay,
} from "@material-ui/data-grid";
import { observer } from "mobx-react-lite";
import moment, { Moment } from "moment-timezone";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    bookedHeaderCell: {
      paddingRight: `0 !important`,
    },
    "& .MuiGridPanel-root": {
      color: bhpColor.blueGrey1,
    },
    root: {},
    dataGrid: {
      "& .MuiDataGrid-cell": {
        maxHeight: "unset !important",
      },
      "& .MuiDataGrid-row": {
        maxHeight: "unset !important",
      },
    },
    spaceTable: {
      border: 0,
      "& .MuiTablePagination-root": {
        color: bhpColor.blueGrey1,
      },
      "& .MuiDataGrid-renderingZone": {
        maxHeight: "unset !important",
      },
      "& .MuiDataGrid-viewport": {
        //maxHeight: "none",
        backgroundColor: bhpColor.backgroundGrey,
      },
      "& .MuiDataGrid-root": {
        height: "850px",
        border: 0,
      },
      "& .MuiDataGrid-cell": {
        border: 0,
        "&:focus": {
          outline: 0,
        },
        "&.mobileCapacityCell": {
          alignItems: "flex-start",
          justifyContent: "flex-end",
          lineHeight: "unset !important",
        },
      },
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-sortIcon": {
          opacity: `1 !important`,
        },
        "&:focus": {
          outline: 0,
        },
        "&:focus-within": {
          outline: 0,
        },
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        paddingLeft: 0,
      },
      "& .MuiDataGrid-columnsContainer": {
        borderBottom: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
        color: bhpColor.blueGrey1,
        paddingRight: 16,
      },
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-row": {
        //cursor: "pointer",
        "&:nth-child(odd)": {
          backgroundColor: bhpColor.white,
          "&:hover": {
            backgroundColor: bhpColor.white,
          },
        },
        "&.Mui-selected": {
          "&:hover": {
            backgroundColor: bhpColor.blueGrey2,
            color: bhpColor.blueGrey1,
          },
          backgroundColor: bhpColor.blueGrey1,
          color: bhpColor.white,
        },
        "&:hover": {
          backgroundColor: bhpColor.backgroundGrey,
        },
      },
    },
  })
);

interface OfficeSearchResultsProps {
  results: Building[];
  rowCount?: number;
  onOfficeSelect: any;
  t: any;
}

const OfficeSearchResults = observer((props: OfficeSearchResultsProps) => {
  const { results, onOfficeSelect, t, rowCount = results.length } = props;
  const classes = innerStyles();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));

  const selectOffice = (officeId: string) => {
    onOfficeSelect(parseInt(officeId));
  };

  const sortAlphaNumeric = (a: any, b: any) => {
    // convert to strings and force lowercase
    a = typeof a === "string" ? a.toLowerCase() : a.toString();
    b = typeof b === "string" ? b.toLowerCase() : b.toString();
    return a.localeCompare(b, "en", { numeric: true });
  };

  const translatedDayTime = (dte: Moment) => {
    return `${t(dte.format("ddd"))} ${dte.format("h:mm a")}`;
  };
  const onOfficeClick = (officeId: any): void => {
    selectOffice(officeId);
  };
  const columns: GridColDef[] = [
    {
      field: "building_name",
      headerName: t("Location"),
      sortable: true,
      flex: 1.1,
      valueGetter: (params) => `${params.row.city}, ${params.row.building_name}`,
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params: GridCellParams) => {
        return (
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              return onOfficeClick(params.row.id);
            }}
            underline="always"
            color="inherit"
          >
            {params.row.city}, {params.row.building_name}
          </Link>
        );
      },
    },
    {
      field: "booked_attendance",
      headerName: t("Desks Booked (today)"),
      sortable: true,
      flex: 0.7,
      renderCell: (params: GridCellParams) => {
        return `${
          params.row.building_type === BuildingType.OFFICE ? params.row.booked_attendance : "N/A"
        }`;
      },
    },
    {
      field: "total_capacity",
      headerName: t("Capacity Limit"),
      sortable: true,
      flex: 0.7,
      renderCell: (params: GridCellParams) => {
        return `${
          params.row.building_type === BuildingType.OFFICE ? params.row.total_capacity : "N/A"
        }`;
      },
    },
    {
      field: "building_timezone",
      headerName: t("Local Time"),
      sortable: true,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return `${translatedDayTime(moment(new Date()).tz(params.row.building_timezone))} ${t(
          "Local Time"
        )} (${moment().tz(params.row.building_timezone).zoneAbbr()})`;
      },
    },
  ];

  const getMobileLocalTime = (dte: Moment) => {
    const sameDay = moment(new Date()).isSame(dte, "day");
    return sameDay ? dte.format("h:mm a") : `${t(dte.format("ddd"))} ${dte.format("h:mm a")}`;
  };

  const columnsMobile: GridColDef[] = [
    {
      field: "building_name",
      headerName: t("Name"),
      filterable: false,
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Box paddingTop={1.5} paddingBottom={1.5}>
            <Typography style={{ fontWeight: 700 }}>
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  return onOfficeClick(params.row.id);
                }}
                underline="always"
                color="inherit"
              >
                {params.row.building_name}
              </Link>
            </Typography>
            <Typography display={"block"}>{params.row.city}</Typography>
            <Typography style={{ fontSize: "smaller" }}>{`${getMobileLocalTime(
              moment(new Date()).tz(params.row.building_timezone)
            )} ${t("Local Time")} (${moment()
              .tz(params.row.building_timezone)
              .zoneAbbr()})`}</Typography>
          </Box>
        );
      },
    },
    {
      field: "booked_attendance",
      headerName: t("Desks Booked / Capacity"),
      headerClassName: classes.bookedHeaderCell,
      sortable: false,
      filterable: false,
      flex: 1,
      disableColumnMenu: true,
      cellClassName: "mobileCapacityCell",
      renderCell: (params: GridCellParams) => {
        return (
          <Box paddingTop={1.5} paddingBottom={1.5}>
            {params.row.booked_attendance} / {params.row.total_capacity}
          </Box>
        );
      },
    },
  ];

  function CustomNoRowsOverlay() {
    return <GridOverlay>{t("Enter search term to see results")}</GridOverlay>;
  }

  const CustomColumnMenu = (props: any) => {
    const { hideMenu, currentColumn } = props;
    return (
      <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} open={false}>
        <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
      </GridColumnMenuContainer>
    );
  };

  return (
    <div className={classes.spaceTable}>
      <DataGrid
        className={classes.dataGrid}
        style={{ color: bhpColor.blueGrey1, height: `${mdAndUp ? "700px" : "900px"}` }}
        columns={mdAndUp ? columns : columnsMobile}
        rows={results}
        rowCount={rowCount}
        pageSize={10}
        rowsPerPageOptions={[10]}
        density={mdAndUp ? GridDensityTypes.Standard : GridDensityTypes.Comfortable}
        disableColumnSelector={true}
        hideFooterSelectedRowCount={true}
        scrollbarSize={0}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          ColumnSortedAscendingIcon: ExpandMore,
          ColumnSortedDescendingIcon: ExpandLess,
          ColumnUnsortedIcon: UnfoldMoreIcon,
          ColumnMenu: CustomColumnMenu,
        }}
        isRowSelectable={(params: any) => params.row.is_booked === false}
        //onRowClick={(params: any) => selectOffice(params.row.id)}
      />
    </div>
  );
});

export default OfficeSearchResults;
