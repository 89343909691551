import {
  Box,
  createStyles,
  Hidden,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiLessThan, mdiGreaterThan } from "@mdi/js";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { BuildingWithCapacity } from "interfaces/Building";
import { bhpColor, settings } from "styles/globals";
import { isWeekend } from "utils/date";

interface PeopleTabHeadRowProps {
  selectedDate: string;
  buildingCapacityRange: BuildingWithCapacity[];
  scrollTo: any;
  changeDate: any;
  numberPlanned: number;
  numberBooked: number;
  t: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    today: {},
    selected: {
      borderRadius: settings.borderRadius,
      border: `1px solid ${bhpColor.blueGrey1}`,
      backgroundColor: "white",
    },
    onDateHover: {
      border: `1px solid transparent`,

      "&:hover": {
        borderRadius: settings.borderRadius,
        border: `1px solid ${bhpColor.blueGrey1}`,
      },
    },
    weekend: { backgroundColor: `${bhpColor.blueGrey4}30` },
    headRow: {
      backgroundColor: bhpColor.backgroundGrey,
      border: `1px solid ${bhpColor.blueGrey4}`,
      "& .MuiTableCell-head": {
        [theme.breakpoints.down("sm")]: {
          paddingLeft: 4,
          paddingRight: 4,
          width: "11%",
        },
      },
    },
  })
);

const PeopleTabHeadRow = observer((props: PeopleTabHeadRowProps) => {
  const {
    buildingCapacityRange,
    scrollTo,
    selectedDate,
    changeDate,
    numberPlanned,
    numberBooked,
    t,
  } = props;
  const classes = innerStyles();
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));

  const scrollLeft = () => scroll(-1);
  const scrollRight = () => scroll(1);
  const scroll = (amount: number) => {
    scrollTo(amount);
  };

  const setDate = (dte: string) => {
    changeDate(dte);
  };

  const today = new Date();
  const plannedLabel = `${mdAndUp ? t("Planned Attendance") : t("Planned")} ${numberPlanned}`;
  const bookedLabel = `${mdAndUp ? t("Desks Booked") : t("Booked")} ${numberBooked}`;
  return (
    <TableRow className={classes.headRow}>
      <Hidden smDown>
        <TableCell>
          <Box style={{ textAlign: "start" }}>
            <Typography style={{ fontWeight: 700, color: bhpColor.blueGrey1 }}>
              {plannedLabel}
            </Typography>
            <Typography style={{ fontWeight: 700, color: bhpColor.orange1 }}>
              {bookedLabel}
            </Typography>
          </Box>
        </TableCell>
      </Hidden>
      <TableCell style={{ cursor: "pointer" }} onClick={() => scrollLeft()}>
        <Icon path={mdiLessThan} size={1} style={{ transform: "scaleX(0.6)" }} />
      </TableCell>
      {buildingCapacityRange.map((val, idx) => {
        return (
          <TableCell
            style={{ cursor: "pointer" }}
            key={`thc${idx}`}
            className={isWeekend(new Date(val.date || "")) ? classes.weekend : ""}
            onClick={() => setDate(val.date)}
          >
            <Box
              py={mdAndUp ? 2 : 0}
              px={mdAndUp ? 0.5 : 0}
              style={{ textAlign: "center", fontSize: mdAndUp ? "20px" : "12px" }}
              className={`${classes.onDateHover} ${
                moment(today).isSame(val.date, "day") ? classes.today : ""
              } ${moment(selectedDate).isSame(val.date, "day") ? classes.selected : ""}`}
            >
              <Typography style={{ fontWeight: 700 }}>{moment(val.date).format("D")}</Typography>
              <Typography style={{ fontSize: "smaller" }}>
                {t(moment(val.date).format("ddd"))}
              </Typography>
            </Box>
          </TableCell>
        );
      })}
      <TableCell style={{ cursor: "pointer" }} onClick={() => scrollRight()}>
        <Icon path={mdiGreaterThan} size={1} style={{ transform: "scaleX(0.6)" }} />
      </TableCell>
    </TableRow>
  );
});

export default PeopleTabHeadRow;
