import Holidays from "date-holidays";
import { Holiday, AllHolidays } from "interfaces/Utils";
import moment from "moment";

interface Country {
  code: string;
  name: string;
  states?: State[];
}
interface State {
  code: string;
  name: string;
}
export const countries: Country[] = [
  {
    code: "AU",
    name: "Australia",
    states: [
      { code: "ACT", name: "Australian Capital Territory" },
      { code: "NSW", name: "New South Wales" },
      { code: "NT", name: "Northern Territory" },
      { code: "QLD", name: "Queensland" },
      { code: "SA", name: "South Australia" },
      { code: "TAS", name: "Tasmania" },
      { code: "VIC", name: "Victoria" },
      { code: "WA", name: "Western Australia" },
    ],
  },
  { code: "US", name: "United States of America", states: [{ code: "TX", name: "Texas" }] },
  { code: "CL", name: "Chile" },
  { code: "SG", name: "Singapore" },
  { code: "CN", name: "China" },
  { code: "GB", name: "United Kingdom", states: [{ code: "ENG", name: "England" }] },
  { code: "PH", name: "Philippines" },
  { code: "MY", name: "Malaysia", states: [{ code: "14", name: "Kuala Lumpur" }] },
  { code: "JP", name: "Japan" },
  { code: "BR", name: "Brasil", states: [{ code: "MG", name: "Minas Gerais" }] },
  { code: "PE", name: "Peru" },
  { code: "EC", name: "Ecuador" },
  {
    code: "CA",
    name: "Canada",
    states: [
      { code: "SK", name: "Saskatchewan" },
      { code: "ON", name: "Ontario" },
    ],
  },
  { code: "MX", name: "Mexico" },
  { code: "TT", name: "Trinidad & Tobago" },
];

export const getHolidays = (years: string[]) => {
  const allHolidaysList: AllHolidays[] = [];

  countries.forEach((country) => {
    const holidayObject = new Holidays();
    var holidaysList = years
      .map((year) => {
        if (country.states && country.states?.length > 0) {
          var allStatesHolidays = country.states
            .map((state) => {
              holidayObject.init(country.code, state.code);
              return holidayObject.getHolidays(year, "en").map((h) => {
                return {
                  date: h.date,
                  name: h.name,
                  type: h.type,
                  state: state.name,
                  country: country.name,
                };
              });
            })
            .reduce((previous, current) => {
              return previous.concat(current);
            });

          return allStatesHolidays;
        } else {
          holidayObject.init(country.code);
          return holidayObject.getHolidays(year, "en").map((h) => {
            return {
              date: h.date,
              name: h.name,
              type: h.type,
              country: country.name,
              state: "",
            };
          });
        }
      })
      .reduce((previous, current) => previous?.concat(current!));

    holidaysList?.forEach((hd) => {
      const holidayFoundIndex = allHolidaysList.findIndex(
        (h) => h.date === moment(new Date(hd.date)).format("YYYY-MM-DD")
      );

      if (holidayFoundIndex >= 0) {
        allHolidaysList.map((h) => {
          if (h.date === moment(new Date(hd.date)).format("YYYY-MM-DD")) {
            h.holidays = [
              ...h.holidays,
              {
                country: country.name,
                holidayName: hd.name,
                holidayType: hd.type,
                state: hd.state || "",
              } as unknown as Holiday,
            ];
          }
        });
      } else {
        allHolidaysList.push({
          date: moment(new Date(hd.date)).format("YYYY-MM-DD"),
          holidays: [
            {
              country: country.name,
              holidayName: hd.name,
              holidayType: hd.type,
              state: hd.state || "",
            } as unknown as Holiday,
          ],
        });
      }
    });
  });

  return allHolidaysList;
};
