import {
  Box,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableHead,
  Theme,
  useTheme,
  Hidden,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useTeamStore } from "providers/RootStoreProvider";
import { useEffect, useState } from "react";
import { bhpColor } from "styles/globals";
import TeamDetailsPlannerTabBody from "./TeamDetailsPlannerTabBody";
import TeamDetailsPlannerTabHeadRow from "./TeamDetailsPlannerTabHeadRow";
import TeamDetailsPeopleSearch from "./TeamDetailsPeopleSearch";
import TeamDetailsPeopleSearchGrid from "./TeamDetailsPeopleSearchGrid";
import TeamDetailsPeopleSearchPlanner from "./TeamDetailsPeopleSearchPlanner";
import { ScheduleType } from "interfaces/Schedule";
import TeamDetailsPlannerNewAppointment from "components/team/TeamDetailsPlannerNewAppointment";

interface TeamDetailsPlannerTabProps {
  teamId: number;
  t: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartOuter: {
      border: `1px solid ${bhpColor.blueGrey4}`,
      [theme.breakpoints.down("sm")]: {
        border: 0,
        paddingLeft: 0,
      },
    },
    teamSelect: {
      "& fieldset": {
        backgroundColor: "white",
      },
    },
    tableBody: {
      "&:before": {
        content: "''",
        display: "table-row",
        height: 12,
      },
      "&:after": {
        content: "''",
        display: "table-row",
        height: 12,
      },
    },
  })
);
const TeamDetailsPlannerTab = observer((props: TeamDetailsPlannerTabProps) => {
  const { teamId, t } = props;
  const classes = innerStyles();
  const teamStore = useTeamStore();
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));

  const numberOfDaysOnChart = mdAndUp ? 6 : 1;
  const [displayStart, setDisplayStart] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [displayEnd, setDisplayEnd] = useState(
    moment(new Date()).add(numberOfDaysOnChart, "d").format("YYYY-MM-DD")
  );
  const [focussedDate, setFocussedDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const startDate = moment(new Date()).format("YYYY-MM-DD");
  const [cacheEndDate, setCacheEndDate] = useState(
    moment(new Date()).add(numberOfDaysOnChart, "d").format("YYYY-MM-DD")
  );
  const [teamSchedules, setTeamSchedules] = useState([]);

  const addDaysToDate = (date: string, amount: number) => {
    return moment(date).add(amount, "d").format("YYYY-MM-DD");
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [workType, setWorkType] = useState<ScheduleType | null>(null);
  const [plannerToggle, setPlannerToggle] = useState(false);
  const [sortByName, setSortByName] = useState(1);
  const teamScheduleWorkTypeChangeHandler = (scheduleDate: string, workType: ScheduleType) => {
    setWorkType(workType);
    setFocussedDate(scheduleDate);
  };
  const scrollTo = (direction: number) => {
    if (direction < 0) {
      const newStart = addDaysToDate(displayStart, 1 * -1);
      setDisplayStart(moment(newStart).isBefore(startDate) ? startDate : newStart);
      setDisplayEnd(
        addDaysToDate(
          moment(newStart).isBefore(startDate) ? startDate : newStart,
          numberOfDaysOnChart
        )
      );
    }
    if (direction > 0) {
      if (moment(displayEnd).diff(moment(), "weeks") < 4) {
        setDisplayStart(addDaysToDate(displayStart, 1));
        setDisplayEnd(addDaysToDate(displayEnd, 1));
        if (moment(displayEnd).isSameOrAfter(moment(cacheEndDate))) {
          setCacheEndDate(
            moment(cacheEndDate)
              .add(numberOfDaysOnChart + 1, "d")
              .format("YYYY-MM-DD")
          );
        }
      }
    }
  };

  const setTheDate = (dte: string) => {
    setFocussedDate(dte);
    setWorkType(null);
  };

  const handlePeopleSearch = (searchString: string) => {
    setSearchTerm(searchString);
  };

  const handleWorkTypeChange = (workType: ScheduleType) => {
    setWorkType(workType);
  };

  const handlePlannerToggle = (plannerToggle: boolean) => {
    setPlannerToggle(plannerToggle);
  };

  const handleSortByName = (sortByName: number) => {
    setSortByName(sortByName);
  };

  const [isLoadingSchedules, setIsLoadingSchedules] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoadingSchedules(true);
      const teamScheduleForWeeks = await teamStore.getTeamScheduleForDates(
        teamId,
        moment().format("YYYY-MM-DD"),
        moment().add(5, "weeks").format("YYYY-MM-DD")
      );
      if (teamScheduleForWeeks) {
        setTeamSchedules(teamScheduleForWeeks[0].schedules);
      }
      setIsLoadingSchedules(false);
    })();
  }, []);

  useEffect(() => {
    setDisplayEnd(addDaysToDate(displayStart, numberOfDaysOnChart));
  }, [numberOfDaysOnChart]);

  const displayedSchedules = teamSchedules?.filter(
    (schedule: any) =>
      moment(schedule.date).isSameOrAfter(moment(displayStart)) &&
      moment(schedule.date).isSameOrBefore(moment(displayEnd))
  );

  return (
    <>
      <Box style={{ color: bhpColor.blueGrey1 }}>
        {isLoadingSchedules ? (
          <Box>
            Loading planner...
            <CircularProgress size={14} color="secondary" />
          </Box>
        ) : (
          <Box className={classes.chartOuter}>
            <Table style={{ border: 0 }}>
              <TableHead>
                <TeamDetailsPlannerTabHeadRow
                  t={t}
                  teamId={teamId}
                  teamWorkTypeSchedule={displayedSchedules}
                  selectedDate={focussedDate}
                  scrollTo={scrollTo}
                  setSelectedDate={setTheDate}
                />
              </TableHead>
              <TableBody className={classes.tableBody}>
                <TeamDetailsPlannerTabBody
                  teamSchedule={displayedSchedules}
                  teamScheduleWorkTypeChange={teamScheduleWorkTypeChangeHandler}
                  workType={workType}
                />
              </TableBody>
            </Table>
          </Box>
        )}

        <TeamDetailsPeopleSearch
          t={t}
          teamId={teamId}
          focussedDate={focussedDate}
          onSearch={handlePeopleSearch}
          plannerToggle={handlePlannerToggle}
          onWorkTypeChange={handleWorkTypeChange}
          handleSortByName={handleSortByName}
          workType={workType}
        />
        {!plannerToggle ? (
          <TeamDetailsPeopleSearchGrid
            t={t}
            teamId={teamId}
            focussedDate={focussedDate}
            searchTerm={searchTerm}
            workType={workType}
          />
        ) : (
          <TeamDetailsPeopleSearchPlanner
            t={t}
            teamId={teamId}
            focussedDate={focussedDate}
            startDate={displayStart}
            endDate={displayEnd}
            searchTerm={searchTerm}
            workType={workType}
            sortByName={sortByName}
          />
        )}
      </Box>
      <Hidden mdUp>
        <TeamDetailsPlannerNewAppointment t={t} teamId={teamId} />
      </Hidden>
    </>
  );
});
export default TeamDetailsPlannerTab;
