import { Box, createStyles, Grid, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { wdpStrings } from "config/strings";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import {
  BasicBooking,
  calculateTimeBooked,
  getHoursMinutes,
  getScheduleFromBooking,
  getTimeStringFromScheduleTime,
  getBookingsForDate,
  StoreUserBookingList,
  getDaysApart,
  getLocalTimeStringForTimeZone,
  getTimeStringFromDate,
  getLocalDateStringForTimeZone,
} from "utils/hourlyBookings";
import CancelIcon from "@material-ui/icons/Cancel";
import { bhpColor } from "styles/globals";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import { useState } from "react";
import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { useRootStore } from "providers/RootStoreProvider";
import { Schedule } from "interfaces/Schedule";
import moment from "moment";
import { BuildingWithCapacity } from "interfaces/Building";
import InformationIcon from "components/utils/InformationIcon";
import { PopupInformationWidget } from "components/settings/PopupInformationWidget";

interface IF_BookingListProps {
  userBookings: StoreUserBookingList;
  date: string;
  t: any;
  currentBuilding?: BuildingWithCapacity | null;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    bookingRow: {
      display: "flex",
      fontSize: 14,
      alignItems: "center",
      paddingTop: "2px",
      paddingBottom: "2px",
      paddingRight: "8px",
      paddingLeft: "8px",
      "&:nth-child(odd)": {
        backgroundColor: bhpColor.blueGrey4,
      },
      "&:nth-child(even)": {
        backgroundColor: bhpColor.white,
      },
    },
  })
);

const IF_BookingList = observer((props: IF_BookingListProps) => {
  const { userBookings, date, t, currentBuilding } = props;
  const classes = innerStyles();
  const rootStore = useRootStore();

  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const [informationOpen, setInformationOpen] = useState(false);

  const handleInformationOpen = () => {
    setInformationOpen(true);
  };
  const handleInformationClose = () => {
    setInformationOpen(false);
  };

  const bookings = getBookingsForDate(userBookings, date, currentBuilding?.building_timezone);

  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [deleteConfirmSchedule, setDeleteConfirmSchedule] = useState<Schedule | null>(null);

  const timeBooked = getHoursMinutes(calculateTimeBooked(bookings));

  const getOffset = (
    date: string | undefined,
    comparisonDate: string | undefined,
    suffix: boolean = false
  ) => {
    if (!date || !comparisonDate) return "";
    const daysApart = getDaysApart(date, comparisonDate);
    return comparisonDate && daysApart !== 0 ? (
      <span style={{ display: "flex", alignItems: "center", fontSize: 10, fontWeight: 700 }}>
        {suffix ? `(+${daysApart})` : `(-${daysApart})`}
      </span>
    ) : (
      ""
    );
  };

  const getCorrectedTimeToDisplay = (
    isCurrentBuilding: boolean,
    booking: BasicBooking,
    useStartTime: boolean = true,
    timeZone: string
  ) => {
    const theOffset = getOffset(
      date,
      useStartTime
        ? booking.booking_details?.start_date_local
        : booking.booking_details?.end_date_local
    );

    const timeFromSchedule = getTimeStringFromScheduleTime(
      useStartTime
        ? booking.booking_details?.start_time_local
        : booking.booking_details?.end_time_local
    );
    const localTime = getLocalTimeStringForTimeZone(
      useStartTime ? booking.booking_period.start : booking.booking_period.end,
      timeZone
    );
    const localDate = moment(
      getLocalDateStringForTimeZone(
        useStartTime ? booking.booking_period.start : booking.booking_period.end,
        timeZone
      )
    ).format("Do");

    return isCurrentBuilding ? (
      <>{`${moment(date).format("Do") !== localDate ? localDate : ""} ${timeFromSchedule}`}</>
    ) : (
      <>
        <span
          style={{ fontSize: "x-small", marginRight: "8px", whiteSpace: "nowrap" }}
        >{`(${timeFromSchedule})`}</span>
        <span style={{ fontStyle: "normal", whiteSpace: "nowrap" }}>
          {`${moment(date).format("Do") !== localDate ? localDate : ""} ${localTime}`}
        </span>
      </>
    );
  };

  return bookings && bookings.length > 0 ? (
    <>
      <ConfirmDialog
        title={t("Change of plan?")}
        primaryButtonText={t("Remove")}
        secondaryButtonText={t("Keep")}
        handleSecondaryClick={() => setDeleteConfirmDialog(false)}
        handlePrimaryClick={async () => {
          if (deleteConfirmSchedule) {
            if (deleteConfirmSchedule.booking_id)
              rootStore.bookingStore.deleteBooking(deleteConfirmSchedule.booking_id);
          }
          setDeleteConfirmDialog(false);
        }}
        isOpen={deleteConfirmDialog}
      >
        {deleteConfirmSchedule && (
          <p>
            {t("Remove your desk booking for")}{" "}
            <Box fontWeight="bold" color="primary.main" component="span">
              {deleteConfirmSchedule.space?.name}
            </Box>{" "}
            <Box fontWeight="bold" component="span">
              {deleteConfirmSchedule.space?.building?.city}
            </Box>
            {", "}
            <Box component="span">{deleteConfirmSchedule.office?.building_name}</Box>
            {` ${t("on")} `}
            {`${t(moment(deleteConfirmSchedule!.date).format("dddd"))}, ${t(
              moment(deleteConfirmSchedule!.date).format("Do")
            )} ${t(moment(deleteConfirmSchedule!.date).format("MMMM"))}`}
            {`${t("?")} `}
          </p>
        )}
      </ConfirmDialog>

      <Grid
        container
        key={`ifb0-0`}
        style={{
          display: "flex",
          width: "400px",
          height: "fit-content",
          borderRadius: "4px",
          border: `1px solid ${bhpColor.blueGrey1}`,
          position: "relative",
          marginBottom: isOpen ? "-165px" : "-27px",
          marginTop: "4px",
        }}
      >
        <Grid
          item
          key={`ifb1-0`}
          xs={12}
          style={{
            display: "flex",
            maxHeight: "140px",
            overflow: "auto",
            borderBottom: isOpen ? `1px solid ${bhpColor.blueGrey2}` : "0",
          }}
        >
          <Grid
            item
            key={`ifb2-0`}
            xs={10}
            style={{
              paddingLeft: "8px",
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box style={{ marginLeft: "-18px", marginRight: "4px", cursor: "pointer" }}>
              <InformationIcon onclick={handleInformationOpen} />
            </Box>
            Your bookings {moment(date).format("MMM Do")}{" "}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                fontWeight: 400,
                marginLeft: "8px",
              }}
            >
              ({timeBooked})
            </Box>
          </Grid>
          <Grid
            item
            key={`ifb3-0`}
            xs={2}
            style={{
              display: "flex",
              justifyContent: "end",
              paddingRight: "8px",
              alignItems: "center",
            }}
          >
            <Box onClick={() => toggleOpen()} style={{ display: "flex" }}>
              {!isOpen ? <AddBoxOutlinedIcon /> : <IndeterminateCheckBoxOutlinedIcon />}
            </Box>
          </Grid>
        </Grid>
        {bookings &&
          bookings.map((bkg, idx) => {
            const pseudoSchedule = getScheduleFromBooking(bkg);
            const isCurrentBuilding = !currentBuilding
              ? true
              : bkg.building
              ? bkg.building.id === currentBuilding.id
              : false;
            return (
              <Grid
                item
                key={`ifba-${idx}`}
                xs={12}
                style={{
                  backgroundColor: "#FFFFFFBB",
                  zIndex: "10",
                  display: isOpen ? "flex" : "none",
                  fontStyle: isCurrentBuilding ? "normal" : "italic",
                }}
                className={classes.bookingRow}
              >
                <Grid item xs={2}>
                  <Tooltip
                    title={`${pseudoSchedule.office?.city}, ${
                      pseudoSchedule.office?.building_name || ""
                    }`}
                  >
                    <span>{bkg.space?.name}</span>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "end", alignItems: "center" }}
                >
                  {getCorrectedTimeToDisplay(
                    isCurrentBuilding,
                    bkg,
                    true,
                    isCurrentBuilding
                      ? pseudoSchedule.office!.building_timezone!
                      : currentBuilding!.building_timezone!
                  )}
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "end", alignItems: "center" }}
                >
                  {getCorrectedTimeToDisplay(
                    isCurrentBuilding,
                    bkg,
                    false,
                    isCurrentBuilding
                      ? pseudoSchedule.office!.building_timezone!
                      : currentBuilding!.building_timezone!
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Link
                    to="#"
                    onClick={() => {
                      setDeleteConfirmDialog(true);
                      setDeleteConfirmSchedule(pseudoSchedule);
                    }}
                  >
                    <Box
                      style={{
                        color: "red",
                        textDecoration: "unset",
                      }}
                    >
                      <Tooltip title={t("Cancel booking")}>
                        <CancelIcon style={{ marginLeft: "8px", cursor: "pointer" }} />
                      </Tooltip>
                    </Box>
                  </Link>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
      <PopupInformationWidget
        t={t}
        isOpen={informationOpen}
        handleClose={handleInformationClose}
        handleSecondaryClick={handleInformationClose}
        title={"Your bookings"}
        content={`<p>These are your bookings for today across the whole of our offices.</p><p>If a time appears in brackets it is the booking time specific to another location e.g (2:00pm)</p><p>All times listed elsewhere are relative to the selected office.</p>`}
        buttonText={"Okay"}
        secondaryButtonText={null}
      />
    </>
  ) : (
    <></>
  );
});

export default IF_BookingList;
