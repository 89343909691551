import { bhpColor } from "styles/globals";

export const range = { red: 89, amber: 69 };

export const getIndicatorColor = (percentage: number) => {
  return percentage > range.red
    ? bhpColor.availabilityRed
    : percentage > range.amber
    ? bhpColor.availabilityAmber
    : percentage === 0
    ? "transparent"
    : bhpColor.available;
};

export const getPercentage = (total: number, amount: number, decimalPlaces: number = 0) => {
  if (total === 0 || amount === 0) return 0;
  const percentage = Math.ceil(100 * (amount / total));
  return percentage;
};

export const drawDot = (diameter: number, indicatorColor: string) => {
  return (
    <div
      className="dot"
      style={{ height: `${diameter}px`, width: `${diameter}px`, backgroundColor: indicatorColor }}
    ></div>
  );
};
