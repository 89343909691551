import moment from "moment";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useMediaQuery,
  createStyles,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { dateInPastArrow, getDateString } from "utils/date";

import {
  useBuildingStore,
  useRootStore,
  useScheduleStore,
  useUserStore,
} from "providers/RootStoreProvider";
import { Schedule, ScheduleType } from "interfaces/Schedule";
import { bhpColor } from "styles/globals";
import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { cityAbbr } from "utils/locations";
import { Building, BuildingType, BuildingWithCapacity } from "interfaces/Building";
import CreateIcon from "@material-ui/icons/Create";
import CancelIcon from "@material-ui/icons/Cancel";
import { toJS } from "mobx";
import { get } from "lodash";
import { FavouriteContextMenu } from "components/booking/FavouriteContextMenu";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import {
  BasicBooking,
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
  getBookingsForDate,
  getDaysApart,
  getDistinctBuildings,
  getScheduleFromBooking,
  getTimeStringFromScheduleTime,
  getWeekOfDates,
  getForwardDates,
} from "utils/hourlyBookings";
import BuildingStore from "stores/BuildingStore";

interface Column {
  id: keyof Schedule;
  label: string;
  align?: "right" | "left" | "center";
  format?: (value: any, value2?: any) => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      height: 40,
      "&:nth-child(odd)": {
        backgroundColor: bhpColor.blueGrey4,
      },
    },
    tableCell: {
      padding: "0px 16px",
      [theme.breakpoints.down("md")]: {
        padding: "0px 4px",
      },
    },
    buildingSelector: {
      padding: 0,
      marginRight: "8px",
      "& .MuiSelect-root": {
        padding: "4px",
        width: "80px",
        fontSize: "14px",
      },
      "& .MuiSelect-iconOutlined": {
        right: 0,
      },
    },
  })
);

const BookingTab = observer((props: any) => {
  const rootStore = useRootStore();
  const store = useScheduleStore();
  const buildingStore = useBuildingStore();
  const userStore = useUserStore();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = props;

  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [deleteConfirmSchedule, setDeleteConfirmSchedule] = useState<Schedule | null>(null);

  const jumpToBookingPage = (schedule: Schedule, specificBuilding_id?: number) => {
    if (specificBuilding_id) {
      const floorId = get(schedule.space, "floor");
      buildingStore.setCurrentBuilding(specificBuilding_id, schedule.date);
      if (schedule.space)
        buildingStore.setCurrentSpace(
          floorId || null,
          schedule.space.serraview_id || null,
          schedule.space
        );
      if (floorId) buildingStore.loadSpaces(specificBuilding_id, floorId, schedule.date, true);
      history.push(`/booking/${moment(schedule.date).format("YYYY-MM-DD")}/${specificBuilding_id}`);
    } else {
      const floorId = get(schedule.space, "floor");
      if (schedule.office) buildingStore.setCurrentBuilding(schedule.office.id, schedule.date);
      if (schedule.space)
        buildingStore.setCurrentSpace(
          floorId || null,
          schedule.space.serraview_id || null,
          schedule.space
        );
      if (schedule.office && floorId)
        buildingStore.loadSpaces(schedule.office?.id, floorId, schedule.date, true);
      if (schedule.office)
        history.push(
          `/booking/${moment(schedule.date).format("YYYY-MM-DD")}/${schedule.office.id}`
        );
    }
  };

  const getColumns = (smAndUp: boolean, t: any, buildingStore: BuildingStore): Column[] => {
    const deskSortOrder = [
      "Anchor - OHS",
      "Anchor - Technology",
      "Desk - Fixed Height",
      "Desk - Sit/Stand",
      "Desk - Ergotron Sit/Stand",
      "Desk - Webcam",
      "Desk - Dockless Monitors",
      "Desk - Dockless Monitor",
      "Desk - Large Monitor",
      "Desk - P50 Dock",
      "Desk - HP Technical Dock",
      "Desk - Thunderbolt Dock",
      "Desk - Non-Standard Workstation",
      "Desk Non-Standard Workstation",
    ];

    const openFloorPlan = (id, canEdit, date) => {
      buildingStore.setupFloorPlanFromSerraviewId(id, canEdit, date);
    };

    const sortDeskTag = (a: any, b: any) => {
      const indexA = deskSortOrder.indexOf(a);
      const indexB = deskSortOrder.indexOf(b);
      return deskSortOrder.indexOf(a) > deskSortOrder.indexOf(b) ? 1 : -1;
    };

    return [
      {
        id: "date",
        label: "Date",
        align: "left",
        format: (schedule: Schedule) => {
          if (smAndUp)
            return `${t(moment(schedule.date).format("ddd"))}, ${t(
              moment(schedule.date).format("Do")
            )} ${t(moment(schedule.date).format("MMMM"))}`;
          else return moment(schedule.date).format("DD/MM/YY");
        },
      },
      {
        id: "status",
        label: "Building",
        align: "left",
        format: (schedule: Schedule, bookings: BasicBooking[] | null = []) => {
          const type = schedule.status;
          switch (type) {
            case ScheduleType.OFFICE: {
              if (!schedule.space && bookings && bookings.length > 1) return "";
              if (schedule?.space?.building) {
                if (smAndUp) return schedule?.space.building.city;
                else return cityAbbr(schedule?.space.building);
              }
              if (schedule?.office) {
                if (smAndUp) return schedule?.office.city;
                else return cityAbbr(schedule?.office);
              }
              return t("Office");
            }
            case ScheduleType.REMOTE:
              return t("Remote");
            case ScheduleType.OFF:
              return t("Off");
            default:
              return t("Unknown");
          }
        },
      },
      {
        id: "space",
        label: "Desk",
        align: "left",
        format: (schedule: Schedule) => {
          const startDay = schedule.date !== schedule.start_date ? schedule.start_date : "";
          const endDay = schedule.date !== schedule.finish_date ? schedule.finish_date : "";

          const outputTimes = {
            start: getTimeStringFromScheduleTime(schedule.desk_start_time),
            end: getTimeStringFromScheduleTime(schedule.desk_finish_time),
          };

          const startTime = schedule.desk_start_time
            ? `${startDay ? "Midnight" : outputTimes.start}`
            : "";
          const endTime = schedule.desk_finish_time
            ? `${endDay ? "Midnight" : outputTimes.end}`
            : "";
          let daysFromStart = 0;
          let daysToFinish = 0;

          if (schedule.finish_date !== schedule.start_date) {
            // this either starts of ends on a different day
            daysFromStart = getDaysApart(schedule.start_date, schedule.date);
            daysToFinish = getDaysApart(schedule.finish_date, schedule.date);
          }

          const localStart = moment
            .tz(
              `${schedule.start_date} ${schedule.desk_start_time || DEFAULT_START_TIME}`,
              schedule.office?.building_timezone!
            )
            .local()
            .format("Do h:mma");
          const localEnd = moment
            .tz(
              `${schedule.finish_date} ${schedule.desk_finish_time || DEFAULT_END_TIME}`,
              schedule.office?.building_timezone!
            )
            .local()
            .format("Do h:mma");

          const localCompare = moment(
            `${schedule.start_date} ${schedule.desk_start_time || DEFAULT_START_TIME}`
          ).format("Do h:mma");

          const localToolTip = localCompare !== localStart ? `${localStart} - ${localEnd}` : "";

          return schedule.space?.name ? (
            <Grid container>
              <Grid
                item
                key={`${schedule.booking_id}a`}
                style={{ display: "flex", alignItems: "center" }}
              >
                {schedule.space?.serraview_id ? (
                  <Link
                    to="#"
                    onClick={() =>
                      openFloorPlan(schedule.space?.serraview_id, false, schedule.date)
                    }
                  >
                    {schedule.space.name}
                  </Link>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item key={`${schedule.booking_id}b`}>
                <Tooltip title={localToolTip}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ textDecoration: "none" }}
                  >
                    <Grid item>{startTime}</Grid>
                    <Grid item>{endTime}</Grid>
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>
          ) : schedule.desk_required == 2 ? (
            "No Desk Required"
          ) : (
            ""
          );
        },
      },
      {
        id: "space",
        label: "Desk Properties",
        align: "left",
        format: (schedule: Schedule) => {
          const rawTags = schedule.space?.tags;
          let tags: string = "";
          if (rawTags) {
            const re = /Desk - /gi;
            tags = rawTags
              .slice()
              .sort((a: string, b: string) => sortDeskTag(a, b))
              .map((tg: string) => t(tg.replace(re, "")))
              .toString();
          }
          return tags !== "" ? tags : schedule.space?.space_type?.name || "";
        },
      },
    ];
  };

  const columns = getColumns(smAndUp, t, buildingStore);

  const [showBookingDates, setShowBookingDates] = useState<string[]>([getDateString(new Date())]);
  const toggleDate = (date: string) => {
    let newDates: string[] = [];
    if (showBookingDates.includes(date)) {
      newDates = [...showBookingDates.filter((dte) => !(`${dte}` === `${date}`))];
    } else {
      newDates = showBookingDates.slice();
      newDates.push(date);
    }
    setShowBookingDates(newDates.slice());
  };

  const drawContextMenu = (
    schedule: Schedule,
    focussedBuilding: Building | null | undefined,
    coreSchedule: Schedule
  ) => {
    if (!focussedBuilding) return;
    const hasFavourites = focussedBuilding
      ? userStore.hasFavouritesInBuilding(focussedBuilding.id)
      : false;
    if (coreSchedule.desk_required == 2) {
      focussedBuilding = null;
    }
    return (
      hasFavourites && (
        <div
          style={{
            marginRight: "10px",
            marginLeft: smAndUp ? "-40px" : "17px",
            cursor: "alias",
            display: "flex",
            fill: "yellow",
          }}
        >
          {" "}
          {focussedBuilding && (
            <FavouriteContextMenu
              t={t}
              building={focussedBuilding}
              date={schedule.date}
              from={
                coreSchedule.desk_start_time && `${coreSchedule.desk_start_time}` !== ""
                  ? coreSchedule.desk_start_time
                  : DEFAULT_START_TIME
              }
              to={
                coreSchedule.desk_finish_time && `${coreSchedule.desk_finish_time}` != ""
                  ? coreSchedule.desk_finish_time
                  : DEFAULT_END_TIME
              }
              schedule={coreSchedule}
            />
          )}
        </div>
      )
    );
  };

  const drawBookNowButton = (schedule: Schedule, coreSchedule: Schedule) => {
    return (
      <Button
        onClick={() => jumpToBookingPage(coreSchedule)}
        style={{ height: 24, textDecoration: "none" }}
        variant="contained"
        color="primary"
        disableElevation
        disabled={coreSchedule.desk_required == 2 ? true : false}
      >
        {smAndUp ? t("Book Desk") : t("Book")}
      </Button>
    );
  };

  const drawCancelIcon = (schedule: Schedule) => {
    return (
      <Link
        to="#"
        onClick={() => {
          setDeleteConfirmDialog(true);
          setDeleteConfirmSchedule(schedule);
        }}
      >
        <Box
          style={{
            color: "red",
            textDecoration: "unset",
          }}
        >
          <Tooltip title={t("Cancel booking")}>
            <CancelIcon style={{ marginLeft: "8px" }} />
          </Tooltip>
        </Box>
      </Link>
    );
  };

  const drawRow = (
    columns: Column[],
    index: string | number,
    isPast: boolean,
    schedule: Schedule,
    booking: BasicBooking | null,
    allBookings: BasicBooking[] | null,
    hasBookings: boolean = false
  ) => {
    const isScheduleRow =
      schedule.status !== ScheduleType.OFFICE ||
      (schedule.status === ScheduleType.OFFICE && !booking);

    const firstRow = isScheduleRow && !booking && `${index}`.indexOf(":") === -1;
    const pseudoSchedule: Schedule = booking
      ? { ...getScheduleFromBooking(booking, schedule), date: schedule.date }
      : firstRow && allBookings
      ? {
          ...getScheduleFromBooking(allBookings[0], schedule),
          date: schedule.date,
          // desk_start_time: schedule.desk_start_time,
          // desk_finish_time: schedule.desk_finish_time,
        }
      : { ...schedule, space: null };

    const showButton = schedule.status === ScheduleType.OFFICE && !schedule.space && !booking;
    const isBookingRow = !schedule.space && booking;

    const drawRow = true;
    const drawExpander = false;

    const focussedBuildingId = rootStore.bookingStore.focussedBuilding[pseudoSchedule.date];
    const focussedBuilding = focussedBuildingId
      ? rootStore.buildingStore.getBuilding(focussedBuildingId)
      : null;

    return (
      <TableRow
        hover
        className={`${classes.tableRow} ${isPast ? "dimmed" : ""}`}
        style={{
          height: "40px",
          display: drawRow ? "table-row" : "none",
        }}
        role="checkbox"
        tabIndex={-1}
        key={index}
      >
        {columns.map((column, idx) => {
          const value = pseudoSchedule[column.id];
          switch (idx) {
            case 0: {
              return (
                <TableCell
                  align={column.align}
                  className={classes.tableCell}
                  key={`${index}-${idx}`}
                  onClick={() => toggleDate(pseudoSchedule.date)}
                >
                  <Box style={{ display: "flex" }}>
                    {!isBookingRow && drawExpander ? (
                      showBookingDates.includes(pseudoSchedule.date) ? (
                        <IndeterminateCheckBoxOutlinedIcon
                          style={{ verticalAlign: "middle", height: "18px" }}
                        />
                      ) : (
                        <AddBoxOutlinedIcon style={{ verticalAlign: "middle", height: "18px" }} />
                      )
                    ) : (
                      ""
                    )}
                    {isScheduleRow ? (column.format ? column.format(pseudoSchedule) : value) : ""}
                  </Box>
                </TableCell>
              );
            }
            case 1: {
              return (
                <TableCell
                  align={column.align}
                  className={classes.tableCell}
                  key={`${index}~${idx}`}
                >
                  {column.format ? column.format(pseudoSchedule, allBookings) : value}
                </TableCell>
              );
            }
            case 2: {
              return (
                <TableCell
                  align={column.align}
                  className={classes.tableCell}
                  key={`${index}x${idx}`}
                  style={{ width: smAndUp ? "30%" : "40%", cursor: "pointer" }}
                >
                  {hasBookings && column.format
                    ? column.format(pseudoSchedule)
                    : column.format
                    ? column.format(pseudoSchedule)
                    : value}
                </TableCell>
              );
            }
            case 3: {
              return (
                <TableCell style={{ display: smAndUp ? "" : "none" }} key={`${index}~${idx}`}>
                  {""}
                </TableCell>
              );
            }
            default: {
              return (
                <TableCell
                  align={column.align}
                  className={classes.tableCell}
                  key={`${index}~${idx}`}
                >
                  {column.format ? column.format(pseudoSchedule) : value}
                </TableCell>
              );
            }
          }
        })}
        <TableCell
          className={classes.tableCell}
          key={`actions${index}a`}
          style={{ alignContent: "end" }}
          align="right"
        >
          <Grid container>
            <Grid item xs={4} style={{ justifyContent: "flex-start", display: "flex" }}>
              {isPast
                ? ""
                : (pseudoSchedule.status === ScheduleType.OFFICE &&
                    pseudoSchedule.space &&
                    drawCancelIcon(pseudoSchedule)) ||
                  (pseudoSchedule.status === ScheduleType.OFFICE &&
                    pseudoSchedule.desk_required == 2 &&
                    drawCancelIcon(pseudoSchedule))}
            </Grid>
            <Grid item xs={8}>
              {isPast ? (
                ""
              ) : pseudoSchedule.status === ScheduleType.OFFICE && firstRow ? (
                <Box
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  {drawContextMenu(pseudoSchedule, pseudoSchedule.office, schedule)}
                  {drawBookNowButton(pseudoSchedule, schedule)}
                </Box>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  const today = new Date();
  const groupOfDates = getForwardDates(today, today.getDay() + 15);

  useEffect(() => {
    groupOfDates.forEach((theDate) => {
      const schedule = store.bookingSchedules.find((sch) => sch.date === getDateString(theDate));
      if (schedule && schedule.status === ScheduleType.OFFICE && schedule.office)
        rootStore.bookingStore.setDateFocussedBuilding(schedule.date, schedule.office.id);
    });
  }, [store.bookingSchedules]);

  return (
    <>
      <ConfirmDialog
        title={t("Change of plan?")}
        primaryButtonText={t("Remove")}
        secondaryButtonText={t("Keep")}
        handleSecondaryClick={() => setDeleteConfirmDialog(false)}
        handlePrimaryClick={async () => {
          if (deleteConfirmSchedule) {
            if (deleteConfirmSchedule.booking_id) {
              rootStore.bookingStore.deleteBooking(deleteConfirmSchedule.booking_id);
              store.isDirty = false;
              store.isLoading = true;
              store.schedules.forEach((element, index) => {
                if (element.date === deleteConfirmSchedule.date) {
                  element.desk_required = 0;
                  element.status = 1;
                }
              });
              store.isLoading = false;
            } else await store.cancelDesk(deleteConfirmSchedule.date);
          }
          setDeleteConfirmDialog(false);
        }}
        isOpen={deleteConfirmDialog}
      >
        {deleteConfirmSchedule && (
          <p>
            {t("Remove your desk booking for")}{" "}
            <Box fontWeight="bold" color="primary.main" component="span">
              {deleteConfirmSchedule.space?.name}
            </Box>{" "}
            <Box fontWeight="bold" component="span">
              {deleteConfirmSchedule.space?.building?.city}
            </Box>
            {", "}
            <Box component="span">{deleteConfirmSchedule.office?.building_name}</Box>
            {` ${t("on")} `}
            {`${t(moment(deleteConfirmSchedule!.date).format("dddd"))}, ${t(
              moment(deleteConfirmSchedule!.date).format("Do")
            )} ${t(moment(deleteConfirmSchedule!.date).format("MMMM"))}`}
            {`${t("?")} `}
          </p>
        )}
      </ConfirmDialog>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, idx) => {
                    switch (idx) {
                      case 2:
                        return (
                          <TableCell
                            key={`${column.id}-${idx}`}
                            align={column.align}
                            style={{
                              width: "auto",
                              fontWeight: 700,
                              fontSize: 16,
                              minWidth: 200,
                              color: bhpColor.blueGrey1,
                            }}
                          >
                            <Grid container>
                              <Grid item xs={6}>
                                {t("Desk")}
                              </Grid>
                              <Grid item xs={3} style={{ textAlign: "left" }}>
                                {t("Start")}
                              </Grid>
                              <Grid item xs={3} style={{ textAlign: "left" }}>
                                {t("End")}
                              </Grid>
                            </Grid>
                          </TableCell>
                        );
                      case 3:
                        return (
                          <TableCell
                            key={`${column.id}-${idx}`}
                            style={{ display: smAndUp ? "" : "none" }}
                          >
                            {""}
                          </TableCell>
                        );
                      default:
                        return (
                          <TableCell
                            key={`${column.id}-${idx}`}
                            align={column.align}
                            style={{
                              width: idx === 3 ? "30%" : "unset",
                              fontWeight: 700,
                              fontSize: 16,
                              color: bhpColor.blueGrey1,
                            }}
                          >
                            {t(column.label)}
                          </TableCell>
                        );
                    }
                  })}
                  <TableCell
                    style={{ fontWeight: 700, fontSize: 16, color: bhpColor.blueGrey1 }}
                    align="right"
                  >
                    {t("Action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupOfDates.map((theDate, index) => {
                  const schedule = store.bookingSchedules.find(
                    (sch) => sch.date === getDateString(theDate)
                  );
                  if (schedule) {
                    const isPast = dateInPastArrow(moment(schedule.date));
                    let daysBookings: BasicBooking[] = [];
                    daysBookings = getBookingsForDate(
                      rootStore.bookingStore.user_bookings,
                      schedule.date,
                      buildingStore.currentBuilding?.building_timezone
                    ).sort((a, b) => (a.booking_period.start > b.booking_period.start ? 1 : -1));
                    if (daysBookings && daysBookings.length > 0) {
                      return (
                        <Fragment key={`F${index}`}>
                          {drawRow(
                            columns,
                            `${index}`,
                            isPast,
                            { ...schedule, space: null },
                            null,
                            daysBookings,
                            true
                          )}
                          {daysBookings.map((bkg: BasicBooking, idx) => {
                            if (idx > 0)
                              return drawRow(
                                columns,
                                `${index}:${idx}`,
                                isPast,
                                schedule,
                                bkg,
                                daysBookings
                              );
                          })}
                        </Fragment>
                      );
                    } else {
                      return drawRow(columns, index, isPast, schedule, null, null);
                    }
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
});

export default BookingTab;
