import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
  TextField,
  Select,
  Button,
  FormControl,
  Snackbar,
  Checkbox,
  Link,
  MenuItem,
  Popover,
  Modal,
} from "@material-ui/core";
import { getTimes, Time } from "utils/time";
import { weekDayNamesSorter } from "utils/date";
import { observer } from "mobx-react-lite";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridOverlay,
} from "@material-ui/data-grid";
import {
  useBuildingStore,
  useBookingStore,
  useRootStore,
  useTeamStore,
  useScheduleStore,
} from "providers/RootStoreProvider";
import { bhpColor, displaySettings, settings } from "styles/globals";
import { Fragment, useEffect, useState, useCallback, useMemo, memo } from "react";
import { PopupInformationWidget } from "components/settings/PopupInformationWidget";
import InformationIcon from "components/utils/InformationIcon";
import {
  AnchorDays,
  IloOccurance,
  IloBOSPractice,
  IloPlace,
  Activity,
  ILOAnchorDays,
} from "interfaces/Teams";
import { SettingsSelectBox } from "components/settings/SettingsSelectBox";
import { capitalizeFirstLetter, capitalizeString } from "utils/string";
import moment from "moment";
import { useHistory } from "react-router";
import LoadingZone from "components/schedule/LoadingZone";
import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { CheckBoxOutlined } from "@material-ui/icons";
import config from "config";
import { TeamParticipant, TeamRoleType } from "interfaces/Teams";
import "react-big-calendar/lib/css/react-big-calendar.css";

interface TeamIloAddActivityProps {
  teamId: number;
  t: any;
  teamMembers: TeamParticipant[];
  activity: Activity | null;
  onChange?: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
    },
    textArea: {
      height: 32,
      width: "100%",
      paddingLeft: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      backgroundColor: `${bhpColor.blueGrey4}50`,
    },
    h4: {
      fontWeight: "bold",
      color: bhpColor.blueGrey1,
      fontSize: 13,
      display: "inline-block",
    },
    textField: {
      width: "calc(100% - 150px)",
      borderRadius: settings.borderRadius,
      backgroundColor: "white",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      "& .MuiInput-root": {
        paddingLeft: theme.spacing(1),
        fontSize: "14px",
      },
      "& .MuiInput-underline:before": {
        borderBottom: `0 !important`,
        transition: "none",
      },
      "& .Mui-focussed": {
        borderBottom: `0 !important`,
      },
      "& .MuiInputBase-inputMultiline": {
        fontSize: 12,
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        fontSize: 12,
      },
    },
    formControl: {
      display: "block",
      width: "100%",
      margin: 0,
      "& svg": {
        width: "20px",
        marginRight: "6px",
      },
    },

    selectBox: {
      width: "calc(100% - 220px)",
      height: 40,
      "& svg": {
        zIndex: 2,
      },
      "& select": {
        zIndex: 2,
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& fieldset": {
        zIndex: 1,
        height: 40,
        top: -3,
        backgroundColor: "white",
        "& .Mui-focussed": {
          backgroundColor: "white",
        },
      },
    },
    selectBoxOcc: {
      width: "100%",
      fontSize: 13,
      height: 40,
      "& svg": {
        zIndex: 2,
      },
      "& select": {
        zIndex: 2,
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& fieldset": {
        zIndex: 1,
        height: 40,
        top: -3,
        backgroundColor: "white",
        "& .Mui-focussed": {
          backgroundColor: "white",
        },
      },
    },

    outer: {
      borderRadius: settings.borderRadius,
      border: `1px solid ${bhpColor.blueGrey4}`,
      color: bhpColor.blueGrey1,
    },
    dataGrid: {
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiDataGrid-row.Mui-even": {
        backgroundColor: "#F6F6F6",
      },
      "& .MuiDataGrid-cell": {
        color: "#466371",
        "&:focus-within": {
          outlineWidth: 0,
        },
      },
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-sortIcon": {
          opacity: `1 !important`,
        },
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        color: "#50524D",
        fontWeight: "bold !important",
      },
      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden",
      },
      padding: "0 0% 0% 0%",
    },
    spaceTable: {
      border: 0,
      "& .MuiTablePagination-root": {
        color: bhpColor.blueGrey1,
      },
      "& .MuiDataGrid-renderingZone": {
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-viewport": {
        maxHeight: "none",
      },
      "& .MuiDataGrid-root": {
        border: 0,
        fontSize: 12,
      },
      "& .MuiDataGrid-cell": {
        border: 0,
        "&:focus": {
          outline: 0,
        },
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-columnHeader": {
        "&:focus": {
          outline: 0,
        },
        "&:focus-within": {
          outline: 0,
        },
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        paddingLeft: 0,
      },
      "& .MuiDataGrid-columnsContainer": {
        borderBottom: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 90,
        color: bhpColor.blueGrey1,
      },
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
      "& .fc-toolbar ": {
        display: "none",
      },
      "& .MuiDataGrid-row": {
        maxHeight: "none !important",
        "&:nth-child(odd)": {
          backgroundColor: bhpColor.backgroundGrey,
          "&:hover": {
            backgroundColor: bhpColor.backgroundGrey,
          },
        },
        "&.Mui-selected": {
          "&:hover": {
            backgroundColor: bhpColor.blueGrey2,
            color: bhpColor.blueGrey1,
          },
          backgroundColor: bhpColor.blueGrey1,
          color: bhpColor.white,
        },
        "&:hover": {
          backgroundColor: bhpColor.backgroundGrey,
        },
      },
    },
  })
);

interface DialogData {
  title: string | null;
  content: string | null;
  primaryButtonText: string | null;
  secondaryButtonText?: string | null;
  handleClose?: any;
}

interface Participant extends TeamParticipant {
  isParticipant: boolean;
}

const TeamIloAddActivity = observer((props: TeamIloAddActivityProps) => {
  const { teamId, t, teamMembers, activity, onChange } = props;
  const store = useRootStore();
  const classes = innerStyles();
  const teamStore = useTeamStore();
  const [informationModal, setInformationModal] = useState<DialogData | null>();
  const [informationOpen, setInformationOpen] = useState(false);
  const handleInformationClose = () => setInformationOpen(false);
  const theme = useTheme();
  const history = useHistory();

  const subHeadAndInfo = (label: string, infoId: number) => {
    return (
      <Box mb={1} mt={1.5} style={{ display: "flex" }}>
        <Typography className={classes.h4}>{t(label)}</Typography>
        <InformationIcon onclick={() => handleInformationOpen(infoId)} color={bhpColor.orange1} />
      </Box>
    );
  };

  const [show, setShow] = useState(false);
  const [showOffice, setOffice] = useState(activity?.place === IloPlace.BHP_LOCATION);
  const textAreaRows = 2;
  const textAreaMaxCharacters = 250;
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const gridRowHeight = mdAndUp ? 52 : 100;
  const gridPageSize = 20;

  const [snackOpen, setSnackOpen] = useState(false);
  const [saveState, setSaveState] = useState(true);
  const [snackMessage, setSnackMessage] = useState(t("ILO Activty Created Successfully"));
  const snackbarOpen = () => setSnackOpen(true);
  const snackbarClose = () => setSnackOpen(false);
  const setSnackBox = (success: boolean, message?: string) => {
    setSnackMessage(message);
    setSaveState(success);
  };

  const [participantList, setParticipantList] = useState<Participant[]>(
    !activity
      ? []
      : teamMembers.map((mem) => {
          return {
            ...mem,
            isParticipant: !activity.participants
              ? false
              : activity.participants.findIndex((par) => par.user_id === mem.user_id) > -1,
          };
        })
  );
  const [selectAll, setSelectAll] = useState<boolean | null>(null);
  useEffect(() => {
    const pList = teamMembers.map((mem) => {
      return {
        ...mem,
        isParticipant:
          activity && activity.participants
            ? activity.participants.findIndex((p) => p.id === mem.user_id) > -1
            : false,
      };
    });
    setParticipantList(pList);
  }, []);

  useEffect(() => {
    if (selectAll != null)
      setParticipantList(teamMembers.map((mem) => ({ ...mem, isParticipant: selectAll })));
  }, [selectAll]);

  const CustomNoRowsOverlay = () => {
    return <GridOverlay className={classes.root}>{t("No records found.")}</GridOverlay>;
  };

  const [days, setDays] = useState([
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ]);

  const makeLocalTime = (timeString: string | null | undefined) => {
    if (!timeString) return;
    const timeParts = timeString.split(":");
    const hr = Number(timeParts[0]);
    const mn = timeParts[1];
    return `${hr}:${mn}`;
  };

  const [startTime, setStartTime] = useState<string>(
    makeLocalTime(activity?.start_time_local) || config.workday.defaultStartWorkingHour
  );
  const [finishTime, setFinishTime] = useState<string>(
    makeLocalTime(activity?.end_time_local) || config.workday.defaultFinishWorkingHour
  );

  const setTime = (value: string, target: string) => {
    switch (target) {
      case "start":
        setStartTime(value);
        break;
      case "finish":
        setFinishTime(value);
        break;
    }
  };

  const getActivityDays = (
    selectedDays: AnchorDays,
    occurs: string | number,
    freq: string
  ): ILOAnchorDays => {
    let newActivityDays: ILOAnchorDays = getBaseWeek(selectedDays);
    switch (`${occurs}`) {
      case `${IloOccurance.DAILY}`:
      case `${IloOccurance.WEEKLY}`:
        newActivityDays = {
          monday: selectedDays["monday"],
          tuesday: selectedDays["tuesday"],
          wednesday: selectedDays["wednesday"],
          thursday: selectedDays["thursday"],
          friday: selectedDays["friday"],
          saturday: selectedDays["saturday"],
          sunday: selectedDays["sunday"],
          monday_week2: selectedDays["monday"],
          tuesday_week2: selectedDays["tuesday"],
          wednesday_week2: selectedDays["wednesday"],
          thursday_week2: selectedDays["thursday"],
          friday_week2: selectedDays["friday"],
          saturday_week2: selectedDays["saturday"],
          sunday_week2: selectedDays["sunday"],
          twoWeekRoutine: false,
        };
        break;
      case `${IloOccurance.EVERY_TWO_WEEKS}`:
        newActivityDays =
          freq === "1"
            ? {
                monday: selectedDays["monday"],
                tuesday: selectedDays["tuesday"],
                wednesday: selectedDays["wednesday"],
                thursday: selectedDays["thursday"],
                friday: selectedDays["friday"],
                saturday: selectedDays["saturday"],
                sunday: selectedDays["sunday"],
                monday_week2: false,
                tuesday_week2: false,
                wednesday_week2: false,
                thursday_week2: false,
                friday_week2: false,
                saturday_week2: false,
                sunday_week2: false,
                twoWeekRoutine: false,
              }
            : {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
                monday_week2: selectedDays["monday"],
                tuesday_week2: selectedDays["tuesday"],
                wednesday_week2: selectedDays["wednesday"],
                thursday_week2: selectedDays["thursday"],
                friday_week2: selectedDays["friday"],
                saturday_week2: selectedDays["saturday"],
                sunday_week2: selectedDays["sunday"],
                twoWeekRoutine: true,
              };
        break;
    }
    return newActivityDays;
  };

  const [currentActivity, setCurrentActivity] = useState<Activity | null>(activity);

  const [activityName, setActivityName] = useState<string>(activity?.activity_name || "");
  const handleNameChange = (newName: any) => {
    setActivityName(newName.target.value);
  };

  const [activityOccurance, setActivityOccurance] = useState<number | string>(
    activity?.occurance || ""
  );
  const handleOccuranceChange = (newOccurance: any) => {
    setActivityOccurance(newOccurance.target.value);
    setFrequencySelectOpen(newOccurance.target.value === "3");
  };

  const [bosPractice, setBOSPractice] = useState<number | string>(activity?.bos_practice || "");
  const handleBOSPracticeChange = (newBOSPractice: any) => {
    setBOSPractice(newBOSPractice.target.value);
  };
  const [activityPurpose, setActivityPurpose] = useState<string>(activity?.purpose || "");
  const handlePurposeChange = (newPurpose: any) => {
    setActivityPurpose(newPurpose.target.value);
  };

  const [activityPreparation, setActivityPreparation] = useState<string>(
    activity?.preparation || ""
  );
  const handlePreparationChange = (newPreparation: any) => {
    setActivityPreparation(newPreparation.target.value);
  };

  const [activityPlace, setActivityPlace] = useState<number | string>(activity?.place || 0);
  const changeStatusOptionHandler = (event) => {
    setActivityPlace(event.target.value);
    if (event.target.value == 1) setOffice(true);
    else setOffice(false);
  };

  const [activityBuilding, setActivityBuilding] = useState<number | string>(
    activity && activity.building ? activity?.building : ""
  );
  const handleBuildingChange = (newBuilding: string) => {
    setActivityBuilding(parseInt(newBuilding));
  };

  const [activityLink, setActivityLink] = useState<string>(activity?.links || "");
  const handleLinkChange = (newLink: any) => {
    setActivityLink(newLink.target.value);
  };

  const handleInformationOpen = (infoId: number) => {
    openInformationModal(getModalProps(t, infoId));
  };

  const openInformationModal = (informationData: DialogData) => {
    setInformationModal(Object.assign({}, informationData));
    setInformationOpen(true);
  };

  const toggleParticipant = (userId: number) => {
    const newList = participantList.slice();
    const theIndex = newList.findIndex((mem) => mem.user_id === userId);
    if (theIndex !== -1) newList[theIndex].isParticipant = !newList[theIndex].isParticipant;
    setParticipantList(newList);
  };

  const gridColumns: GridColDef[] = [
    {
      field: "user_name",
      headerName: t("Name"),
      flex: 1,
      sortable: true,
      valueGetter: (params: any) => `${params?.row?.user_name}`,
      renderCell: (params: GridCellParams) => {
        return (
          <Typography style={{ fontWeight: 100, fontSize: "12px" }}>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                const teamInformationUrl = `/teams/information/${params.row.id}`;
                history.push(teamInformationUrl);
              }}
              underline="always"
              color="inherit"
            >
              {params.row.user_name}
            </Link>
          </Typography>
        );
      },
    },
    {
      field: "role_title",
      headerName: t("Role Title"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => params?.row?.role_title,
    },
    {
      field: "default_team",
      headerName: t("Default Team"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => params?.row?.default_team,
    },
    {
      field: "user_id",
      headerName: t("Add / Remove"),
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <>
            <strong style={{ color: bhpColor.blueGrey1 }}>{t("Add / Remove")}</strong>
            <Checkbox
              checked={selectAll || false}
              onClick={() => setSelectAll(selectAll ? !selectAll : true)}
            />
          </>
        );
      },
      hideSortIcons: true,
      flex: 1,
      valueGetter: (params) => params?.row?.user_id,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.isParticipant}
          onClick={() => toggleParticipant(params?.row?.user_id)}
        />
      ),
    },
  ];

  const gridColumnsMobile: GridColDef[] = [
    {
      field: "user_name",
      headerName: t("Name"),
      flex: 1.5,
      hideSortIcons: true,
      valueGetter: (params: any) => `${params?.row?.user_name}`,
      renderCell: (params: GridCellParams) => {
        return (
          <Typography style={{ fontWeight: 700 }}>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                const teamInformationUrl = `/teams/information/${params.row.id}`;
                history.push(teamInformationUrl);
              }}
              underline="always"
              color="inherit"
            >
              {params.row.user_name}
            </Link>
          </Typography>
        );
      },
    },
    {
      field: "user_id",
      headerName: "t(Add / Remove)",
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <Checkbox
            checked={selectAll || false}
            onClick={() => setSelectAll(selectAll ? !selectAll : true)}
          />
        );
      },
      hideSortIcons: true,
      flex: 1,
      valueGetter: (params) => params?.row?.user_id,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.isParticipant}
          onClick={() => toggleParticipant(params?.row?.user_id)}
        />
      ),
    },
  ];

  const getItemsFromEnum = (theEnum: any) => {
    return Object.entries(theEnum)
      .filter(([key, value]) => {
        return !isNaN(Number(key));
      })
      .map(([key, value]) => ({
        value: key,
        label: capitalizeString(
          `${typeof value === "string" ? value.replaceAll("_", " ") : value}`
        ),
      }));
  };

  const [frequencySelectOpen, setFrequencySelectOpen] = useState(
    !activity ? false : activity.days ? activity.days["twoWeekRoutine"] : false
  );
  const [activityFrequency, setActivityFrequency] = useState(
    !activity ? "1" : activity.days && activity.days["twoWeekRoutine"] ? "2" : "1"
  );
  const handleFrequencyChange = (event) => {
    setActivityFrequency(event.target.value);
  };

  const emptyWeek = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };

  const [activityDays, setActivityDays] = useState<AnchorDays>(
    !activity
      ? emptyWeek
      : activity.days
      ? {
          monday: activity.days["monday"] || activity.days["monday_week2"] || false,
          tuesday: activity.days["tuesday"] || activity.days["tuesday_week2"] || false,
          wednesday: activity.days["wednesday"] || activity.days["wednesday_week2"] || false,
          thursday: activity.days["thursday"] || activity.days["thurday_week2"] || false,
          friday: activity.days["friday"] || activity.days["friday_week2"] || false,
          saturday: activity.days["saturday"] || activity.days["saturday_week2"] || false,
          sunday: activity.days["sunday"] || activity.days["sunday_week2"] || false,
        }
      : emptyWeek
  );

  const getBaseWeek = (theDays: ILOAnchorDays) => {
    return {
      monday: theDays["monday"] || theDays["monday_week2"] || false,
      tuesday: theDays["tuesday"] || theDays["tuesday_week2"] || false,
      wednesday: theDays["wednesday"] || theDays["wednesday_week2"] || false,
      thursday: theDays["thursday"] || theDays["thurday_week2"] || false,
      friday: theDays["friday"] || theDays["friday_week2"] || false,
      saturday: theDays["saturday"] || theDays["saturday_week2"] || false,
      sunday: theDays["sunday"] || theDays["sunday_week2"] || false,
    };
  };

  const changeDays = (wd: string) => {
    let newActivityDays: AnchorDays = {
      monday: wd === "monday" ? !activityDays[wd] : activityDays["monday"],
      tuesday: wd === "tuesday" ? !activityDays[wd] : activityDays["tuesday"],
      wednesday: wd === "wednesday" ? !activityDays[wd] : activityDays["wednesday"],
      thursday: wd === "thursday" ? !activityDays[wd] : activityDays["thursday"],
      friday: wd === "friday" ? !activityDays[wd] : activityDays["friday"],
      saturday: wd === "saturday" ? !activityDays[wd] : activityDays["saturday"],
      sunday: wd === "sunday" ? !activityDays[wd] : activityDays["sunday"],
    };
    setActivityDays(newActivityDays);
  };

  const isWeek2 = (evt: Activity) => {
    return evt.days ? evt.days["week2Routine"] : false;
  };

  useEffect(() => {
    if (onChange) {
      const iloInformation: Activity = {
        id: activity?.id || 0,
        activity_name: activityName || "",
        occurance: activityOccurance! as number,
        bos_practice: bosPractice! as number,
        start_time_local: startTime || "",
        end_time_local: finishTime || "",
        place: activityPlace! as number,
        building: activityBuilding !== "" ? (activityBuilding as number) : null,
        links: activityLink || "",
        purpose: activityPurpose || "",
        preparation: activityPreparation || "",
        participants: participantList.filter((mem) => mem.isParticipant) || [],
        team: teamId,
        days: getActivityDays(activityDays, activityOccurance, activityFrequency),
      };
      onChange(iloInformation);
    }
  }, [
    activityName,
    activityOccurance,
    bosPractice,
    startTime,
    finishTime,
    activityPlace,
    activityBuilding,
    activityLink,
    activityPurpose,
    activityPreparation,
    participantList,
    activityDays,
    activityFrequency,
  ]);

  const getModalProps = (t: any, infoId: number) => {
    let title = "";
    let content = "";
    let primaryButtonText = t("Okay");
    let secondaryButtonText = "";
    let handleClose: any = handleInformationClose;

    switch (infoId) {
      case 1:
        title = t("ILO Routine");
        content = t("ILO routines based on week and time");
        break;
      case 2:
        title = t("Activity Name");
        content = t("Name of the ILO activity");
        break;
      case 3:
        title = t("Occurrence");
        content = t("Occurrence details of the ILO activity");

        break;
      case 4:
        title = t("Day");
        content = t("Days of occurrence of this activity");
        break;
      case 5:
        title = t("Start Time");
        content = t("Starting time of this ILO activity");

        break;
      case 6:
        title = t("End Time");
        content = t("Ending time of this ILO activity");
        break;
      case 7:
        title = t("BOS Practice");
        content = t("List BOS practice for this activity");
        break;
      case 8:
        title = t("Purpose");
        content = t("What is the purpose of this ILO activity");
        break;
      case 9:
        title = t("Participants");
        content = t(
          "These names are drawn from the Team Members. To include a participant select the box next to their name."
        );
        break;
      case 10:
        title = t("Preparation");
        content = t(
          "Include detail of any preparation attendees should undertake... (max 25 words)"
        );
        break;
      case 11:
        title = t("Place");
        content = t("Place in which ILO activity is happening");
        break;
      case 12:
        title = t("Links");
        content = t("URL's related to the ILO activity");
        break;
    }
    return { title, content, primaryButtonText, secondaryButtonText, handleClose };
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid item sm={6} md={6}>
            {subHeadAndInfo("Name", 2)}
            <TextField
              key="aName"
              id="activityName"
              placeholder={t("Enter activity name")}
              type="text"
              size="small"
              variant="outlined"
              style={{ width: "100%" }}
              fullWidth
              className={classes.textField}
              onChange={handleNameChange}
              onBlur={handleNameChange}
              //onKeyUp={handleNameChange}
              value={activityName}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ display: "flex", alignItems: "end" }}>
          <Grid item sm={6} md={6}>
            {subHeadAndInfo("Occurrence", 3)}
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                native
                className={classes.selectBoxOcc}
                onChange={handleOccuranceChange}
                onBlur={handleOccuranceChange}
                value={activityOccurance}
              >
                <option key={`sc1`} value="">
                  {t(`Select Occurrence`)}
                </option>
                {getItemsFromEnum(IloOccurance).map((itm, key) => {
                  return (
                    <option key={key} value={itm.value}>
                      {itm.label}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: "14px" }}>
            {frequencySelectOpen && (
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  native
                  className={classes.selectBoxOcc}
                  onChange={handleFrequencyChange}
                  value={activityFrequency}
                >
                  <option key={`fr1`} value="1">
                    {t(`Week`)} 1
                  </option>
                  <option key={`fr2`} value="2">
                    {t(`Week`)} 2
                  </option>
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item sm={6} md={6}>
            {subHeadAndInfo(t("Days"), 4)}
            <Grid container>
              {weekDayNamesSorter(Object.keys(activityDays)).map((wd: string, index) => {
                if (index < 7)
                  return (
                    <Grid item sm={6} md={6}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <Checkbox
                          checkedIcon={<CheckBoxOutlined />}
                          checked={activityDays[wd]}
                          onChange={() => changeDays(wd)}
                          key={wd}
                          className={`${activityDays[wd] === true ? "selected" : ""}`}
                        />
                        {mdAndUp
                          ? capitalizeFirstLetter(wd)
                          : capitalizeFirstLetter(wd).substring(0, 3)}
                      </FormControl>
                    </Grid>
                  );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12}>
              {subHeadAndInfo(t("Start Time"), 5)}
            </Grid>
            <Grid item xs={12}>
              <Select
                id="startTime"
                value={startTime}
                style={{ height: 40, fontSize: 13 }}
                autoWidth
                variant="outlined"
                onChange={(event: any) => setTime(event.target.value, "start")}
              >
                {getTimes(15).map((time, idx) => {
                  return (
                    <MenuItem value={time.value} key={`StartTime${idx}`}>
                      {time.key}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12}>
              {subHeadAndInfo(t("End Time"), 6)}
            </Grid>
            <Grid item xs={12}>
              <Select
                id="finishTime"
                value={finishTime}
                variant="outlined"
                style={{ height: 40, fontSize: 13 }}
                autoWidth
                onChange={(event: any) => setTime(event.target.value, "finish")}
              >
                {getTimes(15).map((time, idx) => {
                  return (
                    <MenuItem value={time.value} key={`FinishTime${idx}`}>
                      {time.key}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12} md={12}>
          <Grid item xs={6}>
            {subHeadAndInfo(t("BOS Principal"), 7)}
            <Select
              variant="outlined"
              native
              fullWidth
              className={classes.selectBoxOcc}
              onChange={handleBOSPracticeChange}
              value={bosPractice}
            >
              <option key={`sbp1`} value="">
                {t(`Select BOS Practice`)}
              </option>
              {getItemsFromEnum(IloBOSPractice).map((itm, key) => {
                return (
                  <option key={key} value={itm.value}>
                    {itm.label}
                  </option>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <Grid item sm={12} md={12}>
          {subHeadAndInfo(t("Purpose"), 8)}
          <TextField
            id="activityPurpose"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 250 }}
            multiline
            rows={textAreaRows}
            className={classes.textField}
            style={{ width: "100%" }}
            placeholder={
              t(`Describe the purpose of the event you are creating... (max `) +
              `${textAreaMaxCharacters}` +
              t(` characters)`)
            }
            onChange={handlePurposeChange}
            value={activityPurpose || ""}
          />
        </Grid>
        <Grid item sm={12} md={12}>
          {subHeadAndInfo(t("Participants*"), 9)}
          <Box className={classes.outer} my={{ xs: 1, md: 1 }}>
            <Grid className={classes.dataGrid} container>
              <Grid item xs>
                <div
                  className={classes.spaceTable}
                  style={{
                    height:
                      teamMembers && teamMembers?.length > 0
                        ? Math.min(teamMembers?.length + 1, gridPageSize) * gridRowHeight + 100
                        : 200,
                  }}
                >
                  <DataGrid
                    rows={participantList}
                    rowCount={participantList?.length || undefined}
                    rowHeight={gridRowHeight}
                    columns={mdAndUp ? gridColumns : gridColumnsMobile}
                    disableSelectionOnClick
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                      ColumnSortedAscendingIcon: ExpandMore,
                      ColumnSortedDescendingIcon: ExpandLess,
                      ColumnUnsortedIcon: UnfoldMoreIcon,
                    }}
                    pageSize={gridPageSize}
                    rowsPerPageOptions={[gridPageSize]}
                    disableColumnMenu
                    hideFooterRowCount
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item sm={12} md={12}>
          {subHeadAndInfo(t("Preparation"), 10)}
          <TextField
            id="activityPreparation"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 250 }}
            multiline
            rows={textAreaRows}
            className={classes.textField}
            style={{ width: "100%" }}
            placeholder={
              t(`Include details of any preparation attendees should undertake... (max `) +
              ` ${textAreaMaxCharacters}` +
              t(` characters)`)
            }
            onChange={handlePreparationChange}
            value={activityPreparation || ""}
          />
        </Grid>
        <Grid item sm={12} md={12}>
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: "4px" }}>
              {subHeadAndInfo("Place", 11)}
              <Select
                variant="outlined"
                native
                className={classes.selectBoxOcc}
                onChange={changeStatusOptionHandler}
                value={activityPlace}
              >
                <option key={`sp1`} value="">
                  {t(`Select Place`)}
                </option>
                {getItemsFromEnum(IloPlace).map((itm, key) => {
                  return (
                    <option key={key} value={itm.value}>
                      {itm.label.replace("Bhp", "BHP")}
                    </option>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: "4px" }}>
              {showOffice && (
                <Grid item sm={12} md={12}>
                  {subHeadAndInfo("Buildings", 11)}
                  <SettingsSelectBox
                    t={t}
                    phText={`${t("Choose your building")}...`}
                    phValue={-1}
                    theItems={store.buildingStore.buildings
                      .slice()
                      .sort((a, b) => (a.city < b.city ? -1 : 1))}
                    labelField={"display_name"}
                    valueField={"id"}
                    defaultValue={activityBuilding}
                    fontSize={"13px"}
                    width={"100%"}
                    handleChange={handleBuildingChange}
                    theId={"activityBuilding"}
                    theKey={"locationKey"}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={12}>
          {subHeadAndInfo(t("Link"), 12)}
          <TextField
            id="activityLink"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 250 }}
            multiline
            rows={textAreaRows}
            className={classes.textField}
            style={{ width: "100%" }}
            placeholder={t(`Add URLs`) + t(` - https://...`)}
            onChange={handleLinkChange}
            value={activityLink || ""}
          />
        </Grid>
      </Grid>
      <PopupInformationWidget
        t={t}
        isOpen={informationOpen}
        handleClose={informationModal?.handleClose || handleInformationClose}
        handleSecondaryClick={handleInformationClose}
        title={informationModal?.title || null}
        content={informationModal?.content || null}
        buttonText={informationModal?.primaryButtonText || null}
        secondaryButtonText={informationModal?.secondaryButtonText || null}
      />
    </>
  );
});

export default TeamIloAddActivity;
