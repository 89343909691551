import { observer } from "mobx-react-lite";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { useScheduleStore } from "providers/RootStoreProvider";
import { Schedule } from "interfaces/Schedule";
import { useTranslation } from "react-i18next";
import client from "services/api";
import { BuildingType } from "interfaces/Building";

interface TomorrowNearCapacityProps {
  schedule: Schedule;
}

const TomorrowNearCapacity = observer((props: TomorrowNearCapacityProps) => {
  const schedule = props.schedule;
  const scheduleStore = useScheduleStore();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [capacity, setCapacity] = useState({
    planned_attendance: 0,
    total_capacity: -1,
    booked_attendance: 0,
  });

  const [show, setShow] = useState(false);
  const [percentCapacity, setPercentCapacity] = useState(0);

  useEffect(() => {
    (async () => {
      if (scheduleStore.noDeskBookingToday === false)
        if (schedule && schedule.office && schedule.office.building_type === BuildingType.OFFICE) {
          const res = await client.get(
            `/api/building/buildings/date/${moment(schedule.date).format(
              "YYYY-MM-DD"
            )}/capacity/?id=${schedule.office.id}`
          );
          const data = res.data.results[0];
          setCapacity(data);
        }
    })();
  }, [scheduleStore.noDeskBookingToday, schedule]);

  useEffect(() => {
    setPercentCapacity(Math.ceil((capacity.booked_attendance / capacity.total_capacity) * 100));
  }, [capacity]);

  useEffect(() => {
    if (percentCapacity > 90) {
      setShow(true);
    }
  }, [percentCapacity]);

  const bookSpaceRequired = () =>
    history.push(`/booking/${moment(schedule.date).format("YYYY-MM-DD")}/${schedule!.office!.id}`);

  const changePlanner = () => setShow(false);

  return (
    <>
      {show && (
        <ConfirmDialog
          isOpen={true}
          title={t("Tomorrow is near capacity")}
          primaryButtonText={t("Book a desk")}
          secondaryButtonText={t("No thanks")}
          handleSecondaryClick={changePlanner}
          handlePrimaryClick={bookSpaceRequired}
        >
          {t("Looks like tomorrow is close to being full")}{" "}
          <b>
            {"("}
            {Math.ceil((capacity.booked_attendance / capacity.total_capacity) * 100)}
            {"%)"}
          </b>{" "}
          {". Do you need a desk?"}
        </ConfirmDialog>
      )}
    </>
  );
});

export default TomorrowNearCapacity;
