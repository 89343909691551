import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Slide,
} from "@material-ui/core";
import { Space } from "interfaces/Building";
import { bhpColor, settings } from "styles/globals";
import HeaderImage from "assets/headerImage.png";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useRef, useState } from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import { observer } from "mobx-react-lite";
import { useRootStore } from "providers/RootStoreProvider";
import { useTranslation } from "react-i18next";
import { getBuildingBookingSpecs } from "utils/hourlyBookings";
import {
  colorShift,
  correctCoordinates,
  drawExtras,
  drawKey,
  drawSpot,
  getBoxPosition,
  getColorRGB,
  getScales,
  Scale,
  scaledX,
  scaledY,
} from "../Floorplan/Drawing";
import { deskTypeIds, getSpaceTypeDetail } from "./OtherTypes";
import { spot_available, tolerance } from "../Floorplan/Constants";
import moment from "moment";
import CanvasWrapper from "components/utils/CanvasWrapper";
import { isFavourite } from "../FavouriteContextMenu";
import { type } from "os";
import { toJS } from "mobx";

interface EnhancedSpacesProps {
  floorId?: number | null;
  floorSpaces?: Space[];
  date: string;
  buildingId?: number;
  passBack?: any;
  refresh?: any;
  hiddenTypeIds: number[];
}

const useStyles = makeStyles((theme) => ({
  inlineIcon: {
    display: "inline-flex",
    alignItems: "center",
    color: bhpColor.orange1,
    fontWeight: "bold",
    paddingRight: 4,
    cursor: "pointer",
  },
  timeSelector: {
    padding: 0,
    "& .MuiSelect-root": {
      padding: "4px",
      width: "80px",
      fontSize: "14px",
    },
    "& .MuiSelect-iconOutlined": {
      right: 0,
    },
  },
  cancelButton: {
    "&:hover": {
      backgroundColor: `${bhpColor.orange4}50`,
    },
  },
  dialogTitle: {
    backgroundImage: `url(${HeaderImage})`,
    color: bhpColor.white,
    "& h2": {
      fontWeight: "bold",
      "& svg": {
        verticalAlign: "text-top",
      },
    },
  },
  content: {
    marginBottom: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EnhancedSpaces = observer((props: EnhancedSpacesProps) => {
  const { buildingId, floorSpaces, floorId, date, passBack, hiddenTypeIds = [] } = props;
  const store = useRootStore();
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const propsFloorId: string | null = floorId ? `${floorId}` : null;

  store.bookingStore.setDirtyFloor();
  if (propsFloorId && `${propsFloorId}` !== `${store.buildingStore.currentFloorId}`) {
    store.buildingStore.setCurrentFloor(Number(propsFloorId));
  }

  const [hasLegacyFloorplan, setHasLegacyFloorplan] = useState(
    store.buildingStore.currentFloor && store.buildingStore.currentFloor.floor_plan ? true : false
  );

  const getFloorImageModule = (floorId: string | number | null | undefined) => {
    if (!floorId) return null;
    let module;
    try {
      module = require(`assets/floorplans/sv_${floorId}.png`);
    } catch {
      return null;
    }
    return module;
  };

  const [floorImage, setFloorImage] = useState<any>(
    store.buildingStore.currentFloor &&
      store.buildingStore.currentFloor.floor_plan &&
      `${store.buildingStore.currentFloor.floor_plan}` !== ""
      ? store.buildingStore.currentFloor.floor_plan
      : propsFloorId
      ? getFloorImageModule(propsFloorId)
      : null
  );

  const [buildingBookingSpecs, setBuildingBookingspecs] = useState(
    getBuildingBookingSpecs(store.buildingStore)
  );
  const theBuilding = store.buildingStore.getBuilding(buildingId!);
  const localClick = useRef(false);

  const canvasWidth = 1024;
  const [canvasHeight, setCanvasHeight] = useState(1024);
  const [yRatio, setYRatio] = useState(1);

  const onImgLoad = (img: any) => {
    const imgSource = img.target;
    const pixelWidth = imgSource.offsetWidth;
    const pixelHeight = imgSource.offsetHeight;
    const yRatio = pixelHeight / pixelWidth;
    setCanvasHeight(pixelHeight);
    setYRatio(yRatio);
  };

  const canvasRef = useRef(null);

  const floorplanSpecs = store.buildingStore.getHardCodedFloorplanSpecs(buildingId);

  const theScales = getScales(`${store.buildingStore.currentFloor?.id}`, floorplanSpecs);

  const spaceRadius = theScales.spaceRadius || 10;

  const [context, setContext] = useState();
  const theContext = (ctx: any) => setContext(ctx);

  const pointIsNear = (
    spaceX: number,
    spaceY: number,
    clickX: number,
    clickY: number,
    theScales: { scale: { x: number; y: number }; offset: { x: number; y: number } } | null
  ) => {
    const correctedX: number = theScales ? Math.round(scaledX(spaceX, theScales)) : spaceX;
    const correctedY: number = theScales ? Math.round(scaledY(spaceY, theScales)) : spaceY;
    const boundingBox: { tl: { x: number; y: number }; br: { x: number; y: number } } = {
      tl: { x: correctedX - spaceRadius, y: correctedY - spaceRadius },
      br: { x: correctedX + spaceRadius, y: correctedY + spaceRadius },
    };
    return (
      clickX > boundingBox.tl.x &&
      clickX < boundingBox.br.x &&
      clickY > boundingBox.tl.y &&
      clickY < boundingBox.br.y
    );
  };

  const [didClick, setDidClick] = useState(false);
  const [clickPosition, setClickPosition] = useState<{ x: number; y: number }>({ x: -1000, y: 0 });

  const findSpaceNearPoint = (x: number, y: number, spaces: Space[], scales: Scale) => {
    if (spaces && x && y) {
      const theSpace = spaces.find((spc) => {
        const correctedPoint = getPoint(spc, scales);
        return correctedPoint ? pointIsNear(correctedPoint.x, correctedPoint.y, x, y, null) : null;
      });
      return theSpace;
    }
    return null;
  };

  const mouseClick = (evt: any) => {
    const clickOffsetX = evt.nativeEvent.offsetX ? evt.nativeEvent.offsetX : evt.offsetX;
    const clickOffsetY = evt.nativeEvent.offsetY ? evt.nativeEvent.offsetY : evt.offsetY;
    console.log("es click: ", evt);
    if (passBack) passBack({ x: clickOffsetX, y: clickOffsetY }, null /*foundSpace*/);
    if (clickOffsetX && clickOffsetY) {
      setDidClick(true);
      const floorToUse = `${store.buildingStore.currentFloorId}`;
      const theFloorSpaces = store.buildingStore.floorSpaces[floorToUse];
      const floorScales = getScales(floorToUse, floorplanSpecs);
      const foundSpace = findSpaceNearPoint(
        clickOffsetX,
        clickOffsetY,
        theFloorSpaces,
        floorScales
      );
      if (foundSpace) {
        const clickPos = getPoint(foundSpace, floorScales);
        if (clickPos) {
          const boxPosition = getBoxPosition(clickPos, foundSpace, infoBoxRef, box, canvasWidth);
          setClickPosition({ x: boxPosition.x, y: boxPosition.y });
          setClickedSpace(foundSpace);
        }
      } else {
        closeInfoBox();
      }
    }
  };

  /**
   * if has local points returns the calibrated coord
   * otherwise scales off the sv json
   * @param theSpace
   * @param floorScales
   * @returns {x,y}
   */
  const getPoint = (theSpace: Space, floorScales: Scale): { x: number; y: number } => {
    const pointToReturn =
      theSpace.corrected_coordinates_x && theSpace.corrected_coordinates_y
        ? { x: theSpace.corrected_coordinates_x, y: theSpace.corrected_coordinates_y }
        : correctCoordinates({ ...theSpace.coordinates, id: theSpace.id }, floorScales);
    return pointToReturn;
  };

  const infoBoxRef = useRef(null);

  /**
   * set hotSpotPosition to coordinates of space if mouseOver
   * or null if not on a spot
   * @param mouseX - evt.offsetX
   * @param mouseY - evt.offsetY
   * @param ctx - canvas context
   */
  const checkMouseLocation = (mouseX: number, mouseY: number, ctx: any) => {
    if (store.buildingStore.currentFloorId) {
      const floorToUse = `${store.buildingStore.currentFloorId}`;
      const theFloorSpaces = store.buildingStore.floorSpaces[floorToUse].filter((spt) =>
        deskTypeIds.includes(spt.space_type?.id || 0)
      );
      const floorScales = getScales(floorToUse, floorplanSpecs);
      const foundSpace = findSpaceNearPoint(mouseX, mouseY, theFloorSpaces, floorScales);
      if (foundSpace) {
        const labelPos = getPoint(foundSpace, floorScales);
        if (labelPos) {
          ctx.canvas.style.cursor = "pointer";
        }
      } else {
        ctx.canvas.style.cursor = "default";
      }
    }
  };

  const draw = (ctx: any) => {
    if (propsFloorId) {
      let img = new Image();
      img.src = propsFloorId && floorImage;
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);

      const imageData = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
      const shiftedImage = colorShift(imageData, tolerance.black, true);
      if (clickedSpace && didClick) {
        const clickedImage = colorShift(shiftedImage, tolerance.white, false);
        ctx.putImageData(clickedImage, 0, 0);
      } else {
        ctx.putImageData(shiftedImage, 0, 0);
      }

      // drop a tell-tale so we can see it is using local points
      // 2px dot bottom left of floorplan image
      if (hasLocalPoints) {
        const pointHeight = 2;
        ctx.fillStyle = bhpColor.purple4;
        ctx.fillRect(0, canvasHeight - pointHeight, pointHeight, pointHeight);
      }

      const theScales = getScales(propsFloorId, floorplanSpecs);
      const hotSpotColor = spot_available;

      if (!store.buildingStore.isLoadingSpaces) {
        if (store.buildingStore.floorSpaces && store.buildingStore.floorSpaces[propsFloorId]) {
          store.buildingStore.floorSpaces[propsFloorId].map((spc, idx) => {
            if (spc.coordinates || (spc.corrected_coordinates_x && spc.corrected_coordinates_y)) {
              if (!hiddenTypeIds.includes(spc.space_type?.id || 0)) {
                const correctedPoint = getPoint(spc, theScales);
                const theColor = spot_available;
                const outerColor = null;
                drawSpot(
                  ctx,
                  { ...correctedPoint, id: spc.id },
                  getColorRGB(theColor),
                  clickedSpace,
                  outerColor,
                  spaceRadius,
                  false,
                  false,
                  spc.space_type?.id
                );
              }
            }
          });
        }
        setIsDrawn(true);
      }
    }
  };

  /**
   * getSpaceProperties
   * some spaces have a list of tags describing the space,
   * e.g "Desk -Webcam, Desk - Large monitor"
   * others just have a space.type.name e.g"Quiet Corral"
   * this function jets rid of Desk - from the tag and sorts
   * based on a list we were given
   * @param spc - the space
   * @returns sorted and tweaked .tags[] or space.type.name
   * if not tags exist
   */
  const getSpaceProperties = (spc: Space) => {
    const deskSortOrder = [
      "Anchor - OHS",
      "Anchor - Technology",
      "Desk - Fixed Height",
      "Desk - Sit/Stand",
      "Desk - Ergotron Sit/Stand",
      "Desk - Webcam",
      "Desk - Dockless Monitors",
      "Desk - Dockless Monitor",
      "Desk - Large Monitor",
      "Desk - P50 Dock",
      "Desk - HP Technical Dock",
      "Desk - Thunderbolt Dock",
      "Desk - Non-Standard Workstation",
      "Desk Non-Standard Workstation",
    ];

    const sortDeskTag = (a: any, b: any) =>
      deskSortOrder.indexOf(a) > deskSortOrder.indexOf(b) ? 1 : -1;

    const rawTags = spc.tags;
    let tags: string = "";
    if (rawTags) {
      const re = /Desk - /gi;
      tags = rawTags
        .slice()
        .sort((a: string, b: string) => sortDeskTag(a, b))
        .map((tg: string) => t(tg.replace(re, "")))
        .join(", ");
    }
    return tags !== "" ? tags : spc.space_type?.name || "";
  };

  //  const currentBuildingId = store.buildingStore.currentBuilding?.id;
  const theDate = date ? date : moment(new Date()).format("YYYY-MM-DD");

  const [clickedSpace, setClickedSpace] = useState<Space | null>(null);
  const [isDrawn, setIsDrawn] = useState(true);
  const [isDrawing, setIsDrawing] = useState(false);

  const [hasLocalPoints, setHasLocalPoints] = useState(false);

  useEffect(() => {
    setCanvasHeight(canvasWidth * yRatio);
  }, [yRatio]);

  useEffect(() => {
    if (store.buildingStore.currentFloor) {
      // provide some feeback via console if things aren't right
      const theFloorSpaces = store.buildingStore.floorSpaces
        ? store.buildingStore.floorSpaces[store.buildingStore.currentFloor.id]
        : [];
      const hasLocalCoordinates =
        theFloorSpaces && theFloorSpaces.length > 0
          ? theFloorSpaces[0].corrected_coordinates_x
          : false;

      const hasBackendImage =
        store.buildingStore.currentFloor?.floor_plan &&
        store.buildingStore.currentFloor?.floor_plan !== ""
          ? true
          : false;
      if (!hasBackendImage)
        console.log(`Floor ${store.buildingStore.currentFloor.id}: Legacy Floorplan Image`);
      if (!hasLocalCoordinates) {
        setHasLocalPoints(false);
      } else setHasLocalPoints(true);

      const floorImageUrl = hasBackendImage
        ? store.buildingStore.currentFloor?.floor_plan
        : getFloorImageModule(store.buildingStore.currentFloor.id);
      setFloorImage(floorImageUrl);
    }
  }, [store.buildingStore.currentFloor]);

  useEffect(() => {
    if (buildingId && !store.buildingStore.isLoadingSpaces && propsFloorId) {
      store.buildingStore.loadSpaces(buildingId, Number(propsFloorId), theDate, true).then(() => {
        const floorSpaces =
          store.buildingStore.currentFloor &&
          store.buildingStore.floorSpaces &&
          store.buildingStore.floorSpaces[store.buildingStore.currentFloor.id];
        const hasLocalPoints =
          floorSpaces && floorSpaces.length > 0
            ? floorSpaces[0].coordinates.x
              ? true
              : false
            : false;
        setHasLocalPoints(hasLocalPoints);
      });
    }
  }, []);

  useEffect(() => {
    if (context && !store.buildingStore.isLoadingFloorPlan && !isDrawing && !isDrawn) {
      setIsDrawing(true);
      draw(context);
    }
  }, [store.buildingStore.isLoadingSpaces, clickPosition, clickedSpace, isDrawn]);

  useEffect(() => {
    closeInfoBox();
  }, [store.buildingStore.currentFloorId]);

  const box = { height: 104, width: 300, buttonAllowance: 35 };
  const closeInfoBox = () => {
    setClickedSpace(null);
    setClickPosition({ x: -1000, y: 0 });
  };

  const infoBox = () => {
    const spc = clickedSpace;
    if (spc) {
      return (
        <div
          ref={infoBoxRef}
          style={{
            position: "absolute",
            top: clickPosition.y - box.height,
            left: clickPosition.x,
            border: "2px solid black",
            borderRadius: `4px`,
            backgroundColor: "white",
            minHeight: box.height,
            width: box.width,
            whiteSpace: "pre-line",
            fontSize: "15px",
            padding: 10,
            marginBottom: box.height * -1,
            zIndex: "15",
          }}
        >
          <Grid container>
            <Grid item xs={11}>
              <div style={{ marginBottom: 8, fontWeight: 700 }}>{spc?.name || "A.0.000"}</div>
            </Grid>
            <Grid item xs={1}>
              <Box>
                {" "}
                <IconButton
                  aria-label="close"
                  style={{
                    position: "absolute",
                    right: 2,
                    top: -2,
                    color: bhpColor.grey2,
                  }}
                  onClick={closeInfoBox}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </div>
      );
    }
  };

  //#region Step 1 - Obtain enhanced spaces
  useEffect(() => {
    if (buildingId && !store.buildingStore.isLoadingSpaces && propsFloorId) {
      store.buildingStore.loadSpaces(buildingId, Number(propsFloorId), theDate, true);
    }
  }, []);
  //#endregion

  useEffect(() => {
    if (context && !store.buildingStore.isLoadingFloorPlan && !isDrawing && !isDrawn) {
      setIsDrawing(true);
      draw(context);
    }
  }, [store.buildingStore.isLoadingSpaces, clickPosition, clickedSpace, isDrawn]);

  useEffect(() => {
    closeInfoBox();
  }, [store.buildingStore.currentFloorId, store.bookingStore.dirtyFloor]);

  const [showSpaceOpen, setShowSpaceOpen] = useState(false);

  const specificItem = (evt: any, spc: Space) => {
    evt.stopPropagation();
    if (passBack) passBack({ x: spc.corrected_coordinates_x, y: spc.corrected_coordinates_y }, spc);
  };

  return (
    <Box
      pb={{ xs: 2, md: 4 }}
      pt={{ xs: 0, md: 2 }}
      px={{ xs: 0, md: 0 }}
      style={{ display: "block", position: "absolute" }}
      onClick={(e) => mouseClick(e)}
    >
      {propsFloorId && floorSpaces
        ? drawExtras(
            floorSpaces.filter((spc) => !hiddenTypeIds.includes(spc.space_type?.id || 0)),
            specificItem
          )
        : undefined}
      {/* {drawKey(false, { start: new Date(), end: new Date() }, 0, undefined)} */}
      {!floorImage ? (
        <Box>Floorplan image ({propsFloorId}) not found</Box>
      ) : (
        <>
          <CanvasWrapper
            setcontext={theContext}
            mouseclick={undefined /*mouseClick*/}
            draw={draw}
            height={canvasHeight}
            width={canvasWidth}
            mousemove={undefined /*checkMouseLocation*/}
          />
          {propsFloorId &&
            store.buildingStore.currentBuilding?.floors?.find(
              (flr) => `${flr.id}` === `${propsFloorId}`
            ) && (
              <img
                src={propsFloorId && floorImage}
                width={canvasWidth}
                onLoad={onImgLoad}
                style={{ position: "absolute", left: -5000 }}
              />
            )}
        </>
      )}
      {infoBox()}
      <Dialog TransitionComponent={Transition} open={showSpaceOpen}>
        <DialogTitle className={classes.dialogTitle}>Information about this point</DialogTitle>
        <DialogContent>Something</DialogContent>
        <DialogActions style={{ justifyContent: "end", padding: settings.outerPadding }}>
          <Button
            onClick={() => setShowSpaceOpen(false)}
            variant="text"
            className={classes.cancelButton}
            disableElevation
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default EnhancedSpaces;
