import { CalibrationDetail, floorplanSpecs } from "interfaces/Building";
import { toJS } from "mobx";
import RootStore from "stores/RootStore";

export const correctEmptyCalibrations = (store: RootStore) => {
  store.buildingStore.buildingsWithCapacity.forEach((bld) => {
    const bldSpecs = floorplanSpecs.find((fps) => fps.buildingId === bld.id);
    const hardCodedBuildingSpecs = bldSpecs ? bldSpecs.floorplanSpecs : null;
    const coreBuildingSpecs = { ...hardCodedBuildingSpecs };
    if (coreBuildingSpecs.floor_specific) delete coreBuildingSpecs.floor_specific;
    if (
      JSON.stringify(bld.calibration_json).length === 2 &&
      JSON.stringify(coreBuildingSpecs).length > 2
    ) {
      store.buildingStore.updateBuildingCalibration(bld.id, coreBuildingSpecs as CalibrationDetail);
    }
    if (hardCodedBuildingSpecs && bld.floors) {
      bld.floors.forEach((flr) => {
        const hardCodedFloorspecs =
          hardCodedBuildingSpecs.floor_specific &&
          hardCodedBuildingSpecs.floor_specific[`${flr.id}`]
            ? hardCodedBuildingSpecs.floor_specific[`${flr.id}`]
            : coreBuildingSpecs;
        const calibration_json = flr.calibration_json;
        if (
          (!calibration_json || JSON.stringify(calibration_json).length < 10) &&
          hardCodedFloorspecs
        ) {
          // fix this one
          store.buildingStore.updateFloorCalibration(
            flr.id,
            hardCodedFloorspecs as CalibrationDetail
          );
        }
      });
    }
  });
};
