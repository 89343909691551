import { observer } from "mobx-react-lite";
import { useNotificationStore } from "providers/RootStoreProvider";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Topbar from "./Topbar";
import { Card, Box, useTheme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import DOMPurify from "dompurify";
import moment from "moment";
import { isThisMonth, isToday } from "utils/date";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  shape: {
    backgroundColor: theme.palette.secondary.main,
    width: 14,
    height: 14,
  },
  shapeCircle: {
    borderRadius: "50%",
  },
}));

const Notifications = observer(() => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const notificationStore = useNotificationStore();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const circle = <div className={clsx(classes.shape, classes.shapeCircle)} />;
  const lang = i18n.language;
  useEffect(() => {
    notificationStore.getNotifications();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timer = window.setTimeout(() => {
      notificationStore.markNotificationsAsRead();
    }, 5000);
    return () => {
      // Return callback to run on unmount.
      window.clearInterval(timer);
    };
  }, [notificationStore.notifications]);

  return (
    <>
      <Topbar
        title={t("Notifications")}
        subtitle={t("Recent alerts regarding your teams and default office")}
        breadcrumb={t("Notifications")}
      />
      <Box py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }}>
        {notificationStore.isLoading ? (
          <p>{t("Loading...")}</p>
        ) : (
          <>
            {notificationStore.notifications
              .slice()
              .sort((a, b) => b.id - a.id)
              .map((item) => {
                const today = isToday(new Date(item.created_at));
                const currentMonth = isThisMonth(new Date(item.created_at));
                return (
                  <Box mb={2} key={item.id}>
                    <Card
                      variant="outlined"
                      style={{ backgroundColor: item.read ? "#D7DFE24D" : "white" }}
                    >
                      <Box py={2} px={2} display="flex" alignItems="center" flexDirection="row">
                        {item.read ? (
                          <></>
                        ) : (
                          <Box display="flex" mr={3}>
                            {circle}
                          </Box>
                        )}
                        {today ? (
                          <></>
                        ) : currentMonth ? (
                          <Box display="flex" mr={1}>
                            {moment(item.created_at).format("ddd D,")}
                          </Box>
                        ) : (
                          <Box display="flex" mr={1}>
                            {moment(item.created_at).format("ddd D, MMM")}
                          </Box>
                        )}
                        {/* <Box display="flex">{moment(item.created_at).format("h:mma")}</Box> */}
                        {/* <Box display="flex">{t("today")}</Box> */}
                        <Box ml={1} display="flex" width={smAndUp ? "auto" : "150px"}>
                          {lang === "en" ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.english) }}
                            ></div>
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.spanish) }}
                            ></div>
                          )}
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                );
              })}
          </>
        )}
      </Box>
    </>
  );
});

export default Notifications;
