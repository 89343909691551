import ScheduleStore from "./ScheduleStore";
import TeamStore from "./TeamStore";
import UtilStore from "./UtilStore";
import BuildingStore from "./BuildingStore";
import UserStore from "./UserStore";
import PeopleStore from "./PeopleStore";
import EventStore from "./EventStore";
import NotificationStore from "./NotificationStore";
import FeedbackStore from "./FeedbackStore";
import axios, { AxiosError } from "axios";

import { makeAutoObservable, flow } from "mobx";
import BookingStore from "./BookingStore";
import VisitorStore from "./VisitorStore";
import LeaveStore from "./LeaveStore";
import BAMStore from "./BAMStore";

export default class RootStore {
  utilStore!: UtilStore;
  teamStore!: TeamStore;
  bookingStore!: BookingStore;
  scheduleStore!: ScheduleStore;
  buildingStore: BuildingStore;
  userStore!: UserStore;
  peopleStore!: PeopleStore;
  eventStore!: EventStore;
  notificationStore!: NotificationStore;
  feedbackStore!: FeedbackStore;
  visitorStore!: VisitorStore;
  leaveStore!: LeaveStore;
  bamStore!: BAMStore;
  // global store values across the app.
  error = "";

  constructor() {
    this.utilStore = new UtilStore(this);
    this.teamStore = new TeamStore(this);
    this.bookingStore = new BookingStore(this);
    this.scheduleStore = new ScheduleStore(this);
    this.buildingStore = new BuildingStore(this);
    this.userStore = new UserStore(this);
    this.peopleStore = new PeopleStore(this);
    this.eventStore = new EventStore(this);
    this.notificationStore = new NotificationStore(this);
    this.feedbackStore = new FeedbackStore(this);
    this.visitorStore = new VisitorStore(this);
    this.leaveStore = new LeaveStore(this);
    this.bamStore = new BAMStore(this);
    makeAutoObservable(this);
  }

  setError = (error: Error | AxiosError | string) => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 500) {
        this.error = `An unexpected error has occured. Error [${error.response?.status}]`;
      } else {
        this.error = `An unexpected error has happened. Error [${error.response?.status}]`;
      }
    } else {
      this.error = error.toString();
    }
  };
}
