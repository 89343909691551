import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { wdpStrings } from "config/strings";
import { observer } from "mobx-react-lite";
import { Team, TeamSchedulePeopleList } from "interfaces/Teams";
import { TeamWorkTypeSummary } from "./TeamWorkTypeSummary";
import { wdPDefaults } from "interfaces/SettingsWdp";
import InformationIcon from "components/utils/InformationIcon";
import { PopupInformationWidget } from "components/settings/PopupInformationWidget";
import { useState } from "react";
import { bhpColor } from "styles/globals";
import { useTeamStore } from "providers/RootStoreProvider";
import { SettingsSelectBox } from "components/settings/SettingsSelectBox";

interface ShowTeamOverviewProps {
  team: Team | null;
  teamSchedulePeopleList: TeamSchedulePeopleList[] | null;
  t: any;
  version2?: boolean;
  selectedTeamChanged?: any;
  date?: string;
}

const ShowTeamOverview = observer((props: ShowTeamOverviewProps) => {
  const { team, teamSchedulePeopleList, t, version2, selectedTeamChanged, date } = props;
  const teamChangeLink = wdPDefaults.teamChangeUrl;
  const teamStore = useTeamStore();
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));

  const v2 = version2 ? true : false;

  const [informationOpen, setInformationOpen] = useState(false);

  const handleInformationOpen = () => {
    setInformationOpen(true);
  };
  const handleInformationClose = () => {
    setInformationOpen(false);
  };

  const handleTeamChange = (newValue: any) => {
    selectedTeamChanged(newValue);
  };

  return team ? (
    v2 ? (
      <Grid container style={{ height: "100%" }}>
        <Grid
          item
          xs={4}
          style={{ fontSize: "16px", fontWeight: 700, display: "flex", alignItems: "center" }}
        >
          {t("Team to display")}
          <InformationIcon onclick={handleInformationOpen} />
        </Grid>
        <Grid item xs={8} style={{ display: "flex", alignItems: "center", whiteSpace: "pre" }}>
          {teamStore.isLoadingMyTeams ? (
            <>
              {t("Loading list of your teams ")}{" "}
              <CircularProgress size={14} style={{ color: bhpColor.orange1 }} />
            </>
          ) : (
            <SettingsSelectBox
              t={t}
              phText={t("Choose your office...")}
              fontSize={smAndUp ? undefined : "14px"}
              width="100%"
              phValue={-1}
              theItems={teamStore.myTeams}
              handleChange={handleTeamChange}
              defaultValue={team.id || 0}
              valueField="id"
              labelField="name"
              theId={"team"}
              theKey={"teamKey"}
              showPlaceholder={false}
            />
          )}
        </Grid>
        <Grid item xs={12} style={{ display: "flex", alignItems: "center", whiteSpace: "pre" }}>
          {teamStore.isLoadingCurrentTeam || teamStore.isLoadingTeamSchedule ? (
            <>
              {t("Loading team summary ")}{" "}
              <CircularProgress size={14} style={{ color: bhpColor.orange1 }} />
            </>
          ) : (
            <TeamWorkTypeSummary
              version2={v2}
              date={date}
              team={team}
              teamSchedulePeopleList={teamSchedulePeopleList}
            />
          )}
        </Grid>
      </Grid>
    ) : (
      <>
        <Box display="flex" alignItems="center" marginTop={2}>
          <Typography variant="h5">
            {t("Selected Team")}: {team.name}{" "}
          </Typography>
          <InformationIcon onclick={handleInformationOpen} />
        </Box>
        <Box paddingTop={2}>
          <TeamWorkTypeSummary
            version2={v2}
            date={date}
            team={team}
            teamSchedulePeopleList={teamSchedulePeopleList}
          />
        </Box>
        <PopupInformationWidget
          t={t}
          isOpen={informationOpen}
          handleClose={handleInformationClose}
          handleSecondaryClick={handleInformationClose}
          title={"Default Team Work Type"}
          content={
            "<p>This is your Default Team as assigned to you in your Settings.</p><p>You are able to see how many of your Default Team are in the office, working remotely or are unavailable.</p>"
          }
          buttonText={"Okay"}
          secondaryButtonText={null}
        />
      </>
    )
  ) : null;
});

export default ShowTeamOverview;
