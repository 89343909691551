import { observer } from "mobx-react-lite";

import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Box,
  FormControl,
  InputLabel,
  Table,
  Hidden,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  useMediaQuery,
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
  Tooltip,
  Snackbar,
} from "@material-ui/core";
import { bhpColor, settings, displaySettings } from "styles/globals";
import { useEffect, useState, Fragment } from "react";
import { LeaveStatus, LeaveType, LeaveTypeString, Leave } from "../../interfaces/Leave";
import { capitalizeString } from "utils/string";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "styles/datePicker.scss";
import { mdiCalendarRange } from "@mdi/js";
import Icon from "@mdi/react";
import { useUserStore, useLeaveStore } from "providers/RootStoreProvider";
import moment from "moment";
import { toJS } from "mobx";
import { Link, useHistory } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import { ConfirmDialog } from "components/utils/ConfirmDialog";

interface LeaveTabProps {
  t: any;
}
interface LeaveColumn {
  title: string;
  component?: any;
  width?: number;
}

interface Column {
  id: keyof Leave;
  label: string;
  align?: "right" | "left" | "center";
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      border: `1px solid ${bhpColor.blueGrey4}`,
    },
    datePicker: {
      "& input": {
        border: 0,
        width: "100%",
        fontSize: 16,
        height: 40,
      },
    },
    saveSuccess: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.orange1,
        fontWeight: "bold",
        backgroundColor: bhpColor.orange4,
        border: `2px solid ${bhpColor.orange1}`,
        borderRadius: settings.borderRadius,
      },
    },
  })
);

const tableStyles = makeStyles({
  tableRow: {
    height: 40,
    "&:nth-child(odd)": {
      backgroundColor: bhpColor.blueGrey4,
    },
  },
  tableCell: {
    padding: "0px 16px",
  },
  buildingSelector: {
    padding: 0,
    marginRight: "8px",
    "& .MuiSelect-root": {
      padding: "4px",
      width: "80px",
      fontSize: "14px",
    },
    "& .MuiSelect-iconOutlined": {
      right: 0,
    },
  },
});

const LeaveTab = observer((props: LeaveTabProps) => {
  const { t } = props;
  const classes = useStyles();
  const tableClasses = tableStyles();
  const userStore = useUserStore();
  const leaveStore = useLeaveStore();
  const history = useHistory();
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));
  // const [leaveStatus, setLeaveStatus] = useState<LeaveStatus | "">("");
  const [leaveType, setLeaveType] = useState<LeaveType | "">("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const validateForm = () => {
    if (
      // (leaveStatus || leaveStatus === LeaveStatus.PLANNED) &&
      (leaveType || leaveType === LeaveType.MATERNITY) &&
      startDate &&
      endDate
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const saveLeave = async () => {
    const leaveInformation = {
      from_date: moment(startDate).format("YYYY-MM-DD"),
      // leave_status: leaveStatus! as number,
      leave_type: leaveType! as number,
      to_date: moment(endDate).format("YYYY-MM-DD"),
      user: userStore.me?.id || 1,
    };

    const response = await leaveStore.createLeave(leaveInformation);
    if (response.status == 201 || response.status == 200) {
      setMessage(t("Leave saved successfully"));
      setIsFormValid(false);
      setIsOpen(true);
      // setLeaveStatus("");
      setLeaveType("");
      setStartDate(null);
      setEndDate(null);
    } else {
      setIsOpen(true);
      setIsApiError(true);
      setMessage(t("Not saved!!"));
    }
  };

  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [deleteConfirmSchedule, setDeleteConfirmSchedule] = useState<number | null>(null);

  const leaveTableColumns: LeaveColumn[] = [
    // {
    //   title: "Status",
    //   component: (
    //     <FormControl fullWidth variant="filled" size="small">
    //       <Select
    //         id="leaveStatusSelect"
    //         value={leaveStatus}
    //         variant={"outlined"}
    //         onChange={(event: any) => {
    //           setLeaveStatus(event.target.value);
    //         }}
    //       >
    //         <MenuItem value={-1}>
    //           <em style={{ opacity: "38%" }}>Leave Status</em>
    //         </MenuItem>
    //         {Object.keys(LeaveStatus)
    //           .filter((leaveStatus) => isNaN(Number(leaveStatus)))
    //           .map((leaveStatus, idx) => {
    //             return (
    //               <MenuItem value={LeaveStatus[leaveStatus]} key={`leaveStatus-${idx}`}>
    //                 {capitalizeString(leaveStatus)}
    //               </MenuItem>
    //             );
    //           })}
    //       </Select>
    //     </FormControl>
    //   ),
    //   width: 22,
    // },
    {
      title: t("Type"),
      component: (
        <FormControl fullWidth variant="filled" size="small">
          <Select
            id="leaveTypeSelect"
            value={leaveType}
            variant={"outlined"}
            onChange={(event: any) => {
              setLeaveType(event.target.value);
            }}
            style={{ minWidth: "50%" }}
          >
            <MenuItem value={-1} key={`leaveTypeDefault`}>
              <em style={{ opacity: "38%" }}>{t("Leave Type")}</em>
            </MenuItem>
            {Object.keys(LeaveType)
              .filter((leaveType) => isNaN(Number(leaveType)))
              .map((leaveType, idx) => {
                return (
                  <MenuItem value={LeaveType[leaveType]} key={`leaveType-${idx}`}>
                    {t(LeaveTypeString[leaveType])}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      ),
      width: 22,
    },
    {
      title: t("Start"),
      component: (
        <Box
          style={{
            width: "100%",
            border: `1px solid ${bhpColor.grey3}`,
            borderRadius: 2,
            display: "inline-flex",
          }}
        >
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => {
              setStartDate(date);
            }}
            minDate={new Date()}
            maxDate={new Date().setFullYear(new Date().getFullYear() + 1)} // Restricting 1 year end date
            isClearable
            wrapperClassName={classes.datePicker}
            showDisabledMonthNavigation
            dateFormat="yyyy-MM-dd"
          />
          <Icon style={{ paddingTop: 4 }} path={mdiCalendarRange} size={1.5} />
        </Box>
      ),
      width: 22,
    },

    {
      title: t("End"),
      component: (
        <Box
          style={{
            width: "100%",
            border: `1px solid ${bhpColor.grey3}`,
            borderRadius: 2,
            display: "inline-flex",
          }}
        >
          <DatePicker
            selected={endDate}
            onChange={(date: Date) => {
              setEndDate(date);
            }}
            minDate={startDate || new Date()}
            maxDate={new Date().setFullYear(new Date().getFullYear() + 1)} // Restricting 1 year end date
            isClearable
            wrapperClassName={classes.datePicker}
            showDisabledMonthNavigation
            dateFormat="yyyy-MM-dd"
          />
          <Icon style={{ paddingTop: 4 }} path={mdiCalendarRange} size={1.5} />
        </Box>
      ),
      width: 22,
    },
    {
      title: "",
      component: (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={saveLeave}
            disabled={!isFormValid}
          >
            {t("Add")}
          </Button>
        </Box>
      ),
      width: 12,
    },
  ];

  useEffect(() => {
    validateForm();
  }, [/*leaveStatus,*/ leaveType, startDate, endDate]);

  useEffect(() => {
    leaveStore.loadUserLeaves(userStore.me?.id);
    // console.log(toJS(leaveStore.userLeaves));
  }, []);
  const getColumns: Column[] = [
    // {
    //   id: "leave_status",
    //   label: "Status",
    //   align: "left",
    // },
    {
      id: "leave_type",
      label: "Type",
      align: "left",
    },
    {
      id: "from_date",
      label: "From",
      align: "left",
    },
    {
      id: "to_date",
      label: "To",
      align: "left",
    },
  ];
  return (
    <>
      <ConfirmDialog
        title={t("Change of plan?")}
        primaryButtonText={t("Remove")}
        secondaryButtonText={t("Keep")}
        handleSecondaryClick={() => setDeleteConfirmDialog(false)}
        handlePrimaryClick={async () => {
          if (deleteConfirmSchedule) {
            leaveStore.deleteLeave(deleteConfirmSchedule);
          }
          setDeleteConfirmDialog(false);
        }}
        isOpen={deleteConfirmDialog}
      ></ConfirmDialog>
      <Hidden smDown>
        <Box py={1}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: bhpColor.backgroundGrey,
                  borderLeft: `1px solid ${bhpColor.backgroundGrey}`,
                  borderRight: `1px solid ${bhpColor.backgroundGrey}`,
                  borderTop: `1px solid ${bhpColor.backgroundGrey}`,
                }}
                key="header"
              >
                {leaveTableColumns.map((column, idx) => (
                  <TableCell key={`header-${idx}`} style={{ width: `${column.width}%` || "100%" }}>
                    <Box style={{ textAlign: "center", color: bhpColor.blueGrey1 }}>
                      <Typography style={{ fontWeight: 700 }}>{column.title}</Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow key="addLeave">
                {leaveTableColumns.map((column, idx) => (
                  <TableCell
                    key={`addLeaveCell-${idx}`}
                    style={{ width: `${column.width}%` || "100%" }}
                  >
                    <Box style={{ color: bhpColor.blueGrey1 }}>{column.component}</Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box py={1}>
          <Table
            className={classes.table}
            style={{
              border: "0",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    padding: "0",
                    display: "table",
                    border: "0",
                  }}
                  key="header"
                >
                  {leaveTableColumns.map((column, idx) => (
                    <TableRow
                      key={`header-${idx}`}
                      style={{ width: `${column.width}%` || "100%", borderBottom: `0` }}
                    >
                      <Box
                        style={{
                          textAlign: "center",
                          color: bhpColor.blueGrey1,
                          padding: "20px 10px 10px 10px",
                        }}
                      >
                        <Typography style={{ fontWeight: 700 }}>{column.title}</Typography>
                      </Box>
                    </TableRow>
                  ))}
                </TableCell>
                <TableCell
                  key="addLeave"
                  style={{
                    border: "0",
                  }}
                >
                  {leaveTableColumns.map((column, idx) => (
                    <TableRow
                      key={`addLeaveCell-${idx}`}
                      style={{ width: `${column.width}%` || "100%" }}
                    >
                      <Box style={{ color: bhpColor.blueGrey1, paddingBottom: "15px" }}>
                        {column.component}
                      </Box>
                    </TableRow>
                  ))}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </Box>
      </Hidden>
      <Box py={1}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    className={`${tableClasses.tableRow} `}
                    style={{
                      height: "40px",
                      display: "table-row",
                    }}
                  >
                    {getColumns.map((column, idx) =>
                      idx === 5 ? (
                        ""
                      ) : (
                        <TableCell
                          key={`${column.id}-${idx}`}
                          align={column.align}
                          style={{
                            width: idx === 3 ? "30%" : "unset",
                            fontWeight: 700,
                            fontSize: 16,
                            color: bhpColor.blueGrey1,
                          }}
                        >
                          {t(column.label)}
                        </TableCell>
                      )
                    )}
                    <TableCell
                      align="left"
                      style={{
                        width: "unset",
                        fontWeight: 700,
                        fontSize: 16,
                        color: bhpColor.blueGrey1,
                      }}
                    >
                      {t("Action")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaveStore.userLeaves.map((column, index) => {
                    return (
                      <Fragment key={`F${index}`}>
                        <TableRow
                          key={`${column.id}-${index}`}
                          className={`${tableClasses.tableRow} `}
                          style={{
                            height: "40px",
                            display: "table-row",
                          }}
                        >
                          {/* <TableCell
                            key={`leave_status-${index}`}
                            align="left"
                            className={tableClasses.tableCell}
                          >
                            {LeaveStatus[column.leave_status]}
                          </TableCell> */}
                          <TableCell
                            key={`leave_type-${index}`}
                            className={tableClasses.tableCell}
                            align="left"
                          >
                            {LeaveTypeString[LeaveType[column.leave_type]]}
                          </TableCell>
                          <TableCell
                            key={`from_date-${index}`}
                            className={tableClasses.tableCell}
                            align="left"
                          >
                            {column.from_date}
                          </TableCell>
                          <TableCell
                            key={`to_date-${index}`}
                            className={tableClasses.tableCell}
                            align="left"
                          >
                            {column.to_date}
                          </TableCell>
                          <TableCell
                            className={tableClasses.tableCell}
                            key={`actions${index}a`}
                            style={{ alignContent: "end" }}
                            align="right"
                          >
                            <Grid container>
                              <Grid
                                item
                                xs={4}
                                style={{ justifyContent: "flex-start", display: "flex" }}
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setDeleteConfirmDialog(true);
                                    setDeleteConfirmSchedule(column.id);
                                  }}
                                >
                                  <Box
                                    style={{
                                      color: "red",
                                      textDecoration: "unset",
                                    }}
                                  >
                                    <Tooltip title={t("Cancel Leave")}>
                                      <CancelIcon style={{ marginLeft: "8px" }} />
                                    </Tooltip>
                                  </Box>
                                </Link>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={classes.saveSuccess}
        autoHideDuration={displaySettings.snackDelay}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          if (isApiError) {
            setIsApiError(false);
          }
        }}
        message={message}
        key={"top" + "right"}
      />
    </>
  );
});

export default LeaveTab;
