import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Topbar from "./Topbar";
import { teamStyles } from "styles/teamStyles";
import { observer } from "mobx-react-lite";
import { bhpColor, displaySettings } from "styles/globals";
import { useTeamStore, useUserStore } from "providers/RootStoreProvider";
import { useEffect, useState } from "react";
import { TeamSummary } from "components/team/TeamSummary";
import { useTranslation } from "react-i18next";
import { TeamSearch } from "components/team/TeamSearch";
import { Team } from "interfaces/Teams";
import { useHistory } from "react-router";
import LoadingZone from "components/schedule/LoadingZone";

const Teams = observer((props: any) => {
  const userStore = useUserStore();
  const teamStore = useTeamStore();
  const { t, i18n } = useTranslation();
  const {
    profile: { team: myTeamId },
    profile: { team_name: myTeamName },
    profile: { department: myDepartment },
  } = userStore.me || { profile: { team: null, team_name: null, department: "" } };

  const classes = teamStyles();
  // const [teamSummaryUsers, setTeamSummaryUsers] = useState<any[]>([]);
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();

  const snackbarClose = () => {
    teamStore.setTeamDeleted(null);
    teamStore.teamDeletedSnackMessage = "";
  };
  const jumpToCreateTeam = () => {
    history.push("/teams/create");
  };

  const drawCreateTeamButton = () => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: smAndUp ? -70 : -50,
          marginRight: smAndUp ? theme.spacing(4) : 15,
          marginBottom: smAndUp ? 32 : 4,
        }}
      >
        <Button
          variant="contained"
          style={{
            bottom: smAndUp ? 0 : 75,
            left: smAndUp ? 0 : 10,
          }}
          disableElevation
          color="primary"
          onClick={jumpToCreateTeam}
        >
          + Create new team
        </Button>
      </Box>
    );
  };

  useEffect(() => {
    teamStore.loadMyTeams();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Topbar
        title={t("My Teams")}
        subtitle={t("Join, view or create groups with teammates")}
        breadcrumb={t("Teams")}
      />
      <Box style={smAndUp ? {} : { marginBottom: "25px" }}>{drawCreateTeamButton()}</Box>
      <Box px={smAndUp ? 4 : 2} py={smAndUp ? 4 : 1}>
        <Grid container spacing={smAndUp ? 4 : 2}>
          {!teamStore.isLoading ? (
            <>
              {teamStore.myTeams.map((team: Team, idx) => {
                return (
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={`bf${idx}`}>
                    <Paper elevation={0} className={classes.hoverTeamSummaryBorder}>
                      <Box key={`b${idx}`}>
                        <Paper
                          elevation={0}
                          className={classes.paper}
                          style={{
                            minHeight: 118,
                            padding: smAndUp ? theme.spacing(2) : theme.spacing(1),
                          }}
                          key={`bp${idx}`}
                        >
                          <TeamSummary
                            t={t}
                            teamName={team.name}
                            department={team.department}
                            teamCategory={team.category}
                            teamVisibility={team.visibility}
                            member_count={team.member_count}
                            owners={team.owners}
                            teamId={team.id}
                            membersSample={team.members_sample}
                          />
                        </Paper>
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
              <Grid item lg={12} md={12} sm={12}>
                <TeamSearch t={t} />
              </Grid>
            </>
          ) : (
            <Box margin={1.5}>
              {`${t("Loading your teams")}...`}
              <CircularProgress size={14} color="secondary" />
            </Box>
          )}
        </Grid>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={teamStore.teamDeleted ? classes.saveSuccess : classes.saveFailed}
        autoHideDuration={displaySettings.snackDelay}
        open={teamStore.teamDeleted !== null}
        onClose={snackbarClose}
        message={teamStore.teamDeletedSnackMessage}
      />
    </>
  );
});

export default Teams;
