import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Building, Floor, Space } from "interfaces/Building";
import { observer } from "mobx-react-lite";
import HeaderImage from "assets/headerImage.png";
import { bhpColor } from "styles/globals";
// @ts-ignore
import InteractiveFloorplan from "./InteractiveFloorplan";
import { useBuildingStore } from "providers/RootStoreProvider";
import moment from "moment";
import PrismaZoom from "react-prismazoom";
import { useTranslation } from "react-i18next";
import { getToday } from "utils/hourlyBookings";

const PopupStyles = makeStyles((theme) => ({
  base: {
    color: bhpColor.blueGrey1,
    "& .MuiPaper-root": {
      color: bhpColor.blueGrey1,
    },
    "& .MuiDialog-paper": {
      maxWidth: "calc(100% - 64px)",
    },
    "& .MuiDialog-paperFullScreen": {
      maxWidth: "90%",
      maxHeight: "90%",
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(2),
    },
  },
  dialogTitle: {
    backgroundImage: `url(${HeaderImage})`,
    backgroundSize: "100% auto",
    color: bhpColor.white,
    "& h2": {
      fontWeight: "bold",
      "& svg": {
        verticalAlign: "text-top",
      },
    },
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  mainItem: {
    flex: "auto",
  },
  bottomItem: {
    paddingBottom: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface FloorPlanProps {
  building?: Building | null;
  floor?: Floor | null;
  spaceId?: number;
  date: string;
  isOpen: boolean;
  handleClose: any;
  isLoading?: boolean;
  disableEditing?: any;
  showLabels?: boolean;
}

const FloorPlan = observer((props: FloorPlanProps) => {
  const {
    isLoading,
    building,
    floor,
    isOpen,
    handleClose,
    spaceId,
    date,
    disableEditing,
    showLabels,
  } = props;
  const classes = PopupStyles();
  const theme = useTheme();
  const buildingStore = useBuildingStore();
  const fullScreen = true; //useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const planName = isLoading
    ? ""
    : building
    ? `${building !== null ? `${building.city}, ${building.building_name}` : ""} ${
        floor?.name ? `Level ${floor.name}` : ""
      }`
    : "";

  return (
    <Dialog open={isOpen} onClose={handleClose} className={classes.base} fullScreen={fullScreen}>
      <DialogTitle className={classes.dialogTitle}>
        {t("Wayfinder")}: {!isLoading && planName}{" "}
        {date ? `(${moment(date).format("DD MMM")})` : ""}
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box>
            Loading floor plan <CircularProgress size={14} color="secondary" />
          </Box>
        ) : (
          building &&
          floor &&
          floor.hasFloorPlan && (
            <InteractiveFloorplan
              buildingId={building.id}
              floorId={floor.id}
              showSpaceId={spaceId}
              date={date}
              onclose={handleClose}
              disableEditing={disableEditing}
              deskHours={getToday()}
              showButton={false}
              showLabels={showLabels}
              isWayFinder={true}
            />
          )
        )}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
});

export default FloorPlan;
