import {
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Typography,
  styled,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEventStore, useBuildingStore } from "providers/RootStoreProvider";
import { useEffect, useState } from "react";
import { bhpColor } from "styles/globals";
import { Event, EventDates } from "interfaces/Event";
import { getEventImage, getFormattedEventDetailsTime } from "utils/events";
import { BuildingWithCapacity } from "interfaces/Building";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface EventsListProps {
  selectedDateFilter: EventDates | null;
  handleEventSelection: any;
  building: BuildingWithCapacity;
  eventId: number | null;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    subjectEventList: {
      fontWeight: "bold",
      color: bhpColor.blueGrey1,
      whiteSpace: "normal",
    },
    event: {
      backgroundColor: bhpColor.backgroundGrey,
      border: "1px solid #B7B9BB",
      borderRadius: 4,
      margin: 0,
      "&:hover": {
        backgroundColor: bhpColor.white,
        cursor: "pointer",
      },
    },
    eventSelected: {
      backgroundColor: bhpColor.white,
    },
    eventDate: {
      justifyContent: "center",
      alignSelf: "center",
      color: bhpColor.black,
    },
    selectedDate: {
      fontWeight: "bold",
    },
    eventSummary: {
      color: bhpColor.blueGrey2,
      fontSize: 16,
    },
    accordionMobile: {
      padding: 0,
      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
    },
    eventDetailsBox: {
      padding: theme.spacing(1),
      whiteSpace: "normal",
    },
    heading: {
      color: bhpColor.grey1,
      fontSize: 16,
      fontWeight: "bold",
    },
    informationBody: {
      color: bhpColor.grey1,
      fontSize: 14,
      fontWeight: "normal",
      padding: theme.spacing(1),
    },
  })
);

const EventsList = observer((props: EventsListProps) => {
  const { handleEventSelection, selectedDateFilter, building, eventId } = props;
  const eventStore = useEventStore();
  const buildingStore = useBuildingStore();
  const [eventsSummaryList, setEventsSummaryList] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const classes = innerStyles();
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (eventId) {
        const filteredEventSummary = await eventStore.getEventById(eventId);
        if (filteredEventSummary) {
          setEventsSummaryList([filteredEventSummary]);
        } else {
          setEventsSummaryList([]);
        }
      } else {
        const eventsSummaries = await eventStore.getFilteredEventsSummary(
          selectedDateFilter || EventDates.ThisMonth,
          building.id
        );
        setEventsSummaryList(eventsSummaries || []);
      }
    })();
  }, [selectedDateFilter]);

  useEffect(() => {
    if (eventsSummaryList && eventsSummaryList.length > 0) {
      if (eventsSummaryList[0].building === building.id) {
        setSelectedEvent(eventsSummaryList[0]);
        handleEventSelection(eventsSummaryList[0]);
        history.push(`/offices/${building.id}/events/${eventsSummaryList[0].id}`);
      } else {
        history.push(`/offices/${eventsSummaryList[0].building}/events/${eventsSummaryList[0].id}`);
      }
    } else {
      history.push(`/offices/${building.id}/events`);
      setSelectedEvent(null);
    }
  }, [eventsSummaryList]);

  const onEventSelection = (event: Event) => {
    setSelectedEvent(event);
    handleEventSelection(event);
  };

  const Img = styled("img")({
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    padding: 14,
  });
  const { t, i18n } = useTranslation();
  return (
    <>
      {eventsSummaryList.length > 0 ? (
        mdAndUp ? (
          eventsSummaryList.map((event: Event, idx: number) => {
            const floorName = building.floors?.find((floor) => floor.id === event.floor)?.name;
            return (
              <Grid
                container
                key={idx}
                className={`${classes.event} ${
                  event.id === selectedEvent?.id ? classes.eventSelected : ""
                }`}
                onClick={() => onEventSelection(event)}
              >
                <Grid item xs={2} className={classes.eventDate}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        className={event.id === selectedEvent?.id ? classes.selectedDate : ""}
                      >
                        <Box style={{ lineHeight: "normal" }}>
                          {moment(event.event_start_date, "YYYY-MM-DD").format("DD")}
                        </Box>
                        <Box style={{ lineHeight: "normal" }}>
                          {moment(event.event_start_date, "YYYY-MM-DD").format("MMM").toUpperCase()}
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} sm container>
                  <Grid
                    item
                    container
                    direction="column"
                    style={{ alignItems: "left", alignSelf: "center" }}
                    spacing={2}
                  >
                    <Grid item className={classes.eventSummary}>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                        className={classes.subjectEventList}
                      >
                        {event.event_title}
                      </Typography>
                      <Typography variant="body2" gutterBottom component="div">
                        {getFormattedEventDetailsTime(
                          event.event_start_date,
                          event.event_start_time,
                          event.event_end_time,
                          building.building_timezone || null
                        )}
                      </Typography>
                      <Typography variant="body2" component="div">
                        {floorName ? `Level ${floorName}` : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container xs={3} spacing={2}>
                  <Grid item>
                    <Img alt="Not Available" src={getEventImage(event.event_category)} />
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        ) : (
          eventsSummaryList.map((event: Event, idx: number) => {
            const floorName = building.floors?.find((floor) => floor.id === event.floor)?.name;
            return (
              <Accordion key={idx}>
                <AccordionSummary style={{ padding: 0 }} className={classes.accordionMobile}>
                  <Grid
                    container
                    key={idx}
                    className={`${classes.event} ${
                      event.id === selectedEvent?.id ? classes.eventSelected : ""
                    }`}
                    onClick={() => onEventSelection(event)}
                  >
                    <Grid item xs={2} className={classes.eventDate}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            className={event.id === selectedEvent?.id ? classes.selectedDate : ""}
                            style={{ fontSize: 12 }}
                          >
                            <Box style={{ lineHeight: "normal" }}>
                              {moment(event.event_start_date, "YYYY-MM-DD").format("DD")}
                            </Box>
                            <Box style={{ lineHeight: "normal" }}>
                              {moment(event.event_start_date, "YYYY-MM-DD")
                                .format("MMM")
                                .toUpperCase()}
                            </Box>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={7} sm container>
                      <Grid
                        item
                        container
                        direction="column"
                        style={{ alignItems: "left", alignSelf: "center" }}
                        spacing={2}
                      >
                        <Grid item className={classes.eventSummary}>
                          <Typography
                            gutterBottom
                            variant="subtitle2"
                            component="div"
                            className={classes.subjectEventList}
                          >
                            {event.event_title}
                          </Typography>
                          <Typography
                            variant="body2"
                            gutterBottom
                            style={{ letterSpacing: 0, fontSize: 12 }}
                            component="div"
                          >
                            {getFormattedEventDetailsTime(
                              event.event_start_date,
                              event.event_start_time,
                              event.event_end_time,
                              building.building_timezone || null
                            )}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{ letterSpacing: 0, fontSize: 12 }}
                            component="div"
                          >
                            {floorName ? `Level ${floorName}` : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item container xs={3} spacing={0}>
                      <Grid item>
                        <Img alt="Not Available" src={getEventImage(event.event_category)} />
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className={classes.eventDetailsBox}>
                    <Typography variant="h6" component="div" className={classes.heading}>
                      Information:
                    </Typography>
                    <Box className={classes.informationBody}>
                      <Grid container>
                        <Grid item xs={12}>
                          {event.event_description}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })
        )
      ) : (
        <Box>
          <Typography
            component="div"
            variant="subtitle1"
            style={{ paddingTop: 20, paddingBottom: 20, whiteSpace: "normal" }}
            align="center"
          >
            {eventId
              ? "This event could not be found."
              : t("There are no recorded events for the selected location.")}
          </Typography>
        </Box>
      )}
    </>
  );
});
export default EventsList;
