export const wdpStrings = {
  welcomeMessage: {
    title: "Welcome...",
    content:
      "<p><strong>Now let's get you up and running</strong></p><p>The BHP Workday Platform needs some details from you to get started. You can read the Data Privacy Statement if you’d like to see how this data will be used.</p>",
    buttonText: "Let's get you started",
    buttonTwoText: "View Data Privacy Statement",
  },
  dataPrivacyStatemant: {
    title: "",
    content:
      "<p>Workday Platform is being deployed to the BHP Global Standard Operating Environment for Workplace Efficiency purposes. It will capture an employee’s location while working at an office location. It will not capture an employee’s activity outside of our Workplace. This data will be used in de-identified workplace utilisation reports, to power a real-time wayfinding application to find available desks and colleagues and may be used for business continuity purposes and occupational health and safety requirements (e.g. to ensure there are enough fire wardens on a floor). The data will not be used for surveillance purposes. For more information about how BHP handles employee information generally, please refer to the Global Employee Notice.</p><strong>Employees that have legitimate concerns regarding the resourcing tool and their safety or privacy in the workplace they should contact the privacy office at dataprivacy@bhp.com</strong></p>",
    buttonText: "Back",
  },
  confirmPrivacyChange: {
    title: "Are you sure?",
    content:
      "<p>Changing your Schedule’s visibility will mean that members of your team/s are unable to see your schedule or desk bookings.</p>Note: Admin Users will still be able to view your Schedule and see your Desk Bookings</p>",
    primaryButtonText: "Update Visibility",
    secondaryButtonText: "Cancel",
  },
  savedSettingsSuccess: "Changes Saved",
  savedSettingsFailed: "Failed to save",

  undefined: "not available...",
  notFound: "not found",
  percentSymbol: "%",

  defaultDropDownPlaceholder: {
    text: "Choose...",
    value: "",
  },
  topbar: {
    settings: {
      title: "Settings",
      subtitle: "Update your settings here",
      breadcrumb: "Settings",
    },
  },
  worktypeLabel: {
    office: {
      text: "Office",
      aria: "working in office",
    },
    remote: {
      text: "Remote",
      aria: "working remotly",
    },
    off: {
      text: "Off",
      aria: "not working",
    },
  },

  weekdays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
  weekdaysAbbreviated: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],

  booking: {
    yourBooking: "Your booking for",
    bookDesk: "Book Desk",
    youveSelected: "You've selected",
    selectedFloorPrefix: "Level",
    openFloorPlan: "View Floor Plan",
    capacity: "Capacity",
    capacityVAxis: [
      { percentage: 0, label: "25%" },
      { percentage: 25, label: "25%" },
      { percentage: 50, label: "50%" },
      { percentage: 75, label: "75%" },
      { percentage: 100, label: "100%" },
    ],
    capacityLimitForThisOffice: {
      a: "Capacity limit for this office is",
      b: "spaces",
    },
    spacesAvailable: "spaces available",
    selectedTeam: "Selected Team",
    selectAFloor: "Select a Floor",
    selectAFloorToViewSpaces: "Choose a floor to load spaces...",
    noDesksFound: "No spaces found...",
    viewAvailableDesks: "View Available Desks",
    disabledFloor: "not available",
    team: {
      inOffice: "At a BHP Location",
      inOfficeAbrv: "Office",
      remote: "Remote",
      unavailable: "Off",
    },
  },
  dashboard: {
    subheading: "My Workday",
    yourScheduleToday: "My planned location today",
    yourSchedule: {
      yourFullSchedule: "My Full Planner",
      yourSchedule: "Your planner",
      upcomming: "Upcoming week",
      myPlanner: "My Planner",
    },
    teamWidget: {
      viewing: "Viewing",
      viewteamPage: "View Team Page",
      empty: "",
    },
    capacityWidget: {
      scheduleType: {
        unknown: "Unknown",
        off: "Off Today",
        remote: "Working Remote",
        office: "At a BHP Location",
      },
      workingHours: "9am - 5pm",
      plannedAttendance: "Planned Attendance",
      desksBooked: "Desks Booked",
      percentSymbol: "%",
    },
  },
  page: {
    people: {
      searchPlaceholder: "Type a first or last name to search",
    },
    profile: {
      changeProfileLink: "https://bhp.service-now.com/sp?id=user_profile",
    },
    settings: {
      heading: "Your default settings",
      headContent: "The following will be used when updating your planner",
      changeTeamLinkText: "Change",
      titleTeam: "Team",
      titleLocation: "Location",
      titleFieldSite: "Field Site",
      titleWorkingHours: "Working Hours",
      titleRemoteTimezone: "Timezone",
      titleWeeklyRoutine: "Weekly Routine",
      titleScheduleVisibility: "Planner Visibility",
      workTypeTitle: "Work Type",
      weekdayTitle: "Weekday",
      buttons: {
        updateChanges: "Update Changes",
        firstTimeSave: "Complete Setup",
      },
    },
  },
};
