const WORKDAY_NUMBER_OF_WEEKS_SCHEDULABLE = 5;
const WORKDAY_NUMBER_OF_WEEKS_BOOKABLE = 2;

const config = {
  api: {
    baseURL: "",
    timeout: 60000,
    xsrfHeaderName: "X-CSRFToken",
    xsrfCookieName: "workday_csrftoken",
    withCredentials: true,
    headers: {
      "x-requested-with": "XMLHttpRequest",
    },
  },
  workday: {
    // todo: get this from the backend
    numberOfWeeksSchedulable: WORKDAY_NUMBER_OF_WEEKS_SCHEDULABLE,
    numberOfDaysSchedulable: WORKDAY_NUMBER_OF_WEEKS_BOOKABLE * 7,
    numberOfDaysBookable: 2 * 7,
    defaultStartWorkingHour: "9:00",
    defaultFinishWorkingHour: "17:00",
    defaultDeskStartHour: "9:00",
    defaultDeskFinishHour: "17:00",
  },
};
export default config;
