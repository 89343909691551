import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiCalendarRemoveOutline, mdiHeadset, mdiMapMarkerRadiusOutline } from "@mdi/js";
import { bhpColor, settings } from "styles/globals";
import { ScheduleType } from "interfaces/Schedule";
import { wdpStrings } from "config/strings";
import { observer } from "mobx-react-lite";
import { TeamWorkdaySchedule, WorkdaySchedule } from "interfaces/Teams";
import { WorkSharp } from "@material-ui/icons";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: bhpColor.blueGrey1,
      "& .MuiGrid-item": {
        display: "flex",
      },
      "& .MuiLinearProgress-barColorSecondary": {
        borderRadius: settings.borderRadius,
      },
    },
    worktypeBox: {
      width: "100%",
      display: "flex",
      backgroundColor: bhpColor.backgroundGrey,
      borderRadius: settings.borderRadius,
    },
    workStatus: {
      textAlign: "start",
      fontSize: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
      "&.office": {
        color: bhpColor.blueGrey1,
      },
      "&.remote": {
        color: bhpColor.blueGrey2,
      },
      "&.off": {
        color: bhpColor.grey2,
      },
    },
    iconBox: {
      width: 72,
      height: 64,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: settings.borderRadius,
      color: bhpColor.white,
      [theme.breakpoints.down("sm")]: {
        width: 125,
        height: 55,
      },
      "&.office": {
        backgroundColor: bhpColor.blueGrey1,
      },
      "&.remote": {
        backgroundColor: bhpColor.blueGrey2,
      },
      "&.off": {
        backgroundColor: bhpColor.grey2,
      },
      "& .notOffice": {},
      "& .bookingRef": {
        fontSize: 10,
        marginTop: -5,
      },
      textAlign: "center",
    },
    icon: {
      height: 40,
      [theme.breakpoints.down("md")]: {
        height: 36,
      },
      [theme.breakpoints.down("sm")]: {
        height: 26,
      },
      "& .office": {
        color: bhpColor.white,
      },
    },
  })
);

/// From workType return the icon type office|remote|off (default: off)
function getIconPath(workType: ScheduleType) {
  switch (workType) {
    case ScheduleType.OFFICE:
      return mdiMapMarkerRadiusOutline;
    case ScheduleType.REMOTE:
      return mdiHeadset;
    case ScheduleType.OFF:
      return mdiCalendarRemoveOutline;
    default:
      return mdiCalendarRemoveOutline;
  }
}

interface TeamDistributionSummaryProps {
  workdaySchedule: WorkdaySchedule | null;
  t: any;
}

const TeamDistributionSummary = observer((props: TeamDistributionSummaryProps) => {
  const { workdaySchedule, t } = props;
  const classes = innerStyles();
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));
  const theIcon = (type: ScheduleType) => getIconPath(type);

  return workdaySchedule ? (
    <Box mt={1}>
      <Grid container className={classes.root}>
        <Grid item xs={12} md={12}>
          <Box pb={1}>
            <Typography style={{ fontWeight: 700 }}>{t("Worktypes")}</Typography>
          </Box>
        </Grid>
        <Grid item xs={4} md={12}>
          <Box className={classes.worktypeBox} alignItems={"center"} mr={{ sm: 1, md: 2 }}>
            <Box className={`${classes.iconBox} office`}>
              <Icon path={theIcon(ScheduleType.OFFICE)} className={`${classes.icon} office`} />
            </Box>
            <Box p={1} style={{ width: "100%" }}>
              <Box className={`${classes.workStatus} office`}>
                <Typography
                  style={{
                    display: `${smAndUp ? "inline" : "block"}`,
                    fontWeight: 700,
                    lineHeight: 1,
                  }}
                >
                  {workdaySchedule.schedule_status_counts.find(
                    (st) => st.status === ScheduleType.OFFICE
                  )?.count || 0}{" "}
                </Typography>
                <Typography
                  style={{
                    display: `${smAndUp ? "inline" : "block"}`,
                    lineHeight: 1,
                    fontWeight: 400,
                  }}
                >
                  {smAndUp ? t("At a BHP Location") : t("")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4} md={12}>
          <Box
            className={classes.worktypeBox}
            alignItems={"center"}
            mt={{ sm: 0, md: 1 }}
            mb={{ sm: 0, md: 1 }}
            ml={{ sm: 1, md: 0 }}
            mr={{ sm: 1, md: 2 }}
          >
            <Box className={`${classes.iconBox} remote`}>
              <Icon path={theIcon(ScheduleType.REMOTE)} className={classes.icon} />
            </Box>
            <Box p={1} style={{ width: "100%" }}>
              <Box className={`${classes.workStatus} remote`}>
                <Typography
                  style={{
                    fontWeight: 700,
                    display: `${smAndUp ? "inline" : "block"}`,
                    lineHeight: 1,
                  }}
                >
                  {workdaySchedule.schedule_status_counts.find(
                    (st) => st.status === ScheduleType.REMOTE
                  )?.count || 0}{" "}
                </Typography>
                <Typography
                  style={{
                    display: `${smAndUp ? "inline" : "block"}`,
                    lineHeight: 1,
                    fontWeight: 400,
                    color: bhpColor.blueGrey2,
                  }}
                >
                  {smAndUp ? t("Remote") : t("")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4} md={12}>
          <Box
            className={classes.worktypeBox}
            alignItems={"center"}
            ml={{ sm: 1, md: 0 }}
            mr={{ sm: 0, md: 2 }}
          >
            <Box className={`${classes.iconBox} off`}>
              <Icon path={theIcon(ScheduleType.OFF)} className={classes.icon} />
            </Box>
            <Box p={1} style={{ width: "100%" }}>
              <Box className={`${classes.workStatus} off`}>
                <Typography
                  style={{
                    display: `${smAndUp ? "inline" : "block"}`,
                    fontWeight: 700,
                    lineHeight: 1,
                    color: bhpColor.grey2,
                  }}
                >
                  {workdaySchedule.schedule_status_counts.find(
                    (st) => st.status === ScheduleType.OFF
                  )?.count || 0}{" "}
                </Typography>
                <Typography
                  style={{
                    display: `${smAndUp ? "inline" : "block"}`,
                    lineHeight: 1,
                    fontWeight: 400,
                  }}
                >
                  {smAndUp ? t("Unavailable") : t("")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  ) : null;
});

export default TeamDistributionSummary;
