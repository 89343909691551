import { makeAutoObservable, flow } from "mobx";
import { Timezone, Building } from "interfaces/Utils";
import RootStore from "./RootStore";
import client from "services/api";
import { FeedbackForm } from "interfaces/FeedbackForm";

export default class FeedbackStore {
  isLoading = true;
  error = "";
  rootStore!: RootStore;
  feedbackForms: FeedbackForm[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  loadFeedbackForms = flow(function* (this: FeedbackStore) {
    this.isLoading = true;
    this.error = "";
    try {
      const res = yield client.get("/api/form/forms/");
      this.feedbackForms = [];

      res.data.results.forEach((form: FeedbackForm) => {
        this.feedbackForms.push(form);
      });
    } catch (err) {
      this.error = (err as Error).message;
    }
    this.isLoading = false;
  });
}
