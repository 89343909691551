import React, { createElement, useEffect, useState, createContext } from "react";
import { BrowserRouter as Router, Route, NavLink, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useMsal } from "@azure/msal-react";
import { Box, Drawer, IconButton, CssBaseline, Link, Button } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
// import * as Sentry from "@sentry/react";
import { Snackbar } from "@material-ui/core";
import { bhpColor, displaySettings } from "styles/globals";
import { useRootStore } from "providers/RootStoreProvider";

import { primaryRoutes, secondaryRoutes } from "./routes";
import { useStyles } from "./styles/main_styles";
import themelight from "./styles/dds-material-react-light-theme";
import GuardedRoute from "components/route/GuardedRoute";
import logoVertical from "./assets/workday-vertical.png";
import { getWithExpiry, setWithExpiry } from "utils/storage";
import Settings from "views/main/Settings";
import { useUserStore } from "providers/RootStoreProvider";
import AppBarWrapper from "components/main/AppBarWrapper";
import { useTranslation } from "react-i18next";
import FloorPlan from "components/booking/FloorPlan";
import { AllHolidays } from "interfaces/Utils";
import { startOfWeek } from "utils/date";
import moment from "moment";
import { getHolidays } from "utils/holidays";
import { toJS } from "mobx";
import { RequestStatusChoices, VmsMeetingStatusChoices } from "interfaces/Visitor";

export const HolidayContext = createContext<AllHolidays[]>([]);

const Main = observer((props: any) => {
  /** Handle user events and sidebar visibility */
  const [open, setOpen] = React.useState(false);

  const closeMenu = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  /** main styles */
  const classes = useStyles();
  const userStore = useUserStore();
  const rootStore = useRootStore();

  const { t, i18n } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { instance, accounts, inProgress } = useMsal();
  const account = accounts[0];

  const getAllHolidays = () => {
    const yearThisWeek = startOfWeek(new Date()).getFullYear().toString();
    const yearAfterFiveWeeks = moment(startOfWeek(new Date())).add(34, "days").year().toString();
    return getHolidays(
      yearThisWeek === yearAfterFiveWeeks ? [yearThisWeek] : [yearThisWeek, yearAfterFiveWeeks]
    );
  };

  useEffect(() => {
    const loadAsync = async () => {
      // a bit of a hack - the MSAL instructions we followed are for SPA
      // so the frontend gets all of its auth frontend wise which then we can
      // send with `Bearer ${window.token}` but it s a bit of a waste since
      // the backend then needs to call microsoft for each request to validate
      // the token. So instead we are going to use the httponly cookie and session
      // so the user is identified in the backend too through a secure cookie
      const hasSessionId = getWithExpiry("session-backend");
      if (!hasSessionId) {
        setWithExpiry("session-backend", "yes", 3600000);
        window.location.href = encodeURI(
          `/api/oidc/authenticate/?next=${window.location.pathname}`
        );
        return;
      }

      if (accounts[0]) {
        const response = await instance.acquireTokenSilent({
          scopes: ["User.Read"],
          account: accounts[0],
        });

        window.token = response.accessToken;
        await userStore.getMe().then(() => {
          rootStore.bamStore
            .loadMyRequests(userStore.me!.id, RequestStatusChoices.SUBMITTED)
            .then((res) => {
              rootStore.bamStore.loadAllRequests(userStore.me!.id);
            });
        });
        await userStore.getMe().then(() => {
          rootStore.bamStore
            .loadVmsMeetings(userStore.me!.id, VmsMeetingStatusChoices.PROCESSING)
            .then((res) => {
              rootStore.bamStore.loadAllVmsMeetings(userStore.me!.id);
            });
        });
        await userStore.getMe().then(() => {
          rootStore.bamStore
            .loadVmsMeetings(userStore.me!.id, VmsMeetingStatusChoices.SUBMITTED)
            .then((res) => {
              rootStore.bamStore.loadAllVmsMeetings(userStore.me!.id);
            });
        });
        rootStore.feedbackStore.loadFeedbackForms();
      }
    };
    loadAsync();
  }, [account, instance]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeFloorPlan = () => {
    rootStore.buildingStore.openFloorPlan(false);
  };

  const [showFloorPlan, setShowFloorPlan] = useState(rootStore.buildingStore.showFloorPlan);
  useEffect(() => {
    setShowFloorPlan(rootStore.buildingStore.showFloorPlan);
  }, [rootStore.buildingStore.showFloorPlan]);

  // const [hasRequests, setHasRequests] = useState(false);
  // useEffect(() => {
  //   if (userStore.me) {
  //     setHasRequests(rootStore.bamStore.hasRequests(userStore.me!.id));
  //   }
  // }, [userStore.me]);

  const drawSidebarItem = (key: string | number, path: string, icon: any, name: string) => {
    return (
      <div key={`sbia${key}`} className="sidebarItem">
        <NavLink
          key={`sbiaLink${key}`}
          to={path}
          onClick={closeMenu}
          className={`link  ${classes.navLink}`}
          activeClassName="selected"
        >
          {icon && createElement(icon, { className: "icon" })} {t(name)}
        </NavLink>
      </div>
    );
  };

  return userStore.me ? (
    <CssBaseline>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={classes.snackbarError}
        autoHideDuration={displaySettings.snackDelay}
        open={rootStore.error.length > 0}
        onClose={() => rootStore.setError("")}
        message={rootStore.error}
      />
      <ThemeProvider theme={themelight}>
        <HolidayContext.Provider value={getAllHolidays()}>
          <FloorPlan
            isLoading={rootStore.buildingStore.isLoadingFloorPlan}
            building={
              rootStore.buildingStore.floorplan.building_id
                ? rootStore.buildingStore.getBuilding(rootStore.buildingStore.floorplan.building_id)
                : null
            }
            floor={
              rootStore.buildingStore.floorplan.floor_id
                ? {
                    id: rootStore.buildingStore.floorplan.floor_id,
                    name: rootStore.buildingStore.floorplan.floor_name,
                    hasFloorPlan: rootStore.buildingStore.floorplan.floor_hasFloorPlan,
                  }
                : null
            }
            spaceId={rootStore.buildingStore.floorplan.space_id}
            date={rootStore.buildingStore.floorplan.date || moment(new Date()).format("YYYY-MM-DD")}
            isOpen={showFloorPlan}
            handleClose={closeFloorPlan}
            disableEditing={!rootStore.buildingStore.floorplan.canEdit}
            showLabels={rootStore.buildingStore.floorplan.showLabels}
          />
          <Router
            getUserConfirmation={() => {
              /* WDP-645: Prompt user to update changes in settings before leaving the page.
            Empty callback to block the default browser prompt */
            }}
          >
            <div className="App">
              <div className="container">
                {userStore?.me?.is_bhp_user ? (
                  <div style={{ position: "relative" }} className="sidebar">
                    <Drawer
                      className={classes.drawer}
                      variant="persistent"
                      anchor="left"
                      open={open}
                    >
                      <div className="drawer">
                        <div className="bhpLogo">
                          <img alt="logo" className={classes.logo} src={logoVertical}></img>
                        </div>
                        <div className="sidebar">
                          {userStore?.me?.is_bhp_user &&
                            primaryRoutes
                              .filter((rte) => rte.onNav === true)
                              .map(({ path, icon, name }, key) => {
                                if (name.toLowerCase() == "requests")
                                  return (
                                    rootStore.bamStore.hasRequests &&
                                    drawSidebarItem(key, path, icon, name)
                                  );
                                if (name.toLowerCase() == "meetings")
                                  return (
                                    rootStore.bamStore.hasMeetings &&
                                    drawSidebarItem(key, path, icon, name)
                                  );
                                else return drawSidebarItem(key, path, icon, name);
                              })}
                          <br />
                          {!userStore?.me?.is_bhp_user && (
                            <div key={`sbib-99`} className="sidebarItem">
                              <NavLink
                                key={`sbibLink-99`}
                                to={"/visitor-checkin"}
                                onClick={closeMenu}
                                className={`link ${classes.navLink}`}
                                activeClassName="selected"
                              >
                                {t("Visitor Check-In")}
                              </NavLink>
                            </div>
                          )}
                          {userStore?.me?.is_bhp_user &&
                            secondaryRoutes
                              .filter((rte) => rte.onNav === true)
                              .map(({ path, icon, name }, key) => (
                                <div key={`sbib${key}`} className={`sidebarItem`}>
                                  <NavLink
                                    key={`sbibLink${key}`}
                                    to={path}
                                    onClick={closeMenu}
                                    className={`link secondary ${classes.navLink}`}
                                    activeClassName="selected"
                                  >
                                    {t(name)}
                                  </NavLink>
                                </div>
                              ))}
                          {userStore?.me?.is_bhp_user && (
                            <div key={`sbib-1`} className="sidebarItem">
                              <NavLink
                                key={`sbibLink-1`}
                                to={"/settings"}
                                onClick={closeMenu}
                                className={`link secondary ${classes.navLink}`}
                                activeClassName="selected"
                              >
                                {t("Settings")}
                              </NavLink>
                            </div>
                          )}
                          <div key={`sbib-2`} className="sidebarItem">
                            <Link
                              key={`sbibLink-2`}
                              href={
                                "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/My-Workday-info-and-support-centre.aspx"
                              }
                              target="new"
                              // onClick={closeMenu}
                              className={"link secondary"}
                              //activeClassName="selected"
                            >
                              {t("Support")}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <Box
                        style={{
                          position: "absolute",
                          bottom: 6,
                          width: "100%",
                          textAlign: "center",
                          color: bhpColor.orange1,
                          fontSize: 10,
                        }}
                      >
                        {window.env.app_ver}
                      </Box>
                    </Drawer>
                  </div>
                ) : (
                  <div> </div>
                )}
                <div className="page">
                  <div className="content" style={{ height: "100%" }}>
                    <div style={{ position: "relative", zIndex: 100 }}>
                      {userStore?.me?.is_bhp_user && (
                        <AppBarWrapper t={t} i18n={i18n} toggleDrawer={toggleDrawer} />
                      )}
                    </div>
                    <div onClick={closeMenu} style={{ height: "100%" }}>
                      {primaryRoutes.map(({ path, component }, key) => (
                        <GuardedRoute path={path} key={key} component={component} />
                      ))}
                      {secondaryRoutes.map(({ path, component }, key) => (
                        <GuardedRoute path={path} key={key + 1000} component={component} />
                      ))}
                      <Route exact path="/settings">
                        <Settings />
                      </Route>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Route exact path="/">
              {userStore?.me?.is_bhp_user ? (
                <Redirect to="/dashboard" />
              ) : (
                <Redirect to="/visitor-checkin" />
              )}
            </Route>
          </Router>
        </HolidayContext.Provider>
      </ThemeProvider>
    </CssBaseline>
  ) : userStore.error ? (
    <p>
      Sorry - we are having trouble signing you in. Please contact support - The "My Workday" Team.
    </p>
  ) : (
    <></>
  );
});

export default Main;
