import { makeAutoObservable, flow, toJS } from "mobx";
import { find } from "lodash";
import moment from "moment";

import { defaults, Schedule, ScheduleType } from "interfaces/Schedule";
import RootStore from "./RootStore";
import client from "services/api";
import { startOfWeek } from "utils/date";
import { BasicBooking } from "utils/hourlyBookings";
import { Space } from "interfaces/Building";
import {
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
  getDateTimeFromStringsAndTimeZone,
  Period,
} from "utils/hourlyBookings";

export default class ScheduleStore {
  schedules: Schedule[] = [];
  isLoading = true;
  error = "";
  rootStore!: RootStore;
  isDirty = false;
  selectedUserSchedules: Schedule[] = [];

  noDeskBookingToday: null | boolean = null;

  // Going to use 2 modals and going to use this variable:
  // 1 to ask if they want to book
  // 1 if they had a booking and change their schedule
  scheduleChangingToOffice: {
    schedule: Schedule;
    plannedCapacity: number;
    bookedCapacity: number;
    remainingAvailableDesksPercent: number;
    totalCapacity: number;
    adjustedCapacity: number | null;
  } | null = null;
  scheduleChangingAwayFromOfficeWithBooking: {
    schedule: Schedule;
    status: ScheduleType;
    bookings: BasicBooking[];
  } | null = null;
  isChangingSchedule: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  get events(): { [id: string]: Schedule } {
    return this.schedules.reduce(
      (a: { [id: string]: Schedule }, x: Schedule) => ({ ...a, [`${x.date}`]: x }),
      {}
    );
  }
  get userEvents(): { [id: string]: Schedule } {
    return this.selectedUserSchedules.reduce(
      (a: { [id: string]: Schedule }, x: Schedule) => ({ ...a, [`${x.date}`]: x }),
      {}
    );
  }
  get bookingSchedules(): Schedule[] {
    const maxDate = new Date();
    // 14 days max for booking
    // RS: 20102021 - WDP-389 show 15th day
    maxDate.setDate(maxDate.getDate() + 15);
    return this.schedules.filter((schedule) => new Date(schedule.date) <= maxDate);
  }

  scheduleForDay(day: string): Schedule {
    const schedule = this.events[day];
    if (schedule) {
      return schedule;
    }
    // default if the data in the db is not populated. Should never happen
    return {
      status: ScheduleType.OFFICE,
      date: day,
      space: null,
      office: null,
    };
  }

  userScheduleForDay(day: string): Schedule {
    const schedule = this.userEvents[day];
    if (schedule) {
      return schedule;
    }
    // default if the data in the db is not populated. Should never happen
    return {
      status: ScheduleType.OFFICE,
      date: day,
      space: null,
      office: null,
    };
  }

  getScheduleForDay(day: string): Schedule {
    const schedule = this.schedules.find((sch) => sch.date === day);
    if (schedule) {
      return schedule;
    }
    // default if the data in the db is not populated. Should never happen
    return {
      status: ScheduleType.OFFICE,
      date: day,
      space: null,
      office: null,
    };
  }

  loadSchedules = flow(function* (this: ScheduleStore, returnEmptyTimes?: boolean) {
    if (this.schedules.length && !this.isDirty) {
      // we already have the data - lets skip
      return;
    }
    this.isDirty = false;
    this.isLoading = true;
    try {
      const startDate = startOfWeek(new Date());
      const res = yield client.get(
        `/api/myschedule/?date__gte=${moment(startDate).format("YYYY-MM-DD")}`
      );
      this.schedules = res.data.results.map((schedule: Schedule) => {
        const deskHours =
          schedule.office && schedule.office.building_timezone
            ? {
                start: getDateTimeFromStringsAndTimeZone(
                  schedule.date,
                  schedule.desk_start_time
                    ? schedule.desk_start_time
                    : returnEmptyTimes
                    ? ""
                    : DEFAULT_START_TIME,
                  schedule.office.building_timezone
                ),
                end: getDateTimeFromStringsAndTimeZone(
                  schedule.date,
                  schedule.desk_finish_time
                    ? schedule.desk_finish_time
                    : returnEmptyTimes
                    ? ""
                    : DEFAULT_END_TIME,
                  schedule.office.building_timezone
                ),
              }
            : null;
        const theSchedule = {
          ...schedule,
          desk_start_time:
            schedule.desk_start_time && schedule.desk_start_time !== ""
              ? schedule.desk_start_time
              : returnEmptyTimes
              ? ""
              : DEFAULT_START_TIME,
          desk_finish_time:
            schedule.desk_finish_time && schedule.desk_finish_time !== ""
              ? schedule.desk_finish_time
              : returnEmptyTimes
              ? ""
              : DEFAULT_END_TIME,
          deskHours: deskHours,
          updating: false,
        };
        return { ...theSchedule };
      });
      this.isLoading = false;
      return res.data.results;
    } catch (err) {
      this.error = (err as Error).message;
      this.isLoading = false;
      return {};
    }
  });

  // this is purely front end, to keep track of booking
  // period changes made on interactive floorplan
  setDeskHours(date: String, deskHours: Period, timeZone: string | null) {
    this.schedules.map((sch) => {
      if (sch.date === date) {
        sch.deskHours = { ...deskHours };
        if (timeZone) {
          sch.desk_start_time = moment(deskHours.start).tz(timeZone).format("HH:mm");
          sch.desk_finish_time = moment(deskHours.end).tz(timeZone).format("HH:mm");
        }
      }
    });
    this.forceDataReload();
  }

  loadSchedulesForSelectedUser = flow(function* (this: ScheduleStore, userId: number, date: Date) {
    this.isLoading = true;
    try {
      const res = yield client.get(
        `/api/schedules/users/${userId}/?date__gte=${moment(date)
          .add(-1, "d")
          .utc()
          .format("YYYY-MM-DD")}&date__lte=${moment(date).add(8, "d").utc().format("YYYY-MM-DD")}`
      );
      this.selectedUserSchedules = res.data.results.map((schedule: Schedule) => {
        const deskHours =
          schedule.office && schedule.office.building_timezone
            ? {
                start: getDateTimeFromStringsAndTimeZone(
                  schedule.date,
                  schedule.desk_start_time ? schedule.desk_start_time : DEFAULT_START_TIME,
                  schedule.office.building_timezone
                ),
                end: getDateTimeFromStringsAndTimeZone(
                  schedule.date,
                  schedule.desk_finish_time ? schedule.desk_finish_time : DEFAULT_END_TIME,
                  schedule.office.building_timezone
                ),
              }
            : null;
        return {
          ...schedule,
          desk_start_time:
            schedule.desk_start_time && schedule.desk_start_time !== ""
              ? schedule.desk_start_time
              : DEFAULT_START_TIME,
          desk_finish_time:
            schedule.desk_finish_time && schedule.desk_finish_time !== ""
              ? schedule.desk_finish_time
              : DEFAULT_END_TIME,
          deskHours: deskHours,
          updating: false,
        };
      });
      this.isLoading = false;
    } catch (err) {
      this.error = (err as Error).message;
      this.isLoading = false;
    }
  });

  forceDataReload() {
    this.isDirty = true;
  }

  changeSchedule = flow(function* (
    this: ScheduleStore,
    date: string,
    scheduleType: ScheduleType,
    spaceId?: number | null
  ) {
    this.isChangingSchedule = true;
    const schedule = find(this.schedules, (s: Schedule) => s.date === date);
    if (schedule) {
      schedule.updating = true;
    }
    const data: any = {
      date,
      status: scheduleType,
    };
    if (spaceId) {
      if (!schedule?.office) {
        data.office = this.rootStore.userStore.defaultOffice;
        data.office_id = this.rootStore.userStore.defaultOffice?.id;
      }
      data.space_id = spaceId;
    } else {
      data.space_id = null;
    }
    try {
      yield schedule
        ? client.put(`/api/myschedule/${date}/`, data)
        : client.post(`/api/myschedule/`, data);

      if (schedule) {
        if (scheduleType === ScheduleType.OFFICE && !spaceId) {
          // check if we are allowed to book a desk(not too far ahead)
          let bookedCapacity,
            plannedCapacity,
            remainingAvailableDesksPercent,
            totalCapacity,
            adjustedCapacity;

          try {
            const capacityRes = yield client.get(
              `/api/building/buildings/date/${date}/capacity/?id=${this.rootStore.userStore.defaultOffice?.id}`
            );
            bookedCapacity = capacityRes.data.results[0].booked_attendance;
            plannedCapacity = capacityRes.data.results[0].planned_attendance;
            remainingAvailableDesksPercent =
              capacityRes.data.results[0].remaining_availibility_percentage;
            totalCapacity = capacityRes.data.results[0].total_capacity;
            adjustedCapacity = capacityRes.data.results[0].adjusted_capacity;
          } catch (err) {
            console.log(err);
          }
          schedule.office = this.rootStore.userStore.defaultOffice;
          this.scheduleChangingToOffice = {
            schedule: schedule,
            bookedCapacity: bookedCapacity,
            plannedCapacity: plannedCapacity,
            remainingAvailableDesksPercent: remainingAvailableDesksPercent,
            totalCapacity: totalCapacity,
            adjustedCapacity: adjustedCapacity,
          };
        }
        // Todo: update schedule from api - at the moment space is missing
        this.isChangingSchedule = false;
        this.forceDataReload();
        this.loadSchedules();
        schedule.status = scheduleType;
        schedule.updating = false;
      } else {
        this.forceDataReload();
        this.loadSchedules();
      }
    } catch (err) {
      if (schedule) {
        schedule.updating = false;
      }
      throw err;
    }
    this.isChangingSchedule = false;
  });

  changeHoursLocation = flow(function* (
    this: ScheduleStore,
    date: string,
    start_time: string,
    finish_time: string,
    desk_start_time: string,
    desk_finish_time: string,
    office_id: number | null
  ) {
    const schedule = find(this.schedules, (s: Schedule) => s.date === date);
    const existingSchedule: any = {
      status: schedule?.status,
      start_time: schedule?.start_time,
      finish_time: schedule?.finish_time,
      office_id: schedule?.office?.id,
      space_id: schedule?.space?.id,
      desk_start_time:
        schedule?.desk_start_time && schedule?.desk_start_time !== ""
          ? schedule.desk_start_time
          : DEFAULT_START_TIME,
      desk_finish_time:
        schedule?.desk_finish_time && schedule?.desk_finish_time
          ? schedule.desk_finish_time
          : DEFAULT_END_TIME,
    };
    const newSchedule: any = {
      status: schedule?.status,
      start_time: start_time,
      finish_time: finish_time,
      office_id: office_id,
      space_id: schedule?.space?.id || null,
      desk_start_time:
        desk_start_time && desk_start_time !== "" ? desk_start_time : DEFAULT_START_TIME,
      desk_finish_time:
        desk_finish_time && desk_finish_time !== "" ? desk_finish_time : DEFAULT_END_TIME,
    };

    if (JSON.stringify(existingSchedule) !== JSON.stringify(newSchedule)) {
      if (existingSchedule.office_id !== newSchedule.office_id || schedule?.space === null) {
        newSchedule.space_id = null;
      }

      if (schedule) schedule.updating = true;

      yield client.put(`/api/myschedule/${date}/`, newSchedule);
      this.forceDataReload();
      if (schedule) schedule.updating = false;
    }
  });

  cancelDesk = flow(function* (this: ScheduleStore, date: string) {
    const schedule = find(this.schedules, (s: Schedule) => s.date === date);
    const data: any = {
      status: ScheduleType.OFFICE,
      space_id: null,
    };
    yield client.put(`/api/myschedule/${date}/`, data);
    // todo: update schedule from the api request when it gets returned
    this.isDirty = true;
    if (schedule) {
      schedule.space = null;
    }
  });

  setNoDeskBookingToday = (noDeskBookingToday: boolean | null) => {
    this.noDeskBookingToday = noDeskBookingToday;
  };
}
