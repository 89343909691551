import { observer } from "mobx-react-lite";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { bhpColor } from "styles/globals";

import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { useScheduleStore, useUserStore } from "providers/RootStoreProvider";
import { Schedule } from "interfaces/Schedule";
import config from "config";
import { BuildingType } from "interfaces/Building";

interface ConfirmationProps {
  t: any;
}

const BookSpaceRequiredConfirm = observer((props: ConfirmationProps) => {
  const { t } = props;
  const scheduleStore = useScheduleStore();
  const userStore = useUserStore();
  const history = useHistory();

  const bookSpaceNotRequired = () => (scheduleStore.scheduleChangingToOffice = null);
  const bookSpaceRequired = () =>
    history.push(
      `/booking/${moment(scheduleStore.scheduleChangingToOffice!.schedule.date).format(
        "YYYY-MM-DD"
      )}`
    );
  const isScheduleTooFar = (schedule: Schedule): boolean => {
    return (
      moment(schedule.date, "YYYY-MM-DD").diff(moment().format("YYYY-MM-DD"), "days") >
      config.workday.numberOfDaysBookable + 1
    );
  };

  return (
    <>
      {scheduleStore.scheduleChangingToOffice &&
        scheduleStore.scheduleChangingToOffice.schedule.office?.building_type ===
          BuildingType.OFFICE && (
          <ConfirmDialog
            isOpen={
              scheduleStore.scheduleChangingToOffice !== null &&
              !isScheduleTooFar(scheduleStore.scheduleChangingToOffice.schedule)
            }
            title={t("Do you need a desk?")}
            primaryButtonText={t("Book a desk")}
            secondaryButtonText={t("Not right now")}
            handleSecondaryClick={bookSpaceNotRequired}
            handlePrimaryClick={bookSpaceRequired}
          >
            <strong>
              {userStore.defaultOffice
                ? `${userStore.defaultOffice.city}, ${userStore.defaultOffice.building_name}`
                : t("Your default office")}
            </strong>{" "}
            {t("is currently at")}{" "}
            <Box component="span" fontWeight="bold" color={bhpColor.orange1}>
              {Math.round(
                100 - scheduleStore.scheduleChangingToOffice!.remainingAvailableDesksPercent
              )}
              {t("%")} {t("booked")}
            </Box>{" "}
            {t("with")}{" "}
            <Box component="span" fontWeight="bold" color={bhpColor.orange1}>
              {Math.ceil(
                (scheduleStore.scheduleChangingToOffice!.plannedCapacity /
                  scheduleStore.scheduleChangingToOffice!.totalCapacity) *
                  100
              )}
              {t("%")} {t("planned attendance")}
            </Box>{" "}
            {t("on")}{" "}
            <Box component="span" fontWeight="bold">
              {scheduleStore.scheduleChangingToOffice
                ? `${t(
                    moment(scheduleStore.scheduleChangingToOffice!.schedule.date).format("dddd")
                  )} ${t(
                    moment(scheduleStore.scheduleChangingToOffice!.schedule.date).format("Do")
                  )} ${t(
                    moment(scheduleStore.scheduleChangingToOffice!.schedule.date).format("MMMM")
                  )}`
                : ""}
            </Box>
            .{" "}
            {scheduleStore.scheduleChangingToOffice.adjustedCapacity &&
            scheduleStore.scheduleChangingToOffice.adjustedCapacity < 100
              ? t(
                  "Capacity restriction has been applied to this location. Adjusted percentage capacity measure displayed."
                )
              : ""}
          </ConfirmDialog>
        )}
    </>
  );
});

export default BookSpaceRequiredConfirm;
