import { observer } from "mobx-react-lite";
import InformationIcon from "components/utils/InformationIcon";
import { bhpColor } from "styles/globals";
import { useState } from "react";
import { PopupInformationWidget } from "components/settings/PopupInformationWidget";
import { Holiday } from "interfaces/Utils";
import moment from "moment";
import { generateHolidayInformationModalContent } from "utils/date";
import { useMediaQuery, useTheme } from "@material-ui/core";
interface HolidayPopupProps {
  t: any;
  holidays: Holiday[] | null;
  date: string;
}

interface DialogData {
  title: string | null;
  content: string | null;
  primaryButtonText: string | null;
}

const HolidayPopup = observer((props: HolidayPopupProps) => {
  const { holidays, t, date } = props;
  const [informationModal, setInformationModal] = useState<DialogData | null>();
  const [informationOpen, setInformationOpen] = useState(false);
  const handleInformationClose = () => setInformationOpen(false);
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));

  const handleInformationOpen = () => {
    const informationDialogData: DialogData = {
      title: `${moment(date).format("dddd")}, ${`${t(moment(date).format("Do"))} ${t(
        moment(date).format("MMMM")
      )}`}`,
      content: `${generateHolidayInformationModalContent(holidays || [])}`,
      primaryButtonText: "Okay",
    };

    openInformationModal(informationDialogData);
  };
  const openInformationModal = (informationData: DialogData) => {
    setInformationModal(Object.assign({}, informationData));
    setInformationOpen(true);
  };

  return (
    <>
      <PopupInformationWidget
        t={t}
        isOpen={informationOpen}
        handleClose={handleInformationClose}
        handleSecondaryClick={handleInformationClose}
        title={informationModal?.title || null}
        content={informationModal?.content || null}
        buttonText={informationModal?.primaryButtonText || null}
        secondaryButtonText={null}
        hidePrimaryButton={true}
      />
      {holidays && smAndUp && holidays.length > 0 && (
        <InformationIcon
          onclick={() => handleInformationOpen()}
          color={bhpColor.orange1}
          filled={true}
        />
      )}
    </>
  );
});

export default HolidayPopup;
