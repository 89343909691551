import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { bhpColor } from "styles/globals";
import { BuildingType, BuildingWithCapacity } from "interfaces/Building";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { useEffect, useRef, useState } from "react";
import { useRootStore } from "providers/RootStoreProvider";
import {
  BasicBooking,
  BasicBookingLite,
  DEFAULT_END_TIME,
  DEFAULT_OFFICE_END_TIME,
  DEFAULT_OFFICE_START_TIME,
  getDateTimeAtStartOfToday,
  groupByHour,
} from "utils/hourlyBookings";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChartCanvasWrapper from "./ChartCanvasWrapper";
import {
  drawLabel,
  drawLine,
  drawStar,
  drawText,
  etchCircle,
  getHourString,
} from "components/booking/Floorplan/Drawing";
import { ContactlessOutlined } from "@material-ui/icons";
import {
  axisColour,
  axisWidth,
  bookableSpacesLineColour,
  defaultLineColour,
  defaultLineStyle,
  defaultLineWidth,
  defaultTextAlign,
  defaultTextColour,
  defaultTextSize,
  dotSize,
  drawOfficeHoursBlock,
  fillColour,
  getBookingValue,
  getBookingValueLite,
  getTeamBookings,
  gridColour,
  gridWidth,
  gutterBottom,
  gutterLeft,
  gutterRight,
  gutterTop,
  lineColour,
  teamLineColour,
} from "./DrawingParts";
import { Team } from "interfaces/Teams";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: bhpColor.blueGrey1,
      display: "flex",
      "&.padded": {
        padding: "12px",
      },
      borderRadius: "4px",
      backgroundColor: "transparent",
      alignItems: "center",
    },
    officeLocation: {
      textAlign: "start",
      color: bhpColor.grey1,
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        alignItems: "end",
      },
    },
    detailPanel: {
      display: "flex",
      justifyContent: "center",
    },
    fullPanel: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: bhpColor.backgroundGrey,
      borderRadius: "4px",
      marginTop: "12px",
      padding: "8px",
    },
    largeNumber: {
      fontSize: "35px",
      fontWeight: 700,
      color: bhpColor.orange1,
      height: "40px",
      display: "flex",
      alignItems: "center",
    },
    title: {
      fontSize: "14px",
      fontWeight: 700,
      justifyContent: "start",
      textAlign: "start",
      color: bhpColor.grey1,
      paddingTop: "4px",
      paddingBottom: "4px",
      "&.wrap": {
        whiteSpace: "pre-line",
      },
    },
    icon: {
      color: bhpColor.grey1,
    },
    toolTipBox: {
      position: "absolute",
      backgroundColor: "#66666690",
      color: bhpColor.white,
      paddingLeft: 4,
      paddingRight: 4,
      borderRadius: `4px`,
      fontSize: "12px",
    },
  })
);

interface GraphicCapacityChartProps {
  buildingDetail: BuildingWithCapacity | null;
  date: Date;
  t: any;
  is12hr?: boolean;
  chartWidth: number;
  chartHeight: number;
  teamMembers: number[];
  team: Team | null;
}

const GraphicCapacityChart = observer((props: GraphicCapacityChartProps) => {
  const {
    buildingDetail,
    date,
    t,
    is12hr = true,
    chartWidth,
    chartHeight,
    teamMembers,
    team,
  } = props;
  const classes = innerStyles();
  const store = useRootStore();

  const range = { red: 89, amber: 69 };

  const getIndicatorColor = (percentage: number) => {
    return percentage > range.red
      ? bhpColor.availabilityRed
      : percentage > range.amber
      ? bhpColor.availabilityAmber
      : percentage === 0
      ? bhpColor.white
      : bhpColor.available;
  };

  const [context, setContext] = useState();
  const theContext = (ctx: any) => setContext(ctx);
  const canvasWidth = chartWidth;
  const [canvasHeight, setCanvasHeight] = useState(chartHeight);

  const [bookableDesks, setBookableDesks] = useState(buildingDetail?.total_capacity || 1);
  const nearest100 = Math.ceil(bookableDesks / 100) * 100;
  const roundTo =
    nearest100 - bookableDesks > 50
      ? nearest100 - 50
      : nearest100 - bookableDesks < 50
      ? nearest100 + 50
      : nearest100;

  // low numbers locally, so apply a factor to get visable values
  const isLocal = false; //window.env.app_env === "LOCAL" ? true : false;
  const debugFudgeFactor = isLocal ? roundTo : 1;
  const debugLiftOffset = isLocal ? 0 : 0;

  const drawingWidth = canvasWidth - gutterLeft - gutterRight;
  const drawingHeight = canvasHeight - gutterTop - gutterBottom;

  const buildingCapacity = buildingDetail?.total_capacity || 1;
  const capacityAtTopOfDrawing = Math.ceil(buildingCapacity / roundTo) * roundTo;

  const [spacing, setSpacing] = useState(Math.ceil(drawingWidth / (is12hr ? 14 : 25)));
  const unitSize = drawingHeight / capacityAtTopOfDrawing;

  const spacingRef = useRef<number>(0);
  spacingRef.current = spacing;

  const is12hrRef = useRef<boolean>(is12hr);
  is12hrRef.current = is12hr;

  const teamMemRef = useRef<number[]>([]);
  teamMemRef.current = teamMembers;

  const origin = { x: gutterLeft, y: canvasHeight - gutterBottom };

  const [hourGroupings, setHourGroupings] = useState<{ [hr: string]: BasicBookingLite[] }>({});
  const [enhancedGroupings, setEnhancedGroupings] = useState<{
    [hr: string]: {
      // teamMembers: number;
      total_bookings: number;
      bookings: BasicBookingLite[];
    };
  }>({});

  const enhancedGroupingsRef = useRef<{
    [hr: string]: {
      // teamMembers: number;
      total_bookings: number;
      bookings: BasicBookingLite[];
    };
  }>({});
  enhancedGroupingsRef.current = enhancedGroupings;

  const [startIndex, setStartIndex] = useState<number>(is12hr ? 7 : 0);
  const [endIndex, setEndIndex] = useState<number>(is12hr ? 19 : 24);

  useEffect(() => {
    setStartIndex(is12hr ? 7 : 0);
    setEndIndex(is12hr ? 19 : 24);
    setSpacing(Math.ceil(drawingWidth / (is12hr ? 14 : 25)));
  }, [is12hr]);

  useEffect(() => {
    if (buildingDetail) {
      const startOfToday = getDateTimeAtStartOfToday(date);
      let endOfToday = new Date(startOfToday);
      endOfToday.setDate(endOfToday.getDate() + 1);
      store.bookingStore
        .loadBuildingBookingsLite(
          { start: startOfToday, end: endOfToday },
          buildingDetail.id.toString()
        )
        .then(() => {
          const theBookings =
            store.bookingStore.building_bookings_lite[buildingDetail.id.toString()];
          if (theBookings) {
            const groupedBookings = groupByHour(date, theBookings);
            let enhancedGroup: {
              [hr: string]: {
                // teamMembers: number;
                total_bookings: number;
                bookings: BasicBookingLite[];
              };
            } = {};
            Object.keys(hourGroupings).forEach((key, idx) => {
              const thisHour = hourGroupings[key];
              const numBookings = getBookingValueLite(key, hourGroupings, isLocal);
              enhancedGroup[key] = {
                // teamMembers: isLocal
                //   ? Math.floor(Math.random() * numBookings)
                //   : getTeamBookings(key, teamMemRef.current.slice(), hourGroupings),
                total_bookings: numBookings,
                bookings: thisHour,
              };
            });
            setEnhancedGroupings(enhancedGroup);
            setHourGroupings(groupedBookings);
          }
        });
    }
  }, [buildingDetail, startIndex, endIndex, date]);

  const placeText = (
    ctx: any,
    txt: string,
    x: number,
    y: number,
    align: string = defaultTextAlign,
    size: number = defaultTextSize,
    colour: string = defaultTextColour,
    isBold: boolean = false,
    bgColour?: string,
    bdrColour?: string
  ) => {
    drawText(
      ctx,
      `${txt}`,
      gPt(x, null),
      gPt(null, y),
      align,
      colour,
      bgColour || null,
      bdrColour || null,
      size,
      isBold
    );
  };

  /**
   * return a corrected coord.
   * canvas origin top left, x from left, y: from top - not bottom
   * @param x
   * @param y
   * @returns currectedX or Y
   */
  const gPt = (x: number | null, y: number | null) => {
    let res = x !== null ? origin.x + x : y !== null ? drawingHeight - y + gutterTop : 0;
    return res;
  };

  const solidLine = (
    ctx: any,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    width: number = defaultLineWidth,
    colour: string = defaultLineColour,
    style: string = defaultLineStyle
  ) => {
    drawLine(ctx, gPt(x1, null), gPt(null, y1), gPt(x2, null), gPt(null, y2), width, colour, style);
  };

  const draw = (ctx: any) => {
    // clear canvas
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    // fill drawing area
    ctx.fillStyle = bhpColor.white;
    ctx.fillRect(gPt(0, null), gutterTop, drawingWidth, drawingHeight);

    // draw office hours block
    drawOfficeHoursBlock(
      ctx,
      buildingDetail,
      is12hr,
      gutterLeft,
      gutterTop,
      drawingHeight,
      spacing
    );

    //draw bookable desks line
    const buildingCapacityScaled = Math.ceil(buildingCapacity * unitSize);
    solidLine(
      ctx,
      0,
      buildingCapacityScaled,
      drawingWidth,
      buildingCapacityScaled,
      1,
      bookableSpacesLineColour,
      "dashed"
    );

    //draw grid
    for (let i = 0; i < capacityAtTopOfDrawing; i = i + capacityAtTopOfDrawing / 5) {
      placeText(ctx, `${i}`, -10, i * unitSize + defaultTextSize, "right");
      solidLine(ctx, -5, i * unitSize, drawingWidth, i * unitSize, gridWidth, gridColour);
    }
    // label maximum bookable desks
    placeText(ctx, `${capacityAtTopOfDrawing}`, -10, drawingHeight + defaultTextSize / 2, "right");
    placeText(ctx, `Bookable Desks (${bookableDesks})`, 0, buildingCapacityScaled + 20, "left");

    if (team) {
      const teamLabel = `${team.name} bookings`;
      drawText(
        ctx,
        teamLabel,
        gutterLeft,
        gutterTop - defaultTextSize * 1.5,
        "left",
        teamLineColour,
        null,
        null
      );
      const fontWidth = 7;
      const lineStart = gutterLeft + fontWidth * teamLabel.length;
      drawLine(
        ctx,
        lineStart,
        gutterTop - defaultTextSize + 3,
        lineStart + 100,
        gutterTop - defaultTextSize + 3,
        1,
        teamLineColour
      );
    }

    // draw axis
    solidLine(ctx, 0, 0, 0, drawingHeight, axisWidth, axisColour);
    solidLine(ctx, 0, 0, drawingWidth, 0, axisWidth, axisColour);
    const yAxis0 = drawingHeight + gutterTop;
    let column = 0;
    Object.keys(hourGroupings).forEach((key, idx) => {
      if (idx >= startIndex && idx <= endIndex) {
        // axis labels
        const xPos = gPt(spacing * (column + 1), null);
        if (column % 2 != 0) drawLine(ctx, xPos, yAxis0, xPos, yAxis0 + 5, 1, bhpColor.blueGrey3);
        else
          drawText(
            ctx,
            getHourString(Number(key)),
            xPos,
            yAxis0,
            "center",
            defaultTextColour,
            null,
            null
          );
        column++;
      }
    });

    let lastX: number | null = null;
    let lastY: number | null = null;
    let lastVal: number = 0;
    column = 0;

    let lastTeamY: number | null = null;
    let lastTeamVal: number = 0;

    //first pass draw lines if in the display area
    Object.keys(hourGroupings).forEach((key, idx) => {
      if (idx >= startIndex && idx <= endIndex) {
        let bookingsThisPoint = enhancedGroupings[key] ? enhancedGroupings[key].total_bookings : 0;
        // let teamBookingsThisPoint = enhancedGroupings[key] ? enhancedGroupings[key].teamMembers : 0;
        let teamBookingsThisPoint = 0;

        const xPos = gPt(spacing * (column + 1), null);
        const yPos = gPt(null, (bookingsThisPoint + debugLiftOffset) * unitSize);
        const yTeamPos = gPt(null, (teamBookingsThisPoint + debugLiftOffset) * unitSize);

        // if the last point wasn't 0 draw a line
        if (lastX && lastY && lastVal !== 0 && bookingsThisPoint !== 0)
          drawLine(ctx, lastX, lastY, xPos, yPos, 2, lineColour);

        // if the last team point wasn't 0 draw a line
        if (lastX && lastTeamY && lastTeamVal !== 0 && teamBookingsThisPoint !== 0)
          drawLine(ctx, lastX, lastTeamY, xPos, yTeamPos, 1, teamLineColour);

        lastX = xPos;
        lastY = yPos;
        lastVal = bookingsThisPoint;

        lastTeamY = yTeamPos;
        lastTeamVal = teamBookingsThisPoint;
        column++;
      }
    });

    lastX = null;
    lastY = null;
    lastVal = 0;

    column = 0;
    //second pass draw dots
    Object.keys(hourGroupings).forEach((key, idx) => {
      let bookingsThisPoint = enhancedGroupings[key] ? enhancedGroupings[key].total_bookings : 0;
      const xPos = gPt(spacing * (column + 1), null);
      const yPos = gPt(null, (bookingsThisPoint + debugLiftOffset) * unitSize);

      if (idx >= startIndex && idx <= endIndex) {
        // if mouseOver then highlight
        const theColor = hotColumn == Number(key) ? bhpColor.grey1 : fillColour;

        //if this point isn't 0 draw a spot
        if (bookingsThisPoint !== 0) etchCircle(ctx, xPos, yPos, dotSize, 1, null, null, theColor);

        if (bookingsThisPoint !== 0)
          drawText(
            ctx,
            `${bookingsThisPoint}`,
            xPos - defaultTextSize / 2,
            yPos + defaultTextSize,
            "center",
            bhpColor.orange1,
            "#FFFFFF75",
            null
          );

        lastX = xPos;
        lastY = yPos;
        lastVal = bookingsThisPoint;
        column++;
      }
    });
  };

  const [hotColumn, setHotColumn] = useState(-1);

  const [hotspotPosition, setHotspotPosition] = useState<{
    x: number;
    y: number;
    val: number | null;
  }>({ x: -1000, y: 100, val: 0 });

  const tooltipBox = () => {
    const tooltipOffset = 15;
    const letterWidth = 7;
    const hotspotText = `${hotspotPosition.val} team members`;
    const leftOffset = (hotspotText.length / 2) * letterWidth;

    return (
      <div
        className={classes.toolTipBox}
        style={{
          top: hotspotPosition.y - tooltipOffset,
          left: hotspotPosition.x - leftOffset,
        }}
      >
        {hotspotText}
      </div>
    );
  };

  //TODO add team selector

  /** checkMouseLocation & set hotspot
   * @param mouseX - evt.offsetX
   * @param mouseY - evt.offsetY
   * @param ctx - canvas context
   */
  const checkMouseLocation = (mouseX: number, mouseY: number, ctx: any) => {
    const columnSpacing = spacingRef.current;
    const is12hour = is12hrRef.current;
    const enhancedHourly = enhancedGroupingsRef.current;
    if (mouseX > gutterLeft && mouseX < drawingWidth + gutterLeft) {
      let columnIn: number | null = Math.round((mouseX - gutterLeft) / columnSpacing);
      if (is12hour && columnIn > 13) columnIn = null;
      if (columnIn && columnIn < 1) columnIn = null;
      if (columnIn) {
        const hr = is12hour ? columnIn + 6 : columnIn - 1;
        const enhancedData = enhancedHourly[`${hr}`];
        const bookingsInColumn = enhancedData ? enhancedData.total_bookings : 0;
        const yPos = gPt(null, bookingsInColumn);
        const vDist = Math.abs(mouseY - yPos);
        if (vDist < 20 && bookingsInColumn > 0) {
          const xPos = columnSpacing * columnIn + gutterLeft;
          ctx.canvas.style.cursor = "pointer";
          setHotspotPosition({
            x: xPos,
            y: yPos,
            val: 0,
          });
          setHotColumn(hr);
        } else {
          ctx.canvas.style.cursor = "default";
          setHotspotPosition({ x: -1000, y: 100, val: null });
          setHotColumn(-1);
        }
      } else {
        ctx.canvas.style.cursor = "default";
        setHotspotPosition({ x: -1000, y: 100, val: null });
        setHotColumn(-1);
      }
    }
  };

  return (
    <Grid container className={classes.root} style={{ position: "relative" }}>
      {/* {tooltipBox()} */}
      <ChartCanvasWrapper
        setcontext={theContext}
        draw={draw}
        redraw={hotColumn}
        height={canvasHeight}
        width={canvasWidth}
        mousemove={checkMouseLocation}
        addListeners={true}
      />
    </Grid>
  );
});

export default GraphicCapacityChart;
