import { drawText } from "components/booking/Floorplan/Drawing";
import { BuildingWithCapacity } from "interfaces/Building";
import { bhpColor } from "styles/globals";
import {
  BasicBooking,
  BasicBookingLite,
  DEFAULT_OFFICE_END_TIME,
  DEFAULT_OFFICE_START_TIME,
} from "utils/hourlyBookings";

export const gutterTop = 50;
export const gutterBottom = 100;
export const gutterLeft = 150;
export const gutterRight = 100;

export const dotSize = 5;
export const teamLineColour = bhpColor.green1;
export const lineColour = bhpColor.orange1;
export const fillColour = bhpColor.orange1;
export const defaultLineWidth = 1;
export const defaultLineColour = bhpColor.blueGrey1;
export const defaultLineStyle = "solid";

export const defaultTextSize = 12;
export const defaultTextColour = bhpColor.blueGrey1;
export const defaultTextAlign = "left";
export const defaultTextBackgroundColour = "rgb(255,255,255)";
export const defaultTextBorderColour = "rgb(0,0,0)";

export const axisWidth = 1;
export const axisColour = bhpColor.blueGrey1;
export const gridWidth = 1;
export const gridColour = bhpColor.blueGrey4;

export const bookableSpacesLineColour = bhpColor.blueGrey1;

/**
 * return a corrected coord.
 * canvas origin top left, x from left, y: from top - not bottom
 * @param x
 * @param y
 * @returns currectedX or Y
 */
export const gPt = (
  x: number | null,
  y: number | null,
  drawingHeight: number,
  origin: { x: number; y: number }
) => {
  let res = x !== null ? origin.x + x : y !== null ? drawingHeight - y + gutterTop : 0;
  return res;
};

export const getOfficeTime_HoursFromMidnight = (time24Hour: string) => {
  const hrPart = Number(time24Hour.split(":")[0]);
  const mnPart = Number(time24Hour.split(":")[1]) / 60;
  return hrPart + mnPart;
};

export const drawOfficeHoursBlock = (
  ctx: any,
  buildingDetail: BuildingWithCapacity | null,
  is12hr: boolean,
  gutterLeft: number,
  gutterTop: number,
  drawingHeight: number,
  spacing: number
) => {
  if (!buildingDetail) return;
  const officeStartHours = getOfficeTime_HoursFromMidnight(
    buildingDetail?.office_start_time || DEFAULT_OFFICE_START_TIME
  );
  const officeEndHours = getOfficeTime_HoursFromMidnight(
    buildingDetail?.office_end_time || DEFAULT_OFFICE_END_TIME
  );

  let officeHoursStartColumn = officeStartHours + 1;
  let officeHoursEndColumn = officeEndHours + 1;
  let officeHoursDisplayLength = officeHoursEndColumn - officeHoursStartColumn;
  let displayOfficeHours = true;

  if (is12hr) {
    if (officeStartHours < 7) {
      officeHoursStartColumn = 0;
      officeHoursDisplayLength = officeEndHours - 6;
    }
    if (officeStartHours == 7) {
      officeHoursStartColumn = 1;
      officeHoursDisplayLength = officeEndHours - 6;
    }
    if (officeStartHours > 7 && officeStartHours < 19) {
      officeHoursStartColumn = officeStartHours - 6;
    }
    if (officeEndHours == 19) {
      officeHoursDisplayLength = 13 - officeHoursStartColumn;
    }
    if (officeEndHours > 19) {
      officeHoursDisplayLength = 14 - officeHoursStartColumn;
    }
    if (officeHoursEndColumn > 12) officeHoursEndColumn = 14;
    if (
      (officeStartHours > 19 && officeEndHours > 19) ||
      (officeStartHours < 7 && officeEndHours < 7)
    )
      displayOfficeHours = false;
  }

  if (displayOfficeHours) {
    ctx.fillStyle = `${bhpColor.blueGrey4}50`;
    ctx.fillRect(
      gutterLeft + officeHoursStartColumn * spacing,
      gutterTop,
      officeHoursDisplayLength * spacing,
      drawingHeight + 50
    );
    const centerPointForLabel = Math.ceil(
      (officeHoursDisplayLength / 2 + officeHoursStartColumn) * spacing + gutterLeft
    );
    drawText(
      ctx,
      "Office Hours",
      centerPointForLabel,
      drawingHeight + gutterTop + 20,
      "center",
      defaultTextColour,
      null,
      null,
      14,
      true
    );
  }
};

export const getTeamBookings = (
  key: string,
  theMembers: number[],
  groupings: { [hr: string]: BasicBooking[] }
) => {
  if (!groupings[key]) return 0;
  const teamBookings = groupings[key].filter((bkg) => theMembers.includes(bkg.user_id || -1));
  const retVal = teamBookings ? teamBookings.length : 0;
  return retVal;
};

export const getBookingValue = (
  key: string,
  groupings: { [hr: string]: BasicBooking[] },
  isLocal: boolean = false
) => {
  const idx = Number(key);
  if (isLocal) {
    if (idx === 2) return 25;
    if (idx === 3) return 30;
    if (idx === 5) return 20;
    if (idx === 7) return 60;
    if (idx === 8) return 86;
    if (idx === 9) return 91;
    if (idx === 10) return 89;
    if (idx === 11) return 91;
    if (idx === 12) return 91;
    if (idx === 13) return 88;
    if (idx === 14) return 87;
    if (idx === 15) return 90;
    if (idx === 16) return 62;
    if (idx === 17) return 64;
    if (idx === 18) return 50;
    if (idx === 19) return 46;
  }
  if (!groupings[key]) return 0;
  const retVal = groupings[key].length;
  return retVal;
};

export const getTeamBookingsLite = (
  key: string,
  theMembers: number[],
  groupings: { [hr: string]: BasicBookingLite[] }
) => {
  if (!groupings[key]) return 0;
  // const teamBookings = groupings[key].filter((bkg) => theMembers.includes(bkg.user_id || -1));
  // const retVal = teamBookings ? teamBookings.length : 0;
  // return retVal;
};

export const getBookingValueLite = (
  key: string,
  groupings: { [hr: string]: BasicBookingLite[] },
  isLocal: boolean = false
) => {
  const idx = Number(key);
  if (isLocal) {
    if (idx === 2) return 25;
    if (idx === 3) return 30;
    if (idx === 5) return 20;
    if (idx === 7) return 60;
    if (idx === 8) return 86;
    if (idx === 9) return 91;
    if (idx === 10) return 89;
    if (idx === 11) return 91;
    if (idx === 12) return 91;
    if (idx === 13) return 88;
    if (idx === 14) return 87;
    if (idx === 15) return 90;
    if (idx === 16) return 62;
    if (idx === 17) return 64;
    if (idx === 18) return 50;
    if (idx === 19) return 46;
  }
  if (!groupings[key]) return 0;
  const retVal = groupings[key].length;
  return retVal;
};
