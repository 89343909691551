import { makeAutoObservable, flow, toJS, runInAction } from "mobx";
import RootStore from "./RootStore";
import { LeaveCreation, Leave, TeamLeaves } from "interfaces/Leave";
import client from "services/api";
import moment from "moment";

type leaveObject = {
  title: string;
  start: Date;
  end: Date;
};

export default class LeaveStore {
  error: string | unknown = "";
  userLeaves: Leave[] = [];
  teamLeaves: TeamLeaves[] = [];
  isLoadingUserLeaves: boolean = false;
  isLoadingTeamsLeaves: boolean = false;
  rootStore!: RootStore;
  isLoading = false;
  teamLeaveTransform: leaveObject[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  createLeave = flow(function* (
    this: LeaveStore,
    newLeave: {
      from_date: string;
      to_date: string;
      // leave_status: number;
      leave_type: number;
      user: number;
    }
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(`/api/leaves/`, newLeave);
      this.isLoading = false;
      this.userLeaves.push(res.data);
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  loadUserLeaves = flow(function* (this: LeaveStore, user_id: number | undefined) {
    this.isLoading = true;
    this.isLoadingUserLeaves = true;
    try {
      const res = yield client.get(`/api/leaves/user/${user_id}`);
      this.userLeaves = res.data.results;
      this.isLoading = false;
      this.isLoadingUserLeaves = false;
      return this.userLeaves;
    } catch (err) {
      this.error = `${err}`;
    }
    this.isLoading = false;
    this.isLoadingUserLeaves = false;
  });

  deleteLeave = flow(function* (this: LeaveStore, leave_id: number) {
    const url = `/api/leaves/${leave_id}/`;
    try {
      const res = yield client.delete(url);
      if (res.status === 204) this.removeLeaveFromLocalObjects(leave_id);
    } catch (err) {
      this.removeLeaveFromLocalObjects(leave_id);
      this.error = err;
    }
  });

  removeLeaveFromLocalObjects = flow(function* (this: LeaveStore, leave_id: number) {
    let userLeaves = toJS(this.userLeaves);
    for (let key in userLeaves) {
      this.userLeaves = this.userLeaves.filter((bkg) => bkg.id !== leave_id).slice();
    }
  });

  loadTeamLeaves = flow(function* (this: LeaveStore, team_id: number | undefined) {
    this.isLoading = true;
    this.isLoadingUserLeaves = true;
    try {
      const res = yield client.get(`/api/leaves/team/${team_id}`);
      let teamLeaveValues = Array();
      if (res.status == 200) {
        res.data.results.forEach((value) => {
          let leaveObject: leaveObject = {
            title: value["preferred_name"],
            start: new Date(
              moment(value["start_date"] + "T00:00:00Z")
                .tz("UTC")
                .format("YYYY-MM-DD HH:mm:ss")
            ),
            end: new Date(
              moment(value["end_date"] + "T23:59:59Z")
                .tz("UTC")
                .format("YYYY-MM-DD HH:mm:ss")
            ),
          };
          teamLeaveValues.push(leaveObject);
        });
      }
      this.teamLeaves = teamLeaveValues;
      this.isLoading = false;
      this.isLoadingTeamsLeaves = false;
      return this.teamLeaves;
    } catch (err) {
      this.error = `${err}`;
    }
    this.isLoading = false;
    this.isLoadingTeamsLeaves = false;
  });
}
