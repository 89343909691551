import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { bhpColor, settings } from "./globals";

export const feedbackStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
    },
    header: {
      color: bhpColor.blueGrey1,
      marginBottom: theme.spacing(1),
    },
    summaryFeedbackForm: {
      "& :hover": {
        cursor: "pointer",
      },
    },
    paper: {
      padding: theme.spacing(1),
    },
    feedbackFormNameTitle: {
      marginLeft: theme.spacing(1),
      fontWeight: 700,
      color: bhpColor.blueGrey1,
      marginBottom: theme.spacing(1),
    },
    feedbackFormDescription: {
      marginLeft: theme.spacing(1),
      fontSize: 16,
      color: bhpColor.grey1,
    },
    hoverFeedbackSummaryBorder: {
      border: `2px solid transparent`,
      "&:hover": {
        border: `2px solid ${bhpColor.blueGrey1}`,
      },
    },
  })
);
