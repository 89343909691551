import {
  Box,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { TabPanel } from "@material-ui/lab";
import PendingTab from "components/requests/PendingTab";
import { observer } from "mobx-react-lite";
import {
  useBAMStore,
  useBuildingStore,
  useScheduleStore,
  useTeamStore,
  useUserStore,
} from "providers/RootStoreProvider";
import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { bhpColor } from "styles/globals";
import Topbar from "./Topbar";
import ListIcon from "@material-ui/icons/List";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { User } from "interfaces/User";
import { BuildingWithCapacity } from "interfaces/Building";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: bhpColor.black,
    },
  })
);

export enum RequsetStatusType {
  Pending = 1,
  Approved = 2,
  Regjected = 3,
}

interface RequestObject {
  id: number;
  status: RequsetStatusType;
  created_at: Date;
  start_date: Date;
  end_date: Date | null;
  requestor_id: number;
  requestor?: User;
  card_holder_id: number;
  card_holder?: User;
  supervisor_id: number;
  supervisor?: User;
  area: string;
  location_id: number;
  location?: BuildingWithCapacity;
  access_reason: string;
}

const sampleRequests: RequestObject[] = [
  {
    id: 23,
    status: RequsetStatusType.Pending,
    created_at: new Date(),
    start_date: new Date("2022-09-01"),
    end_date: null,
    requestor_id: 3328,
    card_holder_id: 3328,
    supervisor_id: 808,
    area: "MCR",
    location_id: 2,
    access_reason: "I need access to this area for FM Audits",
  },
  {
    id: 25,
    status: RequsetStatusType.Pending,
    created_at: new Date(),
    start_date: new Date("2022-09-02"),
    end_date: null,
    requestor_id: 808,
    card_holder_id: 83238,
    supervisor_id: 808,
    area: "FCR",
    location_id: 2,
    access_reason: "New P & W employee - access to control rooms required",
  },
  {
    id: 29,
    status: RequsetStatusType.Pending,
    created_at: new Date(),
    start_date: new Date("2022-09-05"),
    end_date: new Date("2022-09-23"),
    requestor_id: 74137,
    card_holder_id: 74137,
    supervisor_id: 808,
    area: "UPS",
    location_id: 2,
    access_reason: "Power supply maintenance",
  },
  {
    id: 31,
    status: RequsetStatusType.Pending,
    created_at: new Date(),
    start_date: new Date("2022-09-05"),
    end_date: null,
    requestor_id: 42126,
    card_holder_id: 42126,
    supervisor_id: 808,
    area: "Business Centre Storage Room",
    location_id: 2,
    access_reason: "Require access to setup for regular events and functions",
  },
];

interface RequestTabPanelProps {
  children?: React.ReactNode;
}

const RequestTabPanel = observer((props: RequestTabPanelProps) => {
  const { children, ...other } = props;

  return (
    <div role="tabpanel" {...other}>
      <Box bgcolor="white" position="relative" px={2} py={3}>
        {children}
      </Box>
    </div>
  );
});

const Requests = observer((props: any) => {
  const { t, i18n } = useTranslation();
  const classes = innerStyles();
  const bamStore = useBAMStore();
  const userStore = useUserStore();
  const history = useHistory();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));

  const pendingTab = {
    label: "Open",
    link: "/requests/open",
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <ListIcon fontSize="small" />
      </Box>
    ),
    component: (
      <RequestTabPanel>
        <PendingTab status={"open"} t={t} />
      </RequestTabPanel>
    ),
  };

  const closedTab = {
    label: "Closed",
    link: "/requests/closed",
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <ListAltIcon fontSize="small" />
      </Box>
    ),
    component: (
      <RequestTabPanel>
        <PendingTab status={"closed"} t={t} />
      </RequestTabPanel>
    ),
  };

  const tabs = [pendingTab, closedTab];

  const handleChange = (tabLink: string) => {
    history.push(tabLink);
  };
  const getTabType = (): "fullWidth" | "standard" => {
    if (smAndUp) {
      return "standard";
    } else {
      return "fullWidth";
    }
  };
  const currentTab =
    history.location.pathname.includes("closed") || history.location.pathname.includes("open")
      ? history.location.pathname
      : history.location.pathname + "/open";
  const selectedTab = tabs.find((tab) => currentTab === tab.link);

  useEffect(() => {
    bamStore.loadBAMSpecialAccessAreas();
  }, []);

  return (
    <Fragment>
      <Topbar
        title={t("Building Access Requests")}
        subtitle={t("Action user-submitted requests")}
        breadcrumb={t("Requests")}
      />
      <Box p={{ xs: 0, sm: 2, md: 4 }}>
        <Tabs
          value={currentTab}
          variant={getTabType()}
          onChange={(event, value) => handleChange(value)}
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.link} icon={tab.icon} value={tab.link} label={t(tab.label)} />
          ))}
        </Tabs>
        {selectedTab && selectedTab.component}
      </Box>
    </Fragment>
  );
});

export default Requests;
