import moment from "moment";
import config from "config";
import { capitalizeString } from "./string";
import { Holiday } from "interfaces/Utils";
import { Schedule, ScheduleType } from "interfaces/Schedule";

export const getNth = (d: number) => {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const getDateNth = (d: Date) => {
  const day = d.getDate();
  return `${day}${getNth(day)}`;
};

export const getDateTimeFromSchedule = (schedule: Schedule) => {
  if (schedule.status !== ScheduleType.OFFICE || !schedule.office) return null;
  const theDate = new Date(schedule.date);
  theDate.setHours(0, 0, 0, 0);
  const timeZone = schedule.office.building_timezone;
  if (!timeZone) return null;
  const utcDate = new Date(new Date(theDate).toLocaleString("en-US", { timeZone }));
  return utcDate;
};

export const getDateString = (date: Date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const getTimeString = (date: Date) => {
  let formattedTime = date.toLocaleString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return formattedTime;
};

export const addHours = (sourceDate: Date, hoursToAdd: number) => {
  let newDate = new Date(sourceDate);
  newDate.setTime(newDate.getTime() + hoursToAdd * 60 * 60 * 1000);
  return newDate;
};

export const dateFromUTCToLocal = (dte: string, format: string = "dddd, D of MMMM - h:mm A") => {
  return moment(dte).format(format);
};

/**
 * creates a period of a week based on the given date
 * assume week starts on a sunday (0)
 * @param date - a date somewhere in the week
 * @returns \start - midnight AM on the monday to midnight 7 days later
 */
export const getThisWeek = (date: Date, numberOfDays: number = 7) => {
  return { start: actualStartOfWeek(date), end: endOfPeriod(date, numberOfDays) };
};

export const actualStartOfWeek = (date: Date) => {
  const day = new Date(date);
  const dayOfWeek = date.getDay();
  const diff = day.getDate() - dayOfWeek + (dayOfWeek == 0 ? -6 : 1); // adjust when day is sunday
  day.setHours(0, 0, 0, 0);
  day.setDate(diff);
  return new Date(day);
};

export const actualEndOfWeek = (date: Date) => {
  return endOfPeriod(date, 7);
};

/**
 * can be used to get a data a week from now
 * @param date - the date
 * @param daysToAdd - number of days to add
 * @returns date + number of days
 */
export const endOfPeriod = (date: Date, daysToAdd: number = 7) => {
  const start = actualStartOfWeek(date);
  const endOfWeekDate = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 7);
  return endOfWeekDate;
};

export const startOfWeek = (date: Date) => {
  const day = date.getDay() || 7;
  if (day !== 1) date.setHours(-24 * (day - 1));
  return date;
};

export const endOfWeek = (date: Date) => {
  const start = startOfWeek(date);
  const endOfWeekDate = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 7);
  return endOfWeekDate;
};

export const nextDay = (date: string) => {
  const tomorrow = new Date(date);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const daysBetween = (from: string | Date, to: string | Date): string[] => {
  const fromDate = moment(new Date(from)).startOf("day");
  const toDate = moment(new Date(to)).endOf("day");

  if (fromDate > toDate) return [];

  const span = moment.duration(toDate.diff(fromDate)).asDays();
  const days: string[] = [];
  for (let i = 0; i <= span; i++) {
    days.push(moment(fromDate).add(i, "day").startOf("day").format("yyyy-MM-DD"));
  }
  return days;
};

export const daysBetweenRange = (from: string | Date, numberOfDays: number): string[] => {
  const fromDate = moment(new Date(from)).startOf("day");

  const span = numberOfDays;
  const days: string[] = [];
  for (let i = 0; i <= span; i++) {
    days.push(moment(fromDate).add(i, "day").startOf("day").format("yyyy-MM-DD"));
  }
  return days;
};

export const dateTodayOrFuture = (date: moment.Moment): boolean => {
  return date.isAfter() || isToday(date.local().toDate());
};

export const dateInPastArrow = (date: moment.Moment): boolean => {
  // simon: not sure this method works
  return !dateTodayOrFuture(date);
};

export const isDateInPast = (date: string): boolean => {
  // this method works I think
  return moment(new Date()).isAfter(moment(date), "day");
};

export const isToday = (date: Date): boolean => {
  const today = moment(new Date());
  return today.isSame(moment(date), "day");
};

export const isThisMonth = (date: Date): boolean => {
  return moment(new Date()).isSame(date, "month");
};

export const isDayTooFarToBook = (date: Date | string): boolean => {
  var start = moment(new Date());
  var end = moment(date);
  const days = end.diff(start, "days");
  return days > config.workday.numberOfDaysBookable;
};

export const isWeekend = (date: Date): boolean => {
  return [0, 6].indexOf(date.getDay()) !== -1;
};

export const superscriptDateFormat = (date: string): string => {
  return date.replace(/(\d)(st|nd|rd|th)/g, "$1<sup>$2</sup>");
};

export const weekDayNamesSorter = (data: any) => {
  const sorter = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };

  return data.sort(function sortByDay(a, b) {
    let day1 = a.toLowerCase();
    let day2 = b.toLowerCase();
    return sorter[day1] - sorter[day2];
  });
};

export const generateHolidayInformationModalContent = (holidays: Holiday[]) => {
  //const reducedHolidays = reduceHolidays(holidays || []);
  const reducedHolidaysObject = holidays.reduce((acc, obj) => {
    const key = `${obj["country"]}-${obj["holidayName"]}`;
    if (!acc[key]) {
      acc[key] = {
        country: obj.country,
        holidayName: obj.holidayName,
        holidayType: obj.holidayType,
        states: [],
      };
    }
    if (obj.state) acc[key].states.push(obj.state);
    return acc;
  }, {} as { country: string; holidayName: string; holidayType: string; states: string[] });

  return `<ul>${Object.keys(reducedHolidaysObject)
    ?.map((holiday, idx) => {
      if (reducedHolidaysObject[holiday].states.length > 0) {
        return `<div>Holiday in <b>${
          reducedHolidaysObject[holiday].country
        } - ${reducedHolidaysObject[holiday].states.join(", ")} - </b> for <b>${
          reducedHolidaysObject[holiday].holidayName
        }</b> (${capitalizeString(reducedHolidaysObject[holiday].holidayType)})</div>`;
      } else {
        return `<div>Holiday in <b>${reducedHolidaysObject[holiday].country}</b> for <b>${
          reducedHolidaysObject[holiday].holidayName
        }</b> - (${capitalizeString(reducedHolidaysObject[holiday].holidayType)})</div>`;
      }
    })
    .join("")}</ul>`;
};
