import { ScheduleType } from "interfaces/Schedule";

export const getScheduleTypeString = (scheduleType: ScheduleType) => {
  switch (scheduleType) {
    case 0:
      return "BHP Location";
    case 1:
      return "Remote";
    case 2:
      return "Off";
    default:
      return "BHP Location";
  }
};
