import { ScheduleType } from "interfaces/Schedule";
import { User } from "interfaces/User";
import { Building } from "./Building";

export enum TeamClassificationType {
  VISIBLE = 0,
  CLOSED = 1,
  PRIVATE = 2,
}

export enum TeamRoleType {
  PARTICIPANT = 0,
  OWNER = 1,
}

export interface TeamMember {
  userId: number;
  name?: string;
  worktypeToday?: ScheduleType;
  officeId?: number | null;
}

export interface TeamParticipant {
  id?: number;
  user_id: number;
  user_name?: string;
  first_name?: string;
  last_name?: string;
  preferred_name?: string;
  role_title?: string;
  default_team?: string;
  role?: TeamRoleType;
  picture?: string;
}

export interface TeamOwner {
  id: number;
  email: string;
  preferred_name: string;
  first_name: string;
  last_name: string;
}

export interface AnchorDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface ILOAnchorDays extends AnchorDays {
  monday_week2?: boolean;
  tuesday_week2?: boolean;
  wednesday_week2?: boolean;
  thursday_week2?: boolean;
  friday_week2?: boolean;
  saturday_week2?: boolean;
  sunday_week2?: boolean;
  twoWeekRoutine?: boolean;
}

export interface Team {
  id: number;
  name: string;
  department?: string;
  category: number;
  visibility: number;
  description?: string;
  location?: number | null;
  endDate?: string;
  // todo: type this
  members?: TeamMember[];
  members_sample?: TeamParticipant[];
  users?: User[];
  member_count?: number;
  owners?: TeamParticipant[];
  anchor_days?: AnchorDays;
}

export interface Schedule {
  date: string;
  schedule_status_counts: ScheduleStatusCount[];
}
export interface ScheduleStatusCount {
  status: ScheduleType;
  count: number;
}
export interface TeamWorkSchedule {
  id: number;
  name: string;
  department: string;
  schedules: Schedule[];
}

export interface WorkdayScheduleStatusCount {
  status: ScheduleType;
  count: number;
}

export interface WorkdaySchedule {
  date: string;
  schedule_status_counts: WorkdayScheduleStatusCount[];
}

export interface TeamWorkdaySchedule {
  team: Team;
  schedules: WorkdaySchedule[];
}

export const demoOwners: { userId: number; name: string }[] = [
  { userId: 1, name: "Jason Smith" },
  { userId: 2, name: "James Duncan" },
  { userId: 3, name: "Sarah Jeffrey" },
  { userId: 4, name: "Simon Avril" },
  { userId: 5, name: "Michael New" },
  { userId: 6, name: "Rick Smythe" },
  { userId: 7, name: "Peter Fogherty" },
];

export enum TeamsScheduleStatusTypeProperty {
  OFFICE = "office_count",
  REMOTE = "remote_count",
  OFF = "off_count",
}

export interface WorkSchedule {
  date: string;
  status: ScheduleType;
  building_id: number;
  city: string | null;
  building_name: string | null;
  space: string | null;
}

export interface TeamSchedulePeopleList {
  user_id: number;
  name: string;
  first_name: string;
  last_name: string;
  work_schedules: { [day: string]: WorkSchedule };
  team: Team;
  default_office: Building;
  remote_timezone: string;
  user_picture: string;
}

export enum TeamCategoryType {
  ORGANISATIONAL_UNIT = 0,
  SOCIAL = 4,
  PROJECT = 2,
  FUNCTIONAL = 3,
  CROSS_FUNCTIONAL = 1,
}

export enum TeamVisibilityType {
  VISIBLE = 0,
  CLOSED = 1,
  PRIVATE = 2,
}

export enum IloOccurance {
  DAILY = 1,
  WEEKLY = 2,
  EVERY_TWO_WEEKS = 3,
}

export enum IloBOSPractice {
  SERVE_OUR_CUSTOMER = 1,
  PURSUE_OPERATING_PERFECTION = 2,
  EMPOWER_OUR_PEOPLE = 3,
  OTHERS = 4,
}

export enum IloPlace {
  REMOTE = 0,
  BHP_LOCATION = 1,
}
export interface Noticeboard {
  id?: number;
  team?: number;
  creator: number;
  creator_first_name?: string;
  creator_last_name?: string;
  creator_picture?: string;
  creator_preferred_name?: string;
  avatar_tag?: string;
  avatar_tooltip?: string;
  date_created?: string;
  subject: string;
  description: string;
}

export interface Activity {
  id?: number;
  team: number;
  activity_name?: string;
  building: number | null;
  preparation?: string;
  purpose?: string;
  place?: IloPlace;
  occurance?: IloOccurance.DAILY | IloOccurance.WEEKLY | IloOccurance.EVERY_TWO_WEEKS;
  links?: string;
  bos_practice?: IloBOSPractice;
  days?: ILOAnchorDays;
  participants?: TeamParticipant[];
  start_time_local: string;
  end_time_local: string;
}
