import {
  Box,
  createStyles,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { bhpColor, settings } from "styles/globals";
import { useTeamStore } from "providers/RootStoreProvider";
import { isWeekend } from "utils/date";
import { useEffect, useState } from "react";
import HolidayPopover from "components/utils/HolidayPopover";
import AnchorPopover from "components/utils/AnchorPopover";
import { ScheduleType } from "interfaces/Schedule";
import { AnchorDays, Team } from "interfaces/Teams";

interface TeamDetailsPlannerTabHeadRowProps {
  selectedDate: string;
  scrollTo: any;
  setSelectedDate: any;
  teamWorkTypeSchedule: any[];
  teamId: number;
  t: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    today: {},
    selected: {
      backgroundColor: "white",
      borderRadius: settings.borderRadius,
      border: `1px solid ${bhpColor.blueGrey1}`,
    },
    weekend: { backgroundColor: `${bhpColor.blueGrey4}30` },
    dateHover: {
      border: `1px solid transparent`,
      "&:hover": {
        borderRadius: settings.borderRadius,
        border: `1px solid ${bhpColor.grey3}`,
      },
    },
  })
);

const TeamDetailsPlannerTabHeadRow = observer((props: TeamDetailsPlannerTabHeadRowProps) => {
  const { teamWorkTypeSchedule, scrollTo, selectedDate, setSelectedDate, t, teamId } = props;
  const classes = innerStyles();
  const teamStore = useTeamStore();
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));
  const plannedLabel = mdAndUp ? t("Team Planner") : t("Planner");
  //const [bestDayForCollab, setBestDayForCollab] = useState<string | null>(null);
  const [anchorDays, setAnchorDays] = useState<AnchorDays | null>(null);
  const scrollLeft = () => scroll(-1);
  const scrollRight = () => scroll(1);
  const scroll = (amount: number) => {
    scrollTo(amount);
  };

  const setDate = (dte: string) => {
    setSelectedDate(dte);
  };

  useEffect(() => {
    (async () => {
      await teamStore.loadMyTeams();
      const teamDetails =
        teamStore.myTeams.find((team: Team) => team.id.toString() === teamId.toString()) || null;
      if (teamDetails) {
        setAnchorDays(teamDetails.anchor_days || null);
      }
    })();
  }, [teamId]);

  return (
    <TableRow
      style={{
        backgroundColor: bhpColor.backgroundGrey,
        borderLeft: `1px solid ${bhpColor.backgroundGrey}`,
        borderRight: `1px solid ${bhpColor.backgroundGrey}`,
        borderTop: `1px solid ${bhpColor.backgroundGrey}`,
      }}
    >
      <TableCell style={{ width: `${mdAndUp ? 175 : 75}` }}>
        <Box style={{ textAlign: "center", color: bhpColor.blueGrey1 }}>
          <Typography style={{ fontWeight: 700 }}>{plannedLabel}</Typography>
        </Box>
      </TableCell>
      <TableCell
        style={{
          cursor: "pointer",
          color: bhpColor.blueGrey1,
          width: `${mdAndUp ? 50 : 5}`,
          padding: `${!mdAndUp && 0}`,
        }}
        onClick={() => scrollLeft()}
      >
        <Icon path={mdiChevronLeft} size={1} />
      </TableCell>
      {teamWorkTypeSchedule.map((val, idx) => {
        return (
          <TableCell
            style={{
              cursor: "pointer",
              padding: `${mdAndUp ? "10px 25px 10px 25px" : "0px 5px 0px 5px"}`,
            }}
            key={`thc${idx}`}
            className={isWeekend(new Date(val.date || "")) ? classes.weekend : ""}
            onClick={() => setDate(val.date)}
          >
            <Box
              p={2}
              style={{ textAlign: "center", position: "relative" }}
              className={`${classes.dateHover} ${
                moment(selectedDate).isSame(val.date, "day") ? classes.selected : ""
              }`}
            >
              <Typography style={{ fontWeight: 700, color: bhpColor.blueGrey1 }}>
                {t(moment(val.date).format("D"))}
              </Typography>
              <Typography style={{ fontSize: "12px", color: bhpColor.grey2 }}>
                {t(moment(val.date).format("ddd"))}
              </Typography>
              <HolidayPopover date={val.date} />
              {anchorDays && anchorDays[moment(val.date).format("dddd").toLowerCase()] === true && (
                <AnchorPopover />
              )}
            </Box>
          </TableCell>
        );
      })}
      <TableCell
        style={{
          cursor: "pointer",
          color: bhpColor.blueGrey1,
          width: `${mdAndUp ? 50 : 5}`,
          padding: `${!mdAndUp && 0}`,
        }}
        onClick={() => scrollRight()}
      >
        <Icon path={mdiChevronRight} size={1} />
      </TableCell>
    </TableRow>
  );
});

export default TeamDetailsPlannerTabHeadRow;
