import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Theme,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  TableHead,
  Tooltip,
  Link,
  CircularProgress,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useTeamStore } from "providers/RootStoreProvider";
import { useEffect, useState } from "react";
import { bhpColor } from "styles/globals";
import { ScheduleType } from "interfaces/Schedule";
import moment from "moment";
import Icon from "@mdi/react";
import { getIconPath } from "utils/icons";
import { daysBetweenRange, superscriptDateFormat, isWeekend } from "utils/date";
import { getCityAbbr } from "utils/locations";
import { getWorkTypeColor } from "utils/backGroundColor";
import { useBuildingStore, useUserStore } from "providers/RootStoreProvider";
import config from "config";
import { Building, BuildingWithCapacity } from "interfaces/Building";
import HolidayPopover from "components/utils/HolidayPopover";
import StarBorderTwoToneIcon from "@material-ui/icons/StarBorderTwoTone";
import StarTwoToneIcon from "@material-ui/icons/StarTwoTone";
import DOMPurify from "dompurify";

interface TeamDetailsPeopleSearchPlannerProps {
  teamId: number;
  focussedDate: string;
  startDate: string;
  endDate: string;
  searchTerm: string;
  workType?: ScheduleType | null;
  sortByName?: number;
  t: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: 0,
    },
    weekend: { backgroundColor: `${bhpColor.blueGrey4}30` },
    recordsFoundLabel: {
      color: "#E65400",
      fontFamily: "Arial",
      fontStyle: "italic",
      paddingTop: 20,
      fontWeight: "bold",
      fontSize: 14,
    },
    table: {
      border: `1px solid ${bhpColor.blueGrey4}`,
    },
    tableBodyRow: {
      borderBottom: `1px solid ${bhpColor.blueGrey4}`,

      "& .MuiTableCell-root": {
        borderBottom: 0,
      },
    },

    statusCell: {
      textAlign: "center",
      border: "none",
      backgroundColor: bhpColor.white,
      paddingTop: `16px !important`,
      paddingBottom: `16px !important`,
      "&.weekend": {
        backgroundColor: `${bhpColor.blueGrey4}30`,
      },
    },

    statusBlock: {
      border: `3px solid ${bhpColor.blueGrey2}`,
      backgroundColor: bhpColor.backgroundGrey,
      borderRadius: 4,
      height: 56,
      width: 54,
      fontSize: 10,
      marginLeft: "auto",
      marginRight: "auto",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        height: 45,
        width: 45,
      },
      color: bhpColor.white,
    },

    officeName: {
      marginTop: 4,
      minHeight: 13,
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
        minHeight: 10,
      },
    },
    bookingRef: {
      height: 20,
      marginTop: -5,
      [theme.breakpoints.down("sm")]: {
        marginTop: -7,
      },
      color: bhpColor.white,
      "&.nobooking": {
        color: bhpColor.orange1,
      },
    },
    icon: {
      height: 20,
      color: bhpColor.white,
      "&.office": {
        [theme.breakpoints.down("sm")]: {
          marginTop: -4,
        },
      },
    },
    orangeButton: {
      borderColor: bhpColor.orange1,
      fontSize: 14,
      color: bhpColor.orange1,
      fontWeight: "bold",
      textTransform: "unset",
      marginTop: 20,
      whiteSpace: "nowrap",
    },
  })
);

const TeamDetailsPeopleSearchPlanner = observer((props: TeamDetailsPeopleSearchPlannerProps) => {
  const {
    teamId,
    focussedDate,
    workType,
    searchTerm,
    sortByName = 1,
    startDate,
    endDate,
    t,
  } = props;
  const classes = innerStyles();
  const teamStore = useTeamStore();
  const userStore = useUserStore();
  const buildingStore = useBuildingStore();

  const openFloorPlan = (id: number | undefined, date: string | undefined) => {
    if (id) {
      buildingStore.setupFloorPlanFromSerraviewId(id, false, date);
    }
  };

  const [peopleSchedules, setPeopleSchedules] = useState([]);
  const [peopleSchedulesLastPage, setPeopleSchedulesLastPage] = useState(false);
  const [peopleSchedulePageNumber, setPeopleSchedulePageNumber] = useState(1);
  const paginatedPeopleScheduleLimit = 20;

  const handlePeopleSchedulesPagination = () =>
    setPeopleSchedulePageNumber((prev) => {
      if ((prev + 1) * paginatedPeopleScheduleLimit < peopleSchedules?.length) {
        return prev + 1;
      } else if ((prev + 1) * paginatedPeopleScheduleLimit === peopleSchedules?.length) {
        setPeopleSchedulesLastPage(true);
        return prev + 1;
      } else {
        setPeopleSchedulesLastPage(true);
        return prev;
      }
    });
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));
  const plannedLabel = mdAndUp ? "Weekly Planner" : "Planner";
  const [userIdEdited, setUserIdEdited] = useState<number | null>(null);

  const isUserFavourite = (userId: number) => {
    return userStore.me?.favourite_users && userStore.me?.favourite_users.includes(userId);
  };

  const addUserToFavorites = async (userId: number) => {
    if (userId) {
      setUserIdEdited(userId);
      await userStore.addFavouriteUser(userId);
    }
    setUserIdEdited(null);
  };

  const removeUserFromFavorites = async (userId: number) => {
    if (userId) {
      setUserIdEdited(userId);
      await userStore.removeFavouriteUser(userId);
    }
    setUserIdEdited(null);
  };

  useEffect(() => {
    buildingStore.loadBuildings();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await teamStore.getPeopleSchedule(teamId, startDate, endDate);
      if (response) {
        let filteredResponse = response;
        if (searchTerm !== "") {
          filteredResponse = filteredResponse.filter((res: any) =>
            res.name.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }

        if (workType !== null) {
          filteredResponse = filteredResponse.filter(
            (res: any) =>
              res.work_schedules && res.work_schedules[focussedDate]?.status === workType
          );
        }
        filteredResponse = filteredResponse.sort((a: any, b: any) =>
          sortByName === 1
            ? a.name > b.name
              ? 1
              : b.name > a.name
              ? -1
              : 0
            : a.name < b.name
            ? 1
            : b.name < a.name
            ? -1
            : 0
        );
        setPeopleSchedules(filteredResponse);
      }
    })();
  }, [focussedDate, searchTerm, workType, sortByName, startDate, endDate, userStore.me]);

  return (
    <>
      <Box py={1} className={classes.root}>
        <Paper elevation={0}>
          {peopleSchedules?.length > 0 && (
            <>
              <Typography component={"span"} className={classes.recordsFoundLabel}>
                {peopleSchedules?.length}{" "}
                {peopleSchedules?.length > 1 ? t("Results for") : t("Result for")}:{" "}
                <Box
                  style={{ fontWeight: "normal", display: "inline" }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      superscriptDateFormat(
                        `${t(moment(focussedDate).format("Do"))} ${t(
                          moment(focussedDate).format("MMMM")
                        )}`
                      )
                    ),
                  }}
                />
              </Typography>
            </>
          )}
        </Paper>
        {peopleSchedules.length > 0 ? (
          <Box py={1} style={{ overflowX: "auto" }}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: bhpColor.backgroundGrey,
                    borderLeft: `1px solid ${bhpColor.backgroundGrey}`,
                    borderRight: `1px solid ${bhpColor.backgroundGrey}`,
                    borderTop: `1px solid ${bhpColor.backgroundGrey}`,
                  }}
                >
                  <TableCell style={{ width: `${mdAndUp ? 175 : 75}` }}>
                    <Box style={{ textAlign: "center", color: bhpColor.blueGrey1 }}>
                      <Typography style={{ fontWeight: 700 }}>{plannedLabel}</Typography>
                    </Box>
                  </TableCell>
                  {daysBetweenRange(startDate, mdAndUp ? 6 : 1).map((date, idx) => {
                    return (
                      <TableCell
                        style={{
                          padding: `${mdAndUp ? "10px 25px 10px 25px" : "0px 5px 0px 5px"}`,
                        }}
                        key={`thc${idx}`}
                        className={isWeekend(new Date(date || "")) ? classes.weekend : ""}
                      >
                        <Box p={2} style={{ textAlign: "center", position: "relative" }}>
                          <Typography style={{ fontWeight: 700, color: bhpColor.blueGrey1 }}>
                            {moment(date).format("D")}
                          </Typography>
                          <Typography style={{ fontSize: "12px", color: bhpColor.grey2 }}>
                            {moment(date).format("ddd")}
                          </Typography>
                          <HolidayPopover date={date} />
                        </Box>
                      </TableCell>
                    );
                  })}
                  <TableCell
                    style={{
                      color: bhpColor.blueGrey1,
                      width: `${mdAndUp ? 50 : 5}`,
                      padding: `${!mdAndUp && 0}`,
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {peopleSchedules
                  .filter((schedule: any, index: number) => {
                    return index < peopleSchedulePageNumber * paginatedPeopleScheduleLimit;
                  })
                  .map((schedule: any, index: number) => {
                    return (
                      <TableRow className={classes.tableBodyRow} key={index}>
                        <TableCell
                          style={{
                            width: `${mdAndUp ? 225 : 80}`,
                            borderRight: `1px solid ${bhpColor.blueGrey4}`,
                            paddingLeft: 30,
                            paddingBottom: 10,
                          }}
                        >
                          <Box>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                              <div
                                style={{
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  color: bhpColor.blueGrey1,
                                }}
                              >
                                {schedule.name}
                              </div>
                              <div
                                style={{
                                  alignSelf: "center",
                                  visibility:
                                    schedule.user_id === userStore.me?.id ? "hidden" : "visible",
                                }}
                              >
                                {userStore.isLoadingFavouriteUsers &&
                                schedule.user_id === userIdEdited ? (
                                  <CircularProgress size={14} color="secondary" />
                                ) : isUserFavourite(schedule.user_id) ? (
                                  <Tooltip
                                    title={`Click to remove ${schedule.name} from favourites`}
                                  >
                                    <StarTwoToneIcon
                                      color="primary"
                                      style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        alignSelf: "center",
                                      }}
                                      onClick={(event) => {
                                        event?.preventDefault();
                                        removeUserFromFavorites(schedule.user_id);
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title={`Click to make ${schedule.name} as favourite`}>
                                    <StarBorderTwoToneIcon
                                      style={{ cursor: "pointer", alignSelf: "center" }}
                                      onClick={(event) => {
                                        event?.preventDefault();
                                        addUserToFavorites(schedule.user_id);
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </Box>
                            <div style={{ fontSize: 16, color: bhpColor.blueGrey2, paddingTop: 5 }}>
                              {schedule.default_office?.city}
                            </div>
                            <div style={{ fontSize: 12, color: bhpColor.blueGrey2, paddingTop: 5 }}>
                              {moment().tz(schedule.remote_timezone).zoneAbbr()} (9am - 5pm)
                            </div>
                            <div style={{ fontSize: 12, color: bhpColor.blueGrey1 }}>{`${t(
                              "Local time"
                            )}: ${moment(new Date())
                              .tz(schedule.remote_timezone)
                              .format("h:mma,")} ${t(
                              moment(new Date()).tz(schedule.remote_timezone).format("ddd")
                            )}`}</div>
                          </Box>
                        </TableCell>
                        {daysBetweenRange(startDate, mdAndUp ? 6 : 1).map((date) => {
                          let building: Building | null = null;
                          if (schedule.work_schedules) {
                            building = buildingStore.getBuildingSimple(
                              schedule.work_schedules[date]?.building_id
                            );
                          }
                          return (
                            <TableCell
                              className={classes.statusCell}
                              style={{
                                backgroundColor: isWeekend(new Date(date))
                                  ? `${bhpColor.blueGrey4}30`
                                  : "transparent",
                                color: bhpColor.white,
                              }}
                              key={date}
                            >
                              <div
                                style={{
                                  paddingLeft: mdAndUp ? 16 : 0,
                                  paddingRight: mdAndUp ? 16 : 0,
                                }}
                              >
                                {schedule?.work_schedules && schedule?.work_schedules[date] ? (
                                  <>
                                    <Tooltip
                                      title={
                                        schedule.work_schedules[date]?.status !==
                                        ScheduleType.OFF ? (
                                          <div>
                                            {t("Working Hours")}:{" "}
                                            {moment(
                                              schedule.work_schedules[date]?.start_time &&
                                                schedule.work_schedules[date]?.start_time !== ""
                                                ? schedule.work_schedules[date]?.start_time
                                                : config.workday.defaultStartWorkingHour,
                                              ["HH:mm", "H:mm"]
                                            ).format("hh:mm A")}{" "}
                                            -{" "}
                                            {moment(
                                              schedule.work_schedules[date]?.finish_time &&
                                                schedule.work_schedules[date]?.finish_time !== ""
                                                ? schedule.work_schedules[date]?.finish_time
                                                : config.workday.defaultFinishWorkingHour,
                                              ["HH:mm", "H:mm"]
                                            ).format("hh:mm A")}
                                            <br />
                                            {schedule.work_schedules[date]?.status ===
                                              ScheduleType.OFFICE &&
                                            schedule.work_schedules[date]?.space ? (
                                              schedule.work_schedules[date]?.desk_finish_time &&
                                              schedule.work_schedules[date]?.desk_start_time ? (
                                                <div>
                                                  {t("Desk Hours")}:{" "}
                                                  {moment(
                                                    schedule.work_schedules[date]?.desk_start_time,
                                                    ["HH:mm", "H:mm"]
                                                  ).format("hh:mm A")}{" "}
                                                  -{" "}
                                                  {moment(
                                                    schedule.work_schedules[date]?.desk_finish_time,
                                                    ["HH:mm", "H:mm"]
                                                  ).format("hh:mm A")}
                                                </div>
                                              ) : (
                                                <div>
                                                  {t("Desk Hours")}:{" "}
                                                  {moment(config.workday.defaultStartWorkingHour, [
                                                    "HH:mm",
                                                    "H:mm",
                                                  ]).format("hh:mm A")}{" "}
                                                  -{" "}
                                                  {moment(config.workday.defaultFinishWorkingHour, [
                                                    "HH:mm",
                                                    "H:mm",
                                                  ]).format("hh:mm A")}
                                                </div>
                                              )
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      }
                                    >
                                      <div
                                        className={`${classes.statusBlock}`}
                                        style={{
                                          borderColor: getWorkTypeColor(
                                            schedule.work_schedules[date]?.status
                                          ),
                                          backgroundColor: getWorkTypeColor(
                                            schedule.work_schedules[date]?.status
                                          ),
                                        }}
                                      >
                                        <div className={classes.officeName}>
                                          {building ? getCityAbbr(building) : ""}
                                        </div>
                                        <Icon
                                          path={getIconPath(schedule.work_schedules[date]?.status)}
                                          className={`${classes.icon}  office`}
                                        />
                                        <div className={classes.bookingRef}>
                                          {schedule.work_schedules[date]?.space_serraview_id ? (
                                            <Link
                                              href="#"
                                              onClick={() =>
                                                openFloorPlan(
                                                  schedule.work_schedules[date]?.space_serraview_id,
                                                  date
                                                )
                                              }
                                            >
                                              {schedule.work_schedules[date]?.space}
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </Tooltip>
                                  </>
                                ) : (
                                  <div
                                    className={classes.statusBlock}
                                    style={{ border: "none", backgroundColor: "transparent" }}
                                  ></div>
                                )}
                              </div>
                            </TableCell>
                          );
                        })}

                        <TableCell style={{ width: `${mdAndUp ? 50 : 5}` }}></TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {peopleSchedules.length > paginatedPeopleScheduleLimit ? (
              <Box>
                <Button
                  className={classes.orangeButton}
                  variant="outlined"
                  onClick={handlePeopleSchedulesPagination}
                  disabled={peopleSchedulesLastPage}
                >
                  {t("Show More")}
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Box>
        ) : (
          <Box
            style={{ display: "flex", height: 128, justifyContent: "center", alignItems: "center" }}
          >
            <Paper elevation={0}>
              <div>{t("No records to show")}</div>
            </Paper>
          </Box>
        )}
      </Box>
    </>
  );
});
export default TeamDetailsPeopleSearchPlanner;
