import { Redirect, Route } from "react-router-dom";
import { useUserStore } from "providers/RootStoreProvider";
import { observer } from "mobx-react-lite";

interface GuardedRouteProps {
  theKey?: number;
  path: string;
  component: any;
}

const GuardedRoute = observer((props: GuardedRouteProps) => {
  const userStore = useUserStore();

  const theKey = props.theKey ? props.theKey : undefined;
  const isSetup = userStore.isFullySetup;
  const path = props.path;
  const Component = props.component;

  return (
    <Route
      exact
      key={theKey}
      path={path}
      render={(props) =>
        isSetup || path === "/settings" ? <Component {...props} /> : <Redirect to="/settings" />
      }
    />
  );
});

export default GuardedRoute;
