import { Box, Button, createStyles, Grid, Link, makeStyles, Theme } from "@material-ui/core";
import Icon from "@mdi/react";
import { bhpColor, settings } from "styles/globals";
import { MultipleBooking, Schedule, ScheduleType } from "interfaces/Schedule";
import { wdpStrings } from "config/strings";
import { observer } from "mobx-react-lite";
import OfficeCapacityWidget_V2 from "./OfficeCapacityWidget_V2";
import { BuildingWithCapacity } from "interfaces/Building";
import { getIconPath } from "utils/icons";
import { getWorkTypeColor } from "utils/backGroundColor";
import { useEffect, useState } from "react";
import { useUserStore, useBookingStore } from "providers/RootStoreProvider";
import { actualStartOfWeek, endOfPeriod, getDateString } from "utils/date";
import moment from "moment";
import { getEarliestBooking } from "utils/bookings";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: bhpColor.blueGrey1,
      "& .MuiGrid-item": {
        display: "flex",
      },
      "& .MuiLinearProgress-barColorSecondary": {
        borderRadius: settings.borderRadius,
      },
    },
    worktypeBox: {
      display: "flex",
      backgroundColor: bhpColor.white,
      borderRadius: settings.borderRadius,
    },
    officeDetail: {
      fontSize: 12,
    },
    officeDetailRow: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
      },
    },
    timeDetail: {
      fontSize: 12,
      color: bhpColor.grey2,
      marginLeft: 10,
      [theme.breakpoints.down("sm")]: {
        //not enough space while abbreviation
        //on the timezone is not working
        display: "none",
      },
    },
    workStatus: {
      textAlign: "start",
      fontWeight: 700,
      fontSize: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    iconBox: {
      width: 80,
      height: 72,
      borderRadius: settings.borderRadius,
      backgroundColor: bhpColor.blueGrey1,
      color: bhpColor.white,
      [theme.breakpoints.down("sm")]: {
        width: 65,
        height: 55,
      },
      "& .notOffice": {
        backgroundColor: bhpColor.blueGrey2,
      },
      "& .bookingRef": {
        fontSize: 14,
        marginTop: 0,
        [theme.breakpoints.down("sm")]: {
          fontSize: 11,
        },
      },
      textAlign: "center",
    },
    icon: {
      marginTop: 5,
      height: 40,
      [theme.breakpoints.down("sm")]: {
        height: 26,
      },

      "& .office": {
        color: bhpColor.white,
      },
    },
    orangeButton: {
      borderColor: bhpColor.orange1,
      fontSize: 14,
      height: "36px",
      color: bhpColor.orange1,
      fontWeight: "bold",
      textTransform: "unset",
      whiteSpace: "nowrap",
    },
  })
);

/// From workType return the icon type office|remote|off (default: off)

interface WorkdaySummaryProps {
  buildingDetail: BuildingWithCapacity | null;
  userSchedule: Schedule | null;
  showOfficeCapacityWidget: boolean;
  showOfficeCapacityHourly?: boolean;
  privateSchedule?: boolean;
  inCompleteSettings?: boolean;
  showCovidForm?: boolean;
  userId: number;
  t: any;
}

const WorkdaySummary = observer((props: WorkdaySummaryProps) => {
  const {
    buildingDetail,
    userSchedule,
    t,
    showCovidForm = false,
    showOfficeCapacityHourly,
    userId,
  } = props;

  const classes = innerStyles();
  const userStore = useUserStore();
  const bookingStore = useBookingStore();

  const covidFormBuildings = [2, 23];
  const covidFormUrl =
    showCovidForm && buildingDetail && covidFormBuildings.includes(buildingDetail.id)
      ? "https://bhp.workflowcloud.com/forms/7e87a82d-e43d-49fb-8f54-d9747633c83f"
      : null;

  const inOffice = userSchedule ? userSchedule.status === ScheduleType.OFFICE : false;
  const isOffice = userSchedule
    ? userSchedule.status === ScheduleType.OFFICE
      ? "office"
      : ""
    : "";
  const theIcon = getIconPath(userSchedule ? userSchedule.status : ScheduleType.OFF);
  const earliestBooking = getEarliestBooking(
    bookingStore.user_bookings[getDateString(new Date())] || [],
    getDateString(new Date())
  );
  const currentStatus = !userSchedule
    ? t("Unknown")
    : userSchedule.status === ScheduleType.OFFICE
    ? t("At a BHP Location")
    : userSchedule.status === ScheduleType.REMOTE
    ? t("Remote")
    : t("Off Today");
  const officeLocation = earliestBooking
    ? `${earliestBooking.building?.city}, ${earliestBooking.building?.building_name}`
    : buildingDetail
    ? `${buildingDetail.city}, ${buildingDetail.building_name}`
    : "";

  const bookingRef =
    earliestBooking || userSchedule
      ? earliestBooking?.space?.name || userSchedule?.space?.name
      : "";

  const abbreviatedTimezone = inOffice
    ? `${
        earliestBooking
          ? `${t("9am - 5pm")} (${earliestBooking.building?.building_timezone})`
          : buildingDetail?.building_timezone
          ? `${t("9am - 5pm")} (${buildingDetail.building_timezone})`
          : ""
      }`
    : "";

  useEffect(() => {
    if (userStore.me) {
      const schedulePeriod = {
        start: actualStartOfWeek(new Date()),
        end: endOfPeriod(actualStartOfWeek(new Date()), 7),
      };
      bookingStore.loadUserBookings(schedulePeriod, userId.toString());
    }
  }, [bookingStore, userStore.me]);

  return (
    <div className={classes.root}>
      <Box className={classes.worktypeBox}>
        <Box
          className={`${classes.iconBox} ${isOffice}`}
          paddingTop={bookingRef ? 0 : 1}
          style={{
            backgroundColor: `${getWorkTypeColor(
              userSchedule ? userSchedule.status : ScheduleType.OFFICE
            )}`,
          }}
        >
          {!props.privateSchedule ? (
            <>
              <Icon path={theIcon} className={`${classes.icon}  ${isOffice}`} />
              <Box className={"bookingRef"}>{bookingRef}</Box>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Box p={1} style={{ width: "100%" }}>
          {props.privateSchedule ? (
            <Box className={classes.workStatus}>{t("Private Planner")}</Box>
          ) : props.inCompleteSettings ? (
            <Box className={classes.workStatus}>{t("Incomplete Settings")}</Box>
          ) : (
            <>
              <Box
                className={classes.workStatus}
                style={{
                  color: `${getWorkTypeColor(
                    userSchedule ? userSchedule.status : ScheduleType.OFFICE
                  )}`,
                }}
              >
                {currentStatus}
              </Box>
              {inOffice ? (
                <Box display="flex" flexGrow={1} className={classes.officeDetailRow}>
                  <Box className={classes.officeDetail}>{officeLocation}</Box>
                  <Box className={classes.timeDetail}>{abbreviatedTimezone}</Box>
                </Box>
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      </Box>
      {props.showOfficeCapacityWidget ? (
        <Box mt={2}>
          <OfficeCapacityWidget_V2
            t={t}
            date={userSchedule ? new Date(userSchedule.date) : new Date()}
            hideName={true}
            fullWidth={true}
            snug={true}
            showHourlyGroupings={showOfficeCapacityHourly}
            buildingDetail={buildingDetail}
          />
        </Box>
      ) : (
        <></>
      )}
      {covidFormUrl && userSchedule?.status == ScheduleType.OFFICE ? (
        <>
          {t("Ensure you fill out the")}{" "}
          <Link href={covidFormUrl} target="new">
            {t("COVID-19 self-assesment form")}
          </Link>{" "}
          {t("prior to entry, once a week")}
        </>
      ) : (
        ""
      )}
    </div>
  );
});

export default WorkdaySummary;
