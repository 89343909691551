import { Box, Tooltip, MenuItem, Menu, CircularProgress, ListItemText } from "@material-ui/core";
import { teamStyles } from "styles/teamStyles";
import { useTeamStore, useUserStore } from "providers/RootStoreProvider";
import { Noticeboard } from "interfaces/Teams";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DeleteIcon from "@material-ui/icons/Delete";
import ReportIcon from "@material-ui/icons/Report";
import EditIcon from "@material-ui/icons/Edit";

import React from "react";
import { bhpColor } from "styles/globals";

interface TeamNoticeOptionsMenuProps {
  notice: Noticeboard;
  isTeamOwner: boolean;
  removeNotice: any;
  reportNotice: any;
  editNotice: any;
  t: any;
}
export function TeamNoticeOptionsMenu(props: TeamNoticeOptionsMenuProps) {
  const { t, notice, isTeamOwner, removeNotice, reportNotice, editNotice } = props;
  const classes = teamStyles();
  const teamStore = useTeamStore();
  const userStore = useUserStore();
  const [noticeMenuAnchorEl, setNoticeMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const openNoticeMenu = Boolean(noticeMenuAnchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNoticeMenuAnchorEl(event.currentTarget);
  };
  const handleNoticeMenuClose = () => {
    setNoticeMenuAnchorEl(null);
  };
  return (
    <>
      {teamStore.isDeletingNotice !== notice.id ? (
        <>
          <Box
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              color: bhpColor.blueGrey2,
            }}
            onClick={handleMenuClick}
            key={`notice${notice.id}`}
          >
            <Tooltip title="Menu">
              <MoreHorizIcon />
            </Tooltip>
          </Box>
          <Menu
            id={`notice${notice.id}-menu`}
            open={openNoticeMenu}
            onClose={handleNoticeMenuClose}
            anchorEl={noticeMenuAnchorEl}
          >
            {(isTeamOwner || notice.creator === userStore.me?.id) && (
              <Box>
                <MenuItem
                  key={`${notice.id}-delete`}
                  onClick={() => {
                    removeNotice(notice);
                    setNoticeMenuAnchorEl(null);
                  }}
                >
                  <DeleteIcon style={{ paddingRight: 6, color: bhpColor.red }} />
                  <ListItemText style={{ color: bhpColor.blueGrey1 }}>
                    {t("Delete Notice")}
                  </ListItemText>
                </MenuItem>
              </Box>
            )}
            {notice.creator !== userStore.me?.id && (
              <MenuItem
                onClick={() => {
                  reportNotice(notice);
                  setNoticeMenuAnchorEl(null);
                }}
              >
                <ReportIcon style={{ paddingRight: 6, color: bhpColor.orange2 }} />
                <ListItemText style={{ color: bhpColor.blueGrey1 }}>
                  {t("Report Notice")}
                </ListItemText>
              </MenuItem>
            )}
            {notice.creator === userStore.me?.id && (
              <Box>
                <MenuItem
                  key={`${notice.id}-edit`}
                  onClick={() => {
                    editNotice(notice);
                    setNoticeMenuAnchorEl(null);
                  }}
                >
                  <EditIcon style={{ paddingRight: 6, color: bhpColor.blue1 }} />
                  <ListItemText style={{ color: bhpColor.blueGrey1 }}>
                    {t("Edit Notice")}
                  </ListItemText>
                </MenuItem>
              </Box>
            )}
          </Menu>
        </>
      ) : (
        <Box
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <CircularProgress size={14} />
        </Box>
      )}
    </>
  );
}
