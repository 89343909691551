import { useEffect, useRef, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { setContext } from "@sentry/react";
import { createTextSpan } from "typescript";
import { eventToSentryRequest } from "@sentry/core";
import { mdiDomainRemove } from "@mdi/js";

interface CanvasWrapperProps {
  setcontext: any;
  doScale?: any;
  doMove?: any;
  mouseclick?: any;
  mousemove?: any;
  displaySpecs?: any;
  draw?: any;
  height?: number;
  width?: number;
  scaleX?: number;
  scaleY?: number;
  addListeners?: boolean;
}

const CanvasWrapper = observer((props: CanvasWrapperProps) => {
  const {
    width,
    height,
    draw,
    scaleX,
    scaleY,
    setcontext,
    mouseclick,
    mousemove,
    displaySpecs,
    doScale,
    doMove,
    addListeners,
  } = props;
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));

  const canvasRef = useRef(null);
  const canvasWidth = width || 1024;
  const canvasHeight = height || 1024;
  const scalex = scaleX || 1;
  const scaley = scaleY || 1;

  const [hasListener, setHasListener] = useState(false);
  let isScaling = false;
  let isMoving = false;

  const mouseClick = (evt: any) => {
    const isMoveButtonClick = evt.button === 0;
    const isScaleButtonClick = evt.button === 2;

    if (isScaleButtonClick) {
      // toggle scaling
      isScaling = !isScaling;
      isMoving = false;
    }

    if (isMoveButtonClick) {
      // toggle moving
      isMoving = !isMoving;
      isScaling = false;
      if (mouseclick) mouseclick(evt);
    }
  };

  let currentX = 0;
  let currentY = 0;
  let movements = 0; // use this to limit the sending of points upstream
  const mouseMove = (evt: any, ctx: any) => {
    const mouseX = evt.offsetX;
    const mouseY = evt.offsetY;
    if ((currentX !== 0 && currentX !== mouseX) || (currentY !== 0 && currentY !== mouseY)) {
      if (isScaling && doScale) doScale(currentX < mouseX, currentY < mouseY, ctx);
      if (isMoving && doMove) doMove(currentX < mouseX, currentY < mouseY, ctx);
      if (mousemove && movements % 3 === 2) mousemove(mouseX, mouseY, ctx); // every 3rd point should do
    }
    currentX = mouseX;
    currentY = mouseY;
    movements++;
  };

  useEffect(() => {
    const canvas = canvasRef.current || undefined;
    if (canvas) {
      const ctx = (canvas as HTMLCanvasElement).getContext("2d");
      if (!hasListener && addListeners !== false) {
        (canvas as HTMLCanvasElement).addEventListener("mousedown", (event) => {
          // event.preventDefault();
          if (mouseclick) mouseClick(event);
        });
        (canvas as HTMLCanvasElement).addEventListener("contextmenu", function (evt) {
          evt.preventDefault();
        });
        (canvas as HTMLCanvasElement).addEventListener("mousemove", function (event) {
          mouseMove(event, ctx);
        });
        setHasListener(true);
      }
      setcontext(ctx);
      if (ctx) {
        ctx.scale(scalex, scaley);
        draw(ctx);
      }
    }
  }, [draw]);

  const relevantProps = { ...props };
  delete relevantProps.setcontext;
  delete relevantProps.mouseclick;
  delete relevantProps.draw;
  delete relevantProps.mousemove;

  return (
    <canvas
      onClick={addListeners ? (e) => mouseclick(e) : undefined}
      ref={canvasRef}
      width={canvasWidth + 200}
      height={canvasHeight}
      {...relevantProps}
    />
  );
});

export default CanvasWrapper;
