import React, { ReactElement, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { BuildingWithCapacity } from "interfaces/Building";
import {
  createStyles,
  makeStyles,
  Theme,
  Paper,
  Typography,
  useTheme,
  Grid,
} from "@material-ui/core";
import { useBuildingStore, useUserStore, useScheduleStore } from "providers/RootStoreProvider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import OfficeMainPage from "components/office/OfficeMainPage";
import VisitorRegistration from "components/office/VisitorRegistration";
import IFrameResizer from "iframe-resizer-react";

import LocationCityIcon from "@material-ui/icons/LocationCity";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import ListIcon from "@material-ui/icons/List";
import BuildTwoToneIcon from "@material-ui/icons/BuildTwoTone";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import LoadingZone from "components/schedule/LoadingZone";
import { getOfficeServiceIframe } from "utils/locations";
import { bhpColor } from "styles/globals";
import OfficeSummaryCard from "./OfficeSummaryCard";
import { useTranslation } from "react-i18next";

// interface OfficeTabMainPagePanelProps{
//     myOffice: BuildingWithCapacity | null;
//     t: any;
// }

interface ServiceTabProps {}

const serviceStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: bhpColor.blueGrey1,
      cursor: "pointer",
      width: "50%",
      padding: "12px",
      [theme.breakpoints.down("sm")]: {
        backgroundColor: bhpColor.white,
      },
    },
    buildingName: {
      fontSize: 18,
      fontWeight: 700,
    },
    firstLine: {},
    secondLine: {
      color: bhpColor.blueGrey2,
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    thirdLine: {
      color: bhpColor.grey2,
      fontSize: 12,
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
      },
    },
    defaultName: {
      float: "right",
      color: bhpColor.grey1,
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
      },
    },
    hoverOfficeSummaryBorder: {
      border: `2px solid #84898F`,
      "&:hover": {
        border: `2px solid ${bhpColor.orange1}`,
      },
    },
  })
);

const ServiceTab = observer((props: ServiceTabProps) => {
  const classes = serviceStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));

  // const jumpToService = () => {
  //   const tabLink = getOfficeServiceIframe();
  //   window.open(tabLink, "_blank");
  // };

  return (
    // <Paper
    //   elevation={0}
    //   className={`${classes.root} ${classes.hoverOfficeSummaryBorder}`}
    //   onClick={() => jumpToService()}
    // >
    //   <Box className={classes.buildingName}>Property Assist</Box>
    //   <Box className={classes.secondLine}>View and raise Workplace Requests</Box>
    // </Paper>
    <Paper elevation={0} className={`${classes.root} ${classes.hoverOfficeSummaryBorder}`}>
      <Box className={classes.buildingName}>Property Assist Page is currently down.</Box>
      <Box className={classes.secondLine}>
        We are working on the fix. Please contact your{" "}
        <a
          target={"blank"}
          href="https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Property-Assist.aspx"
        >
          {" "}
          local property assist team
        </a>{" "}
        for your request
      </Box>
    </Paper>
  );
});

interface OfficeTabMainPagePanelProps {
  children?: React.ReactNode;
  title: string;
  description: string;
}

const OfficeTabMainPagePanel = observer((props: OfficeTabMainPagePanelProps) => {
  const { children, title, description, ...other } = props;

  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const scheduleStore = useScheduleStore();

  return (
    <div role="tabpanel" {...other}>
      <Box bgcolor="white" position="relative" p={mdAndUp ? 3 : 0}>
        {/* {scheduleStore.isLoading && <LoadingZone isLoading={scheduleStore.isLoading} />} */}
        <Box py={2}>
          {/* <Typography style={{ fontSize: 26 }} variant="h1">
            {title}
          </Typography> */}
          {/* <Box mt={1}>{description}</Box> */}
          {children}
        </Box>
      </Box>
    </div>
  );
});

interface TabType {
  label: string;
  link: string;
  component: any;
  icon: any;
}

interface urlParams {
  officeId: string;
  eventId: string;
  tab: string;
}

interface OfficeTabsProps {
  myOffice: BuildingWithCapacity | null;
  t: any;
}

const OfficeTabMainPage = observer((props: OfficeTabsProps) => {
  const t = props.t;
  const myOffice = props.myOffice;

  const history = useHistory();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const urlParams: urlParams = useParams();
  const [activeTab, setActiveTab] = useState(history.location.pathname);

  const iframeRef = useRef(null);
  const iframeServiceSrc = getOfficeServiceIframe();

  const officesTab: TabType = {
    label: t("Offices"),
    link: "/offices",
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <LocationCityIcon fontSize="small" />
      </Box>
    ),
    component: (
      <OfficeTabMainPagePanel title={"Office Locations"} description={"Office locations"}>
        <OfficeMainPage t={t} myOffice={myOffice} />
      </OfficeTabMainPagePanel>
    ),
  };

  const visitorsTab: TabType = {
    label: smAndUp ? t("Visitor Registration") : t("Visitor"),
    link: "/offices/visitor-registration",
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        {smAndUp ? <HowToRegIcon fontSize="small" /> : <PersonAddIcon fontSize="small" />}
      </Box>
    ),
    component: (
      <OfficeTabMainPagePanel title={"Office Locations"} description={"Office locations"}>
        <VisitorRegistration />
      </OfficeTabMainPagePanel>
    ),
  };

  const requestAccessTab: TabType = {
    label: smAndUp ? t("Office Access") : t("Access"),
    link: "/offices/office-access",
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <ListIcon fontSize="small" />
      </Box>
    ),
    component: (
      <OfficeTabMainPagePanel title={"Office Locations"} description={"Office locations"}>
        <VisitorRegistration isRequestAccess={true} />
      </OfficeTabMainPagePanel>
    ),
  };

  const serviceTab: TabType = {
    label: smAndUp ? t("Property Assist") : t("Service"),
    link: `/offices/service`,
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <BuildTwoToneIcon fontSize="small" />
      </Box>
    ),
    component: (
      <OfficeTabMainPagePanel title={"Office Locations"} description={"Property Assist"}>
        <ServiceTab />
      </OfficeTabMainPagePanel>
    ),
  };

  let tabs: TabType[] = [officesTab, requestAccessTab, serviceTab];

  const handleChange = (tabLink: string) => {
    history.push(tabLink);
    setActiveTab(tabLink);
  };

  const getTabType = (): "fullWidth" | "standard" => {
    if (smAndUp) {
      return "standard";
    } else {
      return "fullWidth";
    }
  };

  const currentTab = history.location.pathname;
  const selectedTab = tabs.find((tab) => currentTab === tab.link);

  return (
    <div>
      <Tabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(event, value) => handleChange(value)}
        variant={getTabType()}
      >
        {tabs.map((tab) => (
          <Tab label={tab.label} icon={tab.icon} key={tab.link} value={tab.link} />
        ))}
      </Tabs>
      <Paper elevation={0} style={{ backgroundColor: "white" }}>
        {selectedTab && selectedTab.component}
      </Paper>
    </div>
  );
});

export default OfficeTabMainPage;
function innerStyles() {
  throw new Error("Function not implemented.");
}
