import {
  Box,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ActiveTab from "components/meetings/MeetingsTab";
import { observer } from "mobx-react-lite";
import { useBAMStore, useUserStore } from "providers/RootStoreProvider";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { bhpColor } from "styles/globals";
import Topbar from "./Topbar";
import ListIcon from "@material-ui/icons/List";
import { User } from "interfaces/User";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: bhpColor.black,
    },
  })
);

interface MeetingTabPanelProps {
  children?: React.ReactNode;
}

const MeetingTabPanel = observer((props: MeetingTabPanelProps) => {
  const { children, ...other } = props;

  return (
    <div role="tabpanel" {...other}>
      <Box bgcolor="white" position="relative" px={2} py={3}>
        {children}
      </Box>
    </div>
  );
});

const Meetings = observer((props: any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));

  const activeTab = {
    label: "Open",
    link: "/meetings/open",
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <ListIcon fontSize="small" />
      </Box>
    ),
    component: (
      <MeetingTabPanel>
        <ActiveTab status={"open"} t={t} />
      </MeetingTabPanel>
    ),
  };

  const tabs = [activeTab];

  const handleChange = (tabLink: string) => {
    history.push(tabLink);
  };
  const getTabType = (): "fullWidth" | "standard" => {
    if (smAndUp) {
      return "standard";
    } else {
      return "fullWidth";
    }
  };
  const currentTab = history.location.pathname.includes("open")
    ? history.location.pathname
    : history.location.pathname + "/open";

  const selectedTab = tabs.find((tab) => currentTab === tab.link);

  return (
    <Fragment>
      <Topbar
        title={t("Meeting Information")}
        subtitle={t("Action on meetings")}
        breadcrumb={t("Meetings")}
      />
      <Box p={{ xs: 0, sm: 2, md: 4 }}>
        <Tabs
          value={currentTab}
          variant={getTabType()}
          onChange={(event, value) => handleChange(value)}
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.link} icon={tab.icon} value={tab.link} label={t(tab.label)} />
          ))}
        </Tabs>
        {selectedTab && selectedTab.component}
      </Box>
    </Fragment>
  );
});

export default Meetings;
