import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { bhpColor } from "styles/globals";
import { useBuildingStore, useTeamStore, useUserStore } from "providers/RootStoreProvider";
import { BuildingWithCapacity } from "interfaces/Building";
import OfficeSummaryCard from "./OfficeSummaryCard";
import { SettingsSelectBox } from "components/settings/SettingsSelectBox";
import OfficeSearchResults from "./OfficeSearchResults";
import { useHistory } from "react-router-dom";
import { toJS } from "mobx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: bhpColor.blueGrey1,
      padding: "2px 4px",
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
    },
    paper: {
      height: 40,
    },
    recordsFoundLabel: {
      color: "#E65400",
      fontFamily: "Arial",
      fontStyle: "italic",
      padding: "1%",
    },
    iconButton: {
      padding: 8,
    },
    selectBox: {
      display: "block",
      "& select": {
        width: "100%",
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
        "&:focus": {
          backgroundColor: "unset",
        },
      },
      "& fieldset": {
        height: 40,
        top: -3,
        border: 0,
      },
      "&.MuiInputBase-root": {
        color: bhpColor.blueGrey1,
      },
    },
  })
);

interface OfficeMainPageProps {
  myOffice: BuildingWithCapacity | null;
  t: any;
}

const OfficeMainPage = observer((props: OfficeMainPageProps) => {
  const { myOffice, t } = props;
  const buildingStore = useBuildingStore();
  const userStore = useUserStore();
  const teamStore = useTeamStore();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));

  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const doSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchT = event.target.value;
    setSearchTerm(searchT);
    setFilteredList(selectedCountry, searchT);
  };

  const officeSelect = (officeId: number) => {
    const newUrl = `/offices/${officeId}/capacity`;
    history.push(newUrl);
  };

  const sortAlphaNumeric = (a: any, b: any) => {
    // convert to strings and force lowercase
    a = typeof a === "string" ? a.toLowerCase() : a.toString();
    b = typeof b === "string" ? b.toLowerCase() : b.toString();
    return a.localeCompare(b, "en", { numeric: true });
  };

  const countryFilter = (country: string) => {
    setSelectedCountry(country);
    setFilteredList(country);
  };

  const uniqueBy = (arr: any[], prop: string) => {
    return arr.reduce((a, d) => {
      if (!a.find((v: any) => v.label === d[prop])) {
        a.push({ label: d[prop], value: d[prop] });
      }
      return a;
    }, []);
  };

  const setFilteredList = (country: string = selectedCountry, searchT: string = searchTerm) => {
    buildingStore.setFilteredList(country, searchT);
  };

  useEffect(() => {
    if (!buildingStore.isLoadingCapacity) setFilteredList();
  }, [buildingStore.isLoadingCapacity]);

  useEffect(() => {
    if (buildingStore.buildings.length > 0) setFilteredList();
  }, []);

  useEffect(() => {
    // yes i know this 0 is a magic number, but I've already checked if team exists, and it can only be a number
    // so the magic numer is a work around for an error that shouldn't exist
    if (!userStore.isLoading && userStore.me && userStore.me.profile.team)
      buildingStore.loadTeamBuildings(userStore.me.profile.team || 0);
  }, [userStore.me]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Box>
        <Typography style={{ fontSize: 26 }} variant="h1">
          {t("My Offices")}
        </Typography>
        <Box mt={1}>{t("Your favourite offices")}</Box>
        <Box paddingTop={2}>
          <Grid container spacing={3}>
            <Grid item sm={12} lg={6}>
              <OfficeSummaryCard
                t={t}
                onOfficeSelect={officeSelect}
                isDefault={true}
                building={myOffice}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        paddingTop={4}
        style={{ display: buildingStore.teamLocations.length > 0 ? "block" : "none" }}
      >
        <Typography style={smAndUp ? { fontSize: 26 } : { fontSize: 22 }} variant="h1">
          {t("My Team's Offices")}
        </Typography>
        <Typography style={smAndUp ? {} : { fontSize: `14px` }}>
          {t("BHP Office locations linked to your default team")}
        </Typography>
        <Box paddingTop={2}>
          <Grid container spacing={4}>
            {buildingStore.teamLocations.map((teamBuilding, idx) => {
              return (
                <Grid item sm={12} md={6} lg={6} key={`oscg${idx}`}>
                  <OfficeSummaryCard
                    t={t}
                    onOfficeSelect={officeSelect}
                    key={`osc${idx}`}
                    building={buildingStore.getBuilding(teamBuilding.id)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Box mt={3}>
        <h2 style={{ color: "#5C879C" }}>{t("Search")}</h2>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} style={smAndUp ? {} : { paddingBottom: 0 }}>
            <TextField
              id="search"
              label={t("Search")}
              placeholder={t("Search")}
              size="small"
              variant="outlined"
              fullWidth
              style={{ backgroundColor: "white" }}
              autoComplete="off"
              onChange={doSearch}
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} sm={6} md={4}>
              <Paper variant="outlined" className={classes.paper} elevation={0}>
                <SettingsSelectBox
                  t={t}
                  phText={t("Country")}
                  phValue={null}
                  defaultValue={selectedCountry}
                  cssClass={classes.selectBox}
                  theKey={"countrySelector"}
                  handleChange={countryFilter}
                  theId="city"
                  theItems={uniqueBy(buildingStore.buildingsWithCapacity, "country").sort(
                    (a: any, b: any) => sortAlphaNumeric(a.label, b.label)
                  )}
                />
              </Paper>
            </Grid>
          </Hidden>
          <Grid item sm={12} md={12} style={smAndUp ? {} : { paddingTop: 0 }}>
            <Paper
              elevation={0}
              style={smAndUp ? { backgroundColor: "white" } : { backgroundColor: "transparent" }}
            >
              <Hidden smDown>
                <Box paddingTop={mdAndUp ? 2 : 0} paddingLeft={mdAndUp ? 4 : 1}>
                  {buildingStore.buildingCapacityFiltered.length > 0 && (
                    <div className={classes.recordsFoundLabel}>
                      {buildingStore.buildingCapacityFiltered.length} {t("results found")}
                    </div>
                  )}
                </Box>
              </Hidden>
              <Box
                padding={{ xs: 0, md: 4 }}
                paddingTop={{ xs: 0, md: 0 }}
                style={{ textAlign: "center", color: `${bhpColor.grey1}80` }}
              >
                <OfficeSearchResults
                  t={t}
                  results={buildingStore.buildingCapacityFiltered
                    .slice()
                    .sort((a: any, b: any) => sortAlphaNumeric(a.city, b.city))}
                  onOfficeSelect={officeSelect}
                  rowCount={buildingStore.bwcTotalRecordCount}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
});

export default OfficeMainPage;
