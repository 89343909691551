import { useEffect, useState } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  ButtonGroup,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { bhpColor } from "styles/globals";
import { useTranslation } from "react-i18next";
import DateButton from "./DateButton";
import Events from "./Events";
import { EventDates } from "interfaces/Event";
import { BuildingWithCapacity } from "interfaces/Building";
import { useHistory } from "react-router-dom";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    blueButton: {
      borderColor: bhpColor.blueGrey2,
      fontSize: 14,
      color: bhpColor.blueGrey2,
      fontWeight: "bold",
      textTransform: "unset",
      whiteSpace: "nowrap",
    },
  })
);

interface EventsTabProps {
  building: BuildingWithCapacity;
  eventId: number | null;
}

const EventsTab = observer((props: EventsTabProps) => {
  const { building, eventId } = props;
  const classes = innerStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [selectedDateFilter, setSelectedDateFilter] = useState<EventDates | null>(
    eventId ? null : EventDates.ThisMonth
  );
  const [eventIdFromURL, setEventIdFromURL] = useState<number | null>(eventId);
  const handleDateFilter = (value: string) => {
    setEventIdFromURL(null);
    setSelectedDateFilter((EventDates as any)[value]);
  };
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));

  return (
    <Box className={classes.blueButton}>
      {mdAndUp ? (
        <ButtonGroup>
          {Object.keys(EventDates).map((date, idx) => {
            return (
              <DateButton
                key={idx}
                value={date}
                text={(EventDates as any)[date]}
                onClickHandler={handleDateFilter}
                selectedDateFilter={selectedDateFilter}
              />
            );
          })}
        </ButtonGroup>
      ) : (
        <ButtonGroup style={{ paddingTop: 20 }}>
          {Object.keys(EventDates).map((date, idx) => {
            return (idx + 1) % 2 ? (
              <DateButton
                key={idx}
                value={date}
                text={(EventDates as any)[date]}
                onClickHandler={handleDateFilter}
                selectedDateFilter={selectedDateFilter}
              />
            ) : (
              ""
            );
          })}
        </ButtonGroup>
      )}
      <Events
        selectedDateFilter={selectedDateFilter}
        building={building}
        eventId={eventIdFromURL}
      />
    </Box>
  );
});
export default EventsTab;
