import {
  Avatar,
  Box,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
  Badge,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { bhpColor, settings } from "styles/globals";
import { isWeekend } from "utils/date";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Icon from "@mdi/react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { mdiLessThan, mdiGreaterThan } from "@mdi/js";
import { AvatarGroup } from "@material-ui/lab";
import { EventSeat } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

interface AvatarRowProps {
  userSchedules: any[] | null;
  maxAvatarCount?: number;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatars: {
      "& .MuiAvatarGroup-avatar": {
        borderRadius: 25,
      },
      "& .MuiAvatar-colorDefault": {
        color: bhpColor.white,
        backgroundColor: bhpColor.blueGrey1,
        fontSize: 14,
      },
      "& .MuiBadge-colorSecondary": {
        border: `1px solid white`,
      },
      "& .MuiAvatarGroup-root": {
        justifyContent: "flex-end",
      },
    },
    moreAvatars: {
      "& .MuiAvatar-colorDefault:last-child ": {
        color: bhpColor.white,
        backgroundColor: bhpColor.orange1,
        fontSize: 14,
      },
    },
  })
);

const AvatarRow = observer((props: AvatarRowProps) => {
  const { userSchedules, maxAvatarCount = 6 } = props;
  const classes = innerStyles();
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));
  const history = useHistory();

  const extrasArray =
    userSchedules && userSchedules.slice().length > maxAvatarCount
      ? userSchedules
          .slice()
          .sort((a: any, b: any) => (a.first_name < b.first_name ? -1 : 1))
          .slice(maxAvatarCount - 1)
      : null;

  const today = new Date();
  const redirectToPeopleProfile = (userId: number) => {
    const newUrl = `/people/${userId}`;
    history.push(newUrl);
  };
  return (
    <Box className={`${classes.avatars}`}>
      <AvatarGroup
        max={maxAvatarCount}
        className={`${
          userSchedules && userSchedules.slice().length > maxAvatarCount ? classes.moreAvatars : ""
        }`}
        style={{ display: "inline-flex", borderRadius: "25px" }}
      >
        {userSchedules
          ? userSchedules
              .slice()
              .sort((a: any, b: any) => (a.first_name < b.first_name ? -1 : 1))
              .slice(0, maxAvatarCount)
              .map((user, index) => {
                return (
                  <Tooltip
                    title={`${
                      user.preferred_name
                        ? `${user.preferred_name}`
                        : `${user.first_name} ${user.last_name}`
                    } ${user.space_reference ? `(${user.space_reference})` : ""}`}
                    key={index}
                  >
                    <Badge
                      color="primary"
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      variant="standard"
                      invisible={!user.space_reference}
                      badgeContent={<img src={require(`assets/deskBadge.png`)} height={"15px"} />}
                    >
                      <Avatar
                        onClick={() => redirectToPeopleProfile(user.userId)}
                        style={{ cursor: "pointer" }}
                        src={user.user_picture}
                      >
                        {user.first_name.charAt(0) + user.last_name.charAt(0)}
                      </Avatar>
                    </Badge>
                  </Tooltip>
                );
              })
          : null}
      </AvatarGroup>
      {extrasArray ? (
        <Tooltip
          title={
            <>
              {extrasArray.map((user) => {
                return (
                  <span>
                    {user.first_name} {user.last_name}
                    <br />
                  </span>
                );
              })}
            </>
          }
        >
          <Box
            display={"inline-block"}
            position={"relative"}
            style={{ zIndex: 100, marginLeft: -28, cursor: "pointer", width: 30, height: 20 }}
          ></Box>
        </Tooltip>
      ) : null}
    </Box>
  );
});

export default AvatarRow;
