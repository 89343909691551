import {
  Box,
  createStyles,
  Hidden,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { bhpColor, settings } from "styles/globals";
import { isWeekend } from "utils/date";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Icon from "@mdi/react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { mdiLessThan, mdiGreaterThan } from "@mdi/js";
import HolidayPopover from "components/utils/HolidayPopover";

interface CalendarTabHeadRowProps {
  selectedDate: string;
  dateRange: string[];
  changeDate: any;
  t: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    today: {},
    selected: {
      backgroundColor: "white",
      borderRadius: settings.borderRadius,
      border: `1px solid ${bhpColor.blueGrey1} !important`,
    },
    dateHover: {
      border: `1px solid transparent`,
      "&:hover": {
        borderRadius: settings.borderRadius,
        border: `1px solid ${bhpColor.blueGrey1}`,
      },
    },
    weekend: { backgroundColor: `${bhpColor.blueGrey4}30` },
    table: {
      "& .MuiBox-root": {
        maxWidth: "60px",
        [theme.breakpoints.down("md")]: {
          padding: 0,
        },
      },
      "& .MuiTableCell-root": {
        color: bhpColor.blueGrey1,
        textAlign: "-webkit-center",
        borderBottom: 0,
        padding: theme.spacing(2),
        [theme.breakpoints.down("lg")]: {
          padding: theme.spacing(1),
        },
        [theme.breakpoints.down("md")]: {
          paddingTop: 8,
          paddingBottom: 8,
          padding: 0,
        },
      },
    },
    dayOfMonth: {
      fontWeight: "bold",
      fontSize: 20,
      paddingTop: 12,
      color: bhpColor.blueGrey1,
    },
    dayOfWeek: {
      color: bhpColor.blueGrey2,
      fontSize: 12,
    },
    dayText: {
      marginLeft: "auto",
      marginRight: "auto",
      width: 52,
      height: 66,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        width: "unset",
        marginRight: "4px",
        marginLeft: "4px",
      },
    },
  })
);

const CalendarTabHeadRow = observer((props: CalendarTabHeadRowProps) => {
  const { dateRange, selectedDate, changeDate, t } = props;
  const classes = innerStyles();
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));

  const setDate = (dte: string) => {
    changeDate(dte);
  };

  const today = new Date();
  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow>
          {/* <TableCell style={{ cursor: "pointer" }} onClick={() => scrollLeft()}>
            <Icon path={mdiLessThan} size={1} />
          </TableCell> */}
          {dateRange.map((val, idx) => {
            return (
              <TableCell
                style={{ cursor: "pointer" }}
                key={`thc${idx}`}
                className={` ${isWeekend(new Date(val || "")) ? classes.weekend : ""}`}
                onClick={() => setDate(val)}
              >
                <Box
                  //p={{ sm: 1, md: 2 }}
                  style={{ textAlign: "center", position: "relative" }}
                  className={`${classes.dateHover} ${
                    moment(today).isSame(val, "day") ? classes.today : ""
                  } ${moment(selectedDate).isSame(val, "day") ? classes.selected : ""} ${
                    classes.dayText
                  }`}
                >
                  <Typography className={classes.dayOfMonth}>{moment(val).format("D")}</Typography>
                  <Typography className={classes.dayOfWeek}>
                    {t(moment(val).format("ddd"))}
                  </Typography>
                  <HolidayPopover date={val} />
                </Box>
              </TableCell>
            );
          })}
          {/* <TableCell style={{ cursor: "pointer" }} onClick={() => scrollRight()}>
            <Icon path={mdiGreaterThan} size={1} />
          </TableCell> */}
        </TableRow>
      </TableBody>
    </Table>
  );
});

export default CalendarTabHeadRow;
