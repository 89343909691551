import { ScheduleType, SettingsScheduleType } from "./Schedule";

export interface SettingsWdpType {
  user?: number;
  team: number;
  default_office: number;
  remote_timezone: string;
  default_week: {
    mon: ScheduleType;
    tue: ScheduleType;
    wed: ScheduleType;
    thu: ScheduleType;
    fri: ScheduleType;
    sat: ScheduleType;
    sun: ScheduleType;
    mon_default: SettingsScheduleType;
    tue_default: SettingsScheduleType;
    wed_default: SettingsScheduleType;
    thu_default: SettingsScheduleType;
    fri_default: SettingsScheduleType;
    sat_default: SettingsScheduleType;
    sun_default: SettingsScheduleType;
  };
  private_schedule: boolean;
}

export const wdpConstants = {
  privacyVisible: {
    value: false,
    text: "Visible",
    ariaLabel: "visible",
  },
  privacyPrivate: {
    value: true,
    text: "Private",
    ariaLabel: "private",
  },
};

export const wdPDefaults = {
  privacyDefault: wdpConstants.privacyVisible,
  teamChangeUrl:
    "https://spo.bhpbilliton.com/sites/globalproperty/Shared Documents/Forms/AllItems.aspx?id=%2Fsites%2Fglobalproperty%2FShared%20Documents%2FMy%20Workday%20platform%2FMy%20Workday%20Platform%5FHow%20to%5FUpdating%20your%20information%2Epdf&parent=%2Fsites%2Fglobalproperty%2FShared%20Documents%2FMy%20Workday%20platform",
};
