import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { BuildingWithCapacity } from "interfaces/Building";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useRootStore } from "providers/RootStoreProvider";
import { bhpColor } from "styles/globals";
import { drawDot, getIndicatorColor, getPercentage } from "../utils/CapacityUtils";

interface CapacityHeadCellProps {
  buildingDetail: BuildingWithCapacity | null;
  date: Date;
  t: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    today: {},
  })
);

const CapacityHeadCell = observer((props: CapacityHeadCellProps) => {
  const { buildingDetail, date, t } = props;
  const classes = innerStyles();
  const store = useRootStore();

  const bookableDesks = buildingDetail ? buildingDetail.total_capacity || null : null;

  const indicatorColor = getIndicatorColor(
    bookableDesks ? getPercentage(bookableDesks, buildingDetail?.booked_attendance || 0) : 0
  );

  return (
    <Grid container style={{ paddingTop: "8px", paddingBottom: "8px" }}>
      <Grid item xs={12}>
        {drawDot(15, indicatorColor)}
      </Grid>
      <Grid item xs={12} style={{ fontWeight: 700, fontSize: "16px" }}>
        {moment(date).format("DD")}
      </Grid>
      <Grid item xs={12} style={{ fontSize: "12px" }}>
        {moment(date).format("ddd")}
      </Grid>
      <Grid item xs={12}>
        <hr
          style={{
            backgroundColor: bhpColor.grey3,
            height: "1px",
            border: 0,
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
      </Grid>
      <Grid item xs={12} style={{ fontWeight: 700, color: bhpColor.blueGrey1 }}>
        {buildingDetail?.planned_attendance || 0}
      </Grid>
      <Grid item xs={12} style={{ fontWeight: 700, color: bhpColor.orange1 }}>
        {buildingDetail?.booked_attendance || 0}
      </Grid>
    </Grid>
  );
});

export default CapacityHeadCell;
