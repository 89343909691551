export const getSupportLinks = (type: string): string => {
  switch (type.toLowerCase()) {
    case "hybridworking":
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/Hybrid-Working.aspx";
    case "myworkday":
      return "https://spo.bhpbilliton.com/sites/globalproperty/SitePages/My-Workday-info-and-support-centre.aspx";
    default:
      return "";
  }
};
