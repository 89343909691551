import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  useMediaQuery,
  Grid,
  Paper,
  Typography,
  TextField,
  Select,
  Button,
  FormControl,
  Snackbar,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useBuildingStore, useTeamStore, useUserStore } from "providers/RootStoreProvider";
import { bhpColor, displaySettings, settings } from "styles/globals";
import InformationIcon from "components/utils/InformationIcon";
import { Fragment, useEffect, useState, useCallback } from "react";
import { PopupInformationWidget } from "components/settings/PopupInformationWidget";
import { Activity, AnchorDays, Team, TeamCategoryType, TeamVisibilityType } from "interfaces/Teams";
import { SettingsSelectBox } from "components/settings/SettingsSelectBox";
import { capitalizeFirstLetter, capitalizeString } from "utils/string";
import moment from "moment";
import { toJS } from "mobx";
import { get, set, isEqual } from "lodash";
import { useHistory } from "react-router";
import { getTeamCategoryString, getTeamVisibilityString } from "utils/teams";
import LoadingZone from "components/schedule/LoadingZone";
import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { weekDayNamesSorter } from "utils/date";
import ImageViewer from "react-simple-image-viewer";
import logo from "assets/workday-vertical.png";
import TeamIloRoutineViewer from "./TeamIloRoutineViewer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import HeaderImage from "assets/headerImage.png";

interface TeamDetailsInformationTabProps {
  teamId: number;
  t: any;
}

// #region STYLES
const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartOuter: {
      border: `1px solid ${bhpColor.blueGrey4}`,
      [theme.breakpoints.down("sm")]: {
        border: 0,
        paddingLeft: 0,
      },
    },
    mainTable: {
      "& td": {
        // borderBottom: 0,
      },
    },
    textArea: {
      height: 32,
      width: "100%",
      paddingLeft: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      backgroundColor: `${bhpColor.blueGrey4}50`,
    },
    avatar: {
      backgroundColor: "rgb(250, 182, 54)",
      color: "white",
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginLeft: "16px",
      fontSize: "16px",
    },
    h4: {
      fontWeight: "bold",
      color: bhpColor.blueGrey1,
      fontSize: 16,
      display: "inline-block",
    },
    wrapper: {
      marginTop: theme.spacing(2),
    },
    team: {
      height: 36,
      width: "calc(100% - 150px)",
      paddingLeft: theme.spacing(1),
      backgroundColor: `${bhpColor.blueGrey4}50`,
      display: "inline-flex",
      verticalAlign: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    datePicker: {
      width: "calc(100% - 150px)",
      borderRadius: settings.borderRadius,
      border: `1px solid ${bhpColor.grey3}`,
      backgroundColor: "white",
      "& .MuiFormLabel-root": {
        color: bhpColor.blueGrey1,
        fontSize: 16,
        fontWeight: 700,
      },
      "& .MuiInput-root": {
        paddingLeft: theme.spacing(1),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `0 !important`,
        transition: "none",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    textField: {
      width: "calc(100% - 150px)",
      borderRadius: settings.borderRadius,
      backgroundColor: "white",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      "& .MuiInput-root": {
        paddingLeft: theme.spacing(1),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `0 !important`,
        transition: "none",
      },
      "& .Mui-focussed": {
        borderBottom: `0 !important`,
      },
    },
    formControl: {
      display: "block",
      width: "100%",
      margin: 0,
    },
    selectBox: {
      width: "calc(100% - 150px)",
      height: 40,
      "& svg": {
        zIndex: 2,
      },
      "& select": {
        zIndex: 2,
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& fieldset": {
        zIndex: 1,
        height: 40,
        top: -3,
        backgroundColor: "white",
        "& .Mui-focussed": {
          backgroundColor: "white",
        },
      },
    },
    selectInput: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    buttonRow: {
      marginTop: theme.spacing(2),
      textAlign: "start",
    },
    saveFailed: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.purple1,
        fontWeight: "bold",
        backgroundColor: bhpColor.purple4,
        border: `2px solid ${bhpColor.purple1}`,
        borderRadius: settings.borderRadius,
      },
    },
    saveSuccess: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.orange1,
        fontWeight: "bold",
        backgroundColor: bhpColor.orange4,
        border: `2px solid ${bhpColor.orange1}`,
        borderRadius: settings.borderRadius,
      },
    },
    anchorDayButton: {
      fontSize: 20,
      color: bhpColor.blueGrey1,
      borderRadius: settings.borderRadius,
      borderWidth: 2,
      borderColor: bhpColor.blueGrey1,
      borderStyle: "solid",
      backgroundColor: bhpColor.blueGrey4,
      textTransform: "none",
      "&.selected": {
        backgroundColor: bhpColor.blueGrey1,
        color: bhpColor.white,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        height: 50,
        width: 50,
        marginRight: 6,
        minWidth: 50,
      },
    },
    dialogTitle: {
      backgroundImage: `url(${HeaderImage})`,
      backgroundSize: "100% auto",
      color: bhpColor.white,
      "& h2": {
        fontWeight: "bold",
        "& svg": {
          verticalAlign: "text-top",
        },
      },
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    base: {
      color: bhpColor.blueGrey1,
      "& .MuiPaper-root": {
        color: bhpColor.blueGrey1,
      },
      "& .MuiDialog-paper": {
        maxWidth: "calc(100% - 64px)",
      },
      "& .MuiDialog-paperFullScreen": {
        maxWidth: "90%",
        maxHeight: "90%",
      },
      "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
      },
    },
  })
);
// #endregion STYLES

interface DialogData {
  title: string | null;
  content: string | null;
  primaryButtonText: string | null;
  secondaryButtonText?: string | null;
  handleClose?: any;
}

const TeamDetailsInformationTab = observer((props: TeamDetailsInformationTabProps) => {
  const { teamId, t } = props;
  const classes = innerStyles();
  const teamStore = useTeamStore();
  const userStore = useUserStore();
  const history = useHistory();
  const buildingStore = useBuildingStore();
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));

  const textAreaRows = 6;
  const textAreaMaxCharacters = 250;
  const [formValid, setFormValid] = useState<boolean>(false);

  const isCreate = !teamId;

  const includeEndDateInput = false;
  const [anchorDays, setAnchorDays] = useState<AnchorDays>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const [amITeamMember, setAmITeamMember] = useState<boolean>(false);
  //#region Optional - End Date
  // --- Project Type End Date ---
  // Originally the End Date was needed in Figma design 1.0
  // then removed, but is likely to be needed in future
  // since the work was done on the front end it was moved here
  const includeProjectEndDate = () =>
    includeEndDateInput && Number(teamCategory) === TeamCategoryType.PROJECT;
  const [projectEndDate, setProjectEndDate] = useState(
    teamStore.currentTeam?.endDate
      ? moment(teamStore.currentTeam?.endDate, "DD-MM-YYYY").format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD")
  );
  const handleDateChange = (date: any) => setProjectEndDate(date.target.value);
  const getProjectEndDate = () => moment(projectEndDate).format("DD-MM-YYYY");
  const drawEndDate = () => {
    return includeEndDateInput &&
      teamCategory &&
      Number(teamCategory) === TeamCategoryType.PROJECT ? (
      <>
        {subHeadAndInfo("End Date", 7)}
        {canEdit ? (
          <TextField
            id="date"
            type="date"
            value={projectEndDate}
            onChange={handleDateChange}
            className={classes.datePicker}
          />
        ) : (
          <Paper variant="outlined" className={classes.team} key="pEnd">
            {teamStore.currentTeam?.endDate}
          </Paper>
        )}
      </>
    ) : null;
  };
  //#endregion Optional - End Date

  const saveChanges = async () => {
    const teamInformation = {
      name: teamName || "",
      category: teamCategory! as number,
      visibility: teamVisibility! as number,
      location: teamLocation !== "" ? (teamLocation as number) : null,
      description: teamDescription || "",
      anchor_days: anchorDays,
    };
    if (!isCreate && teamId) {
      const editedTeam: Team = { ...teamInformation, id: teamId };
      if (includeProjectEndDate()) set(editedTeam, "endDate", getProjectEndDate());
      const res = await teamStore.changeTeam(editedTeam);
      if (res) {
        setSnackBox(res.status === 200, t("Team Update Successful"));
        snackbarOpen();
        teamStore.setCurrentTeam(teamId);
      }
    } else {
      const res = await teamStore.createTeam(teamInformation);
      if (res) {
        const freshlyCreatedTeam: Team = { ...res.data };
        setSnackBox(res.status === 201, t("Team Update Issue"));
        snackbarOpen();
        teamStore.setCurrentTeam(freshlyCreatedTeam.id);
        jumpyToNewTeam(freshlyCreatedTeam.id);
      }
    }
  };
  const deleteTeam = () => {
    setDeleteTeamConfirmDialogIsOpen(true);
  };

  const jumpyToNewTeam = (newTeamId: number) => {
    history.push(`/teams/people/${newTeamId}`);
  };
  const [canEdit, setCanEdit] = useState(false);

  const [teamName, setTeamName] = useState<string>("");
  const handleNameChange = (newName: any) => {
    setTeamName(newName.target.value);
  };

  const [teamDescription, setTeamDescription] = useState<string>("");
  const handleDescriptionChange = (newValue: any) => {
    setTeamDescription(newValue.target.value);
  };

  const [teamCategory, setTeamCategory] = useState<number | string>("");
  const handleCategoryChange = (newCategory: any) => {
    setTeamCategory(newCategory.target.value);
  };

  const [teamVisibility, setTeamVisibility] = useState<number | string>("");
  const handleVisibilityChange = (newVisibility: any) => {
    setTeamVisibility(newVisibility.target.value);
  };

  const [teamLocation, setTeamLocation] = useState<number | string>("");
  const handleLocationChange = (newLocation: string) => {
    setTeamLocation(newLocation);
  };

  const [informationModal, setInformationModal] = useState<DialogData | null>();
  const [informationOpen, setInformationOpen] = useState(false);
  const [deleteTeamConfirmDialogIsOpen, setDeleteTeamConfirmDialogIsOpen] = useState(false);
  const handleInformationClose = () => setInformationOpen(false);
  const handleDeleteTeamDialogClose = () => setDeleteTeamConfirmDialogIsOpen(false);

  const handleInformationOpen = (infoId: number) => {
    openInformationModal(getModalProps(t, infoId));
  };
  const openInformationModal = (informationData: DialogData) => {
    setInformationModal(Object.assign({}, informationData));
    setInformationOpen(true);
  };

  const handleConfirmDeleteTeam = async () => {
    handleDeleteTeamDialogClose();
    if (teamId) {
      const res = await teamStore.deleteTeam(teamId);
      if (res) {
        if (res.status === 204) {
          teamStore.setTeamDeletedSnackMessage(t("Team deleted"));
          teamStore.setTeamDeleted(true);
        } else {
          teamStore.setTeamDeletedSnackMessage(t("Team delete unsuccessful"));
          teamStore.setTeamDeleted(false);
        }
        history.push(`/teams`);
      }
    }
  };

  const [snackOpen, setSnackOpen] = useState(false);
  const [saveState, setSaveState] = useState(true);
  const [snackMessage, setSnackMessage] = useState(t("Team Update Successful"));
  const snackbarOpen = () => setSnackOpen(true);
  const snackbarClose = () => setSnackOpen(false);
  const setSnackBox = (success: boolean, message: string) => {
    setSnackMessage(message);
    setSaveState(success);
  };

  const subHeadAndInfo = (label: string, infoId: number) => {
    return (
      <Box mb={1} mt={3} style={{ display: "flex" }}>
        <Typography className={classes.h4}>{t(label)}</Typography>
        <InformationIcon onclick={() => handleInformationOpen(infoId)} color={bhpColor.orange1} />
      </Box>
    );
  };

  const getItemsFromEnum = (theEnum: any) => {
    return Object.entries(theEnum)
      .filter(([key, value]) => {
        return !isNaN(Number(key));
      })
      .map(([key, value]) => ({
        value: key,
        label: capitalizeString(`${typeof value === "string" ? value.replace("_", " ") : value}`),
      }));
  };

  // #region GET DATA
  const getModalProps = (t: any, infoId: number) => {
    let title = "";
    let content = "";
    let primaryButtonText = "";
    let secondaryButtonText = "";
    let handleClose: any = handleInformationClose;
    switch (infoId) {
      case 1:
        title = t("Team Name");
        content = t("Team Name Content");
        primaryButtonText = t("Okay");
        break;
      case 2:
        title = t("Team Category");
        content = t(
          "<p>Team category means the type of team:</p><ul><li><strong>Social</strong> – a team established to connect with people that share similar interests outside of work. i.e.: running club, painting, meditation, toastmasters, or choir.</li><li><strong>Project</strong> – a team established for a specific project, which has a start and a finish date.</li><li><strong>Functional</strong> – a team made up by individuals that are part of your org unit. i.e.: Corporate Affairs, Treasury, Strategy and Market Intelligence</li><li><strong>Cross-functional</strong> – a team made up by individuals that belong to different functions that get together on an ongoing basis: i.e.: a working group</li></ul></p>"
        );
        primaryButtonText = t("Okay");
        break;
      case 3:
        title = t("Visibility");
        content = t(
          "<p>This defines the visibility of a team in My Workday platform.<ul><li><strong>Private</strong> – private teams are not visible from the search results on the ‘People’ page in My Workday. Only team Owners can add members.</li><li><strong>Visible</strong> – visible teams are visible to everyone in My Workday. Anyone can join a visible team.</li><li><strong>Closed</strong>- closed teams are visible to everyone in My Workday. Only users added to a closed team by a team Owner can be part of a closed team.</li></ul></p>"
        );
        primaryButtonText = t("Okay");
        break;
      case 4:
        title = t("Location");
        content = t(
          "Select the relevant building if a team is location-specific. If a team is more of a virtual and/or distributed team, please select 'Global.'"
        );
        primaryButtonText = t("Okay");
        break;
      case 5:
        title = t("Description");
        content = t("Enter a short description of the purpose of the team (max. 250 characters).");
        primaryButtonText = t("Okay");
        break;
      case 6:
        title = t("Owners");
        content = t("Users that can add members to private and closed teams.");
        primaryButtonText = t("Okay");
        break;
      case 7:
        title = t("Delete Team");
        content = t(`Are you sure you want to delete ${teamName} team ?`);
        primaryButtonText = t("Delete Team");
        break;
      case 8:
        title = t("Anchor Days");
        content = t(
          `Days designated for team members to get together in the office. These days will be on the Team Information page. `
        );
        primaryButtonText = t("Okay");
        break;
      case 9:
        title = t("Team ILO");
        content = t(`Displays all ILO activity view of the team.  `);
        primaryButtonText = t("Okay");
        break;
    }
    return { title, content, primaryButtonText, secondaryButtonText, handleClose };
  };
  // #endregion GET DATA

  useEffect(() => {
    buildingStore.loadBuildings();
    if (teamId) teamStore.setCurrentTeam(teamId);
    else setCanEdit(true);
  }, [buildingStore, teamStore, teamId]);

  useEffect(() => {
    (async () => {
      await teamStore.loadMyTeams();
      const teamDetails =
        teamStore.myTeams.find((team: Team) => team.id.toString() === teamId.toString()) || null;
      if (teamDetails) {
        setAmITeamMember(true);
      }
    })();
  }, [teamId]);

  useEffect(() => {
    setCanEdit(true);
    if (!teamStore.isLoading && teamStore.currentTeam && teamId) {
      setTeamName(get(teamStore.currentTeam, "name", ""));
      setTeamCategory(get(teamStore.currentTeam, "category", ""));
      if (teamCategory == 0) {
        setCanEdit(false);
      }
      setTeamVisibility(get(teamStore.currentTeam, "visibility", ""));
      setTeamLocation(get(teamStore.currentTeam, "location", "") || "");
      setTeamDescription(get(teamStore.currentTeam, "description", ""));
      setAnchorDays(
        get(teamStore.currentTeam, "anchor_days", {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        })
      );
      setProjectEndDate(
        teamStore.currentTeam.endDate
          ? moment(teamStore.currentTeam?.endDate, "DD-MM-YYYY").format("YYYY-MM-DD")
          : moment(new Date()).format("YYYY-MM-DD")
      );
      setCanEdit(userStore?.me?.id ? teamStore.amIOwner(userStore.me.id) : false);
    }
  }, [teamStore.isLoading, teamStore.currentTeam]);

  const checkFormAndSetIfValid = () => {
    setFormValid(teamName !== "" && teamCategory !== "" && teamVisibility !== "");
  };

  useEffect(() => {
    checkFormAndSetIfValid();
  }, [teamName, teamCategory, teamVisibility, teamLocation, teamDescription]);

  const updateAnchorDays = async (anchorDays: AnchorDays) => {
    if (teamId) {
      const res = await teamStore.updateAnchorDays(teamId, anchorDays);
      if (res) {
        setSnackBox(res.status === 200, t("Anchor Days Update Successful"));
        snackbarOpen();
      } else {
        setSnackBox(res.status === 201, t("Anchor Days Update Issue"));
        snackbarOpen();
      }
    }
  };

  const [isRoutineOpen, setIsRoutineOpen] = useState(false);
  const handleClose = () => {
    setIsRoutineOpen(false);
  };

  const openILO = (event: Activity | null) => {
    setIsRoutineOpen(true);
  };

  const drawAnchorDay = (wd: string, anchorDays: AnchorDays, amITeamMember: boolean) => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <Checkbox
          onClick={() => {
            anchorDays[wd.toLowerCase()] = !anchorDays[wd.toLowerCase()];
            setAnchorDays(anchorDays);
          }}
          key={wd}
          className={`${anchorDays[wd.toLowerCase()] === true ? "selected" : ""}`}
          checked={anchorDays[wd.toLowerCase()] === true ? true : false}
          disabled={!amITeamMember}
        />
        {mdAndUp ? capitalizeFirstLetter(wd) : capitalizeFirstLetter(wd).substring(0, 3)}
      </FormControl>
    );
  };

  return (
    <>
      <LoadingZone isLoading={teamStore.isDeletingTeam} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={saveState ? classes.saveSuccess : classes.saveFailed}
        autoHideDuration={displaySettings.snackDelay}
        open={snackOpen}
        onClose={snackbarClose}
        message={snackMessage}
      />
      <Box py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }}>
        <Typography variant="h3">{isCreate ? t("Create Team") : t("Team Information")}</Typography>
        <Box mt={1}>
          {isCreate
            ? t("Provide your team’s information for others to see")
            : canEdit
            ? t("Add and edit your team’s information for others to see")
            : t("Viewing your team’s information")}
        </Box>
        <Box my={{ xs: 0, md: 4 }}>
          <Grid container className={classes.mainTable}>
            <Grid item sm={12} md={6}>
              <div className={classes.wrapper} key="divName">
                {subHeadAndInfo("Name", 1)}
                {canEdit ? (
                  <TextField
                    key="tName"
                    id="teamName"
                    placeholder={t("Enter a team name (required)")}
                    type="text"
                    size="small"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    onChange={handleNameChange}
                    value={teamName}
                  />
                ) : (
                  <Paper variant="outlined" className={classes.team} key="name">
                    {teamName}
                  </Paper>
                )}
              </div>
              <div className={classes.wrapper} key="divCat">
                {subHeadAndInfo(t("Category"), 2)}
                {canEdit ? (
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      native
                      className={classes.selectBox}
                      placeholder={`${t("Choose team category")}...`}
                      onChange={handleCategoryChange}
                      value={teamCategory}
                    >
                      <option key={`sc1`} value="">
                        {t(`Select category`)}
                      </option>
                      {getItemsFromEnum(TeamCategoryType)
                        .filter((typ) => typ.value !== "0")
                        .map((itm, key) => {
                          return (
                            <option key={key} value={itm.value}>
                              {itm.label}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>
                ) : (
                  <Paper variant="outlined" className={classes.team} key="category">
                    {teamCategory !== ""
                      ? capitalizeString(getTeamCategoryString(teamCategory as unknown as number))
                      : ""}
                  </Paper>
                )}
                {drawEndDate()}
              </div>
              <div className={classes.wrapper} key="divClass">
                {subHeadAndInfo(t("Visibility"), 3)}
                {canEdit ? (
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      native
                      className={classes.selectBox}
                      placeholder={`${t("Choose team visibility")}...`}
                      onChange={handleVisibilityChange}
                      value={teamVisibility}
                    >
                      <option key={`sv1`} value="">
                        {t(`Select visibility`)}
                      </option>
                      {getItemsFromEnum(TeamVisibilityType).map((itm, key) => {
                        return (
                          <option key={key} value={itm.value}>
                            {itm.label}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <Paper variant="outlined" className={classes.team} key="visibility">
                    {teamVisibility !== ""
                      ? capitalizeString(TeamVisibilityType[teamVisibility as number])
                      : ""}
                  </Paper>
                )}
              </div>
              <div className={classes.wrapper} key="divLocation">
                {subHeadAndInfo(t("Location"), 4)}
                {canEdit ? (
                  <SettingsSelectBox
                    t={t}
                    cssClass={classes.selectBox}
                    phText={t("Global")}
                    phValue={null}
                    theItems={buildingStore.buildings
                      .slice()
                      .sort((a, b) => (a.city < b.city ? -1 : 1))}
                    labelField={"display_name"}
                    valueField={"id"}
                    handleChange={handleLocationChange}
                    defaultValue={teamLocation}
                    theId={"location"}
                    theKey={"locationKey"}
                  />
                ) : (
                  <Paper variant="outlined" className={classes.team} key="location">
                    {teamLocation !== ""
                      ? buildingStore.getBasicBuilding(teamLocation as number)?.display_name
                      : "Global"}
                  </Paper>
                )}
              </div>

              {amITeamMember && (
                <div className={classes.wrapper} key="divAnchorDay">
                  {subHeadAndInfo("Anchor Days", 8)}
                  <Grid container style={{ display: "flex", fontSize: "14px" }}>
                    <Grid item xs={12} style={{ display: "flex" }}>
                      <Grid item xs={3} style={{ whiteSpace: "nowrap" }}>
                        {drawAnchorDay("Monday", anchorDays, amITeamMember)}
                      </Grid>
                      <Grid item xs={3} style={{ whiteSpace: "nowrap" }}>
                        {drawAnchorDay("Tuesday", anchorDays, amITeamMember)}
                      </Grid>
                      <Grid item xs={3} style={{ whiteSpace: "nowrap" }}>
                        {drawAnchorDay("Wednesday", anchorDays, amITeamMember)}
                      </Grid>
                      <Grid item xs={3} style={{ whiteSpace: "nowrap" }}>
                        {drawAnchorDay("Thursday", anchorDays, amITeamMember)}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", fontSize: "14px" }}>
                      <Grid item xs={3} style={{ whiteSpace: "nowrap" }}>
                        {drawAnchorDay("Friday", anchorDays, amITeamMember)}
                      </Grid>
                      <Grid item xs={3} style={{ whiteSpace: "nowrap" }}>
                        {drawAnchorDay("Saturday", anchorDays, amITeamMember)}
                      </Grid>
                      <Grid item xs={3} style={{ whiteSpace: "nowrap" }}>
                        {drawAnchorDay("Sunday", anchorDays, amITeamMember)}
                      </Grid>
                      <Grid item xs={3} style={{ whiteSpace: "nowrap" }}>
                        {drawAnchorDay("Remote", anchorDays, amITeamMember)}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                      {!canEdit && (
                        <Button
                          style={{ marginTop: 8 }}
                          onClick={async () => {
                            await updateAnchorDays(anchorDays);
                          }}
                          variant="contained"
                          disabled={!amITeamMember || canEdit}
                          color="primary"
                          disableElevation
                        >
                          {t("Update Anchor Days")}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              )}
              {teamCategory !== TeamCategoryType.ORGANISATIONAL_UNIT && (
                <div className={classes.wrapper} key="divDesc">
                  {subHeadAndInfo("Description", 5)}
                  {canEdit ? (
                    <>
                      <TextField
                        id="teamDescription"
                        variant="outlined"
                        fullWidth
                        inputProps={{ maxLength: 250 }}
                        multiline
                        rows={textAreaRows}
                        className={classes.textField}
                        style={{ width: "100%" }}
                        placeholder={
                          t(`Describe the team you are creating... (max `) +
                          `${textAreaMaxCharacters}` +
                          t(` characters)`)
                        }
                        onChange={handleDescriptionChange}
                        value={teamDescription || ""}
                      />
                      <span style={{ fontSize: 10 }}>
                        {t(`Characters remaining`) +
                          ` - ${textAreaMaxCharacters - teamDescription.length}`}
                      </span>
                    </>
                  ) : (
                    <Paper
                      elevation={0}
                      key="teamDescription"
                      variant="outlined"
                      style={{ display: "flex", alignItems: "start", minHeight: 132 }}
                      className={classes.team}
                    >
                      {teamDescription}
                    </Paper>
                  )}
                </div>
              )}
              {!canEdit &&
              teamStore.currentTeam &&
              teamStore.currentTeam.owners &&
              teamStore.currentTeam.owners.length > 0 ? (
                <div className={classes.wrapper} key="divOwners">
                  {subHeadAndInfo("Owners", 6)}
                  <Paper
                    id="teamOwners"
                    variant="outlined"
                    key="owners"
                    className={classes.team}
                    style={
                      teamStore.currentTeam.owners.length > 10
                        ? {
                            maxHeight: 131,
                            overflowX: "auto",
                          }
                        : { height: "unset" }
                    }
                    placeholder="A list of the owners of the team"
                  >
                    {teamStore.currentTeam.owners.map((owner, idx) => (
                      <Fragment key={`owner${idx}`}>
                        {owner.preferred_name}
                        <br />
                      </Fragment>
                    ))}
                  </Paper>
                </div>
              ) : null}
            </Grid>
            <Grid item sm={12} md={6} style={{ overflowX: smAndUp ? `initial` : `auto` }}>
              <div className={classes.wrapper} key="divIlo">
                {subHeadAndInfo("Team ILO", 9)}
                <Box onClick={() => openILO(null)}>
                  <TeamIloRoutineViewer
                    teamId={teamId}
                    t={t}
                    isSmall={true}
                    didClick={(evt) => openILO(evt)}
                  />
                </Box>
              </div>
              <span style={{ fontSize: "16px", fontStyle: "italic", color: bhpColor.grey2 }}>
                {t(`Click to enlarge`)}
              </span>
              <Dialog
                open={isRoutineOpen}
                onClose={handleClose}
                className={classes.base}
                fullScreen={true}
              >
                <DialogTitle className={classes.dialogTitle}>
                  {teamName} - ILO
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <TeamIloRoutineViewer
                    teamId={teamId}
                    t={t}
                    isSmall={false}
                    didClick={(evt) => openILO(evt)}
                  />
                </DialogContent>
              </Dialog>
            </Grid>

            <Grid container item xs={12} className={classes.buttonRow}>
              <Grid item xs={6}>
                {canEdit && (
                  <Button
                    style={{ marginTop: 8 }}
                    onClick={saveChanges}
                    variant="contained"
                    disabled={!formValid}
                    color="primary"
                    disableElevation
                  >
                    {isCreate ? t("Create") : t("Save Changes")}
                  </Button>
                )}
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                {!isCreate && canEdit && teamCategory !== TeamCategoryType.ORGANISATIONAL_UNIT && (
                  <Button
                    style={{ marginTop: 8, backgroundColor: bhpColor.red }}
                    onClick={deleteTeam}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    {t("Delete Team")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <ConfirmDialog
          isOpen={deleteTeamConfirmDialogIsOpen}
          handlePrimaryClick={handleConfirmDeleteTeam}
          handleSecondaryClick={handleDeleteTeamDialogClose}
          primaryButtonText={getModalProps(t, 7).primaryButtonText}
          secondaryButtonText={"Cancel"}
          title={getModalProps(t, 7).title}
        >
          <Box>{getModalProps(t, 7).content}</Box>
        </ConfirmDialog>
        <PopupInformationWidget
          t={t}
          isOpen={informationOpen}
          handleClose={informationModal?.handleClose || handleInformationClose}
          handleSecondaryClick={handleInformationClose}
          title={informationModal?.title || null}
          content={informationModal?.content || null}
          buttonText={informationModal?.primaryButtonText || null}
          secondaryButtonText={informationModal?.secondaryButtonText || null}
        />
      </Box>
    </>
  );
});

export default TeamDetailsInformationTab;
function weekDaysNameSorter(arg0: string[]) {
  throw new Error("Function not implemented.");
}
