import { Building } from "interfaces/Building";
import { makeAutoObservable, flow, toJS } from "mobx";
import { User } from "interfaces/User";
import RootStore from "./RootStore";
import client from "services/api";
import { Team, TeamParticipant } from "interfaces/Teams";

export default class PeopleStore {
  public isLoading = false;
  public error = "";
  public rootStore!: RootStore;
  public userCount: number | null = null;
  public userNext: string | null = null;
  public users: User[] = [];
  public searchFilter = "";
  public locationFilter: Building | null = null;
  public teamFilter: Team | null = null;
  public sort: string | null = null;
  public participants: TeamParticipant[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setSearchFilter = (searchFilter: string) => {
    if (this.searchFilter !== searchFilter) {
      this.searchFilter = searchFilter;
    }
  };

  setTeamFilter = (team: Team | null) => {
    this.teamFilter = team;
  };

  setLocationFilter = (location: Building | null) => {
    this.locationFilter = location;
  };

  getNextUsers = flow(function* (this: PeopleStore) {
    if (!this.userNext) return;
    this.isLoading = true;
    try {
      const res = yield client.get(this.userNext);
      this.userCount = res.data.count;
      this.userNext = res.data.next;
      this.users = this.users.concat(res.data.results);
    } catch (err: any) {
      this.error = err;
    }
    this.isLoading = false;
  });

  getFilteredUsers = flow(function* (this: PeopleStore, pageSize: number, sort?: string) {
    this.isLoading = true;
    this.sort = sort ? sort : null;
    const queryString = this.getQueryString(pageSize);
    try {
      if (this.searchFilter || this.teamFilter || this.locationFilter) {
        const res = yield client.get(`/api/users/${queryString}`);
        this.userCount = res.data.count;
        this.userNext = res.data.next;
        this.users = res.data.results;
      } else {
        this.users = [];
        this.userCount = null;
      }
    } catch (err: any) {
      this.error = err;
    }
    this.isLoading = false;
  });

  getMentionUsersByTeamId = flow(function* (
    this: PeopleStore,
    searchTerm: string,
    teamId: number,
    pageSize: number
  ) {
    this.isLoading = true;

    try {
      const res = yield client.get(`/api/users/?q=${searchTerm}&limit=${pageSize}&team=${teamId}/`);
      return res.data.results;
    } catch (err: any) {
      this.error = err;
    }
    this.isLoading = false;
  });

  getUserById = flow(function* (this: PeopleStore, userId: number) {
    try {
      const userFound = this.users.find((user) => user.id === userId);
      if (userFound) {
        return userFound;
      } else {
        const res = yield client.get(`/api/users/${userId}/`);
        return res.data;
      }
    } catch (error: any) {
      this.error = error;
      this.isLoading = false;
    }
  });

  getQueryString = (pageSize: number): string => {
    let queryObject = Object.entries({
      q: `${this.searchFilter !== "" ? this.searchFilter : null}`,
      limit: `${pageSize}`,
      profile__default_office: `${
        this.locationFilter?.id ? this.locationFilter.id.toString() : null
      }`,
      team: `${this.teamFilter?.id ? this.teamFilter.id.toString() : null}`,
      ordering: `${this.sort}`,
    }).filter(([_, v]) => {
      return v !== null && v !== "null";
    });
    const urlObject = new URLSearchParams(queryObject);
    if (urlObject.toString() !== "") {
      return `?${urlObject.toString()}`;
    } else {
      return "";
    }
  };

  // getFilteredUsersByName = flow(function* (this: PeopleStore, name: string) {
  //   this.isLoading = true;
  //   try {
  //     if (name) {
  //       const res = yield client.get(`/api/users/?q=${name}`);
  //       this.isLoading = false;
  //       return res.data.results;
  //     }
  //   } catch (err: any) {
  //     this.isLoading = false;
  //     this.error = err;
  //   }
  // });

  //Return BHP users list and ignores non-bhp email users
  getFilteredUsersByName = flow(function* (this: PeopleStore, name: string) {
    this.isLoading = true;
    try {
      if (name) {
        const res = yield client.get(`/api/user-access-request/?q=${name}`);
        this.isLoading = false;
        return res.data.results;
      }
    } catch (err: any) {
      this.isLoading = false;
      this.error = err;
    }
  });

  //Return Non BHP users list and ignores bhp email users
  getFilteredVisitorsByName = flow(function* (this: PeopleStore, name: string) {
    this.isLoading = true;
    try {
      if (name) {
        const res = yield client.get(`/api/vms-user-access-request/?q=${name}`);
        this.isLoading = false;
        return res.data.results;
      }
    } catch (err: any) {
      this.isLoading = false;
      this.error = err;
    }
  });
}
