import { observer } from "mobx-react-lite";
import moment from "moment";

import Box from "@material-ui/core/Box";

import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { bhpColor } from "styles/globals";
import { useBookingStore, useScheduleStore } from "providers/RootStoreProvider";

interface ConfirmationProps {
  t: any;
}

const BookSpaceRequiredDialog = observer((props: ConfirmationProps) => {
  const { t } = props;
  const scheduleStore = useScheduleStore();
  const bookingStore = useBookingStore();

  const confirmRemoveDeskBooking = async () => {
    if (scheduleStore.scheduleChangingAwayFromOfficeWithBooking) {
      await Promise.all(
        scheduleStore.scheduleChangingAwayFromOfficeWithBooking.bookings.map(async (booking) => {
          await bookingStore.deleteBooking(booking.id);
        })
      );
      await scheduleStore.changeSchedule(
        scheduleStore.scheduleChangingAwayFromOfficeWithBooking.schedule.date,
        scheduleStore.scheduleChangingAwayFromOfficeWithBooking.status
      );
      scheduleStore.forceDataReload();
      await scheduleStore.loadSchedules();
    }
    scheduleStore.scheduleChangingAwayFromOfficeWithBooking = null;
  };

  const cancelRemoveDeskBooking = () => {
    scheduleStore.scheduleChangingAwayFromOfficeWithBooking = null;
  };

  return (
    <ConfirmDialog
      title={
        scheduleStore.scheduleChangingAwayFromOfficeWithBooking &&
        `${t(
          moment(scheduleStore.scheduleChangingAwayFromOfficeWithBooking.schedule.date).format(
            "dddd,"
          )
        )} ${t(
          moment(scheduleStore.scheduleChangingAwayFromOfficeWithBooking.schedule.date).format("Do")
        )} ${t(
          moment(scheduleStore.scheduleChangingAwayFromOfficeWithBooking.schedule.date).format(
            "MMMM"
          )
        )}`
      }
      primaryButtonText={t("Confirm")}
      secondaryButtonText={t("Cancel")}
      handleSecondaryClick={cancelRemoveDeskBooking}
      handlePrimaryClick={confirmRemoveDeskBooking}
      isOpen={scheduleStore.scheduleChangingAwayFromOfficeWithBooking !== null}
    >
      {t("You are removing your desk booking(s) for")}
      {": "}
      <ul>
        {scheduleStore.scheduleChangingAwayFromOfficeWithBooking?.bookings.map((booking) => (
          <li>
            <Box component="span" fontWeight="bold" color={bhpColor.orange1}>
              {booking.space?.name}{" "}
            </Box>
            <Box component="span" fontWeight="bold">
              {booking.building?.city}
            </Box>
            {" ("}
            {booking.booking_details?.start_time_local.substring(0, 5)} {" - "}
            {booking.booking_details?.end_time_local.substring(0, 5)} {")"}{" "}
            {booking.building?.building_name}
          </li>
        ))}
      </ul>
    </ConfirmDialog>
  );
});

export default BookSpaceRequiredDialog;
