import {
  Box,
  Tooltip,
  MenuItem,
  Menu,
  Button,
  Grid,
  makeStyles,
  CircularProgress,
  Select,
} from "@material-ui/core";
import { useRootStore, useScheduleStore, useUserStore } from "providers/RootStoreProvider";

import React, { Fragment, useEffect, useState } from "react";
import { bhpColor } from "styles/globals";
import { Schedule, ScheduleType } from "interfaces/Schedule";
import { Building, FavouriteSpace, Space, SpaceBooking, TimePeriod } from "interfaces/Building";
import favDeskBook from "assets/images/favourite_desk_book.svg";
import CloseIcon from "@material-ui/icons/Close";
import RootStore from "stores/RootStore";
import StarBorderTwoToneIcon from "@material-ui/icons/StarBorderTwoTone";
import StarTwoToneIcon from "@material-ui/icons/StarTwoTone";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { get } from "lodash";
import { bookingStyles } from "styles/bookingStyles";
import moment from "moment";
import { dateToUTCRange } from "interfaces/Utils";
import {
  bookingPeriodStatus,
  BuildingSpecs,
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
  getAvailableTimes,
  getBookingDateTimeData,
  getBookingsForDate,
  getBuildingBookingSpecs,
  getColor,
  getDeskHours,
  getLengthOfPeriod,
  getMyScheduleHours,
  getSpacesBookedAtThisTime,
  getTimeStringFromScheduleTime,
  Period,
} from "utils/hourlyBookings";
import { useHistory } from "react-router-dom";
import { getDateString, getThisWeek } from "utils/date";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarRateOutlinedIcon from "@material-ui/icons/StarRateOutlined";

export const getFavouritesInBuilding = (
  favourite_spaces: FavouriteSpace[],
  buildingId?: number
) => {
  return buildingId
    ? favourite_spaces.filter((fav) => `${fav.building_id}` === `${buildingId}`)
    : [];
};

export const hasFavouritesInBuilding = (
  favourite_spaces: FavouriteSpace[],
  buildingId?: number
) => {
  const matchedFavourites = favourite_spaces.filter(
    (spc) => `${spc.building_id}` === `${buildingId}`
  );
  const retVal = matchedFavourites ? matchedFavourites.length > 0 : false;
  return retVal;
};

export const isAvailable = (
  theFavourites: FavouriteSpace[],
  space: Space | FavouriteSpace | undefined,
  UTCFrom: Date,
  UTCTo: Date
) => {
  const serraviewId = get(space, "serraview_id");
  const svId = serraviewId ? serraviewId : get(space, "sv_id");
  const theFavourite = theFavourites.find((spc) => `${spc.sv_id}` === `${svId}`);
  if (theFavourite && theFavourite.bookings) return theFavourite.bookings.length > 0;
  if (theFavourite && theFavourite.bookings) {
    const bookingsInRange = theFavourite.bookings.filter(
      (booking) =>
        (booking.UTCTimeFrom < UTCFrom && booking.UTCTimeTo > UTCTo) ||
        (booking.UTCTimeFrom > UTCFrom && booking.UTCTimeFrom < UTCTo) ||
        (booking.UTCTimeTo > UTCFrom && booking.UTCTimeTo < UTCTo)
    );
    return bookingsInRange ? false : true;
  }
  return true;
};

export const isFavourite = (store: RootStore, space: Space | null, spcId: number | null) => {
  if (!space && !spcId) return false;
  return spcId ? store.userStore.isFavouriteSpace(spcId) : false;
};
export const addFavourite = async (store: RootStore, spaceId?: number) => {
  if (spaceId) await store.userStore.addFavouriteSpaces(spaceId);
};
export const removeFavourite = async (store: RootStore, spaceId?: number) => {
  if (spaceId) await store.userStore.removeFavouriteSpaces(spaceId);
};

export const drawFavouriteSetter = (
  store: RootStore,
  space: Space | number | null,
  hideAdd: boolean | null,
  hideRemove: boolean | null
) => {
  if (space) {
    const spaceId = typeof space === "number" ? space : space?.id;
    return isFavourite(store, null, spaceId)
      ? !hideRemove && (
          <Box
            onClick={() => removeFavourite(store, spaceId)}
            style={{ cursor: "pointer", marginRight: "12px" }}
          >
            <Tooltip title="Click to remove favourite">
              <StarTwoToneIcon style={{ color: "#FFB000", display: "flex", alignSelf: "center" }} />
            </Tooltip>
          </Box>
        )
      : !hideAdd && (
          <Box
            onClick={() => addFavourite(store, spaceId)}
            style={{ cursor: "pointer", marginRight: "12px" }}
          >
            <Tooltip title="Click to make favourite">
              <StarBorderTwoToneIcon style={{ display: "flex", alignSelf: "center" }} />
            </Tooltip>
          </Box>
        );
  }
};

interface FavouriteContextMenuProps {
  building: Building;
  date: string;
  from: string;
  to: string;
  t: any;
  schedule: Schedule;
  menuText?: Boolean;
  disableBooking?: boolean;
}

const useStyles = makeStyles({
  menu: {
    fontSize: 16,
    "& ul": {
      width: "max-content",
    },
  },
  tooltip: {
    whiteSpace: "pre-line",
  },
  buildingSelector: {
    padding: 0,
    marginRight: "8px",
    "& .MuiSelect-root": {
      padding: "4px",
      paddingRight: "25px",
      width: "210px",
      fontSize: "14px",
    },
    "& .MuiSelect-iconOutlined": {
      right: 0,
    },
  },
});

/**
 * Create a context menu from the favourites
 * @param props - building_id, date as string YYYY-MM-DD relative to building
 * @returns a shooting star or context menu
 */
export const FavouriteContextMenu = observer((props: FavouriteContextMenuProps) => {
  const { t, building, date, from, to, schedule } = props;
  const scheduleStore = useScheduleStore();
  const userStore = useUserStore();
  const store = useRootStore();
  const history = useHistory();

  let bookingStart =
    `${from}` !== ""
      ? from
      : `${schedule.desk_start_time}` !== ""
      ? schedule.desk_start_time
      : getDeskHours(store.userStore.me, date).desk_start_time;
  let bookingEnd =
    `${to}` !== ""
      ? to
      : `${schedule.desk_finish_time}` !== ""
      ? schedule.desk_finish_time
      : getDeskHours(store.userStore.me, date).desk_finish_time;
  if (bookingStart && bookingStart.length === 4) bookingStart = `0${bookingStart}`;
  if (bookingEnd && bookingEnd.length === 4) bookingEnd = `0${bookingEnd}`;

  const [buildingBookingSpecs, setBuildingBookingspecs] = useState<BuildingSpecs>(
    getBuildingBookingSpecs(store.buildingStore, building.id)
  );

  const [favourites, setFavourites] = useState<FavouriteSpace[] | null>([
    ...userStore.favouriteSpaces,
  ]);
  const [isLoadingFavourites, setIsLoadingFavourites] = useState(false);

  const building_id = building.id;

  const [dateRange, setDateRange] = useState<{ start: Date; end: Date } | null>(null);

  const classes = useStyles();
  const [favouriteMenuAnchorEl, setFavouriteMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const openFavouriteMenu = Boolean(favouriteMenuAnchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Stop event propagation
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    userStore.primeFavouritesBookings(moment(date).toDate());
    setFavouriteMenuAnchorEl(event.currentTarget);
  };
  const handleFavouriteMenuClose = () => {
    setFavouriteMenuAnchorEl(null);
  };

  const onFocussedBuildingChanged = (date: string, newFocussedBuilding: any) => {
    store.bookingStore.setDateFocussedBuilding(date, newFocussedBuilding);
  };

  const getDistinctBuildings = (favs: FavouriteSpace[]) => {
    let buildingIds: number[] = [];
    let buildings: Partial<Building>[] = [];
    if (favs) {
      favs.forEach((fav) => {
        if (fav.building_id && !buildingIds.includes(fav.building_id)) {
          const thisBuilding = store.buildingStore.getBuilding(fav.building_id);
          if (thisBuilding) {
            buildingIds.push(fav.building_id);
            buildings.push(thisBuilding);
          }
        }
      });
    }
    return buildings;
  };

  const jumpToBookingPage = (space: FavouriteSpace | null) => {
    if (space && space.building_id) {
      const floorId = get(space, "floor");
      store.buildingStore.setCurrentBuilding(space.building_id, date);
      if (space) store.buildingStore.setFocussedSpaceId(space.id || null);
      if (floorId) store.buildingStore.loadSpaces(space.building_id, floorId, date, true);
      history.push(`/booking/${moment(date).format("YYYY-MM-DD")}/${space.building_id}`);
    }
  };

  const quickBook = async (space: FavouriteSpace | null, check: boolean = false) => {
    if (space?.id && bookingStart && bookingEnd) {
      const bld = space.building_id ? store.buildingStore.getBuilding(space.building_id) : null;
      const btz = bld && bld.building_timezone ? bld.building_timezone : "UTC";
      const bookingStartUTC = moment.tz(`${date} ${bookingStart}`, btz).toDate();
      const bookingEndUTC = moment.tz(`${date} ${bookingEnd}`, btz).toDate();
      const deskHrs: Period = { start: bookingStartUTC, end: bookingEndUTC };
      if (check) {
        jumpToBookingPage(space);
      } else {
        const bookDateTimeData = getBookingDateTimeData(deskHrs, btz);
        const created_by = "frontend";
        await store.bookingStore.createUserBooking(
          store.userStore.me!.id,
          space.id,
          bookDateTimeData.start_date_local,
          bookDateTimeData.start_time_local,
          bookDateTimeData.end_date_local,
          bookDateTimeData.end_time_local,
          created_by
        );
        scheduleStore.forceDataReload();
        scheduleStore.loadSchedules();
        store.bookingStore.loadUserBookings(
          getThisWeek(new Date()),
          store.userStore.me!.id.toString()
        );
        setFavouriteMenuAnchorEl(null);
      }
    }
  };

  const [favsBeingRemoved, setFavsBeingRemoved] = useState<number[]>([]);

  const removeFavourite = async (spaceId?: number) => {
    if (spaceId) {
      const removingItems = [...favsBeingRemoved];
      removingItems.push(spaceId);
      setFavsBeingRemoved(removingItems);
      await userStore.removeFavouriteSpaces(spaceId);
      removingItems.splice(removingItems.indexOf(spaceId), 1);
      setFavsBeingRemoved(removingItems);
    }
  };

  const isRemoving = (spaceId?: number) => {
    if (spaceId) {
      return favsBeingRemoved.indexOf(spaceId) > -1;
    }
    return false;
  };

  const getRelevantBookings = (bookings, space_id: number | null) => {
    return space_id
      ? bookings.filter((bkg) => bkg.space && bkg.space.id === space_id).slice()
      : bookings.slice();
  };

  // /**
  //  * checks through the bookings to see if the space is booked on the date requested
  //  * @param space - the space (with bookings)
  //  * @param date - YYYY-MM-DD (uses props building.timezone) to get UTC
  //  * @returns true if booked, false if not booked
  //  */
  // const isBookedOnThisDate = (space: FavouriteSpace, date: string) => {
  //   const spaceStatus =
  //     space.id && myDefaultPeriod ? spacePeriodStatus(space.id, myDefaultPeriod) : "000";
  //   // no bookings
  //   if (!space.bookings || space.bookings.length === 0) return false;

  //   const bookings = space.bookings.filter((booking) => booking.date === date);
  //   return bookings && bookings.length > 0; // just say not available
  // };

  const spacePeriodStatus = (space_id: number, requestedPeriod: Period) => {
    const spaceBookings = store.bookingStore.space_bookings[date];
    if (spaceBookings && spaceBookings.length > 0) {
      // #TODO: Need to check this bookingPeriodStatus function for better availability check
      // const status = bookingPeriodStatus(
      //   spaceBookings,
      //   requestedPeriod,
      //   space_id,
      //   undefined,
      //   undefined,
      //   true,
      //   buildingBookingSpecs.gap,
      //   buildingBookingSpecs.min_duration
      // );
      const relevantBookings = getRelevantBookings(spaceBookings, space_id);
      if (relevantBookings.length > 0) return "111";
      else return "000";
      // return status;
    } else return "000";
  };

  const bookingStartUTC = moment(`${date} ${bookingStart}`).toDate();
  const bookingEndUTC = moment(`${date} ${bookingEnd}`).toDate();
  const deskHours: Period = { start: bookingStartUTC, end: bookingEndUTC };

  const drawContextMenuItem = (
    space: FavouriteSpace,
    isRemoving: boolean,
    idx: number,
    startTime: string = DEFAULT_START_TIME,
    endTime: string = DEFAULT_END_TIME,
    availability_status: string
  ) => {
    const bld = space.building_id ? store.buildingStore.getBuilding(space.building_id) : null;
    const btz = bld && bld.building_timezone ? bld.building_timezone : "UTC";
    const bookingStartUTC = moment.tz(`${date} ${startTime}`, btz).toDate();
    const bookingEndUTC = moment.tz(`${date} ${endTime}`, btz).toDate();
    const deskHours: Period = { start: bookingStartUTC, end: bookingEndUTC };

    const lengthOfPeriod = getLengthOfPeriod(deskHours);

    const timesInvalid = deskHours
      ? deskHours.end <= deskHours.start ||
        lengthOfPeriod < buildingBookingSpecs.min_duration ||
        lengthOfPeriod > buildingBookingSpecs.max_duration
      : false;

    if (timesInvalid) {
      console.log("~~~~~times invalid~~~~~");
      console.log(btz, bookingStart, bookingEnd, deskHours);
      console.log(bookingStartUTC);
      console.log(bookingEndUTC);
    }

    // const availability = space.id && deskHours ? spacePeriodStatus(space.id, deskHours) : "000";
    const availableTimes = deskHours
      ? getAvailableTimes(
          store.bookingStore.user_bookings[date],
          deskHours,
          space.id!,
          buildingBookingSpecs
        )
      : "";

    const availabilityTooltip =
      availableTimes !== "" ? `${t("Available times")}:\n ${availableTimes}` : "";
    const mySpacesAlreadyBooked = getSpacesBookedAtThisTime(
      getBookingsForDate(store.bookingStore.user_bookings, date!, undefined),
      deskHours
    );
    const isDoubleBooked = mySpacesAlreadyBooked && mySpacesAlreadyBooked.length > 0 ? true : false;

    const isDisabled = availability_status !== "000" || isDoubleBooked || timesInvalid;

    return isRemoving ? (
      <CircularProgress size="small" />
    ) : (
      <MenuItem
        key={`key${idx}-${space.id}`}
        style={{
          width: "100%",
          cursor: "default",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={1} style={{ cursor: "pointer" }}>
          <Tooltip title={t("Remove favourite")}>
            <Box onClick={() => removeFavourite(space.id)}>
              <DeleteForeverOutlinedIcon color="secondary" />
            </Box>
          </Tooltip>
        </Grid>
        <Grid item xs={5} style={{ color: getColor(availability_status) }}>
          {space.name}
        </Grid>
        <Grid item xs={6} style={{ cursor: "pointer", alignItems: "flex-end" }}>
          <Tooltip
            className={classes.tooltip}
            title={
              availability_status === "111"
                ? t("Currently Unavailable")
                : availability_status === "000"
                ? isDoubleBooked
                  ? t("You have other bookings at this time")
                  : timesInvalid
                  ? t("Booking times invalid")
                  : t("Instantly book desk")
                : availabilityTooltip
            }
          >
            <Box>
              <Button
                style={{
                  height: 16,
                  textDecoration: "none",
                  fontSize: 14,
                  backgroundColor: !["000", "111"].includes(availability_status)
                    ? bhpColor.partly_available
                    : "",
                }}
                variant="contained"
                color="primary"
                disableElevation
                onClick={() =>
                  !isDisabled
                    ? quickBook(space || null, !["000", "111"].includes(availability_status))
                    : {}
                }
                disabled={isDisabled}
              >
                {["000", "111"].includes(availability_status) ? t("Book") : t("Check")}
              </Button>
            </Box>
          </Tooltip>
        </Grid>
      </MenuItem>
    );
  };

  const [openBuildings, setOpenBuildings] = useState<number[]>([]);
  const toggleOpen = (buildingId: number) => {
    if (openBuildings.includes(buildingId))
      setOpenBuildings(openBuildings.filter((bldId) => bldId !== buildingId));
    else setOpenBuildings([...openBuildings, buildingId]);
  };

  const [groupedFavourites, setGroupedFavourites] = useState<{
    [buildingId: number]: FavouriteSpace[];
  }>({});

  useEffect(() => {
    if (userStore.favouriteSpaces) {
      setFavourites([...userStore.favouriteSpaces]);
      let gFav: { [bldId: number]: FavouriteSpace[] } = {};
      let newOpenBuildings = openBuildings.slice();
      userStore.favouriteSpaces.forEach((fav) => {
        if (fav.building_id) {
          const fav_buildingId = fav.building_id;
          if (!newOpenBuildings.includes(fav_buildingId)) newOpenBuildings.push(fav_buildingId);
          if (gFav[fav_buildingId]) gFav[fav_buildingId].push(fav);
          else gFav[fav_buildingId] = [fav];
        }
      });
      setGroupedFavourites(gFav);
      setOpenBuildings(newOpenBuildings);
    }
  }, [userStore.favouriteSpaces]);

  useEffect(() => {
    setIsLoadingFavourites(store.buildingStore.isLoadingFavourites);
  }, [store.buildingStore.isLoadingFavourites]);

  const menuItem = props.menuText ? (
    <MenuItem disabled={props.disableBooking} style={{ color: "black" }}>
      Book a favourite desk
    </MenuItem>
  ) : (
    <StarRateOutlinedIcon
      style={{ color: bhpColor.orange3 }}
      fontSize="small"
    ></StarRateOutlinedIcon>
  );

  return favourites ? (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          color: bhpColor.blueGrey2,
        }}
        onClick={handleMenuClick}
        key={`favourite${date}`}
      >
        {!openFavouriteMenu ? (
          <Tooltip title={t("Open Menu")}>
            {/* <img src={favDeskBook} alt={t("book favourite desk")} height={`22px`} /> */}
            {menuItem}
          </Tooltip>
        ) : (
          <div style={{ width: 30 }}></div>
        )}
      </Box>
      <Menu
        id={`schedule${date}-menu`}
        open={openFavouriteMenu}
        onClose={handleFavouriteMenuClose}
        anchorEl={favouriteMenuAnchorEl}
        className={`${classes.menu} waldo`}
        style={{ width: "400px", fontSize: "14px" }}
      >
        <Grid
          container
          key={`context-${date}-menu`}
          style={{
            display: "flex",
            width: "360px",
            paddingLeft: "20px",
          }}
        >
          <Grid item xs={11}>
            <Grid container>
              <Grid item xs={5}>
                {t("Date: ")}
              </Grid>
              <Grid item xs={7}>
                {moment(date).format("Do MMMM")}
              </Grid>
              <Grid item xs={5} style={{ whiteSpace: "nowrap" }}>
                {t("Desk Hours: ")}
              </Grid>
              <Grid item xs={7}>
                {`${getTimeStringFromScheduleTime(from)} - ${getTimeStringFromScheduleTime(to)}`}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Box
              onClick={handleFavouriteMenuClose}
              style={{ color: bhpColor.grey3, cursor: "pointer" }}
            >
              {isLoadingFavourites && <CircularProgress size="small" />}
              <CloseIcon fontSize="small" />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {Object.keys(groupedFavourites).map((key, index) => {
            const thisBuilding = store.buildingStore.getBuilding(Number(key));
            return (
              <Grid container key={`context-building-${index}-menu`}>
                <Grid item xs={10}>
                  <Box marginLeft={"18px"} style={{ fontWeight: 700 }}>
                    {`${thisBuilding?.city}, ${thisBuilding?.building_name}`}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    paddingRight: "12px",
                  }}
                >
                  <Box onClick={() => toggleOpen(Number(key))}>
                    {openBuildings.includes(Number(key)) ? <ExpandLess /> : <ExpandMore />}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: `${openBuildings.includes(Number(key)) ? "flex" : "none"}`,
                    flexDirection: "column",
                  }}
                >
                  {groupedFavourites[key]
                    .sort((fav1, fav2) => (fav1.name > fav2.name ? 1 : -1))
                    .map((spc, idx) => {
                      return drawContextMenuItem(
                        spc,
                        isRemoving(spc.id),
                        idx,
                        bookingStart,
                        bookingEnd,
                        spacePeriodStatus(spc.id, deskHours)
                      );
                    })}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Menu>
    </>
  ) : (
    <></>
  );
});
