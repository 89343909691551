import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";

import { useState } from "react";
import { bhpColor, displaySettings, settings } from "styles/globals";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { SettingsSelectBox } from "components/settings/SettingsSelectBox";
import { mdiContentSaveCogOutline } from "@mdi/js";
import { useVisitorStore } from "providers/RootStoreProvider";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useHistory } from "react-router";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartOuter: {
      border: `1px solid ${bhpColor.blueGrey4}`,
      [theme.breakpoints.down("sm")]: {
        border: 0,
        paddingLeft: 0,
      },
    },
    mainTable: {
      "& td": {
        // borderBottom: 0,
      },
    },
    textArea: {
      height: 32,
      width: "100%",
      paddingLeft: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      backgroundColor: `${bhpColor.blueGrey4}50`,
    },
    avatar: {
      backgroundColor: "rgb(250, 182, 54)",
      color: "white",
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginLeft: "16px",
      fontSize: "16px",
    },
    h4: {
      fontWeight: "bold",
      color: bhpColor.blueGrey1,
      fontSize: 16,
      display: "inline-block",
    },
    wrapper: {
      marginTop: theme.spacing(2),
    },
    team: {
      height: 36,
      width: "calc(100% - 150px)",
      paddingLeft: theme.spacing(1),
      backgroundColor: `${bhpColor.blueGrey4}50`,
      display: "inline-flex",
      verticalAlign: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    datePicker: {
      width: "calc(100% - 150px)",
      borderRadius: settings.borderRadius,
      border: `1px solid ${bhpColor.grey3}`,
      backgroundColor: "white",
      "& .MuiFormLabel-root": {
        color: bhpColor.blueGrey1,
        fontSize: 16,
        fontWeight: 700,
      },
      "& .MuiInput-root": {
        paddingLeft: theme.spacing(1),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `0 !important`,
        transition: "none",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    textField: {
      width: "calc(100% - 150px)",
      borderRadius: settings.borderRadius,
      backgroundColor: "white",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      "& .MuiInput-root": {
        paddingLeft: theme.spacing(1),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `0 !important`,
        transition: "none",
      },
      "& .Mui-focussed": {
        borderBottom: `0 !important`,
      },
    },
    formControl: {
      display: "block",
      width: "100%",
      margin: 0,
    },
    selectBox: {
      width: "calc(100% - 150px)",
      height: 40,
      "& svg": {
        zIndex: 2,
      },
      "& select": {
        zIndex: 2,
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& invalid": { color: "gray" },
      "& fieldset": {
        zIndex: 1,
        height: 40,
        top: -3,
        backgroundColor: "white",
        "& .Mui-focussed": {
          backgroundColor: "white",
        },
      },
    },
    selectInput: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    buttonRow: {
      marginTop: theme.spacing(2),
      textAlign: "start",
    },
    saveFailed: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.purple1,
        fontWeight: "bold",
        backgroundColor: bhpColor.purple4,
        border: `2px solid ${bhpColor.purple1}`,
        borderRadius: settings.borderRadius,
      },
    },
    saveSuccess: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.orange1,
        fontWeight: "bold",
        backgroundColor: bhpColor.orange4,
        border: `2px solid ${bhpColor.orange1}`,
        borderRadius: settings.borderRadius,
      },
    },
    anchorDayButton: {
      fontSize: 20,
      color: bhpColor.blueGrey1,
      borderRadius: settings.borderRadius,
      borderWidth: 2,
      borderColor: bhpColor.blueGrey1,
      borderStyle: "solid",
      backgroundColor: bhpColor.blueGrey4,
      textTransform: "none",
      "&.selected": {
        backgroundColor: bhpColor.blueGrey1,
        color: bhpColor.white,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        height: 50,
        width: 50,
        marginRight: 6,
        minWidth: 50,
      },
    },
  })
);

const VisitorCheckin = observer(() => {
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const classes = innerStyles();
  const { t, i18n } = useTranslation();
  const visitorStore = useVisitorStore();
  const history = useHistory();

  const formFieldTitle = (title: string) => {
    return (
      <Grid item xs={12} sm={3}>
        <Typography className={classes.h4}>{title}</Typography>
      </Grid>
    );
  };

  type initialFormStateType = {
    emailId: string;
    reasonForVisit: string;
    notifyHost: boolean;
  };

  const initialFormState: initialFormStateType = {
    emailId: "",
    reasonForVisit: "",
    notifyHost: false,
  };

  const initialFormHelperTextState = {
    emailIdHelperText: "",
    reasonForVisitHelperText: "",
  };

  const initialFormErrorState = {
    emailIdError: false,
    reasonForVisitError: false,
  };

  const [visitorCheckinFormValues, setVisitorCheckinFormValues] =
    useState<object>(initialFormState);
  const [visitorCheckinErrorValues, setVisitorCheckinErrorValues] =
    useState<object>(initialFormErrorState);
  const [visitorCheckinHelperTextValues, setVisitorCheckinHelperTextValues] = useState<object>(
    initialFormHelperTextState
  );

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    let formValues = { ...visitorCheckinFormValues };
    let errorText = { ...visitorCheckinHelperTextValues };
    let errorState = { ...visitorCheckinErrorValues };

    switch (name) {
      case "emailId":
        formValues["emailId"] = event.target.value;
        errorText["emailId"] = "";
        errorState["emailId"] = false;
        break;
      case "reasonForVisit":
        formValues["reasonForVisit"] = event.target.value;
        errorText["reasonForVisit"] = "";
        errorState["reasonForVisit"] = false;
        break;
      case "notifyHost":
        let isChecked = event.target.checked;

        if (isChecked) {
          formValues["notifyHost"] = true;
        } else {
          formValues["notifyHost"] = false;
        }
        break;
      default:
        break;
    }

    setVisitorCheckinFormValues(formValues);
    setVisitorCheckinErrorValues(errorState);
    setVisitorCheckinHelperTextValues(errorText);
  };

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [apiError, setApiError] = useState("");
  const [showApiError, setShowApiError] = useState(false);

  const validateForm = () => {
    let errorText = { ...visitorCheckinHelperTextValues };
    let errorState = { ...visitorCheckinErrorValues };

    for (const [key, value] of Object.entries(visitorCheckinFormValues)) {
      switch (key) {
        case "reasonForVisit":
          if (value == "") {
            errorText["reasonForVisit"] = "Please select a reason for your visit";
            errorState["reasonForVisit"] = true;
          }
          break;
        case "emailId":
          if (value == "") {
            errorText["emailId"] = "Please enter your email address";
            errorState["emailId"] = true;
          } else {
            let re = /^\w+[-\.\w]*@(?!(?:bhp)\.com$)\w+[-\.\w]*?\.\w{2,4}$/;
            if (re.test(value) == false) {
              errorText["emailId"] = "Please enter a non bhp email address";
              errorState["emailId"] = true;
            }
          }
          break;
        default:
          break;
      }
    }

    setVisitorCheckinErrorValues(errorState);
    setVisitorCheckinHelperTextValues(errorText);
  };

  const clearForm = () => {
    setVisitorCheckinFormValues(initialFormState);
    setVisitorCheckinErrorValues(initialFormErrorState);
    setVisitorCheckinHelperTextValues(initialFormHelperTextState);
  };

  const [isApiError, setIsApiError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  const doVisitorCheckin = async () => {
    validateForm();

    if (
      typeof visitorCheckinErrorValues["emailId"] !== "undefined" &&
      typeof visitorCheckinErrorValues["reasonForVisit"] !== "undefined" &&
      !visitorCheckinErrorValues["emailId"] &&
      !visitorCheckinErrorValues["reasonForVisit"]
    ) {
      const visitorInformation = {
        email_id: visitorCheckinFormValues["emailId"],
        reason_for_visit: visitorCheckinFormValues["reasonForVisit"],
        notify_host: visitorCheckinFormValues["notifyHost"],
      };

      const response = await visitorStore.checkInVisitor(visitorInformation);
      if (response.status == 201 || response.status == 200) {
        setMessage("Successfully Checked-In");
        setIsOpen(true);
        clearForm();
      } else {
        setIsOpen(true);
        setIsApiError(true);
        setMessage("User details not found!!!");
      }
    }
  };

  const select_items = [
    { id: "meeting_less_than_2_hours", reason_for_visit: "Meeting <2 hours" },
    { id: "meeting_greater_than_2_hours", reason_for_visit: "Meeting >2 hours" },
    { id: "training_conference", reason_for_visit: "Training/Conference" },
    { id: "to_use_workspace", reason_for_visit: "To use workspace" },
    { id: "other", reason_for_visit: "Other" },
  ];

  return (
    <>
      <Box position="relative" p={mdAndUp ? 3 : 0}>
        <Box py={2}>
          <Box my={4}>
            <Grid container alignItems="center" spacing={5}>
              {formFieldTitle("Email*")}
              <Grid item xs={8}>
                <TextField
                  key="tEmail"
                  id="emailId"
                  placeholder={t("Enter an email (required)")}
                  type="email"
                  size="small"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  onChange={handleFieldChange}
                  value={visitorCheckinFormValues["emailId"]}
                  name="emailId"
                  error={visitorCheckinErrorValues["emailId"]}
                  helperText={visitorCheckinHelperTextValues["emailId"]}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={5}>
              {formFieldTitle("Reason for Visit")}
              <Grid item xs={8}>
                <SettingsSelectBox
                  t={t}
                  cssClass={classes.selectBox}
                  phText={"Select category of visit"}
                  phValue={""}
                  theItems={select_items}
                  labelField={"reason_for_visit"}
                  valueField={"id"}
                  handleChange={handleFieldChange}
                  theId={"reasonForVisit"}
                  theKey={"reasonForVisitKey"}
                  name="reasonForVisit"
                  error={visitorCheckinErrorValues["reasonForVisit"]}
                  helperText={visitorCheckinHelperTextValues["reasonForVisit"]}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={5}>
              <Grid item xs={12} sm={3} />
              <Grid item xs={8}>
                <table
                  style={{
                    width: `calc(100% - 134px)`,
                  }}
                >
                  <tbody>
                    <tr>
                      <td align="right">
                        Notify my Host that I've arrived
                        <Checkbox name="notifyHost" color="primary" onChange={handleFieldChange} />
                      </td>
                    </tr>

                    <tr>
                      <td align="right">
                        <Button
                          style={{ marginTop: 8, marginRight: 8 }}
                          variant="contained"
                          disableElevation
                          color="primary"
                          onClick={doVisitorCheckin}
                        >
                          {t("Check-in")}
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={classes.saveSuccess}
        autoHideDuration={displaySettings.snackDelay}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          if (!isApiError) {
            history.push("/temp");
            history.goBack();
          } else {
            setIsApiError(false);
          }
        }}
        message={message}
        key={"top" + "right"}
      />
    </>
  );
});

export default VisitorCheckin;
