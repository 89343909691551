import town_hall from "assets/office_event_categories/town_hall.png";
import social from "assets/office_event_categories/social.png";
import professional_networking from "assets/office_event_categories/professional_networking.png";
import learning_and_development from "assets/office_event_categories/learning_and_development.png";
import wellness from "assets/office_event_categories/wellness.png";
import inclusion_and_diversity from "assets/office_event_categories/inclusion_and_diversity.png";
import holidays_and_observances from "assets/office_event_categories/holidays_and_observances.png";
import cultural from "assets/office_event_categories/cultural.png";
import other from "assets/office_event_categories/other.png";
import { EventDates } from "interfaces/Event";
import moment from "moment";

export const getEventImage = (eventCategory: string): string => {
  switch (eventCategory) {
    case "BUSINESS":
      return town_hall;
    case "SOCIAL":
      return social;
    case "NETWORKING":
      return professional_networking;
    case "LEARNING":
      return learning_and_development;
    case "WELLNESS":
      return wellness;
    case "INCLUSION AND DIVERSITY":
      return inclusion_and_diversity;
    case "HOLIDAYS":
      return holidays_and_observances;
    case "CULTURAL":
      return cultural;
    case "Other":
      return other;
    default:
      return other;
  }
};

type dateStartEndFilter = { startDate: string; endDate: string };
export const getEventFilterDates = (dateFilter: EventDates) => {
  let returnObject: dateStartEndFilter = {} as dateStartEndFilter;

  switch (dateFilter) {
    case EventDates.ThisWeek:
      returnObject.startDate = moment().format("YYYY-MM-DD");
      returnObject.endDate = moment().endOf("week").format("YYYY-MM-DD");
      return returnObject;
    case EventDates.NextWeek:
      returnObject.startDate = moment().add(1, "week").startOf("week").format("YYYY-MM-DD");
      returnObject.endDate = moment().add(1, "week").endOf("week").format("YYYY-MM-DD");
      return returnObject;
    case EventDates.ThisMonth:
      returnObject.startDate = moment().format("YYYY-MM-DD");
      returnObject.endDate = moment().endOf("month").format("YYYY-MM-DD");
      return returnObject;

    case EventDates.NextMonth:
      returnObject.startDate = moment().add(1, "month").startOf("month").format("YYYY-MM-DD");
      returnObject.endDate = moment().add(1, "month").endOf("month").format("YYYY-MM-DD");
      return returnObject;

    case EventDates.ThisYear:
      returnObject.startDate = moment().format("YYYY-MM-DD");
      returnObject.endDate = moment().endOf("year").format("YYYY-MM-DD");
      return returnObject;

    case EventDates.AllEvents:
      returnObject.startDate = moment().format("YYYY-MM-DD");
      returnObject.endDate = moment().add(5, "years").endOf("year").format("YYYY-MM-DD");
      return returnObject;

    default:
      returnObject.startDate = moment().format("YYYY-MM-DD");
      returnObject.endDate = moment().endOf("month").format("YYYY-MM-DD");
      return returnObject;
  }
};

export const getFormattedEventDetailsTime = (
  startDate: string,
  startTime: string,
  endTime: string,
  buildingTimeZone: string | null
) => {
  if (buildingTimeZone) {
    return `${moment(startDate).tz(buildingTimeZone).format("ddd")}, ${moment(startTime, "hh:mm:ss")
      .tz(buildingTimeZone)
      .format("h:mm a")} - ${moment(endTime, "hh:mm:ss")
      .tz(buildingTimeZone)
      .format("h:mm a")} (${moment().tz(buildingTimeZone).format("z")})`;
  } else {
    return `${moment(startDate).format("ddd")}, ${moment(startTime, "hh:mm:ss").format(
      "h:mm a"
    )} - ${moment(endTime, "hh:mm:ss").format("h:mm a")} (${moment().format("z")})`;
  }
};
