import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    zIndex: 10,
  },
})(Backdrop);

const LoadingZone = (props: any) => {
  return (
    <LimitedBackdrop open={props.isLoading}>
      <CircularProgress color="primary" />
    </LimitedBackdrop>
  );
};

export default LoadingZone;
