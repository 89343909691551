import { createStyles, makeStyles, Theme, Grid, Box } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import EventDetailsTop from "./EventDetailsTop";
import EventDetailsBody from "./EventDetailsBody";
import { Event } from "interfaces/Event";
import { getFormattedEventDetailsTime } from "utils/events";
import { BuildingWithCapacity } from "interfaces/Building";

interface EventDetailsProps {
  event: Event;
  building: BuildingWithCapacity;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventDetail: {
      margin: 0,
    },
  })
);

const EventDetails = observer((props: EventDetailsProps) => {
  const { event, building } = props;
  const classes = innerStyles();
  const { t, i18n } = useTranslation();

  return (
    <Grid container direction="column" className={classes.eventDetail}>
      <Grid item container>
        <EventDetailsTop
          date={event.event_start_date}
          subject={event.event_title}
          imageType={event.event_category}
        />
      </Grid>
      <EventDetailsBody
        date={event.event_start_date}
        time={getFormattedEventDetailsTime(
          event.event_start_date,
          event.event_start_time,
          event.event_end_time,
          building.building_timezone || null
        )}
        location={event.floor_name ? `Level ${event.floor_name}` : ""}
        information={event.event_description}
      />
    </Grid>
  );
});
export default EventDetails;
