import { Box, createStyles, makeStyles, TableCell, TableRow, Theme } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { BuildingWithCapacity } from "interfaces/Building";
import { bhpColor, settings } from "styles/globals";
import { BuildingTeamSchedule } from "interfaces/Schedule";
import TeamScheduleBox from "components/team/TeamScheduleBox";
import { isWeekend } from "utils/date";
import { Team } from "interfaces/Teams";
import { useHistory } from "react-router";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      color: bhpColor.blueGrey1,
      "& .MuiTableCell-root": {
        color: bhpColor.blueGrey1,
        padding: 0,
        paddingLeft: "4px",
        paddingTop: "2px",
        borderBottom: 0,
      },
    },
    teamNameBox: {
      border: `1px solid ${bhpColor.blueGrey4}`,
      fontWeight: 700,
      textAlign: "start",
      borderRadius: settings.borderRadius,
      height: "54px", // yes magic number but 100% isn't applying
      alignItems: "center",
      display: "flex",
      justifyContent: "start",
      cursor: "pointer",
    },
    weekend: { backgroundColor: `${bhpColor.blueGrey4}30` },
  })
);

interface TeamScheduleRowProps {
  isSite?: boolean;
  t: any;
  buildingCapacityRange: BuildingWithCapacity[];
  buildingTeamSchedule: BuildingTeamSchedule[] | null;
  team: Team | null;
}

const TeamScheduleRow = observer((props: TeamScheduleRowProps) => {
  const { isSite, buildingCapacityRange, buildingTeamSchedule, team, t } = props;
  const classes = innerStyles();
  const history = useHistory();
  const isOffice = !isSite;

  const jumpToTeam = () => {
    if (buildingTeamSchedule && buildingTeamSchedule.length > 0) {
      history.push(`/teams/${buildingTeamSchedule[0].team.id}`);
    }
    return false;
  };

  return (
    <>
      <TableRow
        className={classes.row}
        style={{
          borderLeft: `1px solid ${bhpColor.backgroundGrey}`,
          borderRight: `1px solid ${bhpColor.backgroundGrey}`,
        }}
      >
        <TableCell colSpan={2} style={{ borderBottom: 0, paddingLeft: 12 }}>
          <strong>{t("View Team")}</strong>
        </TableCell>
        {buildingCapacityRange.map((val, idx) => {
          return (
            <TableCell
              key={`tmb${idx}`}
              className={isWeekend(new Date(val.date)) ? classes.weekend : ""}
            >
              {" "}
            </TableCell>
          );
        })}
      </TableRow>

      <TableRow
        className={classes.row}
        style={{
          borderLeft: `1px solid ${bhpColor.backgroundGrey}`,
          borderRight: `1px solid ${bhpColor.backgroundGrey}`,
        }}
      >
        <TableCell colSpan={2} style={{ paddingBottom: "16px" }} onClick={jumpToTeam}>
          <Box p={1} className={classes.teamNameBox}>
            {buildingTeamSchedule && buildingTeamSchedule.length > 0
              ? buildingTeamSchedule[0] && buildingTeamSchedule[0].team
                ? buildingTeamSchedule[0].team.name
                : ""
              : ""}
          </Box>
        </TableCell>
        {buildingCapacityRange.map((val, idx) => {
          return (
            <TableCell
              key={`tm${idx}`}
              style={{ paddingRight: "12px", paddingBottom: "16px" }}
              className={isWeekend(new Date(val.date)) ? classes.weekend : ""}
            >
              {buildingTeamSchedule ? (
                <TeamScheduleBox
                  isSite={isSite}
                  t={t}
                  team={team}
                  buildingTeamSchedule={buildingTeamSchedule}
                  date={val.date}
                />
              ) : (
                ""
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
});

export default TeamScheduleRow;
