import { observer } from "mobx-react-lite";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import { bhpColor, settings, displaySettings } from "styles/globals";
import { useEffect, useState, Fragment, useCallback } from "react";
import { capitalizeString } from "utils/string";
import { toJS } from "mobx";
import { useHistory } from "react-router-dom";
import {
  Box,
  Card,
  Grid,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  useMediaQuery,
  TextField,
  Button,
  Link,
} from "@material-ui/core";
import { User } from "interfaces/User";
import { BuildingWithCapacity } from "interfaces/Building";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridDensityTypes,
  GridOverlay,
} from "@material-ui/data-grid";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import { useBAMStore, useBuildingStore, useUserStore } from "providers/RootStoreProvider";
import { Vms_Meeting_Items, VmsMeetingStatusChoices } from "interfaces/Visitor";
import { ConfirmDialog } from "components/utils/ConfirmDialog";

interface ActiveTabProps {
  t: any;
  status: "all" | "open" | "cancelled";
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
        outline: "none",
      },
      border: 0,
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
        fontSize: "12px",
      },
      "& .MuiDataGrid-row": {
        "& .fullRowStyles": {
          maxHeight: "100px",
        },
        maxHeight: "unset",
        "&:nth-child(odd)": {
          backgroundColor: bhpColor.backgroundGrey,
        },
        "& .MuiDataGrid-cell": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          "&:nth-child(odd)": {
            backgroundColor: bhpColor.backgroundGrey,
          },
        },
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
    },
    saveSuccess: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.orange1,
        fontWeight: "bold",
        backgroundColor: bhpColor.orange4,
        border: `2px solid ${bhpColor.orange1}`,
        borderRadius: settings.borderRadius,
      },
    },
  })
);

const tableStyles = makeStyles({
  tableRow: {
    height: 40,
    "&:nth-child(odd)": {
      backgroundColor: bhpColor.backgroundGrey,
    },
  },
  tableCell: {
    padding: "0px 16px",
  },
  buildingSelector: {
    padding: 0,
    marginRight: "8px",
    "& .MuiSelect-root": {
      padding: "4px",
      width: "80px",
      fontSize: "14px",
    },
    "& .MuiSelect-iconOutlined": {
      right: 0,
    },
  },
});

const ActiveTab = observer((props: ActiveTabProps) => {
  const { t, status } = props;
  const classes = useStyles();
  const tableClasses = tableStyles();
  const history = useHistory();
  const bamStore = useBAMStore();
  const userStore = useUserStore();
  const buildingStore = useBuildingStore();

  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [meetingToCancel, setMeetingToCancel] = useState<Vms_Meeting_Items | null>(null);

  function CustomNoRowsOverlay() {
    return <GridOverlay>Nothing to show...</GridOverlay>;
  }

  const [vmsMeetings, setVmsMeetings] = useState<Vms_Meeting_Items[]>(
    bamStore.getVmsMeetings(status == "open" ? [0, 1, 2, 3] : [9])
  );

  const setStatus = (request: any, newStatus: VmsMeetingStatusChoices) => {
    const request_id = request.vms_visit_id;
    bamStore.vmsChangeStatus(newStatus, request_id).then((res) => {
      const successText: string = "Meeting cancelled successfully";
      const failText: string = "Not updated !!!";
      if (res.status == 200 || res.status == 201) {
        setMessage(successText);
        setIsOpen(true);
      } else {
        const responseMessage = res.data.response.data
          ? JSON.stringify(res.data.response.data)
          : failText;
        setMessage(responseMessage);
        setIsOpen(true);
      }
      bamStore.loadVmsMeetings(userStore.me!.id, VmsMeetingStatusChoices.SUBMITTED).then((req) => {
        setVmsMeetings(req ? req.slice() : []);
        bamStore.loadAllVmsMeetings(userStore.me!.id);
      });
    });
  };

  const handleCancelClick = (meeting: Vms_Meeting_Items) => {
    const currentTime = moment();
    const meetingStartTime = moment(meeting.start_date_time);

    if (meetingStartTime.isBefore(currentTime)) {
      setMessage(
        "The meeting has already started. Please check with with the Concierge Team to arrange cancellation."
      );
      setIsOpen(true);
    } else {
      setMeetingToCancel(meeting);
      setConfirmDialogOpen(true);
    }
  };

  const handleConfirmCancel = () => {
    if (meetingToCancel) {
      setStatus(meetingToCancel, VmsMeetingStatusChoices.CANCELLED);
    }
    setConfirmDialogOpen(false);
  };

  const handleCancelDialogClose = () => {
    setConfirmDialogOpen(false);
    setMeetingToCancel(null);
  };

  useEffect(() => {
    bamStore.loadAllVmsMeetings(userStore.me!.id);
    if (bamStore.vms_meetings_grouped_status) {
      setVmsMeetings(bamStore.getVmsMeetings([0, 1, 2, 3]));
    }
  }, []);

  const onUpdateClick = (meetingId: any): void => {
    const newUrl = `/meeting-details/${meetingId}`;
    const vmsMeetingId: number = meetingId;
    history.push(newUrl, vmsMeetingId);
  };

  const columns: GridColDef[] = [
    {
      field: "status_id",
      headerName: t("Action"),
      sortable: false,
      disableColumnMenu: true,
      width: 70,
      minWidth: 70,
      renderHeader: (params: GridColumnHeaderParams) => {
        return status == "cancelled" ? "Status" : "Action";
      },
      renderCell: (params: GridCellParams) => {
        return status == "cancelled" ? (
          <Tooltip title={VmsMeetingStatusChoices[params.row.status_id]}>
            <Box>{VmsMeetingStatusChoices[params.row.status_id]}</Box>
          </Tooltip>
        ) : (
          <Grid container style={{ width: "100%", display: "flex" }}>
            <Grid
              item
              xs={6}
              style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              onClick={() => handleCancelClick(params.row as Vms_Meeting_Items)}
            >
              <Tooltip title="Click to cancel the visit">
                <CancelIcon htmlColor="#ED4C4C" />
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "vms_visit_id",
      headerName: t("ID"),
      sortable: true,
      disableColumnMenu: true,
      width: 70,
      minWidth: 70,
      renderCell: (params: GridCellParams) => {
        return (
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              return onUpdateClick(params.value);
            }}
            underline="always"
            color="inherit"
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "start_date_time",
      headerName: t("Start Date"),
      sortable: true,
      disableColumnMenu: true,
      width: 120,
      minWidth: 120,
      renderCell: (params: GridCellParams) => {
        return params.row.start_date_time
          ? moment(params.row.start_date_time).format("YYYY-DD-MM")
          : "--";
      },
    },
    {
      field: "end_date_time",
      headerName: t("End Date"),
      sortable: true,
      disableColumnMenu: true,
      width: 120,
      minWidth: 120,
      renderCell: (params: GridCellParams) => {
        return params.row.end_date_time ? getDisplayTime(params.row.end_date_time) : "--";
      },
    },
    {
      field: "requester_id",
      headerName: t("Requestor"),
      sortable: true,
      disableColumnMenu: true,
      width: 150,
      minWidth: 150,
      renderCell: (params: GridCellParams) => {
        return `${params.row.requester_first_name} ${params.row.requester_last_name}`;
      },
    },
    {
      field: "host_id",
      headerName: t("Host"),
      sortable: true,
      disableColumnMenu: true,
      width: 150,
      minWidth: 150,
      renderCell: (params: GridCellParams) => {
        return `${params.row.host_name_first_name} ${params.row.host_name_last_name}`;
      },
    },
    {
      field: "location",
      headerName: t("Location"),
      sortable: true,
      disableColumnMenu: true,
      width: 150,
      minWidth: 150,
      renderCell: (params: GridCellParams) => {
        const thisBuilding = buildingStore.getBuilding(Number(params.row.location_id));
        return (
          <Tooltip title={`${thisBuilding?.display_name}`}>
            <Box>
              {(params.row.location_id && thisBuilding?.building_name) || params.row.location_id}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "meeting_name",
      headerName: t("Meeting Name"),
      sortable: true,
      disableColumnMenu: true,
      width: 200,
      minWidth: 200,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.access_reason || ""}>
          <Box>{params.row.meeting_name}</Box>
        </Tooltip>
      ),
    },
    {
      field: "meeting_description",
      headerName: t("Meeting Description"),
      sortable: true,
      disableColumnMenu: true,
      width: 200,
      minWidth: 200,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.meeting_description || ""}>
          <Box>{params.row.meeting_description}</Box>
        </Tooltip>
      ),
    },
  ];

  const getDisplayTime = (dt: Date, format: string = "YYYY-DD-MM") => {
    if (!dt) return "--";
    return new Date(dt).getTime() < new Date("2035-01-01").getTime()
      ? moment(dt).format(format)
      : "";
  };

  return (
    <Fragment>
      <Box>
        {mdAndUp ? (
          <DataGrid
            style={{ color: bhpColor.blueGrey1, height: `${mdAndUp ? "700px" : "900px"}` }}
            className={classes.table}
            columns={columns}
            rows={vmsMeetings}
            rowCount={vmsMeetings ? vmsMeetings.length : 0}
            rowHeight={mdAndUp ? 67 : 125}
            pageSize={10}
            rowsPerPageOptions={[10]}
            density={mdAndUp ? GridDensityTypes.Compact : GridDensityTypes.Comfortable}
            disableColumnSelector={true}
            headerHeight={mdAndUp ? undefined : 0}
            hideFooterSelectedRowCount={true}
            scrollbarSize={0}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        ) : (
          <Table>
            <TableBody>
              {vmsMeetings.map((row, idx) => {
                const thisBuilding = buildingStore.getBuilding(Number(row.location_id));
                return (
                  <TableRow key={`tr${idx}`} className={tableClasses.tableRow}>
                    <TableCell style={{ paddingLeft: "4px", paddingRight: "4px", width: "60px" }}>
                      <Grid container style={{ height: "120px" }}>
                        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                          {status == "cancelled" ? (
                            <Tooltip title={VmsMeetingStatusChoices[row.status_id]}>
                              <Box>{VmsMeetingStatusChoices[row.status_id]}</Box>
                            </Tooltip>
                          ) : (
                            <Grid container style={{ width: "100%", display: "flex" }}>
                              <Grid
                                item
                                xs={6}
                                style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                onClick={() => handleCancelClick(row)}
                              >
                                <Tooltip title="Click to cancel the visit">
                                  <CancelIcon htmlColor="#ED4C4C" />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          ID: {row.vms_visit_id}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12}>
                          <div style={{ display: "inline-block", width: "150px" }}>Host:</div>
                          <strong>{`${row.host_name_first_name} ${row.host_name_last_name}`}</strong>
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ display: "inline-block", width: "100px" }}>From:</div>
                          {row.start_date_time
                            ? moment(row.start_date_time).format("MMM Do h:mma")
                            : "--"}
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ display: "inline-block", width: "100px" }}>To:</div>
                          {row.end_date_time
                            ? getDisplayTime(row.end_date_time, "MMM Do h:mma")
                            : "--"}
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ display: "inline-block", width: "150px" }}>Requestor:</div>
                          <strong>{`${row.requester_first_name} ${row.requester_last_name}`}</strong>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex" }}>
                          <div style={{ display: "inline-block", width: "150px" }}>Location:</div>
                          <strong>
                            {(row.location_id && thisBuilding?.building_name) || row.location_id}
                          </strong>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex" }}>
                          <div style={{ display: "inline-block", width: "150px" }}>
                            Meeting Name:
                          </div>
                          <strong>{row.meeting_name}</strong>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex" }}>
                          <div style={{ display: "inline-block", width: "150px" }}>
                            Meeting Description:
                          </div>
                          <strong>{row.meeting_description}</strong>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={classes.saveSuccess}
        autoHideDuration={displaySettings.snackDelay}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          if (isApiError) {
            setIsApiError(false);
          }
        }}
        message={message}
        key={"top" + "right"}
      />
      {confirmDialogOpen && (
        <ConfirmDialog
          isOpen={confirmDialogOpen}
          title={t("Confirm Cancellation")}
          primaryButtonText={t("Cancel Meeting")}
          secondaryButtonText={t("Go Back")}
          handlePrimaryClick={handleConfirmCancel}
          handleSecondaryClick={handleCancelDialogClose}
        >
          {t("Are you sure you want to cancel this meeting?")}
        </ConfirmDialog>
      )}
    </Fragment>
  );
});

export default ActiveTab;
