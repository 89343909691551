import {
  Box,
  Typography,
  Grid,
  TextField,
  debounce,
  FormControl,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  Paper,
  Avatar,
  Tooltip,
  Link,
} from "@material-ui/core";
import { teamStyles } from "styles/teamStyles";
import { useHistory } from "react-router-dom";
import { bhpColor } from "styles/globals";
import SearchIcon from "@material-ui/icons/Search";

import { Team, TeamVisibilityType } from "interfaces/Teams";
import {
  getTeamCategoryString,
  getTeamsCategoryKeyValues,
  getTeamVisibilityString,
} from "utils/teams";
import { useCallback, useEffect, useState } from "react";

import { useTeamStore, useBuildingStore } from "providers/RootStoreProvider";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnMenuContainer,
  GridFilterMenuItem,
  GridOverlay,
} from "@material-ui/data-grid";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { AvatarGroup } from "@material-ui/lab";
import VpnLockIcon from "@material-ui/icons/VpnLock";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import { get } from "lodash";
interface TeamSearchProps {
  teamId?: number | null;
  teamName?: string | null;
  department?: string;
  users?: any;
  t: any;
}
export function TeamSearch(props: TeamSearchProps) {
  const { teamName, department, users, teamId, t } = props;
  const teamStore = useTeamStore();
  const buildingStore = useBuildingStore();
  const classes = teamStyles();
  const history = useHistory();
  const [selectedCategory, setSelectedCategory] = useState<number>(-1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredTeams, setFilteredTeams] = useState<Team[]>([]);
  const [filteredTeamCount, setFilteredTeamCount] = useState<number>(0);
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const gridRowHeight = smAndUp ? 52 : 100;
  const gridPageSize = 20;
  const [filterModel, setFilterModel] = useState({
    items: [{}],
  });
  const maxAvatarCount = 2;

  useEffect(() => {
    if (searchQuery !== "" || selectedCategory != -1)
      (async () => {
        const filteredTeamResult = await teamStore.getFilteredTeams(
          searchQuery,
          selectedCategory,
          gridPageSize
        );
        setFilteredTeams(filteredTeamResult.results);
        setFilteredTeamCount(filteredTeamResult.count);
      })();
    else setFilteredTeams([]);
  }, [searchQuery, selectedCategory, buildingStore]);

  useEffect(() => {
    buildingStore.loadBuildings();
  }, []);

  const CustomNoRowsOverlay = () => {
    return <GridOverlay className={classes.root}>{t("No records found.")}</GridOverlay>;
  };

  const searchQueryHandler = useCallback(
    debounce((value: string) => setSearchQuery(value), 1000),
    []
  );

  const CustomColumnMenu = (props: any) => {
    const { hideMenu, currentColumn } = props;
    return (
      <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} open={false}>
        <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
      </GridColumnMenuContainer>
    );
  };

  const onLocationClick = (locationId: any): void => {
    const locationUrl = `/offices/${locationId}`;
    history.push(locationUrl);
  };

  const getInitials = (owner: any) => {
    if (!owner) return "--";
    const firstPart = get(owner, "preferred_name", "-");
    const lastPart = get(owner, "last_name", "-");
    return `${firstPart.charAt(0)}${lastPart.charAt(0)}`;
  };

  const getLocationName = (location: number) => {
    const building = buildingStore.getBasicBuilding(location);
    return building ? `${building?.city}, ${building?.building_name}` : "";
  };

  const getTeamVisibilityIcon = (visibility: TeamVisibilityType) => {
    switch (visibility) {
      case TeamVisibilityType.VISIBLE:
        return <PublicOutlinedIcon />;
      case TeamVisibilityType.PRIVATE:
        return <LockOutlinedIcon />;
      case TeamVisibilityType.CLOSED:
        return <VpnLockIcon />;
      default:
        return null;
    }
  };

  const handlePageChange = async (thisPage: number) => {
    const currentListSize = filteredTeams.length;
    const startOfThisPage = gridPageSize * thisPage;
    if (startOfThisPage >= currentListSize) {
      const nextResults = await teamStore.getFilteredTeams(
        searchQuery,
        selectedCategory,
        gridPageSize,
        startOfThisPage
      );
      setFilteredTeams([...filteredTeams, ...nextResults.results]);
    }
    return undefined;
  };

  const gridColumns: GridColDef[] = [
    {
      field: "teamName",
      headerName: t("Team Name"),
      flex: 1.5,
      sortable: true,
      valueGetter: (params: any) => `${params?.row?.name}`,
      renderCell: (params: GridCellParams) => {
        return (
          <Typography style={{ fontWeight: 700 }}>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                const teamInformationUrl = `/teams/information/${params.row.id}`;
                history.push(teamInformationUrl);
              }}
              underline="always"
              color="inherit"
            >
              {params.row.name}
            </Link>
          </Typography>
        );
      },
    },
    {
      field: "members",
      headerName: t("Members"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => params?.row?.member_count,
    },
    {
      field: "category",
      headerName: t("Category"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => getTeamCategoryString(params?.row?.category),
    },
    {
      field: "classsification ",
      headerName: t("Visibility"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => getTeamVisibilityString(params?.row?.visibility),
    },
    {
      field: "location ",
      headerName: t("Location"),
      sortable: true,
      flex: 1.4,
      valueGetter: (params) => getLocationName(params?.row?.location),
      renderCell: (params: GridCellParams) => {
        const thisLocation = getLocationName(params?.row?.location);
        return thisLocation === "" ? (
          "-"
        ) : (
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              return onLocationClick(params?.row?.location);
            }}
            underline="always"
            color="inherit"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {thisLocation}
          </Link>
        );
      },
    },
    {
      field: "owners ",
      headerName: t("Owners"),
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            style={{ marginTop: 0 }}
            className={
              classes.summaryTeamAvatars +
              " " +
              `${
                params?.row?.owners &&
                params?.row?.owners?.length > maxAvatarCount &&
                classes.summaryTeamWithMoreAvatars
              }`
            }
          >
            {params.row.owners && params.row.owners.length > 0 && (
              <AvatarGroup max={maxAvatarCount}>
                {params?.row?.owners?.map((owner: any, index: number) => {
                  return (
                    <Tooltip title={owner.preferred_name} key={index}>
                      <Avatar src={owner.picture}>{getInitials(owner)}</Avatar>
                    </Tooltip>
                  );
                })}
              </AvatarGroup>
            )}
          </div>
        );
      },
    },
  ];

  const gridColumnsMobile: GridColDef[] = [
    {
      field: "teamDetail",
      headerName: t(" "),
      flex: 2,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Box px={1} py={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 700 }}>
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      const teamInformationUrl = `/teams/information/${params.row.id}`;
                      history.push(teamInformationUrl);
                    }}
                    underline="always"
                    color="inherit"
                  >
                    {params.row.name}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography display={"block"} component={"span"}>
                      {getTeamCategoryString(params?.row?.category)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: "right" }}>
                    <Typography style={{ fontSize: "smaller" }} component={"span"}>
                      <div>{getTeamVisibilityIcon(params?.row?.visibility)}</div>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {params?.row?.location && (
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "smaller" }}>
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        return onLocationClick(params?.row?.location);
                      }}
                      underline="always"
                      color="inherit"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {getLocationName(params?.row?.location)}
                    </Link>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <div style={{ color: bhpColor.grey2, fontSize: 12, lineHeight: 2 }}>
                  {`${params?.row?.member_count} Member${params?.row?.member_count > 1 ? "s" : ""}`}
                </div>
              </Grid>
            </Grid>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box className={classes.searchTeam}>
        <Typography variant={"h3"} style={{ color: bhpColor.blueGrey2 }}>
          {t(`Search`)}
        </Typography>
        <Box pt={3} pb={3}>
          <Grid container spacing={smAndUp ? 4 : 2}>
            <Grid item sm={6} md={4} lg={4}>
              <TextField
                id="search"
                style={{ backgroundColor: "white" }}
                label={t("Search")}
                placeholder={t("Search")}
                type="text"
                size="small"
                variant="outlined"
                fullWidth
                autoComplete="off"
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
                onChange={(event) => {
                  searchQueryHandler(event.target.value);
                }}
              />
            </Grid>

            <Grid item sm={6} md={4} lg={4}>
              <FormControl variant="filled" size="small" fullWidth>
                <Select
                  labelId="teamsCategorySelectLabel"
                  id="teamsCategorySelectID"
                  value={selectedCategory}
                  onChange={(event) => setSelectedCategory(event.target.value as number)}
                  style={{ backgroundColor: "white" }}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value={-1}>
                    <em style={{ opacity: "38%" }}>{t(`Category`)}</em>
                  </MenuItem>
                  {getTeamsCategoryKeyValues().map((category) => {
                    return (
                      <MenuItem value={category.value} key={category.key}>
                        {getTeamCategoryString(category.value)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.root} py={1} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Paper className={classes.paper} elevation={0}>
            {(searchQuery || selectedCategory != -1) && filteredTeams?.length >= 0 && (
              <div className={classes.recordsFoundLabel}>
                {filteredTeams?.length}{" "}
                {filteredTeams?.length > 1 ? t("results found") : t("result found")}
              </div>
            )}

            {searchQuery !== "" || selectedCategory !== -1 || filteredTeams?.length > 0 ? (
              <Grid className={classes.dataGrid} container>
                <Grid item xs>
                  <div
                    className={classes.spaceTable}
                    style={{
                      height:
                        filteredTeams && filteredTeams?.length > 0
                          ? Math.min(filteredTeams?.length + 1, gridPageSize) * gridRowHeight + 100
                          : 200,
                      width: "100%",
                    }}
                  >
                    <DataGrid
                      rows={[...filteredTeams]}
                      rowCount={filteredTeamCount || undefined}
                      rowHeight={gridRowHeight}
                      columns={smAndUp ? gridColumns : gridColumnsMobile}
                      pageSize={gridPageSize}
                      rowsPerPageOptions={[gridPageSize]}
                      loading={teamStore.isLoading}
                      components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: ExpandMore,
                        ColumnSortedDescendingIcon: ExpandLess,
                        ColumnUnsortedIcon: UnfoldMoreIcon,
                        ColumnMenu: CustomColumnMenu,
                      }}
                      disableSelectionOnClick
                      hideFooterRowCount
                      filterModel={filterModel}
                      onFilterModelChange={(model) => setFilterModel(model)}
                      className={
                        smAndUp
                          ? ""
                          : `${classes.hideDataGridColumnHeader} ${classes.dataGridMobileRow} ${classes.hideDataGridColumnHeader}`
                      }
                      headerHeight={smAndUp ? 56 : 4}
                      onPageChange={(params) => handlePageChange(params)}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : (
              <div style={{ height: "100px", lineHeight: "100px", textAlign: "center" }}>
                {t("Enter search term to see results")}
              </div>
            )}
          </Paper>
        </Box>
      </Box>
    </>
  );
}
