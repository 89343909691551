import { makeAutoObservable, flow } from "mobx";
import RootStore from "./RootStore";
import { Event, EventDates } from "./../interfaces/Event";
import { getEventFilterDates } from "utils/events";
import moment from "moment";
import client from "services/api";

export default class EventStore {
  isLoading = true;
  error = "";
  rootStore!: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  getEventById = flow(function* (this: EventStore, eventId: number) {
    this.isLoading = true;
    this.error = "";
    try {
      const res = yield client.get(`/api/building/events/${eventId}/`);
      return res.data;
    } catch (err: any) {
      this.error = err;
    }
    this.isLoading = false;
  });

  getFilteredEventsSummary = flow(function* (
    this: EventStore,
    dateFilter: EventDates,
    buildingId: number
  ) {
    this.isLoading = true;
    this.error = "";
    const { startDate, endDate } = getEventFilterDates(dateFilter);
    try {
      const res = yield client.get(`/api/building/${buildingId}/events/${startDate}/${endDate}/`);
      return res.data.results;
    } catch (err: any) {
      this.error = err;
    }
    this.isLoading = false;
  });
}
