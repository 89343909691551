import { createContext, ReactNode, useContext } from "react";
import RootStore from "stores/RootStore";

let store: RootStore;
const StoreContext = createContext<RootStore | undefined>(undefined);
StoreContext.displayName = "StoreContext";

export function useRootStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error("useRootStore must be used within RootStoreProvider");
  }
  return context;
}

export function useBAMStore() {
  const { bamStore } = useRootStore();
  return bamStore;
}

export function useTeamStore() {
  const { teamStore } = useRootStore();
  return teamStore;
}

export function useUtilStore() {
  const { utilStore } = useRootStore();
  return utilStore;
}

export function useBookingStore() {
  const { bookingStore } = useRootStore();
  return bookingStore;
}

export function useScheduleStore() {
  const { scheduleStore } = useRootStore();
  return scheduleStore;
}

export function useBuildingStore() {
  const { buildingStore } = useRootStore();
  return buildingStore;
}

export function useUserStore() {
  const { userStore } = useRootStore();
  return userStore;
}

export function useNotificationStore() {
  const { notificationStore } = useRootStore();
  return notificationStore;
}

export function usePeopleStore() {
  const { peopleStore } = useRootStore();
  return peopleStore;
}

export function useEventStore() {
  const { eventStore } = useRootStore();
  return eventStore;
}

export function RootStoreProvider({ children }: { children: ReactNode }) {
  // only create root store once (store is a singleton)
  const root = store ?? new RootStore();
  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
}

export function useFeedbackStore() {
  const { feedbackStore } = useRootStore();
  return feedbackStore;
}

export function useVisitorStore() {
  const { visitorStore } = useRootStore();
  return visitorStore;
}

export function useLeaveStore() {
  const { leaveStore } = useRootStore();
  return leaveStore;
}
