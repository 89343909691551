import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  useMediaQuery,
  Typography,
  Link,
  Grid,
  FormControl,
  Select,
  Snackbar,
  Button,
  TextField,
  MenuItem,
  debounce,
} from "@material-ui/core";
import { DataGrid, GridCellParams, GridColDef, GridOverlay } from "@material-ui/data-grid";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import SearchIcon from "@material-ui/icons/Search";
import { TeamParticipant, TeamRoleType } from "interfaces/Teams";
import { set } from "lodash";
import { observer } from "mobx-react-lite";
import { usePeopleStore, useTeamStore } from "providers/RootStoreProvider";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { bhpColor, displaySettings, settings } from "styles/globals";
import { capitalizeString } from "utils/string";
import AddPeopleDialog from "./AddPeopleDialog";

interface TeamDetailsPeopleTabProps {
  teamId: number;
  t: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
    },
    spaceTable: {
      border: 0,
      "& .MuiTablePagination-root": {
        color: bhpColor.blueGrey1,
      },
      "& .MuiDataGrid-renderingZone": {
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-viewport": {
        maxHeight: "none",
      },
      "& .MuiDataGrid-root": {
        border: 0,
      },
      "& .MuiDataGrid-cell": {
        border: 0,
        "&:focus": {
          outline: 0,
        },
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-columnHeader": {
        "&:focus": {
          outline: 0,
        },
        "&:focus-within": {
          outline: 0,
        },
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        paddingLeft: 0,
      },
      "& .MuiDataGrid-columnsContainer": {
        borderBottom: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
        color: `${bhpColor.blueGrey1} !important`,
      },
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-row": {
        maxHeight: "none !important",
        "&:nth-child(odd)": {
          backgroundColor: bhpColor.backgroundGrey,
          "&:hover": {
            backgroundColor: bhpColor.backgroundGrey,
          },
        },
        "&.Mui-selected": {
          "&:hover": {
            backgroundColor: bhpColor.blueGrey2,
            color: bhpColor.blueGrey1,
          },
          backgroundColor: bhpColor.blueGrey1,
          color: bhpColor.white,
        },
        "&:hover": {
          backgroundColor: bhpColor.backgroundGrey,
        },
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      border: 0,
    },
    dataGrid: {
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiDataGrid-row.Mui-even": {
        backgroundColor: "#F6F6F6",
      },
      "& .MuiDataGrid-cell": {
        color: "#466371",
        "&:focus-within": {
          outlineWidth: 0,
        },
      },
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-sortIcon": {
          opacity: `1 !important`,
        },
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        color: "#50524D",
        fontWeight: "bold !important",
        paddingRight: 16,
      },
      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden",
      },
      padding: "0 1% 1% 1%",
    },
    formControl: {
      display: "block",
      width: "100%",
      margin: 0,
    },
    selectBox: {
      width: "100%",
      height: 40,
      "& svg": {
        zIndex: 2,
      },
      "& select": {
        zIndex: 2,
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& fieldset": {
        zIndex: 1,
        height: 40,
        top: -3,
        backgroundColor: "white",
        "& .Mui-focussed": {
          border: 0,
          backgroundColor: "white",
        },
      },
    },
    selectInput: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    saveFailed: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.purple1,
        fontWeight: "bold",
        backgroundColor: bhpColor.purple4,
        border: `2px solid ${bhpColor.purple1}`,
        borderRadius: settings.borderRadius,
      },
    },
    saveSuccess: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.orange1,
        fontWeight: "bold",
        backgroundColor: bhpColor.orange4,
        border: `2px solid ${bhpColor.orange1}`,
        borderRadius: settings.borderRadius,
      },
    },
    inlineLink: {
      display: "flex",
      alignItems: "center",
      fontWeight: "bold",
      cursor: "pointer",
    },
    outer: {
      borderRadius: settings.borderRadius,
      border: `1px solid ${bhpColor.blueGrey4}`,
      color: bhpColor.blueGrey1,
    },
  })
);

const TeamDetailsPeopleTab = observer((props: TeamDetailsPeopleTabProps) => {
  const { teamId, t } = props;
  const classes = innerStyles();
  const teamStore = useTeamStore();
  const peopleStore = usePeopleStore();
  const theme = useTheme();
  const history = useHistory();

  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const gridRowHeight = mdAndUp ? 52 : 100;
  const gridPageSize = 20;
  const [filterModel, setFilterModel] = useState({
    items: [{}],
  });

  const [snackOpen, setSnackOpen] = useState(false);
  const [saveState, setSaveState] = useState(true);
  const [snackMessage, setSnackMessage] = useState(t("Team Update Successful"));
  const snackbarOpen = () => setSnackOpen(true);
  const snackbarClose = () => setSnackOpen(false);
  const setSnackBox = (success: boolean, msg?: string) => {
    setSnackMessage(success ? t("Team Update Successful") : `${t(msg)}`);
    setSaveState(success);
  };

  const [teamMembers, setTeamMembers] = useState<TeamParticipant[]>([]);
  const getTeamMembers = async () => {
    const res = await teamStore.loadTeamMembers(teamId);
    setMembers(res);
  };

  useEffect(() => {
    getTeamMembers();
  }, []);

  const CustomNoRowsOverlay = () => {
    return <GridOverlay className={classes.root}>{t("No records found.")}</GridOverlay>;
  };

  const getItemsFromEnum = (theEnum: any) => {
    return Object.entries(theEnum)
      .filter(([key, value]) => {
        return !isNaN(Number(key));
      })
      .map(([key, value]) => ({
        value: key,
        label: capitalizeString(`${value}`),
      }));
  };

  const setMembers = (data: TeamParticipant[]) => {
    if (data) {
      const theMembers = data.map((participant: TeamParticipant, idx: number) => ({
        ...participant,
        id: idx,
      }));
      setTeamMembers(theMembers);
    }
  };

  const removeTeamMember = async (teamId: number, userId: number) => {
    const res = await teamStore.deleteMember(teamId, userId);
    if (res.status === 200) {
      setSnackBox(true);
      snackbarOpen();
      setMembers(teamMembers.filter((part) => part.user_id !== userId));
    } else {
      setSnackBox(false, res.data.response.data.errors);
      snackbarOpen();
    }
  };

  const removeMember = (memberId: number) => {
    if (memberId) {
      removeTeamMember(teamId, memberId);
    }
  };

  const updateTeam = async (
    teamId: number,
    userIds: number[],
    teamIds: number[],
    role: TeamRoleType
  ) => {
    const res = await teamStore.changeTeamMembers(teamId, userIds, teamIds, role);
    if (res.status === 200) {
      setSnackBox(true);
      snackbarOpen();
      setMembers(res.data);
    } else {
      setSnackBox(false, "There was an error saving the team");
      snackbarOpen();
    }
  };

  const updateRole = async (teamId: number, userId: number, role: TeamRoleType) => {
    const res = await teamStore.changeMemberRole(teamId, userId, role);
    if (res.status === 200) {
      setSnackBox(true);
      snackbarOpen();
      setMembers(res.data);
    } else {
      setSnackBox(false, res.data.response.data.errors);
      snackbarOpen();
    }
  };

  const handleRoleChange = (evt: any, userId: number) => {
    const value = Number(evt.nativeEvent.target.value);
    updateRole(teamId, userId, value);
  };

  const gridColumns: GridColDef[] = [
    {
      field: "user_name",
      headerName: t("Name"),
      flex: 1,
      sortable: true,
      valueGetter: (params: any) => `${params?.row?.user_name}`,
      renderCell: (params: GridCellParams) => {
        return (
          <Typography style={{ fontWeight: 700 }}>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                const peopleDetailURL = `/people/${params.row.user_id}`;
                history.push(peopleDetailURL);
              }}
              underline="always"
              color="inherit"
            >
              {params.row.user_name}
            </Link>
          </Typography>
        );
      },
    },
    {
      field: "role_title",
      headerName: t("Role Title"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => params?.row?.role_title,
    },
    {
      field: "default_team",
      headerName: t("Default Team"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => params?.row?.default_team,
    },
    {
      field: "role",
      headerName: t("Role"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => params?.row?.role,
      renderCell: (params) => {
        return (
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              native
              variant="outlined"
              className={classes.selectBox}
              onChange={(e) => handleRoleChange(e, params.row.user_id)}
              value={`${params.row.role}`}
            >
              {getItemsFromEnum(TeamRoleType).map((itm, key) => {
                return (
                  <option key={key} value={itm.value}>
                    {itm.label}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "user_id",
      headerName: t("Action"),
      hideSortIcons: true,
      flex: 1,
      valueGetter: (params) => params?.row?.user_id,
      renderCell: (params) => (
        <Link
          className={classes.inlineLink}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            removeMember(params.row.user_id);
          }}
          underline="always"
          color="primary"
        >
          REMOVE
        </Link>
      ),
    },
  ];

  const gridColumnsMobile: GridColDef[] = [
    {
      field: "user_name",
      headerName: t("Name"),
      flex: 1.5,
      sortable: true,
      valueGetter: (params: any) => `${params?.row?.user_name}`,
      renderCell: (params: GridCellParams) => {
        return (
          <Typography style={{ fontWeight: 700 }}>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                const peopleDetailURL = `/people/${params.row.user_id}`;
                history.push(peopleDetailURL);
              }}
              underline="always"
              color="inherit"
            >
              {params.row.user_name}
            </Link>
          </Typography>
        );
      },
    },
    {
      field: "user_id",
      headerName: "Action",
      hideSortIcons: true,
      flex: 1,
      valueGetter: (params) => params?.row?.user_id,
      renderCell: (params) => (
        <Link
          className={classes.inlineLink}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            removeMember(params.row.user_id);
          }}
          underline="always"
          color="primary"
        >
          REMOVE
        </Link>
      ),
    },
  ];

  const handleResponseClick = (participant: any, role: TeamRoleType) => {
    let teamIds: number[] = [];
    let userIds: number[] = [];
    switch (participant.type) {
      case "User":
        if (userIds.indexOf(participant.user_id) === -1) userIds.push(participant.user_id);
        break;
      case "Team":
        teamIds.push(participant.user_id);
        break;
    }
    updateTeam(teamId, userIds, teamIds, role);
    setAddPeopleDialogOpen(false);
  };

  const handleClose = (res: any) => {
    res === false && setAddPeopleDialogOpen(false);
  };

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<TeamRoleType | -1>(-1);
  const [addPeopleDialogOpen, setAddPeopleDialogOpen] = useState(false);

  const addPeople = () => {
    peopleStore.setSearchFilter("");
    setAddPeopleDialogOpen(true);
  };

  const searchQueryHandler = useCallback(
    debounce((value: string) => setSearchQuery(value), 750),
    []
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={saveState ? classes.saveSuccess : classes.saveFailed}
        autoHideDuration={displaySettings.snackDelay}
        open={snackOpen}
        onClose={snackbarClose}
        message={snackMessage}
      />
      <Box
        style={{ color: bhpColor.blueGrey1 }}
        marginTop={{ xs: 0, md: 2 }}
        marginBottom={{ xs: 0, md: 8 }}
        padding={{ xs: 1, md: 0 }}
      >
        <Typography variant="h3">{t("Manage People")}</Typography>
        <Box my={1.5}>{t("Add, edit and search for people in your team")}</Box>
        <Button
          style={{ marginTop: 8 }}
          onClick={addPeople}
          variant="contained"
          color="primary"
          disableElevation
        >
          {t("Add People")}
        </Button>
      </Box>
      <Box px={{ xs: 1, md: 0 }}>
        <Grid container spacing={4}>
          <Grid item sm={6} md={6} lg={6}>
            <Box marginTop={{ xs: 2, md: 0 }}>
              <TextField
                id="search"
                label={t("Search for People or Team in this team")}
                placeholder={t("Search for People or Teams in this team")}
                type="text"
                size="small"
                variant="outlined"
                fullWidth
                autoComplete="off"
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
                onChange={(event) => {
                  searchQueryHandler(event.target.value);
                }}
              />
            </Box>
          </Grid>
          {mdAndUp && (
            <Grid item sm={6} md={6} lg={6}>
              <FormControl variant="filled" size="small" fullWidth>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  value={selectedRole}
                  onChange={(event) => setSelectedRole(event.target.value as number)}
                  variant="outlined"
                  placeholder={t("Search by role")}
                  fullWidth
                >
                  <MenuItem value={-1}>
                    <span style={{ opacity: "65%" }}>{t("Search by role")}</span>
                  </MenuItem>
                  {getItemsFromEnum(TeamRoleType).map((role) => {
                    return (
                      <MenuItem value={Number(role.value)} key={role.value}>
                        {capitalizeString(role.label)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box px={{ xs: 1, md: 0 }}>
        <Box className={classes.outer} my={{ xs: 2, md: 4 }}>
          <Grid className={classes.dataGrid} container>
            <Grid item xs>
              <div
                className={classes.spaceTable}
                style={{
                  height:
                    teamMembers && teamMembers?.length > 0
                      ? Math.min(teamMembers?.length + 1, gridPageSize) * gridRowHeight + 100
                      : 200,
                  width: "100%",
                }}
              >
                <DataGrid
                  rows={
                    searchQuery != ""
                      ? [
                          ...(selectedRole != -1
                            ? teamMembers.filter((memb) => memb.role === selectedRole)
                            : teamMembers),
                        ].filter(
                          (memb) =>
                            (memb.user_name &&
                              memb.user_name?.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                                -1) ||
                            (memb.default_team &&
                              memb.default_team?.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                                -1)
                        )
                      : [
                          ...(selectedRole != -1
                            ? teamMembers.filter((memb) => memb.role === selectedRole)
                            : teamMembers),
                        ]
                  }
                  rowCount={teamMembers?.length || undefined}
                  rowHeight={gridRowHeight}
                  columns={mdAndUp ? gridColumns : gridColumnsMobile}
                  pageSize={gridPageSize}
                  rowsPerPageOptions={[gridPageSize]}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    ColumnSortedAscendingIcon: ExpandMore,
                    ColumnSortedDescendingIcon: ExpandLess,
                    ColumnUnsortedIcon: UnfoldMoreIcon,
                  }}
                  disableSelectionOnClick
                  hideFooterRowCount
                  disableColumnMenu
                  filterModel={filterModel}
                  onFilterModelChange={(model) => setFilterModel(model)}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <AddPeopleDialog
        isOpen={addPeopleDialogOpen}
        t={t}
        handleCloseClick={handleClose}
        handleResponseClick={handleResponseClick}
      />
    </>
  );
});
export default TeamDetailsPeopleTab;
