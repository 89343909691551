import {
  Box,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
} from "@material-ui/core";
import { mdiCalendarRemoveOutline, mdiHeadset, mdiMapMarkerRadiusOutline } from "@mdi/js";
import { bhpColor, settings } from "styles/globals";
import { ScheduleType } from "interfaces/Schedule";
import { observer } from "mobx-react-lite";
import { TeamSchedulePeopleList, WorkSchedule } from "interfaces/Teams";
import { toJS } from "mobx";
import { getTime } from "interfaces/Utils";
import AvatarRow from "./AvatarRow";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: bhpColor.grey1,
      "& .MuiGrid-item": {
        display: "flex",
      },
      "& .MuiLinearProgress-barColorSecondary": {
        borderRadius: settings.borderRadius,
      },
    },
    teamOfficeBox: {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      padding: 12,
      backgroundColor: bhpColor.backgroundGrey,
      height: "88px",
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      justifyContent: "center",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        borderBottomLeftRadius: 0,
        paddingBottom: 0,
      },
      [theme.breakpoints.down("xs")]: {
        borderBottomLeftRadius: 0,
        paddingBottom: 0,
        fontSize: "small",
        "& .MuiTypography-caption": {
          fontSize: "xx-small",
        },
      },
    },
    teamAvatarRow: {
      backgroundColor: bhpColor.backgroundGrey,
      height: "88px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      paddingLeft: 0,
      paddingBottom: 12,
      paddingTop: 12,
      [theme.breakpoints.down("sm")]: {
        borderLeftBottomRadius: 4,
        paddingLeft: 12,
        paddingTop: 0,
      },
    },
    teamNumberBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      backgroundColor: bhpColor.backgroundGrey,
      height: "88px",
      width: "100%",
      paddingRight: 12,
      paddingBottom: 12,
      paddingTop: 12,
      [theme.breakpoints.down("sm")]: {
        borderTopRightRadius: 0,
        paddingTop: 0,
      },
    },
  })
);

/// From workType return the icon type office|remote|off (default: off)
function getIconPath(workType: ScheduleType) {
  switch (workType) {
    case ScheduleType.OFFICE:
      return mdiMapMarkerRadiusOutline;
    case ScheduleType.REMOTE:
      return mdiHeadset;
    case ScheduleType.OFF:
      return mdiCalendarRemoveOutline;
    default:
      return mdiCalendarRemoveOutline;
  }
}

interface CoreSchedule {
  buildingId: number;
  userId: number;
  firstName: string;
  lastName: string;
  buildingCity: string | null;
  buildingName: string | null;
  buildingTimezone: string;
  date: string;
  status: ScheduleType;
  spaceReference: string | null;
  user_picture: string;
  preferred_name: string;
}

interface TeamOfficeSummaryProps {
  teamSchedulePeopleList: TeamSchedulePeopleList[] | null;
  selectedDate: string;
  t: any;
}

const TeamOfficeSummary = observer((props: TeamOfficeSummaryProps) => {
  const { teamSchedulePeopleList, selectedDate, t } = props;
  const classes = innerStyles();
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));
  const history = useHistory();

  const getTime = (timezone?: string) => {
    return timezone
      ? ` ${t("Local Time")} ${t(moment(new Date()).tz(timezone).format("ddd"))} ${moment(
          new Date()
        )
          .tz(timezone)
          .format("h:mm a")} (${moment().tz(timezone).format("z")})`
      : moment(new Date()).format("DDD dd HH:mm");
  };

  const coreSchedules: CoreSchedule[] = teamSchedulePeopleList
    ? teamSchedulePeopleList
        .filter((tpsl) =>
          tpsl.work_schedules
            ? selectedDate in tpsl.work_schedules &&
              tpsl.work_schedules[selectedDate].status == ScheduleType.OFFICE
            : false
        )
        .map((tpsl) => {
          const thisWorkSchedule: WorkSchedule = tpsl.work_schedules[selectedDate];
          const thisEntry: CoreSchedule = {
            buildingId: thisWorkSchedule.building_id,
            buildingTimezone: tpsl.remote_timezone,
            userId: tpsl.user_id,
            firstName: tpsl.first_name,
            lastName: tpsl.last_name,
            date: thisWorkSchedule.date,
            buildingCity: thisWorkSchedule.city || null,
            buildingName: thisWorkSchedule.building_name || null,
            status: thisWorkSchedule.status,
            spaceReference: thisWorkSchedule.space || null,
            user_picture: tpsl.user_picture,
            preferred_name: tpsl.name,
          };
          return thisEntry;
        })
    : [];

  const blds = [...Array.from(new Set(coreSchedules.map((obj) => obj.buildingId)))];
  const activeOffices =
    blds.map((bldId) => {
      const coreBld = bldId ? coreSchedules.find((cs) => cs.buildingId == bldId) : null;
      if (coreBld) {
        const newEntry = {
          buildingId: coreBld.buildingId,
          buildingCity: coreBld.buildingCity,
          buildingName: coreBld.buildingName,
          buildingTimezone: coreBld.buildingTimezone,
          users: coreSchedules
            .filter((bl) => bl.buildingId == bldId)
            .map((ucs) => ({
              first_name: ucs.firstName,
              last_name: ucs.lastName,
              space_reference: ucs.spaceReference,
              userId: ucs.userId,
              user_picture: ucs.user_picture,
              preferred_name: ucs.preferred_name,
            })),
        };
        return newEntry;
      }
    }) || null;

  const officeSelect = (officeId: number) => {
    const newUrl = `/offices/${officeId}/capacity`;
    history.push(newUrl);
  };

  return activeOffices.length > 0 ? (
    <Box mt={1}>
      <Grid container className={classes.root}>
        <Hidden smDown>
          <Grid item xs={12}>
            <Grid item xs={12} sm={6}>
              <Typography style={{ fontWeight: 700, color: bhpColor.blueGrey1 }}>
                {t("Locations")}
              </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography style={{ fontWeight: 700, color: bhpColor.blueGrey1 }}>
                {t("Team Members")}
              </Typography>
            </Grid>
            <Grid item style={{ justifyContent: "flex-end" }} xs={4} sm={2}>
              <Typography
                style={{
                  fontWeight: 700,
                  textAlign: "end",
                  whiteSpace: "nowrap",
                  color: bhpColor.blueGrey1,
                }}
              >
                {t("# of Team")}
              </Typography>
            </Grid>
          </Grid>
        </Hidden>
        {activeOffices.map((office, idx) =>
          office?.buildingCity ? (
            <Grid item xs={12} key={`off${idx}`}>
              <Grid item xs={12} sm={6}>
                <Box mt={{ xs: 0, sm: 1 }} className={classes.teamOfficeBox}>
                  <Box>
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        officeSelect(office?.buildingId);
                      }}
                      underline="always"
                      color="inherit"
                    >
                      <strong>{office?.buildingCity}</strong>, {office?.buildingName}
                    </Link>
                  </Box>
                  <Typography style={{ display: "block" }} variant="caption">
                    {getTime(office?.buildingTimezone)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8} sm={4}>
                <Box mt={{ xs: 0, sm: 1 }} className={classes.teamAvatarRow}>
                  <AvatarRow userSchedules={office?.users || null} />
                </Box>
              </Grid>
              <Grid item xs={4} sm={2}>
                <Box mt={{ xs: 0, sm: 1 }} className={classes.teamNumberBox}>
                  {office?.users.length}
                </Box>
              </Grid>
            </Grid>
          ) : (
            ""
          )
        )}
      </Grid>
    </Box>
  ) : null;
});

export default TeamOfficeSummary;
