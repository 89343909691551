export enum LeaveStatus {
  PLANNED = 0,
  APPROVED = 1,
}

export enum LeaveType {
  MATERNITY = 0,
  SICK = 1,
  ANNUAL = 2,
  CARERS = 3,
  VOLUNTEER = 4,
  JURY = 5,
}

export enum LeaveTypeString {
  MATERNITY = "Maternity/Paternity",
  SICK = "Sick",
  ANNUAL = "Annual",
  CARERS = "Carers",
  VOLUNTEER = "Volunteer Day",
  JURY = "Jury Duty",
}

export interface LeaveCreation {
  id: number;
  from_date: string;
  to_date: string;
  leave_status: number;
  leave_type: number;
}

export interface Leave {
  id: number;
  from_date: string;
  to_date?: string;
  leave_status: number;
  leave_type: number;
  user_id: number;
}

export interface TeamLeaves {
  title: string;
  start: string;
  end: string;
}
