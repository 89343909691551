import { MultipleBooking } from "interfaces/Schedule";
import moment from "moment";

export const getEarliestBooking = (upcomingBookings: MultipleBooking[], date: string) => {
  if (moment(date).isSame(new Date(), "day")) {
    return (
      upcomingBookings
        .filter((booking) =>
          moment(new Date(), "hh:mm:ss").isBefore(
            moment(booking.booking_details!.end_time_local, "hh:mm:ss")
          )
        )
        .slice()
        .sort((a, b) => {
          return a.booking_details!.start_time_local < b.booking_details!.start_time_local ? -1 : 1;
        })[0] ||
      upcomingBookings.slice().sort((a, b) => {
        return a.booking_details!.start_time_local < b.booking_details!.start_time_local ? -1 : 1;
      })[0]
    );
  } else {
    return upcomingBookings.slice().sort((a, b) => {
      return a.booking_details!.start_time_local < b.booking_details!.start_time_local ? -1 : 1;
    })[0];
  }
};
