import { Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { t } from "i18next";
import { BuildingWithCapacity } from "interfaces/Building";
import { hasBookableSpaceCapacity, hasBookableSpace } from "utils/locations";

// Duplicated getExceededCapacityMessage function to include date parameter for Bookable Space capacity check
export const getExceededSpaceCapacityMessage = (
  building: BuildingWithCapacity | null,
  date: string
) => {
  return !hasBookableSpaceCapacity(building, date) ? (
    <Box padding={0}>
      <Alert style={{ padding: 10, maxWidth: "600px" }} severity="error">
        <AlertTitle>
          <strong>{t("No desks available")}</strong>
        </AlertTitle>
        {t("This location is very busy, and may reach its assigned capacity")}
      </Alert>
    </Box>
  ) : (
    <></>
  );
};

export const getExceededCapacityMessage = (building: BuildingWithCapacity | null) => {
  return !hasBookableSpace(building) ? (
    <Box padding={0}>
      <Alert style={{ padding: 10, maxWidth: "600px" }} severity="error">
        <AlertTitle>
          <strong>{t("No desks available")}</strong>
        </AlertTitle>
        {t("This location is very busy, and may reach its assigned capacity")}
      </Alert>
    </Box>
  ) : (
    <></>
  );
};
