import { observer } from "mobx-react-lite";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import { bhpColor, settings, displaySettings } from "styles/globals";
import { useEffect, useState, Fragment, useCallback } from "react";
import { capitalizeString } from "utils/string";
import { toJS } from "mobx";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Card,
  Grid,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  useMediaQuery,
  TextField,
} from "@material-ui/core";
import { User } from "interfaces/User";
import { BuildingWithCapacity } from "interfaces/Building";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridDensityTypes,
  GridOverlay,
  GridEditRowsModel,
} from "@material-ui/data-grid";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import moment from "moment";
import { useBAMStore, useBuildingStore, useUserStore } from "providers/RootStoreProvider";
import {
  BuildingAccessRequest_Approval_Items,
  RequestStatusChoices,
  CardType,
} from "interfaces/Visitor";
import BAMStore from "stores/BAMStore";

interface PendingTabProps {
  t: any;
  status: "all" | "open" | "closed";
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      //border: `1px solid ${bhpColor.blueGrey4}`,
      "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
        outline: "none",
      },
      border: 0,
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
        fontSize: "12px",
      },
      "& .MuiDataGrid-row": {
        "& .fullRowStyles": {
          maxHeight: "100px",
        },
        maxHeight: "unset",
        "&:nth-child(odd)": {
          backgroundColor: bhpColor.backgroundGrey,
        },
        "& .MuiDataGrid-cell": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          "&:nth-child(odd)": {
            backgroundColor: bhpColor.backgroundGrey,
          },
        },
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
    },
    saveSuccess: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.orange1,
        fontWeight: "bold",
        backgroundColor: bhpColor.orange4,
        border: `2px solid ${bhpColor.orange1}`,
        borderRadius: settings.borderRadius,
      },
    },
  })
);

const tableStyles = makeStyles({
  tableRow: {
    height: 40,
    "&:nth-child(odd)": {
      backgroundColor: bhpColor.backgroundGrey,
    },
  },
  tableCell: {
    padding: "0px 16px",
  },
  buildingSelector: {
    padding: 0,
    marginRight: "8px",
    "& .MuiSelect-root": {
      padding: "4px",
      width: "80px",
      fontSize: "14px",
    },
    "& .MuiSelect-iconOutlined": {
      right: 0,
    },
  },
});

export enum RequsetStatusType {
  Pending = 1,
  Approved = 2,
  Regjected = 3,
}

const PendingTab = observer((props: PendingTabProps) => {
  const { t, status } = props;
  const classes = useStyles();
  const tableClasses = tableStyles();
  const history = useHistory();
  const bamStore = useBAMStore();
  const userStore = useUserStore();
  const buildingStore = useBuildingStore();

  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const [isOpen, setIsOpen] = useState<boolean>(false);

  function CustomNoRowsOverlay() {
    return <GridOverlay>Nothing to show...</GridOverlay>;
  }

  const [requests, setRequests] = useState<BuildingAccessRequest_Approval_Items[]>(
    bamStore.getRequests(status == "open" ? [0] : [1, 2, 3, 4, 5, 6, 7, 8])
  );

  const [editRowsModel, setEditRowsModel] = useState({});
  const [editRowData, setEditRowData] = useState({});

  const handleEditRowsModelChange = useCallback(
    (model: GridEditRowsModel) => {
      const editedIds = Object.keys(model);
      if (editedIds.length === 0) {
        // alert(JSON.stringify(editRowData, null, 4));
      } else {
        setEditRowData(model[editedIds[0]]);
      }
      setEditRowsModel(model);
    },
    [editRowData]
  );

  const comment = Object.keys(editRowData).map((keys) => editRowData[keys].value);

  const setStatus = (request: any, newStatus: RequestStatusChoices) => {
    const request_id = request.building_request_id;
    console.log("fixing status: ", request_id, newStatus, toJS(request));
    const approver_comment = comment[0];
    bamStore
      .changeStatus(
        request.requested_area_list_id,
        newStatus,
        request_id,
        request.access_area_id,
        userStore.me!.id,
        approver_comment
      )
      .then((res) => {
        console.log("resp: ", toJS(res));
        const successText: string = "Request updated successfully";
        const failText: string = "Not updated !!!";
        if (res.status == 200 || res.status == 201) {
          setMessage(successText);
          setIsOpen(true);
        } else {
          const responseMessage = res.data.response.data
            ? JSON.stringify(res.data.response.data)
            : failText;
          setMessage(responseMessage);
          setIsOpen(true);
        }
        //bamStore.loadAllRequests(userStore.me!.id);
        bamStore.loadMyRequests(userStore.me!.id, RequestStatusChoices.SUBMITTED).then((req) => {
          setRequests(req ? req.slice() : []);
          bamStore.loadAllRequests(userStore.me!.id);
        });
      });
  };

  useEffect(() => {
    bamStore.loadAllRequests(userStore.me!.id);
    if (bamStore.building_requests_grouped_status) {
      setRequests(bamStore.getRequests(status == "open" ? [0] : [1, 2, 3, 4, 5, 6, 7, 8]));
    }
  }, []);

  const getAccessAreaName = (area_id: number | number[]) => {
    if (!area_id) return "";
    const theAreaId = typeof area_id == "number" ? area_id : area_id[0];
    return bamStore.special_access_areas.find((area) => area.id == theAreaId)?.name || "";
  };

  const columns: GridColDef[] = [
    {
      field: "status_id",
      headerName: t("Action"),
      sortable: false,
      disableColumnMenu: true,
      width: 70,
      minWidth: 70,
      renderHeader: (params: GridColumnHeaderParams) => {
        return status == "closed" ? "Status" : "Action";
      },
      renderCell: (params: GridCellParams) => {
        return status == "closed" ? (
          <Tooltip title={RequestStatusChoices[params.row.status_id]}>
            <Box>{RequestStatusChoices[params.row.status_id]}</Box>
          </Tooltip>
        ) : (
          <Grid container style={{ width: "100%", display: "flex" }}>
            <Grid
              item
              xs={6}
              style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              onClick={() => setStatus(params.row, RequestStatusChoices.APPROVED)}
            >
              <Tooltip title="Click to approve the request">
                <CheckCircleIcon htmlColor="#39BA2E" />
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              onClick={() => setStatus(params.row, RequestStatusChoices.DENIED)}
            >
              <Tooltip title="Click to deny the request">
                <CancelIcon htmlColor="#ED4C4C" />
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "building_request_id",
      headerName: t("ID"),
      sortable: true,
      disableColumnMenu: true,
      width: 70,
      minWidth: 70,
      renderCell: (params: GridCellParams) => {
        return params.value;
      },
    },
    {
      field: "created_at",
      headerName: t("Created at"),
      sortable: true,
      width: 120,
      minWidth: 120,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return params.row.created_at ? moment(params.row.created_at).format("YYYY-DD-MM") : "--";
      },
    },
    {
      field: "start_date_time",
      headerName: t("Start Date"),
      sortable: true,
      disableColumnMenu: true,
      width: 120,
      minWidth: 120,
      renderCell: (params: GridCellParams) => {
        return params.row.start_date_time
          ? moment(params.row.start_date_time).format("YYYY-DD-MM")
          : "--";
      },
    },
    {
      field: "end_date_time",
      headerName: t("End Date"),
      sortable: true,
      disableColumnMenu: true,
      width: 120,
      minWidth: 120,
      renderCell: (params: GridCellParams) => {
        return params.row.end_date_time ? getDisplayTime(params.row.end_date_time) : "--";
      },
    },
    {
      field: "requester_id",
      headerName: t("Requestor"),
      sortable: true,
      disableColumnMenu: true,
      width: 150,
      minWidth: 150,
      renderCell: (params: GridCellParams) => {
        return `${params.row.requester_first_name} ${params.row.requester_last_name}`;
      },
    },
    {
      field: "card_holder_id",
      headerName: t("Card Holder"),
      sortable: true,
      disableColumnMenu: true,
      width: 150,
      minWidth: 150,
      renderCell: (params: GridCellParams) => {
        return `${params.row.card_holder_first_name} ${params.row.card_holder_last_name}`;
      },
    },
    {
      field: "supervisor_id",
      headerName: t("Supervisor"),
      sortable: true,
      disableColumnMenu: true,
      width: 150,
      minWidth: 150,
      renderCell: (params: GridCellParams) => {
        if (params.row.supervisor_id == null)
          return `${params.row.host_name_first_name} ${params.row.host_name_last_name}`;
        else return `${params.row.supervisor_first_name} ${params.row.supervisor_last_name}`;
      },
    },
    {
      field: "card_type",
      headerName: t("Card Type"),
      sortable: true,
      disableColumnMenu: true,
      width: 200,
      minWidth: 200,
      renderCell: (params: GridCellParams) => {
        return (
          <Tooltip title={CardType[params.row.card_type]}>
            <Box>{CardType[params.row.card_type]}</Box>
          </Tooltip>
        );
      },
    },
    {
      field: "access_area_name",
      headerName: t("Area"),
      sortable: true,
      disableColumnMenu: true,
      width: 200,
      minWidth: 200,
      renderCell: (params: GridCellParams) => {
        const access_area_name = getAccessAreaName(params.row.access_area_id) || "";
        return (
          <Tooltip title={access_area_name}>
            <Box>{access_area_name}</Box>
          </Tooltip>
        );
      },
    },
    {
      field: "location",
      headerName: t("Location"),
      sortable: true,
      disableColumnMenu: true,
      width: 150,
      minWidth: 150,
      renderCell: (params: GridCellParams) => {
        const thisBuilding = buildingStore.getBuilding(Number(params.row.location_id));
        return (
          <Tooltip title={`${thisBuilding?.display_name}`}>
            <Box>
              {(params.row.location_id && thisBuilding?.building_name) || params.row.location_id}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "access_reason",
      headerName: t("Access Reason"),
      sortable: true,
      disableColumnMenu: true,
      width: 200,
      minWidth: 200,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.access_reason || ""}>
          <Box>
            {params.row.access_reason && params.row.access_reason !== ""
              ? params.row.access_reason
              : params.row.reason_for_visit}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "comment",
      headerName: t("Comment"),
      sortable: false,
      disableColumnMenu: true,
      editable: true,
      width: 100,
      minWidth: 100,
      renderCell: (params: GridCellParams) => {
        return (
          <Grid container style={{ width: "100%", display: "flex" }}>
            <Grid item xs={6} style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
              <Tooltip title="Comment">
                <Box>{params.row.comment}</Box>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const getDisplayTime = (dt: Date, format: string = "YYYY-DD-MM") => {
    if (!dt) return "--";
    return new Date(dt).getTime() < new Date("2035-01-01").getTime()
      ? moment(dt).format(format)
      : "";
  };

  return (
    <Fragment>
      <Box>
        {mdAndUp ? (
          <DataGrid
            style={{ color: bhpColor.blueGrey1, height: `${mdAndUp ? "700px" : "900px"}` }}
            className={classes.table}
            columns={columns}
            rows={requests || []}
            editRowsModel={editRowsModel}
            onEditRowsModelChange={handleEditRowsModelChange}
            rowCount={requests ? requests.length : 0}
            rowHeight={mdAndUp ? 67 : 125}
            pageSize={10}
            rowsPerPageOptions={[10]}
            density={mdAndUp ? GridDensityTypes.Compact : GridDensityTypes.Comfortable}
            disableColumnSelector={true}
            headerHeight={mdAndUp ? undefined : 0}
            hideFooterSelectedRowCount={true}
            scrollbarSize={0}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        ) : (
          <Table>
            <TableBody>
              {requests.map((row, idx) => {
                const thisBuilding = buildingStore.getBuilding(Number(row.location_id));
                const access_area_name = getAccessAreaName(row.access_area_id) || "";
                return (
                  <TableRow key={`tr${idx}`} className={tableClasses.tableRow}>
                    <TableCell style={{ paddingLeft: "4px", paddingRight: "4px", width: "60px" }}>
                      <Grid container style={{ height: "120px" }}>
                        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                          {status == "closed" ? (
                            <Tooltip title={RequestStatusChoices[row.status_id]}>
                              <Box>{RequestStatusChoices[row.status_id]}</Box>
                            </Tooltip>
                          ) : (
                            <Grid container style={{ width: "100%", display: "flex" }}>
                              <Grid
                                item
                                xs={6}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  paddingRight: "8px",
                                }}
                                onClick={() => setStatus(row, RequestStatusChoices.APPROVED)}
                              >
                                <Tooltip title="Click to approve the request">
                                  <CheckCircleIcon htmlColor="#39BA2E" />
                                </Tooltip>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                                onClick={() => setStatus(row, RequestStatusChoices.DENIED)}
                              >
                                <Tooltip title="Click to deny the request">
                                  <CancelIcon htmlColor="#ED4C4C" />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          ID: {row.building_request_id}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12}>
                          <div style={{ display: "inline-block", width: "150px" }}>
                            Card Holder:
                          </div>
                          <strong>{`${row.card_holder_first_name} ${row.card_holder_last_name}`}</strong>
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ display: "inline-block", width: "100px" }}>From:</div>
                          {row.start_date_time
                            ? moment(row.start_date_time).format("MMM Do h:mma")
                            : "--"}
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ display: "inline-block", width: "100px" }}>To:</div>
                          {row.end_date_time
                            ? getDisplayTime(row.end_date_time, "MMM Do h:mma")
                            : "--"}
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ display: "inline-block", width: "150px" }}>Requestor:</div>
                          <strong>{`${row.requester_first_name} ${row.requester_last_name}`}</strong>
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ display: "inline-block", width: "150px" }}>Supervisor:</div>
                          <strong>
                            {row.supervisor_id == null
                              ? `${row.host_name_first_name} ${row.host_name_last_name}`
                              : `${row.supervisor_first_name} ${row.supervisor_last_name}`}
                          </strong>
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ display: "inline-block", width: "150px" }}>Card Type:</div>{" "}
                          <strong>{CardType[row.card_type]}</strong>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex" }}>
                          <div style={{ display: "inline-block", width: "120px" }}>Area:</div>{" "}
                          <strong>{access_area_name}</strong>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex" }}>
                          <div style={{ display: "inline-block", width: "150px" }}>Location:</div>
                          <strong>
                            {(row.location_id && thisBuilding?.building_name) || row.location_id}
                          </strong>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex" }}>
                          <div style={{ display: "inline-block", width: "120px" }}>Comment:</div>{" "}
                          <strong>{comment}</strong>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={classes.saveSuccess}
        autoHideDuration={displaySettings.snackDelay}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          if (isApiError) {
            setIsApiError(false);
          }
        }}
        message={message}
        key={"top" + "right"}
      />
    </Fragment>
  );
});

export default PendingTab;
