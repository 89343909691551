import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
  Select,
  Button,
  FormControl,
  Snackbar,
  Checkbox,
  Link,
  Modal,
  Avatar,
  Tooltip,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { observer } from "mobx-react-lite";
import HeadsetMicOutlinedIcon from "@material-ui/icons/HeadsetMicOutlined";
import { GridCellParams, GridColDef, GridOverlay } from "@material-ui/data-grid";
import { useRootStore, useTeamStore } from "providers/RootStoreProvider";
import { bhpColor, settings } from "styles/globals";
import { Fragment, useEffect, useState, useMemo } from "react";
import { PopupInformationWidget } from "components/settings/PopupInformationWidget";
import InformationIcon from "components/utils/InformationIcon";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { AnchorDays, IloOccurance, IloPlace } from "interfaces/Teams";
import { capitalizeString } from "utils/string";
import moment from "moment";
import { useHistory } from "react-router";
import { ConfirmDialog } from "components/utils/ConfirmDialog";
import config from "config";
import { TeamParticipant, TeamRoleType, Activity } from "interfaces/Teams";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { toJS } from "mobx";
import TeamIloAddActivity from "./TeamIloAddActivity";
import CustomWeekView from "components/utils/RBC_Calendar_2Week_View";
import Icon from "@mdi/react";
import { NetworkCheckSharp } from "@material-ui/icons";
import { TeamIloAddActivityConfirmDialog } from "./TeamIloAddActivityConfirmDialog";
import { mdiConsoleNetwork } from "@mdi/js";
import { getIconPath } from "utils/icons";
import { ScheduleType } from "interfaces/Schedule";
import { DEFAULT_END_TIME } from "utils/hourlyBookings";

interface TeamIloRoutineViewerProps {
  teamId: number;
  t: any;
  isSmall: boolean;
  didClick: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
    },
    dialogContainer: {},
    mainTable: {
      "& td": {
        // borderBottom: 0,
      },
    },
    textField: {
      width: "calc(100% - 150px)",
      borderRadius: settings.borderRadius,
      backgroundColor: "white",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      "& .MuiInput-root": {
        paddingLeft: theme.spacing(1),
        fontSize: "14px",
      },
      "& .MuiInput-underline:before": {
        borderBottom: `0 !important`,
        transition: "none",
      },
      "& .Mui-focussed": {
        borderBottom: `0 !important`,
      },
      "& .MuiInputBase-inputMultiline": {
        fontSize: 12,
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        fontSize: 12,
      },
    },
    avatar: {
      backgroundColor: "rgb(250, 182, 54)",
      color: "white",
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginLeft: "16px",
      fontSize: "16px",
    },
    h4: {
      fontWeight: "bold",
      color: bhpColor.blueGrey1,
      fontSize: 14,
      display: "inline-block",
    },
    wrapper: {
      marginTop: theme.spacing(4),
    },

    formControl: {
      display: "block",
      width: "100%",
      margin: 0,
    },
    selectBoxOcc: {
      width: "calc(100% - 150px)",
      fontSize: 13,
      height: 40,
      "& svg": {
        zIndex: 2,
      },
      "& select": {
        zIndex: 2,
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& fieldset": {
        zIndex: 1,
        height: 40,
        top: -3,
        backgroundColor: "white",
        "& .Mui-focussed": {
          backgroundColor: "white",
        },
      },
    },
    spaceTable: {
      border: 0,
      "& .MuiTablePagination-root": {
        color: bhpColor.blueGrey1,
      },
      "& .MuiDataGrid-renderingZone": {
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-viewport": {
        maxHeight: "none",
      },
      "& .MuiDataGrid-root": {
        border: 0,
        fontSize: 12,
      },
      "& .MuiDataGrid-cell": {
        border: 0,
        "&:focus": {
          outline: 0,
        },
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-columnHeader": {
        "&:focus": {
          outline: 0,
        },
        "&:focus-within": {
          outline: 0,
        },
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        paddingLeft: 0,
      },
      "& .MuiDataGrid-columnsContainer": {
        borderBottom: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 90,
        color: bhpColor.blueGrey1,
      },
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
      "& .fc-toolbar ": {
        display: "none",
      },
      "& .MuiDataGrid-row": {
        maxHeight: "none !important",
        "&:nth-child(odd)": {
          backgroundColor: bhpColor.backgroundGrey,
          "&:hover": {
            backgroundColor: bhpColor.backgroundGrey,
          },
        },
        "&.Mui-selected": {
          "&:hover": {
            backgroundColor: bhpColor.blueGrey2,
            color: bhpColor.blueGrey1,
          },
          backgroundColor: bhpColor.blueGrey1,
          color: bhpColor.white,
        },
        "&:hover": {
          backgroundColor: bhpColor.backgroundGrey,
        },
      },
    },
    outer: {
      borderRadius: settings.borderRadius,
      border: `1px solid ${bhpColor.blueGrey4}`,
      color: bhpColor.blueGrey1,
    },
    dataGrid: {
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiDataGrid-row.Mui-even": {
        backgroundColor: "#F6F6F6",
      },
      "& .MuiDataGrid-cell": {
        color: "#466371",
        "&:focus-within": {
          outlineWidth: 0,
        },
      },
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-sortIcon": {
          opacity: `1 !important`,
        },
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        color: "#50524D",
        fontWeight: "bold !important",
      },
      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden",
      },
      padding: "0 0% 0% 0%",
    },
    selectBox: {
      width: "calc(100% - 220px)",
      fontSize: 14,
      height: 40,
      "& svg": {
        zIndex: 2,
      },
      "& select": {
        zIndex: 2,
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& fieldset": {
        zIndex: 1,
        height: 40,
        top: -3,
        backgroundColor: "white",
        "& .Mui-focussed": {
          backgroundColor: "white",
        },
      },
    },
    inlineLink: {
      display: "flex",
      alignItems: "center",
      fontWeight: "bold",
      cursor: "pointer",
    },
    allDayRow: {
      "& .rbc-label": {
        fontSize: "12px",
      },
      "& .rbc-allday-cell ": {
        display: "none",
      },
      "& .rbc-btn-group": {
        display: "none",
      },
      "& .rbc-event": {
        borderRadius: "2px",
      },
      "& .rbc-event-label": {
        display: "none",
      },
      "& .rbc-timeslot-group": {
        minHeight: "24px",
        border: 0,
      },
      "& .rbc-event-content": {
        fontSize: "12px",
        display: "flex",
        alignItems: "end",
      },
      "& .rbc-time-content": {
        border: 0,
      },
      "& .rbc-time-content > * + * > *": {
        border: 0,
      },
      "& .rbc-time-header-content": {
        border: 0,
      },
      "& .rbc-header": {
        paddingTop: 5,
        paddingBottom: 8,
        borderBottom: 0,
        borderLeft: 0,
      },
      "& .rbc-toolbar > .rbc-toolbar-label": {
        display: "none",
      },
      "&.big > .rbc-toolbar > .rbc-toolbar-label": {
        display: "flex",
      },
    },
    summaryTeamAvatars: {
      marginTop: 0,
      marginRight: theme.spacing(0.5),
      "& .MuiAvatar-colorDefault": {
        color: bhpColor.white,
        backgroundColor: bhpColor.blueGrey1,
        fontSize: 14,
      },
      "& .MuiAvatarGroup-root": {
        justifyContent: "flex-end",
      },
    },
    summaryTeamWithMoreAvatars: {
      "& .MuiAvatar-colorDefault:last-child": {
        color: bhpColor.white,
        backgroundColor: bhpColor.orange1,
        fontSize: 14,
      },
    },
  })
);

interface DialogData {
  title: string | null;
  content: string | null;
  primaryButtonText: string | null;
  secondaryButtonText?: string | null;
  handleClose?: any;
}

const TeamIloTab = observer((props: TeamIloRoutineViewerProps) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    border: "1px solid rgb(230 84 0) !important",
    // boxShadow: 24,
    pt: 2,
    px: 2,
    pb: 2,
  };
  const { teamId, t, isSmall, didClick } = props;
  const store = useRootStore();
  const classes = innerStyles();
  const maxAvatarCount = 5;
  const teamStore = useTeamStore();
  const [informationModal, setInformationModal] = useState<DialogData | null>();
  const [informationOpen, setInformationOpen] = useState(false);
  const handleInformationClose = () => setInformationOpen(false);
  const theme = useTheme();
  const history = useHistory();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const lgAndUp = useMediaQuery(theme.breakpoints.up("lg"));

  const [canEdit, setCanEdit] = useState(false);

  const setMembers = (data: TeamParticipant[]) => {
    if (data) {
      const theMembers = data.map((participant: TeamParticipant, idx: number) => ({
        ...participant,
        id: idx,
      }));
      setTeamMembers(theMembers);
    }
  };
  const [teamMembers, setTeamMembers] = useState<TeamParticipant[]>([]);
  const [participants, setParticipants] = useState<TeamParticipant[]>([]);

  const getInitials = (user: TeamParticipant) => {
    const nameToCheck: string = user.preferred_name
      ? user.preferred_name
      : `${user.first_name} ${user.last_name}`;
    return nameToCheck.length > 2
      ? nameToCheck.indexOf(" ") > -1
        ? `${nameToCheck.charAt(0)}${nameToCheck.charAt(nameToCheck.indexOf(" ") + 1)} `
        : nameToCheck.substring(0, 2)
      : "..";
  };

  const subHeadAndInfo = (label: string, infoId: number) => {
    return (
      <Box mb={1} mt={3} style={{ display: "flex" }}>
        <Typography className={classes.h4}>{t(label)}</Typography>
        <InformationIcon onclick={() => handleInformationOpen(infoId)} color={bhpColor.orange1} />
      </Box>
    );
  };

  const [show, setShow] = useState(false);
  const [showOffice, setOffice] = useState(false);
  const textAreaRows = 2;
  const textAreaMaxCharacters = 250;
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const gridRowHeight = mdAndUp ? 52 : 100;
  const gridPageSize = 20;
  const [selectedRole, setSelectedRole] = useState<TeamRoleType | -1>(-1);
  const [snackOpen, setSnackOpen] = useState(false);
  const [saveState, setSaveState] = useState(true);
  const [snackMessage, setSnackMessage] = useState(t("ILO Activty Created Successfully"));
  const snackbarOpen = () => setSnackOpen(true);
  const snackbarClose = () => setSnackOpen(false);
  const setSnackBox = (success: boolean, message?: string) => {
    setSnackMessage(message);
    setSaveState(success);
  };
  const [activityList, setActivityList] = useState<Activity[]>([]);
  const localizer = momentLocalizer(moment);
  const [myEvents, setEvents] = useState([]);
  const [activityTitle, setActivityTitle] = useState("");
  const [activityId, setActivityId] = useState(0);

  const CustomNoRowsOverlay = () => {
    return <GridOverlay className={classes.root}>{t("No records found.")}</GridOverlay>;
  };

  const [days, setDays] = useState([
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
    "monday_week2",
    "tuesday_week2",
    "wednesday_week2",
    "thursday_week2",
    "friday_week2",
    "saturday_week2",
    "sunday_week2",
  ]);

  const loadActivities = (teamId: number) => {
    (async () => {
      const activity_list = await teamStore.loadactivityList(teamId);
      setActivityList(activity_list);
      const newActivityList: any = [];
      days.map((day, idx) => {
        const dayActivity = activity_list.filter((activity: any) => activity.days[day] === true);
        dayActivity.map((activtity, id) => {
          let newactivity = { ...activtity };
          newactivity["title"] = newactivity.activity_name;
          let start_time =
            moment()
              .day(idx + 1)
              .format("YYYY-MM-DD") +
            " " +
            newactivity.start_time_local;
          let end_time =
            moment()
              .day(idx + 1)
              .format("YYYY-MM-DD") +
            " " +
            newactivity.end_time_local;
          let startTime = moment(start_time).toISOString();
          let endTime = moment(end_time).toISOString();
          newactivity["start_time"] = new Date(startTime);
          newactivity["end_time"] = new Date(endTime);
          newActivityList.push(newactivity);
        });
      });
      setEvents(newActivityList);
    })();
  };

  useEffect(() => {
    let hasWeek2Activities = false;
    let allDay = false;
    myEvents.forEach((evt: Activity) => {
      if (evt.days) {
        const hasWeek2 = evt.days["twoWeekRoutine"];
        if (hasWeek2) hasWeek2Activities = true;
      }
      const isBigDay =
        Number(evt.start_time_local.split(":")[0]) < 7 ||
        Number(evt.end_time_local.split(":")[0]) > 19;
      if (isBigDay) allDay = true;
    });
    setRoutineLength(hasWeek2Activities ? "double" : "week");
    if (allDay) {
      setminTime(new Date(today.setHours(0, 0, 0)));
      setmaxTime(new Date(today.setHours(23, 59, 0)));
    } else {
      setminTime(new Date(today.setHours(7, 0, 0)));
      setmaxTime(new Date(today.setHours(20, 0, 0)));
    }
  }, [myEvents]);

  useEffect(() => {
    loadActivities(teamId);
  }, [teamId]);

  useEffect(() => {
    getTeamMembers();
  }, []);

  const [routineLength, setRoutineLength] = useState("week");
  const handleWeekChange = (event) => {
    switch (event.target.value) {
      case "2":
        setRoutineLength("double");
        break;
      case "1":
        setRoutineLength("week");
        break;
    }
  };

  const today = new Date();
  const [minTime, setminTime] = useState(new Date(today.setHours(7, 0, 0)));
  const [maxTime, setmaxTime] = useState(new Date(today.setHours(20, 0, 0)));

  const handleHourChange = (event) => {
    if (event.target.value == 12) {
      setminTime(new Date(today.setHours(7, 0, 0)));
      setmaxTime(new Date(today.setHours(20, 0, 0)));
    }
    if (event.target.value == 24) {
      setminTime(new Date(today.setHours(0, 0, 0)));
      setmaxTime(new Date(today.setHours(23, 59, 0)));
    }
  };

  moment.locale("ko", {
    week: {
      dow: 1,
      doy: 1,
    },
  });

  const [formValid, setFormValid] = useState<boolean>(false);

  const [currentActivity, setCurrentActivity] = useState<Activity | null>(null);

  const handleInformationOpen = (infoId: number) => {
    openInformationModal(getModalProps(t, infoId));
  };

  const openInformationModal = (informationData: DialogData) => {
    setInformationModal(Object.assign({}, informationData));
    setInformationOpen(true);
  };

  const [activityDays, setActivityDays] = useState<AnchorDays>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const saveActivity = async () => {
    console.log("~~~ ACT ~~~");
    console.log(currentActivity);
    if (
      currentActivity?.activity_name == "" ||
      currentActivity?.start_time_local == "" ||
      currentActivity?.end_time_local == "" ||
      currentActivity?.purpose == "" ||
      currentActivity?.preparation == ""
    ) {
      alert("Please provide required details");
    } else {
      if (currentActivity) {
        let res = "";
        res =
          currentActivity.id !== 0
            ? await teamStore.updateIloActivity(currentActivity)
            : await teamStore.createIloActivity(currentActivity);
        if (res) {
          loadActivities(teamId);
          setShow(false);
          setSnackBox(true);
          snackbarOpen();
        }
      }
    }
  };
  const cancelActivity = () => {
    setShow(false);
  };

  const [deleteActivityDialog, setDeleteActivityDialog] = useState(false);
  const openDelete = () => {
    setDeleteActivityDialog(true);
  };
  const closeDelete = () => {
    setDeleteActivityDialog(false);
  };

  const deleteActivity = async () => {
    if (currentActivity?.id) {
      const delRes = await teamStore.deleteIloActivity(teamId, currentActivity?.id);
      loadActivities(teamId);
    }
    closeDelete();
    setShow(false);
  };

  const getModalProps = (t: any, infoId: number) => {
    let title = "";
    let content = "";
    let primaryButtonText = t("Okay");
    let secondaryButtonText = "";
    let handleClose: any = handleInformationClose;

    switch (infoId) {
      case 1:
        title = t("ILO Routine");
        content = t("ILO routines based on week and time");
        break;
      case 2:
        title = t("Activity Name*");
        content = t("Name of the ILO activity");
        break;
      case 3:
        title = t("Occurrence");
        content = t("Occurrence details of the ILO activity");

        break;
      case 4:
        title = t("Day");
        content = t("Days of occurrence of this activity");
        break;
      case 5:
        title = t("Start Time*");
        content = t("Starting time of this ILO activity");

        break;
      case 6:
        title = t("End Time*");
        content = t("Ending time of this ILO activity");
        break;
      case 7:
        title = t("BOS Principal");
        content = t("List BOS principal for this activity");
        break;
      case 8:
        title = t("Purpose*");
        content = t("Purpose of this ILO activity");
        break;
      case 9:
        title = t("Participants");
        content = t("Participants of this activity");
        break;
      case 10:
        title = t("Preparation*");
        content = t("Details of any preparation attendees should undertake");
        break;
      case 11:
        title = t("Place");
        content = t("Place in which ILo activity is happening");
        break;
      case 12:
        title = t("Links");
        content = t("URL's related to the ILO activity");
        break;
    }
    return { title, content, primaryButtonText, secondaryButtonText, handleClose };
  };

  const jumpToAddActivity = async (activityId: number | null | undefined, label: string) => {
    const activityDetails =
      !activityId || activityId === 0 ? null : await teamStore.loadActivity(teamId, activityId);
    getTeamMembers();
    switch (label) {
      case "duplicate":
        setCurrentActivity(
          { ...activityDetails, id: 0, activity_name: `${activityDetails.activity_name}-copy` } ||
            null
        );
        break;
      case "edit":
      case "view":
        setCurrentActivity({ ...activityDetails, id: activityId });
        break;
      case "create":
        setCurrentActivity(null);
        break;
    }
    setParticipants(activityDetails ? activityDetails.participants : []);
    setShow(true);
    setOpen(false);
  };

  const getTeamMembers = async () => {
    const team_members = await teamStore.loadTeamMembers(teamId);
    setMembers(team_members);
  };

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 12),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );

  const [open, setOpen] = useState(false);
  const handleSelectEvent = async (event: Activity, target) => {
    if (event.id) {
      const activityDetails = await teamStore.loadActivity(teamId, event.id);
      setCurrentActivity(activityDetails);
      console.log("click 1", isSmall);
      if (!isSmall) {
        setOpen(true);
      } else {
        if (didClick) {
          console.log("did click");
          didClick(event);
        }
      }
    }
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const getOccurenceText = (act: Activity | null) => {
    if (!act || !act.occurance) return "";
    let preText = "";
    switch (act.occurance) {
      case IloOccurance.DAILY:
        preText = "Occurs daily";
        break;
      case IloOccurance.WEEKLY:
        preText = "Occurs every week";
        break;
      case IloOccurance.EVERY_TWO_WEEKS:
        preText = "Occurs every two weeks";
        break;
    }
    const theDays = act.days
      ? `[${Object.keys(act.days)
          .filter((dy) => act.days && act.days[dy] === true)
          .map((day) => capitalizeString(day.substring(0, 2)))}]`
      : "";
    return `${preText} ${theDays}`;
  };

  const { views, ...otherProps } = useMemo(
    () => ({
      views: {
        double: CustomWeekView,
        week: true,
      },
    }),
    []
  );

  const dayPropGetter = (day) => {
    const dow = moment(day).weekday();
    const backgroundColor = [5, 6].includes(dow) ? bhpColor.backgroundGrey : "";
    return { style: { backgroundColor } };
  };

  const eventPropGetter = (event) => {
    const backgroundColor =
      event.bos_practice === 1
        ? "#FCBA5E"
        : event.bos_practice === 2
        ? "#ABCC66"
        : event.bos_practice === 3
        ? "#E65400"
        : event.bos_practice === 4
        ? "#466371"
        : "";
    return { style: { backgroundColor } };
  };

  const getDisplayTime = (act: Activity | null) => {
    if (!act) return;
    const sElements = act.start_time_local.split(":");
    const eElements = act.end_time_local.split(":");
    const sHour = Number(sElements[0]);
    const sD = sHour < 12 ? "am" : "pm";
    const sH = sHour < 12 ? sHour : sHour - 12;
    const endstartTime = `${sH}:${sElements[1]}${sD}`;
    const eHour = Number(eElements[0]);
    const eD = eHour < 12 ? "am" : "pm";
    const eH = eHour < 12 ? eHour : eHour - 12;
    const startTime = `${sH}:${sElements[1]}${sD}`;
    const endTime = `${eH}:${eElements[1]}${eD}`;
    return `${startTime} - ${endTime}`;
  };

  return (
    <>
      <Calendar
        tooltipAccessor={null}
        events={myEvents}
        localizer={localizer}
        onSelectEvent={handleSelectEvent}
        selectable
        views={views}
        view={routineLength}
        formats="HH:mm"
        startAccessor="start_time"
        endAccessor="end_time"
        className={`${classes.allDayRow} ${routineLength === "double" ? "big" : ""}`}
        min={minTime}
        max={maxTime}
        components={{
          week: {
            header: ({ date, localizer }) => localizer.format(date, "dddd"),
          },
          double: {
            header: ({ date, localizer }) => localizer.format(date, "ddd"),
          },
        }}
        dayPropGetter={(day) => {
          return dayPropGetter(day);
        }}
        eventPropGetter={(event) => {
          return eventPropGetter(event);
        }}
      />
      {/* open */}
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 350 }} style={{ backgroundColor: bhpColor.backgroundGrey }}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6} style={{ display: "flex", alignItems: "start" }}>
                <Typography variant="h4">{currentActivity?.activity_name}</Typography>
              </Grid>
              <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
                {currentActivity?.place === IloPlace.BHP_LOCATION ? (
                  <Icon
                    path={getIconPath(ScheduleType.OFFICE)}
                    style={{ height: "22px", color: bhpColor.blueGrey1 }}
                  />
                ) : (
                  <Icon
                    path={getIconPath(ScheduleType.REMOTE)}
                    style={{ height: "22px", color: bhpColor.blueGrey1 }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ color: "#5C879C", marginTop: "6px" }}>
            {currentActivity?.purpose}
          </Grid>
          <Grid item xs={12} style={{ marginTop: "6px" }}>
            {currentActivity?.preparation || "No preparation required"};{" "}
            {getOccurenceText(currentActivity)}
          </Grid>
          <Grid item xs={12} style={{ marginTop: "6px" }}>
            {getDisplayTime(currentActivity)}
          </Grid>
          <Grid container style={{ display: "flex", alignItems: "end" }}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <div
                className={
                  classes.summaryTeamAvatars +
                  " " +
                  `${
                    teamMembers &&
                    teamMembers?.length >= maxAvatarCount &&
                    classes.summaryTeamWithMoreAvatars
                  }`
                }
              >
                <AvatarGroup max={maxAvatarCount}>
                  {currentActivity &&
                    currentActivity.participants &&
                    currentActivity.participants.map((user: any, index: number) => {
                      if (index < 5) {
                        return (
                          <Tooltip title={user.preferred_name} key={index}>
                            <Avatar key={`a${index}`} src={user.picture}>
                              {getInitials(user)}
                            </Avatar>
                          </Tooltip>
                        );
                      }
                    })}
                </AvatarGroup>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
});

export default TeamIloTab;
