import { makeStyles, createStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import config from "config";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useUserStore, useLeaveStore } from "providers/RootStoreProvider";
import { useEffect, useState } from "react";
import { bhpColor } from "styles/globals";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { toJS } from "mobx";
interface TeamLeaveTabProps {
  teamId?: number;
  t: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendarRow: {
      borderRight: `1px solid ${bhpColor.lightGrey}`,

      border: `1px solid ${bhpColor.lightGrey}`,
      "& > *:first-child": {
        // magic number for the month to fit the first column
        minWidth: 110,
        borderRight: `1px solid ${bhpColor.lightGrey}`,
      },
      "&:first-child": {
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
      },
      "&:last-child": {
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
      },
    },

    dayHeader: {
      fontWeight: 700,
      fontSize: 18,
      backgroundColor: bhpColor.backgroundGrey,
      borderRight: `1px solid ${bhpColor.lightGrey}`,

      "&:last-child, &:nth-last-child(2)": {
        // random color that is supposed to represent the blend of:
        // lightGrey + (blueGrey4 * 0.3 opacity)
        backgroundColor: bhpColor.blueGrey4,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        fontWeight: 400,
        backgroundColor: "white",
      },
    },

    dayBox: {
      borderRight: `1px solid ${bhpColor.lightGrey}`,

      "&:last-child, &:nth-last-child(2)": {
        backgroundColor: "#D7DFE24D",
      },
    },

    mobileRoot: {
      marginLeft: `-${theme.spacing(2)}px`,
      background: "white",
      width: `calc(100% + ${theme.spacing(4)}px)`,
    },
  })
);
const TeamLeaveTab = observer((props: TeamLeaveTabProps) => {
  const { teamId, t } = props;
  const theme = useTheme();
  const userStore = useUserStore();
  const leaveStore = useLeaveStore();
  const classes = useStyles();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const lgAndUp = useMediaQuery(theme.breakpoints.up("lg"));
  const [calendars, setCalendars] = useState<{ start: Date; end: Date }[]>([]);
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  moment.locale("ko", {
    week: {
      dow: 1,
      doy: 1,
    },
  });
  const localizer = momentLocalizer(moment);
  const addWeek = () => {
    let startDate;
    if (calendars.length === 0) {
      startDate = moment().startOf("week").weekday(1).toDate();
      //startDate = startOfWeek(new Date());
    } else {
      startDate = moment(calendars.slice(-1)[0].end).add(1, "day").toDate();
    }
    calendars.push({
      start: startDate,
      end: moment(startDate).endOf("week").weekday(7).toDate(),
      //end: endOfWeek(startDate),
    });
    setCalendars([...calendars]);
  };

  useEffect(() => {
    if (calendars.length === 0) {
      addWeek();
      addWeek();
    }
    if (!smAndUp) {
      // on small viewport I want to load all weeks
      let calendarLength = calendars.length;
      while (calendarLength < config.workday.numberOfWeeksSchedulable) {
        addWeek();
        calendarLength = calendars.length;
      }
    }
  }, [userStore.me]);

  useEffect(() => {
    leaveStore.loadTeamLeaves(teamId);
  }, []);

  const myEventsList = toJS(leaveStore.teamLeaves);
  const formats = {
    weekdayFormat: (date, culture, localizer) => localizer.format(date, "dddd", culture),
  };

  return (
    <>
      <Calendar
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 850 }}
        defaultDate={moment(new Date())}
        views={{ month: true, week: true, day: true, work_week: true }}
        formats={formats}
      />
    </>
  );
});

export default TeamLeaveTab;
