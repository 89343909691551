import { ScheduleType } from "interfaces/Schedule";
import { bhpColor } from "styles/globals";

export const getWorkTypeColor = (workType: ScheduleType | undefined): string => {
  switch (workType) {
    case ScheduleType.OFFICE:
      return bhpColor.blueGrey1;
    case ScheduleType.REMOTE:
      return bhpColor.blueGrey2;
    case ScheduleType.OFF:
      return bhpColor.grey2;
    default:
      return bhpColor.blueGrey1;
  }
};
