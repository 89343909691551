import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  useTheme,
  useMediaQuery,
  Box,
  Link,
} from "@material-ui/core";
import { SettingStyles } from "styles/SettingStyles";
import { wdpStrings } from "config/strings";
import { useTranslation } from "react-i18next";
import { SettingsWorkdayWidget } from "components/settings/SettingsWorkdayWidget";
import { SettingsScheduleType, Schedule, ScheduleType } from "interfaces/Schedule";
import { Fragment, useEffect, useState } from "react";
import EditHoursLocationDialog from "components/utils/EditHoursLocationDialog";
import { mdiClockOutline } from "@mdi/js";
import { bhpColor } from "styles/globals";
import Icon from "@mdi/react";
import { EditHoursLocationType } from "interfaces/Utils";
import { useScheduleStore } from "providers/RootStoreProvider";
import moment from "moment";

interface SettingsWeeklyRoutineProps {
  theRoutine: SettingsScheduleType[];
  handleWorktypeSelection: any;
  handleHoursLocationChanges: any;
  refreshIndex: number;
  default_office?: number;
}

export function SettingsWeeklyRoutine(props: SettingsWeeklyRoutineProps) {
  const {
    refreshIndex,
    theRoutine,
    handleWorktypeSelection,
    handleHoursLocationChanges,
    default_office,
  } = props;

  const classes = SettingStyles();
  const scheduleStore = useScheduleStore();
  const { t, i18n } = useTranslation();
  const [editHoursDialogOpen, setEditHoursDialogOpen] = useState<boolean>(false);
  const [editHoursLocationDay, setEditHoursLocationDay] = useState<string | null>(null);
  const [editHoursLocationWorkType, setEditHoursLocationWorkType] = useState<number | null>(null);

  const [settingsDefaultWeek, setSettingsDefaultWeek] = useState<SettingsScheduleType | null>();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const changeEvent = (event: any, idx: number) => {
    const clickedElement = event.target.localName;
    const newWorktype =
      clickedElement === "span"
        ? event.target.offsetParent.value // click was on label
        : clickedElement === "path"
        ? event.target.parentElement.parentElement.offsetParent.value // click was on path of svg
        : clickedElement === "button"
        ? event.target.value // click was on button
        : event.target.parentElement.offsetParent.value; // click was on svg
    handleWorktypeSelection(parseInt(newWorktype), idx);
  };

  const handleEditHoursLocationClick = (
    day: string,
    workType: number | null,
    settingsDefaultWeek: SettingsScheduleType
  ) => {
    setEditHoursDialogOpen(true);
    setEditHoursLocationDay(day);
    setEditHoursLocationWorkType(workType);
    setSettingsDefaultWeek(settingsDefaultWeek);
  };

  const handleEditHoursResponse = () => {
    setEditHoursDialogOpen(false);
  };

  const handleHoursLocationSave = (
    day: string,
    startTime: string,
    finishTime: string,
    deskStartTime: string,
    deskFinishTime: string,
    location: number
  ) => {
    handleHoursLocationChanges(day, startTime, finishTime, deskStartTime, deskFinishTime, location);
    setEditHoursDialogOpen(false);
  };

  useEffect(() => {
    if (default_office) {
      theRoutine.forEach((dy) => {
        dy.office = dy.office;
      });
    }
  }, [theRoutine, default_office]);

  return (
    <>
      <EditHoursLocationDialog
        t={t}
        isOpen={editHoursDialogOpen}
        day={editHoursLocationDay || null}
        handleResponseClick={handleEditHoursResponse}
        workType={editHoursLocationWorkType}
        editHoursLocationType={EditHoursLocationType.Settings}
        handleHoursLocationSave={handleHoursLocationSave}
        settingsDefaultWeek={settingsDefaultWeek}
      />
      <Paper variant="outlined" elevation={1}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.routineTable}>
            <Hidden smDown>
              {/* Show this only on BIGGER screens */}
              <TableHead>
                <TableRow>
                  <Hidden mdDown>
                    {/* Hide this on AVERAGE screens */}
                    <TableCell className={"titleCell"}>{t("Weekday")}</TableCell>
                  </Hidden>
                  {wdpStrings.weekdaysAbbreviated.map((day, key) => (
                    <TableCell
                      key={`a${key}`}
                      className={`${classes.routineTitle} ${
                        day === "Sat" || day === "Sun" ? "weekend" : ""
                      }`}
                    >
                      {t(day)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.routineBottomRow}>
                  <Hidden mdDown>
                    {/* Hide this on AVERAGE screens */}
                    <TableCell className={"titleCell"}>{t("Work Type")}</TableCell>
                  </Hidden>
                  {theRoutine.map((routine, idx) => (
                    <TableCell
                      key={`ob${idx}`}
                      className={`${classes.routineBody} ${idx > 4 ? "weekend" : ""}`}
                    >
                      <SettingsWorkdayWidget
                        day={wdpStrings.weekdaysAbbreviated[idx].toLowerCase()}
                        dayIdx={idx}
                        t={t}
                        key={`b${idx}`}
                        workType={
                          idx > 4
                            ? routine.status !== null
                              ? routine.status
                              : ScheduleType.OFF
                            : routine.status
                        }
                        orientation="vertical"
                        onChangeValue={changeEvent}
                        settingsDefaultWeek={routine}
                        onEditHoursLocationClick={handleEditHoursLocationClick}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Hidden>
            <Hidden mdUp>
              {/* Show this only on SMALLER screens */}
              <TableBody>
                {theRoutine.map((routine, idx) => (
                  <Fragment key={`fr${idx}`}>
                    {!smAndUp && routine.status !== 2 && (
                      <TableRow
                        className={`${classes.smallTable} ${idx > 4 ? "weekend" : ""}`}
                        style={{ borderBottom: "none" }}
                      >
                        <TableCell>
                          <Box
                            fontSize={12}
                            fontWeight="normal"
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                            color={bhpColor.blueGrey1}
                            justifyContent="center"
                            display="flex"
                            pb={0}
                          >
                            {routine?.start_time && routine?.finish_time ? (
                              <>
                                <Box pt={3} pr={1}>
                                  <Icon path={mdiClockOutline} size={"15px"} />
                                </Box>
                                <Box pt={3} pr={1}>
                                  {moment(routine?.start_time, ["HH:mm", "H:mm"]).format("hh:mm A")}
                                  -
                                  {moment(routine?.finish_time, ["HH:mm", "H:mm"]).format(
                                    "hh:mm A"
                                  )}
                                </Box>
                              </>
                            ) : (
                              ""
                            )}
                            <Box pt={3} pr={1}>
                              <Link
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleEditHoursLocationClick(
                                    wdpStrings.weekdaysAbbreviated[idx].toLowerCase(),
                                    routine.status,
                                    routine
                                  );
                                }}
                                underline="always"
                                style={{ color: bhpColor.orange1 }}
                              >
                                Edit
                              </Link>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow
                      key={`oc${idx}`}
                      className={`${classes.smallTable} ${idx > 4 ? "weekend" : ""}`}
                    >
                      <Box
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                        justifyContent="center"
                        display="flex"
                      >
                        <TableCell colSpan={3} style={{ padding: "15px 0" }}>
                          <TableCell
                            className={classes.titleColumn}
                            style={{ verticalAlign: "middle" }}
                          >
                            {t(wdpStrings.weekdaysAbbreviated[idx])}
                          </TableCell>
                          <TableCell>
                            <SettingsWorkdayWidget
                              day={wdpStrings.weekdaysAbbreviated[idx].toLowerCase()}
                              dayIdx={idx}
                              t={t}
                              key={`occ${idx}`}
                              workType={
                                idx > 4
                                  ? routine.status !== null
                                    ? routine.status
                                    : ScheduleType.OFF
                                  : routine.status
                              }
                              onChangeValue={changeEvent}
                              orientation="horizontal"
                              settingsDefaultWeek={routine}
                              onEditHoursLocationClick={handleEditHoursLocationClick}
                            />
                          </TableCell>
                        </TableCell>
                      </Box>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Hidden>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
