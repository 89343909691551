import { Box, Grid, Paper, useTheme } from "@material-ui/core";
import { feedbackStyles } from "styles/feedbackStyles";

interface FeedbackFormSummaryProps {
  feedbackId: number;
  formName: string;
  formTitle: string;
  formDescription: string;
  t: any;
  url: string;
}

export function FeedbackFormSummary(props: FeedbackFormSummaryProps) {
  const { formName, formTitle, formDescription, url } = props;
  const classes = feedbackStyles();
  const theme = useTheme();
  return (
    <>
      <Paper
        elevation={0}
        className={`${classes.paper} ${classes.hoverFeedbackSummaryBorder}`}
        style={{ minHeight: 118, padding: theme.spacing(2), cursor: "pointer" }}
      >
        <Box
          flexDirection="row"
          onClick={() => {
            window.open(url, "_blank");
          }}
        >
          <div className={classes.feedbackFormNameTitle}>
            {formName}: {formTitle}
          </div>
          <div className={classes.feedbackFormDescription}>{formDescription}</div>
        </Box>
      </Paper>
    </>
  );
}
