import axios from "axios";
import config from "../config";

const client = axios.create(config.api);

client.interceptors.request.use(async (config) => {
  // add token to request headers
  config.headers.Authorization = `Bearer ${window.token}`;
  return config;
});

// Add a response interceptor
// client.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     console.log(error.response.headers.refresh_url?.length);
//     if (error.response.status === 403 && error.response.headers.refresh_url?.length) {
//       // Redirect the user out of this application.
//       document.location.href = error.response.headers.refresh_url;
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

export default client;
