import {
  Box,
  createStyles,
  debounce,
  Grid,
  Link,
  makeStyles,
  Select,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { Fragment, useCallback, useEffect, useState } from "react";
import { BuildingTeamSchedule, UserForTeamSchedule } from "interfaces/Schedule";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnMenuContainer,
  GridFilterMenuItem,
  GridOverlay,
} from "@material-ui/data-grid";
import { bhpColor } from "styles/globals";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { toJS } from "mobx";
import { mdiCalendarSearch } from "@mdi/js";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { useBuildingStore, useTeamStore } from "providers/RootStoreProvider";
import { SettingsSelectBox } from "components/settings/SettingsSelectBox";
import { Autocomplete } from "@material-ui/lab";
import { sortAlphaNumeric } from "utils/string";

import moment from "moment";
import config from "config";
import BookingsPopover from "components/utils/BookingsPopover";

interface PeopleTabDataProps {
  buildingTeamSchedule: BuildingTeamSchedule | null;
  columnSpan?: number;
  changeTeam: any;
  focussedTeam: number | null;
  focussedDate: string;
  t: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerCell: {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        paddingTop: "12px",
      },
    },
    smallTable: {
      color: "unset",
      "& .MuiDataGrid-renderingZone": {
        maxHeight: "unset !important",
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      "& .MuiTableCell-root": {},
      "& .MuiDataGrid-root": {
        maxHeight: "unset !important",
      },
      "& .MuiDataGrid-row": {
        maxHeight: "max-content !important",
        minHeight: "max-content !important",
      },
      "& .MuiDataGrid-cell": {
        lineHeight: "unset !important",
        minHeight: "unset !important",
        maxHeight: "unset !important",
      },
    },
    recordsFoundLabel: {
      color: "#E65400",
      fontFamily: "Arial",
      fontStyle: "italic",
      fontWeight: "bold",
      whiteSpace: "nowrap",
    },
    searchTextField: {
      backgroundColor: "white",
      color: bhpColor.blueGrey1,
      "& .MuiSelect-select:focus": {
        backgroundColor: "white",
      },
    },
    pb4: {
      paddingBottom: 16,
    },
    unbold: {
      fontWeight: "normal",
    },
    textFieldSpacing: {
      "& .MuiTextField-root": {
        padding: 8,
      },
    },
    textFieldLabel: {
      paddingLeft: 10,
      fontWeight: "bold",
      color: "#466371",
    },
    theGrid: {
      "& .MuiDataGrid-columnHeader:focus": {
        outline: "none",
      },
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-sortIcon": {
          opacity: `1 !important`,
        },
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
        fontSize: 14,
        color: bhpColor.grey1,
        paddingRight: 16,
      },
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-columnsContainer": {
        borderBottom: 0,
      },
      "& .MuiDataGrid-cell": {
        borderBottom: 0,
        alignItems: "start",
        [theme.breakpoints.down("sm")]: {
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      "& .MuiDataGrid-root": {
        border: 0,
        "& .Mui-even": {
          backgroundColor: bhpColor.backgroundGrey,
        },
      },
    },
  })
);

const PeopleTabData = observer((props: PeopleTabDataProps) => {
  const { buildingTeamSchedule, columnSpan, t, focussedTeam, changeTeam, focussedDate } = props;
  const classes = innerStyles();
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const buildingStore = useBuildingStore();
  const teamStore = useTeamStore();

  const openFloorPlan = (id: number | undefined, date: string | undefined) => {
    if (id) {
      buildingStore.openFloorPlan(true);
      buildingStore.setupFloorPlanFromSerraviewId(id, false, date);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Name"),
      sortable: true,
      flex: 1,
      minWidth: 151,
      valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}`,
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params: GridCellParams) => {
        return (
          <Link href={`/people/${params.row.id}`} color="secondary">
            {params.row.preferred_name
              ? params.row.preferred_name
              : `${params.row.first_name} ${params.row.last_name}`}
          </Link>
        );
      },
    },
    {
      field: "title",
      headerName: t("Role Title"),
      sortable: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => (params.row.employee_position ? params.row.employee_position : ""),
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params: GridCellParams) => {
        return params.row.employee_position ? params.row.employee_position : "";
      },
    },
    {
      field: "default_team_name",
      headerName: t("Default Team"),
      sortable: true,
      flex: 1,
      minWidth: 151,
      valueGetter: (params) => (params.row.default_team_id ? params.row.default_team_name : ""),
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params: GridCellParams) => {
        return params.row.default_team_id ? (
          <Link href={`/teams/${params.row.default_team_id}`} color="secondary">
            {params.row.default_team_name}
          </Link>
        ) : (
          ""
        );
      },
    },
    {
      field: "space_reference",
      headerName: t("Desk"),
      sortable: true,
      flex: 1,
      minWidth: 151,
      renderCell: (params: GridCellParams) => {
        return params.row.space_reference ? (
          <BookingsPopover
            focussedDate={focussedDate}
            spaceReference={
              params.row.upcoming_booking
                ? params.row.upcoming_booking.space.name
                : params.row.space_reference
            }
            serraViewId={
              params.row.upcoming_booking
                ? params.row.upcoming_booking.space.space_serraview_id
                : params.row.space_serraview_id
            }
            bookings={params.row.multiple_bookings}
          />
        ) : (
          ""
        );
      },
    },
    {
      field: "safety_officer_role",
      headerName: t("Safety Officer Role"),
      sortable: true,
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.is_fire_warden || params.row.is_first_aid_officer,

      renderCell: (params: GridCellParams) => {
        return [
          params.row.is_fire_warden ? t("Fire Warden") : null,
          params.row.is_first_aid_officer ? t("First Aid Officer") : null,
        ]
          .filter((x) => x !== null)
          .join(", ");
      },
    },
    { field: "id", hide: true },
    { field: "email", hide: true },
    { field: "first_name", hide: true },
    { field: "last_name", hide: true },
    { field: "default_team_id", hide: true },
    { field: "default_team_role", hide: true },
  ];

  const columnsSmall: GridColDef[] = [
    {
      field: "name",
      headerName: t("Name"),
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 151,
      renderCell: (params: GridCellParams) => {
        return (
          <Box>
            <Typography display={"block"} style={{ fontWeight: 700, fontSize: 14 }}>
              {params.row.preferred_name
                ? params.row.preferred_name
                : `${params.row.first_name} ${params.row.last_name}`}
            </Typography>
            <Typography display={"block"} style={{ fontSize: 14 }}>
              {params.row.title}
            </Typography>
            <Typography display={"block"} style={{ fontSize: 10 }}>
              {params.row.default_team}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "space_reference",
      headerName: t("Booking Reference"),
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridCellParams) => {
        return (
          <Typography display={"block"} style={{ fontSize: 14 }}>
            {params.row.space_reference ? (
              <Link
                href="#"
                onClick={() =>
                  openFloorPlan(
                    params.row.upcoming_booking
                      ? params.row.upcoming_booking.space.space_serraview_id
                      : params.row.space_serraview_id,
                    params.row.date
                  )
                }
              >
                {params.row.upcoming_booking
                  ? params.row.upcoming_booking.space.name
                  : params.row.space_reference}
              </Link>
            ) : (
              ""
            )}
          </Typography>
        );
      },
    },
    { field: "id", hide: true },
    { field: "title", hide: true },
    { field: "default_team_id", hide: true },
    { field: "default_team_role", hide: true },
    { field: "email", hide: true },
    { field: "first_name", hide: true },
    { field: "last_name", hide: true },
  ];

  function CustomNoRowsOverlay() {
    return <GridOverlay>{t("nothing found")}</GridOverlay>;
  }

  const [searchTerm, setSearchTerm] = useState("");

  const doSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
  };

  // const sortAlphaNumeric = (a: any, b: any) => {
  //   // convert to strings and force lowercase
  //   a = typeof a === "string" ? a.toLowerCase() : a.toString();
  //   b = typeof b === "string" ? b.toLowerCase() : b.toString();
  //   return a.localeCompare(b, "en", { numeric: true });
  // };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const getResults = () => {
    const allResults = [...(buildingTeamSchedule?.users || [])];
    return searchTerm.length == 0
      ? allResults
      : allResults.filter((user) => {
          return (
            user.last_name.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
            user.preferred_name.toLowerCase().includes(searchTerm.toLowerCase())
          );
        });
  };

  const CustomColumnMenu = (props: any) => {
    const { hideMenu, currentColumn } = props;
    return (
      <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} open={false}>
        <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
      </GridColumnMenuContainer>
    );
  };

  const teamSelector = () => {
    return (
      <Select
        id="teamAutoComplete"
        key={"teamSelector"}
        native
        fullWidth
        variant="outlined"
        style={{ height: 40, marginLeft: "8px" }}
        defaultValue={""}
        onChange={(e) => changeTeam(e.target.value)}
        className={classes.searchTextField}
      >
        <option key={`tso0`} value={""}>
          {t("All people planned for this location")}
        </option>
        {[...teamStore.myTeams].map((team, idx) => {
          return (
            <option key={`tso${idx}`} value={team.id.toString()}>
              {team.department ? `${team.department} -> ${team.name}` : team.name}
            </option>
          );
        })}
      </Select>
    );
  };

  return (
    <Fragment>
      <TableRow>
        <TableCell
          colSpan={columnSpan ? columnSpan : 1}
          style={{ padding: 0, paddingLeft: 16, paddingRight: 16, paddingTop: 24 }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <TextField
                id="search"
                label={t("Search")}
                placeholder={t("Search")}
                size="small"
                variant="outlined"
                style={{ backgroundColor: "white", minWidth: mdAndUp ? "75%" : "100%" }}
                autoComplete="off"
                onChange={doSearch}
                value={searchTerm}
                InputProps={{
                  endAdornment: (
                    <>
                      {searchTerm.length > 0 ? (
                        <div style={{ cursor: "pointer" }} onClick={() => clearSearch()}>
                          <ClearIcon />
                        </div>
                      ) : (
                        ""
                      )}
                      <SearchIcon />
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                {[...(buildingTeamSchedule?.users || [])].length > -1 ? (
                  <Box className={classes.recordsFoundLabel}>
                    {getResults().length}{" "}
                    {getResults().length > 1 ? t("results for") : t("result for")}
                    {":"}
                  </Box>
                ) : (
                  ""
                )}
                {teamSelector()}
              </Box>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>

      <TableRow style={{ backgroundColor: "white" }}>
        <TableCell colSpan={columnSpan} className={classes.containerCell}>
          <Box style={{ height: mdAndUp ? "396px" : "500px" }} className={classes.theGrid}>
            <DataGrid
              className={mdAndUp ? "" : classes.smallTable}
              columns={mdAndUp ? columns : columnsSmall}
              rows={getResults()}
              pageSize={15}
              rowsPerPageOptions={[15]}
              density={"compact"}
              disableColumnSelector={true}
              disableSelectionOnClick={true}
              scrollbarSize={0}
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
                ColumnSortedAscendingIcon: ExpandMore,
                ColumnSortedDescendingIcon: ExpandLess,
                ColumnUnsortedIcon: UnfoldMoreIcon,
                ColumnMenu: CustomColumnMenu,
              }}
            />
          </Box>
        </TableCell>
      </TableRow>
    </Fragment>
  );
});

export default PeopleTabData;
