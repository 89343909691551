import Icon from "@mdi/react";
import { mdiInformationOutline, mdiInformation } from "@mdi/js";
import { observer } from "mobx-react-lite";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { bhpColor } from "styles/globals";

interface InformationIconProps {
  onclick?: any;
  color?: string;
  filled?: boolean;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    inlineLink: {
      display: "flex",
      alignItems: "center",
      fontWeight: "bold",
      cursor: "pointer",
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(1),
      },
    },
  })
);

const InformationIcon = observer((props: InformationIconProps) => {
  const { onclick, color, filled } = props;
  const classes = innerStyles();
  return (
    <div
      className={classes.inlineLink}
      style={color ? { color: color } : { color: bhpColor.orange1 }}
      onClick={() => onclick()}
    >
      {filled === true ? (
        <Icon path={mdiInformation} size="1em" />
      ) : (
        <Icon path={mdiInformationOutline} size="1em" />
      )}
    </div>
  );
});

export default InformationIcon;
