import { SettingStyles } from "styles/SettingStyles";
import { FormControl, FormHelperText, Select } from "@material-ui/core";
import { wdpStrings } from "config/strings";
import { get } from "lodash";

interface SettingSelectBoxProps {
  defaultValue?: number | string | null;
  fontSize?: string;
  width?: string;
  phText?: string | null;
  phValue?: string | number | null;
  showPlaceholder?: boolean;
  cssClass?: any;
  theId: string;
  theKey: string;
  theItems: any[];
  valueField?: string;
  labelField?: string;
  handleChange: any;
  t: any;
  name?: string;
  error?: boolean;
  helperText?: string;
}

export function SettingsSelectBox(props: SettingSelectBoxProps) {
  const classes = SettingStyles();
  const {
    showPlaceholder,
    phText,
    phValue,
    defaultValue,
    fontSize,
    width,
    theId,
    theKey,
    theItems,
    handleChange,
    valueField,
    labelField,
    cssClass,
    t,
    name,
    error,
    helperText,
  } = props;
  const addPlaceholder = typeof showPlaceholder === "undefined" ? true : showPlaceholder;

  const vField = valueField || "value";
  const lField = labelField || "label";

  const drawPlaceholder = (showThis: boolean) => {
    return showThis ? (
      <option
        key={`-11-${theKey}`}
        value={phValue ? phValue : wdpStrings.defaultDropDownPlaceholder.value}
      >
        {phText ? phText : t("Choose...")}
      </option>
    ) : null;
  };

  let styleExtras = {};
  if (fontSize) styleExtras = { ...styleExtras, fontSize: fontSize };
  if (width) styleExtras = { ...styleExtras, width: width };

  return (
    <FormControl variant="outlined" className={classes.formControl} error={error}>
      <Select
        native
        className={cssClass ? cssClass : classes.selectBox}
        id={theId}
        key={theKey}
        onChange={
          name
            ? (e) => handleChange({ target: { value: e.target.value, name: e.target.name } })
            : (e) => handleChange(e.target.value)
        }
        value={defaultValue ? defaultValue : undefined}
        style={styleExtras}
        name={name}
      >
        {drawPlaceholder(addPlaceholder)}
        {theItems?.map((itm, key) => {
          return (
            <option key={`${theKey}-${key}`} value={get(itm, vField)}>
              {get(itm, lField)}
            </option>
          );
        })}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
