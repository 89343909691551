import moment from "moment";
import { observer } from "mobx-react-lite";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Slider,
  Switch,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { SettingsSelectBox } from "components/settings/SettingsSelectBox";
import {
  BuildingWithCapacity,
  CalibrationDetail,
  DEFAULT_OFFSET_X,
  DEFAULT_OFFSET_Y,
  DEFAULT_SCALE_X,
  DEFAULT_SCALE_Y,
  DEFAULT_SPACE_RADIUS,
  DEFAULT_YRATIO,
  floorplanSpecs,
  FloorplanSpecs,
  Space,
} from "interfaces/Building";
import { useRootStore } from "providers/RootStoreProvider";
import { bookingStyles } from "styles/bookingStyles";
import Topbar from "./Topbar";
import FloorChooser from "components/booking/FloorChooser";
import ShowBuildingSummary from "components/booking/ShowBuildingSummary";
import { bhpColor, settings } from "styles/globals";
import { useTranslation } from "react-i18next";
import { getExceededCapacityMessage } from "components/booking/utils";
import Calibrator from "components/utils/Calibrator";
import ArrowDropDownTwoToneIcon from "@material-ui/icons/ArrowDropDownTwoTone";
import ArrowDropUpTwoToneIcon from "@material-ui/icons/ArrowDropUpTwoTone";
import ArrowLeftTwoToneIcon from "@material-ui/icons/ArrowLeftTwoTone";
import ArrowRightTwoToneIcon from "@material-ui/icons/ArrowRightTwoTone";
import HeightTwoToneIcon from "@material-ui/icons/HeightTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LayersIcon from "@material-ui/icons/Layers";
import { toJS } from "mobx";
import { get } from "lodash";
import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { correctEmptyCalibrations } from "utils/building";
import {
  deskTypeIds,
  EnhancedSpace,
  esOffset,
  getSpaceExtension,
  getSpaceTypeDetail,
  OtherTypeDefaultConfiguration,
  spaceExtension,
} from "components/booking/enhancedFloorplan/OtherTypes";
import EnhancedSpaces from "components/booking/enhancedFloorplan/EnhancedSpaces";
import { drawIcon } from "components/booking/Floorplan/Drawing";
import { Delete, ExpandLess, ZoomOutMap } from "@material-ui/icons";

interface CalibrationToolProps {
  match?: any;
}

interface ModalData {
  id: number;
  space_type?: { id?: number; name?: string; description?: string };
  name: string;
  description?: string;
  coordinates: { x: number; y: number }[];
}

interface Scale {
  scale: { x: number; y: number };
  offset: { x: number; y: number };
}

const useStyles = makeStyles({
  shrinkGrowButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .rotated": {
      transform: "rotate(90deg)",
    },
    "& .small": {
      fontSize: "14px",
      height: "14px",
    },
  },
  selectedRow: {
    backgroundColor: bhpColor.green4,
    color: bhpColor.black,
    fontWeight: 700,
  },
  modal: {
    borderRadius: `4px`,
    border: `1px solid ${bhpColor.blueGrey1}`,
    backgroundColor: "white",
    whiteSpace: "pre-line",
    width: "100%",
    marginLeft: "10px",
    padding: "10px",
    filter: `drop-shadow(7px 7px 4px #777777)`,
  },
  form: {
    "& .MuiOutlinedInput-input": {
      padding: 4,
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-multiline": {
      padding: 4,
      fontSize: "14px",
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
});

/* Enhanced Spaces description of purpose : -
Developed to enable the presentation of layers of additional information on the Interactive Floorplan
Enhanced Features include; toilets, kitchens, lifts etc.
Basic items are point sources where an icon defines the location
Advanced items may be drawn using polygons

Step 1. Obtain the enhanced spaces
src/components/enhancedFloorplan/OtherTypes defines the deskTypeIds, these are the space.space_type.id of desks, everything else are enhanced spaces
the store has been extended to provide a floorEnhancedSpaces object { [floorId: string]: Space[] } it is loaded at the same time as normal spaces 
through loadSpaces

Step 2. Allow placement of new feature
  a- select feature
  b- click on map
  c- place icon
  d- complete metadata
  e- save

Step 3. Edit/Remove feature
  - click on feature
  - open modal
  - edit metadata & update
    - close modal
  - click delete feature
    - confirm
    - delete from store
*/

const CalibrationTool = observer((props: CalibrationToolProps) => {
  const store = useRootStore();
  const classes = bookingStyles();
  const localStyles = useStyles();
  const history = useHistory();
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));
  const { t, i18n } = useTranslation();

  const theDate = moment(new Date()).format("YYYY-MM-DD");
  const requestedBuildingId = props.match.params.buildingId || null;
  const requestedFloorId = props.match.params.floorId || null;

  const initialBuildingId = requestedBuildingId
    ? parseInt(requestedBuildingId)
    : store.userStore.me?.profile.default_office || null;

  const initialFloorId = requestedFloorId ? parseInt(requestedFloorId) : null;

  const [currentBuildingId, setCurrentBuildingId] = useState<number | null>(initialBuildingId);
  const [currentFloorId, setCurrentFloorId] = useState<number | null>(initialFloorId);

  //#region Building Selection
  const currentBuilding: BuildingWithCapacity | null = currentBuildingId
    ? store.buildingStore.getBuilding(currentBuildingId, theDate)
    : null;
  const handleBuildingChange = (newLocation: string) => {
    if (currentBuildingId != parseInt(newLocation)) {
      setCurrentFloorId(null);
      store.buildingStore.setCurrentFloor(null);
      setCurrentBuildingId(parseInt(newLocation));
      store.buildingStore.setCurrentBuilding(parseInt(newLocation));

      const hardCodedBuildingSpecs = store.buildingStore.getHardCodedFloorplanSpecs(
        parseInt(newLocation)
      );
      const thisBuilding = store.buildingStore.buildingsWithCapacity.find(
        (bld) => bld.id === parseInt(newLocation)
      );
      if (thisBuilding && thisBuilding.calibration_json) {
        const buildingCalibration = fromFloorplanSpecs(thisBuilding.calibration_json);
        if (buildingCalibration) {
          setBuildingSpecs(buildingCalibration);
          setDbBuildingSpecs(buildingCalibration);
          setCalibratorStateValues(buildingCalibration);
        }
      } else {
        if (hardCodedBuildingSpecs) {
          const buildingCalibration = fromFloorplanSpecs(hardCodedBuildingSpecs);
          if (buildingCalibration) {
            setBuildingSpecs(buildingCalibration);
            setCalibratorStateValues(buildingCalibration);
          }
        }
      }
    }
  };

  const [showLayers, setShowLayers] = useState(false);
  const toggleLayers = () => {
    setShowLayers(!showLayers);
  };

  const showVisibleLayerNames = (maxLength: number = 0, maxNameLength?: number) => {
    let maxLabelLength = maxNameLength || 0;
    return shownTypes.map((typ, idx) => {
      const spaceDetail = getSpaceTypeDetail(typ);
      if (spaceDetail) {
        const theName = spaceDetail.name || "";
        const usableName =
          maxLabelLength == 0 || theName.length < maxLabelLength
            ? theName
            : `${theName.substring(0, maxNameLength)}...`;
        return (
          <Tooltip title={spaceDetail.name}>
            <Box
              style={{
                display: "inline-block",
                color: spaceDetail.color || OtherTypeDefaultConfiguration.color,
              }}
            >
              {spaceDetail.icon}
            </Box>
          </Tooltip>
        );
      }
    });
  };

  useEffect(() => {
    store.buildingStore.setCurrentBuilding(currentBuildingId);
    if (`${currentBuildingId}` !== `${store.buildingStore.currentBuilding?.id}`) {
      store.buildingStore.setCurrentBuilding(currentBuildingId);
      setCurrentFloorId(null);
    }
    if (currentFloorId && `${currentFloorId}` !== `${store.buildingStore.currentFloorId}`) {
      store.buildingStore.setCurrentFloorId(currentFloorId);
      store.buildingStore.setCurrentFloor(currentFloorId);
      if (currentBuildingId)
        store.buildingStore.loadSpaces(currentBuildingId, currentFloorId, theDate, true);
    }
  }, [currentBuildingId]);
  //#endregion Building Selection

  // #region Stuff for scaling
  const canvasWidth = 1024;
  const [refreshDrawing, setRefreshDrawing] = useState(true);

  const hardCodedBuildingSpecs = currentBuildingId
    ? store.buildingStore.getHardCodedFloorplanSpecs(currentBuildingId)
    : null;

  const [scaleX, setScaleX] = useState<number>(
    hardCodedBuildingSpecs ? Number(hardCodedBuildingSpecs.scale.x.toFixed(4)) : DEFAULT_SCALE_X
  );
  const [scaleY, setScaleY] = useState<number>(
    hardCodedBuildingSpecs ? Number(hardCodedBuildingSpecs.scale.y.toFixed(4)) : DEFAULT_SCALE_Y
  );
  const [ratioY, setRatioY] = useState<number>(DEFAULT_YRATIO);

  const [offsetX, setOffsetX] = useState<number>(
    hardCodedBuildingSpecs ? Number(hardCodedBuildingSpecs.offset.x) : DEFAULT_OFFSET_X
  );
  const [offsetY, setOffsetY] = useState<number>(
    hardCodedBuildingSpecs ? Number(hardCodedBuildingSpecs.offset.y) : DEFAULT_OFFSET_Y
  );
  const [dotRadius, setDotRadius] = useState<number>(
    hardCodedBuildingSpecs && hardCodedBuildingSpecs.spaceRadius
      ? Number(hardCodedBuildingSpecs.spaceRadius)
      : DEFAULT_SPACE_RADIUS
  );

  const [dbBuildingSpecs, setDbBuildingSpecs] = useState<CalibrationDetail | null>(null);
  const [dbFloorSpecs, setDbFloorSpecs] = useState<CalibrationDetail | null>(null);

  const [specsChanged, setSpecsChanged] = useState(false);
  const [activeSpecs, setActiveSpecs] = useState<CalibrationDetail>({
    scale: { x: Number(scaleX), y: Number(scaleY) },
    offset: { x: Number(offsetX), y: Number(offsetY) },
    spaceRadius: Number(dotRadius),
  });

  const [buildingSpecs, setBuildingSpecs] = useState<FloorplanSpecs>(activeSpecs);

  const offsetIncrement = 1;
  const scaleIncrement = 0.001;

  const [isScaling, setIsScaling] = useState(false);
  const [isMoving, setIsMoving] = useState(false);

  const handleChangeRadius = (event, newValue) => {
    setSpecsChanged(true);
    setDotRadius(newValue);
  };

  const doClick = (evt: any) => {
    evt.preventDefault();
    const isMoveButtonClick = evt.button === 0;
    const isScaleButtonClick = evt.button === 2;

    if (isScaleButtonClick) {
      // toggle scaling
      setIsScaling(!isScaling);
      setIsMoving(false);
    }

    if (isMoveButtonClick) {
      // toggle moving
      setIsMoving(!isMoving);
      setIsScaling(false);
    }
  };

  const doMove = (moveX: boolean | null, moveY: boolean | null) => {
    const newXOffset = moveX ? offsetX + offsetIncrement : offsetX - offsetIncrement;
    const newYOffset = moveY ? offsetY + offsetIncrement : offsetY - offsetIncrement;
    if (moveX !== null) {
      setSpecsChanged(true);
      setOffsetX(newXOffset);
    }
    if (moveY !== null) {
      setSpecsChanged(true);
      setOffsetY(newYOffset);
    }
  };

  const doScale = (deltaX: boolean | null, deltaY: boolean | null) => {
    const newXScale = deltaX ? Number(scaleX) + scaleIncrement : Number(scaleX) - scaleIncrement;
    const newYScale = deltaY ? Number(scaleY) + scaleIncrement : Number(scaleY) - scaleIncrement;
    if (deltaX !== null) {
      setSpecsChanged(true);
      setScaleX(Number(newXScale.toFixed(4)));
    }
    if (deltaY !== null) {
      setSpecsChanged(true);
      setScaleY(Number(newYScale.toFixed(4)));
    }
  };

  const [lastX, setLastX] = useState(0);
  const [lastY, setLastY] = useState(0);

  const trapMove = (evt: any) => {
    if (isMoving || isScaling) {
      if (lastX === 0) setLastX(evt.clentX);
      if (lastY === 0) setLastY(evt.clientY);
      const moveX = lastX === evt.clentX ? null : evt.clientX > lastX;
      const moveY = lastY === evt.clentY ? null : evt.clientY > lastY;
      if (isMoving && (moveX !== null || moveY !== null)) doMove(moveX, moveY);
      if (isScaling && (moveX !== null || moveY !== null)) doScale(moveX, moveY);
      setLastX(evt.clientX);
      setLastY(evt.clientY);
    }
  };

  // #endregion Stuff for Scaling

  /** converts floor calibration strings to numbers
   * @param scales - calibration specs
   * @returns FloorplanSpecs (nummeric)
   */
  const toFloorplanSpecs = (scales: CalibrationDetail) => {
    const numberSpecs: FloorplanSpecs = {
      scale: {
        x: Number(scales.scale.x),
        y: Number(scales.scale.y),
      },
      offset: scales.offset,
      spaceRadius: scales.spaceRadius,
    };
    return numberSpecs;
  };

  /** converts floorplanSpecs to Calibration strings
   * @param scales - floorplan specs (numeric)
   * @returns CalibrationDetail (strings)
   */
  const fromFloorplanSpecs = (scales: FloorplanSpecs | string | null) => {
    if (!scales) return null;
    const usefulScales = typeof scales === "string" ? getSpecsFromString(scales) : { ...scales };
    if (usefulScales && !usefulScales.scale) return null;
    const numberSpecs: CalibrationDetail | null = usefulScales
      ? {
          scale: {
            x:
              typeof usefulScales.scale.x === "string"
                ? Number(usefulScales.scale.x)
                : Number(usefulScales.scale.x.toFixed(4)),
            y:
              typeof usefulScales.scale.y === "string"
                ? Number(usefulScales.scale.y)
                : Number(usefulScales.scale.y.toFixed(4)),
          },
          offset: usefulScales.offset,
          spaceRadius: usefulScales.spaceRadius || DEFAULT_SPACE_RADIUS,
        }
      : null;
    return numberSpecs;
  };

  /** sets the values of the calibration state
   * @param specs - calibration detail to use (default - building core specs)
   */
  const setCalibratorStateValues = (specs: CalibrationDetail = buildingSpecs) => {
    setScaleX(specs.scale.x);
    setScaleY(specs.scale.y);
    setOffsetX(specs.offset.x);
    setOffsetY(specs.offset.y);
    setDotRadius(specs.spaceRadius || DEFAULT_SPACE_RADIUS);
  };

  const getSpecsFromString = (specs: string | null) => {
    if (!specs) return null;
    const scale = get(specs, "scale", null);
    const offset = get(specs, "offset", null);
    const spaceRadius = get(specs, "spaceRadius", null);
    return { scale, offset, spaceRadius };
  };

  //#region floor change
  /**
   * sets the current floor (state)
   * gets the calibration for the floor
   * set useBuilding (state) to floor calibration exists
   * @param newFloorId - chosen floor
   */
  const handleFloorChange = (newFloorId: number) => {
    if (currentFloorId != newFloorId) {
      setCurrentFloorId(newFloorId);
      const storedFloor = store.buildingStore.currentBuilding?.floors?.find(
        (flr) => `${flr.id}` === `${newFloorId}`
      );
      if (storedFloor && storedFloor.calibration_json) {
        const floorCalibration = getSpecsFromString(storedFloor.calibration_json);
        setDbFloorSpecs(fromFloorplanSpecs(floorCalibration));
      }
    }
  };

  const [resetingTool, setResetingTool] = useState(false);

  const getFloorImageModule = (floorId: string | number | null | undefined) => {
    if (!floorId) return null;
    let module;
    try {
      module = require(`assets/floorplans/sv_${floorId}.png`);
    } catch {
      return null;
    }
    return module;
  };

  const [floorImage, setFloorImage] = useState<any>(
    store.buildingStore.currentFloor && store.buildingStore.currentFloor.floor_plan
      ? store.buildingStore.currentFloor.floor_plan
      : currentFloorId
      ? getFloorImageModule(currentFloorId)
      : null
  );

  const applyFloorId = (floorId: number) => {
    if (floorId && `${floorId}` !== `${store.buildingStore.currentFloorId}`) {
      store.buildingStore.setCurrentFloorId(floorId);
      store.buildingStore.setCurrentFloor(floorId);
      if (currentBuildingId)
        store.buildingStore.loadSpaces(currentBuildingId, floorId, theDate, true);
    }

    const storedFloor = store.buildingStore.currentBuilding?.floors?.find(
      (flr) => `${flr.id}` === `${floorId}`
    );
    const floorSpecs = storedFloor?.calibration_json;
    if (floorSpecs && JSON.stringify(floorSpecs).length > 2) {
      const newSpecs = fromFloorplanSpecs(floorSpecs || null);
      if (newSpecs) {
        setDbFloorSpecs(fromFloorplanSpecs(newSpecs));
        resetSpecs(newSpecs);
      }
    }
    if (storedFloor && storedFloor?.floor_plan !== "") {
      setFloorImage(storedFloor.floor_plan);
    } else {
      setFloorImage(getFloorImageModule(currentFloorId));
    }
    setRefreshDrawing(!refreshDrawing);
  };

  useEffect(() => {
    if (currentFloorId) {
      store.buildingStore.setCurrentFloorId(currentFloorId);
      store.buildingStore.setCurrentFloor(currentFloorId);
      applyFloorId(currentFloorId);
      setExtendedSpaces(store.buildingStore.floorEnhancedSpaces[currentFloorId]);
    }
  }, [currentFloorId]);
  //#endregion floor change

  /** there has been a change to the tool
      these are the specs in the display
   */
  useEffect(() => {
    if (specsChanged) {
      const currentSpecs = {
        scale: { x: scaleX, y: scaleY },
        offset: { x: offsetX, y: offsetY },
        spaceRadius: dotRadius,
      };
      setActiveSpecs(currentSpecs);
      setSpecsChanged(false);
      setResetingTool(false);
    }
  }, [
    scaleX,
    scaleY,
    offsetX,
    offsetY,
    ratioY,
    dotRadius,
    specsChanged,
    buildingSpecs,
    resetingTool,
  ]);

  useEffect(() => {
    ///on-mount
    /**
     * helper to set the display specifications
     * @param buildingId
     * @param floorId
     */
    const setTheStoredSpecs = (buildingId: number, floorId: number | null) => {
      if (buildingId) {
        const dBBuilding = store.buildingStore.getBuilding(buildingId);
        if (dBBuilding) {
          const dBBuildingSpecs = dBBuilding.calibration_json;
          if (dBBuildingSpecs && JSON.stringify(dBBuildingSpecs).length > 2)
            setDbBuildingSpecs(getSpecsFromString(dBBuildingSpecs));
          if (floorId) {
            const thisFloor = dBBuilding.floors?.find((flr) => `${flr.id}` === `${floorId}`);
            if (thisFloor) setDbFloorSpecs(fromFloorplanSpecs(thisFloor.calibration_json || null));
          }
        }
      }
    };

    // on mount, check all the buildings and all the floors for calibration data
    // if we have hard coded calibration and nothing in the database then the migration hasn't worked.
    // fix the database.
    if (store.buildingStore.buildingsWithCapacity.length > 10) {
      correctEmptyCalibrations(store);
      //may as well set the display specs here too
      if (requestedBuildingId) setTheStoredSpecs(requestedBuildingId, currentFloorId);
      if (
        (currentFloorId && !store.buildingStore.currentFloor) ||
        (currentFloorId && currentFloorId !== store.buildingStore.currentFloor?.id)
      ) {
        store.buildingStore.setCurrentFloor(currentFloorId);
        applyFloorId(currentFloorId);
        if (requestedBuildingId) store.buildingStore.setCurrentBuilding(requestedBuildingId);
        if (initialBuildingId) handleBuildingChange(initialBuildingId.toString());
      }
    } else {
      store.buildingStore
        .loadBuildingsWithCapacity(moment(new Date()).format("YYYY-MM-DD"), true)
        .then(() => {
          correctEmptyCalibrations(store);
          //may as well set the display specs here too
          if (requestedBuildingId) setTheStoredSpecs(requestedBuildingId, currentFloorId);
          if (
            (currentFloorId && !store.buildingStore.currentFloor) ||
            (currentFloorId && currentFloorId !== store.buildingStore.currentFloor?.id)
          ) {
            store.buildingStore.setCurrentFloor(currentFloorId);
            applyFloorId(currentFloorId);
          }
          if (requestedBuildingId) store.buildingStore.setCurrentBuilding(requestedBuildingId);
          if (initialBuildingId) handleBuildingChange(initialBuildingId.toString());
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /** Displays a calibrator adjuster
   * @param value value to display in field
   * @param Xaxis is this the Xaxis ?
   * @param scale is this affecting the scale?
   * @returns a calibrator tool
   */
  const calibratorAdjuster = (value: string | number, Xaxis: boolean, scale: boolean = false) => {
    return (
      <Grid container style={{ paddingBottom: "6px", alignItems: "center" }}>
        <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            className={localStyles.shrinkGrowButton}
            size="small"
            onClick={() =>
              Xaxis
                ? scale
                  ? doScale(false, null)
                  : doMove(false, null)
                : scale
                ? doScale(null, false)
                : doMove(null, false)
            }
          >
            {Xaxis ? (
              scale ? (
                <HeightTwoToneIcon className={`rotated small`} />
              ) : (
                <ArrowLeftTwoToneIcon fontSize="small" />
              )
            ) : scale ? (
              <HeightTwoToneIcon className={`small`} />
            ) : (
              <ArrowDropUpTwoToneIcon fontSize="small" />
            )}
          </IconButton>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            paddingLeft: "4px",
            paddingRight: "4px",
            justifyContent: "center",
          }}
        >
          {value}
        </Grid>
        <Grid item xs={3} style={{ display: "flex", justifyContent: "end" }}>
          <IconButton
            className={localStyles.shrinkGrowButton}
            size="small"
            onClick={() =>
              Xaxis
                ? scale
                  ? doScale(true, null)
                  : doMove(true, null)
                : scale
                ? doScale(null, true)
                : doMove(null, true)
            }
          >
            {Xaxis ? (
              scale ? (
                <HeightTwoToneIcon fontSize="small" className="rotated" />
              ) : (
                <ArrowRightTwoToneIcon fontSize="small" />
              )
            ) : scale ? (
              <HeightTwoToneIcon fontSize="small" />
            ) : (
              <ArrowDropDownTwoToneIcon fontSize="small" />
            )}
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  /** create a calibration item
   * @param label the label of the calibration item
   * @param canAdjust can you make changes?
   * @returns a calibration item
   */
  const calibratorItem = (label: string, canAdjust: boolean) => {
    return (
      <Grid
        container
        style={{
          borderLeft: `1px solid ${bhpColor.blueGrey1}`,
          borderRight: `1px solid ${bhpColor.blueGrey1}`,
          borderTop: 0,
        }}
      >
        <Grid item xs={3} style={{ textAlign: "end", paddingRight: "12px", paddingTop: "8px" }}>
          {label}
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            paddingTop: "8px",
            paddingLeft: "2px",
            paddingRight: "2px",
            borderLeft: `1px solid ${bhpColor.blueGrey1}`,
          }}
        >
          {canAdjust &&
            calibratorAdjuster(
              label === "Offset" ? offsetX.toFixed(0) : scaleX,
              true,
              label !== "Offset"
            )}
        </Grid>
        <Grid item xs={1}>
          {" "}
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            paddingTop: "8px",
            paddingLeft: "2px",
            paddingRight: "2px",
          }}
        >
          {canAdjust &&
            calibratorAdjuster(
              label === "Offset" ? offsetY.toFixed(0) : scaleY,
              false,
              label !== "Offset"
            )}
        </Grid>
      </Grid>
    );
  };

  /** draws a calibrator tool   */
  const drawCalibratorTool = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container style={{ alignItems: "end" }}>
            <Grid item xs={3} style={{ borderBottom: `1px solid ${bhpColor.blueGrey1}` }}>
              {" "}
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                textAlign: "center",
                border: `1px solid ${bhpColor.blueGrey1}`,
                borderBottom: "none",
                fontWeight: 700,
              }}
            >
              X
            </Grid>
            <Grid item xs={1} style={{ borderBottom: `1px solid ${bhpColor.blueGrey1}` }}>
              {" "}
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                textAlign: "center",
                fontWeight: 700,
                border: `1px solid ${bhpColor.blueGrey1}`,
                borderBottom: "none",
              }}
            >
              Y
            </Grid>
            {calibratorItem("Offset", true)}
            {calibratorItem("Scale", true)}
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              padding: "4px",
              border: `1px solid ${bhpColor.blueGrey1}`,
            }}
          >
            <Grid item xs={3} style={{ textAlign: "start", paddingLeft: "12px" }}>
              Radius
            </Grid>
            <Grid item xs={9} style={{ display: "flex", alignItems: "center" }}>
              <Slider
                value={dotRadius}
                style={{ color: bhpColor.green1, marginLeft: "12px", width: "75%" }}
                min={5}
                step={1}
                max={30}
                onChange={handleChangeRadius}
                aria-labelledby="continuous-slider"
              />
              {dotRadius}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const resetSpecs = (newSpecs: CalibrationDetail | null) => {
    if (!newSpecs) return;
    setCalibratorStateValues(newSpecs);
    setSpecsChanged(true);
  };

  const drawSpecs = (focussedSpecs: CalibrationDetail | null) => {
    return <Box>{focussedSpecs ? JSON.stringify(focussedSpecs) : "-NA-"}</Box>;
  };

  /** Build the building/floor selector
   * @returns building dropdown & floorchooser
   */
  const drawBuildingSelector = () => {
    return (
      <Paper elevation={0} className={`${classes.paper} version2`}>
        <Grid container>
          <Grid item xs={2} className={classes.alignStartCentered}>
            <Box className={classes.label}>{t("Location")}</Box>
          </Grid>
          <Grid item xs={10}>
            <SettingsSelectBox
              t={t}
              cssClass={classes.newBookingSelect}
              phText={t("Choose your office...")}
              fontSize={smAndUp ? undefined : "14px"}
              phValue={-1}
              theItems={store.buildingStore.getCapacityList(t)}
              handleChange={handleBuildingChange}
              defaultValue={currentBuildingId}
              theId={"location"}
              theKey={"locationKey"}
              showPlaceholder={false}
            />
            {!store.buildingStore.isLoadingCapacity &&
              getExceededCapacityMessage(store.buildingStore.currentBuilding)}
          </Grid>
          <Grid item xs={2} className={classes.alignStartCentered}>
            {/* floors */}
            <Box paddingTop={2} className={classes.label}>
              {t("Floor")}
            </Box>
          </Grid>
          <Grid item xs={10} style={{ display: "flex", justifyContent: "center" }}>
            <Box paddingTop={2}>
              {store.buildingStore.currentBuilding ? (
                <FloorChooser
                  onFloorChange={handleFloorChange}
                  floorId={currentFloorId}
                  building={store.buildingStore.currentBuilding}
                  theDate={theDate}
                />
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const onImgLoad = (img: any) => {
    const imgSource = img.target;
    const pixelWidth = imgSource.offsetWidth;
    const pixelHeight = imgSource.offsetHeight;
    const yRatio = Number((pixelHeight / pixelWidth).toFixed(4));
    setRatioY(yRatio);
  };

  const [isFeatures, setIsFeatures] = useState(false);

  const [saveBuildingConfirmDialog, setSaveBuildingConfirmDialog] = useState(false);
  const [saveFloorConfirmDialog, setSaveFloorConfirmDialog] = useState(false);

  /** Save the calibration information to the database
   * @param where - floor | building
   */
  const saveCalibration = (where: string) => {
    switch (where) {
      case "floor":
        //save to floor
        if (currentFloorId) {
          store.buildingStore.updateFloorCalibration(currentFloorId, activeSpecs).then(() => {
            if (currentBuildingId) {
              store.buildingStore
                .loadSpaces(currentBuildingId, currentFloorId, theDate, true)
                .then(() => {
                  if (store.buildingStore.floorSpaces[currentFloorId])
                    setDistinctTypes(
                      getDistinctTypes(
                        store.buildingStore.floorSpaces[currentFloorId].concat(
                          store.buildingStore.floorEnhancedSpaces[currentFloorId]
                        )
                      )
                    );
                });
              store.buildingStore.loadBuildingCapacity(
                currentBuildingId,
                moment(new Date()).format("YYYY-MM-DD")
              );
            }
            setResetingTool(true);
            setDbFloorSpecs(fromFloorplanSpecs(activeSpecs));
            resetSpecs(activeSpecs);
            setRefreshDrawing(!refreshDrawing);
          });
        }
        break;
      case "building":
        //save to building
        if (currentBuildingId) {
          store.buildingStore.updateBuildingCalibration(currentBuildingId, activeSpecs);
          store.buildingStore
            .loadBuildingCapacity(currentBuildingId, moment(new Date()).format("YYYY-MM-DD"))
            .then((bld) => {
              console.log("here: ", toJS(bld));
              if (bld && bld[0].calibration_json) {
                const buildingCalibration = fromFloorplanSpecs(bld[0].calibration_json);
                if (buildingCalibration) setDbBuildingSpecs(buildingCalibration);
              }
            });
        }
        break;
    }
  };

  const changeDisplay = (event) => {
    setIsFeatures(event.target.checked);
  };

  const [distinctTypes, setDistinctTypes] = useState<number[]>([]);
  const [shownTypes, setShownTypes] = useState<number[]>([]);

  /** Get an array of typeId from the given space array
   * @param spaces - array of spaces
   * @returns number[] containing distinct space_type.id
   */
  const getDistinctTypes = (spaces: Space[]) => {
    let types: number[] = [];
    if (spaces && spaces.length > 0)
      spaces.forEach((spc) => {
        if (spc && spc.space_type && spc.space_type.id && !types.includes(spc.space_type.id))
          types.push(spc.space_type.id);
      });
    return types;
  };

  const [expandedTypes, setExpandedTypes] = useState<number[]>([1]);
  /** Helper to add/remove this panel from the expandedTypes
   * @param typId space_type.id to add/remove to list
   */
  const expandGroup = (typId: number) => {
    const typIdPos = expandedTypes.indexOf(typId);
    if (typIdPos == -1) setExpandedTypes(expandedTypes.concat([typId]));
    else setExpandedTypes(expandedTypes.filter((typ) => typ != typId));
  };

  const [selectedType, setSelectedType] = useState<number | null>(16);

  const [clickedSpace, setClickedSpace] = useState<Space | null>(null);

  /** sets the selectedType and selectedItem for placement
   * @param item space item to place */
  const chosenItem = (item: EnhancedSpace | null) => {
    if (item) {
      setSelectedType(selectedType === item.id ? null : item.id);
      setSelectedItem(item);
    }
  };
  const [selectedItem, setSelectedItem] = useState<EnhancedSpace | null>(getSpaceExtension(16));
  const [refreshSpacesDrawing, setRefreshSpacesDrawing] = useState(false);

  /** box placement tr | tl | br | bl */
  const [boxPlacement, setBoxPlacement] = useState<string>("tr");

  const [clickPosition, setClickPosition] = useState<{ x: number; y: number }>({ x: 50, y: 50 });
  const box = { height: 150, width: 350 };
  const icon = { height: 30, width: 30 };

  const redrawSpaces = () => {
    setRefreshSpacesDrawing(!refreshSpacesDrawing);
  };

  const mouseClick = (pos: { x: number; y: number }, spc: Space | null) => {
    console.log("click in ct: ", pos, toJS(spc));
    console.log("spc: ", toJS(spc));

    let boxSide = "r"; // default box position with icon bottom left, and modal top right of click
    let boxVertical = "t";

    let xpos = pos.x;
    if (pos.x + box.width > canvasWidth) {
      boxSide = "l";
      xpos = pos.x - box.width;
    }

    let ypos = pos.y - box.height;
    if (pos.y < box.height) {
      boxVertical = "b";
      ypos = pos.y;
    }
    setBoxPlacement(`${boxVertical}${boxSide}`);
    setClickPosition({
      x: xpos,
      y: ypos,
    });

    if (spc) resetMove();

    if (isMovingIcon) {
      // set the coordinates of the space
      console.log("moved: ", modalData.id, { x: xpos, y: ypos });
      const newExtendedSpaces = extendedSpaces.map((spc) => {
        if (currentFloorId && spc.id === modalData.id)
          store.buildingStore.moveSpace(spc, { x: pos.x, y: pos.y });

        return spc.id == modalData.id
          ? { ...spc, corrected_coordinates_x: pos.x, corrected_coordinates_y: pos.y }
          : { ...spc };
      });
      setExtendedSpaces(newExtendedSpaces);

      setModalData({ ...modalData, coordinates: [{ x: pos.x, y: pos.y }] });
      resetMove();
      redrawSpaces();
    } else {
      if (spc && clickedSpace !== spc) resetMove();
      setClickedSpace(spc);
      // if clicked on a space, set the name & description to the element
      let spaceType = { id: 0, name: "", description: "" };
      let id = 0;
      if (spc) {
        setSpaceName(spc.name);
        const desc = get(spc.space_type, "description", "");
        setSpaceDescription(desc);
        setModalOpen(true);
        chosenItem(getSpaceTypeDetail(spc.space_type?.id));
        spaceType = { ...spaceType, ...spc.space_type };
        id = spc.id;
      } else {
        // if no space AND !formDirty set name & space to that of item being placed
        if (selectedType) {
          const thisItem = getSpaceExtension(selectedType);
          console.log("st: ", selectedType, thisItem);
          setModalOpen(true);
          setSelectedItem(thisItem);
          setSpaceName(thisItem?.name || "");
          setSpaceDescription("");
          spaceType.id =
            selectedItem && selectedItem.typeId.length > 0 ? selectedItem?.typeId[0] : 0;
          spaceType.name = thisItem?.name || "";
        }
      }
      setModalData({
        ...modalData,
        id,
        space_type: spaceType,
        coordinates: [{ x: pos.x - OtherTypeDefaultConfiguration.size / 2, y: pos.y }],
      });
    }
  };

  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    resetMove();
    setFormDirty(false);
    setModalOpen(false);
  };

  const removeItemClicked = () => {
    setDeleteEnhancedFeature(true);
    resetMove();
    console.log("remove item");
  };

  const removeFeature = () => {
    console.log("removing: ", modalData);
    store.buildingStore.deleteSpace(getSpaceFromModal()).then((res) => {
      console.log("res: ", res);
      if (res) setExtendedSpaces(extendedSpaces.filter((spc) => spc.id !== modalData.id));
    });
    setDeleteEnhancedFeature(false);
    setModalOpen(false);
  };

  const [isMovingIcon, setIsMovingIcon] = useState<boolean>(false);

  const resetMove = () => {
    setCurrentCursor("default");
    setIsMovingIcon(false);
  };

  const moveItemClicked = () => {
    console.log("move item");
    setCurrentCursor("move");
    setIsMovingIcon(true);
  };

  const saveItem = () => {
    resetMove();
    setModalOpen(false);
    if (currentFloorId) {
      const spaceFromModal = getSpaceFromModal();
      store.buildingStore.updateSpace(spaceFromModal, currentFloorId).then((res) => {
        if (res) {
          if (spaceFromModal.id === 0) setExtendedSpaces(extendedSpaces.concat(res));
          else
            setExtendedSpaces(
              extendedSpaces.map((spc) =>
                spc.id == spaceFromModal.id ? { ...spaceFromModal } : { ...spc }
              )
            );
        }
      });
    }
    console.log("save item clicked");
    console.log("a:", modalData);
  };

  const getSpaceFromModal = () => {
    return {
      id: modalData.id,
      name: modalData.name,
      coordinates: modalData.coordinates[0],
      corrected_coordinates_x: modalData.coordinates[0].x,
      corrected_coordinates_y: modalData.coordinates[0].y,
      space_type: { ...modalData.space_type },
    };
  };

  const [modalData, setModalData] = useState<ModalData>({
    id: 0,
    name: "",
    description: "",
    coordinates: [],
  });

  const updateModal = () => {
    setModalData({
      ...modalData,
      name: spaceName,
      description: spaceDescription,
      space_type: { ...modalData.space_type, description: spaceDescription },
    });
  };

  const [formDirty, setFormDirty] = useState(false);
  const [spaceName, setSpaceName] = useState<string>("");
  const [spaceDescription, setSpaceDescription] = useState<string>("");
  const [spaceCoordinates, setSpaceCoordinates] = useState<{ x: number; y: number }[]>([]);
  const setName = (evt: any) => {
    const newName = evt.target.value;
    setSpaceName(newName);
    setFormDirty(true);
  };
  const setDescription = (evt: any) => {
    const newDesc = evt.target.value;
    setSpaceDescription(newDesc);
    setFormDirty(true);
  };

  useEffect(() => {
    updateModal();
  }, [spaceName, spaceDescription]);

  const drawModalContent = (spc: Space | null) => {
    const spacing = "8px";
    return (
      <FormControl className={localStyles.form}>
        <Grid container style={{ display: "flex" }}>
          <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
            Name:
          </Grid>
          <Grid item xs={8} style={{ display: "flex", alignItems: "center" }}>
            <TextField
              size="small"
              id="space-name"
              variant="outlined"
              value={spaceName}
              onChange={(evt) => setName(evt)}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              alignItems: "start",
              marginTop: "3px",
              marginBottom: spacing,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                Description:
              </Grid>
              {clickedSpace ? (
                <Grid item xs={12} style={{ display: "flex", marginTop: spacing }}>
                  <Grid item xs={6}>
                    <ZoomOutMap
                      onClick={() => moveItemClicked()}
                      style={{ color: bhpColor.blueGrey1, cursor: "pointer" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Delete
                      onClick={() => removeItemClicked()}
                      style={{ color: bhpColor.availabilityRed, cursor: "pointer" }}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "3px",
              marginBottom: spacing,
            }}
          >
            <TextField
              multiline
              rows={2}
              size="small"
              id="space-desc"
              variant="outlined"
              placeholder="Additional information"
              onChange={(evt) => setDescription(evt)}
              value={spaceDescription}
            />
          </Grid>
          {clickedSpace ? (
            <Grid container>
              <Grid item xs={6} style={{ display: "flex", justifyContent: "start" }}>
                <Button size="small" variant="contained" color="primary" onClick={() => saveItem()}>
                  UPDATE
                </Button>
              </Grid>
              <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => closeModal()}
                  color="secondary"
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={6} style={{ display: "flex", justifyContent: "start" }}>
                <Button size="small" variant="contained" color="primary" onClick={() => saveItem()}>
                  STORE
                </Button>
              </Grid>
              <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => closeModal()}
                  color="secondary"
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </FormControl>
    );
  };

  const drawContainer = (
    loc: { x: number; y: number },
    pos: string,
    theItem: EnhancedSpace | null
  ) => {
    switch (pos) {
      case "tr":
        return (
          <Grid item xs={12} style={{ display: "flex" }}>
            <div
              style={{
                color: theItem
                  ? theItem.color || OtherTypeDefaultConfiguration.color
                  : OtherTypeDefaultConfiguration.color,
              }}
            >
              <Box
                className="iconWrapper"
                style={{
                  marginLeft: `-${OtherTypeDefaultConfiguration.size / 2}px`,
                  position: "absolute",
                  top: `${box.height}px`,
                  left: `-3px`,
                }}
              >
                {theItem && !clickedSpace
                  ? drawIcon(1, 1, theItem, null, {
                      ...theItem,
                      coordinates: loc,
                    })
                  : ""}
              </Box>
            </div>
            <div className={localStyles.modal} style={{ minHeight: box.height }}>
              {drawModalContent(clickedSpace)}
            </div>
          </Grid>
        );
      case "tl":
        return (
          <Grid item xs={12} style={{ display: "flex" }}>
            <div className={localStyles.modal} style={{ minHeight: box.height }}>
              {drawModalContent(clickedSpace)}
            </div>
            <div
              style={{
                color: theItem
                  ? theItem.color || OtherTypeDefaultConfiguration.color
                  : OtherTypeDefaultConfiguration.color,
              }}
            >
              <Box
                className="iconWrapper"
                style={{
                  marginLeft: `-${OtherTypeDefaultConfiguration.size / 2}px`,
                  position: "absolute",
                  top: `${box.height}px`,
                  left: `${box.width}px`,
                }}
              >
                {theItem && !clickedSpace
                  ? drawIcon(1, 1, theItem, null, {
                      ...theItem,
                      coordinates: loc,
                    })
                  : ""}
              </Box>
            </div>
          </Grid>
        );
      case "bl":
        return (
          <Grid item xs={12} style={{ display: "flex" }}>
            <div className={localStyles.modal} style={{ minHeight: box.height }}>
              {drawModalContent(clickedSpace)}
            </div>
            <div
              style={{
                color: theItem
                  ? theItem.color || OtherTypeDefaultConfiguration.color
                  : OtherTypeDefaultConfiguration.color,
              }}
            >
              <Box
                className="iconWrapper"
                style={{
                  marginLeft: `-${OtherTypeDefaultConfiguration.size / 2}px`,
                  position: "absolute",
                  top: `0px`,
                  left: `${box.width}px`,
                }}
              >
                {theItem && !clickedSpace
                  ? drawIcon(1, 1, theItem, null, {
                      ...theItem,
                      coordinates: loc,
                    })
                  : ""}
              </Box>
            </div>
          </Grid>
        );
      case "br":
        return (
          <Grid item xs={12} style={{ display: "flex" }}>
            <div
              style={{
                color: theItem
                  ? theItem.color || OtherTypeDefaultConfiguration.color
                  : OtherTypeDefaultConfiguration.color,
              }}
            >
              <Box
                className="iconWrapper"
                style={{
                  marginLeft: `-${OtherTypeDefaultConfiguration.size / 2}px`,
                  position: "absolute",
                  top: `0px`,
                  left: `-3px`,
                }}
              >
                {theItem && !clickedSpace
                  ? drawIcon(1, 1, theItem, null, {
                      ...theItem,
                      coordinates: loc,
                    })
                  : ""}
              </Box>
            </div>
            <div className={localStyles.modal} style={{ minHeight: box.height }}>
              {drawModalContent(clickedSpace)}
            </div>
          </Grid>
        );
    }
  };

  const [extendedSpaces, setExtendedSpaces] = useState<Space[]>([]);
  const [currentCursor, setCurrentCursor] = useState("default");
  const [deleteEnhancedFeature, setDeleteEnhancedFeature] = useState(false);

  const addRemoveType = (evt: any, typ: number) => {
    const currentlySelected = shownTypes.includes(typ);
    let newSelection: number[] = [];
    if (currentlySelected) newSelection = shownTypes.filter((sTyp) => sTyp !== typ);
    else newSelection = [...shownTypes, typ];
    setShownTypes(newSelection);
  };

  useEffect(() => {
    const allTypes = getDistinctTypes(
      currentFloorId
        ? store.buildingStore.floorSpaces[currentFloorId]
          ? store.buildingStore.floorSpaces[currentFloorId].concat(extendedSpaces)
          : extendedSpaces
        : extendedSpaces
    );
    const previouslyHiddenTypes = distinctTypes.filter((tp) => !shownTypes.includes(tp));
    setDistinctTypes(allTypes);
    setShownTypes(
      allTypes.filter((tp) => !previouslyHiddenTypes.includes(tp)).sort((a, b) => (a > b ? 1 : -1))
    );
  }, [extendedSpaces]);

  return (
    <>
      <Topbar
        title={t("Calibration Tool")}
        subtitle={t("Scale and offset the floorplan")}
        breadcrumb={t("Calibrator")}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          paddingLeft: "32px",
          marginBottom: "-30px",
        }}
      >
        <div style={{ width: "25%" }}>
          <Grid container style={{ alignItems: "center", fontSize: "small" }}>
            <Grid item xs={4}>
              Features
            </Grid>
            <Grid item xs={4}>
              <Switch size="small" checked={isFeatures} onChange={changeDisplay}></Switch>
            </Grid>
            <Grid item xs={4}>
              Calibrator
            </Grid>
          </Grid>
        </div>
      </div>
      {store.buildingStore.isLoadingCapacity && (
        <Box py={{ xs: 2, md: 4 }} px={{ xs: 0, md: 4 }} className={classes.root}>
          Loading building details <CircularProgress variant="indeterminate" size={14} />
        </Box>
      )}
      <>
        {store.buildingStore.isOffice(currentBuildingId) && (
          <Box
            py={{ xs: 2, md: 4 }}
            px={{ xs: 0, md: 4 }}
            className={classes.root}
            style={{ fontSize: "small" }}
          >
            <Grid container spacing={4}>
              <Grid item md={12} lg={6}>
                {drawBuildingSelector()}
                <Box style={{ paddingTop: "25px" }}>
                  <Paper style={{ padding: "12px", maxHeight: "50px" }}>
                    {isFeatures ? (
                      <Grid container style={{ display: "flex", alignItems: "center" }}>
                        <Grid item xs={3}>
                          Key:
                        </Grid>
                        <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundColor: `${bhpColor.available}`,
                              borderRadius: "50%",
                              height: "20px",
                              width: "20px",
                              display: "inline-flex",
                              marginRight: "12px",
                            }}
                          ></div>
                          Workday Point
                        </Grid>
                        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              border: `2px solid ${bhpColor.focussed}`,
                              borderRadius: "50%",
                              height: "20px",
                              width: "20px",
                              display: "inline-flex",
                              marginRight: "12px",
                            }}
                          ></div>
                          Raw Point
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={2}>
                          Features:
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          style={{
                            zIndex: 1000,
                            position: "relative",
                            backgroundColor: currentFloorId && showLayers ? "#FFFFFF" : "",
                            borderRadius: "4px",
                            boxShadow:
                              currentFloorId && showLayers
                                ? `5px 10px ${bhpColor.blueGrey1}65`
                                : "",
                          }}
                        >
                          {showLayers ? (
                            distinctTypes.map((typ) => {
                              const spaceDetail = getSpaceTypeDetail(typ);
                              return (
                                <Grid
                                  container
                                  style={{
                                    border: "1px solid #cacaca",
                                    margin: "2px",
                                    alignItems: "center",
                                  }}
                                >
                                  <Grid item xs={2}>
                                    <Checkbox
                                      size="small"
                                      checked={shownTypes.includes(typ)}
                                      onClick={(evt) => addRemoveType(evt, typ)}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    {spaceDetail.name}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    style={{
                                      color: spaceDetail.color,
                                      justifyContent: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {" "}
                                    {deskTypeIds.includes(typ) ? (
                                      <div
                                        className="dot"
                                        style={{ backgroundColor: bhpColor.available }}
                                      ></div>
                                    ) : (
                                      spaceDetail.icon
                                    )}
                                  </Grid>
                                </Grid>
                              );
                            })
                          ) : (
                            <div>{showVisibleLayerNames(0, 30)}</div>
                          )}
                        </Grid>
                        <Grid
                          style={{ display: "flex", justifyContent: "end", alignItems: "start" }}
                        >
                          <Tooltip title="Show the list of features on this floor">
                            <Box onClick={toggleLayers}>
                              {showLayers ? (
                                <ExpandLess />
                              ) : (
                                <LayersIcon style={{ color: bhpColor.blueGrey1 }} />
                              )}
                            </Box>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                </Box>
              </Grid>
              <Grid item md={12} lg={6}>
                <Paper
                  style={{
                    padding: "12px",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {isFeatures ? (
                    <Box style={{ width: "80%" }}>
                      {drawCalibratorTool()}
                      <Grid item xs={12}>
                        <Grid
                          container
                          style={{
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            paddingLeft: "6px",
                            border: `1px solid ${bhpColor.blueGrey1}`,
                            borderTop: "none",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12}>
                            <Accordion elevation={0}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                style={{ height: "24px", minHeight: "24px", paddingLeft: "10px" }}
                              >
                                Active Calibration:
                              </AccordionSummary>
                              <AccordionDetails style={{ whiteSpace: "pre-wrap" }}>
                                {JSON.stringify(activeSpecs)}
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: "start", paddingLeft: "10px" }}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => setSaveBuildingConfirmDialog(true)}
                              style={{
                                textTransform: "uppercase",
                                whiteSpace: "nowrap",
                                height: "25px",
                              }}
                            >
                              save to building
                            </Button>
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: "end", paddingRight: "10px" }}>
                            <Button
                              onClick={() => setSaveFloorConfirmDialog(true)}
                              variant="outlined"
                              color="secondary"
                              style={{
                                textTransform: "uppercase",
                                whiteSpace: "nowrap",
                                height: "25px",
                              }}
                            >
                              save to floor
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: "6px",
                          border: `1px solid ${bhpColor.blueGrey1}`,
                          borderTop: "none",
                          alignItems: "center",
                        }}
                      >
                        <Accordion elevation={0}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{ height: "24px", minHeight: "24px", paddingLeft: "10px" }}
                          >
                            Stored Calibration Details
                          </AccordionSummary>
                          <AccordionDetails style={{ whiteSpace: "pre-wrap" }}>
                            <Grid container>
                              <Grid item xs={8}>
                                <span style={{ fontWeight: 700 }}>Building calibration:</span>
                                {drawSpecs(dbBuildingSpecs)}
                              </Grid>
                              <Grid item xs={4} style={{ textAlign: "end" }}>
                                {dbBuildingSpecs && (
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ height: "20px", textTransform: "uppercase" }}
                                    onClick={() => resetSpecs(dbBuildingSpecs)}
                                  >
                                    use
                                  </Button>
                                )}
                              </Grid>

                              <Grid item xs={8} style={{ paddingTop: "8px" }}>
                                <span style={{ fontWeight: 700 }}>Floor calibration:</span>
                                {drawSpecs(dbFloorSpecs)}
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "end",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  style={{ height: "20px", textTransform: "uppercase" }}
                                  onClick={() => resetSpecs(dbFloorSpecs)}
                                >
                                  use
                                </Button>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Box>
                  ) : (
                    <Grid container>
                      <Grid item xs={3}>
                        {selectedType ? "Placing Feature:" : "Choose a Feature:"}
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        style={{ fontWeight: 700, cursor: "pointer" }}
                        onClick={() => chosenItem(getSpaceExtension(selectedType))}
                      >
                        {selectedType ? (
                          <Tooltip
                            title={`click to stop placing ${getSpaceExtension(selectedType)?.name}`}
                          >
                            <div>{getSpaceExtension(selectedType)?.name || "Unknown"}</div>
                          </Tooltip>
                        ) : (
                          "Click a feature then click on the plan"
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          color:
                            getSpaceExtension(selectedType)?.color ||
                            OtherTypeDefaultConfiguration.color,
                        }}
                      >
                        {selectedType && getSpaceExtension(selectedType)?.icon}
                      </Grid>
                      <Grid item xs={12} style={{ height: "185px", overflowY: "scroll" }}>
                        {spaceExtension
                          .filter((par) => par.parentId === null)
                          .map((typ) => {
                            return (
                              <Grid container style={{ alignItems: "center", cursor: "pointer" }}>
                                <Grid
                                  item
                                  xs={1}
                                  style={{ color: bhpColor.blueGrey1 }}
                                  onClick={() => expandGroup(typ.id)}
                                >
                                  {expandedTypes.includes(typ.id) ? (
                                    <ArrowDropUpTwoToneIcon />
                                  ) : (
                                    <ArrowRightTwoToneIcon />
                                  )}
                                </Grid>
                                <Grid item xs={4} onClick={() => expandGroup(typ.id)}>
                                  {typ.name}
                                </Grid>
                                <Grid item xs={4} onClick={() => expandGroup(typ.id)}>
                                  {typ.typeId.join(", ")}
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    color: typ.color || OtherTypeDefaultConfiguration.color,
                                  }}
                                  onClick={() => expandGroup(typ.id)}
                                >
                                  {typ.icon}
                                </Grid>
                                {expandedTypes.includes(typ.id || 0) &&
                                  spaceExtension
                                    .filter((par) => par.parentId === typ.id)
                                    .map((etyp) => {
                                      return (
                                        <Tooltip
                                          title={
                                            selectedType === etyp.id
                                              ? `click to stop placing ${etyp.name}`
                                              : `click to place ${etyp.name}`
                                          }
                                        >
                                          <Grid
                                            container
                                            onClick={() => chosenItem(etyp)}
                                            style={{ cursor: "pointer" }}
                                            className={
                                              selectedType === etyp.id
                                                ? localStyles.selectedRow
                                                : undefined
                                            }
                                          >
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={4}>
                                              {etyp.name}
                                            </Grid>
                                            <Grid item xs={3}>
                                              {/* {etyp.typeId.join(", ")} */}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={3}
                                              style={{
                                                color:
                                                  typ.color || OtherTypeDefaultConfiguration.color,
                                              }}
                                            >
                                              {etyp.icon}
                                            </Grid>
                                          </Grid>
                                        </Tooltip>
                                      );
                                    })}
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Grid>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  className={`${classes.paper} booking version2`}
                  style={{ alignItems: "center", minHeight: 1120 }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      {currentBuilding ? (
                        <>
                          <ShowBuildingSummary t={t} building={currentBuilding} />
                          {store.buildingStore.isLoadingSpacesForThisFloor(currentFloorId) && (
                            <Box>
                              {`Loading spaces `}
                              <CircularProgress size={14} color="secondary" />
                            </Box>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    ></Grid>
                  </Grid>

                  {store.buildingStore.currentFloor ? (
                    isFeatures ? (
                      <Calibrator
                        floorId={store.buildingStore.currentFloorId}
                        date={theDate}
                        buildingId={store.buildingStore.currentBuilding?.id}
                        scales={toFloorplanSpecs(activeSpecs)}
                        doClick={doClick}
                        trapMove={trapMove}
                        displayCorrected={refreshDrawing}
                        yRatio={ratioY}
                        hiddenTypeIds={distinctTypes.filter((tp) => !shownTypes.includes(tp))}
                      />
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          cursor: currentCursor,
                          width: 1024,
                          height: 1024,
                        }}
                      >
                        <div
                          className="modalWrapper"
                          style={{
                            position: "absolute",
                            top: clickPosition.y + esOffset.y,
                            left: clickPosition.x + esOffset.x,
                            width: box.width,
                            fontSize: "15px",
                            padding: 10,
                            marginBottom: box.height * -1,
                            zIndex: "15",
                          }}
                        >
                          <Grid container>
                            {modalOpen && drawContainer(clickPosition, boxPlacement, selectedItem)}
                          </Grid>
                        </div>

                        <EnhancedSpaces
                          floorId={store.buildingStore.currentFloorId}
                          date={theDate}
                          buildingId={store.buildingStore.currentBuilding?.id}
                          passBack={mouseClick}
                          refresh={refreshSpacesDrawing}
                          floorSpaces={extendedSpaces}
                          hiddenTypeIds={distinctTypes.filter((tp) => !shownTypes.includes(tp))}
                        />
                      </div>
                    )
                  ) : (
                    <Box>No floor</Box>
                  )}
                </Paper>
              </Grid>
            </Grid>
            <ConfirmDialog
              title={t("Delete Enhanced Feature?")}
              primaryButtonText={t("Delete")}
              secondaryButtonText={t("Cancel")}
              handleSecondaryClick={() => setDeleteEnhancedFeature(false)}
              handlePrimaryClick={() => {
                removeFeature();
                setDeleteEnhancedFeature(false);
              }}
              isOpen={deleteEnhancedFeature}
            >
              <Box style={{ display: "flex", whiteSpace: "pre-line" }}>
                {`Are you sure you want to delete this enahnced feature?
                  Name: ${modalData.name}
                  Description: ${modalData.description}
                  `}
              </Box>
            </ConfirmDialog>
            <ConfirmDialog
              title={t("Save Calibration To Floor?")}
              primaryButtonText={t("Store")}
              secondaryButtonText={t("Cancel")}
              handleSecondaryClick={() => setSaveFloorConfirmDialog(false)}
              handlePrimaryClick={() => {
                saveCalibration("floor");
                setSaveFloorConfirmDialog(false);
              }}
              isOpen={saveFloorConfirmDialog}
            >
              <Box style={{ display: "flex", whiteSpace: "pre-line" }}>
                {`Are you sure you want to save this calibration data to the floor?

This will set the local coordinates of the spaces.`}
              </Box>
            </ConfirmDialog>
            <ConfirmDialog
              title={t("Save Calibration To Building?")}
              primaryButtonText={t("Store")}
              secondaryButtonText={t("Cancel")}
              handleSecondaryClick={() => setSaveBuildingConfirmDialog(false)}
              handlePrimaryClick={() => {
                saveCalibration("building");
                setSaveBuildingConfirmDialog(false);
              }}
              isOpen={saveBuildingConfirmDialog}
            >
              <Box style={{ display: "flex", whiteSpace: "pre-line" }}>
                {`Are you sure you want to save this calibration data to the building?

This does not write over floors with specific calibration data.
Nor does it set any coordinates of spaces.`}
              </Box>
            </ConfirmDialog>
          </Box>
        )}
      </>
      {currentFloorId &&
        store.buildingStore.currentBuilding?.floors?.find(
          (flr) => `${flr.id}` === `${currentFloorId}`
        ) && (
          <img
            src={currentFloorId && floorImage}
            width={canvasWidth}
            onLoad={onImgLoad}
            style={{ position: "absolute", left: -5000 }}
          />
        )}
    </>
  );
});

export default CalibrationTool;
