export const displaySettings = {
  snackDelay: 3000,
};

// brought from the colours specified in Figma
export const bhpColor = {
  orange1: "#E65400",
  orange2: "#F77929",
  orange3: "#FAB636",
  orange4: "#F8D595",
  purple1: "#761F6A",
  purple2: "#9F2A95",
  purple3: "#D04C95",
  purple4: "#E8C8E0",
  blue1: "#224483",
  blue2: "#1866B0",
  blue3: "#2DA2D7",
  blue4: "#ACD3F4",
  turquoise1: "#0B637B",
  turquiose2: "#0C8FA5",
  turquoise3: "#52D8E5",
  turquoise4: "#ABE8E3",
  green1: "#018882",
  green2: "#01AA89",
  green3: "#B3DE68",
  green4: "#E1F199",
  blueGrey1: "#466371",
  blueGrey2: "#5C879C",
  blueGrey3: "#90B0BF",
  blueGrey4: "#D7DFE2",
  grey1: "#50524D",
  grey2: "#84898F",
  grey3: "#BCBCBA",
  grey4: "#E6E8D3",
  black: "#252525",
  webGrey: "#F2F0E9",
  white: "#FFFFFF",
  lightGrey: "#E0E0E0",
  backgroundGrey: "#F6F6F6",
  red: "#d50000",
  team: "#E65400",
  firstAid: "#66AA72",
  fireWarden: "#E60000",
  available: "#66AA72",
  unavailable: "#50524D",
  partly_available: "#0099CC",
  focussed: "#FAB636",
  availabilityAmber: "#DE9734",
  availabilityRed: "#D74744",
};

export const settings = {
  momentDateFormat: "YYYY-MM-DD",
  fontSize: 16,
  font: { smallest: 16 },
  tableHeaderHeight: 72,
  mobileWidth: 456,
  modalDefaultWidth: 456,
  modalDefaultHeight: 300,
  dashboardSingleColumn: 520,
  borderRadius: 4,
  borderWidth: 2,
  outerPadding: 16,
  weekday: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
};
