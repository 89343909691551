import { createStyles, Grid, makeStyles, Theme, Hidden } from "@material-ui/core";
import { BuildingWithCapacity } from "interfaces/Building";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useRootStore } from "providers/RootStoreProvider";
import { useEffect, useState } from "react";
import { bhpColor } from "styles/globals";
import { BasicBooking, getDateTimeAtStartOfToday, groupByHour } from "utils/hourlyBookings";
import { drawDot, getIndicatorColor, getPercentage } from "../utils/CapacityUtils";

interface CapacityHeadSummaryProps {
  buildingDetail: BuildingWithCapacity | null;
  date: Date;
  t: any;
}

const tightLineHeight = "16px";
const normalHeight = "14px";
const mediumHeight = "20px";
const largeHeight = "30px";
const isBold = 700;

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    label: {
      fontSize: normalHeight,
      fontWeight: isBold,
      color: bhpColor.blueGrey1,
      "&.grey1": {
        color: bhpColor.grey1,
      },
      "&.grey2": {
        color: bhpColor.grey2,
      },
      "&.grey3": {
        color: bhpColor.grey3,
      },
      "&.orange1": {
        color: bhpColor.orange1,
      },
      "&.orange3": {
        color: bhpColor.orange3,
      },
      "&.size20": {
        fontSize: mediumHeight,
      },
      "&.size30": {
        fontSize: largeHeight,
      },
      "&.nowrap": {
        whiteSpace: "nowrap",
      },
    },
  })
);

const CapacityHeadSummary = observer((props: CapacityHeadSummaryProps) => {
  const { buildingDetail, date, t } = props;
  const classes = innerStyles();
  const store = useRootStore();

  const bookableDesks = buildingDetail
    ? buildingDetail?.adjusted_capacity || buildingDetail.total_capacity || null
    : null;

  const indicatorColor = getIndicatorColor(
    bookableDesks ? getPercentage(bookableDesks, buildingDetail?.booked_attendance || 0) : 0
  );

  const [hourGroupings, setHourGroupings] = useState<{ [hr: string]: BasicBooking[] }>({});
  const [startIndex, setStartIndex] = useState<number>(7);
  const [endIndex, setEndIndex] = useState<number>(19);
  const [dayMax, setDayMax] = useState<number | null>(null);
  const [dayMin, setDayMin] = useState<number | null>(null);

  useEffect(() => {
    if (buildingDetail) {
      const startOfToday = getDateTimeAtStartOfToday(date);
      let endOfToday = new Date(startOfToday);
      endOfToday.setDate(endOfToday.getDate() + 1);
      store.bookingStore
        .loadBuildingBookings(
          { start: startOfToday, end: endOfToday },
          buildingDetail.id.toString()
        )
        .then(() => {
          const theBookings = store.bookingStore.building_bookings[buildingDetail.id.toString()];
          if (theBookings) {
            const groupedBookings = groupByHour(date, theBookings);
            let minBookings = 100000;
            let maxBookings = 0;
            setHourGroupings(groupedBookings);
            Object.keys(groupedBookings).forEach((key) => {
              const bookingsThisHour = groupedBookings[key].length;
              if (bookingsThisHour < minBookings) minBookings = bookingsThisHour;
              if (bookingsThisHour > maxBookings) maxBookings = bookingsThisHour;
            });
            setDayMin(minBookings);
            setDayMax(maxBookings);
          }
        });
    }
  }, [buildingDetail, startIndex, endIndex, date]);

  return (
    <Grid container style={{ height: "100%" }}>
      <Grid item xs={5}>
        <Grid container style={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            className={`${classes.label} grey2`}
            style={{ lineHeight: tightLineHeight }}
          >
            {t(`Bookable Desks`)}:
          </Grid>
          <Grid item xs={12} className={`${classes.label} size30`}>
            {bookableDesks}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
        <div style={{ backgroundColor: bhpColor.grey2, width: "1px", height: "80%" }}></div>
      </Grid>
      <Grid item xs={6}>
        <Grid container style={{ height: "100%", fontSize: normalHeight }}>
          <Grid
            item
            xs={12}
            className={`${classes.flexCenter} ${classes.label} nowrap grey2`}
            style={{ lineHeight: tightLineHeight }}
          >
            {t(`Selected day`)}:{" "}
            <div
              style={{
                borderRadius: "8px",
                minHeight: `16px`,
                minWidth: `16px`,
                marginLeft: "4px",
                backgroundColor: indicatorColor,
              }}
            ></div>
          </Grid>
          <Grid
            item
            xs={6}
            className={`${classes.flexCenter} ${classes.label} nowrap orange1 size30`}
          >
            {dayMax}
          </Grid>
          <Grid item xs={5} className={`${classes.label} grey1`}>
            {t(`High`)}
          </Grid>
          <Hidden xsUp>
            <Grid
              item
              xs={6}
              className={`${classes.flexCenter} ${classes.label} nowrap orange3 size20`}
            >
              {dayMin}
            </Grid>
            <Grid item xs={5} className={`${classes.label} grey3`}>
              {t(`Low`)}
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default CapacityHeadSummary;
