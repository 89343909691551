import { Box, createStyles, makeStyles, Paper, Theme } from "@material-ui/core";
import Icon from "@mdi/react";
import { bhpColor, settings } from "styles/globals";

interface TeamWorkingStatusSummaryProps {
  count: number;
  iconPath: string;
  text: string;
  color?: string;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      color: bhpColor.blueGrey1,
      fontSize: "18px",
      height: 52,
    },
    iconBox: {
      width: 48,
      height: 52,
      borderRadius: settings.borderRadius,
      backgroundColor: bhpColor.blueGrey1,
      color: bhpColor.white,
      verticalAlign: "top",
      textAlign: "center",
      display: "inline-block",
    },
    icon: {
      alignSelf: "center",
      height: 20,
      color: bhpColor.white,
      marginTop: 14,
    },
    countBlock: {
      height: 45,
      width: 105,
      display: "inline-block",
    },
  })
);

const TeamWorkingStatusSummary = (props: TeamWorkingStatusSummaryProps) => {
  const { count, iconPath, text, color } = props;
  const classes = innerStyles();
  return (
    <Paper elevation={0} className={classes.paper}>
      <Box
        className={classes.iconBox}
        style={{ backgroundColor: color ? color : bhpColor.blueGrey2 }}
      >
        <Icon path={iconPath} size={1} className={classes.icon} />
      </Box>
      <Box
        paddingLeft={1}
        className={classes.countBlock}
        style={{ color: color ? color : bhpColor.blueGrey2 }}
      >
        <Box style={{ fontWeight: 700, fontSize: 20, marginBottom: -8, marginTop: 4 }}>{count}</Box>
        <Box paddingTop={1} style={{ fontSize: 10 }}>
          {text}
        </Box>
      </Box>
    </Paper>
  );
};

export default TeamWorkingStatusSummary;
