import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createStyles, makeStyles, Theme, Paper, Typography, useTheme } from "@material-ui/core";
import { getSupportLinks } from "utils/infoandsupport";
import { bhpColor } from "styles/globals";
import { useTranslation } from "react-i18next";

interface InfoAndSupportTabPanelProps {
  title: string;
  description: string;
  iFrame: any;
}

const InfoAndSupportTabPanel = observer((props: InfoAndSupportTabPanelProps) => {
  const { title, description, iFrame, ...other } = props;
  const innerStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        padding: theme.spacing(1),
      },
      hoverSupportSummaryBorder: {
        border: `2px solid ${bhpColor.blueGrey1}`,
        "&:hover": {
          border: `2px solid ${bhpColor.orange2}`,
        },
        width: "50%",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      hybridSupportTitle: {
        marginLeft: theme.spacing(1),
        fontWeight: 700,
        color: bhpColor.blueGrey1,
        marginBottom: theme.spacing(1),
      },
      hybridSupportDescription: {
        marginLeft: theme.spacing(1),
        fontSize: 16,
        color: bhpColor.grey1,
        [theme.breakpoints.down("sm")]: {
          textDecoration: "underline",
        },
      },
    })
  );
  const classes = innerStyles();

  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Box position="relative" p={mdAndUp ? 3 : 0}>
        <Box py={2}>
          <Typography style={{ fontSize: 26 }} variant="h1">
            {title}
          </Typography>
          <Box mt={1}>{description}</Box>
        </Box>
      </Box>
      <Box px={4} pt={2} pb={4}>
        <Paper
          elevation={0}
          className={`${classes.paper} ${classes.hoverSupportSummaryBorder}`}
          style={{
            minHeight: 118,
            padding: theme.spacing(2),
            cursor: "pointer",
          }}
        >
          <Box
            flexDirection="row"
            onClick={() => {
              window.open(iFrame, "_blank");
            }}
          >
            <div className={classes.hybridSupportTitle}>{title}</div>
            <div className={classes.hybridSupportDescription}>
              Click here to access Hybrid and materials
            </div>
          </Box>
        </Paper>
      </Box>
    </>
  );
});

const InfoAndSupportTabPanelWorkday = observer((props: InfoAndSupportTabPanelProps) => {
  const { title, description, iFrame, ...other } = props;
  const innerStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        padding: theme.spacing(1),
      },
      hoverSupportSummaryBorder: {
        border: `2px solid ${bhpColor.blueGrey1}`,
        "&:hover": {
          border: `2px solid ${bhpColor.orange2}`,
        },
        width: "50%",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      hybridSupportTitle: {
        marginLeft: theme.spacing(1),
        fontWeight: 700,
        color: bhpColor.blueGrey1,
        marginBottom: theme.spacing(1),
      },
      hybridSupportDescription: {
        marginLeft: theme.spacing(1),
        fontSize: 16,
        color: bhpColor.grey1,
        [theme.breakpoints.down("sm")]: {
          textDecoration: "underline",
        },
      },
    })
  );
  const classes = innerStyles();

  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Box position="relative" p={mdAndUp ? 3 : 0}>
        <Box py={2}>
          <Typography style={{ fontSize: 26 }} variant="h1">
            {title}
          </Typography>
          <Box mt={1}>{description}</Box>
        </Box>
      </Box>
      <Box px={4} pt={2} pb={4}>
        <Paper
          elevation={0}
          className={`${classes.paper} ${classes.hoverSupportSummaryBorder}`}
          style={{
            minHeight: 118,
            padding: theme.spacing(2),
            cursor: "pointer",
          }}
        >
          <Box
            flexDirection="row"
            onClick={() => {
              window.open(iFrame, "_blank");
            }}
          >
            <div className={classes.hybridSupportTitle}>{title}</div>
            <div className={classes.hybridSupportDescription}>
              Click here to access MyWorkday and materials
            </div>
          </Box>
        </Paper>
      </Box>
    </>
  );
});

interface urlParams {
  officeId: string;
  eventId: string;
  tab: string;
}

interface TabType {
  label: string;
  link: string;
  component: any;
}

const InfoAndSupportTabs = observer(() => {
  const history = useHistory();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const urlParams: urlParams = useParams();
  const [activeTab, setActiveTab] = useState(history.location.pathname);
  const { t, i18n } = useTranslation();

  const hybridWorkingTab: TabType = {
    label: t("Hybrid Working"),
    link: `hybridworking`,
    component: (
      <InfoAndSupportTabPanel
        title={"Hybrid Working"}
        description={t("Find the latest updates and support materials on how to use the platform")}
        iFrame={getSupportLinks("hybridworking")}
      />
    ),
  };

  const myWorkdayTab: TabType = {
    label: t("My Workday"),
    link: `myworkday`,
    component: (
      <InfoAndSupportTabPanelWorkday
        title={t("My Workday")}
        description={t("Find the latest updates and support materials on how to use the platform")}
        iFrame={getSupportLinks("myworkday")}
      />
    ),
  };

  let tabs: TabType[] = [hybridWorkingTab, myWorkdayTab];

  useEffect(() => {
    history.push(`/infoandsupport/${urlParams.tab || hybridWorkingTab.link}`);
    setActiveTab(urlParams.tab || hybridWorkingTab.link);
  }, []);

  const handleChange = (tabLink: string) => {
    history.push(`/infoandsupport/${tabLink}`);
    setActiveTab(tabLink);
  };

  const getTabType = (): "fullWidth" | "standard" => {
    if (smAndUp) {
      return "standard";
    } else {
      return "fullWidth";
    }
  };
  const selectedTab = tabs.find((tab) => activeTab === tab.link) || hybridWorkingTab;

  return (
    <div>
      <Tabs
        value={activeTab}
        variant={getTabType()}
        onChange={(event, value) => handleChange(value)}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map((tab) => (
          <Tab label={tab.label} key={tab.link} value={tab.link} />
        ))}
      </Tabs>
      <Paper elevation={0} style={{ backgroundColor: "white" }}>
        {selectedTab && selectedTab.component}
      </Paper>
    </div>
  );
});

export default InfoAndSupportTabs;
