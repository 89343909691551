import { observer } from "mobx-react-lite";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Popover, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { useContext, useState } from "react";
import { HolidayContext } from "Main";
import { bhpColor } from "styles/globals";
import Icon from "@mdi/react";
import { mdiAnchor } from "@mdi/js";
import { generateHolidayInformationModalContent } from "utils/date";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
  })
);

const AnchorPopover = observer(() => {
  const classes = innerStyles();

  const allHolidays = useContext(HolidayContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {
        <div
          style={{
            position: "absolute",
            top: -8,
            left: -10,
            backgroundColor: bhpColor.green1,
            borderRadius: "50%",
            height: 18,
            width: 18,
          }}
          onMouseEnter={(event) => handlePopoverOpen(event)}
          onMouseLeave={handlePopoverClose}
        >
          <Icon style={{ color: bhpColor.white }} path={mdiAnchor} size="15px" />
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            className={classes.popover}
          >
            <div
              style={{
                minHeight: 50,
                padding: 8,
                verticalAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <b>Anchor Day: </b> Designated day/s your team has selected to attend the office
              </div>
            </div>
          </Popover>
        </div>
      }
    </>
  );
});

export default AnchorPopover;
