import { makeAutoObservable, flow } from "mobx";
import { Timezone, Building } from "interfaces/Utils";
import RootStore from "./RootStore";
import client from "services/api";

export default class UtilStore {
  timezones: Timezone[] = [];
  isLoading = true;
  error = "";
  rootStore!: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  loadTimezones = flow(function* (this: UtilStore) {
    this.isLoading = true;
    this.error = "";
    try {
      const res = yield client.get("/api/timezones/");
      this.timezones = [];
      res.data.timezones.forEach((tz: string) => {
        this.timezones.push({ name: tz });
      });
      //   this.timezones.teams = res.data.results;
      //   return this.teams;
    } catch (err) {
      this.error = (err as Error).message;
    }
    this.isLoading = false;
  });
}
