import { observer } from "mobx-react-lite";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRootStore } from "providers/RootStoreProvider";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import CanvasWrapper from "components/utils/CanvasWrapper";
import { floorplanSpecs, FloorplanSpecs, Space } from "interfaces/Building";
import moment from "moment";
import { bhpColor } from "styles/globals";
import { TeamParticipant } from "interfaces/Teams";
import { deskTypeIds } from "components/booking/enhancedFloorplan/OtherTypes";

interface CalibratorProps {
  floorId?: number | null;
  floorSpaces?: Space[];
  date?: string;
  buildingId?: number;
  match?: any;
  teamMembers?: TeamParticipant[];
  scales: {
    scale: { x: number; y: number };
    offset: { x: number; y: number };
    spaceRadius?: number;
  };
  yRatio?: number;
  doClick?: any;
  trapMove?: any;
  displayCorrected?: boolean;
  hiddenTypeIds?: number[];
}

interface Scale {
  scale: { x: number; y: number };
  offset: { x: number; y: number };
}

interface DeskColour {
  r: number;
  g: number;
  b: number;
  hex: string;
}

const Calibrator = observer((props: CalibratorProps) => {
  const {
    buildingId,
    floorId,
    scales,
    doClick,
    trapMove,
    displayCorrected,
    yRatio,
    hiddenTypeIds = [],
  } = props;
  const store = useRootStore();
  const { t, i18n } = useTranslation();

  const propsFloorId: string | null = floorId ? `${floorId}` : null;
  const canvasRef = useRef(null);

  const getFloorImageModule = (floorId: string | number | null | undefined) => {
    if (!floorId) return null;
    let module;
    try {
      module = require(`assets/floorplans/sv_${floorId}.png`);
    } catch {
      return null;
    }
    return module;
  };

  const getFloorImage = (buildingId: number | null, floorId: number | null) => {
    if (!buildingId || !floorId) return "";
    const thisFloor = store.buildingStore.getFloor(buildingId, floorId);
    return thisFloor && thisFloor.floor_plan ? thisFloor.floor_plan : getFloorImageModule(floorId);
  };

  const [floorImage, setFloorImage] = useState<any>(
    getFloorImage(buildingId || null, floorId || null)
  );

  // #region Stuff for scaling
  const getScales = (floorId: string) => {
    return scales;
  };

  const theScales = getScales(`${store.buildingStore.currentFloor?.id}`);
  const spaceRadius = theScales?.spaceRadius || 10;

  const scaledX = (x: number, theScales: Scale) => x * theScales.scale.x + theScales.offset.x;
  const scaledY = (y: number, theScales: Scale) => y * theScales.scale.y + theScales.offset.y;
  const correctCoordinates = (
    spc: { x: number | null; y: number | null; id?: number },
    scales: Scale
  ) => {
    return spc.x && spc.y
      ? { x: scaledX(spc.x, scales), y: scaledY(spc.y, scales), id: spc.id }
      : { x: 0, y: 0, id: spc.id };
  };
  // #endregion

  const canvasWidth = 1024;
  const [canvasHeight, setCanvasHeight] = useState(yRatio ? canvasWidth * yRatio : canvasWidth);

  const [context, setContext] = useState();
  const theContext = (ctx: any) => setContext(ctx);

  /**
   * Draw a circle - solid or outlined
   * @param ctx
   * @param xPos
   * @param yPos
   * @param radius
   * @param lineWidth - width of outline
   * @param globalAlpha - transparency 0 see through -> 1 solid
   * @param strokeColor - if null no outline
   * @param fillColor - if null no fill
   */
  const etchCircle = (
    ctx: any,
    xPos: number,
    yPos: number,
    radius: number,
    lineWidth: number | null,
    globalAlpha: number | null,
    strokeColor: string | null,
    fillColor: string | null
  ) => {
    if (ctx) {
      if (globalAlpha) ctx.globalAlpha = globalAlpha;
      if (lineWidth) ctx.lineWidth = lineWidth;
      ctx.strokeStyle = strokeColor;
      if (strokeColor) {
        ctx.beginPath();
        ctx.moveTo(xPos + radius, yPos);
        ctx.strokeStyle = strokeColor;
        ctx.arc(xPos, yPos, radius, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.closePath();
      }
      if (fillColor) {
        ctx.beginPath();
        ctx.moveTo(xPos + radius, yPos);
        ctx.fillStyle = fillColor;
        ctx.arc(xPos, yPos, radius, 0, 2 * Math.PI);
        ctx.fill();
        ctx.closePath();
      }
      if (globalAlpha) ctx.globalAlpha = 1;
    }
  };

  const drawSpot = (
    ctx: any,
    spc: { x: number | null; y: number | null; id?: number },
    color: string = "rgb(200, 0, 0)",
    theScales: Scale,
    clickedSpace: Space | null,
    isLocal: boolean = false
  ) => {
    if (spc.x !== null && spc.y !== null) {
      const correctedPoint = isLocal
        ? { ...spc, id: spc.id }
        : correctCoordinates({ ...spc, id: spc.id }, theScales);
      const xPos = correctedPoint.x; // scaledX(spc.x, theScales);
      const yPos = correctedPoint.y; // scaledY(spc.y, theScales);
      const correctedRadius = spaceRadius;
      if (xPos && yPos) {
        if (!isLocal) etchCircle(ctx, xPos, yPos, correctedRadius, 3, null, color, null);
        else etchCircle(ctx, xPos, yPos, correctedRadius, 5, 0.9, null, color);
      }
    }
  };

  const draw = (ctx: any) => {
    if (propsFloorId) {
      let img = new Image();
      img.src = propsFloorId && floorImage;
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);

      const theScales = getScales(propsFloorId);
      if (!store.buildingStore.isLoadingSpaces) {
        if (store.buildingStore.floorSpaces && store.buildingStore.floorSpaces[propsFloorId]) {
          store.buildingStore.floorSpaces[propsFloorId]
            .filter((spc) =>
              deskTypeIds
                .filter((tp) => !hiddenTypeIds.includes(tp))
                .includes(spc.space_type?.id || 0)
            )
            .map((spc, idx) => {
              if (spc.coordinates) {
                drawSpot(
                  ctx,
                  {
                    x: spc.corrected_coordinates_x || null,
                    y: spc.corrected_coordinates_y || null,
                    id: spc.id,
                  },
                  bhpColor.available,
                  theScales,
                  null,
                  true
                );
                drawSpot(
                  ctx,
                  { ...spc.coordinates, id: spc.id },
                  bhpColor.focussed,
                  theScales,
                  null,
                  false
                );
              }
            });
        }
        setIsDrawn(true);
      }
    }
  };

  //  const currentBuildingId = store.buildingStore.currentBuilding?.id;
  const theDate = moment(new Date()).format("YYYY-MM-DD");

  const [isDrawn, setIsDrawn] = useState(true);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    if (buildingId && !store.buildingStore.isLoadingSpaces && propsFloorId) {
      store.buildingStore.loadSpaces(buildingId, Number(propsFloorId), theDate, true);
    }
  }, []);

  useEffect(() => {
    if (context && !store.buildingStore.isLoadingFloorPlan && !isDrawing && !isDrawn) {
      setIsDrawing(true);
      draw(context);
    }
  }, [store.buildingStore.isLoadingSpaces, isDrawn]);

  // media doesn't map locally, so just use the stored floorplan image
  const isLocal = window.env.app_env === "LOCAL" ? true : false;

  useEffect(() => {
    setFloorImage(getFloorImage(buildingId || null, floorId || null));
  }, [floorId]);

  useEffect(() => {
    setCanvasHeight(yRatio ? canvasWidth * yRatio : canvasWidth);
  }, [yRatio]);

  return !floorImage ? (
    <Box>Floorplan image ({propsFloorId}) not found</Box>
  ) : (
    <>
      <Box
        pb={{ xs: 2, md: 4 }}
        px={{ xs: 0, md: 4 }}
        onClick={doClick ? (evt) => doClick(evt) : undefined}
        onContextMenu={doClick ? (evt) => doClick(evt) : undefined}
        onMouseMove={trapMove ? (evt) => trapMove(evt) : undefined}
      >
        <CanvasWrapper
          setcontext={theContext}
          draw={draw}
          height={canvasHeight}
          width={canvasWidth}
        />
      </Box>
    </>
  );
});

export default Calibrator;
