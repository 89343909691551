import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from "@material-ui/core";
import Icon from "@mdi/react";
import moment, { Moment } from "moment";
import { mdiCalendarRemoveOutline, mdiHeadset, mdiMapMarkerRadiusOutline } from "@mdi/js";
import { bhpColor, settings } from "styles/globals";
import { Schedule, ScheduleType } from "interfaces/Schedule";
import { observer } from "mobx-react-lite";
import {
  useBuildingStore,
  useScheduleStore,
  useUserStore,
  useBookingStore,
} from "providers/RootStoreProvider";
import { get } from "lodash";
import DisabledOverlay from "components/utils/DisabledOverlay";
import { User } from "interfaces/User";
import { getWorkTypeColor } from "utils/backGroundColor";
import HolidayPopover from "./HolidayPopover";
import config from "config";
import { actualStartOfWeek, endOfPeriod } from "utils/date";
import { getEarliestBooking } from "utils/bookings";
import { useEffect } from "react";
import { cityAbbr } from "utils/locations";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    scheduleBlock: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: `-${theme.spacing(2)}px`,
        padding: theme.spacing(2),
        background: "white",
        width: `calc(100% + ${theme.spacing(4)}px)`,
      },
      marginTop: 24,
      display: "flex",
    },
    tableContainer: {},
    scheduleTable: {
      tableLayout: "fixed",
      "& .MuiTableHead-root": {
        backgroundColor: bhpColor.backgroundGrey,
        border: `1px solid ${bhpColor.blueGrey4}`,
      },
      "& .MuiTableCell-head": {
        borderBottom: "none",
      },
      "& .MuiTableCell-root": {
        padding: 8,
        [theme.breakpoints.down("sm")]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    day: {
      "&.weekend": {
        backgroundColor: `${bhpColor.blueGrey4}30`,
      },
    },
    dayText: {
      marginLeft: "auto",
      marginRight: "auto",
      width: 52,
      height: 66,
      textAlign: "center",
      "&.today": {
        backgroundColor: bhpColor.white,
      },
      "& .dayOfMonth": {
        fontWeight: "bold",
        fontSize: 20,
        paddingTop: 12,
        color: bhpColor.blueGrey1,
      },
      "& .dayOfWeek": {
        color: bhpColor.blueGrey2,
        fontSize: 12,
      },
    },
    statusCell: {
      textAlign: "center",
      border: "none",
      backgroundColor: bhpColor.white,
      paddingTop: `16px !important`,
      paddingBottom: `16px !important`,
      "&.weekend": {
        backgroundColor: `${bhpColor.blueGrey4}30`,
      },
    },
    statusBlock: {
      border: `3px solid ${bhpColor.blueGrey2}`,
      backgroundColor: bhpColor.backgroundGrey,
      borderRadius: 4,
      height: 56,
      width: 54,
      fontSize: 10,
      marginLeft: "auto",
      marginRight: "auto",
      fontWeight: "bold",

      [theme.breakpoints.down("sm")]: {
        height: 45,
        width: 45,
      },
    },
    officeName: {
      marginTop: 4,
      minHeight: 13,
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
        minHeight: 10,
      },
    },
    bookingRef: {
      height: 20,
      marginTop: -5,
      [theme.breakpoints.down("sm")]: {
        marginTop: -7,
      },
      "&.nobooking": {
        color: bhpColor.orange1,
      },
    },
    icon: {
      height: 20,
      color: bhpColor.white,
      "&.office": {
        [theme.breakpoints.down("sm")]: {
          marginTop: -4,
        },
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    disabledOverlay: {
      color: "#466371",
      fontFamily: "Arial",
      fontWeight: "bold",
      textAlign: "center",
    },
  })
);

/// From workType return the icon type office|remote|off (default: off)
function getIconPath(workType?: ScheduleType) {
  switch (workType) {
    case ScheduleType.OFFICE:
      return mdiMapMarkerRadiusOutline;
    case ScheduleType.REMOTE:
      return mdiHeadset;
    case ScheduleType.OFF:
      return mdiCalendarRemoveOutline;
    default:
      return mdiCalendarRemoveOutline;
  }
}

/// Looks at day of week to identify weekend
function isWeekend(date: string) {
  const dateParameter = new Date(date);
  return dateParameter.getDay() === 0 || dateParameter.getDay() === 6;
}

type UserScheduleWidgetProps = {
  startDate: string;
  selectedUser: User | null;
  t: any;
};

/// pass in an array of 7 workDay
const UserScheduleWidget = observer((props: UserScheduleWidgetProps) => {
  const { startDate, selectedUser, t } = props;
  const classes = innerStyles();

  const scheduleStore = useScheduleStore();
  const buildingStore = useBuildingStore();
  const userStore = useUserStore();
  const bookingStore = useBookingStore();

  const defaultOffice = selectedUser?.profile?.default_office
    ? buildingStore.getBuilding(selectedUser.profile.default_office)
    : null;
  const thisDay = moment.utc(startDate);

  function isToday(date: string) {
    return startDate
      ? moment(date).isSame(startDate, "day")
      : moment(date).isSame(moment().utc(), "day");
  }

  /// Rick - workaround for the potential no schedules scenario
  const getDaySchedule = (date: Moment) => {
    const hasSchedules = scheduleStore.selectedUserSchedules.length > 0;
    const weekday = date.utc().format("ddd").toLowerCase();
    return hasSchedules
      ? scheduleStore.userScheduleForDay(date.format("YYYY-MM-DD"))
      : {
          date: date.format("YYYY-MM-DD"),
          status: get(selectedUser?.profile?.default_week, weekday, null),
          space: null,
          office: selectedUser?.profile?.default_office
            ? buildingStore.getBuilding(selectedUser?.profile?.default_office)
            : null,
        };
  };

  const numberOfDaysInWidget = 7;
  const theWeek: { date: string; schedule?: Schedule }[] = [...Array(numberOfDaysInWidget)].map(
    (theDay, idx) => {
      const date = moment(thisDay).utc().add(idx, "d");
      const dateStr = date.format("YYYY-MM-DD");
      return {
        date: dateStr,
        schedule: getDaySchedule(date),
      };
    }
  );

  const formatCity = (city: string | null) => {
    if (city === null) return null;
    return city.toUpperCase().substr(0, 4);
  };

  const getLocation = (sched?: Schedule) => {
    return !sched
      ? formatCity(defaultOffice?.city || null)
      : sched.status === ScheduleType.OFFICE
      ? formatCity(sched.office ? sched.office?.city || null : defaultOffice?.city || null)
      : null;
  };

  const privateSchedule = selectedUser?.profile?.private_schedule;
  const incompleteSchedule = scheduleStore.selectedUserSchedules.length < 7;
  const incompleteSettings = !userStore.isFullySetup;
  const incomplete = incompleteSettings || incompleteSchedule;

  // useEffect(() => {
  //   if (userStore.me) {
  //     const schedulePeriod = {
  //       start: actualStartOfWeek(new Date()),
  //       end: endOfPeriod(actualStartOfWeek(new Date()), 7),
  //     };
  //     bookingStore.loadUserBookings(schedulePeriod, userStore.me.id.toString());
  //   }
  // }, [bookingStore, userStore.me]);

  return (
    <div className={classes.scheduleBlock}>
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.scheduleTable}
          style={{ position: "relative", height: privateSchedule || incomplete ? 250 : "initial" }}
        >
          <DisabledOverlay open={privateSchedule || incomplete}>
            {privateSchedule ? (
              <div className={classes.disabledOverlay}>
                {t("It looks like this user's planner has been made private.")}
              </div>
            ) : incomplete ? (
              <div className={classes.disabledOverlay}>
                {incompleteSchedule
                  ? t("It looks like this user’s schedule is not complete.")
                  : t("It looks like this user’s Default Settings are not complete.")}
              </div>
            ) : (
              <></>
            )}

            <br />
            <div className={classes.disabledOverlay} style={{ bottom: 0, textAlign: "left" }}>
              {t("Find out more about...")} <br />
              <div style={{ color: "#E65400", textDecoration: "underline" }}>
                {t("Workday Platform data collection and use")}{" "}
              </div>
            </div>
          </DisabledOverlay>
          <TableHead>
            <TableRow>
              {theWeek.map((workDay: { date: string; schedule?: Schedule }, key) => {
                return (
                  <TableCell
                    key={key}
                    className={`${classes.day} ${isWeekend(workDay.date) ? "weekend" : ""}`}
                  >
                    <div
                      className={`${classes.dayText} ${isToday(workDay.date) ? "today" : ""}
                        `}
                      style={{ position: "relative" }}
                    >
                      <div className={"dayOfMonth"}>{moment.utc(workDay.date).format("D")}</div>
                      <div className={"dayOfWeek"}>{t(moment.utc(workDay.date).format("ddd"))}</div>
                      <HolidayPopover date={workDay.date} />
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedUser?.profile && !privateSchedule ? (
              <TableRow>
                {theWeek.map((workDay: { date: string; schedule?: Schedule }, key) => {
                  const earliestBooking = bookingStore.user_bookings[workDay.date]
                    ? getEarliestBooking(bookingStore.user_bookings[workDay.date], workDay.date)
                    : null;
                  return (
                    <TableCell
                      key={key}
                      className={`${classes.statusCell}  ${
                        isWeekend(workDay.date) ? "weekend" : ""
                      }`}
                    >
                      <Tooltip
                        title={
                          workDay.schedule?.status !== ScheduleType.OFF ? (
                            <div>
                              {t("Working Hours")}:{" "}
                              {moment(
                                workDay.schedule?.start_time && workDay.schedule?.start_time !== ""
                                  ? workDay.schedule?.start_time
                                  : config.workday.defaultStartWorkingHour,
                                ["HH:mm", "H:mm"]
                              ).format("hh:mm A")}{" "}
                              -{" "}
                              {moment(
                                workDay.schedule?.finish_time &&
                                  workDay.schedule?.finish_time !== ""
                                  ? workDay.schedule?.finish_time
                                  : config.workday.defaultFinishWorkingHour,
                                ["HH:mm", "H:mm"]
                              ).format("hh:mm A")}
                              <br />
                              {workDay.schedule?.status === ScheduleType.OFFICE &&
                              workDay.schedule?.space ? (
                                workDay.schedule?.desk_finish_time &&
                                workDay.schedule?.desk_start_time ? (
                                  <div>
                                    {t("Desk Hours")}:{" "}
                                    {moment(workDay.schedule?.desk_start_time, [
                                      "HH:mm",
                                      "H:mm",
                                    ]).format("hh:mm A")}{" "}
                                    -{" "}
                                    {moment(workDay.schedule?.desk_finish_time, [
                                      "HH:mm",
                                      "H:mm",
                                    ]).format("hh:mm A")}
                                  </div>
                                ) : (
                                  <div>
                                    {t("Desk Hours")}:{" "}
                                    {moment(config.workday.defaultStartWorkingHour, [
                                      "HH:mm",
                                      "H:mm",
                                    ]).format("hh:mm A")}{" "}
                                    -{" "}
                                    {moment(config.workday.defaultFinishWorkingHour, [
                                      "HH:mm",
                                      "H:mm",
                                    ]).format("hh:mm A")}
                                  </div>
                                )
                              ) : (
                                ""
                              )}{" "}
                              {earliestBooking && (
                                <div>
                                  Booking Hours:{" "}
                                  {moment(earliestBooking.booking_details!.start_time_local, [
                                    "HH:mm",
                                    "H:mm",
                                  ]).format("hh:mm A")}{" "}
                                  -{" "}
                                  {moment(earliestBooking.booking_details!.end_time_local, [
                                    "HH:mm",
                                    "H:mm",
                                  ]).format("hh:mm A")}
                                </div>
                              )}
                            </div>
                          ) : (
                            ""
                          )
                        }
                      >
                        <div
                          className={classes.statusBlock}
                          style={{
                            color: bhpColor.white,
                            backgroundColor: getWorkTypeColor(workDay.schedule?.status),
                            borderColor: getWorkTypeColor(workDay.schedule?.status),
                          }}
                        >
                          <div className={classes.officeName}>
                            {earliestBooking?.building
                              ? cityAbbr(earliestBooking?.building)
                              : getLocation(workDay.schedule)}
                          </div>

                          <Icon
                            path={getIconPath(workDay.schedule?.status)}
                            className={`${classes.icon}  ${
                              workDay.schedule?.status === ScheduleType.OFFICE ? "office" : ""
                            }`}
                          />
                          <div
                            className={`${classes.bookingRef} ${
                              getLocation(workDay.schedule) && !workDay.schedule?.space
                                ? "nobooking"
                                : ""
                            }`}
                          >
                            {getLocation(workDay.schedule) && !workDay.schedule?.space
                              ? ""
                              : earliestBooking?.space?.name}
                          </div>
                        </div>
                      </Tooltip>
                    </TableCell>
                  );
                })}
              </TableRow>
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export default UserScheduleWidget;
