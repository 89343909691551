import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Hidden,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useUserStore, useBuildingStore } from "providers/RootStoreProvider";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { peopleStyles } from "styles/peopleStyles";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnMenuContainer,
  GridFilterMenuItem,
  GridOverlay,
  GridSortModel,
} from "@material-ui/data-grid";
import { getScheduleTypeString } from "utils/schedule";
import { capitalizeString, sortAlphaNumeric } from "utils/string";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { ScheduleType } from "interfaces/Schedule";
import StarBorderTwoToneIcon from "@material-ui/icons/StarBorderTwoTone";
import StarTwoToneIcon from "@material-ui/icons/StarTwoTone";
import config from "config";
import { bhpColor } from "styles/globals";

interface FavouritePeopleProps {
  officeId?: number;
  match?: any;
}

const FavouritePeople = observer((props: FavouritePeopleProps) => {
  const classes = peopleStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const smAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const userStore = useUserStore();
  const buildingStore = useBuildingStore();
  const history = useHistory();
  const [favouritePeople, setFavouritePeople] = useState<any[]>([]);
  const gridRowHeight = smAndUp ? 52 : 150;
  const gridPageSize = 10;
  const [userIdEdited, setUserIdEdited] = useState<number | null>(null);
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const [sortModel, setSortModel] = useState<GridSortModel>(
    smAndUp
      ? [
          {
            field: "work_type",
            sort: "desc",
          },
        ]
      : []
  );

  const openFloorPlan = (id: number | undefined, date: string | undefined) => {
    if (id) {
      buildingStore.setupFloorPlanFromSerraviewId(id, false, date);
    }
  };

  const handleSortChange = (model: any) => {
    if (JSON.stringify(sortModel) !== JSON.stringify(model)) {
      setSortModel(model);
    }
  };

  const CustomColumnMenu = (props: any) => {
    const { hideMenu, currentColumn } = props;
    return (
      <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} open={false}>
        <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
      </GridColumnMenuContainer>
    );
  };

  const CustomNoRowsOverlay = () => {
    return <GridOverlay className={classes.root}>{t("No records found.")}</GridOverlay>;
  };

  const onNameClick = (peopleId: any): void => {
    const newUrl = `/people/${peopleId}`;
    history.push(newUrl);
  };

  const onLocationClick = (locationId: any): void => {
    const locationUrl = `/offices/${locationId}`;
    history.push(locationUrl);
  };

  const isUserFavourite = (userId: number) => {
    return userStore.me?.favourite_users && userStore.me?.favourite_users.includes(userId);
  };

  const addUserToFavorites = async (userId: number) => {
    if (userId) {
      setUserIdEdited(userId);
      await userStore.addFavouriteUser(userId);
    }
    setUserIdEdited(null);
  };

  const removeUserFromFavorites = async (userId: number) => {
    if (userId) {
      setUserIdEdited(userId);
      await userStore.removeFavouriteUser(userId);
    }
    setUserIdEdited(null);
  };
  const gridColumns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      sortable: true,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Box
              style={{
                display: "inline-flex",
                visibility: params.row.user_id === userStore.me?.id ? "hidden" : "visible",
              }}
              paddingRight={2}
            >
              {userStore.isLoadingFavouriteUsers && params.row.user_id === userIdEdited ? (
                <CircularProgress size={14} color="secondary" />
              ) : isUserFavourite(params.row.user_id) ? (
                <Tooltip title={`Click to remove ${params.row.name} from favourites`}>
                  <StarTwoToneIcon
                    color="primary"
                    style={{ cursor: "pointer", display: "flex", alignSelf: "center" }}
                    onClick={() => removeUserFromFavorites(params.row.user_id)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={`Click to make ${params.row.name} as favourite`}>
                  <StarBorderTwoToneIcon
                    style={{ cursor: "pointer", alignSelf: "center" }}
                    onClick={() => addUserToFavorites(params.row.user_id)}
                  />
                </Tooltip>
              )}
            </Box>{" "}
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                return onNameClick(params.row.user_id);
              }}
              underline="always"
              color="inherit"
            >
              {params.row.name}
            </Link>
          </>
        );
      },
    },
    {
      field: "work_type",
      headerName: t("Work Type"),
      sortable: true,
      flex: 1,
      valueGetter: (params) =>
        params.row.work_type || params.row.work_type === 0
          ? getScheduleTypeString(params.row.work_type)
          : "",
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params) => {
        return (
          <div>
            {params.row.work_type || params.row.work_type === 0
              ? getScheduleTypeString(params.row.work_type)
              : ""}
          </div>
        );
      },
    },
    {
      field: "team",
      headerName: t("Default Team"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => (params.row.default_team ? params.row.default_team : ""),
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params) =>
        params.row.default_team ? <div>{params.row.default_team}</div> : <div>--</div>,
    },
    {
      field: "default_office",
      headerName: t("Location"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => (params.row.default_office ? params.row.default_office : ""),
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params: GridCellParams) => {
        return params.row.default_office ? (
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              return onLocationClick(
                params.row.default_office_id ? params.row.default_office_id : ""
              );
            }}
            underline="always"
            color="inherit"
          >
            {params.row.default_office ? params.row.default_office : ""}
          </Link>
        ) : (
          t("Not Available")
        );
      },
    },
    {
      field: "space",
      headerName: t("Desk"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => (params.row.spce ? params.row.space : ""),
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params) => (
        <div>
          {params.row.space ? (
            <Link href="#" onClick={() => openFloorPlan(params.row.space.serraview_id, todayDate)}>
              {params.row.space.name}
            </Link>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      field: "working_hours",
      headerName: t("Working Hours"),
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return params.row.status !== ScheduleType.OFF
          ? `${moment(
              params.row.start_time
                ? params.row.start_time
                : config.workday.defaultStartWorkingHour,
              ["HH:mm", "H:mm"]
            ).format("hh:mm A")} - ${moment(
              params.row.finish_time
                ? params.row.finish_time
                : config.workday.defaultFinishWorkingHour,
              ["HH:mm", "H:mm"]
            ).format("hh:mm A")}`
          : "";
      },
    },
    {
      field: "remote_timezone",
      headerName: t("Local Time"),
      sortable: true,
      flex: 1,
      renderCell: (params) => {
        return `${t(moment(new Date()).tz(params.row.remote_timezone).format("ddd"))} ${moment(
          new Date()
        )
          .tz(params.row.remote_timezone)
          .format("h:mma")} (${moment().tz(params.row.remote_timezone).zoneAbbr()})`;
      },
    },
  ];

  const gridColumnsMobile: GridColDef[] = [
    {
      field: "people_Team_Detail",
      headerName: t("People"),
      filterable: false,
      sortable: false,
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Box px={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <Typography style={{ fontWeight: 700 }}>
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          return onNameClick(params.row.user_id);
                        }}
                        underline="always"
                        color="inherit"
                      >
                        {params.row.name}
                      </Link>
                    </Typography>
                  </div>
                  <div
                    style={{
                      alignSelf: "center",
                      visibility: params.row.user_id === userStore.me?.id ? "hidden" : "visible",
                    }}
                  >
                    {userStore.isLoadingFavouriteUsers && params.row.user_id === userIdEdited ? (
                      <CircularProgress size={14} color="secondary" />
                    ) : isUserFavourite(params.row.user_id) ? (
                      <Tooltip title={`Click to remove ${params.row.name} from favourites`}>
                        <StarTwoToneIcon
                          color="primary"
                          style={{ cursor: "pointer", display: "flex", alignSelf: "center" }}
                          onClick={() => removeUserFromFavorites(params.row.user_id)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={`Click to make ${params.row.name} as favourite`}>
                        <StarBorderTwoToneIcon
                          style={{ cursor: "pointer", alignSelf: "center" }}
                          onClick={() => addUserToFavorites(params.row.user_id)}
                        />
                      </Tooltip>
                    )}
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography display={"block"} component={"span"}>
                  {params.row.work_schedules ? (
                    <div>
                      {params.row.space ? (
                        <>
                          {capitalizeString(ScheduleType[params.row.status])}
                          {params.row.space ? (
                            <>
                              {" ("}
                              <Link
                                href="#"
                                onClick={() =>
                                  openFloorPlan(params.row.space.serraview_id, todayDate)
                                }
                              >
                                {params.row.space}
                              </Link>
                              {")"}
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        t("Not Available")
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "smaller" }} component={"span"}>
                  {params.row.team ? <div>{params.row.team.team_name}</div> : <div> </div>}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "smaller" }}>
                  {params.row.default_office ? (
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        return onLocationClick(
                          params.row.default_office_id ? params.row.default_office_id : ""
                        );
                      }}
                      underline="always"
                      color="inherit"
                    >
                      {params.row.default_office ? params.row.default_office : ""}
                    </Link>
                  ) : (
                    t("Not Available")
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "smaller" }}>
                  {`${t(moment(new Date()).tz(params.row.remote_timezone).format("ddd"))} ${moment(
                    new Date()
                  )
                    .tz(params.row.remote_timezone)
                    .format("h:mm a")} (${moment().tz(params.row.remote_timezone).zoneAbbr()})`}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    (async () => {
      const favUsers = await userStore.getFavouriteUsers(todayDate);
      setFavouritePeople(favUsers);
    })();
  }, [userStore.me]);

  return favouritePeople.length > 0 ? (
    <>
      <Box className={classes.root} pt={smAndUp ? 3 : 1} px={{ xs: 2, md: 4 }}>
        <Hidden smDown>
          <h2 style={{ color: bhpColor.blueGrey1, lineHeight: 0 }}>{t("Favourite People")}</h2>
        </Hidden>
        <Hidden smUp>
          <h2 style={{ color: bhpColor.blueGrey1, lineHeight: 0 }}>{t("People")}</h2>
        </Hidden>
      </Box>
      <Box className={classes.root} py={smAndUp ? 4 : 2} px={{ xs: 2, md: 4 }}>
        <Paper className={classes.paper} elevation={0}>
          <Grid className={classes.dataGrid} container>
            <Grid item xs>
              <div
                className={classes.spaceTable}
                style={{
                  height: Math.min(favouritePeople.length, gridPageSize) * gridRowHeight + 110,
                }}
              >
                <DataGrid
                  rows={favouritePeople}
                  getRowId={(r) => r.name}
                  columns={smAndUp ? gridColumns : gridColumnsMobile}
                  rowHeight={gridRowHeight}
                  pageSize={gridPageSize}
                  loading={userStore.isLoadingFavouriteUsers}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    ColumnSortedAscendingIcon: ExpandMore,
                    ColumnSortedDescendingIcon: ExpandLess,
                    ColumnUnsortedIcon: UnfoldMoreIcon,
                    ColumnMenu: CustomColumnMenu,
                  }}
                  disableSelectionOnClick
                  hideFooterRowCount
                  disableColumnMenu={false}
                  sortModel={sortModel}
                  onSortModelChange={(model) => {
                    handleSortChange(model);
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  ) : (
    <div></div>
  );
});
export default FavouritePeople;
