import {
  Box,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Icon from "@mdi/react";
import {
  mdiLessThan,
  mdiGreaterThan,
  mdiChartScatterPlotHexbin,
  mdiChevronUp,
  mdiChevronDown,
} from "@mdi/js";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { BuildingWithCapacity } from "interfaces/Building";
import { bhpColor } from "styles/globals";
import { isWeekend } from "utils/date";
import { useBuildingStore } from "providers/RootStoreProvider";
import { toJS } from "mobx";
import CapacityHeadCell from "./components/CapacityHeadCell";
import CapacityHeadSummary from "./components/CapacityHeadSummary";

interface CapacityTabHeadRowProps {
  buildingCapacityRange: BuildingWithCapacity[];
  correctedTotalCapacity?: number;
  scrollTo: any;
  t: any;
  isSite?: boolean;
  focussedDate?: string;
  focusDate?: any;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    capacityLabels: {
      width: 200,
    },
    dateHead: {
      "&.focussed": {
        border: `1px solid black`,
        borderRadius: "8px",
        backgroundColor: bhpColor.white,
      },
    },
    today: {},
    weekend: { backgroundColor: `${bhpColor.blueGrey4}30` },
  })
);

const CapacityTabHeadRow = observer((props: CapacityTabHeadRowProps) => {
  const {
    isSite,
    buildingCapacityRange,
    correctedTotalCapacity,
    scrollTo,
    t,
    focussedDate,
    focusDate,
  } = props;
  const classes = innerStyles();
  const lgAndUp = useMediaQuery(useTheme().breakpoints.up("lg"));

  const isOffice = !isSite;

  const scrollLeft = () => scroll(-1);
  const scrollRight = () => scroll(1);
  const scroll = (amount: number) => {
    scrollTo(amount);
  };

  const today = new Date();
  const plannedLabel = lgAndUp ? t("Planned Attendance") : t("Planned");
  const bookedLabel = lgAndUp ? t("Desks Booked") : t("Booked");

  const buildingData = buildingCapacityRange.length ? buildingCapacityRange[0] : null;
  const capacityToUse =
    buildingData &&
    correctedTotalCapacity &&
    buildingData.total_capacity &&
    correctedTotalCapacity > buildingData.total_capacity
      ? correctedTotalCapacity || 0
      : buildingData?.total_capacity || 0;

  const adjustedCapacity = buildingData?.adjusted_capacity;
  const disableLeftChevron = () => {
    return buildingCapacityRange.findIndex((bc) => moment(today).isSame(bc.date, "day")) >= 0;
  };
  const disableRightChevron = () => {
    return (
      buildingCapacityRange.findIndex((bc) =>
        moment(today).add(14, "days").isSame(bc.date, "day")
      ) >= 0
    );
  };

  const todaysData = buildingCapacityRange.filter((bcr) => bcr.date == focussedDate);

  return (
    <Hidden xsDown>
      <TableRow
        style={{
          backgroundColor: bhpColor.backgroundGrey,
        }}
      >
        <TableCell>
          <Grid container style={{ minHeight: "170px", width: "240px" }}>
            <Grid item xs={12}>
              <Box
                style={{
                  border: `1px solid ${bhpColor.white}`,
                  borderRadius: "4px",
                  backgroundColor: bhpColor.white,
                  padding: "8px",
                  width: "100%",
                }}
              >
                <CapacityHeadSummary
                  buildingDetail={todaysData[0]}
                  date={moment(focussedDate).toDate()}
                  t={t}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "start", justifyContent: "end" }}
            >
              <Box style={{ textAlign: "end" }}>
                <Typography style={{ fontWeight: 700, color: bhpColor.blueGrey1 }}>
                  {plannedLabel}
                </Typography>
                {isOffice && (
                  <Typography style={{ fontWeight: 700, color: bhpColor.orange1 }}>
                    {bookedLabel}
                  </Typography>
                )}
                {adjustedCapacity && adjustedCapacity < 100 && (
                  <Typography
                    style={{ fontSize: "smaller", fontWeight: 700, color: bhpColor.grey1 }}
                  >
                    {`${t("Adjusted capacity measures shown")} (${adjustedCapacity}%)`}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell style={{ cursor: "pointer", width: 50 }} onClick={() => scrollLeft()}>
          <Icon
            color={disableLeftChevron() ? bhpColor.grey3 : ""}
            path={mdiLessThan}
            size={1}
            style={{ transform: "scaleX(0.6)" }}
          />
        </TableCell>
        {buildingCapacityRange.map((val, idx) => {
          return (
            <TableCell
              key={`thc${idx}`}
              style={{ paddingRight: "12px", alignItems: "start", verticalAlign: "top" }}
              onClick={() => (focusDate ? focusDate(val.date) : undefined)}
              className={isWeekend(new Date(val.date)) ? classes.weekend : ""}
            >
              <Tooltip title="Detail this day">
                <Box
                  pl={2}
                  pr={2}
                  style={{ textAlign: "center", cursor: "pointer" }}
                  className={`${classes.dateHead} ${
                    moment(today).isSame(val.date, "day") ? classes.today : ""
                  } ${val.date === focussedDate ? "focussed" : ""}`}
                >
                  <CapacityHeadCell buildingDetail={val} date={moment(val.date).toDate()} t={t} />
                </Box>
              </Tooltip>
            </TableCell>
          );
        })}
        <TableCell style={{ cursor: "pointer", width: 50 }} onClick={() => scrollRight()}>
          <Icon
            color={disableRightChevron() ? bhpColor.grey3 : ""}
            path={mdiGreaterThan}
            size={1}
            style={{ transform: "scaleX(0.6)" }}
          />
        </TableCell>
      </TableRow>
    </Hidden>
  );
});

export default CapacityTabHeadRow;
