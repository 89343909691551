import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import { useUserStore, useVisitorStore, useBAMStore } from "providers/RootStoreProvider";
import { useEffect, useState, useCallback, Fragment } from "react";
import { useHistory } from "react-router";
import { bhpColor, settings, displaySettings } from "styles/globals";
import { useTranslation } from "react-i18next";
import HeaderImage from "assets/headerImage.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { peopleStyles } from "styles/peopleStyles";
import { debounce } from "lodash";
import moment from "moment";
import { Form_prequisite, Special_Access_Area } from "stores/BAMStore";
import { Vms_Meeting_Items } from "interfaces/Visitor";
import {
  BuildingAcccessVMSExternalVisitorModel,
  GallagherVMSIntergationUpdateModel,
  CardType,
  FormElement,
  getDefaultCardTypeBlurb,
  getLocalDate,
} from "interfaces/Visitor";
import LoadingZone from "components/schedule/LoadingZone";
import Topbar from "views/main/Topbar";
import { usePeopleStore } from "providers/RootStoreProvider";
// #region STYLES
const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartOuter: {
      border: `1px solid ${bhpColor.blueGrey4}`,
      [theme.breakpoints.down("sm")]: {
        border: 0,
        paddingLeft: 0,
      },
    },
    form: {
      "& .MuiGrid-container": {
        margin: "20px",
        marginLeft: "10px",
        marginRight: "10px",
      },
    },
    mainTable: {
      "& td": {
        // borderBottom: 0,
      },
    },
    textArea: {
      height: 32,
      width: "100%",
      paddingLeft: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      backgroundColor: `${bhpColor.blueGrey4}50`,
    },
    avatar: {
      backgroundColor: "rgb(250, 182, 54)",
      color: "white",
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginLeft: "16px",
      fontSize: "16px",
    },
    h4: {
      fontWeight: "bold",
      color: bhpColor.blueGrey1,
      fontSize: 16,
      display: "inline-block",
      marginLeft: "15px",
    },
    wrapper: {
      marginTop: theme.spacing(2),
    },
    team: {
      height: 36,
      width: "calc(100% - 150px)",
      paddingLeft: theme.spacing(1),
      backgroundColor: `${bhpColor.blueGrey4}50`,
      display: "inline-flex",
      verticalAlign: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    datePicker: {
      width: "calc(100% - 150px)",
      borderRadius: settings.borderRadius,
      border: `1px solid ${bhpColor.grey3}`,
      backgroundColor: "white",
      "& .MuiFormLabel-root": {
        color: bhpColor.blueGrey1,
        fontSize: 16,
        fontWeight: 700,
      },
      "& .MuiInput-root": {
        paddingLeft: theme.spacing(1),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `0 !important`,
        transition: "none",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    textField: {
      width: "calc(100% - 150px)",
      borderRadius: settings.borderRadius,
      backgroundColor: "white",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 10px)",
      },
      "& .MuiInput-root": {
        paddingLeft: theme.spacing(1),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `0 !important`,
        transition: "none",
      },
      "& .Mui-focussed": {
        borderBottom: `0 !important`,
      },
    },
    formControl: {
      display: "block",
      width: "100%",
      margin: 0,
    },
    selectBox: {
      width: "calc(100% - 150px)",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 10px)",
      },
      height: 40,
      "& svg": {
        zIndex: 2,
      },
      "& select": {
        zIndex: 2,
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& fieldset": {
        zIndex: 1,
        height: 40,
        top: -3,
        backgroundColor: "white",
        "& .Mui-focussed": {
          backgroundColor: "white",
        },
      },
    },
    selectInput: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    buttonRow: {
      marginTop: theme.spacing(2),
      textAlign: "start",
    },
    saveFailed: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.purple1,
        fontWeight: "bold",
        backgroundColor: bhpColor.purple4,
        border: `2px solid ${bhpColor.purple1}`,
        borderRadius: settings.borderRadius,
      },
    },
    saveSuccess: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.orange1,
        fontWeight: "bold",
        backgroundColor: bhpColor.orange4,
        border: `2px solid ${bhpColor.orange1}`,
        borderRadius: settings.borderRadius,
      },
    },
    base: {
      color: bhpColor.blueGrey1,
      "& .MuiPaper-root": {
        color: bhpColor.blueGrey1,
      },
      "& .MuiDialog-paper": {
        maxWidth: "calc(100% - 64px)",
      },
      "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
      },
    },
    dialogTitle: {
      backgroundImage: `url(${HeaderImage})`,
      backgroundSize: "100% auto",
      color: bhpColor.white,
      "& h2": {
        fontWeight: "bold",
        "& svg": {
          verticalAlign: "text-top",
        },
      },
    },
    content: {
      marginBottom: theme.spacing(2),
    },
    mainItem: {
      flex: "auto",
    },
    bottomItem: {
      paddingBottom: 0,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    anchorDayButton: {
      fontSize: 20,
      color: bhpColor.blueGrey1,
      borderRadius: settings.borderRadius,
      borderWidth: 2,
      borderColor: bhpColor.blueGrey1,
      borderStyle: "solid",
      backgroundColor: bhpColor.blueGrey4,
      textTransform: "none",
      "&.selected": {
        backgroundColor: bhpColor.blueGrey1,
        color: bhpColor.white,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        height: 50,
        width: 50,
        marginRight: 6,
        minWidth: 50,
      },
    },
  })
);
// #endregion STYLES
interface MeetingDetailsProps {
  meetingId?: number;
  match?: any;
}
interface User {
  email: string;
  firstname: string;
  lastname: string;
  company: string;
  phoneNumber: string;
}
const topBarTitle = "Meeting Information";
const topBarSubTitle = "Update Meeting Details";
const topBarBreadcrumb = "Meeting Details";
const MeetingDetails = observer((props: any) => {
  const isRequestAccess = true;
  const userStore = useUserStore();
  const bamStore = useBAMStore();
  const classes = innerStyles();
  const { t, i18n } = useTranslation();
  const peopleClasses = peopleStyles();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const visitorStore = useVisitorStore();
  const peopleStore = usePeopleStore();
  const history = useHistory();
  const [emailOptions, setEmailOptions] = useState<User[]>([]);

  const charLimit = 250;
  const vmsMeetingId = props.match.params.meetingId || null;
  type initialFormStateType = {
    locationId: number;
    emailId: string;
    firstName: string;
    lastName: string;
    companyName: string;
    phoneNumber: string;
    fromDateTime: string;
    toDateTime: string;
    accessBuildingHours: boolean;
    hostNameId: number;
    notifyVisitor: boolean;
    vmsAccessArea: string;
    meetingName: string;
    meetingDescription?: string;
    mobileCredentials: boolean;
    under18?: boolean;
    meetingRoom: string;
  };
  const initialFormState: initialFormStateType = {
    locationId: 0,
    emailId: "",
    firstName: "",
    lastName: "",
    companyName: "",
    phoneNumber: "",
    fromDateTime: "",
    toDateTime: "",
    accessBuildingHours: true,
    hostNameId: 0,
    notifyVisitor: false,
    vmsAccessArea: "",
    meetingName: "",
    meetingDescription: "",
    mobileCredentials: true,
    under18: false,
    meetingRoom: "",
  };
  const registrationFormFields: {
    [formName: string]: FormElement[];
  } = {
    "TEST - External Visitor - DO NOT USE": [
      { fieldName: "locationId", helperText: "You need to choose a location", isRequired: true },
      {
        fieldName: "emailId",
        helperText: "You need to provide an email address",
        isRequired: true,
      },
      { fieldName: "firstName", helperText: "You need to enter a first name", isRequired: true },
      { fieldName: "lastName", helperText: "You need to choose a last name", isRequired: true },
      {
        fieldName: "companyName",
        helperText: "You need to enter the company name",
        isRequired: true,
      },
      { fieldName: "hostNameId", helperText: "You need to choose a host", isRequired: true },
      {
        fieldName: "phoneNumber",
        helperText: "You need to enter the phone number",
        isRequired: true,
      },
      { fieldName: "fromDateTime", helperText: "You need to set a start time", isRequired: true },
      { fieldName: "toDateTime", helperText: "You need to set an end time", isRequired: true },
      {
        fieldName: "meetingName",
        helperText: "You need to enter the name of the meeting",
        isRequired: true,
      },
      {
        fieldName: "meetingDescription",
        helperText: "You need to enter the description of the meeting",
        isRequired: false,
      },
      {
        fieldName: "vmsAccessArea",
        helperText: "You have to Select the Visit Category",
        isRequired: true,
      },
      {
        fieldName: "mobileCredentials",
        helperText: "You can request Mobile Access",
        isRequired: false,
      },
      {
        fieldName: "under 18?",
        helperText: "You need to enable the check box if the visitor's age is under 18",
        isRequired: true,
      },
      {
        fieldName: "Meeting Room",
        helperText: "You need to specify the meeting room details",
        isRequired: false,
      },
    ],
  };
  const initialFormErrorState = {
    locationIdError: false,
    firstNameError: false,
    lastNameError: false,
    emailIdError: false,
    fromDateTimeError: false,
    toDateTimeError: false,
    hostId: false,
    companyNameError: false,
    phoneNumberError: false,
    hostNameIdError: false,
    vmsAccessArea: false,
    meetingName: false,
    meetingDescription: false,
    meetingRoom: false,
  };
  const initialFormHelperTextState = {
    // locationIdHelperText: "",
    firstNameHelperText: "",
    lastNameHelperText: "",
    emailIdHelperText: "",
    fromDateTimeHelperText: "",
    toDateTimeHelperText: "",
    // hostId: "",
    companyNameHelperText: "",
    phoneNumberHelperText: "",
    // hostNameIdHelperText: "",
    // vmsAccessArea: "",
    meetingName: "",
    meetingDescription: "",
    meetingRoom: "",
  };
  const [visitorRegistrationFormValues, setVisitorRegistrationFormValues] =
    useState<object>(initialFormState);
  const [visitorRegistrationErrorValues, setVisitorRegistrationErrorValues] =
    useState<object>(initialFormErrorState);
  const [visitorRegistrationHelperTextValues, setVisitorRegistrationHelperTextValues] =
    useState<object>(initialFormHelperTextState);
  const [showRegistrationForm, setShowRegistrationForm] = useState("block");
  const setFormValues = (fieldName: string, value: any) => {
    let formValues = { ...visitorRegistrationFormValues };
    let errorText = { ...visitorRegistrationHelperTextValues };
    let errorState = { ...visitorRegistrationErrorValues };
    formValues[fieldName] = value;
    errorText[fieldName] = "";
    errorState[fieldName] = false;
    setVisitorRegistrationFormValues(formValues);
    setVisitorRegistrationErrorValues(errorState);
    setVisitorRegistrationHelperTextValues(errorText);
  };
  const [displayVisitorDetailField, setDisplayVisitorDetailField] = useState<boolean>(true);
  const checkEmailId = (event) => {
    let email_value: string = event.target.value;
    if (email_value !== "") {
      // let email_rule = /^\w+[-\.\w]*@(?!(?:bhp)\.com$)\w+[-\.\w]*?\.\w{2,4}$/;
      let email_rule = /^\w+[-+_%\.\w]*@(?!(?:bhp)\.com$)\w+[-\.\w]*?\.\w{2,4}$/;
      if (email_rule.test(email_value) == true) {
        (async () => {
          const visitorEmail = {
            email_id: email_value,
          };
          const response = await visitorStore.validateVisitorEmail(visitorEmail);
          if (response.status == 200) {
            setDisplayVisitorDetailField(false);
          }
        })();
      }
    }
  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    let formValues = { ...visitorRegistrationFormValues };
    let errorText = { ...visitorRegistrationHelperTextValues };
    let errorState = { ...visitorRegistrationErrorValues };
    if (name.startsWith("emailId")) formValues[name] = `${event.target.value}`.toLowerCase();
    if (
      name.startsWith("firstName") ||
      name.startsWith("lastName") ||
      name.startsWith("companyName") ||
      name.startsWith("phoneNumber") ||
      name.startsWith("meetingRoom") ||
      name.startsWith("meetingDescription")
    ) {
      formValues[name] = event.target.value;
      errorText[name] = "";
      errorState[name] = false;
    }
    switch (name) {
      case "fromDateTime":
        formValues[name] = event.target.value;
        if (cardType == 9) {
          let startDateTime = event.target.value;
          const [startDateValues, startTimeValues] = startDateTime.split("T");
          const [startHours, startMinutes] = startTimeValues.split(":");
          if (
            startHours < 7 ||
            (startHours > 17 && startMinutes >= 0) ||
            (startHours >= 17 && startMinutes > 0)
          )
            setVmsStartDateValidation(true);
          else setVmsStartDateValidation(false);
        }
        errorText[name] = "";
        errorState[name] = false;
        break;
      case "toDateTime":
        formValues[name] = event.target.value;
        if (cardType == 9) {
          let endDateTime = event.target.value;
          const [endDateValues, endTimeValues] = endDateTime.split("T");
          const [endHours, endMinutes] = endTimeValues.split(":");
          if ((endHours > 17 && endMinutes >= 0) || (endHours >= 17 && endMinutes > 0))
            setVmsEndDateValidation(true);
          else setVmsEndDateValidation(false);
        }
        errorText[name] = "";
        errorState[name] = false;
        break;
      case "firstName":
      case "lastName":
      case "toDateTime":
      case "companyName":
      case "phoneNumber":
      case "notifyVisitor":
        let isNotifyVisitorChecked = event.target.checked;
        if (isNotifyVisitorChecked) {
          formValues["notifyVisitor"] = true;
        } else {
          formValues["notifyVisitor"] = false;
        }
        break;
      default:
        break;
    }
    setVisitorRegistrationFormValues(formValues);
    setVisitorRegistrationErrorValues(errorState);
    setVisitorRegistrationHelperTextValues(errorText);
  };
  const formFieldTitle = (title: string) => {
    return (
      <Grid item xs={12} sm={3}>
        <Typography className={classes.h4}>{title}</Typography>
      </Grid>
    );
  };
  const [locationId, setLocationId] = useState<number>(0);
  const [vmsAccessAreaId, setVmsAccessAreaId] = useState<number>(0);
  const [vmsAccessAreaName, setVmsAccessAreaName] = useState<string>("");
  const [hostId, setHostId] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const clearForm = () => {
    setVisitorRegistrationFormValues(initialFormState);
    setVisitorRegistrationErrorValues(initialFormErrorState);
    setVisitorRegistrationHelperTextValues(initialFormHelperTextState);
  };
  const [message, setMessage] = useState<string>("");
  const [registrationButtonStatus, setRegistrationButtonStatus] = useState<boolean>(false);
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const isFieldValid = (field: { fieldName: string; helperText: string; isRequired: boolean }) => {
    const value = visitorRegistrationFormValues[field.fieldName];
    if (typeof value == undefined) {
      console.log("test not found: ", field.fieldName);
      return false;
    }
    // if field exists assume ok, unless required no value
    let returnValue = true;
    if (field.isRequired) {
      if (value == 0 || value == "") returnValue = false;
    }
    if (field.fieldName.startsWith("email")) {
      let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/;
      if (re.test(value) == false) returnValue = false;
    }
    return returnValue;
  };
  const [emailIdArray, setEmailIdArray] = useState<string[]>([]);
  const findDuplicateEmail = () => {
    let emailIdList = [...emailIdArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      emailIdList.push(visitorRegistrationFormValues[`emailId${elementSuffix}`]);
      // setEmailIdArray(emailIdList);
    });
    return emailIdList;
  };
  const [vmsStartDateValidation, setVmsStartDateValidation] = useState(false);
  const [vmsEndDateValidation, setVmsEndDateValidation] = useState(false);
  const checkForm = (cardType: CardType, formFields: FormElement[] = []) => {
    let errorText = { ...visitorRegistrationHelperTextValues };
    let errorState = { ...visitorRegistrationErrorValues };
    let isValid = true;
    let requiredFields: FormElement[] = [...formFields];
    if (requiredFields.length > 0) {
      requiredFields.forEach((field) => {
        const value = visitorRegistrationFormValues[field.fieldName];
        let testResult = isFieldValid(field);
        let helperText = field.helperText;
        if (field.fieldName.startsWith("email")) {
          // let re = /^\w+[-\.\w]*@(?!(?:bhp)\.com$)\w+[-\.\w]*?\.\w{2,}$/;
          let re = /^\w+[-+_%\.\w]*@(?!(?:bhp)\.com$)\w+[-\.\w]*?\.\w{2,}$/;
          if (value !== "" && re.test(value) == false) {
            helperText = `${helperText} - non BHP`;
            testResult = false;
          } else testResult = true;
          if ([CardType["TEST - External Visitor - DO NOT USE"]].includes(+cardType)) {
            let duplicateEmailIds = findDuplicateEmail();
            let unique = new Set();
            for (let i = 0; i < duplicateEmailIds.length; i++) {
              if (unique.has(duplicateEmailIds[i])) {
                testResult = false;
                helperText = "Duplicate email id's are not allowed";
              }
              unique.add(duplicateEmailIds[i]);
            }
          }
        }
        if (field.fieldName.startsWith("fromDateTime")) {
          let selectedFromDateTime = value;
          let todays_date = moment(new Date()).format("YYYY-MM-DD");
          if ([CardType["TEST - External Visitor - DO NOT USE"]].includes(+cardType)) {
            let specialAccessArea = visitorRegistrationFormValues["vmsAccessArea"];
            // let building_timezone = getBuildingTimezone(visitorRegistrationFormValues["locationId"]);
            let currentDate = moment(new Date()).format("YYYY-MM-DD");
            // let maxFromDateTime = moment(currentDate).add(3, "days").format();
            let maxSpecialAccessDate = moment(currentDate).add(14, "days").format();
            // if (moment(selectedFromDateTime).isBefore(maxFromDateTime)) {
            //   helperText = "Request must be submitted 3 days* prior to the start date";
            //   testResult = false;
            // }
            if (specialAccessArea) {
              if (vmsLocationAccessAreas) {
                let vmsArea = vmsLocationAccessAreas.filter((vms) => vms.name == specialAccessArea);
                if (vmsArea[0].vms_access_group && vmsArea[0].vms_special_access_required) {
                  if (moment(selectedFromDateTime).isBefore(maxSpecialAccessDate)) {
                    helperText =
                      "IROC requests must be submitted at least 2 weeks prior to the proposed start date.";
                    testResult = false;
                  }
                }
              }
            }
          }
        }
        if (field.fieldName.startsWith("toDateTime")) {
          let selectedFromDateTime = visitorRegistrationFormValues["fromDateTime"];
          let selectedToDateTime = value;
          if ([CardType["TEST - External Visitor - DO NOT USE"]].includes(+cardType)) {
            let maxToDateTime = moment(selectedFromDateTime).add(7, "days").format();
            if (moment(selectedToDateTime).isAfter(maxToDateTime)) {
              helperText = "Please select a date so the visit is less than 7 days";
              testResult = false;
            }
            if (moment(selectedToDateTime).isAfter(moment(selectedFromDateTime)) == false) {
              testResult = false;
              helperText = "Please select an end date/time after the meeting start";
            }
          }
          //   testResult = true;
        }
        if (!testResult) isValid = false;
        errorText[field.fieldName] = testResult ? undefined : helperText;
        errorState[field.fieldName] = !testResult;
        if (field.fieldName.startsWith("email")) {
          // let re = /^\w+[-\.\w]*@(?!(?:bhp)\.com$)\w+[-\.\w]*?\.\w{2,}$/;
          let re = /^\w+[-+_%\.\w]*@(?!(?:bhp)\.com$)\w+[-\.\w]*?\.\w{2,}$/;
          // loop for all visitors
          visitors.forEach((idx) => {
            if (idx > 1) {
              const thisFieldName = `emailId-${idx - 1}`;
              const thisValue = visitorRegistrationFormValues[thisFieldName];
              if (thisValue !== "" && re.test(thisValue) == false) {
                helperText = "You need to provide email address - non BHP";
                errorText[thisFieldName] = helperText;
                errorState[thisFieldName] = true;
              } else {
                testResult = true;
                errorText[thisFieldName] = "";
                errorState[thisFieldName] = false;
              }
            }
          });
        }
        if (
          [CardType["TEST - External Visitor - DO NOT USE"]].includes(+cardType) &&
          field.fieldName.startsWith("phoneNumber")
        ) {
          visitors.forEach((idx) => {
            if (idx > 1) {
              const thisFieldName = `under18-${idx - 1}`;
              const name = `phoneNumber-${idx - 1}`;
              const val = visitorRegistrationFormValues[name];
              const thisValue = visitorRegistrationFormValues[thisFieldName];
              if ((!thisValue && val == "") || (thisValue == undefined && val == undefined)) {
                errorText[name] = helperText;
                errorState[name] = true;
                isValid = false;
              }
            } else {
              const name = "phoneNumber";
              const thisValue = visitorRegistrationFormValues["under18"];
              const val = visitorRegistrationFormValues["phoneNumber"];
              if (!thisValue && val == "") {
                errorText[name] = helperText;
                errorState[name] = true;
                isValid = false;
              }
            }
          });
        }
        if ([CardType["TEST - External Visitor - DO NOT USE"]].includes(+cardType)) {
          ["firstName", "lastName", "companyName"].forEach((fld) => {
            if (field.fieldName.startsWith(fld)) {
              // loop for all visitors
              visitors.forEach((idx) => {
                if (idx > 1) {
                  const thisFieldName = `${fld}-${idx - 1}`;
                  const thisValue = visitorRegistrationFormValues[thisFieldName];
                  const testResult = field.isRequired
                    ? thisValue == 0 || thisValue == "" || !thisValue
                      ? false
                      : true
                    : true;
                  errorText[thisFieldName] = testResult ? undefined : helperText;
                  errorState[thisFieldName] = !testResult;
                }
              });
            }
          });
        }
      });
    }
    if (!isValid) console.log("fail: ", errorState);
    setVisitorRegistrationErrorValues(errorState);
    setVisitorRegistrationHelperTextValues(errorText);
    return isValid;
  };
  // VMS Integration
  const [emailArray, setEmailArray] = useState<string[]>([]);
  const [firstNameArray, setFirstNameArray] = useState<string[]>([]);
  const [lastNameArray, setLastNameArray] = useState<string[]>([]);
  const [phoneNumberArray, setPhoneNumberArray] = useState<string[]>([]);
  const [companyNameArray, setCompanyNameArray] = useState<string[]>([]);
  const [mobileCredFlagArray, setMobileCredFlagArray] = useState<boolean[]>([]);
  const [childVisitorsFlagArray, setChildVisitorsFlagArray] = useState<boolean[]>([]);
  const [vmsAccessAreas, setvmsAccessAreas] = useState<number>(0);
  const get_visitors_email = () => {
    let emailList = [...emailArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      emailList.push(visitorRegistrationFormValues[`emailId${elementSuffix}`]);
      setEmailArray(emailList);
    });
    return emailList;
  };
  const get_visitors_firstname = () => {
    let firstNameList = [...firstNameArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      firstNameList.push(visitorRegistrationFormValues[`firstName${elementSuffix}`]);
      setFirstNameArray(firstNameList);
    });
    return firstNameList;
  };
  const get_visitors_lastname = () => {
    let lastNameList = [...lastNameArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      lastNameList.push(visitorRegistrationFormValues[`lastName${elementSuffix}`]);
      setLastNameArray(lastNameList);
    });
    return lastNameList;
  };
  const get_visitors_companyname = () => {
    let companyNameList = [...companyNameArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      companyNameList.push(visitorRegistrationFormValues[`companyName${elementSuffix}`]);
      setCompanyNameArray(companyNameList);
    });
    return companyNameList;
  };
  const get_visitors_phonenumber = () => {
    let phoneNumberList = [...phoneNumberArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      if (visitorRegistrationFormValues[`phoneNumber${elementSuffix}`] == undefined) {
        phoneNumberList.push("");
      } else phoneNumberList.push(visitorRegistrationFormValues[`phoneNumber${elementSuffix}`]);
      setCompanyNameArray(phoneNumberList);
    });
    return phoneNumberList;
  };
  const get_childVisitorsFlag = () => {
    let childVisitorFlagList = [...childVisitorsFlagArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      if (idx > 1) {
        childVisitorFlagList.push(visitorRegistrationFormValues[`under18-${elementSuffix}`]);
      } else {
        childVisitorFlagList.push(visitorRegistrationFormValues[`under18${elementSuffix}`]);
      }
      setChildVisitorsFlagArray(childVisitorFlagList);
    });
    return childVisitorFlagList;
  };
  const get_mobileCredentialsFlag = () => {
    let mobileCredFlagList = [...mobileCredFlagArray];
    visitors.forEach((idx) => {
      const elementSuffix = idx > 1 ? `${idx - 1}` : "";
      if (idx > 1) {
        mobileCredFlagList.push(
          visitorRegistrationFormValues[`mobileCredentials-${elementSuffix}`]
        );
      } else {
        mobileCredFlagList.push(visitorRegistrationFormValues[`mobileCredentials${elementSuffix}`]);
      }
      setMobileCredFlagArray(mobileCredFlagList);
    });
    return mobileCredFlagList;
  };
  // Additional fields for VMS gallagher for external visitors
  // Mobile credentials, under18-for child visitor validation
  const initialMobileCredState = {
    mobileCredentials: true,
    mobileCredentials1: true,
  };
  const [initialMobileCredStates, setInitialMobileCredState] =
    useState<object>(initialMobileCredState);
  const [mobileCredFlag, setMobileCredFlag] = useState<object>(initialMobileCredStates);
  const getMobileCredFlag = (fieldName: string, value: boolean) => {
    let mobileCredState = { ...mobileCredFlag };
    mobileCredState[fieldName] = value;
    setMobileCredFlag(mobileCredState);
  };

  const hangleChildVisitorChange = (event, titleSuffix) => {
    const isChecked = event.target.checked;
    let formValues = { ...visitorRegistrationFormValues };

    if (titleSuffix >= 1) {
      formValues[`under18-${titleSuffix}`] = isChecked;
    } else {
      formValues["under18"] = isChecked;
    }

    setVisitorRegistrationFormValues(formValues);
  };

  const handleMobileCredentialsChange = (event, titleSuffix) => {
    const isChecked = event.target.checked;
    let formValues = { ...visitorRegistrationFormValues };

    if (titleSuffix >= 1) {
      formValues[`mobileCredentials-${titleSuffix}`] = isChecked;
    } else {
      formValues["mobileCredentials"] = isChecked;
    }

    setVisitorRegistrationFormValues(formValues);
  };
  const displayResponse = (
    response: any,
    successText: string = "Request updated successfully",
    failText: string = "Not saved !!!"
  ) => {
    if (response.status == 201 || response.status == 200) {
      setMessage(successText);
      setRegistrationButtonStatus(true);
      setIsOpen(true);
      clearForm();
    } else {
      setIsOpen(true);
      setIsApiError(true);
      const responseMessage = response.data.response.data
        ? JSON.stringify(response.data.response.data)
        : failText;
      setMessage(responseMessage);
    }
  };
  const updateVmsBuildingRequests = (buildingRequestVmsId: number) => {
    visitors.map((idx) => {
      const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
      const indx = idx > 1 ? `${idx - 1}` : "";
      const VmsVisitorAccessRequest: BuildingAcccessVMSExternalVisitorModel = {
        location_id: locationId,
        card_type_id: cardType,
        start_date_time: getLocalDate(visitorRegistrationFormValues["fromDateTime"]),
        end_date_time: getLocalDate(visitorRegistrationFormValues["toDateTime"]),
        requester_id: userStore.me!.id,
        host_name_id: hostId,
        email_id: visitorRegistrationFormValues[`emailId${elementSuffix}`],
        first_name: visitorRegistrationFormValues[`firstName${elementSuffix}`],
        last_name: visitorRegistrationFormValues[`lastName${elementSuffix}`],
        company: visitorRegistrationFormValues[`companyName${elementSuffix}`],
        phone_number: visitorRegistrationFormValues[`phoneNumber${elementSuffix}`],
        mobile_credentials:
          visitorRegistrationFormValues[`mobileCredentials${indx}`] ||
          visitorRegistrationFormValues[`mobileCredentials${indx}`] == undefined
            ? true
            : false,
        under18: visitorRegistrationFormValues[`under18${elementSuffix}`] || false,
        vms_access_area: vmsAccessAreaId,
        meeting_name: visitorRegistrationFormValues["meetingName"],
        meeting_description: visitorRegistrationFormValues["meetingDescription"],
        notify_visitor: visitorRegistrationFormValues["notifyVisitor"],
        building_request_vms_id: buildingRequestVmsId,
      };
      bamStore
        .updateAccessRequest_VMS_External_Visitor(VmsVisitorAccessRequest)
        .then((response) => {
          if (response.status == 201 || response.status == 200) {
            console.log("Visitor has updated successfully in MWD", response.status);
            // setShowMainForm(false);
            // setMessage("Visitor has created successfully");
            // setRegistrationButtonStatus(true);
            // setIsOpen(true);
            // return response.status;
          }
        });
    });
  };
  const doVisitorRegistrationBooking = async () => {
    setIsSending(true);
    // setShowMainForm(false);
    if (isRequestAccess) {
      switch (+cardType) {
        // VMS Gallagher external visitor form
        case CardType["TEST - External Visitor - DO NOT USE"]:
          if (checkForm(cardType, visitorRegistrationRequiredFormFields)) {
            let parent_request_id = 0;
            let submissionsResults: { result: boolean; response: any }[] = [];
            let buildingRequestVmsId = 0;
            const VisitorAccessRequest: GallagherVMSIntergationUpdateModel = {
              location_id: locationId,
              card_type_id: cardType,
              start_date_time: getLocalDate(visitorRegistrationFormValues["fromDateTime"]),
              end_date_time: getLocalDate(visitorRegistrationFormValues["toDateTime"]),
              requester_id: userStore.me!.id,
              host_name_id: hostId,
              card_holder: get_visitors_email(),
              card_holder_first_name: get_visitors_firstname(),
              card_holder_last_name: get_visitors_lastname(),
              card_holder_company: get_visitors_companyname(),
              card_holder_phone_num: get_visitors_phonenumber(),
              vms_access_area: vmsAccessAreaId,
              card_holder_mobile_cred: get_mobileCredentialsFlag(),
              card_holder_under18: get_childVisitorsFlag(),
              meeting_name: visitorRegistrationFormValues["meetingName"],
              meeting_description: visitorRegistrationFormValues["meetingDescription"],
              notify_visitor: visitorRegistrationFormValues["notifyVisitor"],
              meeting_room: visitorRegistrationFormValues["meetingRoom"],
              building_request_vms_id: vmsMeetingId,
            };
            bamStore
              .updateAccessRequest_Gallagher_VMS_External_Visitor(
                VisitorAccessRequest,
                vmsMeetingId
              )
              .then((response) => {
                if (response.data.building_request_vms_id) {
                  buildingRequestVmsId = response.data.building_request_vms_id;
                  updateVmsBuildingRequests(buildingRequestVmsId);
                }
                if (response.status == 201 || response.status == 200) {
                  console.log("response.status", response.status);
                  if (parent_request_id == 0) {
                    parent_request_id = response.data.visitor_id;
                  }
                  setMessage("Visitor created successfully");
                  setRegistrationButtonStatus(true);
                  setIsOpen(true);
                  setShowMainForm(false);
                  submissionsResults.push({ result: true, response });
                } else {
                  setIsOpen(true);
                  setIsApiError(true);
                  setMessage("Not saved!!");
                  setShowMainForm(false);
                  submissionsResults.push({ result: false, response });
                }
              });
            const failures = submissionsResults.filter((res) => !res.result);
            console.log("failures: ", failures);
            if (failures && failures.length == 0) {
              clearForm();
            } else {
              setIsOpen(true);
              setIsApiError(true);
              setMessage(`${failures.length} requests had issues`);
            }
            setIsSending(false);
          } else {
            console.log("Form invalid", visitorRegistrationErrorValues);
            setIsSending(false);
          }
          break;
      }
    }
  };
  const [isSending, setIsSending] = useState(false);
  const handleClose = () => {};
  const [form_prerequisites, setForm_Prerequisites] = useState<Form_prequisite[]>([]);
  const [visitorCount, setVisitorCount] = useState<number>(0);
  const [visitors, setVisitors] = useState<number[]>([1]);
  const [validVisitor, setValidVisitor] = useState(true);
  const [visitorLimit, setVisitorLimit] = useState(false);
  const [disableUpdateFields, setDisableUpdateFields] = useState(false);
  const getInitalMobileCredFlag = (visitorsCount) => {
    let mobileCredChecked = { ...mobileCredFlag };
    const indx = visitorsCount;
    mobileCredFlag[`mobileCredentials${indx}`] = true;
  };
  const getVmsVisitorCount = (value: number) => {
    (async () => {
      if (value != undefined) {
        const res = await bamStore.loadSingleMeeting(vmsMeetingId);
        setVmsMeetings(res);
        setVisitorCount(res.length);
      } else {
        setVisitorCount(0);
      }
    })();
  };
  const vmsVisitorCountHandler = useCallback(
    debounce((value: number) => getVmsVisitorCount(value), 1000),
    []
  );
  const addDynamicVisitor = () => {
    const newVisitors = [...visitors];
    vmsVisitorCountHandler(vmsMeetingId);
    if (vmsMeetingId && visitorCount != 0) {
      mapFormFields();
      const newVisitorCount = visitorCount - 1;
      setVisitorCount(newVisitorCount);
      if (newVisitorCount < 1) {
        setValidVisitor(false);
      } else {
        if (visitorCount != 0) {
          newVisitors.push(visitors.length + 1);
          setVisitors(newVisitors);
          getInitalMobileCredFlag(visitors.length + 1);
        }
      }
    }
  };
  const addVisitor = () => {
    const newVisitors = [...visitors];
    newVisitors.push(visitors.length + 1);
    if (newVisitors.length < 21) {
      setVisitors(newVisitors);
      getInitalMobileCredFlag(visitors.length + 1);
    } else {
      console.log("More than 20 visitors can be added in a single request!");
      setVisitorLimit(true);
    }
  };
  const removeVisitor = (indexToRemove: number) => {
    const newVisitors = visitors.slice(0, -1);

    const updatedFormValues = { ...visitorRegistrationFormValues };
    const fieldsToUpdate = [
      "emailId",
      "firstName",
      "lastName",
      "companyName",
      "phoneNumber",
      "under18",
      "mobileCredentials",
    ];

    // Shift the values for the removed visitor and all subsequent visitors
    for (let i = indexToRemove; i <= visitors.length; i++) {
      const currentSuffix = i > 1 ? `-${i - 1}` : "";
      const nextSuffix = i > 1 ? `-${i}` : "-1";

      fieldsToUpdate.forEach((field) => {
        if (i === visitors.length) {
          // Remove the last visitor's fields
          delete updatedFormValues[`${field}${currentSuffix}`];
          //console.log("skip delete updatedFormValues",updatedFormValues)
        } else {
          // Shift values from the next visitor to the current position
          updatedFormValues[`${field}${currentSuffix}`] =
            updatedFormValues[`${field}${nextSuffix}`];
        }
      });
    }
    setVisitorRegistrationFormValues(updatedFormValues);
    setVisitors(newVisitors);
  };

  const makeAccessAreaRequired = (status: boolean, numberOfVisitors: number = 1) => {
    let requiredFields = { ...visitorRegistrationRequiredFormFields };
    // go through formFields and set accessArea fields
    if (requiredFields) {
      requiredFields.map((fld) => {
        if (fld.fieldName.startsWith("accessAreaId")) fld.isRequired = status;
        if (fld.fieldName.startsWith("accessReason")) fld.isRequired = status;
      });
      setVisitorRegistrationRequiredFields(requiredFields);
    }
  };
  const makeCompanyRequired = (status: boolean, numberOfVisitors: number = 1) => {
    let requiredFields = { ...visitorRegistrationRequiredFormFields };
    console.log("rf: ", requiredFields);
    // go through formFields and make company and phoneRequired
    if (requiredFields) {
      requiredFields.map((fld) => {
        if (fld.fieldName.startsWith("email")) fld.isRequired = status;
        if (fld.fieldName.startsWith("firstName")) fld.isRequired = status;
        if (fld.fieldName.startsWith("lastName")) fld.isRequired = status;
        if (fld.fieldName.startsWith("company")) fld.isRequired = status;
        if (fld.fieldName.startsWith("phone")) fld.isRequired = status;
      });
      setVisitorRegistrationRequiredFields(requiredFields);
    }
  };
  const drawSimpleTextField = (
    fieldName: string,
    fieldTitle: string,
    isRequired: boolean,
    placeholder: string
  ) => {
    return (
      <Grid container alignItems="center">
        {formFieldTitle(`${fieldTitle}${isRequired ? "*" : ""}`)}
        <Grid item xs={12} sm={8}>
          <TextField
            key={`t${fieldName}`}
            id={`f${fieldName}`}
            disabled={disableUpdateFields}
            placeholder={t(placeholder)}
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            className={classes.textField}
            onChange={handleFieldChange}
            value={visitorRegistrationFormValues[fieldName]}
            name={`${fieldName}`}
            error={visitorRegistrationErrorValues[fieldName]}
            helperText={visitorRegistrationHelperTextValues[fieldName]}
          />
        </Grid>
      </Grid>
    );
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStyles(name, multiSelect, theme) {
    return {
      fontWeight:
        multiSelect.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const [accessArea, setAccessArea] = useState<string[]>([]);
  const [vmsAccessArea, setVmsAccessArea] = useState<string[]>([]);
  const [locationAccessAreas, setLocationAccessAreas] = useState<Special_Access_Area[]>([]);
  const [vmsLocationAccessAreas, setVMSLocationAccessAreas] = useState<Special_Access_Area[]>([]);
  const [vmsMeetings, setVmsMeetings] = useState<Vms_Meeting_Items[]>([]);
  const getRequiredFlag = (fieldName: string) => {
    return isRequired(fieldName) ? "*" : "";
  };
  const isRequired = (fieldName: string) => {
    if (!visitorRegistrationRequiredFormFields) return false;
    const matchingFields = visitorRegistrationRequiredFormFields.filter(
      (field) => field.fieldName === fieldName && field.isRequired
    );
    return matchingFields && matchingFields.length > 0;
  };

  const fetchFilteredVisitorsByEmail = useCallback(
    debounce(async (email) => {
      if (email && email.length > 3) {
        try {
          const response = await peopleStore.getFilteredVisitorsByName(email);
          if (response) {
            setEmailOptions(response);
          } else {
            setEmailOptions([]);
          }
        } catch (error) {
          console.error("Error fetching visitor details:", error);
        }
      } else {
        setEmailOptions([]);
      }
    }, 1000),
    []
  );
  const handleEmailChange = (event, value, titleSuffix) => {
    let emailid = value;
    console.log("Handle email change is being hit", emailid);

    if (value) {
      if (titleSuffix >= 1) {
        let formValues = { ...visitorRegistrationFormValues };
        formValues[`emailId-${titleSuffix}`] = emailid;
        setVisitorRegistrationFormValues(formValues);
        console.log("Going into suffix");
      } else {
        setVisitorRegistrationFormValues({
          ...visitorRegistrationFormValues,
          emailId: emailid,
        });
      }
    }

    // Ensure email options are fetched correctly
    if (value) {
      fetchFilteredVisitorsByEmail(value);
    }
  };

  const handleEmailSelect = (event, value, titleSuffix) => {
    if (value) {
      const formValues = {
        [`firstName${titleSuffix >= 1 ? `-${titleSuffix}` : ""}`]: value.first_name,
        [`lastName${titleSuffix >= 1 ? `-${titleSuffix}` : ""}`]: value.last_name,
        [`emailId${titleSuffix >= 1 ? `-${titleSuffix}` : ""}`]: value.email,
        [`companyName${titleSuffix >= 1 ? `-${titleSuffix}` : ""}`]: value.company,
        [`phoneNumber${titleSuffix >= 1 ? `-${titleSuffix}` : ""}`]: value.phone_number,
      };
      setVisitorRegistrationFormValues((prev) => ({ ...prev, ...formValues }));
    }
  };

  const mapFormFields = () => {
    let formValues = { ...visitorRegistrationFormValues };
    if (vmsMeetings) {
      setCardTypesForLocation(bamStore.location_cardTypes[vmsMeetings[0].location_id]);
      setCardType(vmsMeetings[0].card_type);
      setLocationId(vmsMeetings[0].location_id);
      setVmsAccessAreaId(vmsMeetings[0].vms_access_area_id);
      setVmsAccessAreaName(vmsMeetings[0].vms_access_area);
      setHostId(vmsMeetings[0].host_name_id);
      formValues["locationId"] = vmsMeetings[0].location_name;
      formValues["cardTypeId"] = CardType[vmsMeetings[0].card_type];
      formValues["vmsAccessArea"] = vmsMeetings[0].vms_access_area;
      formValues["hostNameId"] =
        vmsMeetings[0].host_email +
        " | " +
        vmsMeetings[0].host_name_first_name +
        " " +
        vmsMeetings[0].host_name_last_name;
      formValues["fromDateTime"] = vmsMeetings[0].start_date_time;
      formValues["toDateTime"] = vmsMeetings[0].end_date_time;
      formValues["meetingName"] = vmsMeetings[0].meeting_name;
      formValues["meetingRoom"] = vmsMeetings[0].meeting_room;
      formValues["meetingDescription"] = vmsMeetings[0].meeting_description;
      setVisitorRegistrationFormValues(formValues);
      vmsMeetings.forEach((idx, pos) => {
        const position: number = pos + 1;
        const titleSuffix = position > 1 ? ` ${position - 1}` : "";
        const elementSuffix = position > 1 ? `-${position - 1}` : "";
        // const indx = position > 1 ? `-${position - 1}` : "";
        const email_id = idx.email_id;
        const first_name = idx.first_name;
        const last_name = idx.last_name;
        const company_name = idx.company;
        const phone_number = idx.phone_number;
        const under18 = idx.under18;
        const mobile_credentials = idx.card_holder_mobile_cred;
        formValues[`emailId${elementSuffix}`] = email_id;
        formValues[`firstName${elementSuffix}`] = first_name;
        formValues[`lastName${elementSuffix}`] = last_name;
        formValues[`companyName${elementSuffix}`] = company_name;
        formValues[`phoneNumber${elementSuffix}`] = phone_number;
        formValues[`under18${elementSuffix}`] = under18;
        formValues[`mobileCredentials${elementSuffix}`] = mobile_credentials;
        setVisitorRegistrationFormValues(formValues);
      });
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (visitorRegistrationFormValues["fromDateTime"]) {
      let visitStartDate = visitorRegistrationFormValues["fromDateTime"];
      let currentDate = moment(new Date()).format("YYYY-MM-DDTHH:mm");
      if (moment(visitStartDate).isAfter(currentDate)) {
        if (isMounted) setDisableUpdateFields(false);
      } else {
        if (isMounted) setDisableUpdateFields(true);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [visitorRegistrationFormValues["fromDateTime"]]);

  // Add or remove multiple visitor funcationality for VMS gallagher for external visitors
  const drawVmsExternalVisitor = (idx: number) => {
    const titleSuffix = idx > 1 ? ` ${idx - 1}` : "";
    const elementSuffix = idx > 1 ? `-${idx - 1}` : "";
    const indx = idx > 1 ? `${idx - 1}` : "";

    return (
      <Fragment key={idx}>
        <Grid container alignItems="center">
          {!disableUpdateFields && visitors.length > 1 && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: smAndUp ? "-25px" : "0",
                marginRight: smAndUp ? "0" : "10px",
              }}
            >
              <Tooltip title="exclude this visitor">
                <Box
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    color: bhpColor.blueGrey1,
                  }}
                  onClick={() => removeVisitor(idx)}
                >
                  {!smAndUp && `Remove visitor`} <IndeterminateCheckBoxOutlinedIcon />
                </Box>
              </Tooltip>
            </Grid>
          )}

          {formFieldTitle(`Email${titleSuffix}${getRequiredFlag(`emailId`)}`)}
          <Grid item xs={12} sm={8}>
            <Autocomplete
              key={`tEmail${elementSuffix}`}
              options={emailOptions || []}
              getOptionLabel={(option: User) => option.email || ""}
              freeSolo
              onInputChange={(event, value) => handleEmailChange(event, value, indx)}
              onChange={(event, value) => handleEmailSelect(event, value, indx)}
              inputValue={visitorRegistrationFormValues[`emailId${elementSuffix}`] || ""}
              //value={selectedValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  key={`tEmail${elementSuffix}`}
                  id={`emailId${elementSuffix}`}
                  disabled={disableUpdateFields}
                  placeholder={t("Enter an email (required)")}
                  type="email"
                  size="small"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  name={`emailId${elementSuffix}`}
                  error={visitorRegistrationErrorValues[`emailId${elementSuffix}`]}
                  helperText={visitorRegistrationHelperTextValues[`emailId${elementSuffix}`]}
                />
              )}
            />
          </Grid>
        </Grid>
        {drawSimpleTextField(
          `firstName${elementSuffix}`,
          `First Name${titleSuffix}`,
          isRequired(`firstName`),
          "Enter a first name (required)"
        )}
        {drawSimpleTextField(
          `lastName${elementSuffix}`,
          `Last Name${titleSuffix}`,
          isRequired(`lastName`),
          "Enter a last name (required)"
        )}
        {drawSimpleTextField(
          `companyName${elementSuffix}`,
          `Company${titleSuffix}`,
          isRequired(`companyName`),
          "Enter a company name"
        )}
        {drawSimpleTextField(
          `phoneNumber${elementSuffix}`,
          `Mobile Number${titleSuffix}`,
          isRequired(`phoneNumber`),
          "Enter a phone number"
        )}
        <Grid container alignItems="center">
          {formFieldTitle(`Mobile Access${titleSuffix}${getRequiredFlag(`mobileCredentials`)}`)}
          <Grid
            item
            xs={12}
            sm={8}
            style={{
              alignItems: "center",
              color: bhpColor.blueGrey1,
              fontStyle: "italic",
            }}
          >
            <Checkbox
              style={{ paddingLeft: 0 }}
              key={`mobileCredentials${elementSuffix}`}
              id={`mobileCredentials${elementSuffix}`}
              name={`mobileCredentials${elementSuffix}`}
              checked={visitorRegistrationFormValues[`mobileCredentials${elementSuffix}`]}
              disabled={disableUpdateFields}
              onChange={(evt) => handleMobileCredentialsChange(evt, indx)}
            />{" "}
            Check this box to request Mobile Access
          </Grid>
          {formFieldTitle(`Visitor is under 18?${titleSuffix}${getRequiredFlag(`under18`)}`)}
          <Grid
            item
            xs={12}
            sm={8}
            style={{
              alignItems: "center",
              color: bhpColor.blueGrey1,
              fontStyle: "italic",
            }}
          >
            <Checkbox
              style={{ paddingLeft: 0 }}
              key={`under18${elementSuffix}`}
              id={`under18${elementSuffix}`}
              name={`under18${elementSuffix}`}
              checked={visitorRegistrationFormValues[`under18${elementSuffix}`]}
              disabled={disableUpdateFields}
              onChange={(evt) => hangleChildVisitorChange(evt, indx)}
            />{" "}
            Check this box if the visitor is under 18
          </Grid>
        </Grid>
      </Fragment>
    );
  };
  const copyAsEndDateIfEndEmpty = () => {
    let formValues = { ...visitorRegistrationFormValues };
    const fromDate = formValues["fromDateTime"];
    const toDate = formValues["toDateTime"];
    formValues["toDateTime"] = fromDate;
    setVisitorRegistrationFormValues(formValues);
  };
  const [cardType, setCardType] = useState<CardType>(CardType.None);
  const [cardTypesForLocation, setCardTypesForLocation] = useState<Form_prequisite[]>([]);
  const getCardTypeBlurb = (typ: CardType, locationId: number | null) => {
    if (!cardTypesForLocation) return getDefaultCardTypeBlurb(typ);
    const theCard = cardTypesForLocation.filter((crd) => crd.card_type == +typ);
    if (theCard[0]) return theCard[0].prerequisites_data;
    return getDefaultCardTypeBlurb(typ);
  };
  const [showMainForm, setShowMainForm] = useState(true);
  const [visitorRegistrationRequiredFormFields, setVisitorRegistrationRequiredFields] = useState<
    FormElement[]
  >(registrationFormFields["visitor"]);
  const setRequiredFormFields = (cardType: CardType) => {
    setVisitorRegistrationRequiredFields(registrationFormFields[CardType[cardType]]);
  };
  useEffect(() => {
    const hasLocation = !["", "0"].includes(`${visitorRegistrationFormValues["locationId"]}`);
    const showForm = isRequestAccess && cardType !== CardType.None && hasLocation;
    if (isRequestAccess) {
      // setShowMainForm(true);
      setRequiredFormFields(cardType);
    }
  }, [isRequestAccess, cardType, visitorRegistrationFormValues]);

  useEffect(() => {
    if (isRequestAccess) {
      bamStore.loadBAMPrerequisites().then((pre) => {
        setForm_Prerequisites(pre);
      });
    }
    bamStore.loadBAMSpecialAccessAreas().then((saas) => {
      setLocationAccessAreas(bamStore.location_saas[locationId]);
    });
    bamStore.loadVMSAccessGroupAreas().then((saas) => {
      setVMSLocationAccessAreas(bamStore.vms_location_saas[locationId]);
    });
  }, []);

  const isStartDateInPast = () => {
    const startDate = new Date(visitorRegistrationFormValues["fromDateTime"]);
    return startDate < new Date();
  };
  return (
    <Fragment>
      <Topbar title={topBarTitle} subtitle={topBarSubTitle} breadcrumb={topBarBreadcrumb} />
      <Box position="relative" p={mdAndUp ? 3 : 0} className={classes.form}>
        <Box py={2}>
          <Typography style={{ fontSize: 26, marginLeft: 15 }} variant="h1">
            {"Meeting Information"}
          </Typography>
          <Box my={4} display={showRegistrationForm}>
            {validVisitor && addDynamicVisitor()}
            <Grid container alignItems="center">
              {formFieldTitle("Location*")}
              <Grid item xs={12} sm={8}>
                <TextField
                  disabled
                  // key={`t${locatonId}`}
                  // id={`f${locatonId}`}
                  type="text"
                  size="small"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  // onChange={handleFieldChange}
                  value={visitorRegistrationFormValues["locationId"]}
                  name={`${locationId}`}
                  // error={visitorRegistrationErrorValues[fieldName]}
                  // helperText={visitorRegistrationHelperTextValues[fieldName]}
                />
              </Grid>
            </Grid>
            {isRequestAccess && (
              <Grid container alignItems="center">
                {formFieldTitle("Access Type*")}
                <Grid item xs={12} sm={8}>
                  <TextField
                    disabled
                    // key={`t${locatonId}`}
                    // id={`f${locatonId}`}
                    type="text"
                    size="small"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    // onChange={handleFieldChange}
                    value={visitorRegistrationFormValues["cardTypeId"]}
                    // name={`${cardTypeId}`}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container alignItems="center">
              {formFieldTitle(" ")}
              <Grid item xs={12} sm={8} style={{ alignItems: "center", color: bhpColor.blueGrey1 }}>
                <Box
                  style={{
                    backgroundColor: bhpColor.blueGrey4,
                    padding: "12px",
                    width: smAndUp ? `calc(100% - 150px)` : `calc(100% - 10px)`,
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getCardTypeBlurb(cardType, locationId) || "",
                    }}
                  />
                </Box>
                {isStartDateInPast() && (
                  <Grid item xs={12} sm={8}>
                    <Typography
                      variant="body2"
                      style={{
                        color: bhpColor.orange1,
                        padding: "12px",
                        fontStyle: "italic",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                      }}
                    >
                      The meeting has already started. Other than end date/time, please contact the
                      Concierge Team to modify this meeting.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {showMainForm && (
              <Fragment>
                {/* Add or remove multiple visitor logic for VMS gallagher for external visitors */}
                {vmsMeetingId ? (
                  <>
                    {visitors.map((idx) => drawVmsExternalVisitor(idx))}
                    {!disableUpdateFields && (
                      <Grid
                        container
                        style={{
                          marginTop: smAndUp ? "0" : "0",
                          marginBottom: smAndUp ? "0" : "0",
                          height: "0px",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            marginRight: smAndUp ? 0 : "10px",
                          }}
                        >
                          <Box
                            style={{
                              paddingLeft: "8px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                              fontSize: "14px",
                              color: bhpColor.blueGrey1,
                            }}
                          >
                            <Tooltip title="Include another visitor">
                              <Box
                                onClick={() => addVisitor()}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <PersonAddIcon />
                                Click to include another visitor
                              </Box>
                            </Tooltip>{" "}
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                    {[CardType["TEST - External Visitor - DO NOT USE"]].includes(+cardType) &&
                      visitorLimit && (
                        <Grid
                          container
                          style={{
                            marginTop: smAndUp ? "0" : "0",
                            marginBottom: smAndUp ? "0" : "0",
                            height: "4px",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: smAndUp ? 0 : "10px",
                            }}
                          >
                            <Box
                              style={{
                                paddingLeft: "15px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                                fontSize: "14px",
                                color: bhpColor.orange1,
                              }}
                            >
                              More than 20 visitors can not be added in a single request. Please
                              contact reception.
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    {
                      <Grid container alignItems="center">
                        {formFieldTitle(`Visit Type${getRequiredFlag("vmsAccessArea")}`)}
                        <Grid item xs={12} sm={8}>
                          <TextField
                            disabled
                            // key={`t${locatonId}`}
                            // id={`f${locatonId}`}
                            type="text"
                            size="small"
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            value={visitorRegistrationFormValues["vmsAccessArea"]}
                            // name={`${cardTypeId}`}
                          />
                        </Grid>
                      </Grid>
                    }
                    {
                      <Grid container alignItems="center">
                        {formFieldTitle(`Host Name${getRequiredFlag("hostNameId")}`)}
                        <Grid item xs={12} sm={8}>
                          <TextField
                            disabled
                            // key={`t${hostNameId}`}
                            // id={`f${hostNameId}`}
                            type="text"
                            size="small"
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            value={visitorRegistrationFormValues["hostNameId"]}
                            // name={`${hostNameId}`}
                          />
                        </Grid>
                      </Grid>
                    }
                    {visitorRegistrationFormValues["fromDateTime"] && (
                      <Grid container alignItems="center">
                        {formFieldTitle("Start Date / Time*")}
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          style={{
                            flexBasis: smAndUp ? `calc(67% - 155px)` : `calc(100% - 10px)`,
                            maxWidth: "100%",
                            flexGrow: 0,
                          }}
                        >
                          <TextField
                            key="tFromDate"
                            id="fromDate"
                            disabled={disableUpdateFields}
                            placeholder={t("Enter a from date time (required)")}
                            type="datetime-local"
                            size="small"
                            variant="outlined"
                            fullWidth
                            onChange={handleFieldChange}
                            onBlur={copyAsEndDateIfEndEmpty}
                            // value={visitorRegistrationFormValues["fromDateTime"]}
                            defaultValue={visitorRegistrationFormValues["fromDateTime"].slice(
                              0,
                              16
                            )}
                            name="fromDateTime"
                            error={visitorRegistrationErrorValues["fromDateTime"]}
                            helperText={visitorRegistrationHelperTextValues["fromDateTime"]}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {visitorRegistrationFormValues["fromDateTime"] && (
                      <Grid container alignItems="center">
                        {formFieldTitle(`End Date / Time${isRequired("toDateTime") ? "*" : ""}`)}
                        <Grid
                          item
                          xs={8}
                          style={{
                            flexBasis: smAndUp ? `calc(67% - 155px)` : `calc(100% - 10px)`,
                            maxWidth: "100%",
                            flexGrow: 0,
                          }}
                        >
                          <TextField
                            key="tToDate"
                            id="toDate"
                            placeholder={t("Enter a to date time (required)")}
                            type="datetime-local"
                            size="small"
                            variant="outlined"
                            fullWidth
                            onChange={handleFieldChange}
                            // value={visitorRegistrationFormValues["toDateTime"]}
                            defaultValue={visitorRegistrationFormValues["toDateTime"].slice(0, 16)}
                            name="toDateTime"
                            error={visitorRegistrationErrorValues["toDateTime"]}
                            helperText={visitorRegistrationHelperTextValues["toDateTime"]}
                            style={{ fontSize: ".9rem" }}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {[CardType["TEST - External Visitor - DO NOT USE"]].includes(+cardType) &&
                      (vmsStartDateValidation || vmsEndDateValidation) && (
                        <Grid
                          container
                          style={{
                            marginTop: smAndUp ? "0" : "0",
                            marginBottom: smAndUp ? "0" : "0",
                            height: "4px",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: smAndUp ? 0 : "10px",
                            }}
                          >
                            <Box
                              style={{
                                paddingLeft: "15px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                                fontSize: "14px",
                                color: bhpColor.orange1,
                              }}
                            >
                              This visit is scheduled before / after business hours, please arrange
                              with Reception to collect Access Cards in advance during business
                              hours.
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    {/* VMS Gallagher form fields-meeting name and meeting description */}
                    {
                      <Grid container>
                        <Grid
                          item
                          xs={3}
                          style={{ whiteSpace: "nowrap", alignItems: "flex-start" }}
                        >
                          {formFieldTitle(`Meeting Room${getRequiredFlag("meetingRoom")}`)}
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <TextField
                            // disabled
                            // key={`t${meetingRoom}`}
                            // id={`f${meetingRoom}`}
                            disabled={disableUpdateFields}
                            placeholder={`${t("Enter Meeting Room")}`}
                            type="text"
                            size="small"
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            onChange={handleFieldChange}
                            value={visitorRegistrationFormValues["meetingRoom"]}
                            name={"meetingRoom"}
                            helperText={visitorRegistrationHelperTextValues["meetingRoom"]}
                          />
                        </Grid>
                      </Grid>
                    }
                    {
                      <Grid container>
                        <Grid
                          item
                          xs={3}
                          style={{ whiteSpace: "nowrap", alignItems: "flex-start" }}
                        >
                          {formFieldTitle(`Meeting Name${getRequiredFlag("meetingName")}`)}
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <TextField
                            disabled
                            // key={`t${meetingName}`}
                            // id={`f${meetingName}`}
                            placeholder={`${t("Enter Meeting Name")}`}
                            type="text"
                            size="small"
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            value={visitorRegistrationFormValues["meetingName"]}
                            helperText={visitorRegistrationHelperTextValues["meetingName"]}
                            // name={`${meetingName}`}
                          />
                        </Grid>
                      </Grid>
                    }
                    {
                      <Grid container>
                        <Grid
                          item
                          xs={3}
                          style={{ whiteSpace: "nowrap", alignItems: "flex-start" }}
                        >
                          {formFieldTitle(
                            `Meeting Description${getRequiredFlag("meetingDescription")}`
                          )}
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <TextField
                            key="tmeetingDescription"
                            id="meetingDescription"
                            disabled={disableUpdateFields}
                            multiline
                            minRows={4}
                            placeholder={`${t("Start typing..")}${charLimit} ${t(
                              "character limit"
                            )}`}
                            type="text"
                            size="small"
                            variant="outlined"
                            fullWidth
                            inputProps={{ maxLength: charLimit }}
                            className={classes.textField}
                            onChange={handleFieldChange}
                            value={visitorRegistrationFormValues["meetingDescription"] || ""}
                            error={visitorRegistrationErrorValues["meetingDescription"]}
                            helperText={visitorRegistrationHelperTextValues["meetingDescription"]}
                            name="meetingDescription"
                          />
                          <Box
                            style={{
                              maxWidth: smAndUp ? "calc(100% - 150px)" : `calc(100% - 10px)`,
                              display: "flex",
                              justifyContent: "end",
                              fontSize: "smaller",
                              color: bhpColor.blueGrey3,
                            }}
                          >
                            {`${
                              charLimit - visitorRegistrationFormValues["meetingDescription"].length
                            } ${t("left")}`}
                          </Box>
                          {isStartDateInPast() && (
                            <Grid item xs={12} sm={8}>
                              <Typography
                                variant="body2"
                                style={{
                                  color: bhpColor.orange1,
                                  padding: "12px",
                                  fontStyle: "italic",
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "16px",
                                }}
                              >
                                The meeting has already started. Other than end date/time, please
                                contact the Concierge Team to modify this meeting.
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    }
                    {/* Notify visitor functionality */}
                    {
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          <table
                            style={{
                              width: smAndUp ? `calc(100% - 134px)` : `calc(100% - 10px)`,
                            }}
                          >
                            <tbody>
                              <tr>
                                <td align="left">
                                  <Checkbox
                                    name="notifyVisitor"
                                    color="primary"
                                    onChange={handleFieldChange}
                                  />
                                  Notify Visitor of registration
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid>
                      </Grid>
                    }
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <table
                          style={{
                            width: smAndUp ? `calc(100% - 134px)` : `calc(100% - 10px)`,
                          }}
                        >
                          <tbody>
                            <tr>
                              <td align="left">
                                <Button
                                  style={{ marginTop: 8, marginRight: 8 }}
                                  onClick={doVisitorRegistrationBooking}
                                  variant="contained"
                                  disableElevation
                                  color="primary"
                                  disabled={registrationButtonStatus}
                                >
                                  {t("Submit")}
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <div>"Meeting details is loading"</div>
                )}
              </Fragment>
            )}
          </Box>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={classes.saveSuccess}
        autoHideDuration={displaySettings.snackDelay}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          if (!isApiError) {
            history.push("/meetings");
            // history.goBack();
          } else {
            setIsApiError(false);
          }
        }}
        message={message}
        key={"top" + "right"}
      />
      <LoadingZone isLoading={isSending} />
    </Fragment>
  );
});
export default MeetingDetails;
