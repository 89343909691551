import {
  FormControl,
  Grid,
  createStyles,
  makeStyles,
  MenuItem,
  NativeSelect,
  Paper,
  Select,
  Theme,
} from "@material-ui/core";
import { useState } from "react";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: "24px",
      width: "50%",
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: "24px",
      "& .MuiGrid-item": {
        padding: "12px",
        borderBottom: "1px solid #cacaca",
      },
    },
  })
);

const Tester = (props: any) => {
  const [val, setVal] = useState("10:15");
  const classes = innerStyles();
  const onChange = (newVal: any) => {
    setVal(`${newVal}`);
  };

  return (
    <Paper className={classes.paper}>
      <Grid container>
        <Grid item xs={6} style={{ borderTop: "1px solid #cacaca" }}>
          HTML Select
        </Grid>
        <Grid item xs={6} style={{ borderTop: "1px solid #cacaca" }}>
          <FormControl>
            <select value={val} onChange={(e) => onChange(e.target.value)}>
              <option value={"09:00"} label="9:00am">
                9:00am
              </option>
              <option value={"09:15"} label="9:150am">
                9:15am
              </option>
              <option value={"09:30"} label="9:30am">
                9:30am
              </option>
              <option value={"09:45"} label="9:45am">
                9:45am
              </option>
              <option value={"10:00"} label="10:00am">
                10:00am
              </option>
              <option value={"10:15"} label="10:15am">
                10:15am
              </option>
              <option value={"10:30"} label="10:30am">
                10:30am
              </option>
              <option value={"10:45"} label="10:45am">
                10:45am
              </option>
              <option value={"11:00"} label="11:00am">
                11:00am
              </option>
            </select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          Material UI Select - MenuItem
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <Select value={val} onChange={(e) => onChange(e.target.value)}>
              <MenuItem value={"09:00"}>9:00am</MenuItem>
              <MenuItem value={"09:15"}>9:15am</MenuItem>
              <MenuItem value={"09:30"}>9:30am</MenuItem>
              <MenuItem value={"09:45"}>9:45am</MenuItem>
              <MenuItem value={"10:00"}>10:00am</MenuItem>
              <MenuItem value={"10:15"}>10:15am</MenuItem>
              <MenuItem value={"10:30"}>10:30am</MenuItem>
              <MenuItem value={"10:45"}>10:45am</MenuItem>
              <MenuItem value={"11:00"}>11:00am</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          Material UI Select Native
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <Select native value={val} onChange={(e) => onChange(e.target.value)}>
              <option value={"09:00"} label="9:00am">
                9:00am
              </option>
              <option value={"09:15"} label="9:150am">
                9:15am
              </option>
              <option value={"09:30"} label="9:30am">
                9:30am
              </option>
              <option value={"09:45"} label="9:45am">
                9:45am
              </option>
              <option value={"10:00"} label="10:00am">
                10:00am
              </option>
              <option value={"10:15"} label="10:15am">
                10:15am
              </option>
              <option value={"10:30"} label="10:30am">
                10:30am
              </option>
              <option value={"10:45"} label="10:45am">
                10:45am
              </option>
              <option value={"11:00"} label="11:00am">
                11:00am
              </option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          Material UI NativeSelect
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <NativeSelect value={val} onChange={(e) => onChange(e.target.value)}>
              <option value={"09:00"} label="9:00am">
                9:00am
              </option>
              <option value={"09:15"} label="9:150am">
                9:15am
              </option>
              <option value={"09:30"} label="9:30am">
                9:30am
              </option>
              <option value={"09:45"} label="9:45am">
                9:45am
              </option>
              <option value={"10:00"} label="10:00am">
                10:00am
              </option>
              <option value={"10:15"} label="10:15am">
                10:15am
              </option>
              <option value={"10:30"} label="10:30am">
                10:30am
              </option>
              <option value={"10:45"} label="10:45am">
                10:45am
              </option>
              <option value={"11:00"} label="11:00am">
                11:00am
              </option>
            </NativeSelect>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Tester;
