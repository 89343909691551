import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  TableCell,
  TableRow,
  Theme,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { bhpColor } from "styles/globals";
import Icon from "@mdi/react";
import { getIconPath } from "utils/icons";
import { ScheduleType } from "interfaces/Schedule";
import { isWeekend } from "utils/date";
import { capitalizeString } from "utils/string";
import { useEffect, useState } from "react";
//import useMediaQuery from "utils/useMediaQuery";
import { getWorkTypeColor } from "utils/backGroundColor";
import { getScheduleTypeString } from "utils/schedule";

interface TeamDetailsPlannerTabBodyProps {
  teamSchedule: any[];
  columnSpan?: number;
  teamScheduleWorkTypeChange: any;
  workType: ScheduleType | null;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    theGrid: {
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-columnsContainer": {
        border: 0,
      },
      "& .MuiDataGrid-cell": {
        border: 0,
      },
      "& .MuiDataGrid-root": {
        border: 0,
      },
      "& .MuiGrid-root": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .MuiGrid-item": {
        marginBottom: 5,
        borderRadius: 4,
        maxHeight: 32,
        maxWidth: 80,
      },
    },

    paper: {
      textAlign: "right",
      borderRadius: 4,
      fontSize: 16,
      fontWeight: "bold",
      color: bhpColor.blueGrey1,
    },
    icon: {
      alignSelf: "center",
      height: 20,
      color: bhpColor.white,
      marginTop: 4,
    },
    countBlock: {
      height: 32,
      width: 60,
      fontSize: 12,
    },
    iconBox: {
      width: 40,
      height: 32,
      borderRadius: 1,
      color: bhpColor.white,
      verticalAlign: "top",
      textAlign: "center",
    },
    tableBodyRow: {
      borderLeft: `1px solid ${bhpColor.backgroundGrey}`,
      borderRight: `1px solid ${bhpColor.backgroundGrey}`,
      "& .MuiTableCell-root": {
        borderBottom: 0,
        padding: 2,
        paddingTop: 6,
      },

      "& .MuiTableBody-root": {
        paddingTop: 20,
      },
    },
    weekend: { backgroundColor: `${bhpColor.blueGrey4}30` },
    weekendCount: {
      backgroundColor: bhpColor.white,
      "& .MuiPaper-root": {
        backgroundColor: bhpColor.white,
      },
    },
    weekDayCount: {
      backgroundColor: bhpColor.backgroundGrey,
      "& .MuiPaper-root": {
        backgroundColor: bhpColor.backgroundGrey,
      },
    },
    gridCellHover: {
      "&:hover": {
        cursor: "pointer",
        border: `2px solid ${bhpColor.blueGrey4}`,
      },
    },
    gridCellClick: {
      border: `2px solid ${bhpColor.blueGrey2}`,
      "&:hover": {
        cursor: "pointer",
        border: `2px solid ${bhpColor.blueGrey2}`,
      },
    },
  })
);

const TeamDetailsPlannerTabBody = observer((props: TeamDetailsPlannerTabBodyProps) => {
  const { teamSchedule, columnSpan, teamScheduleWorkTypeChange, workType } = props;
  const classes = innerStyles();
  const [selectedCellDate, setselectedCellDate] = useState<string | null>(null);
  const [selectedCellWorkType, setselectedCellWorkType] = useState<ScheduleType | null>(null);
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));

  let tableRows: JSX.Element[] = [];
  const handleCellClick = (scheduleDate: string, workType: ScheduleType) => {
    teamScheduleWorkTypeChange(scheduleDate, workType);
    setselectedCellDate(scheduleDate);
    setselectedCellWorkType(workType);
  };
  for (const type of [ScheduleType.OFFICE, ScheduleType.REMOTE, ScheduleType.OFF]) {
    tableRows.push(
      <TableRow className={classes.tableBodyRow} key={type}>
        <TableCell colSpan={columnSpan}>
          <Box className={classes.theGrid}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                style={{
                  minWidth: mdAndUp ? 144 : 20,
                  justifyContent: "left",
                  backgroundColor: bhpColor.backgroundGrey,
                }}
              >
                <Paper
                  elevation={0}
                  className={classes.paper}
                  style={{ backgroundColor: bhpColor.backgroundGrey }}
                >
                  <Box
                    className={classes.iconBox}
                    style={{
                      display: "inline-block",
                      backgroundColor: getWorkTypeColor(type),
                    }}
                  >
                    <Icon path={getIconPath(type)} size={1} className={classes.icon} />
                  </Box>
                  {mdAndUp && (
                    <Box
                      style={{
                        fontSize: 12,
                        color: getWorkTypeColor(type),
                        display: "inline-block",
                        paddingLeft: 10,
                        paddingTop: 8,
                        fontStyle: "normal",
                      }}
                    >
                      {getScheduleTypeString(type)}
                    </Box>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </TableCell>
        <TableCell colSpan={columnSpan}></TableCell>
        {teamSchedule.map((schedule) => {
          return (
            <TableCell
              colSpan={columnSpan}
              key={schedule.date}
              className={isWeekend(new Date(schedule.date || "")) ? classes.weekend : ""}
            >
              <Box className={classes.theGrid}>
                <Grid container spacing={1}>
                  {schedule.schedule_status_counts.find((item: any) => item.status === type)
                    ?.count ? (
                    <Grid
                      item
                      xs={12}
                      className={[
                        classes.gridCellHover,
                        isWeekend(new Date(schedule.date || ""))
                          ? classes.weekendCount
                          : classes.weekDayCount,
                        selectedCellDate === schedule.date &&
                        selectedCellWorkType === type &&
                        workType !== null
                          ? classes.gridCellClick
                          : "",
                      ].join(" ")}
                      onClick={() => {
                        handleCellClick(schedule.date, type);
                      }}
                    >
                      <Paper
                        elevation={0}
                        className={classes.paper}
                        style={{ color: getWorkTypeColor(type) }}
                      >
                        {
                          schedule.schedule_status_counts.find((item: any) => item.status === type)
                            ?.count
                        }
                      </Paper>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Box>
            </TableCell>
          );
        })}

        <TableCell colSpan={columnSpan}></TableCell>
      </TableRow>
    );
  }
  return <>{tableRows}</>;
});

export default TeamDetailsPlannerTabBody;
