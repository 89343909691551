import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  useMediaQuery,
  Snackbar,
} from "@material-ui/core";

import moment from "moment";
import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import SwipeableViews from "react-swipeable-views";
import { bhpColor, displaySettings, settings } from "styles/globals";
import { daysBetweenRange, getDateNth } from "utils/date";
import ScheduleWidget from "components/schedule/ScheduleWidget";
import { useBookingStore, useScheduleStore, useUserStore } from "providers/RootStoreProvider";
import config from "config";
import { Schedule } from "interfaces/Schedule";
import EditHoursLocationDialog from "components/utils/EditHoursLocationDialog";
import { EditHoursLocationType } from "interfaces/Utils";
import LoadingZone from "components/schedule/LoadingZone";
import { useHistory } from "react-router";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendarRow: {
      border: `1px solid ${bhpColor.lightGrey}`,
      "& > *:first-child": {
        // magic number for the month to fit the first column
        minWidth: 110,
        borderRight: `1px solid ${bhpColor.lightGrey}`,
      },
      "&:first-child": {
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
      },
      "&:last-child": {
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
      },
    },
    saveSuccess: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.orange1,
        fontWeight: "bold",
        backgroundColor: bhpColor.orange4,
        border: `2px solid ${bhpColor.orange1}`,
        borderRadius: settings.borderRadius,
      },
    },
    dayHeader: {
      fontWeight: 700,
      fontSize: 18,
      backgroundColor: bhpColor.backgroundGrey,
      "&:last-child, &:nth-last-child(2)": {
        // random color that is supposed to represent the blend of:
        // lightGrey + (blueGrey4 * 0.3 opacity)
        backgroundColor: bhpColor.blueGrey4,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        fontWeight: 400,
        backgroundColor: "white",
      },
    },

    dayBox: {
      "&:last-child, &:nth-last-child(2)": {
        backgroundColor: "#D7DFE24D",
      },
    },

    mobileRoot: {
      marginLeft: `-${theme.spacing(2)}px`,
      background: "white",
      width: `calc(100% + ${theme.spacing(4)}px)`,
    },
  })
);

interface ScheduleTabProps {
  t: any;
}

const ScheduleTab = observer((props: ScheduleTabProps) => {
  // todo: this is using a global:
  // https://mobx.js.org/react-integration.html#using-external-state-in-observer-components
  // which is not ideal for unit testing
  // todo: move to react context
  const scheduleStore = useScheduleStore();
  const userStore = useUserStore();
  const bookingStore = useBookingStore();
  const { t } = props;

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const lgAndUp = useMediaQuery(theme.breakpoints.up("lg"));

  const [showBooking, setShowBooking] = useState(true);
  const [calendars, setCalendars] = useState<{ start: Date; end: Date }[]>([]);
  const [indexWeekMobile, setIndexWeekMobile] = useState(0);
  const [editHoursDialogOpen, setEditHoursDialogOpen] = useState<boolean>(false);
  const [editHoursLocationSchedule, setEditHoursLocationSchedule] = useState<Schedule | null>(null);
  const [editHoursLocationWorkType, setEditHoursLocationWorkType] = useState<number | null>(null);

  useEffect(() => {
    if (calendars.length === 0) {
      addWeek();
      addWeek();
    }
    if (!smAndUp) {
      // on small viewport I want to load all weeks
      let calendarLength = calendars.length;
      while (calendarLength < config.workday.numberOfWeeksSchedulable) {
        addWeek();
        calendarLength = calendars.length;
      }
    }
  }, [userStore.me]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleShowBookings = () => {
    setShowBooking((prev) => !prev);
  };

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const addWeek = () => {
    let startDate;
    if (calendars.length === 0) {
      startDate = moment().day(1).toDate();
      //startDate = startOfWeek(new Date());
    } else {
      startDate = moment(calendars.slice(-1)[0].end).add(1, "day").toDate();
    }
    calendars.push({
      start: startDate,
      end: moment(startDate).day(7).toDate(),
      //end: moment(startDate).endOf("week").weekday(7).toDate(),
      //end: endOfWeek(startDate),
    });
    setCalendars([...calendars]);
  };

  const handleEditHoursLocationClick = (schedule: Schedule) => {
    setEditHoursDialogOpen(true);
    setEditHoursLocationSchedule(schedule);
    setEditHoursLocationWorkType(schedule.status);
  };

  const handleEditHoursResponse = () => {
    setEditHoursDialogOpen(false);
  };

  const [showLoading, setShowLoading] = useState(false);
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  const handleHoursLocationSave = (
    schedule: Schedule,
    startTime: string,
    finishTime: string,
    deskStartTime: string,
    deskFinishTime: string,
    location: number
  ) => {
    (async () => {
      if (schedule) {
        await scheduleStore.changeHoursLocation(
          schedule.date,
          startTime,
          finishTime,
          deskStartTime,
          deskFinishTime,
          location
        );
        scheduleStore.loadSchedules();
      }
    })();
    setEditHoursDialogOpen(false);
  };

  return (
    <>
      <LoadingZone isLoading={showLoading} />
      <EditHoursLocationDialog
        t={t}
        isOpen={editHoursDialogOpen}
        schedule={editHoursLocationSchedule}
        handleResponseClick={handleEditHoursResponse}
        handleHoursLocationSave={handleHoursLocationSave}
        workType={editHoursLocationWorkType}
        editHoursLocationType={EditHoursLocationType.Planner}
      />
      {smAndUp && (
        <>
          <Box
            display="flex"
            mt={2}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              display="flex"
              width="100%"
              className={classes.calendarRow}
              flexDirection="row"
              top={0}
              position="sticky"
              zIndex={1}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                color={bhpColor.blueGrey1}
                style={{ display: lgAndUp ? "flex" : "none", backgroundColor: "white" }}
                py={2}
              >
                {t("Week")}
              </Box>
              {days.map((day, idx) => (
                <Box
                  display="flex"
                  flexGrow={1}
                  flexBasis={0}
                  justifyContent="center"
                  color={bhpColor.blueGrey1}
                  py={2}
                  className={classes.dayHeader}
                  key={`${day}-${idx}`}
                >
                  {t(day)}
                </Box>
              ))}
            </Box>
            {calendars.map((week, idx) => (
              <Box
                display="flex"
                width="100%"
                flexGrow={1}
                className={classes.calendarRow}
                flexDirection="row"
                key={`week${idx}`}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  color={bhpColor.blueGrey1}
                  style={{ display: lgAndUp ? "flex" : "none" }}
                  py={2}
                  px={1.7}
                >
                  <Box fontWeight="bold" fontSize="h6.fontSize">
                    {t(moment(week.start).format("MMM"))}
                  </Box>
                  <Box fontSize="subtitle1.fontSize">
                    {t(getDateNth(week.start))} - {t(getDateNth(week.end))}
                  </Box>
                </Box>

                {daysBetweenRange(week.start, 6).map((day, idx) => (
                  <Box
                    display="flex"
                    flexGrow={1}
                    flexBasis={0}
                    py={2}
                    justifyContent="center"
                    className={classes.dayBox}
                    key={idx}
                  >
                    <ScheduleWidget
                      showBooking={showBooking}
                      schedule={scheduleStore.scheduleForDay(day)}
                      handleEditHoursLocation={handleEditHoursLocationClick}
                      upcomingBookings={bookingStore.user_bookings[day] || null}
                      showLoading={setShowLoading}
                      displayMessage={setMessage}
                      isOpen={setIsOpen}
                      isApiError={setIsApiError}
                    ></ScheduleWidget>
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </>
      )}{" "}
      {!smAndUp && (
        <Box className={classes.mobileRoot}>
          <SwipeableViews index={indexWeekMobile}>
            {calendars.map((week, idx) => (
              <>
                <Box
                  px={2}
                  py={2}
                  display="flex"
                  bgcolor={bhpColor.backgroundGrey}
                  borderTop={`1px solid ${bhpColor.blueGrey4}`}
                  borderBottom={`1px solid ${bhpColor.blueGrey4}`}
                  justifyContent="space-between"
                  key={idx}
                >
                  <Box
                    style={{ color: bhpColor.blueGrey1, fontSize: 12 }}
                    onClick={() => {
                      if (indexWeekMobile - 1 < config.workday.numberOfWeeksSchedulable)
                        setIndexWeekMobile(indexWeekMobile - 1);
                    }}
                  >
                    &lt; {t("Prev week")}
                  </Box>
                  <Box style={{ color: bhpColor.blueGrey1, fontWeight: "bold", fontSize: 16 }}>
                    {`${t(moment(week.start).format("MMM"))} ${t(moment(week.start).format("Do"))}`}{" "}
                    - {t(moment(week.end).format("Do"))}
                  </Box>
                  <Box
                    style={{ color: bhpColor.blueGrey1, fontSize: 12 }}
                    onClick={() => {
                      if (indexWeekMobile + 1 < config.workday.numberOfWeeksSchedulable)
                        setIndexWeekMobile(indexWeekMobile + 1);
                    }}
                  >
                    {t("Next week")} &gt;
                  </Box>
                </Box>
                <Box bgcolor="white" display="flex" flexDirection="column">
                  {daysBetweenRange(week.start, 6).map((day, idx) => (
                    <Box
                      style={{ width: "100%" }}
                      display="flex"
                      flexWrap="wrap"
                      className={classes.dayHeader}
                      key={idx}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        color={bhpColor.blueGrey1}
                        borderRight={`1px solid ${bhpColor.blueGrey4}`}
                        alignItems="center"
                        flexBasis="20%"
                      >
                        {t(moment(day).format("ddd"))}
                      </Box>
                      <Box display="flex" flexBasis="80%" px={2} py={2}>
                        <ScheduleWidget
                          showBooking={showBooking}
                          schedule={scheduleStore.scheduleForDay(day)}
                          handleEditHoursLocation={handleEditHoursLocationClick}
                          upcomingBookings={bookingStore.user_bookings[day] || null}
                          showLoading={setShowLoading}
                          displayMessage={setMessage}
                          isOpen={setIsOpen}
                          isApiError={setIsApiError}
                        ></ScheduleWidget>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </>
            ))}
          </SwipeableViews>
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={classes.saveSuccess}
        autoHideDuration={displaySettings.snackDelay}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          if (!isApiError) {
            history.push("/temp");
            history.goBack();
          } else {
            setIsApiError(false);
          }
        }}
        message={message}
        key={"top" + "right"}
      />
    </>
  );
});

export default ScheduleTab;
