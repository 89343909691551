import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "components/utils/ConfirmDialog";

interface SettingsCustomPrompt {
  when: boolean;
  onOK: any;
  onCancel: any;
  title: string;
  okText: string;
  cancelText: string;
}

const SettingsCustomRouterPrompt = observer((props: SettingsCustomPrompt) => {
  const { when, onOK, onCancel, title, okText, cancelText } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  //TODO fix the history-supports-only-one-prompt-at-a-time warning
  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <ConfirmDialog
      isOpen={when}
      handlePrimaryClick={handleOK}
      handleSecondaryClick={handleCancel}
      primaryButtonText={okText}
      secondaryButtonText={cancelText}
      title={title}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: t("There are unsaved changes. Are you sure you want to leave ?"),
        }}
      />
    </ConfirmDialog>
  ) : null;
});
export default SettingsCustomRouterPrompt;
