import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  useMediaQuery,
  Grid,
  Paper,
  Snackbar,
  Avatar,
  Tooltip,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { usePeopleStore, useTeamStore, useUserStore } from "providers/RootStoreProvider";
import { bhpColor, displaySettings, settings } from "styles/globals";
import { useEffect, useMemo, useState } from "react";
import AddCircleTwoToneIcon from "@material-ui/icons/AddCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import CheckCircleOutlineTwoToneIcon from "@material-ui/icons/CheckCircleOutlineTwoTone";
import { Noticeboard } from "interfaces/Teams";
import moment from "moment";
import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { dateFromUTCToLocal } from "utils/date";
import { TeamNoticeOptionsMenu } from "./TeamNoticeOptionsMenu";
import ReportNoticeDialog from "./ReportNoticeDialog";
import EditNoticeDialog from "./EditNoticeDialog";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NoticeBoardRichTextBox from "components/utils/NoticeBoardRichTextBox";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.css";
import "quill-mention";
import { StringMap } from "quill";
import { User } from "interfaces/User";
import { debounce } from "lodash";

interface TeamNoticeboardTabProps {
  teamId?: number;
  t: any;
}

// #region STYLES
const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    saveField: {
      height: "100%",
      justifyContent: "center",
      color: bhpColor.orange1,
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
        textDecorationThickness: "2px",
      },
    },
    addNewNoticeLink: {
      color: bhpColor.blueGrey1,
      fontWeight: 700,
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      textAlign: "end",
      cursor: "pointer",
      textDecoration: "underline",
    },
    noticeCard: {
      color: bhpColor.blueGrey1,
      marginBottom: 20,
      padding: "8px",
      overflowWrap: "anywhere",
      "& .subject": {
        fontSize: "24px",
        whiteSpace: "break-spaces",
        marginLeft: "10px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
        },
      },
      "& .date": {
        color: bhpColor.orange1,
        fontSize: "16px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
        },
        textAlign: "end",
      },
      "& .description": {
        fontSize: "16px",
        whiteSpace: "break-spaces",
      },
    },
    textArea: {
      height: 32,
      width: "100%",
      paddingLeft: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      backgroundColor: `${bhpColor.blueGrey4}50`,
    },
    avatar: {
      backgroundColor: "rgb(250, 182, 54)",
      color: "white",
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginLeft: "16px",
      fontSize: "16px",
    },
    h4: {
      fontWeight: "bold",
      color: bhpColor.blueGrey1,
      fontSize: 16,
      display: "inline-block",
    },
    wrapper: {
      marginTop: theme.spacing(2),
    },
    textField: {
      width: "calc(100% - 150px)",
      borderRadius: settings.borderRadius,
      backgroundColor: "white",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      "& .MuiInput-root": {
        paddingLeft: theme.spacing(1),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `0 !important`,
        transition: "none",
      },
      "& .Mui-focussed": {
        borderBottom: `0 !important`,
      },
    },
    saveFailed: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.purple1,
        fontWeight: "bold",
        backgroundColor: bhpColor.purple4,
        border: `2px solid ${bhpColor.purple1}`,
        borderRadius: settings.borderRadius,
      },
    },
    saveSuccess: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.orange1,
        fontWeight: "bold",
        backgroundColor: bhpColor.orange4,
        border: `2px solid ${bhpColor.orange1}`,
        borderRadius: settings.borderRadius,
      },
    },
    quillEditor: {
      "& .ql-editor": {
        minHeight: `200px !important`,
        maxHeight: "auto",
      },
    },
  })
);
// #endregion STYLES

interface DialogData {
  title: string | null;
  content: string | null;
  primaryButtonText: string | null;
  secondaryButtonText?: string | null;
}

const TeamNoticeboardTab = observer((props: TeamNoticeboardTabProps) => {
  // #region constants

  const { teamId, t } = props;
  const classes = innerStyles();
  const teamStore = useTeamStore();
  const userStore = useUserStore();
  const peopleStore = usePeopleStore();
  const mdAndUp = useMediaQuery(useTheme().breakpoints.up("md"));
  const [formValid, setFormValid] = useState<boolean>(false);
  const subjectMaxCharacters = 150;
  const [snackOpen, setSnackOpen] = useState(false);
  const [saveState, setSaveState] = useState(true);
  const [snackMessage, setSnackMessage] = useState(t("Team Update Successful"));
  const snackbarOpen = () => setSnackOpen(true);
  const snackbarClose = () => setSnackOpen(false);
  const confirmDialogDefaultContent = t("Are you sure you want to publish this notice...?");
  const confirmDialogDefaultTitle = t("Confirm Publish Notice");
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [confirmDialogTitle, setConfirmDialogTitle] = useState(confirmDialogDefaultTitle);
  const [confirmDialogContent, setConfirmDialogContent] = useState(confirmDialogDefaultContent);
  const [reportNoticeConfirmIsOpen, setReportNoticeConfirmIsOpen] = useState<boolean>(false);
  const [editNoticeConfirmIsOpen, setEditNoticeConfirmIsOpen] = useState<boolean>(false);
  const [isRemovingNotice, setIsRemovingNotice] = useState<Noticeboard | null>(null);
  const [firstUp, setFirstUp] = useState(true);
  const [noticeSubject, setNoticeSubject] = useState("");
  const [noticeDescription, setNoticeDescription] = useState("");
  const [selectedNoticeId, setSelectedNoticeId] = useState<number>(0);
  const [selectedNotice, setSelectedNotice] = useState<Noticeboard | null>(null);
  const [newNotice, setNewNotice] = useState(false);
  const isTeamOwner =
    userStore.me && teamId ? teamStore.amITeamOwner(Number(teamId), userStore.me.id) : false;
  const [teamMembers, setTeamMembers] = useState<User[]>([]);
  // #endregion

  // #region quill configuration
  const quillToolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["link", "image"],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
  ];

  const quillMentions: any = {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
    dataAttributes: ["id", "value"],
    source: async (searchTerm, renderList) => {
      const matchedPeople = await suggestPeople(searchTerm);
      renderList(matchedPeople);
    },
  };

  const quillModules: StringMap = useMemo(
    () => ({ toolbar: quillToolbarOptions, mention: quillMentions }),
    []
  );

  // #endregion

  // #region methods

  const setSnackBox = (success: boolean) => {
    setSnackMessage(success ? t("Team Update Successful") : t("Team Update Issue"));
    setSaveState(success);
  };

  const handleSubjectChange = (event: any) => {
    setNoticeSubject(event.target.value);
  };

  const handleDescriptionChange = (event: any) => {
    setNoticeDescription(event);
  };

  const makeNewNotice = () => {
    resetInputs();
    setNewNotice(true);
    checkFormAndSetIfValid();
  };
  const cancelNewNotice = () => {
    resetInputs();
    setNewNotice(false);
    checkFormAndSetIfValid();
  };
  const cancelEditNotice = () => {
    resetInputs();
    setSelectedNotice(null);
  };

  const checkFormAndSetIfValid = () => {
    /* By default quill is adding <p><br/></p> if there is nothing in the rich text editor, so to keep the cursor in the richtext we need this . 
   For form validation adding additional replace to check if after replacing the string is still empty means form is empty and disable the save button.
   Also Replace all is going to take care if user is just adding empty line breaks instead of content*/
    setFormValid(noticeDescription.replaceAll("<p><br></p>", "") !== "" && noticeSubject !== "");
  };

  const resetDialog = () => {
    setIsRemovingNotice(null);
    setDialog(confirmDialogDefaultTitle, confirmDialogDefaultContent);
    checkFormAndSetIfValid();
  };

  const resetInputs = () => {
    setNoticeSubject("");
    setNoticeDescription("");
    checkFormAndSetIfValid();
  };

  const setDialog = (title: string, content: string) => {
    setConfirmDialogTitle(title);
    setConfirmDialogContent(content);
  };

  const saveButtonPress = () => {
    if (!formValid) return;
    resetDialog();
    setConfirmDialogIsOpen(true);
  };

  const saveEditNotice = async (notice: Noticeboard) => {
    notice.description = noticeDescription;
    notice.subject = noticeSubject;
    if (teamId && notice) {
      await teamStore.updateNotice(teamId, notice);
    }
    setSelectedNotice(null);
  };

  const confirmDialogOk = () => {
    if (teamId && userStore.me) {
      if (isRemovingNotice && isRemovingNotice.id) {
        // delete the notice
        teamStore.removeNotice(teamId, isRemovingNotice.id);
        setIsRemovingNotice(null);
      } else {
        // Save the notice...
        const newNotice: Noticeboard = {
          creator: userStore.me.id,
          team: teamId,
          subject: noticeSubject,
          description: noticeDescription,
        };
        (async () => {
          const theAdd = await teamStore.addNotice(teamId, newNotice);
          resetInputs();
          setNewNotice(false);
        })();
      }
    }
    resetDialog();
    setConfirmDialogIsOpen(false);
    checkFormAndSetIfValid();
  };

  const confirmDialogCancel = () => {
    setIsRemovingNotice(null);
    setConfirmDialogIsOpen(false);
  };

  const removeNotice = (notice: Noticeboard) => {
    setDialog("Remove Notice", `Are you sure you want to remove "${notice.subject}"?`);
    setConfirmDialogIsOpen(true);
    setIsRemovingNotice(notice);
  };

  const handleReportNotice = (notice: Noticeboard) => {
    setSelectedNoticeId(notice.id || 0);
    setReportNoticeConfirmIsOpen(true);
  };

  const handleEditNotice = (notice: Noticeboard) => {
    setSelectedNotice(notice);
    setNoticeDescription(notice.description);
    setNoticeSubject(notice.subject);
  };

  const handleReportNoticeClose = () => {
    setReportNoticeConfirmIsOpen(false);
  };
  const handleEditNoticeClose = () => {
    setEditNoticeConfirmIsOpen(false);
  };

  const handleReportNoticeResponse = async (reason: string, noticeId: number) => {
    if (teamId && noticeId) {
      await teamStore.reportNotice(teamId, noticeId, reason);
    }
    setReportNoticeConfirmIsOpen(false);
    setSelectedNoticeId(0);
  };

  const handleEditNoticeResponse = async (notice: Noticeboard) => {
    if (teamId && notice) {
      await teamStore.updateNotice(teamId, notice);
    }
    resetInputs();
    setSelectedNotice(null);
  };

  const suggestPeople = async (searchTerm) => {
    if (teamId && searchTerm) {
      return userStore.usersByTeamId
        .map((user: User) => {
          return {
            id: `${user.id}`,
            value: `${user.first_name} ${user.last_name}`,
            preferredName: `${user.preferred_name}`,
            email: `${user.email}`,
          };
        })
        .filter((user) => {
          return (
            user.preferredName.includes(searchTerm) ||
            user.value.includes(searchTerm) ||
            user.email.includes(searchTerm)
          );
        });
    }
  };

  // #endregion

  // #region states

  useEffect(() => {
    checkFormAndSetIfValid();
  }, [noticeSubject, noticeDescription]);

  useEffect(() => {
    teamId &&
      (async () => {
        await teamStore.getNotices(teamId);
        userStore.resetUsersByTeamId();
        await userStore.loadUsersByTeamId(teamId);
        setFirstUp(false);
      })();
  }, [teamId]);

  // #endregion

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={saveState ? classes.saveSuccess : classes.saveFailed}
        autoHideDuration={displaySettings.snackDelay}
        open={snackOpen}
        onClose={snackbarClose}
        message={snackMessage}
      />
      <ConfirmDialog
        isOpen={confirmDialogIsOpen}
        handlePrimaryClick={confirmDialogOk}
        handleSecondaryClick={confirmDialogCancel}
        secondaryButtonText={"Cancel"}
        title={confirmDialogTitle}
      >
        <Box>{confirmDialogContent}</Box>
      </ConfirmDialog>
      <ReportNoticeDialog
        isOpen={reportNoticeConfirmIsOpen}
        handleCloseClick={handleReportNoticeClose}
        handleResponseClick={handleReportNoticeResponse}
        noticeId={selectedNoticeId}
        t={t}
      />
      {selectedNotice && (
        <EditNoticeDialog
          isOpen={editNoticeConfirmIsOpen}
          handleCloseClick={handleEditNoticeClose}
          handleUpdateClick={handleEditNoticeResponse}
          notice={selectedNotice}
          t={t}
        />
      )}
      {!selectedNotice && !teamStore.isLoadingNotices && (
        <Box
          m={{ xs: 1, md: 3 }}
          className={classes.addNewNoticeLink}
          onClick={() => makeNewNotice()}
        >
          <AddCircleTwoToneIcon />
          {t("Add a notice to the noticeboard")}
        </Box>
      )}
      {newNotice && (
        <Paper className={classes.noticeCard}>
          <form noValidate autoComplete="off">
            <Grid container>
              <Grid item xs={10}>
                <Grid container>
                  Ensure this field has no more than 250 characters.
                  <Grid item xs={12}>
                    <Box className={"date"}>
                      {moment(new Date()).format("dddd, D of MMMM - H:mm A")}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={"subject"} style={{ fontSize: "16px" }}>
                      {!teamStore.isAddingNotice ? (
                        <>
                          <TextField
                            inputProps={{ maxLength: subjectMaxCharacters }}
                            fullWidth
                            multiline
                            value={noticeSubject}
                            onChange={handleSubjectChange}
                            label="Enter a subject"
                          />
                          <span style={{ fontSize: 10, float: "right" }}>{`Characters remaining - ${
                            subjectMaxCharacters - noticeSubject.length
                          }`}</span>
                        </>
                      ) : (
                        <Box className={"subject"}>{noticeSubject}</Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={"description"}>
                      {!teamStore.isAddingNotice ? (
                        <>
                          <Box
                            className={classes.quillEditor}
                            style={{ border: "solid 2px", borderRadius: 5, padding: 5 }}
                          >
                            {/* <NoticeBoardRichTextBox
                              description={noticeDescription}
                              handleContentChange={handleContentChange}
                              teamId={teamId}
                            /> */}
                            <ReactQuill
                              value={noticeDescription}
                              onChange={debounce((event) => handleDescriptionChange(event), 500)}
                              modules={quillModules}
                              placeholder="Enter a Description"
                            />
                          </Box>
                        </>
                      ) : (
                        <div
                          className={"description"}
                          dangerouslySetInnerHTML={{ __html: `${noticeDescription}` }}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <Box
                  style={{ display: "flex", alignItems: "center", justifyContent: "end" }}
                  onClick={() => cancelNewNotice()}
                >
                  <Tooltip title="Click to cancel the new notice">
                    <CancelTwoToneIcon />
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={2}>
                {!teamStore.isAddingNotice ? (
                  <Box
                    className={classes.saveField}
                    style={{
                      cursor: !formValid ? "default" : "pointer",
                    }}
                    onClick={() => saveButtonPress()}
                  >
                    <Tooltip
                      title={
                        formValid
                          ? "Click to save the new notice"
                          : "Subject & Description required..."
                      }
                    >
                      <>
                        <div style={{ display: "block" }}>
                          <CheckCircleOutlineTwoToneIcon
                            style={{ color: formValid ? bhpColor.orange1 : bhpColor.grey3 }}
                          />
                        </div>
                        <div
                          style={{
                            display: "block",
                            color: formValid ? bhpColor.orange1 : bhpColor.grey3,
                            fontSize: "12px",
                          }}
                        >
                          SAVE
                        </div>
                      </>
                    </Tooltip>
                  </Box>
                ) : (
                  <Box
                    className={classes.saveField}
                    style={{ cursor: !formValid ? "default" : "pointer" }}
                  >
                    <CircularProgress size={14} />
                    <div
                      style={{
                        display: "block",
                        color: formValid ? bhpColor.orange1 : bhpColor.grey3,
                      }}
                    >
                      ADDING
                    </div>
                  </Box>
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
      {firstUp && teamStore.isLoadingNotices ? (
        <Paper className={classes.noticeCard}>
          <Box>
            Loading notices <CircularProgress size={14} />
          </Box>
        </Paper>
      ) : (
        teamStore.teamNotices.map((notice, idx) => {
          return notice.id !== selectedNotice?.id ? (
            <Paper
              key={`notice${idx}`}
              className={classes.noticeCard}
              style={teamStore.isDeletingNotice === notice.id ? { opacity: 0.5 } : {}}
            >
              <Grid container>
                <Grid item xs={1}>
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Tooltip title={notice.avatar_tooltip || ""}>
                      <Avatar
                        style={{
                          marginTop: "6px",
                          backgroundColor:
                            notice.avatar_tag?.startsWith("J") || notice.avatar_tag?.startsWith("S")
                              ? bhpColor.orange3
                              : "#06AD96",
                        }}
                        src={notice.creator_picture}
                      >
                        {notice.avatar_tag || ""}
                      </Avatar>
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box className={"date"}>
                        {notice.date_created &&
                          dateFromUTCToLocal(
                            notice.date_created,
                            mdAndUp ? undefined : "ddd, D MMM - h:mm A"
                          )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={"subject"}>{notice.subject}</Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        className={"description"}
                        dangerouslySetInnerHTML={{ __html: `${notice.description}` }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  {!selectedNotice && !newNotice && (
                    <TeamNoticeOptionsMenu
                      t={t}
                      notice={notice}
                      isTeamOwner={isTeamOwner}
                      removeNotice={removeNotice}
                      reportNotice={handleReportNotice}
                      editNotice={handleEditNotice}
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <Paper className={classes.noticeCard}>
              <form noValidate autoComplete="off">
                <Grid container>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box className={"date"}>
                          {moment(new Date()).format("dddd, D of MMMM - H:mm A")}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className={"subject"} style={{ fontSize: "16px" }}>
                          <>
                            <TextField
                              inputProps={{ maxLength: subjectMaxCharacters }}
                              fullWidth
                              multiline
                              value={noticeSubject}
                              onChange={handleSubjectChange}
                              label="Enter a subject"
                            />
                            <span
                              style={{ fontSize: 10, float: "right" }}
                            >{`Characters remaining - ${
                              subjectMaxCharacters - noticeSubject.length
                            }`}</span>
                          </>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className={"description"}>
                          {!teamStore.isAddingNotice ? (
                            <>
                              <Box style={{ border: "solid 2px", borderRadius: 5, padding: 5 }}>
                                {/* <NoticeBoardRichTextBox
                                  description={noticeDescription}
                                  handleContentChange={handleContentChange}
                                  teamId={teamId}
                              
                                /> */}
                                <ReactQuill
                                  value={noticeDescription}
                                  onChange={debounce(
                                    (event) => handleDescriptionChange(event),
                                    500
                                  )}
                                  modules={quillModules}
                                  placeholder="Enter a Description"
                                />
                              </Box>
                            </>
                          ) : (
                            <div
                              className={"description"}
                              dangerouslySetInnerHTML={{ __html: `${noticeDescription}` }}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <Box
                      style={{ display: "flex", alignItems: "center", justifyContent: "end" }}
                      onClick={() => cancelEditNotice()}
                    >
                      <Tooltip title="Click to cancel the edit notice">
                        <CancelTwoToneIcon />
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    {!teamStore.isUpdatingNotice ? (
                      <Box
                        className={classes.saveField}
                        style={{
                          cursor:
                            (noticeDescription !== notice.description ||
                              noticeSubject !== notice.subject) &&
                            formValid
                              ? "pointer"
                              : "default",
                        }}
                        onClick={() => saveEditNotice(notice)}
                      >
                        <>
                          <div style={{ display: "block" }}>
                            <CheckCircleOutlineTwoToneIcon
                              style={{
                                color:
                                  (noticeDescription !== notice.description ||
                                    noticeSubject !== notice.subject) &&
                                  formValid
                                    ? bhpColor.orange1
                                    : bhpColor.grey3,
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "block",
                              color:
                                (noticeDescription !== notice.description ||
                                  noticeSubject !== notice.subject) &&
                                formValid
                                  ? bhpColor.orange1
                                  : bhpColor.grey3,
                            }}
                          >
                            Update
                          </div>
                        </>
                      </Box>
                    ) : (
                      <Box
                        className={classes.saveField}
                        style={{ cursor: !formValid ? "default" : "pointer" }}
                      >
                        <CircularProgress size={14} />
                        <div
                          style={{
                            display: "block",
                            color: formValid ? bhpColor.orange1 : bhpColor.grey3,
                          }}
                        >
                          Updating
                        </div>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Paper>
          );
        })
      )}
    </Box>
  );
});

export default TeamNoticeboardTab;
