import { createStyles, makeStyles, Theme, Box, useTheme, useMediaQuery } from "@material-ui/core";
import { bhpColor, settings } from "styles/globals";
import Icon from "@mdi/react";
import { mdiCalendarRemoveOutline, mdiHeadset, mdiMapMarkerRadiusOutline } from "@mdi/js";

import { Team, TeamSchedulePeopleList } from "interfaces/Teams";
import { ScheduleType } from "interfaces/Schedule";
import { toJS } from "mobx";
import moment from "moment";
import { useEffect } from "react";
import { useTeamStore } from "providers/RootStoreProvider";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    iconBlock: {
      "& svg": {
        width: "18px",
        textAlign: "center",
        alignSelf: "baseline",
        [theme.breakpoints.down("xs")]: {
          //display: "none",
        },
      },
    },
    box: {
      padding: theme.spacing(1),
      height: 39,
      display: "flex",
      marginRight: theme.spacing(1),
      borderStyle: "solid",
      borderWidth: 2,
      borderColor: bhpColor.blueGrey2,
      borderRadius: settings.borderRadius,
      backgroundColor: bhpColor.backgroundGrey,
      color: bhpColor.blueGrey2,
      alignItems: "center",
      fontSize: 12,
      whiteSpace: "nowrap",

      [theme.breakpoints.down("xs")]: {
        width: 100,
      },

      "&.office": {
        backgroundColor: bhpColor.blueGrey1,
        borderColor: bhpColor.blueGrey1,
        color: bhpColor.white,
        "& .number": {
          color: bhpColor.white,
        },
      },
      "& .number": {
        marginLeft: 5,
        marginRight: 5,
        fontSize: 18,
        fontWeight: 700,
        verticalAlign: "bottom",
        color: bhpColor.blueGrey1,
        [theme.breakpoints.down("xs")]: {
          marginRight: 4,
        },
      },
    },
  })
);

// RS 21/03/2022 introduce date incase teamSchedulePeopleList
// is empty and needs to be reloaded
interface TeamWorkTypeSummaryProps {
  team?: Team | null;
  teamSchedulePeopleList: TeamSchedulePeopleList[] | null;
  version2?: boolean;
  date?: string;
}

export function TeamWorkTypeSummary(props: TeamWorkTypeSummaryProps) {
  const { team, teamSchedulePeopleList, version2, date } = props; // eslint-disable-line @typescript-eslint/no-unused-vars
  const classes = innerStyles();
  const selectedDate = moment(new Date()).format(settings.momentDateFormat);
  const smAndUp = useMediaQuery(useTheme().breakpoints.up("sm"));
  const teamStore = useTeamStore();

  const getQuantity = (worktype: ScheduleType) => {
    if (team && teamSchedulePeopleList) {
      const tspl = teamSchedulePeopleList.filter((tpsl) =>
        tpsl.work_schedules
          ? selectedDate in tpsl.work_schedules &&
            tpsl.work_schedules[selectedDate].status == worktype
          : false
      );
      return tspl.length;
    } else return 0;
  };

  useEffect(() => {
    if (team && date && (!teamSchedulePeopleList || teamSchedulePeopleList?.length === 0))
      teamStore.loadTeamSchedulePeopleList(team.id, date, date);
  }, []);

  return (
    <Box display="flex" style={version2 ? { width: "100%", justifyContent: "space-between" } : {}}>
      <Box
        className={`${classes.box} office ${classes.iconBlock}`}
        style={{
          justifyContent: version2 ? "center" : "initial",
          alignItems: version2 ? "center" : "center",
          minWidth: version2 ? "30%" : "125px",
          marginRight: version2 ? 0 : 8,
        }}
      >
        <Box className={"number"} style={{ display: "flex", alignItems: "center" }}>
          <Icon
            path={mdiMapMarkerRadiusOutline}
            size={"20px"}
            style={{ alignSelf: "auto", marginRight: 4 }}
          />
          {getQuantity(ScheduleType.OFFICE)}
        </Box>
        BHP Location
      </Box>
      <Box
        className={`${classes.box} remote ${classes.iconBlock}`}
        style={{
          marginRight: version2 ? 0 : 8,
          justifyContent: version2 ? "center" : "initial",
          alignItems: version2 ? "center" : "center",
          minWidth: version2 ? "30%" : "125px",
          backgroundColor: version2 ? bhpColor.blueGrey2 : "inherit",
          color: version2 ? "white" : "inherit",
        }}
      >
        <Box
          className={"number"}
          style={{
            alignItems: "center",
            display: "flex",
            color: version2 ? "white" : "inherit",
          }}
        >
          <Icon path={mdiHeadset} size={"20px"} style={{ alignSelf: "auto", marginRight: 4 }} />
          {getQuantity(ScheduleType.REMOTE)}
        </Box>
        REMOTE
      </Box>
      <Box
        className={`${classes.box} off ${classes.iconBlock}`}
        style={{
          display: "flex",
          justifyContent: version2 ? "center" : "initial",
          alignItems: version2 ? "center" : "center",
          minWidth: version2 ? "30%" : "125px",
          marginRight: version2 ? 0 : 8,
          backgroundColor: version2 ? "#94989B" : "inherit",
          borderColor: version2 ? "#84898F" : "inherit",
          color: version2 ? "white" : "inherit",
        }}
      >
        <Box
          className={"number"}
          style={{
            display: "flex",
            alignItems: "center",
            color: version2 ? "white" : "inherit",
          }}
        >
          <Icon
            path={mdiCalendarRemoveOutline}
            size={"20px"}
            style={{ alignSelf: "auto", marginRight: 4 }}
          />
          {getQuantity(ScheduleType.OFF)}
        </Box>
        OFF
      </Box>
    </Box>
  );
}
