import { makeAutoObservable, flow, toJS, runInAction } from "mobx";

import {
  demoOwners,
  Noticeboard,
  Team,
  TeamCategoryType,
  TeamParticipant,
  TeamRoleType,
  TeamSchedulePeopleList,
  TeamVisibilityType,
  TeamWorkdaySchedule,
  IloOccurance,
  IloBOSPractice,
  IloPlace,
  Activity,
} from "interfaces/Teams";
import RootStore from "./RootStore";
import client from "services/api";
import { User } from "interfaces/User";
import { get, set } from "lodash";
import moment from "moment";

export default class TeamStore {
  teams: Team[] = [];
  teamWorkdaySchedule: TeamWorkdaySchedule | null = null;
  teamSchedulePeopleList: TeamSchedulePeopleList[] | null = null;
  myTeam: Team | null = null;
  myTeams: Team[] = [];
  currentTeam: Team | null = null;

  public searchFilter = "";

  isLoading = false;
  isDeletingTeam: boolean = false;
  isLoadingNotices: boolean = false;
  isAddingNotice: boolean = false;
  isReportingNotice: boolean = false;
  isUpdatingNotice: boolean = false;
  isDeletingNotice: number | null = null;
  teamNotices: Noticeboard[] = [];

  teamsLoading: number[] = [];
  isLoadingMyTeams: boolean = false;
  isLoadingCurrentTeam: boolean = false;
  isLoadingTeamSchedulePeople: boolean = false;
  isLoadingTeamSchedule: boolean = false;
  error: string | unknown = "";
  rootStore!: RootStore;

  devTeamEmails: string[] = ["richard.smythe@bhp.com"];
  teamDeleted: boolean | null = null;
  teamDeletedSnackMessage: string = "";
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  amIDev(user: User | null) {
    return user && user.email && this.devTeamEmails.includes(user.email);
  }

  amIOwner(myId: number) {
    return this.currentTeam && this.currentTeam.owners
      ? this.currentTeam?.owners?.findIndex((own) => own.id == myId) > -1
      : false;
  }

  amIInTeam(teamId: number) {
    return this.myTeams.findIndex((team) => team.id === teamId) > -1;
  }

  amITeamOwner(teamId: number, myId: number) {
    const thisTeam = this.myTeams.find((team) => team.id === teamId);
    return thisTeam && thisTeam.owners
      ? thisTeam.owners.findIndex((own) => own.id === myId) > -1
      : false;
  }

  setCurrentTeam(teamId: number) {
    const foundTeam = this.teams.find((tm) => tm.id === teamId);
    if (foundTeam) {
      runInAction(() => (this.isLoadingCurrentTeam = false));
      runInAction(() => (this.currentTeam = foundTeam));
      return;
    }
    runInAction(() => (this.isLoadingCurrentTeam = true));
    this.loadTeam(teamId).then((team) => {
      runInAction(() => (this.currentTeam = team));
      runInAction(() => (this.isLoadingCurrentTeam = false));
    });
  }

  setTeamDeleted(teamDeleted: boolean | null) {
    this.teamDeleted = teamDeleted;
  }

  setTeamDeletedSnackMessage(teamDeletedSnackMessage: string) {
    this.teamDeletedSnackMessage = teamDeletedSnackMessage;
  }

  setMyTeam(teamId: number) {
    const foundTeam = this.teams.find((tm) => tm.id === teamId);
    if (foundTeam) {
      this.myTeam = foundTeam;
      return;
    }
    const updateMyTeam = async () => {
      const res = await this.loadTeam(teamId);
      this.myTeam = { ...res };
    };
    updateMyTeam();
  }

  getTeam(teamId: number): Team | null {
    return this.teams.find((tm) => tm.id === teamId) || null;
  }

  getTeamWorkdaySchedule(dte: string) {
    return this.teamWorkdaySchedule?.schedules.find((sch) => sch.date === dte) || null;
  }

  loadTeamSchedulePeopleList = flow(function* (
    this: TeamStore,
    teamId: number,
    dateFrom: string,
    dateTo: string
  ) {
    this.isLoading = true;
    this.isLoadingTeamSchedulePeople = true;
    try {
      const res = yield client.get(`/api/team/${teamId}/${dateFrom}/${dateTo}/schedule/people/`);
      this.teamSchedulePeopleList = res.data.results;
    } catch (err) {
      this.error = `${err}`;
    }
    this.isLoadingTeamSchedulePeople = false;
    this.isLoading = false;
  });

  loadTeamMembers = flow(function* (this: TeamStore, teamId: number | undefined) {
    if (!teamId) return;
    this.isLoading = true;
    try {
      const res = yield client.get(`/api/team/teams/${teamId}/members/`);
      return res.data;
    } catch (err) {
      this.error = `${err}`;
    }
    this.isLoading = false;
  });

  teamExists = flow(function* (this: TeamStore, teamId: string | null) {
    if (teamId === "create") return true;
    if (!teamId) return false;
    this.isLoading = true;
    try {
      const res = yield client.get(`/api/team/teams/${teamId}/`);
      return true;
    } catch (err) {
      console.log("err: ", toJS(err));
      this.isLoading = false;
      this.error = `${err}`;
      return false;
    }
  });

  loadMyTeam = flow(function* (this: TeamStore, teamId: number | undefined) {
    if (!teamId) return;
    this.isLoading = true;
    try {
      const res = yield client.get(`/api/users/?profile__team=${teamId}`);
      if (res.data.results) {
        const results = [...res.data.results];
        runInAction(() => (this.myTeam = { ...results[0].profile.team }));
      }
    } catch (err) {
      this.error = `${err}`;
    }
    this.isLoading = false;
  });

  loadTeamWorkdaySchedule = flow(function* (
    this: TeamStore,
    teamId: number,
    dateFrom: string,
    dateTo: string
  ) {
    this.isLoading = true;
    this.isLoadingTeamSchedule = true;
    try {
      const res = yield client.get(`/api/team/${teamId}/${dateFrom}/${dateTo}/schedule/`);
      const theResults = res.data.results;
      this.teamWorkdaySchedule = {
        team: { ...theResults[0] },
        schedules: [...theResults[0].schedules],
      };
    } catch (err) {
      this.error = `${err}`;
    }
    this.isLoadingTeamSchedule = false;
    this.isLoading = false;
  });

  // loadTeams = flow(function* (this: TeamStore) {
  //   if (this.teams.length > 0) return this.teams;
  //   this.isLoading = true;
  //   try {
  //     // some screens need all the teams - for now we load them all - we ll have to make this better
  //     const res = yield client.get("/api/team/teams/?limit=2500");
  //     this.teams = res.data.results;
  //     this.isLoading = false;
  //     return this.teams;
  //   } catch (err) {
  //     this.error = `${err}`;
  //   }
  //   this.isLoading = false;
  // });

  loadMyTeams = flow(function* (this: TeamStore) {
    //if (this.myTeams.length > 0) return this.myTeams;
    this.isLoading = true;
    this.isLoadingMyTeams = true;
    try {
      const res = yield client.get("/api/team/myteams/");
      this.myTeams = res.data.results;
      this.isLoading = false;
      this.isLoadingMyTeams = false;
      return this.teams;
    } catch (err) {
      this.error = `${err}`;
    }
    this.isLoading = false;
    this.isLoadingMyTeams = false;
  });

  loadTeam = flow(function* (this: TeamStore, teamId: number) {
    if (teamId.toString() === "create") return;
    if (!teamId || teamId === 0) return;
    if (this.teams.find((team) => team.id == teamId)) {
      return this.teams.find((team) => team.id == teamId);
    }
    if (this.teamsLoading.includes(teamId)) return;
    this.isLoading = true;
    try {
      this.teamsLoading.push(teamId);
      const res = yield client.get(`/api/team/teams/${teamId}/`);
      const thisTeam = {
        ...res.data,
        endDate: "14-01-2025", //TODO remove this hard coding...
      };
      if (!(this.teams.filter((tm) => tm.id == teamId).length > 0)) this.teams.push(thisTeam);
      this.isLoading = false;
      this.teamsLoading.splice(this.teamsLoading.indexOf(teamId), 1);
      return thisTeam;
    } catch (err) {
      this.error = `${err}`;
    }
    this.isLoading = false;
  });

  changeTeam = flow(function* (this: TeamStore, givenTeam: Team) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.put(`/api/team/teams/${givenTeam.id}/`, givenTeam);
      if (res.status === 200) {
        this.teams.splice(
          this.teams.findIndex((team) => team.id === givenTeam.id),
          1
        );
        this.teams.push(res.data);
      }
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  createTeam = flow(function* (
    this: TeamStore,
    newTeam: {
      name: string;
      category: TeamCategoryType;
      visibility: TeamVisibilityType;
      location: number | null;
      description?: string;
    }
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(`/api/team/teams/`, newTeam);
      const thisTeam = { ...res.data };
      this.teams.push(thisTeam);
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  joinTeam = flow(function* (this: TeamStore, teamId: number) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(`/api/team/teams/${teamId}/join/`);
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  leaveTeam = flow(function* (this: TeamStore, teamId: number) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(`/api/team/teams/${teamId}/leave/`);
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  changeTeamMembers = flow(function* (
    this: TeamStore,
    teamId: number,
    userIds: number[],
    teamIds: number[],
    role: TeamRoleType
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const postBody = {
        users: userIds,
        teams: teamIds,
        role: role,
      };
      const res = yield client.post(`/api/team/teams/${teamId}/members/`, postBody);
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  changeMemberRole = flow(function* (
    this: TeamStore,
    teamId: number,
    userId: number,
    role: TeamRoleType
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.put(`/api/team/teams/${teamId}/members/`, [
        { user: userId, role: role },
      ]);
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  deleteMember = flow(function* (this: TeamStore, teamId: number, userId: number) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.delete(`/api/team/teams/${teamId}/members/`, {
        data: [{ user: userId }],
      });
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  getTeamsByName = flow(function* (this: TeamStore, query: string) {
    this.isLoading = true;
    try {
      if (query) {
        const res = yield client.get(`/api/team/teams/?q=${query}`);
        this.isLoading = false;
        return res.data.results;
      }
    } catch (err) {
      this.isLoading = false;
      this.error = err;
    }
  });

  getFilteredTeams = flow(function* (
    this: TeamStore,
    query: string,
    category: number,
    limit?: number,
    offset?: number
  ) {
    if (query === "" && category === -1) return;
    this.isLoading = true;
    try {
      this.isLoading = false;
      const res = yield client.get(
        `/api/team/teams/${this.getFilteredTeamsQueryString(query, category, limit, offset)}`
      );
      this.isLoading = false;
      return res.data;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return [];
    }
  });

  getFilteredTeamsQueryString = (
    query: string,
    category: number,
    limit?: number,
    offset?: number
  ): string => {
    console.log("offset", offset);
    let queryObject = Object.entries({
      q: `${query !== "" ? query : null}`,
      category: `${category && category !== -1 ? category : null}`,
      limit: `${limit ? limit : null}`,
      offset: `${offset || offset === 0 ? offset : null}`,
    }).filter(([_, v]) => {
      return v !== null && v !== "null";
    });
    const urlObject = new URLSearchParams(queryObject);
    if (urlObject.toString() !== "") {
      return `?${urlObject.toString()}`;
    } else {
      return "";
    }
  };

  getTeamScheduleForDates = flow(function* (
    this: TeamStore,
    teamId: number,
    startDate: string,
    endDate: string
  ) {
    if (teamId.toString() === "create") return;
    this.isLoading = true;
    this.isLoadingTeamSchedule = true;
    try {
      const res = yield client.get(`/api/team/${teamId}/${startDate}/${endDate}/schedule/`);
      this.isLoading = false;
      this.isLoadingTeamSchedule = false;
      return res.data.results;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
    }
    this.isLoadingTeamSchedule = false;
  });

  getPeopleSchedule = flow(function* (
    this: TeamStore,
    teamId: number,
    startDate: string,
    endDate: string
  ) {
    if (teamId.toString() === "create") return;
    this.isLoading = true;
    try {
      const res = yield client.get(`/api/team/${teamId}/${startDate}/${endDate}/schedule/people/`);
      this.isLoading = false;
      return res.data.results;
    } catch (error) {
      this.isLoading = false;
      this.error = error;
    }
  });

  //#region Noticeboard

  getNoticeWithAvatarData(notice: Noticeboard) {
    return {
      ...notice,
      ...this.getAvatarDetails(
        notice.creator_first_name || "",
        notice.creator_last_name || "",
        notice.creator_preferred_name || ""
      ),
    };
  }

  addNotice = flow(function* (this: TeamStore, teamId: number, notice: Noticeboard) {
    this.isAddingNotice = true;
    try {
      const res = yield client.post(`/api/notices/team/${teamId}/`, notice);
      if (res.status === 201) {
        const newNotice: Noticeboard = this.getNoticeWithAvatarData(res.data);
        this.teamNotices.unshift(newNotice);
      }
      this.isAddingNotice = false;
    } catch (error) {
      this.isLoadingNotices = false;
      this.error = error;
    }
    this.isAddingNotice = false;
  });

  updateNotice = flow(function* (this: TeamStore, teamId: number, notice: Noticeboard) {
    this.isUpdatingNotice = true;
    try {
      const putData = {
        team: notice.team,
        subject: notice.subject,
        description: notice.description,
      };
      const res = yield client.put(`/api/notices/team/${teamId}/${notice.id}/`, putData);
      if (res.status === 200) {
        const updatedNotice: Noticeboard = this.getNoticeWithAvatarData(res.data);
        const findNoticeIndex = this.teamNotices.findIndex(
          (notice) => notice.id === updatedNotice.id
        );
        if (findNoticeIndex > 0) {
          this.teamNotices[findNoticeIndex] = updatedNotice;
        }
      }
      this.isUpdatingNotice = false;
    } catch (error) {
      this.isLoadingNotices = false;
      this.error = error;
    }
    this.isUpdatingNotice = false;
  });

  removeNotice = flow(function* (this: TeamStore, teamId: number, noticeId: number) {
    this.isDeletingNotice = noticeId;
    try {
      const res = yield client.delete(`/api/notices/team/${teamId}/${noticeId}/`);
      console.log("res:", res);
      if (res.status === 204) {
        this.teamNotices = this.teamNotices.filter((notice) => notice.id !== noticeId);
        this.isDeletingNotice = null;
      }
    } catch (error) {
      this.isDeletingNotice = null;
      this.error = error;
    }
  });

  getAvatarDetails(
    creator_first_name: string,
    creator_last_name: string,
    creator_preferred_name: string
  ) {
    const avatar_tag = `${creator_first_name ? creator_first_name.charAt(0).toUpperCase() : ""}${
      creator_last_name ? creator_last_name.charAt(0).toUpperCase() : ""
    }`;
    const avatar_tooltip = creator_preferred_name
      ? creator_preferred_name
      : `${creator_first_name ? creator_first_name : ""} ${
          creator_last_name ? creator_last_name : ""
        }`;
    return { avatar_tag, avatar_tooltip };
  }

  getNotices = flow(function* (this: TeamStore, teamId: number) {
    this.isLoadingNotices = true;
    try {
      const res = yield client.get(`/api/notices/team/${teamId}/`);
      const notices = [
        ...res.data.results.map((notice: Noticeboard) => {
          return {
            ...notice,
            ...this.getAvatarDetails(
              notice.creator_first_name || "",
              notice.creator_last_name || "",
              notice.creator_preferred_name || ""
            ),
          };
        }),
      ];
      this.isLoadingNotices = false;
      this.teamNotices = [...notices].sort((a: Noticeboard, b: Noticeboard) => {
        return a.date_created && b.date_created ? (a.date_created > b.date_created ? -1 : 1) : -1;
      });
    } catch (error) {
      this.isLoadingNotices = false;
      this.error = error;
    }
  });

  deleteTeam = flow(function* (this: TeamStore, teamId: number) {
    this.isDeletingTeam = true;

    try {
      this.error = "";
      const res = yield client.delete(`/api/team/teams/${teamId}/`);
      console.log("Team store delete team response", res);
      this.teamDeleted = true;
      this.isDeletingTeam = false;
      return res;
    } catch (err) {
      this.teamDeleted = false;
      this.error = err;
      this.isDeletingTeam = false;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  updateAnchorDays = flow(function* (this: TeamStore, teamId: number, anchorDays: any) {
    try {
      this.error = "";
      const anchor_days = { anchor_days: anchorDays };
      const res = yield client.put(`/api/team/teams/${teamId}/update-anchor-days/`, anchor_days);
      return res;
    } catch (err) {
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  reportNotice = flow(function* (
    this: TeamStore,
    teamId: number,
    noticeId: number,
    message: string
  ) {
    this.isReportingNotice = true;
    try {
      this.error = "";
      this.isLoading = true;
      const reportBody = { message: message };
      const res = yield client.post(`/api/notices/team/${teamId}/${noticeId}/report/`, reportBody);
      this.isLoading = false;
      this.isReportingNotice = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      this.isReportingNotice = false;

      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  createIloActivity = flow(function* (this: TeamStore, newActivity: Activity) {
    const participants = newActivity.participants?.map((mem) => {
      if (mem.user_id) return mem.user_id;
    });
    try {
      this.error = "";
      this.isLoading = true;
      const postData = { ...newActivity, participants };
      //console.log("pd: ", postData);
      const res = yield client.post(`/api/team/ilo/`, { ...postData });
      const thisIlo = { ...res.data };
      //      this.teams.push(thisIlo);
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  updateIloActivity = flow(function* (this: TeamStore, newActivity: Activity) {
    const teamId = newActivity.team;
    const participants = newActivity.participants?.map((mem) => {
      if (mem.user_id) return mem.user_id;
    });
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.put(`/api/team/ilo/${newActivity.id}/`, {
        ...newActivity,
        participants,
      });
      const thisIlo = { ...res.data };
      //      this.teams.push(thisIlo);
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  deleteIloActivity = flow(function* (this: TeamStore, teamId: number, activityId: number) {
    try {
      this.error = "";
      const res = yield client.delete(`/api/team/${teamId}/ilo/activity/${activityId}/`);
      console.log("api res: ", res.data);
      return res;
    } catch (err) {
      console.log("api err: ", err);
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  loadactivityList = flow(function* (this: TeamStore, teamId: number) {
    try {
      const res = yield client.get(`/api/team/${teamId}/ilo/activity/`);
      return res.data.results;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
    }
  });

  loadActivity = flow(function* (this: TeamStore, teamId: number, activityId: number) {
    try {
      const res = yield client.get(`/api/team/${teamId}/ilo/activity/${activityId}/`);
      return res.data;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
    }
  });

  //#endregion
}
