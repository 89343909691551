import moment from "moment";
import config from "config";
import { stringify } from "querystring";

export interface Time {
  key: string;
  value: string;
}

export const getTimes = (interval: number): Time[] => {
  let times: Time[] = []; // times array
  let startTime = 0; // start time
  var ampmArray = [" AM", " PM"]; // AM-PM

  //loop to increment the time and push results in array
  for (var i = 0; startTime < 24 * 60; i++) {
    var hh = Math.floor(startTime / 60); // getting hours of day in 0-24 format
    var mm = startTime % 60; // getting minutes of the hour in 0-55 format
    times[i] = {
      key: [0, 12].includes(hh)
        ? `12:${("0" + mm).slice(-2)}${ampmArray[Math.floor(hh / 12)]}`
        : `${("0" + (hh % 12)).slice(-2)}:${("0" + mm).slice(-2)}${ampmArray[Math.floor(hh / 12)]}`, // pushing data in array in [00:00 - 12:00 AM/PM format]
      value: `${hh.toString().slice(-2)}:${("0" + mm).slice(-2)}`,
    };
    startTime = startTime + interval;
  }
  return times;
};
