import { Box, createStyles, Hidden, makeStyles, Paper, Theme } from "@material-ui/core";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { wdpStrings } from "config/strings";
import { BuildingWithCapacity } from "interfaces/Building";
import { observer } from "mobx-react-lite";
import moment from "moment-timezone";
import Icon from "@mdi/react";
import { mdiClockTimeFourOutline } from "@mdi/js";
import ClockOutline from "@mdi/js";
import { bhpColor } from "styles/globals";
import { useState } from "react";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: bhpColor.blueGrey1,
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        backgroundColor: bhpColor.white,
      },
    },
    buildingName: {
      fontSize: 18,
    },
    firstLine: {},
    secondLine: {
      color: bhpColor.blueGrey2,
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    thirdLine: {
      color: bhpColor.grey2,
      fontSize: 12,
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
      },
    },
    defaultName: {
      float: "right",
      color: bhpColor.grey1,
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
      },
    },
    hoverOfficeSummaryBorder: {
      border: `2px solid #84898F`,
      "&:hover": {
        border: `2px solid ${bhpColor.blueGrey1}`,
      },
    },
  })
);

interface OfficeSummaryCardProps {
  building: BuildingWithCapacity | null;
  onOfficeSelect?: any;
  isDefault?: boolean;
  t: any; //i18n translation
}

const OfficeSummaryCard = observer((props: OfficeSummaryCardProps) => {
  const { building, onOfficeSelect, isDefault, t } = props;
  const classes = innerStyles();
  const selectOffice = (officeId: number) => {
    onOfficeSelect(officeId);
  };

  const getTime = (timezone?: string) => {
    return timezone
      ? `${t(moment(new Date()).tz(timezone).format("ddd"))} ${moment(new Date())
          .tz(timezone)
          .format("H:mma")} (${moment().tz(timezone).format("z")})`
      : `${t(moment(new Date()).format("DDD"))} ${moment(new Date()).format("dd")} ${moment(
          new Date()
        ).format("H:mma")}`;
  };

  return building ? (
    <Paper
      elevation={0}
      className={`${classes.root} ${classes.hoverOfficeSummaryBorder}`}
      onClick={() => selectOffice(building.id)}
    >
      <Box padding={2} className={classes.firstLine}>
        <Box className={classes.buildingName}>
          {building ? (
            <Box>
              <strong>
                {building.city}, {building.building_name}
              </strong>
              {isDefault ? <Box className={classes.defaultName}>({t("Default")})</Box> : ""}
            </Box>
          ) : (
            " "
          )}
        </Box>
        <Box paddingTop={0.5} display="flex" className={classes.secondLine} alignItems="center">
          <Box marginRight={1} display="flex">
            <Icon path={mdiClockTimeFourOutline} size={0.8} />
          </Box>
          <Box>{building ? getTime(building.building_timezone) : ""}</Box>
        </Box>
        <Box paddingTop={2} className={classes.thirdLine}>
          {building ? `${building.booked_attendance} ${t("At a BHP Location")}` : t("BHP Location")}
        </Box>
      </Box>
    </Paper>
  ) : (
    // we could do a skeleton here probably
    <></>
  );
});

export default OfficeSummaryCard;
