import { Avatar, Box, Grid, Hidden, IconButton, makeStyles } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNotificationStore } from "providers/RootStoreProvider";

import Typography from "@material-ui/core/Typography";
// import NotificationsIcon from "@material-ui/icons/Notifications";
// import Badge from "@material-ui/core/Badge";
import { bhpColor } from "styles/globals";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  topBar: {
    minHeight: "214px",
    backgroundColor: bhpColor.blueGrey4,
    position: "relative",
    margin: 0,
    flexGrow: 1,
    // borderColor: #d7dfe2 !important;
  },
  headers: {
    position: "absolute",
    bottom: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  breadcrumb: {
    flexGrow: 1,
    fontWeight: "bold",
    color: bhpColor.blueGrey1,
    fontSize: 14,
    position: "relative",
    top: -50,
    zIndex: 100,
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      top: 0,
    },
  },
}));

interface TopbarProps {
  title: string;
  subtitle: string;
  breadcrumb: string;
  showAvatar?: boolean;
  avatarSrc?: string;
  avatarText?: string;
}

const Topbar = observer((props: TopbarProps) => {
  const { avatarSrc, avatarText, showAvatar } = props;
  const classes = useStyles();
  const notificationStore = useNotificationStore();
  const [hourlyBookingAlertOpen, setHourlyBookingAlertOpen] = useState<boolean>(false);
  const handleHourlyBookingAlertClose = () => {
    localStorage.setItem("isHourlyBookingAlertClosed", "true");
    setHourlyBookingAlertOpen(false);
  };

  useEffect(() => {
    notificationStore.getUnreadCount();
    const isHourlyBookingAlertClosed = localStorage.getItem("isHourlyBookingAlertClosed");
    if (isHourlyBookingAlertClosed) {
      isHourlyBookingAlertClosed === "true"
        ? setHourlyBookingAlertOpen(false)
        : setHourlyBookingAlertOpen(false);
    } else {
      localStorage.setItem("isHourlyBookingAlertClosed", "false");
      setHourlyBookingAlertOpen(true);
    }
  }, []);

  return (
    <>
      <div className={classes.topBar}>
        <Hidden xsDown>
          <Box paddingLeft={{ sm: 2, md: 4 }}>
            <Typography variant="h6" className={classes.breadcrumb}>
              {props.breadcrumb}
            </Typography>
          </Box>
        </Hidden>
        <Box className={classes.headers} style={{ display: "inline-flex" }}>
          {showAvatar && (
            <Box style={{ alignSelf: "center" }} paddingRight={2}>
              <Avatar src={avatarSrc} style={{ width: 100, height: 100 }}>
                {avatarText}
              </Avatar>
            </Box>
          )}
          <Box style={{ alignSelf: "center" }}>
            <Typography variant="h1">{props.title}</Typography>
            <Typography variant="h2" style={{ marginTop: 8 }}>
              {props.subtitle}
            </Typography>
          </Box>
        </Box>

        {/* <Grid container style={{ display: "flex" }} className={classes.headers}>
        {showAvatar && (
          <Grid item xs={1} style={{ alignSelf: "center" }}>
            <Avatar
              src={avatarSrc}
              style={{ width: smAndUp ? 100 : 100, height: smAndUp ? 100 : 100 }}
            >
              {avatarText}
            </Avatar>
          </Grid>
        )}
        <Grid item style={{ alignSelf: "center" }}>
          <Typography variant="h1">{props.title}</Typography>
          <Typography variant="h2" style={{ marginTop: 8 }}>
            {props.subtitle}
          </Typography>
        </Grid>
      </Grid> */}
      </div>
      {/* {hourlyBookingAlertOpen && (
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleHourlyBookingAlertClose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>
            <strong>Platform Changes!</strong>
          </AlertTitle>
          We're moving to time-based desk booking. Please bear with us as measures and pages may not
          be accurate or look as they should.
        </Alert>
      )} */}
    </>
  );
});

export default Topbar;
