import { Box, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { bhpColor, settings } from "styles/globals";
import { BuildingTeamSchedule } from "interfaces/Schedule";
import { Team } from "interfaces/Teams";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    teamBox: {
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: settings.borderRadius,
      border: `1px solid ${bhpColor.blueGrey1}`,
      "&.mobile": {
        width: "85%",
      },
      height: "100%",
      textAlign: "center",
    },
  })
);

interface TeamScheduleBoxProps {
  isSite?: boolean;
  t: any;
  team: Team | null;
  buildingTeamSchedule: BuildingTeamSchedule[] | null;
  date: string;
  isMobile?: boolean;
}

const TeamScheduleBox = observer((props: TeamScheduleBoxProps) => {
  const { isSite, t, buildingTeamSchedule, date, isMobile = false } = props;
  const classes = innerStyles();
  const theSchedule = buildingTeamSchedule
    ? buildingTeamSchedule.find((bts) => moment(bts.date).isSame(moment(date), "day"))
    : null;
  const isOffice = !isSite;

  return isMobile ? (
    <Box className={`${classes.teamBox} mobile`}>
      <Grid container style={{ height: "100%" }}>
        <Grid item>
          <Box
            pt={0.5}
            style={{
              fontSize: 10,
              writingMode: "vertical-lr",
              lineHeight: "10px",
            }}
          >
            {t("Members")}
            <br />
            {t("Team")}
          </Box>
        </Grid>
        <Grid item>
          <Box mt={0.25} style={{ color: bhpColor.blueGrey2, fontWeight: 700, height: "20px" }}>
            {theSchedule?.total_users_planned || "0"}
          </Box>
          {isOffice && (
            <Box mt={1} style={{ color: bhpColor.orange2, fontWeight: 700, height: "20px" }}>
              {theSchedule?.total_users_booked || "0"}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box className={classes.teamBox}>
      <Grid container style={{ height: "100%" }}>
        <Grid item sm={isOffice ? 6 : 12}>
          <Typography style={{ color: bhpColor.blueGrey2, fontWeight: 700, height: "20px" }}>
            {theSchedule?.total_users_planned || "0"}
          </Typography>
        </Grid>
        {isOffice && (
          <Grid item sm={6}>
            <Typography style={{ color: bhpColor.orange2, fontWeight: 700, height: "20px" }}>
              {theSchedule?.total_users_booked || "0"}
            </Typography>
          </Grid>
        )}
        <Grid item sm={12}>
          <Box
            pt={0.5}
            style={{
              fontSize: 10,
            }}
          >
            {t("Team")}
            <br />
            {t("Members")}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

export default TeamScheduleBox;
