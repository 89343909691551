import { Box, createStyles, Link, makeStyles, Theme } from "@material-ui/core";
import { wdpStrings } from "config/strings";
import { BuildingWithCapacity } from "interfaces/Building";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { getAvailableCapacity } from "utils/locations";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    buildingName: {
      fontSize: 20,
    },
  })
);

interface ShowBuildingSummaryProps {
  building: BuildingWithCapacity | null;
  t: any;
  spaceCount?: number;
}

const ShowBuildingSummary = observer((props: ShowBuildingSummaryProps) => {
  const { building, t } = props;
  const classes = innerStyles();
  const correctedTotalCapacity = getAvailableCapacity(
    building?.adjusted_capacity,
    building?.total_capacity
  );

  const remainingSpace = building?.booked_attendance
    ? correctedTotalCapacity - building.booked_attendance
    : correctedTotalCapacity;

  return building ? (
    <Box>
      <Box className={classes.buildingName}>
        {building && building.id !== 0 ? (
          <Link href={`/offices/${building.id}`} color="secondary" underline="always">
            <strong>{building.city},</strong> {building.building_name}
          </Link>
        ) : (
          " "
        )}
      </Box>
      {/* {building && building.id !== 0
        ? `${props.spaceCount} ${t("of")} ${correctedTotalCapacity.toFixed(0)} ${t(
            "spaces available"
          )}`
        : " "} */}
      {props.spaceCount} {t("of")} {correctedTotalCapacity.toFixed(0)} {t("spaces available")}
    </Box>
  ) : (
    <></>
  );
});

export default ShowBuildingSummary;
