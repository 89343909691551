import { Notification } from "interfaces/Notification";
import { makeAutoObservable, flow } from "mobx";
import RootStore from "./RootStore";
import client from "services/api";

export default class NotificationStore {
  public isLoading = false;
  public error = "";
  public rootStore!: RootStore;
  public notifications: Notification[] = [];
  public unreadCount: number = 0;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  getUnreadCount = flow(function* (this: NotificationStore) {
    this.isLoading = true;
    this.error = "";

    try {
      const res = yield client.get(`/api/notifications/unread/count/`);
      this.unreadCount = res.data.count;
    } catch (err: any) {
      this.error = err;
    }
    this.isLoading = false;
  });

  getNotifications = flow(function* (this: NotificationStore) {
    this.isLoading = true;
    this.error = "";
    try {
      this.notifications = [];
      const res = yield client.get(`/api/notifications/`);
      this.notifications = res.data;
    } catch (err: any) {
      this.error = err;
    }
    this.isLoading = false;
  });

  markNotificationsAsRead = flow(function* (this: NotificationStore) {
    this.error = "";
    try {
      yield client.post(`/api/notifications/mark-as-read/`);
      this.unreadCount = 0;
    } catch (err: any) {
      this.error = err;
    }
  });
}
