import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { bhpColor } from "styles/globals";
import SearchIcon from "@material-ui/icons/Search";
import { debounce } from "lodash";
import { ScheduleType } from "interfaces/Schedule";
import { Autocomplete } from "@material-ui/lab";
import { capitalizeString } from "utils/string";
//import useMediaQuery from "utils/useMediaQuery";

interface TeamDetailsPeopleSearchProps {
  teamId: number;
  focussedDate: string;
  onSearch: any;
  plannerToggle: any;
  onWorkTypeChange: any;
  handleSortByName: any;
  workType: ScheduleType | null;
  t: any;
}

const TeamDetailsPeopleSearch = observer((props: TeamDetailsPeopleSearchProps) => {
  const { onSearch, onWorkTypeChange, plannerToggle, handleSortByName, workType, t } = props;
  const [showPlannerView, setShowPlannerView] = useState(false);
  const [sortBy, setSortBy] = useState(1);

  const doSearch = (searchTerm: string) => {
    onSearch(searchTerm);
  };

  const handlePlannerToggle = () => {
    setShowPlannerView((prev) => !prev);
  };

  const handleSortChange = (event: any) => {
    setSortBy(event.target.value);
  };
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    plannerToggle(showPlannerView);
    handleSortByName(sortBy);
  }, [showPlannerView, sortBy]);

  const workTypes = [ScheduleType.OFFICE, ScheduleType.REMOTE, ScheduleType.OFF];
  const sortWorkTypesBy = [
    { text: "A-Z", value: 1 },
    { text: "Z-A", value: -1 },
  ];
  return (
    <>
      <Box pt={8}>
        <Grid container style={{ justifyContent: `${mdAndUp ? "space-between" : "space-around"}` }}>
          <Grid item lg={3} md={3} sm={6} xs={6}>
            <TextField
              id="name"
              label={t("Search People")}
              placeholder={t("Search People")}
              type="text"
              size="small"
              variant="outlined"
              fullWidth
              autoComplete="off"
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
              onChange={debounce((event: any) => {
                doSearch(event.target.value);
              }, 1000)}
            />
          </Grid>
          {showPlannerView && mdAndUp ? (
            <>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Autocomplete
                  id="workTypeAutoComplete"
                  options={workTypes}
                  getOptionLabel={(workType) => {
                    return capitalizeString(ScheduleType[workType].toString());
                  }}
                  fullWidth
                  autoComplete
                  value={workType}
                  onChange={(event: any, value: any) => {
                    onWorkTypeChange(value);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      id="worktype"
                      label={t("Work Type")}
                      placeholder={t("Start typing to search Work Type...")}
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={3}>
                <FormControl fullWidth variant="outlined" size="small">
                  <Select
                    labelId="sortBySelect"
                    id="sortBy"
                    value={sortBy}
                    onChange={handleSortChange}
                    variant={"outlined"}
                    fullWidth
                  >
                    {sortWorkTypesBy.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          ) : (
            ""
          )}
          <Grid
            item
            lg={3}
            md={3}
            sm={5}
            xs={5}
            style={{ textAlign: "right", alignContent: "flex-end" }}
          >
            <FormControl component="fieldset">
              <FormGroup color="secondary" aria-label="plannerView" row>
                <FormControlLabel
                  checked={showPlannerView}
                  onChange={handlePlannerToggle}
                  control={<Switch color="secondary" />}
                  label={
                    <Box color={bhpColor.blueGrey1} fontWeight="bold">
                      {t("Weekly Planner View")}
                    </Box>
                  }
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});
export default TeamDetailsPeopleSearch;
