import { ScheduleType } from "interfaces/Schedule";
import { mdiMapMarkerRadiusOutline, mdiHeadset, mdiCalendarRemoveOutline } from "@mdi/js";

export const getIconPath = (workType: ScheduleType): string => {
  switch (workType) {
    case ScheduleType.OFFICE:
      return mdiMapMarkerRadiusOutline;
    case ScheduleType.REMOTE:
      return mdiHeadset;
    case ScheduleType.OFF:
      return mdiCalendarRemoveOutline;
    default:
      return mdiCalendarRemoveOutline;
  }
};
