import { t } from "i18next";
import { Building, BuildingWithCapacity, Space } from "interfaces/Building";
import { Schedule, ScheduleType, SettingsScheduleType } from "interfaces/Schedule";
import { TeamParticipant } from "interfaces/Teams";
import { User } from "interfaces/User";
import { get } from "lodash";
import { toJS } from "mobx";
import { storeAnnotation } from "mobx/dist/internal";
import moment from "moment";
import { useBuildingStore, useUserStore } from "providers/RootStoreProvider";
import RootStore from "stores/RootStore";
import { bhpColor } from "styles/globals";
import { addHours, getDateString, getNth, getThisWeek } from "./date";

export interface Period {
  start: Date;
  end: Date;
}

export interface StoreUserBookingList {
  [date: string]: BasicBooking[];
}

export interface StoreSpaceBookingList {
  [date: string]: BasicBooking[];
}

export interface StoreFloorBookingList {
  [floor_id: string]: BasicBooking[];
}

export interface StoreBuildingBookingList {
  [building_id: string]: BasicBooking[];
}

export interface StoreBuildingBookingListLite {
  [building_id: string]: BasicBookingLite[];
}

export interface DateFocussedBuilding {
  [date: string]: number;
}

export const BOOKING_GAP = 60; // minutes - round to nearest half hour after booking gap
export const DEFAULT_INCREMENT = 15; // minutes from the hour, so 0,15,30,45 for start & finish
export const BOOKING_DURATION_MIN = 30; // minimum booking length
export const BOOKING_DURATION_MAX = 1440; // maximum booking length
export const DEFAULT_START_TIME = "09:00";
export const DEFAULT_END_TIME = "17:00";
export const DEFAULT_OFFICE_START_TIME = "09:00";
export const DEFAULT_OFFICE_END_TIME = "17:00";
export const MAX_DAYS_AHEAD_TO_BOOK = 14;

export interface BuildingSpecs {
  gap: number; // minutes - round to nearest half hour after booking gap
  lock: number; // minutes from the hour, so 0,15,30,45 for start & finish
  min_duration: number; // minimum booking length
  max_duration: number; // maximum booking length
  default_start: string; // "09:00",
  default_end: string; //"17:00",
  timezone: string;
}

export interface BasicBooking {
  id: number;
  space: Partial<Space> | null;
  building?: Partial<Building> | null;
  user_id: number | null;
  user_name: string | null;
  user_is_first_aid_officer?: boolean | null;
  user_is_fire_warden?: boolean | null;
  booking_period: Period;
  booking_details?: {
    start_date_local: string;
    start_time_local: string;
    end_date_local: string;
    end_time_local: string;
  };
  desk_required?: boolean;
}

export interface BasicBookingLite {
  id: number;
  building_id?: string;
  booking_period: Period;
  booking_details?: {
    start_date_local: string;
    start_time_local: string;
    end_date_local: string;
    end_time_local: string;
  };
}

export interface BookingJSON {
  id: number;
  user?: {
    id: number;
    first_name: string | null;
    last_name: string | null;
    preferred_name: string | null;
    is_fire_warden: boolean | null;
    is_first_aid_office: boolean | null;
  };
  space: Partial<Space>;
  building: Partial<Building> | null;
  start_datetime_utc: string; //"2022-05-19T01:30:00Z",
  end_datetime_utc: string;
  start_date_local: string;
  start_time_local: string;
  end_date_local: string;
  end_time_local: string;
}

export const getDeskHours = (user: User | null, date: string) => {
  let desk_start_time = DEFAULT_START_TIME;
  let desk_finish_time = DEFAULT_END_TIME;
  if (user) {
    const dayOfWeek = moment(date).day();
    switch (dayOfWeek) {
      case 0:
        desk_start_time =
          user.profile.default_week.sun_default.desk_start_time || DEFAULT_START_TIME;
        desk_finish_time =
          user.profile.default_week.sun_default.desk_finish_time || DEFAULT_END_TIME;
        break;
      case 1:
        desk_start_time =
          user.profile.default_week.mon_default.desk_start_time || DEFAULT_START_TIME;
        desk_finish_time =
          user.profile.default_week.mon_default.desk_finish_time || DEFAULT_END_TIME;
        break;
      case 2:
        desk_start_time =
          user.profile.default_week.tue_default.desk_start_time || DEFAULT_START_TIME;
        desk_finish_time =
          user.profile.default_week.tue_default.desk_finish_time || DEFAULT_END_TIME;
        break;
      case 3:
        desk_start_time =
          user.profile.default_week.wed_default.desk_start_time || DEFAULT_START_TIME;
        desk_finish_time =
          user.profile.default_week.wed_default.desk_finish_time || DEFAULT_END_TIME;
        break;
      case 4:
        desk_start_time =
          user.profile.default_week.thu_default.desk_start_time || DEFAULT_START_TIME;
        desk_finish_time =
          user.profile.default_week.thu_default.desk_finish_time || DEFAULT_END_TIME;
        break;
      case 5:
        desk_start_time =
          user.profile.default_week.fri_default.desk_start_time || DEFAULT_START_TIME;
        desk_finish_time =
          user.profile.default_week.fri_default.desk_finish_time || DEFAULT_END_TIME;
        break;
      case 6:
        desk_start_time =
          user.profile.default_week.sat_default.desk_start_time || DEFAULT_START_TIME;
        desk_finish_time =
          user.profile.default_week.sat_default.desk_finish_time || DEFAULT_END_TIME;
        break;
    }
  }
  return { desk_start_time, desk_finish_time };
};

export const getWeekOfDates = (date: Date, numberOfDays: number = 7) => {
  const theWeek = getThisWeek(date, numberOfDays);
  const dates: Date[] = [];
  for (let i = 0; i < numberOfDays; i++) {
    const thisDate = new Date(theWeek.start);
    thisDate.setDate(thisDate.getDate() + i);
    dates.push(thisDate);
  }
  return dates;
};

export const getForwardDates = (date: Date, numberOfDays: number = 7) => {
  const dates: Date[] = [];
  for (let i = 0; i < numberOfDays; i++) {
    const thisDate = new Date(date);
    thisDate.setDate(thisDate.getDate() + i);
    dates.push(thisDate);
  }
  return dates;
};

/**
 * get a colour based on the availability cues
 * 000 available, 111 unavailable, otherwise partly_available
 * @param status - "000" | "100" | "010" | "001" | "110" | "011" | "101" | "111"
 * @returns color: available | unavailable | partly_available
 */
export const getColor = (status: string) => {
  let colour = bhpColor.available;
  switch (status) {
    case "000":
      colour = bhpColor.available;
      break;
    case "111":
      colour = bhpColor.unavailable;
      break;
    default:
      colour = bhpColor.partly_available;
  }
  return colour;
};

export const getAvailableTimes = (
  bookings: BasicBooking[],
  requestedPeriod: Period,
  space_id: number,
  buildingSpecs: BuildingSpecs
) => {
  const dayBookings = getBookingsTable(
    getDateString(requestedPeriod.start),
    bookings,
    space_id,
    buildingSpecs,
    undefined
  );
  const availableTimes: string[] = [];
  dayBookings
    .filter((bkg) => bkg && !bkg.user_id)
    .map((bkg) => {
      availableTimes.push(
        `${getTimeStringFromDate(
          bkg.booking_period.start,
          true,
          buildingSpecs.timezone
        )} - ${getTimeStringFromDate(bkg.booking_period.end, true, buildingSpecs.timezone)}`
      );
    });
  return availableTimes.join(",\n");
};

export const buildLegacySpace = (pseudoSpace: Partial<Space> | null) => {
  const newSpace: Space | null = pseudoSpace
    ? {
        ...pseudoSpace,
        id: pseudoSpace.id || 0,
        name: pseudoSpace.name || "",
        coordinates: { x: 0, y: 0 },
      }
    : null;
  return newSpace;
};

/**
 * see if a firewarden is booked on this space
 * @param bookings - all the bookings
 * @param space_id - the space to check against
 * @returns true if a firewarden has this space booked
 */
export const isFireWardenBooked = (bookings: BasicBooking[], space_id: number | null) => {
  return !space_id
    ? false
    : bookings.filter((bkg) => bkg.space?.id === space_id && bkg.user_is_fire_warden).length > 0;
};

/**
 * see if a firstaidOfficer is booked on this space
 * @param bookings - all the bookings
 * @param space_id - the space to check against
 * @returns true if a firstAidOfficer has this space booked
 */
export const isFirstAidOfficerBooked = (bookings: BasicBooking[], space_id: number | null) => {
  return !space_id
    ? false
    : bookings.filter((bkg) => bkg.space?.id === space_id && bkg.user_is_first_aid_officer).length >
        0;
};

export const LocaleDateToAus = (dateString: string) => {
  const theParts = dateString.split("/");
  return `${theParts[2]}-${theParts[0]}-${theParts[1]}`;
};

export const getBookingDateTimeData = (deskHours: Period, timeZone: string) => {
  let start_date_local: string = LocaleDateToAus(
    deskHours.start.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone,
    })
  );
  let start_time_local: string = deskHours.start.toLocaleString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    timeZone,
  });
  let end_date_local: string = LocaleDateToAus(
    deskHours.end.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone,
    })
  );
  let end_time_local: string = deskHours.end.toLocaleString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    timeZone,
  });
  const bookingData = { start_date_local, start_time_local, end_date_local, end_time_local };
  return bookingData;
};

export const getBookingDateTimeDataFromMoment = (deskHours: Period, timeZone: string) => {
  const startTime = moment(deskHours.start);
  const endTime = moment(deskHours.end);

  let start_date_local: string = startTime.clone().tz(timeZone).format("YYYY-MM-DD");
  let start_time_local: string = startTime.clone().tz(timeZone).format("HH:mm");
  let end_date_local: string = endTime.clone().tz(timeZone).format("YYYY-MM-DD");
  let end_time_local: string = endTime.clone().tz(timeZone).format("HH:mm");
  const bookingData = { start_date_local, start_time_local, end_date_local, end_time_local };
  return bookingData;
};

/**
 * get a period covering schedule hours for this day for this building
 * @param date - date to use to get day of week from routine
 * @param defaultStart - from specs or a default start time 09:00
 * @param defaultEnd  - from specs or a default start time 17:00
 * @returns a period {start,finish} or null if nothing available
 */
export const getDefaultScheduleHoursForBuilding = (
  date: string,
  building: BuildingWithCapacity | null
) => {
  const timeZone = building ? building.building_timezone : "UTC";
  const theDate = timeZone
    ? getDateTimeFromStringsAndTimeZone(date, "00:00", timeZone)
    : new Date(date);
  let startTime = new Date(theDate);
  let endTime = new Date(theDate);

  // form a period
  startTime.setHours(
    parseInt(DEFAULT_START_TIME.split(":")[0]),
    parseInt(DEFAULT_START_TIME.split(":")[1])
  );

  endTime.setHours(
    parseInt(DEFAULT_END_TIME.split(":")[0]),
    parseInt(DEFAULT_END_TIME.split(":")[1])
  );
  return { start: startTime, end: endTime };
};

/**
 * get a period covering schedule hours for this day
 * @param date - date to use to get day of week from routine
 * @param defaultStart - from specs or a default start time 09:00
 * @param defaultEnd  - from specs or a default start time 17:00
 * @returns a period {start,finish} or null if nothing available
 */
export const getMyScheduleHours = (mySchedule: Schedule, date: string): Period => {
  const timeZone = mySchedule.office?.building_timezone;
  const startTimeUTC = timeZone
    ? moment.tz(`${date} ${mySchedule.desk_start_time || DEFAULT_START_TIME}`, timeZone).toDate()
    : new Date(date);
  const endTimeUTC = timeZone
    ? moment.tz(`${date} ${mySchedule.desk_finish_time || DEFAULT_END_TIME}`, timeZone).toDate()
    : new Date(date);

  const startAndFinish = { start: startTimeUTC, end: endTimeUTC };
  return startAndFinish;
};

/**
 * get a period covering default hours for this day
 * @param date - date to use to get day of week from routine
 * @param defaultStart - from specs or a default start time 09:00
 * @param defaultEnd  - from specs or a default start time 17:00
 * @returns a period {start,finish} or null if nothing available
 */
export const getMyDefaultHours = (
  me: any,
  date: string,
  defaultStart: string | null = null,
  defaultEnd: string | null = null
): Period => {
  const theDate = new Date(date);
  const dayOfWeek = theDate.getDay();

  let startTime = new Date(theDate);
  let endTime = new Date(theDate);
  if (me) {
    let defaultDay: SettingsScheduleType = me.profile.default_week.mon_default;
    switch (dayOfWeek) {
      case 0:
        defaultDay = me.profile.default_week.sun_default;
        break;
      case 1:
        defaultDay = me.profile.default_week.mon_default;
        break;
      case 2:
        defaultDay = me.profile.default_week.tue_default;
        break;
      case 3:
        defaultDay = me.profile.default_week.wed_default;
        break;
      case 4:
        defaultDay = me.profile.default_week.thu_default;
        break;
      case 5:
        defaultDay = me.profile.default_week.fri_default;
        break;
      case 6:
        defaultDay = me.profile.default_week.sat_default;
        break;
    }
    if (defaultDay.start_time && defaultDay.finish_time) {
      startTime.setHours(
        parseInt(defaultDay.start_time.split(":")[0]),
        parseInt(defaultDay.start_time.split(":")[1])
      );
      endTime.setHours(
        parseInt(defaultDay.finish_time.split(":")[0]),
        parseInt(defaultDay.finish_time.split(":")[1])
      );
      return { start: startTime, end: endTime };
    }
  }
  if (defaultStart && defaultEnd) {
    startTime.setHours(parseInt(defaultStart.split(":")[0]), parseInt(defaultStart.split(":")[1]));
    endTime.setHours(parseInt(defaultEnd.split(":")[0]), parseInt(defaultEnd.split(":")[1]));
    return { start: startTime, end: endTime };
  }
  startTime.setHours(
    parseInt(DEFAULT_START_TIME.split(":")[0]),
    parseInt(DEFAULT_START_TIME.split(":")[1])
  );
  endTime.setHours(
    parseInt(DEFAULT_END_TIME.split(":")[0]),
    parseInt(DEFAULT_END_TIME.split(":")[1])
  );
  return { start: startTime, end: endTime };
};

export const buildLegacyBuilding = (pseudoBuilding: Partial<Building> | null) => {
  const newBuilding: Building | null = pseudoBuilding
    ? {
        ...pseudoBuilding,
        id: pseudoBuilding.id || 0,
        city: pseudoBuilding.city || "",
        building_name: pseudoBuilding.building_name || "",
      }
    : null;
  return newBuilding;
};

export const getScheduleFromBooking = (booking: BasicBooking, schedule?: Schedule) => {
  // if schedule date isn't within booking start/end set space:null
  let scheduleInRangeOfBooking = true;
  if (schedule) {
    const scheduleDate = moment(schedule.date);
    const bookingStartDate = moment(booking.booking_details?.start_date_local);
    const bookingEndDate = moment(booking.booking_details?.end_date_local);
    if (bookingEndDate < scheduleDate || bookingStartDate > scheduleDate)
      scheduleInRangeOfBooking = false;
  }

  let pseudoSchedule: Schedule =
    schedule && !scheduleInRangeOfBooking
      ? { ...schedule }
      : {
          date: getDateString(booking.booking_period.start),
          status: ScheduleType.OFFICE,
          space: buildLegacySpace(booking.space),
          office: buildLegacyBuilding(booking.building || null),
          start_date: booking.booking_details?.start_date_local,
          desk_start_time: booking.booking_details?.start_time_local,
          finish_date: booking.booking_details?.end_date_local,
          desk_finish_time: booking.booking_details?.end_time_local,
          deskHours: booking.booking_period,
          booking_id: booking.id,
          desk_required: booking.desk_required ? 1 : 2,
        };
  return pseudoSchedule;
};

/**
 * creates an array of options {value:string,label:string}
 * where the value is an internationalise datetime string
 * @param startTime
 * @param endTime
 * @param increment
 * @param hour12
 * @returns
 */
export const getTimeDropdownOptions = (
  startTime: Date,
  endTime: Date,
  increment: number = 15,
  hour12: boolean = true,
  isEndTime: boolean = false,
  timeZone: string
) => {
  const dateAtStart = getDateString(startTime);
  let options: { value: string; label: string }[] = [];
  for (
    var loopTime = new Date(startTime);
    loopTime < endTime;
    loopTime = addMinutes(loopTime, increment)
  ) {
    const dateNow = getDateString(loopTime);
    const daysDifference = getDaysApart(dateNow, dateAtStart);
    let displayValue = getLocalTimeStringForTimeZone(loopTime, timeZone);
    if (daysDifference > 0 && isEndTime) displayValue = `+${daysDifference} ${displayValue}`;
    options.push({
      value: jsDateToInternationalisedString(loopTime),
      label: `${displayValue}`,
    });
  }
  return options;
};

/**
 * Allows us to set the ISO or UTC format in one place
 * @param date - javascript Date
 * @returns string in chosen format
 */
export const jsDateToInternationalisedString = (date: Date) => {
  return date.toUTCString();
};

export const getDateTimeFromStringsAndTimeZone = (date: string, time: string, timeZone: string) => {
  const utcDate = new Date(new Date(`${date} ${time}`).toLocaleString("en-US", { timeZone }));
  return utcDate;
};

export const MS_PER_MINUTE = 60000;

/**
 * applys a gap to a date
 * @param date - js date
 * @param forward - true add the gap, false subtract the gap
 * @returns a js date
 */
export const addMinutes = (date: Date, minutes: number) => {
  return new Date(date.getTime() + minutes * MS_PER_MINUTE);
};

export const getLengthOfBooking = (booking: BasicBooking) => {
  const diffInMilliSeconds =
    booking.booking_period.end.getTime() - booking.booking_period.start.getTime();
  return Math.floor(diffInMilliSeconds / MS_PER_MINUTE);
};

export const getLengthOfPeriod = (period: Period) => {
  const diffInMilliSeconds = period.end.getTime() - period.start.getTime();
  return Math.floor(diffInMilliSeconds / MS_PER_MINUTE);
};

/**
 * returns a string describing the number of minutes as hours and minutes
 * @param minutes  number of minutes
 * @returns string H hrs:MM mins
 */
export const getHoursMinutes = (mins: number) => {
  const hours = mins / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return rhours + " hrs " + rminutes + " mins";
};

/**
 * Sum up the number of minutes of bookings
 * @param bookings - the bookings
 * @returns the number of minutes in the bookings
 */
export const calculateTimeBooked = (bookings: BasicBooking[]) => {
  return bookings && bookings.length > 0
    ? bookings.reduce((accumulator, bkg) => accumulator + getLengthOfBooking(bkg), 0)
    : 0;
};

/**
 * Check the list of bookings to identify spaces that are booked in the period
 * @param bookings - list of bookings
 * @param targetPeriod - the time to check
 * @returns a list of space_id's that are booked at this time
 */
export const getSpacesBookedAtThisTime = (bookings: BasicBooking[], targetPeriod: Period) => {
  let spaceIds: number[] = [];
  let spaces: Partial<Space>[] = [];
  if (bookings && bookings.length > 0) {
    bookings.map((bkg, idx) => {
      const bkgStart = bkg.booking_period.start;
      const bkgEnd = bkg.booking_period.end;
      // find bookings that;
      //         overlap this time
      //         start during this time
      //         end during this time
      const isBooked =
        (bkgStart < targetPeriod.start && bkgEnd >= targetPeriod.end) ||
        (bkgStart >= targetPeriod.start && bkgStart < targetPeriod.end) ||
        (bkgEnd > targetPeriod.start && bkgEnd < targetPeriod.end);
      if (isBooked && bkg.space && bkg.space.id && !spaceIds.includes(bkg.space.id)) {
        spaceIds.push(bkg.space.id);
        spaces.push(bkg.space);
      }
    });
  }
  return spaces;
};

/**
 * Check the list of bookings to identify one that touch the period
 * @param bookings - list of bookings
 * @param targetPeriod - the time to check
 * @returns the bookings that touch this time
 */
export const getBookingsAtThisTime = (
  bookings: BasicBooking[],
  targetPeriod: Period,
  isWayFinder: boolean
) => {
  // if wayfinder just look at hours, not date
  if (bookings && bookings.length > 0) {
    const bookingsInRange = bookings.filter((bkg, idx) => {
      if (bkg.booking_period) {
        const bkgStart = bkg.booking_period.start;
        const bkgEnd = bkg.booking_period.end;
        // find bookings that;
        //         overlap this time
        //         start during this time
        //         end during this time
        const isBooked =
          (bkgStart < targetPeriod.start && bkgEnd >= targetPeriod.end) ||
          (bkgStart >= targetPeriod.start && bkgStart < targetPeriod.end) ||
          (bkgEnd > targetPeriod.start && bkgEnd < targetPeriod.end);
        if (isBooked) return { ...bkg };
      }
    });
    return bookingsInRange.slice();
  }
  return [];
};

/**
 * Check the list of bookings to identify one that touch the period
 * @param bookings - list of bookings
 * @param targetPeriod - the time to check
 * @returns the bookings that touch this time
 */
export const getBookingsAtThisTime_old = (bookings: BasicBooking[], targetPeriod: Period) => {
  if (bookings && bookings.length > 0) {
    const bookingsInRange = bookings.filter((bkg, idx) => {
      const bkgStart = bkg.booking_period.start;
      const bkgEnd = bkg.booking_period.end;
      // find bookings that;
      //         overlap this time
      //         start during this time
      //         end during this time
      const isBooked =
        (bkgStart < targetPeriod.start && bkgEnd >= targetPeriod.end) ||
        (bkgStart >= targetPeriod.start && bkgStart < targetPeriod.end) ||
        (bkgEnd > targetPeriod.start && bkgEnd < targetPeriod.end);
      if (isBooked) return { ...bkg };
    });
    return bookingsInRange.slice();
  }
  return [];
};

export const getDaysApart = (date1: string | undefined, date2: string | undefined) => {
  if (!date1 || !date2) return 0;
  if (date1 === date2) return 0;
  const day1 = getDateTimeAtStartOfToday(new Date(date1));
  const day2 = getDateTimeAtStartOfToday(new Date(date2));
  const difference = day1.getTime() - day2.getTime();
  const days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

export const getDaysBetween = (date1: Date | undefined, date2: Date | undefined) => {
  if (!date1 || !date2) return 0;
  if (date1 === date2) return 0;
  const difference = date1.getTime() - date2.getTime();
  const days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

export const hasBookingsFromTeam = (
  bookings: BasicBooking[],
  teamMembers: TeamParticipant[],
  space_id: number | null
) => {
  if (bookings.length === 0 || teamMembers.length === 0) return false;
  let hasMatch = false;
  const relevantBookings = getRelevantBookings(bookings, space_id);

  const teamBookings = relevantBookings.filter((bkg) => {
    const isTeamBooking = teamMembers.filter((mbr) => mbr.user_id === bkg.user_id);
    return isTeamBooking && isTeamBooking.length > 0;
  });
  return teamBookings && teamBookings.length > 0;
};

export const getDateTimeAtStartOfToday = (today: Date = new Date()) => {
  const year = today.getFullYear();
  const mon = today.getMonth();
  const day = today.getDate();
  return new Date(year, mon, day, 0, 0, 0, 0);
};

export const getDayPeriod = (date: Date | null, duration: number = 24, timezone: string | null) => {
  const todayStart = timezone
    ? moment.tz(getDateTimeAtStartOfToday(date ? date : undefined), timezone).toDate()
    : moment(getDateTimeAtStartOfToday(date ? date : undefined)).toDate();
  const todayEnd = addHours(new Date(todayStart), duration - 1);
  todayEnd.setMinutes(59, 0, 0);
  return { start: todayStart, end: todayEnd };
};

export const getToday = () => {
  const todayStart = getDateTimeAtStartOfToday();
  const todayEnd = addHours(new Date(todayStart), 23);
  todayEnd.setMinutes(59, 0, 0);
  return { start: todayStart, end: todayEnd };
};

export const isPeriodAvailable = (
  bookings: BasicBooking[],
  space_id: number | null,
  requestedPeriod: Period,
  gap: number,
  min_duration: number
) => {
  const relevantBookings = getRelevantBookings(bookings, space_id);
  const periodToCheck = {
    start: addMinutes(requestedPeriod.start, gap * -1),
    end: addMinutes(requestedPeriod.end, gap),
  };
  const bps = bookingPeriodStatus(
    relevantBookings,
    periodToCheck,
    null,
    true,
    true,
    false,
    gap,
    min_duration
  );
  if (bps !== "000") return false; // a booking is in this range

  // time is available, but make sure we aren't within the building.gap of the end of the previous booking
  const closeFinishers = relevantBookings.filter(
    (bkg) =>
      bkg.booking_period.start < periodToCheck.start &&
      addMinutes(bkg.booking_period.end, gap) > requestedPeriod.start
  );
  const isAvailable = !closeFinishers || (closeFinishers && closeFinishers.length === 0);
  return isAvailable;
};

/**
 * see if this booking is possible
 * check it is within min-max length
 * check it isn't within gap of current bookings
 * @param bookings - the bookings
 * @param requestedPeriod - {start,end}
 * @param space_id - the space id
 * @returns true/false if booking is possible
 */
export const isTimeAvailable = (
  bookings: BasicBooking[],
  requestedPeriod: Period,
  space_id: number | null,
  min_duration: number,
  max_duration: number,
  gap: number
) => {
  const periodLengthMs = Math.round(
    (requestedPeriod.end.getTime() - requestedPeriod.start.getTime()) / MS_PER_MINUTE
  );
  // make sure length is correct
  if (periodLengthMs < min_duration || periodLengthMs > max_duration) return false;

  const relevantBookings = getRelevantBookings(bookings, space_id);
  // make sure no booking is complete around the actual period
  const bookingsOverPeriod = relevantBookings.filter(
    (bkg) =>
      bkg.booking_period.start <= requestedPeriod.start &&
      bkg.booking_period.end >= requestedPeriod.end
  );
  if (bookingsOverPeriod && bookingsOverPeriod.length > 0) return false;

  // make sure no booking is within the avtual period
  const bookingsWithinPeriod = relevantBookings.filter(
    (bkg) =>
      bkg.booking_period.start >= requestedPeriod.start &&
      bkg.booking_period.end <= requestedPeriod.end
  );
  if (bookingsWithinPeriod && bookingsWithinPeriod.length > 0) return false;

  // make sure no booking ends within gap of the start
  // a booking ends between start of this period - gap and start of this period
  const bookingsCloseToStart = relevantBookings.filter(
    (bkg) =>
      bkg.booking_period.end > addMinutes(requestedPeriod.start, gap * -1) &&
      bkg.booking_period.end <= requestedPeriod.start
  );
  if (bookingsCloseToStart && bookingsCloseToStart.length > 0) return false;

  // make sure no booking starts or ends within the period
  const bookingsAffected = relevantBookings.filter(
    (bkg) =>
      (bkg.booking_period.start > addMinutes(requestedPeriod.start, gap * -1) &&
        bkg.booking_period.start < addMinutes(requestedPeriod.end, gap)) ||
      (bkg.booking_period.end > addMinutes(requestedPeriod.start, gap * -1) &&
        bkg.booking_period.end < addMinutes(requestedPeriod.end, gap))
  );
  if (bookingsAffected && bookingsAffected.length > 0) return false;

  // make sure no booking starts within gap of the end
  // a booking starts between end of this period and end of this period + gap
  const bookingsCloseToEnd = relevantBookings.filter(
    (bkg) =>
      bkg.booking_period.start >= requestedPeriod.end &&
      bkg.booking_period.start < addMinutes(requestedPeriod.end, gap)
  );
  if (bookingsCloseToEnd && bookingsCloseToEnd.length > 0) return false;

  // check gaps between bookings in period are big enough to book
  // NB. we've already rejected based on overlapping bookings
  // so only looking for those that start or end within the period
  const lookingForGaps = relevantBookings.filter(
    (bkg) =>
      (bkg.booking_period.start >= requestedPeriod.start &&
        bkg.booking_period.start <= requestedPeriod.end) ||
      (bkg.booking_period.end >= requestedPeriod.start &&
        bkg.booking_period.end <= requestedPeriod.end)
  );
  if (lookingForGaps && lookingForGaps.length > 0) {
    let gaps: number[] = [];
    let lastEnd = new Date();
    lookingForGaps
      .sort((bkg1, bkg2) => (bkg1.booking_period.start > bkg2.booking_period.start ? 1 : -1))
      .map((bkg, idx) => {
        if (idx === 0) {
          lastEnd = new Date(bkg.booking_period.end);
        } else {
          const gap = Math.round(
            (bkg.booking_period.start.getTime() - lastEnd.getTime()) / MS_PER_MINUTE
          );
          lastEnd = new Date(bkg.booking_period.end);
          gaps.push(gap);
        }
      });
    const bigEnoughGaps = gaps.filter((gap) => gap > 2 * gap + min_duration);
    if (!bigEnoughGaps) return false;
  }
  return true;
};

const getRelevantBookings = (bookings: BasicBooking[], space_id: number | null) => {
  return space_id
    ? bookings.filter((bkg) => bkg.space && bkg.space.id === space_id).slice()
    : bookings.slice();
};

/**
 * available will return 000
 * any 1s and the space is partly booked
 * 111 space is fully booked
 * @param space_id - space id
 * @param requestedPeriod - start & end of time period UTC
 * @returns XXX -
 *       - digit 1 a booking overlaps start
 *       - digit 2 a booking exists within the period
 *       - digit 3 a booking overlaps end
 */
export const bookingPeriodStatus = (
  bookings: BasicBooking[],
  requestedPeriod: Period | undefined,
  space_id: number | null,
  inclusiveStart: boolean = true,
  inclusiveEnd: boolean = true,
  allowForGap: boolean,
  gap: number = BOOKING_GAP,
  min_duration: number = BOOKING_DURATION_MIN
) => {
  if (!requestedPeriod) return "111";
  // add/remove a minute from the gap to allow for bookings to
  // touch (because this relates to an empty space)
  const actualPeriod = allowForGap
    ? {
        start: addMinutes(requestedPeriod.start, gap * -1 + 1),
        end: addMinutes(requestedPeriod.end, gap - 1),
      }
    : requestedPeriod;

  const relevantBookings = getRelevantBookings(bookings, space_id);
  if (relevantBookings && relevantBookings.length === 0) return "000";

  const raw_period_unavailable = relevantBookings.filter((bkg) => {
    const startTimeLocal = bkg.booking_details?.start_time_local;
    const endTimeLocal = bkg.booking_details?.end_time_local;
    const bkgStart = moment(
      `${moment(requestedPeriod.start).format("YYYY-MM-DD")} ${startTimeLocal}`
    ).toDate();

    const bkgEnd = moment(
      `${moment(requestedPeriod.start).format("YYYY-MM-DD")} ${endTimeLocal}`
    ).toDate();
    return bkgStart <= requestedPeriod.start && bkgEnd >= requestedPeriod.end;
  });

  if (raw_period_unavailable && raw_period_unavailable.length > 0) return "111";

  const period_unavailable = relevantBookings.filter((bkg) => {
    const startTimeLocal = get(bkg, "start_time_local");
    const endTimeLocal = get(bkg, "end_time_local");
    const bkgStart = moment(
      `${moment(requestedPeriod.start).format("YYYY-MM-DD")} ${startTimeLocal}`
    ).toDate();
    const bkgEnd = moment(
      `${moment(requestedPeriod.start).format("YYYY-MM-DD")} ${endTimeLocal}`
    ).toDate();

    if (inclusiveStart)
      if (inclusiveEnd) return bkgStart <= actualPeriod.start && bkgEnd >= actualPeriod.end;
      else return bkgStart <= actualPeriod.start && bkgEnd > actualPeriod.end;
    else if (inclusiveEnd) return bkgStart < actualPeriod.start && bkgEnd >= actualPeriod.end;
    else return bkgStart < actualPeriod.start && bkgEnd > actualPeriod.end;
  });

  if (period_unavailable && period_unavailable.length > 0) return "111";

  // these bookings start before the requested period
  // and finish before the end of the requested period
  const overlap_pre = relevantBookings.filter((bkg) => {
    const startTimeLocal = get(bkg, "start_time_local");
    const endTimeLocal = get(bkg, "end_time_local");
    const bkgStart = moment(
      `${moment(requestedPeriod.start).format("YYYY-MM-DD")} ${startTimeLocal}`
    ).toDate();
    const bkgEnd = moment(
      `${moment(requestedPeriod.start).format("YYYY-MM-DD")} ${endTimeLocal}`
    ).toDate();

    if (inclusiveStart)
      if (inclusiveEnd)
        return (
          bkgStart <= actualPeriod.start &&
          bkgEnd >= actualPeriod.start &&
          bkgEnd <= actualPeriod.end
        );
      else
        return (
          bkgStart <= actualPeriod.start && bkgEnd > actualPeriod.start && bkgEnd < actualPeriod.end
        );
    else if (inclusiveEnd)
      return (
        bkgStart < actualPeriod.start && bkgEnd > actualPeriod.start && bkgEnd <= actualPeriod.end
      );
    else
      return (
        bkgStart < actualPeriod.start && bkgEnd > actualPeriod.start && bkgEnd < actualPeriod.end
      );
  });
  let digit1 = overlap_pre && overlap_pre.length > 0 ? "1" : "0";

  // these bookings start and finish during the requested period
  const overlap_inside = relevantBookings.filter((bkg) => {
    const startTimeLocal = get(bkg, "start_time_local");
    const endTimeLocal = get(bkg, "end_time_local");
    const bkgStart = moment(
      `${moment(requestedPeriod.start).format("YYYY-MM-DD")} ${startTimeLocal}`
    ).toDate();
    const bkgEnd = moment(
      `${moment(requestedPeriod.start).format("YYYY-MM-DD")} ${endTimeLocal}`
    ).toDate();

    if (inclusiveStart)
      if (inclusiveEnd) return bkgStart >= actualPeriod.start && bkgEnd <= actualPeriod.end;
      else return bkgStart >= actualPeriod.start && bkgEnd < actualPeriod.end;
    else if (inclusiveEnd) return bkgStart > actualPeriod.start && bkgEnd <= actualPeriod.end;
    else return bkgStart > actualPeriod.start && bkgEnd < actualPeriod.end;
  });
  const digit2 = overlap_inside && overlap_inside.length > 0 ? "1" : "0";
  if (digit1 === "0" && digit2 === "1") {
    // check digit 1 to make sure there is enough time at start of period to make a valid booking
    const firstBooking =
      overlap_inside.length === 1
        ? overlap_inside[0]
        : overlap_inside.sort((bkg1, bkg2) =>
            bkg1.booking_period.start > bkg2.booking_period.start ? 1 : -1
          )[0];
    const timeBetweenStarts = Math.round(
      (firstBooking.booking_period.start.getTime() - requestedPeriod.start.getTime()) /
        MS_PER_MINUTE
    );
    // change digit1 if not enough time for gap + min booking length
    if (timeBetweenStarts < gap + min_duration) digit1 = "1";
  }

  // these bookings start during the requested period
  // and finish after the end of the requested period
  const overlap_post = relevantBookings.filter((bkg) => {
    const startTimeLocal = get(bkg, "start_time_local");
    const endTimeLocal = get(bkg, "end_time_local");
    const bkgStart = moment(
      `${moment(requestedPeriod.start).format("YYYY-MM-DD")} ${startTimeLocal}`
    ).toDate();
    const bkgEnd = moment(
      `${moment(requestedPeriod.start).format("YYYY-MM-DD")} ${endTimeLocal}`
    ).toDate();

    if (inclusiveStart)
      if (inclusiveEnd)
        return (
          bkgStart >= actualPeriod.start &&
          bkgStart <= actualPeriod.end &&
          bkgEnd >= actualPeriod.end
        );
      else
        return (
          bkgStart >= actualPeriod.start && bkgStart < actualPeriod.end && bkgEnd > actualPeriod.end
        );
    else if (inclusiveEnd)
      return (
        bkgStart > actualPeriod.start &&
        bkgStart <= actualPeriod.start &&
        bkgEnd >= actualPeriod.end
      );
    else
      return (
        bkgStart > actualPeriod.start && bkgStart < actualPeriod.end && bkgEnd > actualPeriod.end
      );
  });
  let digit3 = overlap_post && overlap_post.length > 0 ? "1" : "0";
  if (digit3 === "0" && digit2 === "1") {
    // check digit 3 to make sure there is enough time at end of period to make a valid booking
    const lastBooking =
      overlap_inside.length === 1
        ? overlap_inside[0]
        : overlap_inside.sort((bkg1, bkg2) =>
            bkg1.booking_period.start > bkg2.booking_period.start ? 1 : -1
          )[overlap_inside.length - 1];
    const timeBetweenEnds = Math.round(
      (requestedPeriod.end.getTime() - lastBooking.booking_period.end.getTime()) / MS_PER_MINUTE
    );
    // change digit1 if not enough time for gap + min booking length
    if (timeBetweenEnds < gap + min_duration) digit3 = "1";
  }
  let retVal = `${digit1}${digit2}${digit3}`;

  // only return partial if booking is possible
  let isTimeBookable = true;

  return retVal.indexOf("0") > -1 ? (isTimeBookable ? retVal : "111") : retVal;
};

export const getBuildingBookingSpecs = (store: any, building_id?: number): BuildingSpecs => {
  const thisBuilding = building_id
    ? store.getBuilding(building_id)
    : store.currentBuilding
    ? store.currentBuilding
    : null;
  return thisBuilding
    ? {
        gap: thisBuilding.inter_booking_gap || 60, // minutes - round to nearest half hour after booking gap
        lock: 15, // minutes from the hour, so 0,15,30,45 for start & finish
        min_duration: thisBuilding.minimum_booking_time || 30, // minimum booking length
        max_duration: thisBuilding.maximum_booking_time || 1440, // maximum booking length
        default_start: thisBuilding.office_start_time || "09:00",
        default_end: thisBuilding.office_end_time || "17:00",
        timezone: thisBuilding.building_timezone,
      }
    : {
        gap: 60, // minutes - round to nearest half hour after booking gap
        lock: 15, // minutes from the hour, so 0,15,30,45 for start & finish
        min_duration: 30, // minimum booking length
        max_duration: 1440, // maximum booking length
        default_start: "09:00",
        default_end: "17:00",
        timezone: "UTC",
      };
};

export const getSortedBookings = (bookings: BasicBooking[]) => {
  const sortedArray = bookings.sort((bkg1, bkg2) =>
    bkg1.booking_period.start > bkg2.booking_period.start ? 1 : -1
  );
  return sortedArray.slice();
};

/**
 * Get the bookings that start, finish or overlap this given date
 * take the booking get the localDate and make sure it is on this date
 * @param allBookings
 * @param date - this is a calendar date eg 2022-05-22
 */
export const getBookingsForDate = (
  bookingsDictionary: StoreUserBookingList,
  date: string,
  localTimeZone: string | undefined
) => {
  // loop through entire dictionary extracting bookings that meet the criteria
  let theBookings: BasicBooking[] = [];
  Object.keys(bookingsDictionary).map((key) => {
    const bookings = bookingsDictionary[key].filter((bkg) => {
      let isCorrectDate = false;
      const compareStart = moment(bkg.booking_details?.start_date_local);
      const compareEnd = moment(bkg.booking_details?.end_date_local);
      const compareDate = moment(date);

      const bookingLocalDateStart = localTimeZone
        ? moment(bkg.booking_period.start).tz(localTimeZone)
        : null;
      const bookingLocalDateEnd = localTimeZone
        ? moment(bkg.booking_period.end).tz(localTimeZone)
        : null;

      isCorrectDate =
        bkg.booking_details?.start_date_local === date ||
        bkg.booking_details?.end_date_local === date ||
        (compareStart <= compareDate && compareEnd >= compareDate);
      if (bookingLocalDateStart && bookingLocalDateEnd && !isCorrectDate) {
        // check if coordinated time should show this...
        isCorrectDate =
          bookingLocalDateStart?.format("YYYY-MM-DD") === date ||
          bookingLocalDateEnd?.format("YYYY-MM-DD") === date ||
          (bookingLocalDateStart <= compareDate && bookingLocalDateEnd >= compareDate);
      }
      return isCorrectDate;
    });
    if (bookings && bookings.length > 0) theBookings.push(...bookings);
  });
  return theBookings.sort((bkg1, bkg2) =>
    bkg1.booking_period.start > bkg2.booking_period.start ? 1 : -1
  );
};

export const altDayString = (date: Date) => {
  const theDay = `${date.getDate()}${getNth(date.getDate())}`;
  return theDay;
};

export const getTimeStringFromScheduleTime = (timeString?: string) => {
  if (timeString) {
    return moment(`${moment(new Date()).format("YYYY-MM-DD")} ${timeString}`).format("h:mma");
  }
  return "";
};

export const getLocalTimeStringForTimeZone = (date: Date, timeZone: string) => {
  return moment(date).tz(timeZone).format("h:mma");
};

export const getLocalDateStringForTimeZone = (date: Date, timeZone: string) => {
  return moment(date).tz(timeZone).format("YYYY-MM-DD");
};

export const getMomentTimeString = (date: Date) => {
  return moment(date).format("h:mma");
};

export const getTimeStringFromDate = (
  date: Date | null,
  padded: boolean = true,
  timezone?: string
) => {
  if (!date) return "";
  let formattedTime = "";
  if (timezone) {
    formattedTime = moment.tz(date, timezone).format("h:mma");
    if (formattedTime === "0:00am") formattedTime = "Midnight";
  } else {
    let formattedTime = date.toLocaleString("en-GB", {
      hour12: true,
      hour: padded ? "2-digit" : "numeric",
      minute: "2-digit",
    });
    if (formattedTime.indexOf("pm") > -1) formattedTime = formattedTime.replace("00:", "12:");
    if (formattedTime === "00:00 pm") formattedTime = "12:00pm";
    if (formattedTime === "00:00 am") formattedTime = "Midnight";
    formattedTime = formattedTime.replace("00:", "12:");
    formattedTime = formattedTime.replace(" ", "");
    if (formattedTime.indexOf("am") > -1 || formattedTime.indexOf("pm")) {
      if (formattedTime.substring(0, 1) === "0") formattedTime = formattedTime.substring(1);
    }
  }
  return formattedTime;
};

export const getMinutesBetweenBookings = (booking1: BasicBooking, booking2: BasicBooking) => {
  const diffInMilliSeconds =
    booking2.booking_period.start.getTime() - booking1.booking_period.end.getTime();
  return Math.floor(diffInMilliSeconds / MS_PER_MINUTE);
};

/**
 * applys a gap to a date
 * @param date - js date
 * @param forward - true add the gap, false subtract the gap
 * @returns a js date
 */
export const applyGap = (date: Date, gap: number, forward: boolean = true) => {
  return forward
    ? new Date(date.getTime() + gap * MS_PER_MINUTE)
    : new Date(date.getTime() - gap * MS_PER_MINUTE);
};

export const isToday = (date: string) => {
  const someDate = new Date(date);
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
};

export const tomorrow = (date: Date) => {
  let result = new Date(date.getFullYear(), date.getMonth(), date.getDay(), 0, 0, 0, 0);
  result.setDate(result.getDate() + 1);
  return result;
};

/**
 * Returns booked user_names
 * or null if not given (or null)
 * Unavailable if names aren't available
 * @param bookings
 * @param space_id - the id of the space to check
 * @param specificTime - the time to check
 */
export const getBookedSpaceName = (
  bookings: BasicBooking[],
  space_id: number,
  specificTime: Date | null
) => {
  const timeToUse = specificTime ? specificTime : new Date();
  const spaceBookings = bookings.filter((bkg) => bkg.space && bkg.space.id === space_id).slice();
  // if no specific time, return all names
  if (!specificTime) {
    let names: string[] = [];
    spaceBookings.map((bkg) => {
      if (bkg.user_name && bkg.user_name !== "" && !names.includes(bkg.user_name))
        names.push(bkg.user_name);
    });
    return names;
  }

  if (
    spaceBookings.length === 0 ||
    spaceBookings.filter((bkg) => bkg.booking_period.end < timeToUse)
  )
    return [];
  const overLappedBooking = spaceBookings.filter(
    (bkg) => bkg.booking_period.start < timeToUse && bkg.booking_period.end > timeToUse
  );
  const bestBooking =
    overLappedBooking && overLappedBooking.length > 0
      ? overLappedBooking[0]
      : getSortedBookings(spaceBookings).filter((bkg) => bkg.booking_period.start > timeToUse)[0];
  return bestBooking.user_name && bestBooking.user_name !== null
    ? [bestBooking.user_name]
    : [t("Unavailable")];
};

export const getBookingsTable = (
  dateString: string,
  bookings: BasicBooking[],
  space_id: number | null,
  buildingSpecs: BuildingSpecs,
  timezone: string | undefined
) => {
  // build up a full day of bookings...
  // padded with user_id  = null, user_name = null, when available
  // so midnight 00:00 to first bookings is null
  //const date = getDateTimeAtStartOfToday(new Date(dateString));
  const date = moment.tz(dateString, buildingSpecs.timezone).toDate();
  let dayBookings: BasicBooking[] = [];
  let dayStart = new Date(date);
  let dayEnd = addHours(dayStart, 24);

  if (!bookings) return [];
  if (bookings && bookings.length === 0) return [];
  const sortedBookings = getSortedBookings(
    space_id ? bookings.filter((bkg) => bkg.space && bkg.space.id === space_id) : bookings
  );

  const firstBooking = sortedBookings[0];
  let bookingEnd = firstBooking
    ? applyGap(firstBooking.booking_period.start, buildingSpecs.gap, false)
    : dayEnd;

  const tzone =
    timezone || (firstBooking && firstBooking.building?.building_timezone)
      ? firstBooking.building?.building_timezone
      : buildingSpecs.timezone;

  let thisBooking: BasicBooking = {
    id: 0,
    space: null,
    user_id: null,
    user_name: null,
    booking_period: { start: dayStart, end: bookingEnd },
    booking_details: {
      start_date_local: tzone
        ? moment(dayStart).tz(tzone).format("YYYY-MM-DD")
        : moment(dayStart).format("YYYY-MM-DD"),
      start_time_local: "00:00:00",
      end_date_local: tzone
        ? moment(bookingEnd).tz(tzone).format("YYYY-MM-DD")
        : moment(bookingEnd).format("YYYY-MM-DD"),
      end_time_local: tzone
        ? moment(bookingEnd).tz(tzone).format("HH:mm")
        : moment(bookingEnd).format("HH:mm"),
    },
  };

  dayBookings.push(thisBooking);
  dayBookings.push(firstBooking);

  let previousBooking = firstBooking;
  sortedBookings.map((bkg, idx) => {
    if (idx > 0 && idx < bookings.length) {
      if (
        getMinutesBetweenBookings(previousBooking, bkg) >
        buildingSpecs.gap + buildingSpecs.min_duration
      ) {
        // add an available booking
        let availableFrom = applyGap(previousBooking.booking_period.end, buildingSpecs.gap);
        let availableTo = applyGap(bkg.booking_period.start, buildingSpecs.gap, false);
        dayBookings.push({
          id: bkg.id,
          space: null,
          building: null,
          user_id: null,
          user_name: null,
          booking_details: {
            start_date_local: tzone
              ? moment(availableFrom).tz(tzone).format("YYYY-MM-DD")
              : moment(availableFrom).format("YYYY-MM-DD"),
            start_time_local: tzone
              ? moment(availableFrom).tz(tzone).format("HH:mm")
              : moment(availableFrom).format("HH:mm"),
            end_date_local: tzone
              ? moment(availableTo).tz(tzone).format("YYYY-MM-DD")
              : moment(availableTo).format("YYYY-MM-DD"),
            end_time_local: tzone
              ? moment(availableTo).tz(tzone).format("HH:mm")
              : moment(availableTo).format("HH:mm"),
          },
          booking_period: { start: availableFrom, end: availableTo },
        });
      }
      dayBookings.push(bkg);
    }
    previousBooking = bkg;
  });

  const lastBooking = sortedBookings[sortedBookings.length - 1];
  if (
    lastBooking &&
    lastBooking.booking_period &&
    lastBooking.booking_period.end.getTime() < dayEnd.getTime()
  ) {
    let lastGapStart = applyGap(lastBooking.booking_period.end, buildingSpecs.gap);
    dayBookings.push({
      id: 0,
      space: null,
      building: null,
      user_id: null,
      user_name: null,
      booking_details: {
        start_date_local: tzone
          ? moment(lastGapStart).tz(tzone).format("YYYY-MM-DD")
          : moment(lastGapStart).format("YYYY-MM-DD"),
        start_time_local: tzone
          ? moment(lastGapStart).tz(tzone).format("HH:mm")
          : moment(lastGapStart).format("HH:mm"),
        end_date_local: tzone
          ? moment(dayEnd).tz(tzone).format("YYYY-MM-DD")
          : moment(dayEnd).format("YYYY-MM-DD"),
        end_time_local: tzone
          ? moment(dayEnd).tz(tzone).format("HH:mm")
          : moment(dayEnd).format("HH:mm"),
      },
      booking_period: {
        start: lastGapStart,
        end: new Date(dayEnd),
      },
    });
  }
  dayBookings.map((bkg) => {});
  return dayBookings;
};

export const getDistinctBuildings = (bookings: BasicBooking[]) => {
  let buildingIds: number[] = [];
  let buildings: Partial<Building>[] = [];
  if (bookings) {
    bookings.map((bkg) => {
      if (bkg.building && bkg.building.id && !buildingIds.includes(bkg.building.id)) {
        buildingIds.push(bkg.building.id);
        buildings.push(bkg.building);
      }
    });
  }
  return buildings;
};

export const countDistinctUsers = (bookings: BasicBooking[]) => {
  let users: number[] = [];
  if (bookings) {
    bookings.map((bkg) => {
      if (bkg.user_id && !users.includes(bkg.user_id)) users.push(bkg.user_id);
    });
  }
  return users.length;
};

/**
 * returns an array bookings each of the 24 hours
 * uses the first booking to define the date
 * @param bookings
 */
export const groupByHour = (date: Date, bookings) => {
  const hours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
  ];
  const sortedBookings = getSortedBookings(bookings);
  let groups: { [hr: string]: BasicBooking[] } = {};
  //const startTime = sortedBookings[0].booking_period.start;
  const theDate = getDateTimeAtStartOfToday(date);

  hours.forEach((hr) => {
    const period: Period = {
      start: new Date(theDate.getTime() + hr * MS_PER_MINUTE * 60),
      end: new Date(theDate.getTime() + (hr + 1) * MS_PER_MINUTE * 60),
    };
    const bookingsThisHour = bookings.filter(
      (bkg) =>
        (bkg.booking_period.start <= period.start && bkg.booking_period.end > period.end) ||
        (bkg.booking_period.start >= period.start && bkg.booking_period.start < period.end) ||
        (bkg.booking_period.end >= period.start && bkg.booking_period.start < period.end) ||
        (bkg.booking_period.start >= period.start && bkg.booking_period.end < period.end)
    );
    groups[hr.toString()] = bookingsThisHour;
  });
  return groups;
};

export const getBoolean = (chk: any) => {
  return chk ? true : false;
};

export const getEndString = (bookingEnd: string | undefined, date: string) => {
  if (!bookingEnd) return "";
  if (bookingEnd.indexOf("+") === -1) return `${date} ${bookingEnd}`;
  const parts = bookingEnd.replace("+", "").split(" ");
  const endTime = parts[1];
  const endDate = moment(date).add(parts[0], "day").format("YYYY-MM-DD");
  return `${endDate} ${endTime}`;
};

export const getPeriodStatus = (
  bookings: BasicBooking[],
  startTime: string | undefined,
  endTime: string | undefined
) => {
  if (bookings.length === 0) return "000";
  let bookings_overlapping_start = 0;
  let bookings_overlapping_end = 0;
  let bookings_inside = 0;
  const periodStart = moment(startTime).toDate();
  const periodEnd = moment(endTime).toDate();
  bookings.forEach((bkg) => {
    const bkgStartTime = moment(
      `${bkg.booking_details?.start_date_local} ${bkg.booking_details?.start_time_local}`
    ).toDate();
    const bkgEndTime = moment(
      `${bkg.booking_details?.end_date_local} ${bkg.booking_details?.end_time_local}`
    ).toDate();
    if (bkgStartTime <= periodStart && bkgEndTime >= periodStart) bookings_overlapping_start = 1;
    if (bkgStartTime >= periodStart && bkgEndTime <= periodEnd) bookings_inside = 1;
    if (bkgStartTime <= periodEnd && bkgEndTime >= periodEnd) bookings_overlapping_end = 1;
    if (bkgStartTime <= periodStart && bkgEndTime >= periodEnd) {
      bookings_overlapping_start = 1;
      bookings_inside = 1;
      bookings_overlapping_end = 1;
    }
  });
  return `${bookings_overlapping_start}${bookings_inside}${bookings_overlapping_end}`;
};

export const bookingsAtThisTime = (
  bookings: BasicBooking[],
  startTime: string | undefined,
  endTime: string | undefined,
  timezone: string | undefined
) => {
  if (bookings.length === 0) return [];
  const periodStart = timezone
    ? moment.tz(startTime, timezone).toDate()
    : moment(startTime).toDate();
  const periodEnd = timezone ? moment.tz(endTime, timezone) : moment(endTime).toDate();
  return bookings.filter((bkg) => {
    const bkgStartTime = moment(bkg.booking_period.start).toDate();
    const bkgEndTime = moment(bkg.booking_period.end).toDate();
    if (
      (bkgStartTime <= periodStart && bkgEndTime >= periodStart) ||
      (bkgStartTime >= periodStart && bkgEndTime <= periodEnd) ||
      (bkgStartTime <= periodEnd && bkgEndTime >= periodEnd)
    )
      return true;
  });
};

export const getLengthOfSelection = (
  startTime: string | undefined,
  endTime: string | undefined
) => {
  if (!startTime || !endTime) return 0;
  const st = new Date(startTime);
  const en = new Date(endTime);
  const diff = en.getTime() - st.getTime();
  return Math.round(diff / 60000);
};
