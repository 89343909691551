import { bhpColor } from "styles/globals";

export interface DeskColour {
  r: number;
  g: number;
  b: number;
  hex: string;
}

export const newImageColour: DeskColour = { r: 70, g: 99, b: 113, hex: bhpColor.blueGrey1 };
export const clickedImageColour: DeskColour = { r: 217, g: 217, b: 217, hex: bhpColor.blueGrey4 };
export const tolerance = { black: 75, white: 230 };
export const spot_officer: DeskColour = { r: 255, g: 255, b: 255, hex: bhpColor.white };
export const spot_focussed: DeskColour = { r: 250, g: 182, b: 54, hex: bhpColor.focussed };
export const spot_team: DeskColour = { r: 230, g: 84, b: 0, hex: bhpColor.team };
export const spot_available: DeskColour = { r: 102, g: 170, b: 114, hex: bhpColor.available };
export const spot_unavailable: DeskColour = { r: 80, g: 82, b: 77, hex: bhpColor.unavailable };
export const spot_partly: DeskColour = { r: 0, g: 153, b: 204, hex: bhpColor.partly_available };

export const label_tooltip: { text: DeskColour; background: DeskColour; border: DeskColour } = {
  text: { r: 255, g: 255, b: 255, hex: bhpColor.white },
  background: { r: 117, g: 117, b: 117, hex: bhpColor.grey2 },
  border: { r: 117, g: 117, b: 117, hex: bhpColor.grey2 },
};
