import {
  Box,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Switch,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { BuildingType, BuildingWithCapacity } from "interfaces/Building";
import { bhpColor, settings } from "styles/globals";
import { createRef, Fragment, useEffect, useRef, useState } from "react";
import { wdpStrings } from "config/strings";
import { isWeekend } from "utils/date";
import { BuildingTeamSchedule } from "interfaces/Schedule";
import TeamScheduleBox from "components/team/TeamScheduleBox";
import { Team } from "interfaces/Teams";
import BuildingStore from "stores/BuildingStore";
import { useBuildingStore, useTeamStore } from "providers/RootStoreProvider";
import { mdiChevronUp, mdiChevronDown } from "@mdi/js";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import Icon from "@mdi/react";
import HourlyBookingRanges from "components/dashboard/officeCapacity/HourlyBookingRanges";
import ChartCanvasWrapper from "./components/ChartCanvasWrapper";
import GraphicCapacityChart from "./components/GraphicCapacityChart";
import { toJS } from "mobx";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartColumn: {
      display: "inline-block",
      backgroundColor: bhpColor.blueGrey2,
      "&.today": {
        //backgroundColor: bhpColor.orange1,
      },
      "&.booked": {
        backgroundColor: `${bhpColor.orange2}`,
        "&.today": {
          //backgroundColor: `${bhpColor.blueGrey1}`,
        },
      },
      borderRadius: settings.borderRadius,
      width: `90%`,
      [theme.breakpoints.down(1120)]: {
        width: `90%`,
      },
    },
    columns: {
      minWidth: "25%",
      marginLeft: "auto",
      marginRight: "auto",
      "& .inline": {
        width: "50%",
        display: "inline-block",
      },
    },
    columnValue: {
      fontSize: "16px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      position: "absolute",
      color: bhpColor.blueGrey1,
      "&.isolated": {
        left: 0,
        width: "100%",
        display: "flex",
        flexFlow: "column",
        textAlign: "center",
      },
      "&.planned": {},
      "&.booked": {
        color: `${bhpColor.orange2}`,
      },
    },
    axisLabel: {
      position: "absolute",
      paddingRight: "50%",
      fontWeight: 700,
      fontSize: 16,
      color: bhpColor.blueGrey1,
      width: "100%",
      textAlign: "end",
      [theme.breakpoints.down(1120)]: {},
      "&.full": {
        [theme.breakpoints.down(1120)]: {
          left: `0px`,
        },
      },
    },
    chartMaxLine: {
      borderBottom: `1px dashed ${bhpColor.grey1}`,
      position: "absolute",
    },
    weekend: { backgroundColor: `${bhpColor.blueGrey4}30` },
    row: {
      color: bhpColor.blueGrey1,
      "& .MuiTableCell-root": {
        color: bhpColor.blueGrey1,
        padding: 0,
        paddingLeft: "4px",
        paddingTop: "2px",
        borderBottom: 0,
      },
    },
    td: {
      width: "100%",
    },
    teamBox: {
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: settings.borderRadius,
      border: `1px solid ${bhpColor.blueGrey1}`,
      "&.mobile": {
        width: "85%",
      },
      height: "100%",
      textAlign: "center",
    },
    mobilePlanned: {
      color: bhpColor.grey3,
      whiteSpace: "nowrap",
      paddingLeft: "4px",
      borderBottomRightRadius: settings.borderRadius,
      borderTopRightRadius: settings.borderRadius,
      backgroundColor: bhpColor.blueGrey1,
      height: "25px",
    },
    mobileBooked: {
      color: bhpColor.grey1,
      whiteSpace: "nowrap",
      paddingLeft: "4px",
      borderBottomRightRadius: settings.borderRadius,
      borderTopRightRadius: settings.borderRadius,
      backgroundColor: bhpColor.orange1,
      height: "25px",
    },
  })
);

interface CapacityTabChartProps {
  isSite?: boolean;
  correctedTotalCapacity?: number;
  buildingCapacityRange: BuildingWithCapacity[];
  buildingTeamSchedule: BuildingTeamSchedule[] | null;
  team: Team | null;
  scrollTo?: any;
  t: any;
  focussedDate?: string;
  weekView?: boolean;
  building: BuildingWithCapacity | null;
  is12hr?: boolean;
}

const CapacityTabChart = observer((props: CapacityTabChartProps) => {
  const {
    isSite,
    correctedTotalCapacity,
    buildingCapacityRange,
    buildingTeamSchedule,
    team,
    scrollTo,
    t,
    focussedDate,
    weekView = true,
    building = null,
    is12hr = true,
  } = props;
  const classes = innerStyles();
  const today = new Date();

  const matches = useMediaQuery("(min-width:1024px)");
  const teamStore = useTeamStore();

  const chartWidth = 1024;
  const chartHeight = 600;
  const lgAndUp = useMediaQuery(useTheme().breakpoints.up("lg"));
  const buildingStore = useBuildingStore();

  const getPercentage = (val: number = 0, total: number = 1) => {
    if (val > total) return 100;
    if (val === 0 || total === 0) return 0;
    const retVal = Math.ceil((val / total) * 100);
    return retVal;
  };

  const isOffice = !isSite;

  const buildingData = buildingCapacityRange.length ? buildingCapacityRange[0] : null;
  const capacityToUse =
    buildingData &&
    correctedTotalCapacity &&
    buildingData.total_capacity &&
    correctedTotalCapacity > buildingData.total_capacity
      ? correctedTotalCapacity || 0
      : buildingData?.total_capacity || 0;

  const theBuildingCapacityRange = buildingCapacityRange.map((bc) => {
    return {
      ...bc,
      total_capacity: capacityToUse,
    };
  });
  const disableUpChevron = () => {
    return buildingCapacityRange.findIndex((bc) => moment(today).isSame(bc.date, "day")) >= 0;
  };
  const disableDownChevron = () => {
    return (
      buildingCapacityRange.findIndex((bc) =>
        moment(today).add(14, "days").isSame(bc.date, "day")
      ) >= 0
    );
  };
  const scrollUp = () => scroll(-1);
  const scrollDown = () => scroll(1);
  const scroll = (amount: number) => {
    scrollTo(amount);
  };

  const [teamMembers, setTeamMembers] = useState<number[]>([]);

  useEffect(() => {
    if (team) {
      teamStore.loadTeamMembers(team?.id).then((tm) => {
        setTeamMembers(tm.flatMap((mem) => mem.user_id));
      });
    }
  }, [team]);

  return (
    <Fragment>
      <Hidden smUp>
        <Box textAlign="center" pt={1}>
          <KeyboardArrowUp
            style={{ color: disableUpChevron() ? bhpColor.grey3 : "" }}
            onClick={() => scrollUp()}
          />
        </Box>
        <Box pt={2}>
          {theBuildingCapacityRange.map((val, idx) => {
            const overplanned =
              val.planned_attendance && val.planned_attendance > val.total_capacity ? ">" : "";
            const overbooked =
              val.booked_attendance && val.booked_attendance > val.total_capacity ? ">" : "";
            const bookedColumnValue = getPercentage(val.booked_attendance, val.total_capacity);
            const plannedColumnWidth = getPercentage(val.planned_attendance, val.total_capacity);
            const bookedColumnWidth = getPercentage(val.booked_attendance, val.total_capacity);

            return (
              <Fragment key={`fr${idx}`}>
                <TableRow className={classes.row}>
                  <TableCell className={classes.td} colSpan={10}>
                    <Box pb={2}>
                      <Paper elevation={0} style={{ color: bhpColor.blueGrey1 }}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box style={{ fontSize: "16px" }}>
                              <span style={{ fontWeight: 700 }}>
                                {t(moment(val.date).format("dddd"))},
                              </span>{" "}
                              {`${t(moment(val.date).format("Do"))} ${t("of")} ${t(
                                moment(val.date).format("MMMM")
                              )}`}
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <TeamScheduleBox
                              t={t}
                              buildingTeamSchedule={buildingTeamSchedule}
                              date={val.date}
                              isMobile={true}
                              team={team}
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Box>
                              <Box
                                className={classes.mobilePlanned}
                                width={`${plannedColumnWidth}%`}
                              >
                                {t("Planned")} {plannedColumnWidth}
                                {overplanned}
                                {t("%")}
                              </Box>
                              {isOffice && (
                                <Box
                                  mt={0.25}
                                  width={`${bookedColumnWidth}%`}
                                  className={classes.mobileBooked}
                                >
                                  {t("Booked")} {bookedColumnValue}
                                  {overbooked}
                                  {t("%")}
                                </Box>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  </TableCell>
                </TableRow>
              </Fragment>
            );
          })}
        </Box>
        <Box textAlign="center" pb={1}>
          <KeyboardArrowDown
            style={{ color: disableDownChevron() ? bhpColor.grey3 : "" }}
            onClick={() => scrollDown()}
          />
        </Box>
      </Hidden>
      {/* Full screen table */}
      <Hidden xsDown>
        <TableRow
          className={classes.row}
          style={{
            borderLeft: `1px solid ${bhpColor.backgroundGrey}`,
            borderRight: `1px solid ${bhpColor.backgroundGrey}`,
          }}
        >
          <TableCell
            colSpan={theBuildingCapacityRange.length + 3}
            style={{ borderBottom: 0, padding: 0 }}
          >
            {" "}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={theBuildingCapacityRange.length + 3}>
            <GraphicCapacityChart
              buildingDetail={building}
              date={moment(focussedDate).toDate()}
              t={undefined}
              is12hr={is12hr}
              chartHeight={chartHeight}
              chartWidth={chartWidth}
              teamMembers={teamMembers}
              team={team}
            />
          </TableCell>
        </TableRow>
      </Hidden>
    </Fragment>
  );
});

export default CapacityTabChart;
