import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { bhpColor, settings } from "./globals";

export const peopleStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
    },
    paper: {
      padding: settings.outerPadding,
      color: bhpColor.blueGrey1,
      "&.booking": {
        minHeight: 798,
      },
    },

    dataGrid: {
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiDataGrid-row.Mui-even": {
        backgroundColor: "#F6F6F6",
      },
      "& .MuiDataGrid-cell": {
        color: "#466371",
      },
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-sortIcon": {
          opacity: `1 !important`,
        },
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        color: "#50524D",
        fontWeight: "bold !important",
        paddingRight: 16,
      },
      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden",
      },
      padding: "0 1% 1% 1%",
    },
    recordsFoundLabel: {
      color: bhpColor.orange1,
      fontFamily: "Arial",
      fontStyle: "italic",
      padding: "1%",
    },
    searchTextField: {
      backgroundColor: "white",
    },
    pb4: {
      paddingBottom: 16,
    },
    unbold: {
      fontWeight: "normal",
    },
    textFieldSpacing: {
      "& .MuiTextField-root": {
        padding: 8,
      },
    },
    textFieldLabel: {
      paddingLeft: 10,
      fontWeight: "bold",
      color: "#466371",
    },
    disabledBackGround: {
      "& .MuiInputBase-root": {
        backgroundColor: "#F6F6F6",
      },
    },
    heading: {
      color: "#466371",
      fontFamily: "Arial",
      fontStyle: "normal",
    },
    spaceTable: {
      border: 0,
      "& .MuiTablePagination-root": {
        color: bhpColor.blueGrey1,
      },
      "& .MuiDataGrid-renderingZone": {
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-viewport": {
        maxHeight: "none",
      },
      "& .MuiDataGrid-root": {
        border: 0,
      },
      "& .MuiDataGrid-cell": {
        border: 0,
        "&:focus": {
          outline: 0,
        },
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-columnHeader": {
        "&:focus": {
          outline: 0,
        },
        "&:focus-within": {
          outline: 0,
        },
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        paddingLeft: 0,
      },
      "& .MuiDataGrid-columnsContainer": {
        borderBottom: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
        color: bhpColor.blueGrey1,
      },
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-row": {
        maxHeight: "none !important",
        "&:nth-child(odd)": {
          backgroundColor: bhpColor.backgroundGrey,
          "&:hover": {
            backgroundColor: bhpColor.backgroundGrey,
          },
        },
        "&.Mui-selected": {
          "&:hover": {
            backgroundColor: bhpColor.blueGrey2,
            color: bhpColor.blueGrey1,
          },
          backgroundColor: bhpColor.blueGrey1,
          color: bhpColor.white,
        },
        "&:hover": {
          backgroundColor: bhpColor.backgroundGrey,
        },
      },
    },
  })
);
