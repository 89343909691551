import { Avatar, Box, Grid, Tooltip } from "@material-ui/core";
import { Alert, AlertTitle, AvatarGroup } from "@material-ui/lab";
import { teamStyles } from "styles/teamStyles";
import { useHistory } from "react-router-dom";
import { useTeamStore, useUserStore } from "providers/RootStoreProvider";
import {
  TeamParticipant,
  TeamCategoryType,
  TeamVisibilityType,
  TeamMember,
} from "interfaces/Teams";
import { LockTwoTone, VpnLockTwoTone, PublicTwoTone, ContactsTwoTone } from "@material-ui/icons";
import { toJS } from "mobx";
import { bhpColor } from "styles/globals";
import { getTeamCategoryString } from "utils/teams";

interface TeamSummaryProps {
  teamId: number | null;
  teamName?: string | null;
  teamCategory?: TeamCategoryType;
  teamVisibility?: TeamVisibilityType;
  department?: string;
  users?: any;
  owners?: TeamParticipant[];
  member_count?: number;
  membersSample?: TeamParticipant[];
  t: any;
}
export function TeamSummary(props: TeamSummaryProps) {
  const {
    teamName,
    teamCategory,
    teamVisibility,
    department,
    teamId,
    t,
    member_count,
    membersSample,
  } = props;
  const classes = teamStyles();
  const userStore = useUserStore();
  const maxAvatarCount = 5;
  const history = useHistory();
  const teamStore = useTeamStore();
  const fakeArraySize = membersSample && member_count ? member_count - maxAvatarCount : 0;
  const fakeArray = fakeArraySize > 0 ? [...Array(fakeArraySize)] : [];
  const getInitials = (user: TeamParticipant) => {
    const nameToCheck: string = user.preferred_name
      ? user.preferred_name
      : `${user.first_name} ${user.last_name}`;
    return nameToCheck.length > 2
      ? nameToCheck.indexOf(" ") > -1
        ? `${nameToCheck.charAt(0)}${nameToCheck.charAt(nameToCheck.indexOf(" ") + 1)} `
        : nameToCheck.substring(0, 2)
      : "..";
  };

  const drawVisibilityIcon = (visibility?: TeamVisibilityType) => {
    switch (visibility) {
      case TeamVisibilityType.PRIVATE:
        return (
          <Tooltip title="Visibility: Private">
            <LockTwoTone style={{ fontSize: 32, color: bhpColor.blueGrey1 }} />
          </Tooltip>
        );
      case TeamVisibilityType.CLOSED:
        return (
          <Tooltip title="Visibility: Closed">
            <VpnLockTwoTone style={{ fontSize: 32, color: bhpColor.blueGrey1 }} />
          </Tooltip>
        );
      case TeamVisibilityType.VISIBLE:
        return (
          <Tooltip title="Visibility: Visible">
            <PublicTwoTone style={{ fontSize: 32, color: bhpColor.blueGrey1 }} />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Visibility: Visible">
            <PublicTwoTone style={{ fontSize: 32, color: bhpColor.blueGrey1 }} />
          </Tooltip>
        );
    }
  };

  return teamId ? (
    <>
      <Box
        className={classes.summaryTeam}
        style={{ display: "flex", minHeight: "inherit" }}
        onClick={() => {
          const teamDetailsUrl = `/teams/planner/${teamId}`;
          teamStore.setCurrentTeam(teamId);
          history.push(teamDetailsUrl);
        }}
      >
        <Grid container>
          <Grid item xs={8} style={{ display: "grid" }}>
            <div className={classes.summaryTeamTitle}>{teamName}</div>
            <div className={classes.summaryTeamDepartment}>{department || "\u00A0"}</div>
            <div className={classes.summaryTeamCategory}>
              {teamCategory ? getTeamCategoryString(teamCategory) : "Organisational unit"}
            </div>
            <div className={classes.summaryTeamMembersCount}>
              {member_count
                ? `${member_count} ${member_count > 1 ? t("Members") : t("Member")}`
                : ""}
            </div>
          </Grid>
          <Grid item xs={4}>
            <Grid container style={{ height: "100%" }}>
              <Grid item xs={12}>
                <div className={classes.summaryTeamType}>
                  {userStore.me?.profile.team === teamId
                    ? t("Default")
                    : drawVisibilityIcon(teamVisibility)}
                </div>
              </Grid>
              <Grid item xs={12} style={{ alignSelf: "flex-end" }}>
                <div
                  className={
                    classes.summaryTeamAvatars +
                    " " +
                    `${
                      membersSample &&
                      membersSample?.length >= maxAvatarCount &&
                      classes.summaryTeamWithMoreAvatars
                    }`
                  }
                >
                  <AvatarGroup max={maxAvatarCount}>
                    {membersSample &&
                      membersSample.map((user: any, index: number) => {
                        return (
                          <Tooltip title={user.preferred_name} key={index}>
                            <Avatar key={`a${index}`} src={user.picture}>
                              {getInitials(user)}
                            </Avatar>
                          </Tooltip>
                        );
                      })}
                    {fakeArray.map((dodgy, index) => (
                      <Avatar key={`d${index}`}></Avatar>
                    ))}
                  </AvatarGroup>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  ) : (
    <Box padding={1}>
      <Alert style={{ padding: 10 }} severity="error">
        <AlertTitle>
          <strong>{t("Sorry error")}</strong>
        </AlertTitle>
        {t("No team information provided")}
      </Alert>
    </Box>
  );
}
