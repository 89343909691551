import { Box, Button, Paper, createStyles, makeStyles, Theme } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { bhpColor } from "styles/globals";
import { useTranslation } from "react-i18next";
import { EventDates } from "interfaces/Event";

interface DateButtonProps {
  text: string;
  value: string;
  onClickHandler: any;
  selectedDateFilter: EventDates | null;
}

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    blueButton: {
      borderColor: bhpColor.blueGrey2,
      fontSize: 14,
      color: bhpColor.blueGrey2,
      fontWeight: "bold",
      textTransform: "unset",
      whiteSpace: "nowrap",
      margin: theme.spacing(0.5),
    },
    selectedButton: {
      backgroundColor: `${bhpColor.blueGrey1} !important`,
      color: bhpColor.white,
    },
  })
);

const DateButton = observer((props: DateButtonProps) => {
  const { value, text, onClickHandler, selectedDateFilter } = props;
  const classes = innerStyles();
  const { t, i18n } = useTranslation();
  return (
    <Button
      value={value}
      className={`${classes.blueButton} ${
        selectedDateFilter === (EventDates as any)[value] ? classes.selectedButton : ""
      }`}
      variant="outlined"
      onClick={() => onClickHandler(value)}
    >
      {text}
    </Button>
  );
});
export default DateButton;
