import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { bhpColor } from "styles/globals";
import { BuildingType, BuildingWithCapacity } from "interfaces/Building";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { useRootStore } from "providers/RootStoreProvider";
import { BasicBooking, getDateTimeAtStartOfToday, groupByHour } from "utils/hourlyBookings";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: bhpColor.blueGrey1,
      display: "flex",
      "&.padded": {
        padding: "12px",
      },
      borderRadius: "4px",
      backgroundColor: "transparent",
      alignItems: "center",
    },
    officeLocation: {
      textAlign: "start",
      color: bhpColor.grey1,
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        alignItems: "end",
      },
    },
    detailPanel: {
      display: "flex",
      justifyContent: "center",
    },
    fullPanel: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: bhpColor.backgroundGrey,
      borderRadius: "4px",
      marginTop: "12px",
      padding: "8px",
    },
    largeNumber: {
      fontSize: "35px",
      fontWeight: 700,
      color: bhpColor.orange1,
      height: "40px",
      display: "flex",
      alignItems: "center",
    },
    title: {
      fontSize: "14px",
      fontWeight: 700,
      justifyContent: "start",
      textAlign: "start",
      color: bhpColor.grey1,
      paddingTop: "4px",
      paddingBottom: "4px",
      "&.wrap": {
        whiteSpace: "pre-line",
      },
    },
    icon: {
      color: bhpColor.grey1,
    },
  })
);

interface HourlyBookingRangesProps {
  buildingDetail: BuildingWithCapacity | null;
  date: Date;
  t: any;
  is12hr?: boolean;
}

const HourlyBookingRanges = observer((props: HourlyBookingRangesProps) => {
  const { buildingDetail, date, t, is12hr = true } = props;
  const classes = innerStyles();
  const store = useRootStore();

  const range = { red: 89, amber: 69 };

  const getIndicatorColor = (percentage: number) => {
    return percentage > range.red
      ? bhpColor.availabilityRed
      : percentage > range.amber
      ? bhpColor.availabilityAmber
      : percentage === 0
      ? bhpColor.white
      : bhpColor.available;
  };

  const getPercentage = (total: number, amount: number, decimalPlaces: number = 0) => {
    if (total === 0 || amount === 0) return 0;
    const percentage = Math.ceil(100 * (amount / total));
    return percentage;
  };

  const [hourGroupings, setHourGroupings] = useState<{ [hr: string]: BasicBooking[] }>({});
  const [startIndex, setStartIndex] = useState<number>(is12hr ? 7 : 0);
  const [endIndex, setEndIndex] = useState<number>(is12hr ? 19 : 24);

  useEffect(() => {
    setStartIndex(is12hr ? 7 : 0);
    setEndIndex(is12hr ? 19 : 24);
  }, [is12hr]);

  useEffect(() => {
    if (buildingDetail) {
      const startOfToday = getDateTimeAtStartOfToday(date);
      let endOfToday = new Date(startOfToday);
      endOfToday.setDate(endOfToday.getDate() + 1);
      store.bookingStore
        .loadBuildingBookings(
          { start: startOfToday, end: endOfToday },
          buildingDetail.id.toString()
        )
        .then(() => {
          const theBookings = store.bookingStore.building_bookings[buildingDetail.id.toString()];
          if (theBookings) {
            const groupedBookings = groupByHour(date, theBookings);
            setHourGroupings(groupedBookings);
          }
        });
    }
  }, [buildingDetail, startIndex, endIndex, date]);

  const drawCell = (
    index: number,
    value: number,
    bookableDesks: number | null,
    rowHeight: number = 15
  ) => {
    const height = value * rowHeight;
    const heightString = `${height}px`;
    const label = value > 0 ? value : "--";
    const indicatorColor = getIndicatorColor(
      bookableDesks ? getPercentage(bookableDesks, value) : 0
    );

    const ann = index < 12 ? "am" : "pm";
    const timeValue = index === 0 ? "12" : index > 12 ? index - 12 : index;
    const timeLabel = `${timeValue}${ann}`;

    return (
      <Grid
        container
        style={{
          fontSize: "10px",
          display: "flex",
          maxWidth: "37px",
          border: `1px solid ${bhpColor.blueGrey4}`,
          borderRadius: "4px",
          backgroundColor: "white",
          marginRight: "1px",
          marginLeft: "1px",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: bhpColor.grey3,
            fontWeight: 700,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20px",
          }}
        >
          {timeLabel}
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "8px", paddingBottom: "8px" }}>
          {drawDot(20, indicatorColor)}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            fontWeight: 700,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: bhpColor.orange1,
            height: "20px",
          }}
        >
          {label}
        </Grid>
      </Grid>
    );
  };

  const drawDot = (diameter: number, indicatorColor: string) => {
    return (
      <div
        className="dot"
        style={{ height: `${diameter}px`, width: `${diameter}px`, backgroundColor: indicatorColor }}
      ></div>
    );
  };

  /**
   * Change view range of groupings
   * @param direction 1 right, -1 left
   */
  const setRange = (direction: number) => {
    const currentStart = startIndex;
    let start = startIndex;
    let end = endIndex;
    switch (currentStart) {
      case 0:
        start = direction > 0 ? 7 : 0;
        end = direction > 0 ? 19 : 12;
        break;
      case 7:
        start = direction > 0 ? 11 : 0;
        end = direction > 0 ? 24 : 12;
        break;
      case 11:
        start = direction > 0 ? 11 : 7;
        end = direction > 0 ? 24 : 19;
        break;
    }
    setStartIndex(start);
    setEndIndex(end);
  };

  const drawHourlyGroupings = (
    hourlyGroupings: { [hr: string]: BasicBooking[] },
    buildingDetail: BuildingWithCapacity | null
  ) => {
    return (
      <>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
          <Grid
            item
            xs={1}
            style={{
              maxWidth: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Box onClick={() => setRange(-1)} style={{ cursor: "pointer" }}>
              <ChevronLeftIcon style={{ transform: "scaleX(0.6)", marginLeft: "-5px" }} />
            </Box>
          </Grid>
          <Grid
            item
            xs={10}
            style={{
              display: "flex",
              justifyContent: "center",
              flexGrow: "unset",
              maxWidth: "unset",
              width: "100%",
              overflowX: "auto",
            }}
          >
            <>
              {Object.keys(hourlyGroupings).length > 0 ? (
                Object.keys(hourlyGroupings).map((key, index) => {
                  {
                    return index >= startIndex && index <= endIndex
                      ? drawCell(
                          index,
                          hourlyGroupings[key].length,
                          buildingDetail ? buildingDetail.total_capacity || null : null,
                          15
                        )
                      : "";
                  }
                })
              ) : (
                <CircularProgress color="primary" size={25} />
              )}
            </>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              maxWidth: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Box onClick={() => setRange(1)} style={{ cursor: "pointer", width: "18px" }}>
              <ChevronRightIcon style={{ transform: "scaleX(0.6)" }} />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Grid container className={classes.root}>
      {drawHourlyGroupings(hourGroupings, buildingDetail)}
    </Grid>
  );
});

export default HourlyBookingRanges;
