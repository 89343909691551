import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { bhpColor, settings } from "./globals";

export const SettingStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: theme.spacing(2),
    },
    h4: {
      fontWeight: "bold",
      color: bhpColor.blueGrey1,
      fontSize: 16,
      display: "inline-block",
    },
    formControl: {
      display: "block",
      width: "100%",
      margin: 0,
    },
    team: {
      height: 32,
      width: "calc(100% - 150px)",
      paddingLeft: theme.spacing(1),
      backgroundColor: `${bhpColor.blueGrey4}50`,
      display: "inline-flex",
      verticalAlign: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 80px)",
      },
    },
    pronouns: {
      "& .MuiOutlinedInput-root": {
        height: 32,
      },
      width: "calc(100% - 150px)",
      display: "inline-flex",
      verticalAlign: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 80px)",
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    selectBox: {
      width: "calc(100% - 150px)",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 80px)",
      },

      height: 40,
      "& svg": {
        zIndex: 2,
      },
      "& select": {
        zIndex: 2,
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& fieldset": {
        zIndex: 1,
        height: 40,
        top: -3,
        backgroundColor: "white",
        "& .Mui-focussed": {
          backgroundColor: "white",
        },
      },
    },
    selectInput: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    visibility: {
      maxWidth: 333,
      color: bhpColor.grey1,
      "& .Mui-ToggleButton-root": {
        color: bhpColor.grey1,
        padding: 0,
      },
      "& .MuiToggleButton-root.Mui-selected": {
        color: bhpColor.grey1,
        backgroundColor: bhpColor.blueGrey4,
      },
    },
    toggleButtonGroup: {
      color: bhpColor.grey1,
      padding: 0,
      "& .Mui-selected": {
        backgroundColor: bhpColor.blueGrey4,
      },
      "& .Mui-ToggleButton-root": {
        color: bhpColor.grey1,
        padding: 0,
      },
    },
    toggleButton: {
      fontWeight: "bold",
      fontSize: 16,
      color: bhpColor.grey1,
      textTransform: "unset",
      borderRadius: `${settings.borderRadius}px !important`,
      borderColor: "transparent !important",
      margin: 6,
      width: 158,
      [theme.breakpoints.down("sm")]: {
        width: 90,
      },
      padding: 0,
    },
    toggleButton2: {
      fontWeight: "bold",
      fontSize: 16,
      color: bhpColor.grey1,
      textTransform: "unset",
      borderRadius: `${settings.borderRadius}px !important`,
      borderColor: "transparent !important",
      marginLeft: 0,
      margin: 6,
      width: 140,
      padding: 0,
    },
    profileRoutine: {
      width: "100%",
      display: "flex",
      fontWeight: "bold",
      color: bhpColor.blueGrey1,
      flexDirection: "row",
      backgroundColor: bhpColor.white,
      [theme.breakpoints.down("sm")]: {
        width: settings.mobileWidth,
        flexDirection: "column !important",
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      "& .column": {
        textAlign: "center",
        display: "inline-flex",
        backgroundColor: bhpColor.backgroundGrey,
        "&.weekend": {
          backgroundColor: `${bhpColor.blueGrey4}30`,
        },
        [theme.breakpoints.up("md")]: {
          width: "14.28%",
          display: "block",
          backgroundColor: "transparent",
          marginBottom: 0,
        },
        "& .header": {
          textAlign: "center !important",
          width: "auto",
          paddingTop: 101,
        },
      },
      "& .largeOnly": {
        display: "none !important",
        borderRight: `1px solid ${bhpColor.blueGrey4}`,
        [theme.breakpoints.up("lg")]: {
          display: "block !important",
        },
      },
    },
    saveFailed: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.purple1,
        fontWeight: "bold",
        backgroundColor: bhpColor.purple4,
        border: `2px solid ${bhpColor.purple1}`,
        borderRadius: settings.borderRadius,
      },
    },
    saveSuccess: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.orange1,
        fontWeight: "bold",
        backgroundColor: bhpColor.orange4,
        border: `2px solid ${bhpColor.orange1}`,
        borderRadius: settings.borderRadius,
      },
    },
    inlineLink: {
      marginLeft: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      color: bhpColor.blueGrey1,
      fontWeight: "bold",
      cursor: "pointer",
    },
    routineTable: {
      fontWeight: `bold`,
      "& .MuiTableCell-root": {
        fontWeight: "bold",
        color: bhpColor.blueGrey1,
        fontSize: settings.fontSize,
        textAlign: "center",
        padding: 0,
      },
      "& .titleCell": {
        borderRight: `1px solid ${bhpColor.blueGrey4}`,
        whiteSpace: "nowrap",
      },
    },
    routineTitle: {
      height: settings.tableHeaderHeight,
      backgroundColor: bhpColor.backgroundGrey,
      "&.weekend": {
        backgroundColor: `${bhpColor.blueGrey4}70`,
      },
    },
    routineBody: {
      "&.weekend": {
        backgroundColor: `${bhpColor.blueGrey4}70`,
        "& .MuiFormControl-root": {
          [theme.breakpoints.up("md")]: {
            backgroundColor: `${bhpColor.white} !important`,
          },
        },
      },
    },
    routineBottomRow: {
      "& .MuiTableCell-root": {
        borderBottom: 0,
      },
    },

    smallTable: {
      backgroundColor: `${bhpColor.backgroundGrey}`,
      borderBottom: `4px solid ${bhpColor.white}`,
      "&:last-child": {
        borderBottomWidth: 0,
      },
      "&.weekend": {
        backgroundColor: `${bhpColor.blueGrey4}70`,
      },
      "& .MuiTableCell-root": {
        padding: 0,
        borderBottom: 0,
        textAlign: "center",
      },
      "& .MuiToggleButtonGroup-root": {
        width: "100%",
      },
    },
    tableContainer: {
      overflowX: "unset",
    },
    titleColumn: {
      width: "20%",
    },
    buttonProgress: {
      color: bhpColor.orange4,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);
