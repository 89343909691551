import { Period } from "utils/hourlyBookings";
import { BuildingWithCapacity, Space, Building } from "./Building";
import { Team } from "./Teams";

export enum ScheduleType {
  OFFICE = 0,
  REMOTE = 1,
  OFF = 2,
}

export interface SettingsScheduleType {
  status: number | null;
  start_time: string | null;
  finish_time: string | null;
  desk_start_time: string | null;
  desk_finish_time: string | null;
  office: number | null;
}

// export interface Building {
//   city: string;
//   building_name: string;
// }

// export interface Space {
//   name: string;
//   building?: Building;
//   space_type: {
//     id: number;
//     name: string;
//   };
//   // todo: add the other fields as we need them
// }

export const defaults = {
  desk_start_time: "09:00:00",
  desk_finish_time: "17:00:00",
};

export interface Schedule {
  date: string;
  status: ScheduleType;
  space: Space | null;
  office: Building | null;

  // adding this for the UI
  updating?: boolean;
  start_date?: string;
  start_time?: string;
  finish_date?: string;
  finish_time?: string;
  desk_start_time?: string;
  desk_finish_time?: string;
  deskHours?: { start: Date; end: Date };
  booking_id?: number;
  desk_required?: number;
}

export interface MultipleBooking {
  id: number;
  booking_period: Period;
  space: Partial<Space> | null;
  booking_details?: {
    start_date_local: string;
    start_time_local: string;
    end_date_local: string;
    end_time_local: string;
  };
  building?: Partial<Building> | null;
}

export interface UserForTeamSchedule {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  preferred_name: string;
  status: ScheduleType;
  space_reference: string | null;
  space_serraview_id?: number | null;
  default_team_id: number | null;
  default_team_name: string | null;
  default_team_role: string | null;
  desk_start_time: string | null;
  desk_finish_time: string | null;
  multiple_bookings?: MultipleBooking[] | null;
  upcoming_booking?: MultipleBooking;
}

export interface BuildingTeamSchedule {
  date: string;
  building: BuildingWithCapacity;
  team: Team;
  total_users_planned: number;
  total_users_booked: number;
  users: UserForTeamSchedule[];
}
