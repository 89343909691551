import moment from "moment";
import Icon from "@mdi/react";
import SearchIcon from "@material-ui/icons/Search";
import {
  Box,
  Grid,
  TextField,
  Paper,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Tooltip,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Fragment, useCallback, useEffect, useState } from "react";
import { debounce, isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import {
  GridColDef,
  DataGrid,
  GridOverlay,
  GridCellParams,
  GridColumnMenuContainer,
  GridFilterMenuItem,
  GridSortModel,
  GridRowsProp,
  GridSortDirection,
} from "@material-ui/data-grid";
import { useHistory } from "react-router-dom";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";

import Topbar from "./Topbar";
import { peopleStyles } from "styles/peopleStyles";
import {
  usePeopleStore,
  useBuildingStore,
  useTeamStore,
  useScheduleStore,
  useUserStore,
} from "providers/RootStoreProvider";
import WorkdaySummary from "components/dashboard/officeCapacity/WorkdaySummary";
import UserScheduleWidget from "components/utils/UserScheduleWidget";
import LoadingZone from "components/schedule/LoadingZone";
import { User } from "interfaces/User";
import { useTranslation } from "react-i18next";
import { Team } from "interfaces/Teams";
import StarBorderTwoToneIcon from "@material-ui/icons/StarBorderTwoTone";
import StarTwoToneIcon from "@material-ui/icons/StarTwoTone";
import FavouritePeople from "components/people/FavouritePeople";
interface PeopleProps {
  officeId?: number;
  match?: any;
}

const People = observer((props: PeopleProps) => {
  //#region Stores Declaration
  const peopleStore = usePeopleStore();
  const buildingStore = useBuildingStore();
  const teamStore = useTeamStore();
  const scheduleStore = useScheduleStore();
  const userStore = useUserStore();
  const { t, i18n } = useTranslation();
  //#endregion Stores Declaration

  //#region Constants Declaration

  const history = useHistory();
  const peopleId = props.match.params.peopleId || null;
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const classes = peopleStyles();
  const topBarTitle = selectedUser
    ? `${
        selectedUser!.profile?.preferred_name ||
        selectedUser!.first_name + " " + selectedUser!.last_name
      } ${selectedUser!.profile?.pronouns ? "(" + selectedUser!.profile?.pronouns + ")" : ""}`
    : "People";

  const topBarSubTitle = `${
    selectedUser
      ? `${selectedUser!.profile?.employee_position ? selectedUser!.profile.employee_position : ""}`
      : t("Search for anyone across BHP")
  }`;
  const topBarBreadcrumb = `${t("People")} ${
    selectedUser
      ? selectedUser!.profile?.preferred_name
        ? ` > ${selectedUser!.profile.preferred_name}`
        : ` > ${selectedUser!.first_name} ${selectedUser!.last_name}`
      : ""
  }`;

  const getInitials = (user: User) => {
    const nameToCheck: string = user.preferred_name
      ? user.preferred_name
      : `${user.first_name} ${user.last_name}`;
    return nameToCheck.length > 2
      ? nameToCheck.indexOf(" ") > -1
        ? `${nameToCheck.charAt(0)}${nameToCheck.charAt(nameToCheck.indexOf(" ") + 1)} `
        : nameToCheck.substring(0, 2)
      : "..";
  };

  const todayDate = new Date();
  const displayDate = {
    date: `${t(moment(todayDate).format("Do"))} ${t("of")} ${t(moment(todayDate).format("MMMM"))}`,
    day: t(moment(todayDate).format("dddd")),
  };
  const theDate = moment(todayDate).format("YYYY-MM-DD");
  const todayScheduleForSelectedUser = scheduleStore.userScheduleForDay(theDate);
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const gridRowHeight = smAndUp ? 52 : 100;
  const gridPageSize = 20;
  const [filterModel, setFilterModel] = useState({
    items: [{}],
  });
  const [teamsDropdownOptions, setTeamsDropdownOptions] = useState<Team[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [userIdEdited, setUserIdEdited] = useState<number | null>(null);

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };
  //#endregion Constants Declaration

  //#region Hooks
  useEffect(() => {
    peopleStore.getFilteredUsers(gridPageSize);
    buildingStore.loadBuildings();
    // teamStore.loadTeams();
  }, [
    peopleStore,
    peopleStore.searchFilter,
    peopleStore.teamFilter,
    peopleStore.locationFilter,
    buildingStore,
    teamStore,
    userStore.me,
  ]);

  useEffect(() => {
    (async () => {
      if (peopleId) {
        setSelectedUser(await peopleStore.getUserById(peopleId));
        await scheduleStore.loadSchedulesForSelectedUser(peopleId, todayDate);
      }
    })();
  }, [scheduleStore, peopleId]);

  useEffect(() => {
    if (sortModel && sortModel.length > 0) {
      peopleStore.getFilteredUsers(
        gridPageSize,
        getSortString(sortModel[0].field, sortModel[0].sort)
      );
    } else {
      peopleStore.getFilteredUsers(gridPageSize);
    }
  }, [sortModel]); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Hooks

  //#region Methods

  const getSortString = (fieldName: string, sort: GridSortDirection) => {
    switch (fieldName) {
      case "fullName":
        return sort === "asc" ? "first_name,last_name" : "-first_name,-last_name";
      case "roleTitle":
        return sort === "asc" ? "profile__employee_position" : "-profile__employee_position";
      case "team":
        return sort === "asc" ? "profile__team__name" : "-profile__team__name";
      case "default_office":
        return sort === "asc"
          ? "profile__default_office__city,profile__default_office__building_name"
          : "-profile__default_office__city,-profile__default_office__building_name";
      case "safetyOfficerRole":
        return sort === "asc"
          ? "-profile__is_fire_warden,-profile__is_first_aid_officer"
          : "profile__is_fire_warden,profile__is_first_aid_officer";
      default:
        break;
    }
  };
  const onNameClick = (peopleId: any): void => {
    const newUrl = `/people/${peopleId}`;
    history.push(newUrl);
  };

  const onLocationClick = (locationId: any): void => {
    const locationUrl = `/offices/${locationId}`;
    history.push(locationUrl);
  };

  function CustomNoRowsOverlay() {
    const classes = peopleStyles();
    return <GridOverlay className={classes.root}>{t("No records found.")}</GridOverlay>;
  }

  const handleTeamFilter = (value: string) => {
    (async () => {
      if (value && value.length > 2) {
        const res = await teamStore.getTeamsByName(value);
        setTeamsDropdownOptions(res);
      } else {
        setTeamsDropdownOptions([]);
      }
    })();
  };

  const teamFilterHandler = useCallback(
    debounce((value: string) => handleTeamFilter(value), 1000),
    []
  );

  //#endregion Methods

  const CustomDownArrow = () => <Icon path={mdiChevronDown} size={1} />;

  const CustomUpArrow = () => <Icon path={mdiChevronUp} size={1} />;
  const sortAlphaNumeric = (a: any, b: any) => {
    // convert to strings and force lowercase
    a = typeof a === "string" ? a.toLowerCase() : a.toString();
    b = typeof b === "string" ? b.toLowerCase() : b.toString();
    return a.localeCompare(b, "en", { numeric: true });
  };

  const isUserFavourite = (userId: number) => {
    return userStore.me?.favourite_users && userStore.me?.favourite_users.includes(userId);
  };

  const addUserToFavorites = async (userId: number) => {
    if (userId) {
      setUserIdEdited(userId);
      await userStore.addFavouriteUser(userId);
    }
    setUserIdEdited(null);
  };

  const removeUserFromFavorites = async (userId: number) => {
    if (userId) {
      setUserIdEdited(userId);
      await userStore.removeFavouriteUser(userId);
    }
    setUserIdEdited(null);
  };

  const gridColumns: GridColDef[] = [
    {
      field: "fullName",
      headerName: t("Name"),
      flex: 1,
      sortable: true,
      valueGetter: (params) => `${params?.row?.first_name} ${params?.row?.last_name}`,
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Box
              style={{
                display: "inline-flex",
                visibility: params.row.id === userStore.me?.id ? "hidden" : "visible",
              }}
              paddingRight={2}
            >
              {userStore.isLoadingFavouriteUsers && params.row.id === userIdEdited ? (
                <CircularProgress size={14} color="secondary" />
              ) : isUserFavourite(params.row.id) ? (
                <Tooltip
                  title={`${t("Click to remove")} ${params.row.first_name} ${
                    params.row.last_name
                  } ${t("from favourites")}`}
                >
                  <StarTwoToneIcon
                    color="primary"
                    style={{ cursor: "pointer", display: "flex", alignSelf: "center" }}
                    onClick={() => removeUserFromFavorites(params.row.id)}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={`${t("Click to make")} ${params.row.first_name} ${
                    params.row.last_name
                  } ${t("as favourite")}`}
                >
                  <StarBorderTwoToneIcon
                    style={{ cursor: "pointer", alignSelf: "center" }}
                    onClick={() => addUserToFavorites(params.row.id)}
                  />
                </Tooltip>
              )}
            </Box>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                return onNameClick(params.row.id);
              }}
              underline="always"
              color="inherit"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {params.row.preferred_name
                ? params.row.preferred_name
                : `${params.row.first_name} ${params.row.last_name}`}
            </Link>
          </>
        );
      },
    },
    {
      field: "roleTitle",
      headerName: t("Role Title"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => params?.row?.profile?.employee_position,
      renderCell: (params: GridCellParams) => {
        return (
          <div style={{ whiteSpace: "pre-wrap" }}>{params?.row?.profile?.employee_position}</div>
        );
      },
    },
    {
      field: "team",
      headerName: t("Default Team"),
      sortable: true,
      flex: 1,
      // remove some items from the filter menu
      valueGetter: (params) => params?.row?.profile?.team_name,
      renderCell: (params: GridCellParams) => {
        return <div style={{ whiteSpace: "pre-wrap" }}>{params?.row?.profile?.team_name}</div>;
      },
    },
    {
      field: "default_office",
      headerName: t("Location"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => {
        return params.row && params.row.profile && params.row.profile.default_city
          ? `${params.row.profile.default_city}, ${params.row.profile.default_office_name}`
          : "";
      },
      sortComparator: (v1, v2) => {
        return sortAlphaNumeric(v1, v2);
      },
      renderCell: (params: GridCellParams) => {
        return (
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              return onLocationClick(params?.row?.profile?.default_office);
            }}
            underline="always"
            color="inherit"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {[
              params.row?.profile?.default_city || "",
              params?.row?.profile?.default_office_name || "",
            ]
              .toString()
              .replace(/^,|,$/g, "")}
          </Link>
        );
      },
    },
    {
      field: "safetyOfficerRole",
      headerName: t("Safety Officer Role"),
      sortable: true,
      flex: 1,
      valueGetter: (params) => {
        return [
          params.row.profile?.is_fire_warden ? t("Fire Warden") : null,
          params.row.profile?.is_first_aid_officer ? t("First Aid Officer") : null,
        ]
          .filter((x) => x !== null)
          .join(", ");
      },
      renderCell: (params: GridCellParams) => {
        return (
          <div style={{ whiteSpace: "pre-wrap" }}>
            {[
              params.row.profile?.is_fire_warden ? t("Fire Warden") : null,
              params.row.profile?.is_first_aid_officer ? t("First Aid Officer") : null,
            ]
              .filter((x) => x !== null)
              .join(", ")}
          </div>
        );
      },
    },
  ];
  const gridColumnsMobile: GridColDef[] = [
    {
      field: "people_detail",
      headerName: t(" "),
      filterable: false,
      sortable: false,
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Box px={1} py={2}>
            <Grid container spacing={1} style={{ whiteSpace: "pre-wrap" }}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 700 }}>
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      return onNameClick(params.row.id);
                    }}
                    underline="always"
                    color="inherit"
                  >
                    {params.row.preferred_name
                      ? params.row.preferred_name
                      : `${params.row.first_name || ""} ${params.row.last_name || ""}`}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography display={"block"}>
                  {params?.row?.profile?.employee_position
                    ? params?.row?.profile?.employee_position
                    : " "}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "smaller" }}>
                  {params?.row?.profile?.team_name || ""}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "smaller" }}>
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      return onLocationClick(params?.row?.profile?.default_office);
                    }}
                    underline="always"
                    color="inherit"
                  >
                    {[
                      params.row?.profile?.default_city || "",
                      params?.row?.profile?.default_office_name || "",
                    ]
                      .toString()
                      .replace(/^,|,$/g, "")}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        );
      },
    },
  ];

  const CustomColumnMenu = (props: any) => {
    const { hideMenu, currentColumn } = props;
    return (
      <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} open={false}>
        <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
      </GridColumnMenuContainer>
    );
  };

  const handlePageChange = (thisPage: number) => {
    const currentListSize = peopleStore.users.length;
    const startOfThisPage = gridPageSize * thisPage;
    if (startOfThisPage >= currentListSize) peopleStore.getNextUsers();
    return undefined;
  };

  const drawAddFavouriteButton = (userId: number) => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: smAndUp ? -70 : -50,
          marginRight: smAndUp ? theme.spacing(4) : 15,
          marginBottom: smAndUp ? 32 : 4,
        }}
      >
        <Tooltip
          title={`${t("Click to make")} ${selectedUser!.first_name} ${selectedUser!.last_name} ${t(
            "as favourite"
          )}`}
        >
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={() => addUserToFavorites(userId)}
          >
            {t("Add to Favourites")}
          </Button>
        </Tooltip>
      </Box>
    );
  };

  const drawRemovefavouriteButton = (userId: number) => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: smAndUp ? -70 : -50,
          marginRight: smAndUp ? theme.spacing(4) : 15,
          marginBottom: smAndUp ? 32 : 4,
        }}
      >
        <Tooltip
          title={`${t("Click to make")} ${selectedUser!.first_name} ${selectedUser!.last_name} ${t(
            "as favourite"
          )}`}
        >
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={() => removeUserFromFavorites(userId)}
          >
            {t("Remove from Favourites")}
          </Button>
        </Tooltip>
      </Box>
    );
  };
  return (
    <Fragment>
      <Topbar
        title={t(topBarTitle)}
        subtitle={t(topBarSubTitle)}
        breadcrumb={t(topBarBreadcrumb)}
        showAvatar={selectedUser ? true : false}
        avatarText={selectedUser ? getInitials(selectedUser) : ""}
        avatarSrc={selectedUser?.profile.picture || ""}
      />
      <Box>
        {selectedUser?.id && selectedUser?.id !== userStore.me?.id
          ? isUserFavourite(selectedUser?.id)
            ? drawRemovefavouriteButton(selectedUser?.id)
            : drawAddFavouriteButton(selectedUser?.id)
          : ""}
      </Box>
      {peopleId ? (
        <>
          <LoadingZone isLoading={scheduleStore.isLoading} />
          <Box className={classes.root} py={{ xs: 2, md: 4 }} px={{ xs: 2, md: 4 }}>
            <Grid container>
              <Grid item xs={12} sm={9} lg={6}>
                <Box my={2}>
                  <Typography variant="h3">
                    {displayDate.day}, <span className={classes.unbold}>{displayDate.date}</span>
                  </Typography>
                </Box>
                <div style={{ width: "70%" }}>
                  {
                    <WorkdaySummary
                      t={t}
                      buildingDetail={
                        todayScheduleForSelectedUser?.office
                          ? buildingStore.getBuilding(todayScheduleForSelectedUser?.office.id)
                          : userStore.defaultOffice
                          ? buildingStore.getBuilding(userStore.defaultOffice.id)
                          : null
                      }
                      userSchedule={todayScheduleForSelectedUser}
                      showOfficeCapacityWidget={false}
                      privateSchedule={selectedUser?.profile.private_schedule}
                      inCompleteSettings={!userStore.isFullySetup}
                      userId={peopleId}
                    />
                  }
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={9} lg={6}>
                <Box pt={3}>
                  <h2 className={classes.heading}>{t("Contact Information")}</h2>
                  <Paper className={classes.paper + " " + classes.textFieldSpacing} elevation={0}>
                    <div className={classes.textFieldLabel}>{t("Team")}</div>
                    <TextField
                      disabled
                      id="teamDetail"
                      value={
                        selectedUser?.profile?.team_name
                          ? selectedUser.profile.team_name
                          : t("Not available")
                      }
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={classes.disabledBackGround}
                    />
                    <div className={classes.textFieldLabel}>{t("Manager")}</div>

                    <TextField
                      disabled
                      id="managerDetail"
                      value={
                        selectedUser?.profile?.manager_name
                          ? selectedUser.profile.manager_name
                          : t("Not available")
                      }
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={classes.disabledBackGround}
                    />

                    <div className={classes.textFieldLabel}>{t("Email")}</div>

                    <TextField
                      disabled
                      id="emailDetail"
                      value={selectedUser?.email ? selectedUser.email : t("Not available")}
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={classes.disabledBackGround}
                    />

                    <div className={classes.textFieldLabel}>{t("Phone")}</div>

                    <TextField
                      disabled
                      id="outlined-disabled"
                      value={
                        selectedUser?.profile?.employee_mobile_number
                          ? selectedUser.profile.employee_mobile_number
                          : t("Not available")
                      }
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={classes.disabledBackGround}
                    />

                    <div className={classes.textFieldLabel}>{t("Location")}</div>

                    <TextField
                      disabled
                      id="outlined-disabled"
                      value={
                        selectedUser
                          ? selectedUser?.profile
                            ? buildingStore.buildings.find(
                                (b) => b.id === selectedUser?.profile.default_office
                              )?.building_name
                            : t("Not available")
                          : t("Not available")
                      }
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={classes.disabledBackGround}
                    />

                    {(selectedUser?.profile?.is_fire_warden ||
                      selectedUser?.profile?.is_first_aid_officer) && (
                      <>
                        <div className={classes.textFieldLabel}>{t("Safety Officer Role")}</div>

                        <TextField
                          disabled
                          id="outlined-disabled"
                          value={[
                            selectedUser?.profile?.is_fire_warden ? t("Fire Warden") : null,
                            selectedUser?.profile?.is_first_aid_officer
                              ? t("First Aid Officer")
                              : null,
                          ]
                            .filter((x) => x !== null)
                            .join(", ")}
                          variant="outlined"
                          fullWidth
                          size="small"
                          className={classes.disabledBackGround}
                        />
                      </>
                    )}
                  </Paper>
                </Box>
              </Grid>
              <Grid item sm={12} lg={6}>
                <Box pt={3}>
                  <h2 className={classes.heading}>
                    {/* {selectedUser && selectedUser.profile && selectedUser.profile.preferred_name
                      ? selectedUser.profile?.preferred_name
                      : selectedUser?.first_name
                      ? selectedUser.first_name + "'s"
                      : ""}{" "} */}
                    {t("Upcoming Planner")}
                  </h2>
                  <UserScheduleWidget t={t} startDate={theDate} selectedUser={selectedUser} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <FavouritePeople />
          <Box className={classes.root} pt={smAndUp ? 2 : 1} px={{ xs: 2, md: 4 }}>
            <h2 style={{ color: "#5C879C", lineHeight: 0 }}>{t("Search")}</h2>
          </Box>
          <Box className={classes.root} px={{ xs: 2, md: 4 }}>
            <Grid container spacing={smAndUp ? 4 : 2} style={{ paddingTop: 0 }}>
              <Grid item sm={6} md={4}>
                <TextField
                  id="name"
                  label={t("Name")}
                  placeholder={t("Type a first or last name to search")}
                  type="text"
                  size="small"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    endAdornment: <SearchIcon />,
                  }}
                  onChange={debounce((event) => {
                    peopleStore.setSearchFilter(event.target.value);
                  }, 1000)}
                  className={classes.searchTextField}
                />
              </Grid>
              {smAndUp && (
                <Grid item sm={6} md={4}>
                  <Autocomplete
                    id="teamAutoComplete"
                    options={[...teamsDropdownOptions]}
                    getOptionLabel={(team) => team?.name}
                    fullWidth
                    autoComplete
                    defaultValue={undefined}
                    clearOnBlur={false}
                    onChange={(event, value) => {
                      if (!value) {
                        setTeamsDropdownOptions([]);
                      }
                      peopleStore.setTeamFilter(value);
                    }}
                    className={classes.searchTextField}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderOption={(option, { selected }) => (
                      <>
                        {option.department ? `${option.department} -> ${option.name}` : option.name}
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="team"
                        label={t("Team")}
                        placeholder={`${t("Start typing to search Team")}...`}
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={(event) => {
                          teamFilterHandler(event.target.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {smAndUp && (
                <Grid item sm={12} md={4}>
                  <Autocomplete
                    id="locationAutoComplete"
                    options={[...buildingStore.buildings]}
                    getOptionLabel={(location) => `${location?.city}, ${location?.building_name}`}
                    fullWidth
                    autoComplete
                    defaultValue={null}
                    onChange={(event, value) => {
                      peopleStore.setLocationFilter(value);
                    }}
                    className={classes.searchTextField}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="location"
                        label={t("Location")}
                        placeholder={`${t("Start typing to search Location")}...`}
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box className={classes.root} py={smAndUp ? 4 : 2} px={{ xs: 2, md: 4 }}>
            <Paper className={classes.paper} elevation={0}>
              {peopleStore.userCount && (
                <div className={classes.recordsFoundLabel}>
                  {peopleStore.userCount}{" "}
                  {peopleStore.userCount > 1 ? t("results found") : t("result found")}
                </div>
              )}
              {peopleStore.searchFilter !== "" ||
              peopleStore.locationFilter ||
              peopleStore.teamFilter ? (
                <Grid className={classes.dataGrid} container>
                  <Grid item xs>
                    <div
                      className={classes.spaceTable}
                      style={{
                        height:
                          peopleStore.users.length > 0
                            ? Math.min(peopleStore.users.length + 1, gridPageSize) * gridRowHeight +
                              100
                            : 200,
                        width: "100%",
                      }}
                    >
                      <DataGrid
                        rows={[...peopleStore.users]}
                        rowCount={peopleStore.userCount || undefined}
                        rowHeight={gridRowHeight}
                        columns={smAndUp ? gridColumns : gridColumnsMobile}
                        pageSize={gridPageSize}
                        rowsPerPageOptions={[gridPageSize]}
                        loading={peopleStore.isLoading}
                        components={{
                          NoRowsOverlay: CustomNoRowsOverlay,
                          ColumnSortedAscendingIcon: ExpandMore,
                          ColumnSortedDescendingIcon: ExpandLess,
                          ColumnUnsortedIcon: UnfoldMoreIcon,
                          ColumnMenu: CustomColumnMenu,
                        }}
                        disableSelectionOnClick
                        hideFooterRowCount
                        filterModel={filterModel}
                        onFilterModelChange={(model) => setFilterModel(model)}
                        onPageChange={(params) => handlePageChange(params)}
                        headerHeight={smAndUp ? 56 : 4}
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={(model) => {
                          if (!isEqual(model, sortModel)) handleSortModelChange(model);
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <div style={{ textAlign: "center" }}>{t("Enter search term to see results")}</div>
              )}
            </Paper>
          </Box>
        </>
      )}
    </Fragment>
  );
});

export default People;
