import { useEffect, useState } from "react";
import Topbar from "./Topbar";
import {
  FormControl,
  Button,
  Paper,
  Link,
  Box,
  Typography,
  Grid,
  Checkbox,
  TextField,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { bhpColor, displaySettings } from "../../styles/globals";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { SettingStyles } from "../../styles/SettingStyles";
import { PopupInformationWidget } from "../../components/settings/PopupInformationWidget";
import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { SettingsSelectBox } from "components/settings/SettingsSelectBox";
import { wdpStrings } from "config/strings";
import { SettingsWeeklyRoutine } from "components/settings/SettingsWeeklyRoutine";
import { useRootStore, useUserStore } from "providers/RootStoreProvider";
import { observer } from "mobx-react-lite";
import { SettingsWdpType, wdpConstants, wdPDefaults } from "interfaces/SettingsWdp";
import { api } from "utils/bridge";
import LoadingZone from "components/schedule/LoadingZone";
import moment from "moment";
import InformationIcon from "components/utils/InformationIcon";
import { useTranslation } from "react-i18next";
import config from "config";
import { ScheduleType, SettingsScheduleType } from "interfaces/Schedule";
import { CheckBoxOutlined } from "@material-ui/icons";
import SettingsCustomRouterPrompt from "components/route/SettingsCustomRouterPrompt";
import { DEFAULT_END_TIME, DEFAULT_START_TIME } from "utils/hourlyBookings";

interface MySettings {
  user: number | null;
  team: number | null;
  default_office: number | null;
  remote_timezone: string | null;
  private_schedule: boolean | null;
  default_week: {
    mon: number | null;
    tue: number | null;
    wed: number | null;
    thu: number | null;
    fri: number | null;
    sat: number | null;
    sun: number | null;
    mon_default: SettingsScheduleType | null;
    tue_default: SettingsScheduleType | null;
    wed_default: SettingsScheduleType | null;
    thu_default: SettingsScheduleType | null;
    fri_default: SettingsScheduleType | null;
    sat_default: SettingsScheduleType | null;
    sun_default: SettingsScheduleType | null;
  };
  hide_mobile: boolean | null;
  pronouns: string | null;
}

interface DialogData {
  title: string | null;
  content: string | null;
  primaryButtonText: string | null;
  secondaryButtonText?: string | null;
}

export const validateSettings = (theSettings: MySettings | null) => {
  if (theSettings === null) return false;
  let formValid = true;
  // Excluded team requirement for the moment
  // if (theSettings.team === null) formValid = false;
  if (theSettings.default_office === null || theSettings.default_office < -2) formValid = false;
  if (theSettings.remote_timezone === null || theSettings.remote_timezone === "") formValid = false;
  if (theSettings.private_schedule === null) formValid = false;
  if (theSettings.default_week.mon === null) formValid = false;
  if (theSettings.default_week.tue === null) formValid = false;
  if (theSettings.default_week.wed === null) formValid = false;
  if (theSettings.default_week.thu === null) formValid = false;
  if (theSettings.default_week.fri === null) formValid = false;
  // if (theSettings.default_week.sat === null) formValid = false;
  // if (theSettings.default_week.sun === null) formValid = false;
  return formValid;
};

export const Settings = observer(() => {
  const store = useRootStore();
  const userStore = useUserStore();
  const { t, i18n } = useTranslation();

  const classes = SettingStyles();

  /// region messaging ///
  const [firstTimeSave, setFirstTimeSave] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [saveState, setSaveState] = useState(true);
  const [showMyPhoneNumber, setShowMyPhoneNumber] = useState<boolean>(
    !userStore.me?.profile.hide_mobile
  );
  const [settingsChanged, setSettingsChanged] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState(t("savedSettingsSuccess"));
  const snackbarOpen = () => setSnackOpen(true);
  const snackbarClose = () => setSnackOpen(false);
  const setSnackBox = (success: boolean) => {
    setSnackMessage(success ? t("savedSettingsSuccess") : t("savedSettingsFailed"));
    setSaveState(success);
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const handleConfirmDialogPrimaryClick = (e: any) => {
    // do what is needed from a left button click
    setPrivacy(true);
    setConfirmDialogOpen(false);
  };
  const handleConfirmDialogSecondaryClick = (e: any) => {
    // do what is needed from a right button click
    setConfirmDialogOpen(false);
  };

  const [informationModal, setInformationModal] = useState<DialogData | null>();
  const [informationOpen, setInformationOpen] = useState(false);
  const handleInformationClose = () => setInformationOpen(false);
  const handleInformationOpen = (infoId: number) => {
    openInformationModal(api.getModalProps(t, infoId));
  };
  const openInformationModal = (informationData: DialogData) => {
    setInformationModal(Object.assign({}, informationData));
    setInformationOpen(true);
  };

  const [privacyStatementOpen, setPrivacyStatementOpen] = useState(false);
  const handlePrivacyStatementClose = () => setPrivacyStatementOpen(false);
  const openDataPrivacy = () => setPrivacyStatementOpen(true);
  /// end messaging

  const [myId, setMyId] = useState<number>(-1);
  let userSettings: MySettings = {
    user: null,
    team: null,
    default_office: null,
    private_schedule: null,
    remote_timezone: null,
    default_week: {
      mon: null,
      tue: null,
      wed: null,
      thu: null,
      fri: null,
      sat: null,
      sun: null,
      mon_default: null,
      tue_default: null,
      wed_default: null,
      thu_default: null,
      fri_default: null,
      sat_default: null,
      sun_default: null,
    },
    hide_mobile: null,
    pronouns: null,
  };

  const [team, setTeam] = useState<number | null>(null);
  const teamChangeLink = wdPDefaults.teamChangeUrl;
  const [location, setLocation] = useState<number | null>(
    userStore.me?.profile.default_office || null
  );
  const [site, setSite] = useState<number | null>(userStore.me?.profile.default_office || null);
  const [pronouns, setPronouns] = useState<string>(userStore.me?.profile.pronouns || "");
  const handleSitechange = (newSite: string) => {
    setSettingsChanged(true);
    const theSite = parseInt(newSite);
    const chosenBuilding = store.buildingStore.getBasicBuilding(parseInt(newSite));
    if (chosenBuilding && chosenBuilding?.building_timezone) {
      setRemoteTimezone(chosenBuilding.building_timezone);
    }
    const currentRoutine = defaultRoutine.slice().map((dy) => ({
      ...dy,
      office: theSite,
    }));
    setSite(theSite);
    setWeeklyRoutine(currentRoutine);
    setDefaultRoutine(currentRoutine);
    setRefreshTriggerIndex(refreshTriggerIndex + 1);
  };

  const handleLocationChange = (newLocation: string) => {
    const theLocation = parseInt(newLocation);
    if (newLocation == "-2") {
      setShowSite(true);
    } else {
      setShowSite(false);
      setSettingsChanged(true);
      const chosenBuilding = store.buildingStore.getBasicBuilding(parseInt(newLocation));
      if (chosenBuilding && chosenBuilding?.building_timezone) {
        setRemoteTimezone(chosenBuilding.building_timezone);
      }
    }
    setLocation(parseInt(newLocation));
    const currentRoutine = defaultRoutine.map((dy) => ({
      ...dy,
      office: theLocation !== -2 ? theLocation : site || null,
    }));
    setWeeklyRoutine(currentRoutine);
    setDefaultRoutine(currentRoutine);
    setRefreshTriggerIndex(refreshTriggerIndex + 1);
  };

  const [showSite, setShowSite] = useState(false);
  const [remoteTimezone, setRemoteTimezone] = useState<string | null>(null);
  const handleTimezoneChange = (newTimezone: string) => {
    setSettingsChanged(true);
    setRemoteTimezone(newTimezone);
  };

  const [privacy, setPrivacy] = useState<boolean | null>(null);
  const handlePrivacy = (event: any, clickedPrivacy: string) => {
    setSettingsChanged(true);
    const newPrivacy = clickedPrivacy !== null ? clickedPrivacy : privacy;
    const isPrivate = newPrivacy === wdpConstants.privacyPrivate.value;
    if (isPrivate) setConfirmDialogOpen(true);
    else setPrivacy(isPrivate);
  };
  const days: string[] = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  /// weekly routine ///
  const emptyScheduleTypeObject: SettingsScheduleType = {
    status: null,
    start_time: null,
    finish_time: null,
    desk_start_time: null,
    desk_finish_time: null,
    office: null,
  };
  const emptyWeek = Array(7).fill(emptyScheduleTypeObject);
  const [defaultRoutine, setDefaultRoutine] = useState<SettingsScheduleType[]>(emptyWeek);
  const [weeklyRoutine, setWeeklyRoutine] = useState<SettingsScheduleType[]>(emptyWeek);
  /// hack RS: to force update on component. state doesnt see a change in the values of the week array
  const [refreshTriggerIndex, setRefreshTriggerIndex] = useState(0);
  const getWeek = (theRoutine: SettingsScheduleType[]) => {
    let routineObject: any = {};
    days.forEach((day, index) => {
      routineObject[day] = theRoutine[index]?.status;
      routineObject[`${day}_default`] = {
        status:
          index > 4 ? theRoutine[index]?.status || ScheduleType.OFF : theRoutine[index]?.status,
        start_time: theRoutine[index]?.start_time || config.workday.defaultStartWorkingHour,
        finish_time: theRoutine[index]?.finish_time || config.workday.defaultFinishWorkingHour,
        desk_start_time:
          theRoutine[index]?.desk_start_time || config.workday.defaultStartWorkingHour,
        desk_finish_time:
          theRoutine[index]?.desk_finish_time || config.workday.defaultFinishWorkingHour,
        office: theRoutine[index]?.office,
      };
    });
    return routineObject;
  };

  const handleWorkTypeSelection = (newWorktype: number, idx: number) => {
    setSettingsChanged(true);
    let newRoutine = defaultRoutine;
    newRoutine[idx].status = newWorktype;
    setWeeklyRoutine(newRoutine);
    setDefaultRoutine(newRoutine);
    setRefreshTriggerIndex(refreshTriggerIndex + 1);
  };

  const handleHoursLocationChanges = (
    day: string,
    startTime?: string,
    finishTime?: string,
    deskStartTime?: string,
    deskFinishTime?: string,
    office?: number
  ) => {
    setSettingsChanged(true);
    const dayIndex = days.findIndex((d) => d === day);
    let newRoutine = defaultRoutine;
    newRoutine[dayIndex].start_time = startTime || null;
    newRoutine[dayIndex].finish_time = finishTime || null;
    newRoutine[dayIndex].desk_start_time = deskStartTime || DEFAULT_START_TIME;
    newRoutine[dayIndex].desk_finish_time = deskFinishTime || DEFAULT_END_TIME;
    newRoutine[dayIndex].office = office || null;
    //store.buildingStore.buildings.find((building) => building.id === office) || null;

    setWeeklyRoutine(newRoutine);
    setDefaultRoutine(newRoutine);
    setRefreshTriggerIndex(refreshTriggerIndex + 1);
  };
  /// weekly routine ///

  const saveChanges = async () => {
    userSettings = {
      user: myId,
      team: store.teamStore.myTeam ? store.teamStore.myTeam.id : null,
      default_office: location === -2 ? site : location,
      private_schedule: privacy,
      remote_timezone: remoteTimezone,
      default_week: getWeek(weeklyRoutine),
      hide_mobile: !showMyPhoneNumber,
      pronouns: pronouns,
    };
    console.log("From saveChanges", userSettings.default_week);

    const isValid = validateSettings(userSettings);
    if (userSettings !== null && isValid) {
      const res = await store.userStore.changeSettings(userSettings as SettingsWdpType);
      if (res.status === 200) {
        setSettingsChanged(false);
      }
      setSnackBox(res.status === 200);
      snackbarOpen();
    }
  };

  const updateUserSettings = () => {
    userSettings = {
      user: myId,
      team: store.teamStore.myTeam ? store.teamStore.myTeam.id : null,
      default_office: location === -2 ? site : location,
      private_schedule: privacy,
      remote_timezone: remoteTimezone,
      default_week: getWeek(weeklyRoutine),
      hide_mobile: !showMyPhoneNumber,
      pronouns: pronouns,
    };

    const isValid = validateSettings(userSettings);
    setFormValid(isValid);
  };

  const [formValid, setFormValid] = useState<boolean>(false);
  const [seenWelcomeMessage, setSeenWelcomeMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  /// on-mount
  useEffect(() => {
    const theValue = (val: any) => (val ? val : null);
    store.buildingStore.loadBuildings();

    store.utilStore.loadTimezones();
    if (store.userStore.me) {
      setMyId(store.userStore.me.id);
      setRemoteTimezone(theValue(store.userStore.me?.profile.remote_timezone));
      setPrivacy(store.userStore.me?.profile.private_schedule || false);
      const default_office_name: any = userStore.me?.profile.default_office_name;
      if (default_office_name && default_office_name.startsWith("FS-")) {
        setLocation(-2);
        setSite(theValue(store.userStore.me?.profile.default_office));
        setShowSite(true);
      } else {
        setLocation(theValue(store.userStore.me?.profile.default_office));
      }
      const theRoutine = [
        store.userStore.routine[0],
        store.userStore.routine[1],
        store.userStore.routine[2],
        store.userStore.routine[3],
        store.userStore.routine[4],
        store.userStore.routine[5],
        store.userStore.routine[6],
      ];
      setWeeklyRoutine(theRoutine);

      const myTeamId = store.userStore.me!.profile.team;
      if (myTeamId) {
        store.teamStore.setMyTeam(myTeamId);
        setTeam(store.userStore.me.profile.team || null);
      }
      setIsLoading(false);
    }
    return () => {
      //clean up
    };
  }, [store.userStore, store.userStore.me]); // eslint-disable-line react-hooks/exhaustive-deps

  /// on change settings
  useEffect(() => {
    updateUserSettings();
  }, [
    seenWelcomeMessage,
    location,
    site,
    remoteTimezone,
    weeklyRoutine,
    privacy,
    showMyPhoneNumber,
    pronouns,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoading) {
      setWeeklyRoutine(defaultRoutine);
      updateUserSettings();
      setFormValid(validateSettings(userSettings));
    }
  }, [refreshTriggerIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //load finished...
    if (!isLoading) {
      updateUserSettings();
      setDefaultRoutine(weeklyRoutine);
      const isValid = validateSettings(userSettings);
      setFormValid(isValid);
      setFirstTimeSave(!isValid);
      if (!isValid && !seenWelcomeMessage) {
        setSeenWelcomeMessage(true);
        handleInformationOpen(6);
      }
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const subHeadAndInfo = (label: string, infoId: number) => {
    if (infoId == 6) {
      return (
        <Box mb={1} mt={3} style={{ display: "flex" }}>
          <Typography className={classes.h4}>{t(label)}</Typography>
        </Box>
      );
    }
    return (
      <Box mb={1} mt={3} style={{ display: "flex" }}>
        <Typography className={classes.h4}>{t(label)}</Typography>

        <InformationIcon onclick={() => handleInformationOpen(infoId)} color={bhpColor.orange1} />
        {/* <div className={classes.inlineLink} onClick={() => handleInformationOpen(infoId)}>
          <Icon path={mdiInformationOutline} size="1em" />
        </div> */}
      </Box>
    );
  };

  const handlePronounsChange = (value: string) => {
    setPronouns(value);
  };

  return (
    <>
      <SettingsCustomRouterPrompt
        when={settingsChanged}
        title={t("You didn't save your changes")}
        cancelText={t("Cancel")}
        okText={t("Yes")}
        onOK={() => true}
        onCancel={() => false}
      />

      {userStore.isLoading ||
      store.utilStore.isLoading ||
      store.teamStore.isLoading ||
      store.buildingStore.isLoading ? (
        <LoadingZone
          isLoading={
            userStore.isLoading ||
            store.utilStore.isLoading ||
            store.teamStore.isLoading ||
            store.buildingStore.isLoading
          }
        />
      ) : null}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={saveState ? classes.saveSuccess : classes.saveFailed}
        autoHideDuration={displaySettings.snackDelay}
        open={snackOpen}
        onClose={snackbarClose}
        message={snackMessage}
      />
      <Topbar
        title={t("Settings")}
        subtitle={t("Update your settings here")}
        breadcrumb={t("Settings")}
      />
      <Box px={{ xs: 2, sm: 4 }} my={{ xs: 2, sm: 4 }}>
        <Paper elevation={0}>
          <Box px={2} py={4}>
            <Typography variant="h3">{t("Your default settings")}</Typography>
            <p>{t("The following")}</p>
            <Grid container>
              <Grid item md={6} xs={12}>
                {/* Team Section */}
                <div className={classes.wrapper}>
                  {subHeadAndInfo(wdpStrings.page.settings.titleTeam, 1)}
                  <div>
                    <Paper variant="outlined" className={classes.team}>
                      {team
                        ? store.userStore.me?.profile.team_name
                        : store.teamStore.isLoading
                        ? t("Loading...")
                        : t("notFound")}
                    </Paper>
                    <div className={classes.inlineLink} style={{ display: "inline-block" }}>
                      <Link href={teamChangeLink} target="new" color="primary">
                        {t("Change")}
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Display Name */}
                {store.userStore.me?.profile.preferred_name && (
                  <div className={classes.wrapper}>
                    {subHeadAndInfo("Display Name", 7)}
                    <div>
                      <Paper variant="outlined" className={classes.team}>
                        {userStore.me?.profile.preferred_name}
                      </Paper>
                    </div>
                  </div>
                )}
                {/* Pronouns */}
                {store.userStore.me?.profile.preferred_name && (
                  <div className={classes.wrapper}>
                    {subHeadAndInfo("Pronouns", 10)}
                    <div>
                      <TextField
                        key="pronouns"
                        id="pronouns"
                        type="text"
                        size="small"
                        variant="outlined"
                        fullWidth
                        className={classes.pronouns}
                        onChange={(event) => handlePronounsChange(event.target.value)}
                        value={pronouns}
                        inputProps={{ maxLength: 20 }}
                      />
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item sm={12} md={12}>
                <Grid container>
                  <Grid item xs={6}>
                    {/* Location Section */}
                    {subHeadAndInfo(wdpStrings.page.settings.titleLocation, 2)}
                    <SettingsSelectBox
                      t={t}
                      phText={`${t("Choose your default office")}...`}
                      phValue={-1}
                      theItems={store.buildingStore.buildings
                        .slice()
                        .sort((a, b) => (a.city < b.city ? -1 : 1))
                        .filter((building: any) => building.building_type === 0)
                        .concat([
                          {
                            display_name: "Other",
                            id: -2,
                            city: "",
                            building_name: "",
                            building_type: 0,
                          },
                        ])}
                      labelField={"display_name"}
                      valueField={"id"}
                      handleChange={handleLocationChange}
                      defaultValue={location}
                      theId={"location"}
                      theKey={"locationKey"}
                    />
                  </Grid>

                  {/* Site Section */}
                  <Grid item xs={6}>
                    {showSite && (
                      <Grid item sm={12} md={12} style={{ marginLeft: "-110px" }}>
                        {subHeadAndInfo(wdpStrings.page.settings.titleFieldSite, 6)}
                        <SettingsSelectBox
                          t={t}
                          phText={`${t("Choose your default site")}...`}
                          phValue={-1}
                          theItems={store.buildingStore.buildings
                            .slice()
                            .sort((a, b) => (a.city < b.city ? -1 : 1))
                            .filter((building: any) => building.building_type === 1)}
                          labelField={"display_name"}
                          valueField={"id"}
                          handleChange={handleSitechange}
                          defaultValue={site}
                          theId={"site"}
                          theKey={"siteKey"}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Timezone Section */}
              <Grid item sm={6} md={6}>
                <div className={classes.wrapper}>
                  {subHeadAndInfo(t("Timezone"), 3)}
                  <SettingsSelectBox
                    t={t}
                    phText={`${t("Choose your timezone")}...`}
                    phValue={""}
                    valueField={"name"}
                    labelField={"name"}
                    theItems={store.utilStore.timezones
                      .slice()
                      .sort((a, b) => (a.name < b.name ? -1 : 1))}
                    handleChange={handleTimezoneChange}
                    defaultValue={remoteTimezone ? remoteTimezone : moment.tz.guess()}
                    theId={"remoteTimezone"}
                    theKey={"timezoneKey"}
                  />
                </div>
              </Grid>
            </Grid>

            {/* Weekly Routine Section */}
            <div className={classes.wrapper}>
              {subHeadAndInfo(wdpStrings.page.settings.titleWeeklyRoutine, 4)}
            </div>
            <SettingsWeeklyRoutine
              handleHoursLocationChanges={handleHoursLocationChanges}
              handleWorktypeSelection={handleWorkTypeSelection}
              theRoutine={defaultRoutine}
              refreshIndex={refreshTriggerIndex}
              default_office={
                location ? (location == -2 ? site || undefined : location || undefined) : undefined
              }
            />

            {/* Schedule Visibility Section */}
            <div className={classes.wrapper}>
              {subHeadAndInfo(t("Planner Visibility"), 5)}
              <Paper variant="outlined" className={classes.visibility}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <ToggleButtonGroup
                    exclusive
                    className={classes.toggleButtonGroup}
                    aria-label="privacy"
                    value={privacy}
                    onChange={handlePrivacy}
                    key={"privacyKey"}
                  >
                    <ToggleButton
                      value={wdpConstants.privacyVisible.value}
                      style={{ padding: 0 }}
                      className={classes.toggleButton}
                      aria-label={t("Visible")}
                    >
                      {t("Visible")}
                    </ToggleButton>
                    <ToggleButton
                      value={wdpConstants.privacyPrivate.value}
                      className={classes.toggleButton2}
                      style={{ padding: 0 }}
                      aria-label={t("Private")}
                    >
                      {t("Private")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
              </Paper>
            </div>
            <Box my={2}>
              <Checkbox
                checkedIcon={<CheckBoxOutlined />}
                checked={showMyPhoneNumber}
                onChange={() => {
                  setShowMyPhoneNumber((prevState) => {
                    setSettingsChanged(true);
                    return !prevState;
                  });
                }}
              />
              <Box
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  display: "inline-flex",
                  verticalAlign: "middle",
                  color: bhpColor.grey1,
                }}
                px={1}
              >
                {t("Display my phone number")}
              </Box>
            </Box>
            <Box my={2}>
              <Button
                onClick={saveChanges}
                variant="contained"
                disabled={!formValid}
                color="primary"
                disableElevation
              >
                {`${!firstTimeSave ? t("Update Changes") : t("Complete Setup")}`}
              </Button>
            </Box>
            <PopupInformationWidget
              t={t}
              isOpen={informationOpen}
              handleClose={handleInformationClose}
              handleSecondaryClick={
                informationModal?.secondaryButtonText ? openDataPrivacy : handleInformationClose
              }
              title={informationModal?.title || null}
              content={informationModal?.content || null}
              buttonText={informationModal?.primaryButtonText || null}
              secondaryButtonText={informationModal?.secondaryButtonText || null}
            />
            <PopupInformationWidget
              t={t}
              isOpen={privacyStatementOpen}
              handleClose={handlePrivacyStatementClose}
              title={t("Privacy Statement Title")}
              content={t("Privacy Statement Content")}
              buttonText={t("Privacy Statement Button")}
              secondaryButtonText={null}
            />
            <ConfirmDialog
              isOpen={confirmDialogOpen}
              handlePrimaryClick={handleConfirmDialogPrimaryClick}
              handleSecondaryClick={handleConfirmDialogSecondaryClick}
              primaryButtonText={t("Update Visibility")}
              secondaryButtonText={t("Cancel")}
              title={t("Are you sure")}
            >
              <div dangerouslySetInnerHTML={{ __html: t("Privacy content") }} />
            </ConfirmDialog>
          </Box>
        </Paper>
      </Box>
    </>
  );
});

export default Settings;
