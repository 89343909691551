import { observer } from "mobx-react-lite";
import { ConfirmDialog } from "components/utils/ConfirmDialog";
import { Box, TextField } from "@material-ui/core";
import { useState } from "react";
import { Noticeboard } from "interfaces/Teams";

interface EditNoticeDialogProps {
  t: any;
  handleUpdateClick: any;
  handleCloseClick: any;
  isOpen: boolean;
  notice: Noticeboard;
}

const EditNoticeDialog = observer((props: EditNoticeDialogProps) => {
  const { t, handleUpdateClick, handleCloseClick, isOpen, notice } = props;

  const [editNoticeSubject, setEditNoticeSubject] = useState<string>(notice.subject);
  const [editNoticeDescription, setEditNoticeDescription] = useState<string>(notice.description);

  const handleSave = () => {
    notice.subject = editNoticeSubject;
    notice.description = editNoticeDescription;
    handleUpdateClick(notice);
  };

  const handleEditNoticeSubjectChange = (newValue: any) => {
    setEditNoticeSubject(newValue.target.value);
  };

  const handleEditNoticeDescriptionChange = (newValue: any) => {
    setEditNoticeDescription(newValue.target.value);
  };

  return (
    <>
      {
        <ConfirmDialog
          isOpen={isOpen}
          title={t("Edit Notice")}
          primaryButtonText={t("Update Notice")}
          secondaryButtonText={t("Cancel")}
          handleSecondaryClick={handleCloseClick}
          handlePrimaryClick={handleSave}
          disablePrimary={
            notice.subject === editNoticeSubject && notice.description === editNoticeDescription
          }
          //maxWidth="sm"
        >
          <Box>
            <Box py={2} style={{ fontWeight: "bold" }}>
              {t("Subject")}
            </Box>
            <Box component="form">
              <TextField
                id="editNoticeSubjectTextArea"
                variant="outlined"
                rows={2}
                inputProps={{ maxLength: 150 }}
                multiline
                placeholder={t(`Enter a subject... (150 Characters limit)`)}
                value={editNoticeSubject}
                onChange={handleEditNoticeSubjectChange}
                style={{ width: 400 }}
              />
            </Box>
            <Box py={2} style={{ fontWeight: "bold" }}>
              {t("Description")}
            </Box>
            <Box component="form">
              <TextField
                id="editNoticeDescriptionTextArea"
                variant="outlined"
                rows={4}
                inputProps={{ maxLength: 250 }}
                multiline
                placeholder={t(`Enter the description... (250 Characters limit)`)}
                value={editNoticeDescription}
                onChange={handleEditNoticeDescriptionChange}
                style={{ width: 400 }}
              />
            </Box>
          </Box>
        </ConfirmDialog>
      }
    </>
  );
});

export default EditNoticeDialog;
