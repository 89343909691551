import {
  Box,
  createStyles,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from "@material-ui/core";
import Icon from "@mdi/react";
import moment, { Moment } from "moment";
import { mdiCalendarRemoveOutline, mdiHeadset, mdiMapMarkerRadiusOutline } from "@mdi/js";
import { bhpColor, settings } from "styles/globals";
import { Schedule, ScheduleType } from "interfaces/Schedule";
import { observer } from "mobx-react-lite";
import {
  useBuildingStore,
  useScheduleStore,
  useUserStore,
  useBookingStore,
} from "providers/RootStoreProvider";
import { get } from "lodash";
import { cityAbbr } from "utils/locations";
import { getWorkTypeColor } from "utils/backGroundColor";
import { BuildingType } from "interfaces/Building";
import config from "config";
import { useContext, useEffect } from "react";
import HolidayPopover from "components/utils/HolidayPopover";
import { actualStartOfWeek, endOfPeriod } from "utils/date";
import { getEarliestBooking } from "utils/bookings";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    scheduleBlock: {
      marginTop: 24,
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        marginLeft: `-${theme.spacing(2)}px`,
        marginTop: 0,
        padding: theme.spacing(2),
        background: "white",
        width: `calc(100% + ${theme.spacing(4)}px)`,
      },
    },
    tableContainer: {},
    scheduleTable: {
      tableLayout: "fixed",
      "& .MuiTableHead-root": {
        backgroundColor: bhpColor.backgroundGrey,
        border: `1px solid ${bhpColor.blueGrey4}`,
      },
      "& .MuiTableCell-head": {
        borderBottom: "none",
      },
      "& .MuiTableCell-root": {
        padding: 8,
        [theme.breakpoints.down("sm")]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    day: {
      "&.weekend": {
        backgroundColor: `${bhpColor.blueGrey4}30`,
      },
    },
    dayText: {
      marginLeft: "auto",
      marginRight: "auto",
      width: 52,
      height: 66,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        width: "unset",
        marginRight: "4px",
        marginLeft: "4px",
      },
      "&.selected": {
        backgroundColor: "white",
        borderRadius: settings.borderRadius,
        border: `1px solid ${bhpColor.blueGrey1}`,
      },
      "& .dayOfMonth": {
        fontWeight: "bold",
        fontSize: 20,
        paddingTop: 12,
        color: bhpColor.blueGrey1,
      },
      "& .dayOfWeek": {
        color: bhpColor.blueGrey2,
        fontSize: 12,
      },
      "& .holidayDate": {
        color: bhpColor.orange1,
      },
      "& .holidayWeekName": {
        color: bhpColor.orange2,
      },
    },
    statusCell: {
      textAlign: "center",
      border: "none",
      backgroundColor: bhpColor.white,
      paddingTop: `16px !important`,
      paddingBottom: `16px !important`,
      "&.weekend": {
        backgroundColor: `${bhpColor.blueGrey4}30`,
      },
    },
    statusBlock: {
      backgroundColor: bhpColor.backgroundGrey,
      borderRadius: 4,
      height: 56,
      width: 54,
      fontSize: 10,
      marginLeft: "auto",
      marginRight: "auto",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        height: 45,
        width: 45,
      },
    },
    officeName: {
      marginTop: 4,
      minHeight: 13,
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
        minHeight: 10,
      },
    },
    bookingRef: {
      height: 20,
      marginTop: -5,
      [theme.breakpoints.down("sm")]: {
        marginTop: -7,
      },
      "&.nobooking": {
        color: bhpColor.orange1,
      },
    },
    icon: {
      height: 20,
      color: bhpColor.blueGrey2,
      "&.office": {
        [theme.breakpoints.down("sm")]: {
          marginTop: -4,
        },
      },
    },
  })
);

/// From workType return the icon type office|remote|off (default: off)
const getIconPath = (workType?: ScheduleType) => {
  switch (workType) {
    case ScheduleType.OFFICE:
      return mdiMapMarkerRadiusOutline;
    case ScheduleType.REMOTE:
      return mdiHeadset;
    case ScheduleType.OFF:
      return mdiCalendarRemoveOutline;
    default:
      return mdiCalendarRemoveOutline;
  }
};

/// Looks at day of week to identify weekend
const isWeekend = (date: string) => {
  const dateParameter = moment(date).utc().toDate();
  return dateParameter.getDay() === 0 || dateParameter.getDay() === 6;
};

/// Compares given date with current date to identify if today
const isToday = (date: string) => {
  const dateParameter = moment(date).utc().toDate();
  var today = moment().utc().toDate();
  return (
    dateParameter.getDate() === today.getDate() &&
    dateParameter.getMonth() === today.getMonth() &&
    dateParameter.getFullYear() === today.getFullYear()
  );
};

type YourScheduleWidgetProps = {
  startDate: string;
  selectedDate?: string;
  changeDate?: any;
  t: any;
  altTopMargin?: number;
  altRowHeight?: number;
};

/// pass in an array of 7 workDay
const YourScheduleWidget = observer((props: YourScheduleWidgetProps) => {
  const { startDate, changeDate, selectedDate, t, altTopMargin, altRowHeight } = props;
  const classes = innerStyles();
  const scheduleStore = useScheduleStore();
  const userStore = useUserStore();
  const buildingStore = useBuildingStore();
  const bookingStore = useBookingStore();

  const openFloorPlan = (id: number | undefined, date: string | undefined) => {
    if (id) {
      buildingStore.setupFloorPlanFromSerraviewId(id, false, date);
    }
  };

  const defaultOffice = userStore.me?.profile.default_office
    ? buildingStore.getBuilding(userStore.me.profile.default_office)
    : null;
  const thisDay = moment.utc(startDate);

  const isCurrent = (date: string) => {
    return selectedDate
      ? moment(date).isSame(selectedDate, "day")
      : moment(date).isSame(moment().utc(), "day");
  };

  /// Rick - workaround for the potential no schedules scenario
  const getDaySchedule = (date: Moment) => {
    const hasSchedules = scheduleStore.schedules.length > 0;
    const weekday = date.utc().format("ddd").toLowerCase();
    return hasSchedules
      ? scheduleStore.scheduleForDay(date.utc().format("YYYY-MM-DD"))
      : {
          date: date.utc().format("YYYY-MM-DD"),
          status: get(userStore.me!.profile.default_week, weekday, null),
          space: null,
          office: userStore.defaultOffice
            ? buildingStore.getBuilding(userStore.defaultOffice.id)
            : null,
        };
  };

  const autoFit = (lgth: number, txt?: string) => {
    return txt ? txt.length > lgth ? <span style={{ fontSize: "smaller" }}>{txt}</span> : txt : "";
  };

  const numberOfDaysInWidget = 7;
  const theWeek: { date: string; schedule?: Schedule }[] = [...Array(numberOfDaysInWidget)].map(
    (theDay, idx) => {
      const date = moment(thisDay).utc().add(idx, "d");
      const dateStr = date.format("YYYY-MM-DD");
      return {
        date: dateStr,
        schedule: getDaySchedule(date),
      };
    }
  );
  const getLocation = (sched?: Schedule) => {
    return !sched
      ? cityAbbr(defaultOffice)
      : sched.status === ScheduleType.OFFICE
      ? cityAbbr(sched.office ? sched.office : defaultOffice)
      : null;
  };

  const setDate = (dte: string) => {
    if (changeDate) changeDate(dte);
  };

  useEffect(() => {
    if (userStore.me) {
      const schedulePeriod = {
        start: actualStartOfWeek(new Date()),
        end: endOfPeriod(actualStartOfWeek(new Date()), 7),
      };
      bookingStore.loadUserBookings(schedulePeriod, userStore.me.id.toString());
    }
  }, [bookingStore, userStore.me]);

  return (
    <Box
      className={classes.scheduleBlock}
      style={{ marginTop: altTopMargin ? `${altTopMargin}px` : "" }}
    >
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.scheduleTable} cellSpacing={5}>
          <TableHead>
            <TableRow style={{ height: altRowHeight ? `${altRowHeight}px` : "" }}>
              {theWeek.map((workDay: { date: string; schedule?: Schedule }, key) => {
                return (
                  <TableCell
                    key={key}
                    className={`${classes.day} ${isWeekend(workDay.date) ? "weekend" : ""}`}
                    onClick={() => setDate(workDay.date)}
                    style={changeDate ? { cursor: "pointer" } : {}}
                  >
                    <Box
                      className={`${classes.dayText} ${isCurrent(workDay.date) ? "selected" : ""}
                        `}
                      style={{ position: "relative" }}
                    >
                      <div className={"dayOfMonth"}>{moment.utc(workDay.date).format("D")}</div>
                      <div className={"dayOfWeek"}>{t(moment.utc(workDay.date).format("ddd"))}</div>
                      <HolidayPopover date={workDay.date} />
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ height: altRowHeight ? `${altRowHeight}px` : "" }}>
              {theWeek.map((workDay: { date: string; schedule?: Schedule }, key) => {
                const earliestBooking = bookingStore.user_bookings[workDay.date]
                  ? getEarliestBooking(bookingStore.user_bookings[workDay.date], workDay.date)
                  : null;

                return (
                  <TableCell
                    key={key}
                    className={`${classes.statusCell}  ${isWeekend(workDay.date) ? "weekend" : ""}`}
                  >
                    <Tooltip
                      title={
                        workDay.schedule?.status !== ScheduleType.OFF ? (
                          <div>
                            {t("Working Hours")}:{" "}
                            {moment(
                              workDay.schedule?.start_time && workDay.schedule?.start_time !== ""
                                ? workDay.schedule?.start_time
                                : config.workday.defaultStartWorkingHour,
                              ["HH:mm", "H:mm"]
                            ).format("hh:mm A")}{" "}
                            -{" "}
                            {moment(
                              workDay.schedule?.finish_time && workDay.schedule?.finish_time !== ""
                                ? workDay.schedule?.finish_time
                                : config.workday.defaultFinishWorkingHour,
                              ["HH:mm", "H:mm"]
                            ).format("hh:mm A")}
                            <br />
                            {workDay.schedule?.status === ScheduleType.OFFICE &&
                            workDay.schedule?.space ? (
                              workDay.schedule?.desk_finish_time &&
                              workDay.schedule?.desk_start_time ? (
                                <div>
                                  {t("Desk Hours")}:{" "}
                                  {moment(workDay.schedule?.desk_start_time, [
                                    "HH:mm",
                                    "H:mm",
                                  ]).format("hh:mm A")}{" "}
                                  -{" "}
                                  {moment(workDay.schedule?.desk_finish_time, [
                                    "HH:mm",
                                    "H:mm",
                                  ]).format("hh:mm A")}
                                </div>
                              ) : (
                                <div>
                                  {t("Desk Hours")}:{" "}
                                  {moment(config.workday.defaultStartWorkingHour, [
                                    "HH:mm",
                                    "H:mm",
                                  ]).format("hh:mm A")}{" "}
                                  -{" "}
                                  {moment(config.workday.defaultFinishWorkingHour, [
                                    "HH:mm",
                                    "H:mm",
                                  ]).format("hh:mm A")}
                                </div>
                              )
                            ) : (
                              ""
                            )}
                            {earliestBooking && (
                              <div>
                                {t("Booking Hours")}:{" "}
                                {moment(earliestBooking.booking_details!.start_time_local, [
                                  "HH:mm",
                                  "H:mm",
                                ]).format("hh:mm A")}{" "}
                                -{" "}
                                {moment(earliestBooking.booking_details!.end_time_local, [
                                  "HH:mm",
                                  "H:mm",
                                ]).format("hh:mm A")}
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )
                      }
                    >
                      <div
                        className={`${classes.statusBlock}`}
                        style={{
                          color: bhpColor.white,
                          border: `3px solid ${getWorkTypeColor(workDay.schedule?.status)}`,
                          backgroundColor: getWorkTypeColor(workDay.schedule?.status),
                        }}
                      >
                        {/* //<div className={classes.officeName}>{getLocation(workDay.schedule)}</div> */}
                        <div className={classes.officeName}>
                          {earliestBooking?.building
                            ? cityAbbr(earliestBooking?.building)
                            : getLocation(workDay.schedule)}
                        </div>
                        <Icon
                          path={getIconPath(workDay.schedule?.status)}
                          className={classes.icon}
                          style={{
                            color: bhpColor.white,
                          }}
                        />
                        <div
                          className={`${classes.bookingRef} ${
                            getLocation(workDay.schedule) && !workDay.schedule?.space
                              ? "nobooking"
                              : ""
                          }`}
                        >
                          {workDay.schedule?.office?.building_type === BuildingType.OFFICE &&
                          getLocation(workDay.schedule) &&
                          !workDay.schedule?.space ? (
                            workDay.schedule ? (
                              <Link
                                href={`/booking/${workDay.schedule?.date}/${workDay.schedule?.office?.id}`}
                              >
                                {t("BOOK")}
                              </Link>
                            ) : (
                              t("BOOK")
                            )
                          ) : (
                            <Link
                              href="#"
                              onClick={() =>
                                openFloorPlan(
                                  earliestBooking
                                    ? earliestBooking.space?.serraview_id
                                    : workDay.schedule?.space?.serraview_id,
                                  workDay.schedule?.date
                                )
                              }
                              style={{ color: bhpColor.white }}
                            >
                              {autoFit(
                                6,
                                earliestBooking
                                  ? earliestBooking.space?.name
                                  : workDay.schedule?.space?.name
                              )}
                            </Link>
                          )}
                        </div>
                      </div>
                    </Tooltip>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});

export default YourScheduleWidget;
