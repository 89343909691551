import { makeAutoObservable, flow, toJS, runInAction } from "mobx";
import RootStore from "./RootStore";
import { VisitorRegistration } from "interfaces/Visitor";
import client from "services/api";

export default class VisitorStore {
  error: string | unknown = "";
  rootStore!: RootStore;
  isLoading = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  createVisitor = flow(function* (
    this: VisitorStore,
    newRegistration: {
      email_id: string;
      first_name: string;
      last_name: string;
      space_id: number;
      location_id: number;
      company?: string;
      phone_number?: string;
      reason_for_visit?: string;
      from_date_time: string;
      to_date_time: string;
      host_name_id: number;
      book_a_desk: boolean;
      notify_visitor: boolean;
      // access_area: string;
      requester_id: number;
      parent_visitor_id: number;
      access_pass_needed: boolean;
      special_access: boolean;
    }
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(`/api/visitor/registrations/`, newRegistration);
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  checkInVisitor = flow(function* (
    this: VisitorStore,
    visitorCheckin: {
      email_id: string;
      reason_for_visit: string;
      notify_host: string;
    }
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(`/api/visitor/checkin/`, visitorCheckin);
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });

  validateVisitorEmail = flow(function* (
    this: VisitorStore,
    validateVisitorEmailValues: {
      email_id: string;
    }
  ) {
    try {
      this.error = "";
      this.isLoading = true;
      const res = yield client.post(
        `/api/visitor/check-visitor-email/`,
        validateVisitorEmailValues
      );
      this.isLoading = false;
      return res;
    } catch (err) {
      this.isLoading = false;
      this.error = err;
      return { data: err, status: 0, statusText: "API Failed" };
    }
  });
}
