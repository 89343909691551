//region GET DATA
export class api {
  static getModalProps = (t: any, infoId: number) => {
    let title = "";
    let content = "";
    let primaryButtonText = "";
    let secondaryButtonText = "";
    switch (infoId) {
      case 1:
        title = t("Team Information");
        content = t("Team Information Content");
        primaryButtonText = t("Okay");
        break;
      case 2:
        title = t("Location Information");
        content = t("Location Information Content");
        primaryButtonText = t("Okay");
        break;
      case 3:
        title = t("Timezone Information");
        content = t("Timezone Information Content");
        primaryButtonText = t("Okay");
        break;
      case 4:
        title = t("Weekly Routine");
        content = t("Weekly Routine Content");
        primaryButtonText = t("Okay");
        break;
      case 5:
        title = t("Planner Visibility");
        content = t("Visibility content");
        primaryButtonText = t("Okay");
        break;
      case 6:
        title = t("Welcome...");
        content = t("Now let's get you up and running");
        primaryButtonText = t("Let's get you started");
        secondaryButtonText = t("View Data Privacy Statement");
        break;
      case 7:
        title = t("Display Name");
        content = t("Your preferred display name.");
        primaryButtonText = t("Okay");
        break;
      case 8:
        title = t("Working Hours");
        content = t("Your preferred working hours.");
        primaryButtonText = t("Okay");
        break;
      case 9:
        title = t("Access Levels");
        content = t("Access Levels Content");
        primaryButtonText = t("Okay");
        break;
      case 10:
        title = t("Pronouns");
        content = t("Pronouns Content");
        // content = t(
        //   `<strong>Information around Pronouns</strong> - Link is :
        //     <a href="https://spo.bhpbilliton.com/sites/GRPBISTECHSTDS/Collection/Technology%20Inclusive%20Identity%20Data%20Standard%20IDC00001.pdf"
        //      rel="external" target="_blank">Inclusive Identity Data (bhpbilliton.com)</a>`
        // );

        primaryButtonText = t("Okay");
        break;
    }
    return { title, content, primaryButtonText, secondaryButtonText };
  };
}
//endregion GET DATA
