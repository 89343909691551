import {
  AppBar,
  Avatar,
  Box,
  createStyles,
  Divider,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import SettingsIcon from "@material-ui/icons/Settings";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import { mdiAccountSupervisor } from "@mdi/js";
import MenuIcon from "@material-ui/icons/Menu";
import { mdiChevronDown, mdiAccountCircle, mdiLogout, mdiTranslate } from "@mdi/js";
import TranslateIcon from "@material-ui/icons/Translate";
import { useNotificationStore, useUserStore, useBuildingStore } from "providers/RootStoreProvider";
import { useMsal } from "@azure/msal-react";
import { bhpColor } from "styles/globals";
import logoHorizontal from "assets/workday-horizontal.png";
import logoVertical from "assets/workday-vertical.png";
import { useEffect, useState } from "react";
import useInterval from "utils/useInterval";
import moment from "moment";
import { TimezoneWidget } from "components/utils/TimezoneWidget";

const innerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
      alignItems: "center",
      justifyContent: "center",
    },
    appbar: {
      backgroundColor: "transparent",
      boxShadow: "none",
      color: "inherit",
      [theme.breakpoints.down("xs")]: {
        backgroundColor: bhpColor.blueGrey4,
      },
    },
    toolbar: {
      backgroundColor: bhpColor.blueGrey4,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      },
    },
    admin: {
      fontWeight: "bold",
      color: bhpColor.orange1,
      textDecoration: "none",
      marginRight: theme.spacing(2),
    },
    avatar: {
      backgroundColor: "rgb(250, 182, 54)",
      color: "white",
    },
    avatarSmall: {
      width: "35px !important",
      height: "35px !important",
      marginLeft: "16px",
      fontSize: "16px",
    },
    avatarLarge: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      margin: 0,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    settingsIcon: {
      marginRight: "8px",
    },
    notificationsIcon: {
      marginRight: "0",
    },
    sectionDesktop: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    svgTop: {
      width: "80px",
    },
    onHoverPointerChange: {
      cursor: "pointer",
    },
    gridContainer: {
      padding: theme.spacing(2.5),
      backgroundColor: bhpColor.blueGrey4,
    },
    listItem: {
      paddingBottom: 0,
      width: "100%",
      color: bhpColor.blueGrey1,
      "& .MuiListItem-root": {
        paddingTop: 10,
        paddingBottom: 10,
        "& .MuiListItemIcon-root": {
          color: bhpColor.blueGrey1,
        },
        "&:hover": {
          backgroundColor: "white",
          color: bhpColor.orange1,
          "& .MuiListItemIcon-root": {
            color: bhpColor.orange1,
          },
        },
      },
    },
    name: {
      fontSize: 20,
      fontWeight: "bold",
      color: bhpColor.blueGrey1,
      marginTop: theme.spacing(1.5),
    },
    email: {
      fontSize: 14,
      color: bhpColor.grey1,
      marginTop: theme.spacing(1),
    },
    logo: {
      display: "flex",
      width: "100%",
      padding: theme.spacing(2),
    },
    logoHorizontal: {
      height: 60,
      marginLeft: `-${theme.spacing(2)}px`,
      alignSelf: "center",
    },
    sectionMobileMenu: {
      display: "flex",
      padding: 0,
      backgroundColor: bhpColor.blueGrey4,
      [theme.breakpoints.up("md")]: {
        display: "none",
        padding: "0 16px",
      },
    },
  })
);

const handleSignout = (instance: any) => {
  const logoutRequest = {
    account: instance.getAccountByHomeId(window.env.AZURE_CONFIG.clientId),
    postLogoutRedirectUri: "https://www.bhp.com",
  };
  instance.logoutRedirect(logoutRequest);
};

interface AppBarWrapperProps {
  toggleDrawer: any;
  t: any;
  i18n: any;
}

const AppBarWrapper = observer((props: AppBarWrapperProps) => {
  const { toggleDrawer, t, i18n } = props;
  const classes = innerStyles();
  const history = useHistory();
  const userStore = useUserStore();
  const buildingStore = useBuildingStore();
  const notificationStore = useNotificationStore();
  const { instance } = useMsal();
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [language, setLanguage] = useState<string>(sessionStorage.getItem("language") || "en");
  const [profilePicture, setProfilePicture] = useState<any>(null);
  const trapMenuClick = () => {
    toggleDrawer();
  };

  const toggleLanguage = () => {
    setLanguage(language == "en" ? "es" : "en");
  };

  useEffect(() => {
    buildingStore.loadBuildings();

    sessionStorage.setItem("language", language);
    i18n.changeLanguage(language);
    if (userStore.profilePicture) {
      setProfilePicture(userStore.profilePicture);
    }
  }, [language, userStore.profilePicture]);

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar disableGutters={true} className={classes.toolbar} style={{ paddingLeft: 0 }}>
        <Grid container>
          <Grid item xs={7}>
            <Hidden mdUp>
              <div className={classes.sectionMobileMenu} onClick={trapMenuClick}>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                >
                  <MenuIcon />
                </IconButton>
                <img alt="logo" className={classes.logoHorizontal} src={logoHorizontal}></img>
              </div>
            </Hidden>
          </Grid>
          <Grid item xs={5} style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className={classes.sectionDesktop}>
              {(userStore?.me?.is_staff || userStore?.me?.is_superuser) && smAndUp && (
                <a className={classes.admin} href="/api/admin/">
                  {t("Admin Portal")}
                </a>
              )}

              <SettingsIcon
                className={classes.settingsIcon + " " + classes.onHoverPointerChange}
                onClick={() => {
                  history.push("/settings");
                }}
              />
              <Badge
                invisible={notificationStore.unreadCount === 0}
                badgeContent={
                  (notificationStore.unreadCount <= 99 && notificationStore.unreadCount) || `99+`
                }
                color="primary"
              >
                <NotificationsIcon
                  className={classes.notificationsIcon + " " + classes.onHoverPointerChange}
                  onClick={() => {
                    history.push("/notifications");
                  }}
                />
              </Badge>
              {smAndUp && <TimezoneWidget />}

              <PopupState variant="popover" popupId="profileMenu">
                {(popupState) => (
                  <>
                    <Avatar
                      className={
                        classes.avatar +
                        " " +
                        classes.avatarSmall +
                        " " +
                        classes.onHoverPointerChange
                      }
                      src={profilePicture}
                      {...bindTrigger(popupState)}
                    >
                      {userStore.getInitials()}
                    </Avatar>
                    <Icon
                      path={mdiChevronDown}
                      className={classes.onHoverPointerChange}
                      size={1}
                      {...bindTrigger(popupState)}
                    />
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box width={255}>
                        <Grid container className={classes.gridContainer}>
                          <Grid item xs={12}>
                            <div className={classes.root}>
                              <Avatar
                                className={classes.avatar + " " + classes.avatarLarge}
                                src={userStore.profilePicture}
                              >
                                {userStore.getInitials()}
                              </Avatar>
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="h6" align={"center"} className={classes.name}>
                              {userStore.getPreferredName()}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" align={"center"} className={classes.email}>
                              {userStore.me?.email}
                            </Typography>
                          </Grid>
                        </Grid>

                        <List
                          className={classes.listItem}
                          component="nav"
                          aria-label="mailbox folders"
                        >
                          <ListItem
                            button
                            onClick={() => {
                              history.push("/profile");
                            }}
                          >
                            <ListItemIcon>
                              <Icon path={mdiAccountCircle} size={1} />
                            </ListItemIcon>
                            <ListItemText primary={t("Profile")} />
                          </ListItem>
                          <Divider variant="fullWidth" />
                          {(userStore?.me?.is_staff || userStore?.me?.is_superuser) && !smAndUp && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  history.push("/api/admin/");
                                }}
                              >
                                <ListItemIcon>
                                  <Icon path={mdiAccountSupervisor} size={1} />
                                </ListItemIcon>
                                <ListItemText primary={t("Admin Portal")} />
                              </ListItem>
                              <Divider variant="fullWidth" />
                            </>
                          )}
                          <ListItem
                            button
                            onClick={() => {
                              toggleLanguage();
                            }}
                          >
                            <ListItemIcon>
                              <Icon path={mdiTranslate} size={1} />
                            </ListItemIcon>
                            <ListItemText>
                              {t("Translate")} ({language == "en" ? "Español" : "English"})
                            </ListItemText>
                          </ListItem>
                          <Divider variant="fullWidth" />
                          <ListItem button onClick={() => handleSignout(instance)}>
                            <ListItemIcon>
                              <Icon path={mdiLogout} size={1} />
                            </ListItemIcon>
                            <ListItemText primary={t("Sign-out")} />
                          </ListItem>
                        </List>
                      </Box>
                    </Popover>
                  </>
                )}
              </PopupState>
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
});

export default AppBarWrapper;
