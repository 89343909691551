import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { bhpColor, settings } from "./globals";

export const dashboardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
      },
      display: "flex",
      flexGrow: 1,
      "& .MuiGrid-item": {
        display: "initial",
      },
    },
    yourScheduleGrid: {},
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: settings.outerPadding,
      textAlign: "center",
    },
    unbold: {
      fontWeight: 400,
    },
    orangeButton: {
      borderColor: bhpColor.orange1,
      fontSize: 14,
      color: bhpColor.orange1,
      minWidth: "auto",
      fontWeight: "bold",
      textTransform: "unset",
      whiteSpace: "nowrap",
    },
    teamSelect: {
      "& fieldset": {
        backgroundColor: "white",
      },
    },
    teamSelectionGrid: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      alignContent: "center",
      [theme.breakpoints.down("md")]: { justifyContent: "start" },
      "& .MuiBox-root": {
        textAlign: "end",
      },
    },
    teamSelectDropdown: {
      fontWeight: "bold",
      height: 40,
      width: "100%",
      backgroundColor: bhpColor.white,
      "& select": {
        "&:focus": {
          backgroundColor: "unset",
        },
      },
    },
  })
);
