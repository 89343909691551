import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { bhpColor, settings } from "./globals";

export const teamStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
    },
    header: {
      color: bhpColor.blueGrey1,
      marginBottom: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(2),
    },
    summaryTeam: {
      "& :hover": {
        cursor: "pointer",
      },
    },
    hoverTeamSummaryBorder: {
      border: `2px solid transparent`,
      "&:hover": {
        border: `2px solid ${bhpColor.blueGrey1}`,
        borderRadius: 4,
      },
    },
    summaryTeamType: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      textAlign: "right",
      marginRight: theme.spacing(1),
      color: bhpColor.grey1,
    },
    summaryTeamTitle: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      marginLeft: theme.spacing(1),
      fontWeight: 700,
      color: bhpColor.blueGrey1,
      fontSize: 18,
    },
    summaryTeamDepartment: {
      marginLeft: theme.spacing(1),
      fontSize: 16,
      color: bhpColor.blueGrey2,
    },
    summaryTeamCategory: {
      marginLeft: theme.spacing(1),
      fontSize: 16,
      color: bhpColor.orange1,
    },
    summaryTeamMembersCount: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(3),
      color: bhpColor.grey2,
      fontSize: 12,
      display: "flex",
      alignItems: "end",
    },
    summaryTeamAvatars: {
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(1),
      "& .MuiAvatar-colorDefault": {
        color: bhpColor.white,
        backgroundColor: bhpColor.blueGrey1,
        fontSize: 14,
      },
      "& .MuiAvatarGroup-root": {
        justifyContent: "flex-end",
      },
    },
    summaryTeamWithMoreAvatars: {
      "& .MuiAvatar-colorDefault:last-child": {
        color: bhpColor.white,
        backgroundColor: bhpColor.orange1,
        fontSize: 14,
      },
    },
    capacityBlock: {
      [theme.breakpoints.up("sm")]: {
        width: 155,
      },
    },
    teamWorkSummarySpacing: {
      [theme.breakpoints.up("xl")]: {
        flexBasis: "14%",
      },
    },
    searchTeam: {
      paddingTop: 15,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 5,
      },
    },
    recordsFoundLabel: {
      color: bhpColor.orange1,
      fontFamily: "Arial",
      fontStyle: "italic",
      padding: "1%",
    },
    spaceTable: {
      border: 0,
      "& .MuiTablePagination-root": {
        color: bhpColor.blueGrey1,
      },
      "& .MuiDataGrid-renderingZone": {
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-viewport": {
        maxHeight: "none",
      },
      "& .MuiDataGrid-root": {
        border: 0,
      },
      "& .MuiDataGrid-cell": {
        border: 0,
        "&:focus": {
          outline: 0,
        },
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-columnHeader": {
        "&:focus": {
          outline: 0,
        },
        "&:focus-within": {
          outline: 0,
        },
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        paddingLeft: 0,
      },
      "& .MuiDataGrid-columnsContainer": {
        borderBottom: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
        color: bhpColor.blueGrey1,
      },
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-row": {
        maxHeight: "none !important",
        "&:nth-child(odd)": {
          backgroundColor: bhpColor.backgroundGrey,
          "&:hover": {
            backgroundColor: bhpColor.backgroundGrey,
          },
        },
        "&.Mui-selected": {
          "&:hover": {
            backgroundColor: bhpColor.blueGrey2,
            color: bhpColor.blueGrey1,
          },
          backgroundColor: bhpColor.blueGrey1,
          color: bhpColor.white,
        },
        "&:hover": {
          backgroundColor: bhpColor.backgroundGrey,
        },
      },
    },
    dataGrid: {
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiDataGrid-row.Mui-even": {
        backgroundColor: "#F6F6F6",
      },
      "& .MuiDataGrid-cell": {
        color: "#466371",
      },
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-sortIcon": {
          opacity: `1 !important`,
        },
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        color: "#50524D",
        fontWeight: "bold !important",
        paddingRight: 16,
      },
      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden",
      },
      padding: "0 1% 1% 1%",
    },
    dataGridMobileRow: {
      "& .MuiDataGrid-row": {
        backgroundColor: "red",
        marginBottom: theme.spacing(2),
      },
    },
    saveFailed: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.purple1,
        fontWeight: "bold",
        backgroundColor: bhpColor.purple4,
        border: `2px solid ${bhpColor.purple1}`,
        borderRadius: settings.borderRadius,
      },
    },
    saveSuccess: {
      "& .MuiSnackbarContent-root": {
        color: bhpColor.orange1,
        fontWeight: "bold",
        backgroundColor: bhpColor.orange4,
        border: `2px solid ${bhpColor.orange1}`,
        borderRadius: settings.borderRadius,
      },
    },
    hideDataGridColumnHeader: {
      "& .MuiDataGrid-columnHeader": {
        visibility: "hidden",
      },
      "& .MuiDataGrid-window": {
        top: 8,
      },
    },
  })
);
