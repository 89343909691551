import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PeopleIcon from "@material-ui/icons/People";
import EventIcon from "@material-ui/icons/Event";
import LanguageIcon from "@material-ui/icons/Language";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LoadingZone from "components/schedule/LoadingZone";
import CapacityTab from "./CapacityTab";
import PeopleTab from "./PeopleTab";
import EventsTab from "./EventsTab";
import { BuildingType, BuildingWithCapacity } from "interfaces/Building";
import { Paper } from "@material-ui/core";
import { bhpColor } from "styles/globals";
import IFrameResizer from "iframe-resizer-react";
import { useParams } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useTheme } from "@material-ui/core/styles";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useBuildingStore, useTeamStore } from "providers/RootStoreProvider";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SettingsSelectBox } from "components/settings/SettingsSelectBox";
import { Team } from "interfaces/Teams";
import InformationIcon from "components/utils/InformationIcon";
import { PopupInformationWidget } from "components/settings/PopupInformationWidget";
import { getOfficeIframe } from "utils/locations";
import { workSpaceIframe } from "utils/locations";
import { ClassRounded } from "@material-ui/icons";

interface OfficeTabPanelProps {
  t: any;
  building: BuildingWithCapacity;
  focussedTeam: number | null;
  changeTeam?: any;
  children?: React.ReactNode;
}

const OfficeTabPanel = observer((props: OfficeTabPanelProps) => {
  const { t, building, children, changeTeam, focussedTeam, ...other } = props;
  const theme = useTheme();
  const buildingStore = useBuildingStore();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div role="tabpanel" {...other}>
      <Box bgcolor={smAndUp ? "white" : "transparent"} position="relative" p={mdAndUp ? 3 : 0}>
        {buildingStore.isLoading && <LoadingZone isLoading={buildingStore.isLoading} />}
        {children}
      </Box>
    </div>
  );
});

interface OfficeCapacityProps {
  building: BuildingWithCapacity;
  team: Team | null;
  focussedDate: string;
  focussedTeam: number | null;
  changeTeam?: any;
  displayStart: string;
  displayEnd: string;
  changeDate: any;
  scrollTo: any;
  t: any;
}

interface urlParams {
  officeId: string;
  eventId: string;
  tab: string;
}
// Not required for the moment, but enables changing the team by dropdown
// const innerStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     teamSelect: {
//       "& fieldset": {
//         backgroundColor: "white",
//       },
//     },
//   })
// );

interface TabType {
  label: string;
  link: string;
  icon: any;
  component: any;
}

const OfficeCapacity = observer((props: OfficeCapacityProps) => {
  const {
    building,
    focussedDate,
    focussedTeam,
    displayStart,
    displayEnd,
    changeTeam,
    changeDate,
    scrollTo,
    team,
    t,
  } = props;
  const theme = useTheme();
  const history = useHistory();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const urlParams: urlParams = useParams();
  const [activeTab, setActiveTab] = useState(history.location.pathname);
  const [informationOpen, setInformationOpen] = useState(false);
  const [eventId, setEventId] = useState<number | null>(null);
  const [eventTabLink, setEventTabLink] = useState<string>("");
  const iframeInfoSrc = getOfficeIframe(building);
  const iframeWorkSpaceSrc = workSpaceIframe(building);

  const handleInformationOpen = () => {
    setInformationOpen(true);
  };
  const handleInformationClose = () => {
    setInformationOpen(false);
  };

  useEffect(() => {
    if (urlParams) {
      if (urlParams.tab) {
        if (urlParams.tab === "events") {
          if (urlParams.eventId) {
            setEventId(+urlParams.eventId);
            setEventTabLink(`/offices/${building.id}/${urlParams.tab}/${urlParams.eventId}`);
            setActiveTab(`/offices/${building.id}/${urlParams.tab}/${urlParams.eventId}`);
          } else {
            setEventTabLink(`/offices/${building.id}/events`);
            setActiveTab(`/offices/${building.id}/events`);
          }
        } else {
          setActiveTab(`/offices/${building.id}/${urlParams.tab}`);
        }
      } else {
        setActiveTab(`/offices/${building.id}/capacity`);
      }
    }
  }, []);

  const [weekView, setWeekView] = useState(false);
  const handleViewChange = () => {
    setWeekView(!weekView);
  };

  const capacityTab: TabType = {
    label: t("Capacity"),
    link: `/offices/${building.id}/capacity`,
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <EqualizerIcon fontSize="small" />
      </Box>
    ),
    component: (
      <OfficeTabPanel t={t} building={building} focussedTeam={focussedTeam} changeTeam={changeTeam}>
        <CapacityTab
          correctedTotalCapacity={building.total_capacity}
          isSite={building.building_type === BuildingType.SITE}
          t={t}
          currentTeam={team}
          displayStart={displayStart}
          displayEnd={displayEnd}
          scrollTo={scrollTo}
          building={building}
          weekView={weekView}
          viewChange={handleViewChange}
          changeTeam={changeTeam}
        />
      </OfficeTabPanel>
    ),
  };

  const peopleTab: TabType = {
    label: t("People"),
    link: `/offices/${building.id}/people`,
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <PeopleIcon fontSize="small" />
      </Box>
    ),
    component: (
      <OfficeTabPanel t={t} building={building} changeTeam={changeTeam} focussedTeam={focussedTeam}>
        <PeopleTab
          t={t}
          focussedDate={focussedDate}
          focussedTeam={focussedTeam}
          changeTeam={changeTeam}
          changeDate={changeDate}
          displayStart={displayStart}
          displayEnd={displayEnd}
          scrollTo={scrollTo}
          buildingId={building.id}
        />
      </OfficeTabPanel>
    ),
  };

  const eventsTab: TabType = {
    label: t("Events"),
    link: eventTabLink || `/offices/${building.id}/events`,
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <EventIcon fontSize="small" />
      </Box>
    ),
    component: (
      <OfficeTabPanel t={t} building={building} focussedTeam={focussedTeam} changeTeam={changeTeam}>
        <EventsTab building={building} eventId={eventId} />
      </OfficeTabPanel>
    ),
  };

  const informationTab: TabType = {
    label: t("Information"),
    link: `/offices/${building.id}/information`,
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <LanguageIcon fontSize="small" />
      </Box>
    ),
    component: (
      <OfficeTabPanel t={t} building={building} changeTeam={changeTeam} focussedTeam={focussedTeam}>
        {building && building.serraview_id && (
          // <IFrameResizer
          //   src={iframeInfoSrc}
          //   style={{ border: 0, minHeight: "1800px", width: "1px", minWidth: "100%" }}
          // />
          <>
            <Box px={4} pt={2} pb={4}>
              <Paper
                elevation={0}
                style={{
                  minHeight: 118,
                  padding: theme.spacing(2),
                  cursor: "pointer",
                  border: `2px solid ${bhpColor.blueGrey1}`,
                  width: "50%",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                }}
              >
                <Box
                  flexDirection="row"
                  onClick={() => {
                    window.open(iframeInfoSrc, "_blank");
                  }}
                >
                  <div
                    style={{
                      marginLeft: theme.spacing(1),
                      fontWeight: 700,
                      color: bhpColor.blueGrey1,
                      marginBottom: theme.spacing(1),
                    }}
                  >
                    Building Information
                  </div>
                  <div
                    style={{
                      marginLeft: theme.spacing(1),
                      fontSize: 16,
                      color: bhpColor.grey1,
                    }}
                  >
                    {t(`Click here to access Location information and materials`)}
                  </div>
                </Box>
              </Paper>
            </Box>
          </>
        )}
      </OfficeTabPanel>
    ),
  };

  const workSpaceTab: TabType = {
    label: t("Meeting Rooms"),
    link: `/offices/${building.id}/workspace`,
    icon: (
      <Box display="flex" alignItems="center" my="auto" mr={1}>
        <BubbleChartIcon fontSize="small" />
      </Box>
    ),
    component: (
      <OfficeTabPanel t={t} building={building} changeTeam={changeTeam} focussedTeam={focussedTeam}>
        {building && building.serraview_id && (
          <IFrameResizer
            src={iframeWorkSpaceSrc}
            style={{ border: 0, minHeight: "720px", width: "1px", minWidth: "100%" }}
          />
        )}
      </OfficeTabPanel>
    ),
  };

  const tabs: TabType[] = [
    capacityTab,
    peopleTab,
    ...(iframeInfoSrc.length > 0 ? [informationTab] : []),
    eventsTab,
    // ...(iframeWorkSpaceSrc.length > 0 ? [workSpaceTab] : []),
  ];

  const handleChange = (tabLink: string) => {
    history.push(tabLink);
    setActiveTab(tabLink);
  };

  const getTabType = (): "fullWidth" | "standard" => {
    if (smAndUp) {
      return "standard";
    } else {
      return "fullWidth";
    }
  };
  const selectedTab = tabs.find((tab) => activeTab === tab.link);

  return (
    <div>
      <Tabs
        value={activeTab}
        variant={getTabType()}
        onChange={(event, value) => handleChange(value)}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map((tab) => (
          <Tab key={tab.link} icon={tab.icon} value={tab.link} label={tab.label} />
        ))}
      </Tabs>
      <Paper elevation={0} style={{ backgroundColor: smAndUp ? "white" : "transparent" }}>
        {selectedTab && selectedTab.component}
      </Paper>{" "}
      {weekView && selectedTab?.label !== "Events" ? (
        <Paper elevation={0}>
          <Box
            paddingTop={2}
            marginRight={{ xs: 0, sm: 2 }}
            marginLeft={{ xs: 0, sm: 3 }}
            paddingBottom={2}
            style={{
              display: "flex",
              alignItems: "center",
              color: bhpColor.grey1,
              fontSize: "20px",
            }}
          >
            {t("Total Office Capacity")}: <strong>{building.total_capacity}</strong>
            <InformationIcon onclick={handleInformationOpen} />
          </Box>
        </Paper>
      ) : (
        ""
      )}
      <PopupInformationWidget
        t={t}
        isOpen={informationOpen}
        handleClose={handleInformationClose}
        handleSecondaryClick={handleInformationClose}
        title={t("Capacity Measures")}
        content={t("Capacity Measures Content")}
        buttonText={t("Okay")}
        secondaryButtonText={null}
      />
    </div>
  );
});

export default OfficeCapacity;
